(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name waitinglist.directive:configurewl
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="waitinglist">
       <file name="index.html">
        <configurewl></configurewl>
       </file>
     </example>
   *
   */
  angular
    .module('waitinglist')
    .directive('configurewl', configurewl);
  
  configurewl.$inject = ['Waitinglist', '$log'];
  
  function configurewl(Waitinglist, $log) {
    return {
      restrict: 'E',
      scope: {},
      templateUrl: 'waitinglist/configurewl-directive.tpl.html',
      replace: false,
      controllerAs: 'cwlCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'configurewl';
        vm.waitinglists = Waitinglist.$search({active: true});
        vm.add = add;
        vm.formid = guid;
        vm.splice = splice;
  
        function splice(i, wl) {
          vm.waitinglists.splice(i, 1);
          wl.active = false;
          wl.$save();
          $log.debug('array: ', vm.waitinglists);
        }
  
        function add() {
          if (vm.newlist !== '') {
            vm.waitinglists.$create({name: vm.newlist});
          }
          
          vm.newlist = '';
          /* if (!angular.isArray(vm.waitinglists)) {
            vm.waitinglists = [];
          }
          vm.waitinglists.push(vm.newvalue);
          $log.debug('array: ', vm.waitinglists);
          vm.newvalue = '';*/
        }
  
        function guid() {
          function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
              .toString(16)
              .substring(1);
          }
          return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
        }
        
        
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
