(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name helpers.doctor.factory:Doctor
   *
   * @description
   *
   */
  angular
    .module('helpers.doctor')
    .factory('Doctor', Doctor)
    .factory('Doctorcount', Doctorcount)
    .factory('Doctorpaged', Doctorpaged)
    .factory('Doctorsearch', Doctorsearch);

  Doctor.$inject = ['restmod'];
  function Doctor(restmod) {
    return restmod.model('/routes/doctors').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      titlefront: {init: 'Dr.'},
      active: {init: true}
    });
  }

  Doctorsearch.$inject = ['restmod'];
  function Doctorsearch(restmod) {
    return restmod.model('/routes/doctors/docahead');
  }

  Doctorcount.$inject = ['restmod'];
  function Doctorcount(restmod) {
    return restmod.model('/routes/doctors/count');
  }


  Doctorpaged.$inject = ['restmod'];
  function Doctorpaged(restmod) {
    return restmod.model('/routes/doctors/pagedlist');
  }


}());
