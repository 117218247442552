(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:patientMemo
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-memo patient=""></patient-memo>
   </file>
   </example>
   *
   */
  angular
    .module('patient')
    .directive('patientMemo', patientMemo);

  patientMemo.$inject = ['Config'];

  function patientMemo(Config) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'patient/patient-memo-directive.tpl.html',
      replace: false,
      // controller: 'PatientCtrl',
      controller: function () {
        var vm = this;
        // no idea why this is needed, but it is essential
        vm.conf = Config.$find('configuration');
      },
      controllerAs: 'pmCtrl',
      bindToController: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
