(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name invoice.directive:invoicebrowser
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="invoice">
   <file name="index.html">
   <invoicebrowser></invoicebrowser>
   </file>
   </example>
   *
   */
  angular
    .module('finance.invoice')
    .directive('invoicebrowser', invoicebrowser);
  
  
  function invoicebrowser() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'finance/invoice/invoicebrowser-directive.tpl.html',
      replace: false,
      controllerAs: 'invCtrl',
      bindToController: true,
      controller: function () {
      // this is an empty controller!
        
      } // controller
    };
  } // directive
  
  // klammerfriedhof
}());
