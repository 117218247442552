(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name dms.scan.directive:scanSorter
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="dms.scan">
   <file name="index.html">
   <scan-sorter></scan-sorter>
   </file>
   </example>
   *
   */
  angular
    .module('dms.scan')
    .directive('scanSorter', scanSorter);
  
  scanSorter.$inject = ['FileGrabberScan', 'FileAhead', 'File', '$log', 'Share', 'Config',
    'pdfDelegate', 'Patientsearch', 'SignHelper', '$http'];
  
  function scanSorter(FileGrabberScan, FileAhead, File, $log, Share, Config,
                      PdfDelegate, Patientsearch, SignHelper, $http) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'dms/scan/scan-sorter-directive.tpl.html',
      replace: false,
      controllerAs: 'scanCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.conf = Config.$find('configuration');
        
        vm.pdf = 0;
        vm.ready = false;
        // vm.fileDelete = fileDelete;
        // vm.fileLabel = fileLabel;
        vm.nextPdf = nextPdf;
        vm.previousPdf = previousPdf;
        vm.nextPage = nextPage;
        vm.previousPage = previousPage;
        vm.rotatePage = rotatePage;
        vm.gotoPage = gotoPage;
        vm.zoomIn = zoomIn;
        vm.zoomOut = zoomOut;
        vm.assignpatient = assignpatient;
        vm.getNames = getNames;
        vm.getTags = getTags;
        vm.clearPatient = clearPatient;
        vm.closePdf = closePdf;
        vm.reloadPdfs = reloadPdfs;
        vm.spliceDocument = spliceDocument;
        // vm.loadTags = loadTags;
        vm.assignKeyword = assignKeyword;
        vm.saveKeyword = saveKeyword;
        vm.formDisabled = formDisabled;
        
        // get the scans
        vm.reloadPdfs();
        
        // slow, but working
        function previousPdf() {
          $log.debug('next pdf');
          vm.scans[vm.pdf].$save();
          vm.pdf = vm.pdf === 0 ? vm.scans.length - 1 : vm.pdf - 1;
          PdfDelegate
            .$getByHandle('scans-pdf-container')
            .load('/routes/files/filereader/' + vm.scans[vm.pdf].fileid)
            .then(function () {
              vm.currentpage = 1;
              vm.currentpage = PdfDelegate.$getByHandle('scans-pdf-container').getCurrentPage();
              vm.maxpc = PdfDelegate.$getByHandle('scans-pdf-container').getPageCount();
            });
        }
        
        function nextPdf() {
          vm.scans[vm.pdf].$save();
          vm.pdf = vm.pdf < vm.scans.length - 1 ? vm.pdf + 1 : 0;
          PdfDelegate
            .$getByHandle('scans-pdf-container')
            .load('/routes/files/filereader/' + vm.scans[vm.pdf].fileid)
            .then(function () {
              vm.currentpage = 1;
              vm.currentpage = PdfDelegate.$getByHandle('scans-pdf-container').getCurrentPage();
              vm.maxpc = PdfDelegate.$getByHandle('scans-pdf-container').getPageCount();
            });
        }
        
        function previousPage() {
          PdfDelegate.$getByHandle('scans-pdf-container').prev();
          vm.currentpage = PdfDelegate.$getByHandle('scans-pdf-container').getCurrentPage();
        }
        
        function nextPage() {
          PdfDelegate.$getByHandle('scans-pdf-container').next();
          vm.currentpage = PdfDelegate.$getByHandle('scans-pdf-container').getCurrentPage();
        }
        
        function zoomIn() {
          PdfDelegate.$getByHandle('scans-pdf-container').zoomIn();
        }
        
        function zoomOut() {
          PdfDelegate.$getByHandle('scans-pdf-container').zoomOut();
        }
        
        function rotatePage() {
          PdfDelegate.$getByHandle('scans-pdf-container').rotate();
        }
        
        function gotoPage() {
          if (vm.currentpage > 0 && vm.currentpage <= vm.maxpc) {
            PdfDelegate.$getByHandle('scans-pdf-container').goToPage(vm.currentpage);
          }
        }
        
        
        // typeahead patient search backend query
        function getNames(val) {
          console.log('loading names');
          
          return Patientsearch.$search({query: val}).$asPromise().then(function (response) {
            console.log('response: ', response);
            return response.map(function (patient) {
              patient.text = patient.firstname + ' ' + patient.lastname + ', ' + patient.svnr;
              return patient;
            });
          });
        }
        
        // typeahead patient search action
        function assignpatient($item, $model, $label) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          vm.scans[vm.pdf].patientid = $model._id;
          vm.scans[vm.pdf].signedoff = false;
          vm.scans[vm.pdf].patientfname = $model.firstname + ' ' + $model.lastname + ', ' + $model.svnr;
          vm.scans[vm.pdf].$save();
          SignHelper.triggerUpdate();
          vm.asyncSelected = null;
        }
        
        
        function assignKeyword($item, $model, $label) {
          if (!angular.isArray(vm.scans[vm.pdf].keywords)) {
            vm.scans[vm.pdf].keywords = [];
          }
          $log.debug('$model: ', $model);
          $log.debug('$item: ', $item);
          $log.debug('$label: ', $label);
          // vm.scans[vm.pdf].keywords.push({text: $item});
          // saveKeyword();
        }
        
        function clearPatient() {
          vm.scans[vm.pdf].patientid = null;
          vm.scans[vm.pdf].patientfname = null;
          vm.scans[vm.pdf].signoff = null;
          vm.scans[vm.pdf].jobid = 'scan';
          vm.scans[vm.pdf].$save();
          SignHelper.triggerUpdate();
        }
        
        
        function closePdf() {
          vm.scans[vm.pdf].jobid = 'processedscan';
          vm.saveKeyword();
          vm.scans[vm.pdf].$save();
          vm.spliceDocument();
          // vm.reloadPdfs();
        }
        
        function spliceDocument() {
          if (vm.scans.length > 1) {
            vm.scans.splice(vm.pdf, 1);
            vm.pdf = vm.pdf === 0 ? vm.scans.length - 1 : vm.pdf - 1;
            vm.nextPdf();
          }
          else {
            vm.scans.splice(vm.pdf, 1);
            vm.pdf = 0;
            vm.nomoredocuments = true;
          }
        }
        
        function reloadPdfs() {
          vm.ready = false;
          vm.nomoredocuments = false;
          vm.pdf = 0;
          Share.$search({name: 'scan'}).$asPromise().then(function (share) {
            var shareobj = {};
            shareobj.path = share[0].path;
            FileGrabberScan.$create(shareobj).$asPromise().then(function () {
              File.$search({jobid: 'scan'}).$asPromise().then(function (s) {
                vm.scans = s;
                $log.debug('scans: ', vm.scans);
                vm.ready = true;
                if (vm.scans.length === 0) {
                  vm.nomoredocuments = true;
                }
                else {
                  // load the first scan
                  PdfDelegate
                    .$getByHandle('scans-pdf-container')
                    .load('/routes/files/filereader/' + vm.scans[vm.pdf].fileid)
                    .then(function () {
                      vm.currentpage = PdfDelegate.$getByHandle('scans-pdf-container').getCurrentPage();
                      vm.maxpc = PdfDelegate.$getByHandle('scans-pdf-container').getPageCount();
                    });
                }
              });
            });
          });
        }
        
        /* function loadTags(query) {
         var ptags = [{text: 'Leberwerte'}, {text: 'Biopsie'}, {text: 'Entlassungsbrief'}, {text: 'Vorbefund'}];
         return ptags;
         }
         */

        // use aggregate query to obtain a list of distinct tags
        // first match all documents contain a tag that fits the regex
        // then unwind the document per tag to obtain multiple copies
        function getTags(val) {
          var myregex = '^' + val; // when using $regex, do not include '/' for regex delimiter
          var match = [
            {'$match': {'flattags': {'$regex': myregex, '$options': 'i'}}},
            {'$unwind': '$flattags'},
            {'$match': {'flattags': {'$regex': myregex, '$options': 'i'}}},
            {'$group': {'_id': '$flattags',  'total': {$sum: 1}}}];
          
          console.log('loading tags');
          
          
          return $http.post('/routes/files/aggregate', match).then(function (response) {
            var i;
            var tags = [];
            // $log.debug('tags data received: ', response);
            for (i = 0; i < response.data.length; i++) {
              tags.push({text: response.data[i]._id});
            }
            $log.debug('suggested tags array: ', tags);
            $log.debug('keywords array: ', vm.scans[vm.pdf].keywords);
            return tags;
          });
          
          
/*
            // tagahead - typeahead flatkeyword search backend query
            return FileAhead.$search({query: val}).$asPromise().then(function (response) {
            var tags = [];
            // var filteredtags = [];
            var re = new RegExp('^' + val, 'i');
            
            response.forEach(function (res) {
              $log.debug('single response entry: ', res);
              res.keywords.forEach(function (t) {
                  $log.debug('tag: ', t);
                  if (re.exec(t.text)) {
                    $log.debug('tag matched: ', t);
                    tags = tags.concat(t);
                    tags.push(t.text);
                    $log.debug('tags matched: ', tags);
                  }
                }
              );
            });
            $log.debug('filtered tags: ', tags);
            return uniq(tags);
          });*/
        }
        
        
        function formDisabled(scan) {
          var answer = true;
          if (angular.isObject(scan) && scan.hasOwnProperty('doccat') && scan.doccat === 'patient' && angular.isString(scan.patientid)) {
            answer = false;
          }
          if (angular.isObject(scan) && scan.hasOwnProperty('doccat') && scan.doccat === 'generic') {
            answer = false;
          }
          return answer;
        }
        
/*        function uniq(a) {
          return a.sort().filter(function (item, pos, ary) {
            return !pos || item !== ary[pos - 1];
          });
        }*/
        
        
        function saveKeyword() {
          var flattags = [];
          var i = 0;
          if (angular.isArray(vm.scans[vm.pdf].keywords)) {
            for (i = 0; i < vm.scans[vm.pdf].keywords.length; i++) {
              flattags.push(vm.scans[vm.pdf].keywords[i].text);
            }
            vm.scans[vm.pdf].flattags = flattags;
          }
        }
        
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint 'no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
