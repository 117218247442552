(function () {
  'use strict';

  angular
    .module('login')
    .config(config);



  function config($stateProvider) {
    // show modal for login
    $stateProvider.state('login', {
      url: '/login',
      params: {url: {}},
      onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
        console.log('urls in onEnter: ', $stateParams.url);
        $uibModal.open({
          template: '<div class="modalspacer"><login url="miCtrl.url" dialog="miCtrl.$uibModalInstance"></login></div>',
          controller: ['url', '$uibModalInstance', function (u, $uibModalInstance) {
            console.log('$stateParams in uib controller: ', u);
            var vm = this;
            vm.url = u;
            vm.$uibModalInstance = $uibModalInstance;
          }],
          controllerAs: 'miCtrl',
          resolve: {
            url: function () {
              console.log('url in resolve: ', $stateParams.url);
              return $stateParams.url;
            }
          }
        });
      }]
    });

    $stateProvider.state('logout', {
      url: '/logout',
      onEnter: ['$state', '$http', function ($state, $http) {

        $http.get('/routes/logout')
          .then(function () {
            $state.go('login');
          });
      }]
    });

  }
}());
