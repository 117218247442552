(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name testdata.factory:Testdata
   *
   * @description
   *
   */
  angular
    .module('testdata')
    .factory('Testdata', Testdata);

  function Testdata() {
    var TestdataBase = {};
    TestdataBase.someValue = 'Testdata';
    TestdataBase.someMethod = function () {
      return 'Testdata';
    };
    return TestdataBase;
  }
}());
