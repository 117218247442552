(function () {
  'use strict';

  angular
    .module('testdata')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('testdata', {
        url: '/testdata',
        views: {
          main: {template: '<testdata></testdata>'},
          // leftbar: {template: ''},
          rightupper: {template: '<todowidget></todowidget>'},
          // rightlower: {template: '<chat></chat>'}
          rightlower: {template: ''}
        }
      });
  }
}());
