(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name drug.controller:DrugCtrl
   *
   * @description
   *
   */
  angular
    .module('drug')
    .controller('DrugCtrl', DrugCtrl);

  DrugCtrl.$inject = ['Drug', 'Drugsearch', 'Config'];

  function DrugCtrl(Drug, Drugsearch, Config) {
    var vm = this;
    vm.ctrlName = 'DrugCtrl';
    vm.Drug = Drug;
    vm.Drugsearch = Drugsearch;

    vm.getDrugs = function (val) {
      console.log('loading drug names');

      return vm.Drugsearch.$search({query: val}).$asPromise().then(function (response) {
        console.log('drug search response: ', response);
        return response.map(function (drug) {
          drug.text = drug.ZBEZ;
          return drug;
        });
      });
    };
  }
  
  // Klammerfriedhof
}());
