(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name patient.directive:checkoutDocuments
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <checkout-documents patient="consCtrl.patient"></checkout-documents>
   </file>
   </example>
   *
   */
  angular
    .module('consult')
    .directive('checkoutDocuments', checkoutDocuments);
  
  checkoutDocuments.$inject = ['$log', '$state', '$http', 'ExamHelper', 'ConsultModalsHelper',
    'PrescriptionHelper', 'Doctor', 'ConsultRefresh', 'vertxEventBusService', '$cookies', '$window'];
  
  function checkoutDocuments($log, $state, $http, ExamHelper, ConsultModalsHelper,
                             PrescriptionHelper, Doctor, ConsultRefresh, vertxEventBusService, $cookies, $window) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'consult/checkout-documents-directive.tpl.html',
      replace: false,
      controllerAs: 'docCtrl',
      bindToController: true,
      controller: function ($scope) {
        var vm = this;
        vm.$state = $state;
        vm.consultDocs = {};
        vm.examDocs = [];
        
        vm.editExam = editExam;
        vm.deleteExam = ExamHelper.deleteExam;
        vm.editPrescription = PrescriptionHelper.editPrescriptions;
        
        vm.addAideModal = ConsultModalsHelper.addAideModal;
        vm.addReferralModal = ConsultModalsHelper.addReferralModal;
        
        vm.checkReferrals = checkReferrals;
        vm.checkAides = checkAides;
        vm.checkPrescription = checkPrescription;
        vm.printSome = printSome;
        vm.saveAll = saveAll;
        vm.switchToPayment = switchToPayment;
        vm.setRecipient = setRecipient;


        vm.patient.checkoutstate = 'ccdocs';
        vm.patient.$save();
        
        function editExam(exam) {
          var closelistener;
          ExamHelper.editExam(exam, vm.patient);
          closelistener = $scope.$on('exameditclosed', function () {
            // $log.debug('cel: scope on ExamHelper.editExam call exam: ', exam);
            closelistener();
            vm.patient.openConsult.examinations.$refresh();
          });
        }
        
        function checkReferrals() {
          if (angular.isArray(vm.patient.openConsult.referrals) && vm.patient.openConsult.referrals.length > 0) {
            vm.consultDocs['referral'] = true;
          }
          else {
            vm.consultDocs['referral'] = false;
          }
          return vm.consultDocs['referral'];
        }

        function checkAides() {
          if (angular.isArray(vm.patient.openConsult.aides) && vm.patient.openConsult.aides.length > 0) {
            vm.consultDocs['aide'] = true;
          }
          else {
            vm.consultDocs['aide'] = false;
          }
          return vm.consultDocs['aide'];
        }

        function checkPrescription() {
          calculateActivedrugs();
          if (vm.patient.activedrugs > 0) {
            vm.consultDocs.prescription = true;
          }
          else {
            vm.consultDocs.prescription = false;
          }
        }
        
        function calculateActivedrugs() {
          var i;
          vm.patient.activedrugs = 0;
          for (i = 0; i < vm.patient.drugs.length; i++) {
            if (vm.patient.drugs[i].active === true && vm.patient.drugs[i].foreign === false && vm.patient.drugs[i].print === true) {
              vm.patient.activedrugs += 1;
            }
          }
        }
        
        function saveAll() {
          var key;
          var i;

          $log.debug('vm.ConsultDocs in saveAll: ', vm.ConsultDocs);


          // not so smart - results in automatic consult.$save() chain
          // set to forced manual selection instead
          // setRecipient();

          for (key in vm.consultDocs) {
            if (vm.consultDocs.hasOwnProperty(key)) {
              
              $http({
                url: '/routes/pdfprinter/consult',
                method: 'GET',
                params: {
                  patientid: vm.patient._id,
                  consultid: vm.patient.openConsult._id,
                  doctype: key
                }
              });
              
            }
          }
          
          if (vm.patient.openConsult.examinations.length) {
            for (i = 0; i < vm.examDocs.length; i++) {
              if (vm.patient.openConsult.examinations[i].active === true && vm.patient.openConsult.examinations[i].template.includefindings === false) {
                $http({
                  url: '/routes/pdfprinter/exam',
                  method: 'GET',
                  params: {
                    patientid: vm.patient._id,
                    examid: vm.patient.openConsult.examinations[i]._id,
                    doctype: 'examfinding'
                  }
                });
              }
            }
          }

          // vm.patient.openConsult.$save();
          switchToPayment();


          // TODO: this hangs the system. Why?
          /* vm.patient.openConsult.$save().$asPromise().then(function () {
           switchToPayment();
           }); */
        }
        
        function printSome() {
          var key;
          var i;
          $log.debug('vm.ConsultDocs in printSome(): ', vm.ConsultDocs);

          // not so smart - results in automatic consult.$save() chain
          // set to forced manual selection instead
          // setRecipient();
          
          for (key in vm.consultDocs) {
            if (vm.consultDocs.hasOwnProperty(key)) {
              if (vm.consultDocs[key]) {
                $http({
                  url: '/routes/pdfprinter/consultprint',
                  method: 'GET',
                  params: {
                    patientid: vm.patient._id,
                    consultid: vm.patient.openConsult._id,
                    doctype: key
                  }
                });
              }
              else {
                $http({
                  url: '/routes/pdfprinter/consult',
                  method: 'GET',
                  params: {
                    patientid: vm.patient._id,
                    consultid: vm.patient.openConsult._id,
                    doctype: key
                  }
                });
              }
            }
          }
          
          if (vm.patient.openConsult.examinations.length) {
            for (i = 0; i < vm.examDocs.length; i++) {
              if (vm.examDocs[i]) {
                $http({
                  url: '/routes/pdfprinter/examprint',
                  method: 'GET',
                  params: {
                    patientid: vm.patient._id,
                    examid: vm.patient.openConsult.examinations[i]._id,
                    doctype: 'examfinding'
                  }
                });
              }
              else {
                if (vm.patient.openConsult.examinations[i].active === true && vm.patient.openConsult.examinations[i].template.includefindings === false) {
                  $http({
                    url: '/routes/pdfprinter/exam',
                    method: 'GET',
                    params: {
                      patientid: vm.patient._id,
                      examid: vm.patient.openConsult.examinations[i]._id,
                      doctype: 'examfinding'
                    }
                  });
                }
              }
            }
          }
          // vm.patient.openConsult.$save();
          switchToPayment();
        }
        
        function switchToPayment() {
          // TODO: not sure if this would really work, would need to unset the variable if additional changes are made
          // leave it in for now, won't use in checkout-payment.reallyExit()
          vm.patient.openConsult.docssaved = true;
          vm.patient.checkoutstate = 'payment';
          vm.patient.incheckout = true;

          vm.patient.$save();
          $state.go('patientrecord.checkout.payment');
          /* vm.patient.$save().$asPromise().then(function () {
            $state.go('patientrecord.checkout.payment');
          }); */
        }

        function setRecipient() {
          var docid;
          vm.fr = {};
          $log.debug('setRecipient entered');
          $log.debug('is practdoctor: ', vm.recipient === 'practdoctor');
          if (angular.isString(vm.recipient) && (vm.recipient === 'practdoctor' || vm.recipient === 'refdoctor')) {
            $log.debug('yes, doctor selected');
            $log.debug('docid: ', vm.patient[vm.recipient]._id);
            docid = vm.patient[vm.recipient]._id;
            Doctor.$find(docid).$asPromise().then(function (d) {
              $log.debug('Doctor retrieved: ', d);
              setInfo(d);
            });
          }
          else {
            setInfo(vm.patient);
          }
        }

        // set recipient contact information field by field
        function setInfo(d) {
          var i = 0;
          if (d.titlefront) {
            vm.fr.titlefront = d.titlefront;
          }
          if (d.titleback) {
            vm.fr.titleback = d.titleback;
          }
          if (d.firstname) {
            vm.fr.firstname = d.firstname;
          }
          if (d.lastname) {
            vm.fr.lastname = d.lastname;
          }
          if (d.institute) {
            vm.fr.institute = d.institute;
          }
          if (d.address) {
            vm.fr.address = d.address;
          }
          if (d.postcode) {
            vm.fr.postcode = d.postcode;
          }
          if (d.city) {
            vm.fr.city = d.city;
          }
          if (d.phone1) {
            vm.fr.phone1 = d.phone1;
          }

          vm.patient.openConsult.recipient = vm.fr;
          $log.debug('Recipient created: ', vm.patient.openConsult.recipient);
          
          // save to consult for printing recipient on consultfindings
          vm.patient.openConsult.$save();
          
          // save to each exam to print recipient on examfindings
          if (angular.isArray(vm.patient.openConsult.examinations) && vm.patient.openConsult.examinations.length > 0) {
            for (i = 0; i < vm.patient.openConsult.examinations.length; i++) {
              vm.patient.openConsult.examinations[i].recipient = vm.fr;
              vm.patient.openConsult.examinations[i].$save();
            }
          }
          
          // vm.patient.recipient = vm.fr;
          // vm.patient.$save();

        }


      },
      link: function (scope, element, attrs, ctrl) {

        ConsultRefresh.refreshAll(ctrl.patient);

        /* ctrl.patient.openExam.$refresh();
         ctrl.patient.openConsult.$refresh().$asPromise().then(function (oc) {
         oc.examinations.$refresh();
         });
         */

        // listen for openconsult updates
        vertxEventBusService.on('consults.update', function (message) {
          var messages = message.split(':');
          console.log('Consult updated: ', messages[0]);
          if (messages[1] !== $window.sessionStorage.getItem('sprtabid') && ctrl.patient.openConsult._id === messages[0]) {
          // if (messages[1] !== $cookies.get('vertx-web.session') && ctrl.patient.openConsult._id === messages[0]) {
            ctrl.patient.openConsult.$refresh().$asPromise().then(function () {
              ctrl.patient.openConsult.examinations.$refresh();
            });
            $log.debug(' (cbd) refreshed openconsult for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
          else {
            $log.debug(' (cbd) ignored openconsult change for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
        });

      }
    };
  }
}());
