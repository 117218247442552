(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name drug.factory:PrescriptionHelper
   *
   * @description
   *
   */
  angular
    .module('drug')
    .factory('PrescriptionHelper', PrescriptionHelper);

  PrescriptionHelper.$inject = ['$log', '$uibModal'];

  function PrescriptionHelper($log, $uibModal) {
    var PrescriptionHelperBase = {};

    PrescriptionHelperBase.editPrescriptions = editPrescriptions;

    function editPrescriptions(patient) {
      $log.debug('editPrescription called');
      var modalinstance = $uibModal.open({
        template: '<div class="modalspacer"><prescription patient="miCtrl.patient" dialog="miCtrl.dialog"></prescription></div>',
        size: 'lg',
        backdrop: 'static',
        controllerAs: 'miCtrl',
        controller: ['$uibModalInstance',
          function EditPrescriptionCtrl($uibModalInstance) {
            var vm = this;
            $log.debug('EditPrescriptionCtrl patient: ', patient);
            vm.patient = patient;
            vm.dialog = $uibModalInstance;
          }], // editPrescription modal controller
        resolve: {
          patient: function () {
            $log.debug('EditPrescriptionCtrl resolve function patient: ', patient);
            return patient;
          }
        }
      }); // editPrescription modal open
    }

    return PrescriptionHelperBase;
  }
}());
