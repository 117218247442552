<h3>Registrierkassen DEP Datenexport (Datenerfassungsprotokoll)</h3>

<div class="alert alert-info">
  <h3>Anleitung</h3>
  <p>Diese Routine exportiert das Registrierkassen Datenerfassungsprotokoll gemäß RKSV17.
    Da diese Registrierkasse Klasse III als Teil einer integrierten Branchenlösung ausschließlich Belege für Rechnungen erzeugt,
    sind diese Daten möglicherweise nicht ausreichend. Bitte Rücksprache halten und ggf. den
    <a ui-sref="finance.bmfexport">Finanzamtsexport</a> anwenden, der mehr Daten beinhaltet.</p>
  <p>Daten können beginnend mit 1.1.2017, für das aktuelle Jahr oder einen beliebigen Zeitraum exportiert werden.</p>
  <p>Das Ergebnis des Exports ist eine Datei namens <tt>depexport.json</tt>. Diese Datei muss lokal gespeichert und auf
    einen Memorystick kopiert werden. Der Memorystick wird an die Finanz übergeben.
    Nach Rücksprache mit dem Finanzbeamten kann die Datei eventuell auch per E-Mail übermittelt werden.</p>
</div>

<a ng-click="vm.thisyear()" class="btn btn-success" >Dieses Jahr</a>
<a ng-click="vm.allyears()" class="btn btn-success" >Seit 1.1.2017</a>

<form class="form-vertical">
  <div class="row">
    <div class="col-md-6">
      <label for="startdate">Von</label>

      <p class="input-group">
        <input type="text" class="form-control" uib-datepicker-popup="yyyy-MM-dd" ng-model="vm.startdate"
               is-open="start.opened" ng-required="true" id="startdate" name="startdate"
               clear-text="Löschen" close-text="Schließen" current-text="Heute" starting-day="1"
               alt-input-formats="vm.altInputFormats"/>
              <span class="input-group-btn">
                <button type="button" class="btn btn-default" ng-click="start.opened = true;"><i
                  class="glyphicon glyphicon-calendar"></i></button>
              </span>
      </p>
    </div>
    <div class="col-md-6">
      <label for="enddate">Bis</label>
      <p class="input-group">
        <input type="text" class="form-control" uib-datepicker-popup="yyyy-MM-dd" ng-model="vm.enddate"
               is-open="end.opened" ng-required="true" id="enddate" name="enddate"
               close-text="Schließen" clear-text="Löschen" current-text="Heute" starting-day="1"
               alt-input-formats="vm.altInputFormats"/>
              <span class="input-group-btn">
                <button type="button" class="btn btn-default" ng-click="end.opened = true;"><i
                  class="glyphicon glyphicon-calendar"></i></button>
              </span>
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <button class="btn btn-success" ng-click="vm.exportDEP()"><i class="fa fa-download"></i> Exportieren</button>
    </div>
  </div>
</form>
