<!-- This is a very important hack: DO NOT REMOVE this totally pointless invisible bindings -->
<!-- Removal of these bindings will cause exam and consult close detection to fail -->
<span style="visibility: hidden">Consult Status patient: <span ng-bind='billCtrl.patient.inconsult'></span> </span>
<span style="visibility: hidden">Consult Status openConsult: <span ng-bind='billCtrl.patient.openConsult.open'></span> </span>
<span style="visibility: hidden">Exam Status patient: <span ng-bind='billCtrl.patient.inexam'></span> </span>
<span style="visibility: hidden">Exam Status openExam: <span ng-bind='billCtrl.patient.openExam.open'></span> </span>
<span style="visibility: hidden">openExam type: <span ng-bind='billCtrl.patient.openExam.template.displayname'></span> </span>
<span style="visibility: hidden">Consultstate: <span ng-bind='billCtrl.patient.consultstate'></span> </span>
<span style="visibility: hidden">Checkoutstate: <span ng-bind='billCtrl.patient.checkoutstate'></span> </span>

<!-- not in consult -->
<div ng-hide="billCtrl.patient.inconsult == true && billCtrl.patient.openConsult.open === true">
  <div class="alert alert-info">
    <h4>Die Konsultation wurde von anderer Stelle beendet</h4>
    <span class="btn btn-primary" ng-click="billCtrl.$state.go('patientrecord.history')">Zur Patienten-Historie wechseln</span>
  </div>
</div>

<!-- in consult, already in checkout-->
<!--
<div
    ng-show="billCtrl.patient.inconsult == true && billCtrl.patient.openConsult.open === true && billCtrl.patient.incheckout === true">
  <div class="alert alert-info">
    <h4>Diese Konsultation wird gerade abgeschlossen</h4>
    <span class="btn btn-primary" ng-click="billCtrl.switchToExit()">
      Zum Konsultationsabschluß wechseln
    </span>
  </div>
-->


</div>

<!-- In consult -->
<div
    ng-show="billCtrl.patient.inconsult == true && billCtrl.patient.openConsult.open === true">
  
  
  <h3>Honorarnote</h3>
  
  
  <h4>Diagnosen für Rechnung</h4>
<!--<textarea class="form-control" rows="2" title="invoicediag"
          ng-model="billCtrl.patient.openConsult.service.invoicediagnoses"
          ng-change="billCtrl.patient.openConsult.$save()">{{billCtrl.patient.openConsult.invoicediagnoses}}</textarea>-->
 
  <textarea class="form-control" rows="2" title="invoicediag"
          ng-init="billCtrl.patient.openConsult.invoicediagnoses = billCtrl.joinDiagnoses()"
          ng-model="billCtrl.patient.openConsult.invoicediagnoses"
          ng-blur="billCtrl.patient.openConsult.$save()">{{billCtrl.patient.openConsult.invoicediagnoses}}</textarea>
 
  <h4>Gewählte Positionen</h4>
  <div ng-hide="billCtrl.patient.openConsult.service" class="alert alert-info">
    <h4>Dienstleistungskatalog wird geladen</h4>
  </div>
  
<!--  <div ng-hide="billCtrl.patient.openConsult.service.items.length" class="alert alert-info">
    <h4>Es wurden noch keine Rechnungspositionen ausgewählt</h4>
  </div>-->
  
  <table width="100%" ng-show="billCtrl.patient.openConsult.service.items.length">
    <tr style="border-bottom: #333333 solid 2pt">
      <th width="5%">Pos</th>
      <th width="55%">Name</th>
      <th width="15%" style="text-align: right">Preis EUR</th>
      <th width="15%" style="text-align: right">Steuer %</th>
      <th width="10%" style="text-align: center">Aktion</th>
    </tr>
    <!-- Gewählte Positionen -->
    <tr ng-repeat="s in billCtrl.patient.openConsult.service.items | filter: { selected: true }">
      <td>{{$index + 1}}</td>
      <td><span>{{s.name}}</span> <span ng-show="s.hint">&nbsp;({{s.hint}})</span></td>
      <td style="text-align: right">{{s.price | currency: '' : 2}} EUR</td>
      <td style="text-align: right">{{s.tax | currency: '' : 0}} %</td>
      <td style="text-align: center">
      <span class="btn btn-danger" uib-tooltip="Service aus Liste entfernen"
            ng-click="s.selected = false; billCtrl.sumUpItems()"><i class="fa fa-remove"></i>
      </span>
      </td>
    </tr>
    <!-- Zwischensumme netto -->
    <tr style="border-top: #333333 solid 2pt;">
      <td></td>
      <td style="text-align: right">Betrag (netto)&nbsp;</td>
      <td style="text-align: right">{{billCtrl.patient.openConsult.service.netto | currency: '' : 2}} EUR</td>
      <td style="text-align: right"></td>
      <td style="text-align: center">
      </td>
    </tr>
    <!-- Gesamtsumme brutto -->
    <tr style="border-top: #333333 solid 2pt;border-bottom: #333333 double 2pt;">
      <td></td>
      <td style="text-align: right">Zwischensumme (brutto)&nbsp;</td>
      <td style="text-align: right">{{billCtrl.patient.openConsult.service.totalbefore | currency: '' : 2}} EUR</td>
      <td style="text-align: right"></td>
      <td style="text-align: center">
      </td>
    </tr>
    <!-- Rabatt -->
    <tr class="alert alert-warning">
      <td></td>
      <td style="text-align: right">Rabatt (EUR)&nbsp;</td>
      <td style="text-align: right; padding-top:5pt; padding-bottom:5pt;">
        <input type="number"
               class="form-control" ng-change="billCtrl.sumUpItems()"
               ng-model-options="{ updateOn: 'default blur', debounce: { default: 2000, blur: 0 } }"
               step='0.01' min
               style="text-align: right"
               
               ng-model="billCtrl.patient.openConsult.service.discount"></td>
      <td style="text-align: right"></td>
      <td style="text-align: center">
      </td>
    </tr>
    <!-- Steuer -->
<!--    <tr>
      <td></td>
      <td style="text-align: right">USt. 20%&nbsp;</td>
      <td style="text-align: right">{{billCtrl.patient.openConsult.service.tax | currency: '' : 2}} EUR</td>
      <td style="text-align: right"></td>
      <td style="text-align: center">
      </td>
    </tr>-->
    <tr ng-repeat="(tax, val) in billCtrl.patient.openConsult.service.discounttaxsum">
      <td></td>
      <td style="text-align: right">USt. {{tax}}%&nbsp;</td>
      <td style="text-align: right">{{val | currency: '' : 2}} EUR</td>
      <td style="text-align: right"></td>
      <td style="text-align: center">
      </td>
    </tr>
    <!-- Gesamtsumme brutto -->
    <tr style="border-top: #333333 solid 2pt;border-bottom: #333333 double 2pt;">
      <td></td>
      <td style="text-align: right">Gesamt (brutto)&nbsp;</td>
      <td style="text-align: right">{{billCtrl.patient.openConsult.service.total | currency: '' : 2}} EUR</td>
      <td style="text-align: right"></td>
      <td style="text-align: center">
      </td>
    </tr>
  </table>
  
  <!-- Buttons -->
  <div class="row spacer-top">
    <div class="col-md-12">
      <button type="submit" class="btn btn-success" ng-click="billCtrl.saveInvoiceItems()"
              ng-hide="billCtrl.patient.incheckout === true"
              data-ng-disabled="billCtrl.nonzeroitems() === 0">
        Weiter zur Dokumentenübersicht
      </button>
      <button type="submit" class="btn btn-success" ng-click="billCtrl.saveInvoiceItems()"
              ng-show="billCtrl.patient.incheckout === true"
              data-ng-disabled="billCtrl.nonzeroitems() === 0">
        Weiter zum Bezahlen
      </button>

      <!--<button type="submit" class="btn btn-success" ng-click="billCtrl.saveInvoiceItems()"
              ng-show="billCtrl.$state.current.name === 'patientrecord.billing'"
              data-ng-disabled="billCtrl.patient.openConsult.service.items.length === 0">
        Weiter zur Dokumentenübersicht
      </button>
      <button type="submit" class="btn btn-success" ng-click="billCtrl.saveInvoiceItems()"
              ng-show="billCtrl.$state.current.name === 'checkout.redobilling'"
              data-ng-disabled="billCtrl.patient.openConsult.service.items.length === 0">
        Weiter zum Bezahlen
      </button>-->
      <a type="submit" class="btn btn-info" target="_blank"
         data-ng-disabled="billCtrl.patient.openConsult.service.items.length === 0"
         href="/routes/pdfprinter/consultpreview?patientid={{billCtrl.patient._id}}&consultid={{billCtrl.patient.openConsult._id}}&doctype=invoicepreview">
        <i class="fa fa-eye"></i> PDF Vorschau</a>
      <button type="submit" class="btn btn-danger" ng-click="billCtrl.noInvoice()">Weiter ohne Rechnung</button>
    </div>
  </div>
  
  
  <!-- Service Liste -->
  <h4>Positionen</h4>
  <form>
    <div class="row">
      <div class="col-md-12" ng-if="service.price > 0"
           ng-repeat="service in billCtrl.patient.openConsult.service.items | filter: { active: true }">
        <div class="form-group col-md-12">
          <label>
            <input type="checkbox"
                   ng-init="false"
                   ng-model="service.selected"
                   ng-change="billCtrl.sumUpItems()"
                   uib-tooltip="{{service.comment}}"/> {{service.name}}<span
              ng-show="service.hint">({{service.hint}})</span>, EUR {{service.price}}</label>
        </div>
      </div>
    </div>
  </form>
  
  <div style="min-height:3cm">&nbsp;</div>
</div>