(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name dms.signoff.directive:signoffAll
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="dms.signoff">
   <file name="index.html">
   <signoff-all></signoff-all>
   </file>
   </example>
   *
   */
  angular
    .module('dms.signoff')
    .directive('signoffAll', signoffAll);

  signoffAll.$inject = ['File', '$log', 'Patient', 'pdfDelegate', 'SignHelper', '$stateParams',
    'vertxEventBusService', '$state', '$uibModal', '$http'];


  function signoffAll(File, $log, Patient, PdfDelegate, SignHelper, $stateParams,
                      vertxEventBusService, $state, $uibModal, $http) {
    return {
      restrict: 'EA',
      scope: {
        patientid: '@'
      },
      templateUrl: 'dms/signoff/signoff-all-directive.tpl.html',
      replace: false,
      controllerAs: 'soCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        var query = {};
        vm.name = 'signoffAll';
        vm.nomoredocuments = true;
        vm.ready = false;
        vm.$state = $state;

        vm.pdf = 0;
        vm.ready = false;
        vm.nextPdf = nextPdf;
        vm.previousPdf = previousPdf;
        vm.nextPage = nextPage;
        vm.previousPage = previousPage;
        vm.rotatePage = rotatePage;
        vm.gotoPage = gotoPage;
        vm.zoomIn = zoomIn;
        vm.zoomOut = zoomOut;
        vm.reloadPdfs = reloadPdfs;
        vm.closePdf = closePdf;
        vm.getTags = getTags;
        vm.patientlogger = patientlogger;
        vm.patienttodo = patienttodo;

        vm.$onInit = function () {
          // $log.debug('stateParams.patientid: ', $stateParams.patientid);
          $log.debug('***** vm.patientid: ', vm.patientid);


          // get the scans
          if (angular.isDefined(vm.patientid) && angular.isString(vm.patientid) && vm.patientid.length > 0) {
            query = {signedoff: false, patientid: $stateParams.patientid};
            $log.debug('Loading scans for patient: ', $stateParams.patientid);
          }
          else {
            query = {signedoff: false};
            $log.debug('Loading all assigned scans');
          }

          File.$search(query).$then(function (s) {
            vm.unsignedScans = s;
            vm.reloadPdfs();
            $log.debug(vm.unsignedScans);
          });
        };



        // slow, but working
        function previousPdf() {
          $log.debug('next pdf');
          vm.unsignedScans[vm.pdf].$save();
          vm.pdf = vm.pdf === 0 ? vm.unsignedScans.length - 1 : vm.pdf - 1;
          vm.patient = Patient.$find(vm.unsignedScans[vm.pdf].patientid);
          PdfDelegate
            .$getByHandle('unsigned-pdf-container')
            .load('/routes/files/filereader/' + vm.unsignedScans[vm.pdf].fileid)
            .then(function () {
              vm.currentpage = 1;
              vm.currentpage = PdfDelegate.$getByHandle('unsigned-pdf-container').getCurrentPage();
              vm.maxpc = PdfDelegate.$getByHandle('unsigned-pdf-container').getPageCount();
            });
        }

        function nextPdf() {
          vm.unsignedScans[vm.pdf].$save();
          vm.pdf = vm.pdf < vm.unsignedScans.length - 1 ? vm.pdf + 1 : 0;
          vm.patient = Patient.$find(vm.unsignedScans[vm.pdf].patientid);
          PdfDelegate
            .$getByHandle('unsigned-pdf-container')
            .load('/routes/files/filereader/' + vm.unsignedScans[vm.pdf].fileid)
            .then(function () {
              vm.currentpage = 1;
              vm.currentpage = PdfDelegate.$getByHandle('unsigned-pdf-container').getCurrentPage();
              vm.maxpc = PdfDelegate.$getByHandle('unsigned-pdf-container').getPageCount();
            });

        }

        function previousPage() {
          PdfDelegate.$getByHandle('unsigned-pdf-container').prev();
          vm.currentpage = PdfDelegate.$getByHandle('unsigned-pdf-container').getCurrentPage();
        }

        function nextPage() {
          PdfDelegate.$getByHandle('unsigned-pdf-container').next();
          vm.currentpage = PdfDelegate.$getByHandle('unsigned-pdf-container').getCurrentPage();
        }

        function zoomIn() {
          PdfDelegate.$getByHandle('unsigned-pdf-container').zoomIn();
        }

        function zoomOut() {
          PdfDelegate.$getByHandle('unsigned-pdf-container').zoomOut();
        }

        function rotatePage() {
          PdfDelegate.$getByHandle('unsigned-pdf-container').rotate();
        }

        function gotoPage() {
          if (vm.currentpage > 0 && vm.currentpage <= vm.maxpc) {
            PdfDelegate.$getByHandle('unsigned-pdf-container').goToPage(vm.currentpage);
          }
        }

        function reloadPdfs() {
          vm.ready = false;
          vm.nomoredocuments = true;
          vm.pdf = 0;

          $log.debug('unsigned scans length: ', vm.unsignedScans.length);
          if (vm.unsignedScans.length > 0) {
            vm.patient = Patient.$find(vm.unsignedScans[vm.pdf].patientid);
            vm.ready = true;
            PdfDelegate
              .$getByHandle('unsigned-pdf-container')
              .load('/routes/files/filereader/' + vm.unsignedScans[vm.pdf].fileid)
              .then(function () {
                vm.currentpage = PdfDelegate.$getByHandle('unsigned-pdf-container').getCurrentPage();
                vm.maxpc = PdfDelegate.$getByHandle('unsigned-pdf-container').getPageCount();
                vm.ready = true;
                vm.nomoredocuments = false;
              });
          }
        }


        // get document classification tags
        function getTags(val) {
          var myregex = '^' + val; // when using $regex, do not include '/' for regex delimiter
          var match = [
            {'$match': {'flattags': {'$regex': myregex, '$options': 'i'}}},
            {'$unwind': '$flattags'},
            {'$match': {'flattags': {'$regex': myregex, '$options': 'i'}}},
            {'$group': {'_id': '$flattags',  'total': {$sum: 1}}}];

          return $http.post('/routes/files/aggregate', match).then(function (response) {
            var i;
            var tags = [];
            // $log.debug('tags data received: ', response);
            for (i = 0; i < response.data.length; i++) {
              tags.push({text: response.data[i]._id});
            }
            return tags;
          });
        }

        // Modal für neuen Patient-Log-Eintrg
        function patientlogger() {
          $uibModal.open({
            template: '<div class="modalspacer"><patient-log patient="miCtrl.patient"></patient-log></div>',
            size: 'modal-sm',
            controller: ['patient', function (patient) {
              var vmi = this;
              vmi.patient = patient;
            }],
            controllerAs: 'miCtrl',
            resolve: {
              patient: function () {
                return vm.patient;
              }
            }
          });
        }


        // Modal for new Patient-Todo
        function patienttodo() {
          $uibModal.open({
            template: '<div class="modalspacer"><patient-reminder patient="miCtrl.patient"></patient-reminder></div>',
            size: 'modal-sm',
            controller: ['patient', function (patient) {
              var vmi = this;
              vmi.patient = patient;
            }],
            controllerAs: 'miCtrl',
            resolve: {
              patient: function () {
                return vm.patient;
              }
            }
          });
        }

        function closePdf() {
          vm.unsignedScans[vm.pdf].signedoff = true;
          vm.unsignedScans[vm.pdf].$save();
          // signal array change
          SignHelper.triggerUpdate();
        }

        // listen for array change, reload list and redisplay
        vertxEventBusService.on('signoff.update', function () {
          vm.unsignedScans.$refresh().$asPromise().then(function () {
            $log.debug('signoff.update caught: ', vm.unsignedScans);
            vm.reloadPdfs();
          });
        });


      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
