(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name utils.filereader.factory:Filereader
   *
   * @description
   *
   */
  angular
    .module('utils.filereader')
    .factory('Filereader', Filereader);

  Filereader.$inject = ['$q'];

  function Filereader($q) {
    var onLoad = function (reader, deferred, scope) {
      return function () {
        scope.$apply(function () {
          deferred.resolve(reader.result);
        });
      };
    };

    var onError = function (reader, deferred, scope) {
      return function () {
        scope.$apply(function () {
          deferred.reject(reader.result);
        });
      };
    };

    var onProgress = function (reader, scope) {
      return function (event) {
        scope.$broadcast('fileProgress',
            {
              total: event.total,
              loaded: event.loaded
            }
        );
      };
    };

    var getReader = function (deferred, scope) {
      var reader = new FileReader();
      reader.onload = onLoad(reader, deferred, scope);
      reader.onerror = onError(reader, deferred, scope);
      reader.onprogress = onProgress(reader, scope);
      return reader;
    };

    var readAsDataURL = function (file, scope) {
      var deferred = $q.defer();
      var reader = getReader(deferred, scope);
      console.log('readasdaturl scope: ',scope);
      reader.readAsDataURL(file);

      return deferred.promise;
    };

    var readAsText = function (file, scope) {
      var deferred = $q.defer();
      var reader = getReader(deferred, scope);
      reader.readAsText(file);
      return deferred.promise;
    };

    var readAsArrayBuffer = function (file, scope) {
      var deferred = $q.defer();
      var reader = getReader(deferred, scope);
      reader.readAsArrayBuffer(file);
      return deferred.promise;
    };

    var readAsBinaryString = function (file, scope) {
      var deferred = $q.defer();
      var reader = getReader(deferred, scope);
      reader.readAsBinaryString(file);
      return deferred.promise;
    };

    return {
      readAsDataUrl: readAsDataURL,
      readAsText: readAsText,
      readAsBinaryString: readAsBinaryString,
      readAsArrayBuffer: readAsArrayBuffer
    };

  }


}());


