<div ui-view="doctormain" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
<h2>Ärzteverzeichnis</h2>


    <!--<div><a class="btn btn-success" ui-sref="doctornew">Neuen Arzt anlegen</a></div>-->
    <h3>Ärztesuche</h3>
    <div><doctor-search></doctor-search></div>


    <doctors></doctors>
</div>