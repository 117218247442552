(function () {
  'use strict';

  /* @ngdoc object
   * @name service
   * @description
   *
   */
  angular
    .module('service', [
      'ui.router'
    ])
    .filter('aek', function () {
      return function (points) {
        if (!points) {
          return '';
        }
        return points * 0.15;
      };
    });


}());


