(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name consult.factory:OpenConsults
   *
   * @description
   *
   */
  angular
    .module('consult')
    .factory('OpenConsults', OpenConsults);

  OpenConsults.$inject = ['restmod', '$log', 'vertxEventBusService'];

  function OpenConsults(restmod, $log, vertxEventBusService) {
    var OpenConsultsBase = {};
    var model = restmod.model('/routes/patients');
    OpenConsultsBase.allOpenConsults = model.$search({inconsult: true});
    OpenConsultsBase.triggerUpdate = function () {
      vertxEventBusService.publish('oc.update', {});
      $log.debug('openConsult factory refresh triggered');
    };

    return OpenConsultsBase;
  }



}());
