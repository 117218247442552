(function () {
  'use strict';

  angular
    .module('document')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('document', {
        url: '/document',
        templateUrl: 'document/document.tpl.html',
        controller: 'DocumentCtrl',
        controllerAs: 'document'
      });
  }
}());
