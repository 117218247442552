<h3>Dokumente zuordnen: <span class="label label-danger">{{scanCtrl.scans.length}}</span> unbearbeite Dokumente</h3>

<div ng-hide="scanCtrl.ready" class="alert alert-info">
    <h3><i class="fa fa-info-circle"></i> Scans werden geladen</h3>
</div>

<div ng-show="scanCtrl.nomoredocuments" class="alert alert-info">
        <h3><i class="fa fa-info-circle"></i> Keine Dokumente verfügbar
            <span class="btn btn-primary" ng-click="scanCtrl.reloadPdfs()">Nach neuen Dokumenten suchen</span>
        </h3>
</div>


<!-- Menubar -->
<div>
            <button class="btn btn-primary" ng-click="scanCtrl.previousPdf()" uib-tooltip="Vorige PDF Datei bearbeiten"><i class="fa fa-arrow-left"></i> PDF</button>
    <button class="btn btn-info" ng-click="scanCtrl.zoomIn()" uib-tooltip="Hineinzoomen"><i class="fa fa-search-plus"></i></button>
    <button class="btn btn-info" ng-click="scanCtrl.zoomOut()" uib-tooltip="Herauszoomen"><i class="fa fa-search-minus"></i></button>
    <button class="btn btn-info" ng-click="scanCtrl.rotatePage()" uib-tooltip="Dokument drehen"><i class="fa fa-rotate-right"></i></button>
    <button class="btn btn-info" ng-click="scanCtrl.previousPage()" uib-tooltip="Zurückblättern"><i class="fa fa-arrow-left"></i></button>
    <!--  Seite: {{scanCtrl.currentpage}} / {{scanCtrl.maxpc}}  -->
    <form style="display: inline" class="form-horizontal">
        <label for="pagenr"> Seite: </label>
        <input type="number" class="form-control"
               style="display: inline; width: 4em"
               width="5em" name="pagenr" id="pagenr"
               ng-change="scanCtrl.gotoPage()"
               ng-model="scanCtrl.currentpage">
        <label>/{{scanCtrl.maxpc}}</label></form>
    <button class="btn btn-info" ng-click="scanCtrl.nextPage()" uib-tooltip="Vorblättern"><i class="fa fa-arrow-right"></i></button>
    <button class="btn btn-success" ng-click="scanCtrl.scans[scanCtrl.pdf].doccat = 'patient'"
            uib-tooltip="Dokument Patient zuordnen">
        <i class="fa fa-arrow-down"></i><i class="fa fa-user"></i>
    </button>
    <button class="btn btn-success" ng-click="scanCtrl.scans[scanCtrl.pdf].doccat = 'generic'"
            uib-tooltip="Allgemeines Dokument zuordnen">
        <i class="fa fa-arrow-down"></i><i class="fa fa-book"></i>
    </button>
    <button class="btn btn-primary" ng-click="scanCtrl.reloadPdfs()" uib-tooltip="PDFs neu laden">PDF <i class="fa fa-undo"></i></button>
    <button class="btn btn-primary" ng-click="scanCtrl.nextPdf()" uib-tooltip="Nächste PDF Datei bearbeiten">PDF <i class="fa fa-arrow-right"></i></button>
</div>

<!-- DMS Actions -->
<form class="form-vertical spacer-top">
    <!-- patient docs -->
    <div class="row" ng-show="scanCtrl.scans[scanCtrl.pdf].doccat === 'patient'">
        <!-- Assign Patient -->
        <div class="form-group col-xs-12 col-md-6" ng-hide="scanCtrl.scans[scanCtrl.pdf].patientid">
            <label for="scanpatient">Patient</label>
            <div  class="input-group">
                <input type="text" class="form-control"
                       id="scanpatient"
                       placeholder="Patient auswählen ..."
                       ng-model="scanCtrl.asyncSelected"
                       typeahead-on-select="$parent.$dismiss();scanCtrl.assignpatient($item, $model, $label)"
                       uib-typeahead="pat as pat.text for pat in scanCtrl.getNames($viewValue)"
                       typeahead-loading="loadingNames"
                       typeahead-no-results="noResults">
                <span class="input-group-btn">
                    <button type="submit" class="btn btn-success"><i class="fa fa-search"></i></button>
                </span>
                <i ng-show="loadingNames" class="glyphicon glyphicon-refresh"></i>

                <div ng-show="noResults">
                    <i class="glyphicon glyphicon-remove"></i> Keine Patienten gefunden
                </div>
            </div>
        </div>
        <div class="form-group col-xs-12 col-md-6" ng-show="scanCtrl.scans[scanCtrl.pdf].patientid">
            <label for="scanpatientn">Patient</label>
            <div class="input-group">
                <input type="text" class="form-control"
                       id="scanpatientn"
                       readonly
                       ng-model="scanCtrl.scans[scanCtrl.pdf].patientfname">
                <span class="input-group-btn">
                    <button class="btn btn-danger"
                        ng-click="scanCtrl.clearPatient()"><i class="fa fa-remove"></i>
                    </button>
                </span>
            </div>
        </div>
        <!-- PatientDokument type -->
        <div class="col-xs-12 col-md-6">
            <div class="form-group">
                <label for="patdoctype">Patienten-Dokumenttyp</label>
                <select id="patdoctype" ng-model="scanCtrl.scans[scanCtrl.pdf].type" class="form-control">
                    <option ng-repeat="v in scanCtrl.conf.dmspatientcat" value="{{v}}">{{v}}</option>
                </select>
            </div>
        </div>
    </div>
<!-- generic docs -->
    <div class="row" ng-show="scanCtrl.scans[scanCtrl.pdf].doccat === 'generic'">
        <div class="col-xs-12">
            <div class="form-group">
                <label for="gendoctype">Allgemeiner Dokumenttyp</label>
                <select id="gendoctype" ng-model="scanCtrl.scans[scanCtrl.pdf].type" class="form-control">
                    <option ng-repeat="v in scanCtrl.conf.dmsgencat"  value="{{v}}" >{{v}}</option>
                </select>
            </div>
        </div>
    </div>

    <!-- keywords -->
    <div class="row" ng-show="scanCtrl.scans[scanCtrl.pdf].doccat">
        <div class="form-group col-xs-10">
            <label for="dockeywords">Stichworte</label>
            <tags-input ng-model="scanCtrl.scans[scanCtrl.pdf].keywords"
                        display-property="text"
                        id="dockeywords"
                        add-on-paste="true"
                        add-on-comma="false"
                        min-length="1"
                        enable-editing-last-tag="true"
                        add-from-autocomplete-only="false"
                        replace-spaces-with-dashes="false"
                        template="tag-template">

                <auto-complete source="scanCtrl.getTags($query)"
                               min-length="1"
                               load-on-focus="true"
                               load-on-empty="false"
                               load-on-down-arrow="true"
                               max-results-to-show="32"
                               select-first-match="false"
                               template="autocomplete-template">
                </auto-complete>

            </tags-input>
        </div>
        <div class="form-group col-xs-2">
            <label for="dockeywords">&nbsp;</label>
            <button class="btn btn-success" ng-click="scanCtrl.closePdf()" uib-tooltip="Dieses Dokument abschließen"
            ng-disabled="scanCtrl.formDisabled(scanCtrl.scans[scanCtrl.pdf])">
                Abschließen
            </button>
        </div>
    </div>
</form>

<!-- PDF Viewer -->
<pdf-viewer ng-show="scanCtrl.ready && !scanCtrl.nomoredocuments"
            delegate-handle="scans-pdf-container"
            url="pdfUrl"
            scale="1"
            show-toolbar="false"></pdf-viewer>


<!-- Tag autocomplete templates -->
<script type="text/ng-template" id="tag-template">
    <div class="tag-template">
        <div class="right-panel">
            <span>{{$getDisplayText()}}</span>
            <a class="remove-button" ng-click="$removeTag()">&#10006;</a>
        </div>
    </div>
</script>

<script type="text/ng-template" id="autocomplete-template">
    <div class="autocomplete-template">
        <div>
            <span ng-bind-html="$highlight($getDisplayText())">{{text}}</span>
        </div>
    </div>
</script>

