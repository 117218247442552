(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name examination.directive:examList
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="examination">
   <file name="index.html">
   <exam-list></exam-list>
   </file>
   </example>
   *
   */
  angular
    .module('examination')
    .directive('examList', examList);
  
  examList.$inject = ['Examination', 'ExamHelper', '$http', '$log', 'Rlog'];
  
  function examList(Examination, ExamHelper, $http, $log, Rlog) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'examination/exam-list-directive.tpl.html',
      replace: false,
      controllerAs: 'exlCtrl',
      bindToController: true,
      controller: function ($scope) {
        var vm = this;
        // vm.conf = Config.$find('configuration');
        vm.ctrlName = 'ExamlistCtrl';
        vm.patExams = Examination.$search({patientid: vm.patient._id, active: true});
        
        // necessary for table sort and filter
        vm.pexs = vm.patExams;
        
        vm.editExam = editExam;
        vm.spinners = [];
        
        function editExam(exam, index) {
          var closelistener;
          
          ExamHelper.editExam(exam, vm.patient);
          /*
           // catch img edits
           // not needed, edits after consult are on click2save basis
           $scope.$on('imgeditclosed', function () {
           exam.$save();
           });
           */
          
          // Must generate a new pdf on examination edit save'n'quit
          closelistener = $scope.$on('exameditclosed', function () {
            $log.debug('exam-list $on ExamHelper.editExam call exam: ', exam);
            Rlog.debug('examination', 'exam-list-directive', 'exam-list $on exameditclosed called for exam: ' + exam._id, {exam: exam._id});
            
            // TODO: do I need to close the listener - how is it done?
            // closelistener();
            vm.spinners[index] = true;
            // force generate a new pdf
            $http({
              url: '/routes/pdfprinter/exam',
              method: 'GET',
              params: {
                patientid: vm.patient._id,
                examid: exam._id,
                doctype: 'examfinding'
              }
            }).success(function (d) {
              // obsolete, moced to backend
              // exam.docid = d.docid;
              // exam.$save();
              exam.$refresh().$asPromise().then(function (e) {
                $log.debug('exam after refresh: ', e);
                vm.spinners[index] = false;
              });
            });
            
          });
        }
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        
        
        /*          scope.$watch('exlCtrl.patient._id', function (newval, oldval) {
         if (angular.isString(newval) && newval.length > 20) {
         ctrl.patExams = Examination.$search({patientid: newval._id, active: true});
         }
         }, true);*/
        
        /*
         // listen for consult updates
         ctrl.vertxEventBusService.on('consults.update', function (message) {
         var messages = message.split(':');
         console.log('Consult updated: ', message);
         if (ctrl.patient.openConsult._id === messages[0]) {
         ctrl.patient.openConsult.$refresh();
         console.log('refreshed openConsult for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
         }
         if (ctrl.patient.openConsult._id !== messages[0]) {
         console.log('ignored openConsult update for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
         }
         });
         */
      }
    };
  }
}());
