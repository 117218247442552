(function () {
  'use strict';

  /* @ngdoc object
   * @name forms
   * @description
   *
   */
  angular
    .module('forms', [
      'ui.router',
      'ui.tinymce',
      'ui.bootstrap'
    ]);
}());
