(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name config.directive:configEditor
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="config">
   <file name="index.html">
   <config-editor></config-editor>
   </file>
   </example>
   *
   */
  angular
    .module('config')
    .directive('configEditor', configEditor);

  configEditor.$inject = ['Config', 'Printerlist', '$log'];

  function configEditor(Config, Printerlist, $log) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'config/config-editor-directive.tpl.html',
      replace: false,
      // controller: 'ConfigCtrl',
      controllerAs: 'confCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.ctrlName = 'ConfigCtrl';
        vm.Printerlist = Printerlist;
        vm.conf = Config.$find('configuration');
        vm.allprinters = vm.Printerlist.getPrinters();
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */

        // the third argument 'true' ensures deep checking of contents, works better than watchCollection
        scope.$watch('confCtrl.conf.dmspatientcat', function () {
          if (angular.isFunction(ctrl.conf.$save)) {
            ctrl.conf.$save();
            console.log('dmspatientcat saved: ', ctrl.conf.dmspatientcat);
          }

        }, true);

        scope.$watch('confCtrl.conf.dmsgencat', function () {
          if (angular.isFunction(ctrl.conf.$save)) {
            ctrl.conf.$save();
            console.log('dmsgencat saved: ', ctrl.conf.dmsgencat);
          }
        }, true);

      }
    };
  }
}());
