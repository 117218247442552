(function () {
  'use strict';

  /* @ngdoc object
   * @name todo
   * @description
   *
   */
  angular
    .module('todo', [
      'ui.router',
      'knalli.angular-vertxbus'
    ]);
}());
