<div class="form-group">
    <div class="input-group">
        <input type="text" class="form-control" tabindex="{{drsCtrl.tabi}}"
               ng-class="{'dirtyfield': drsCtrl.newdoc.text.length > 0}"
               placeholder="Nachname, Spezialisierung"
               ng-model="drsCtrl.newdoc.text"
               typeahead-on-select="$parent.$dismiss();drsCtrl.showdoctor($item, $model, $label)"
               uib-typeahead="doc as doc.text for doc in drsCtrl.getNames($viewValue)"
               typeahead-loading="loadingNames"
               typeahead-no-results="noResults">
    <span class="input-group-btn">
      <button type="submit"
              ng-class="{'btn-warning': noResults, 'btn-info': !noResults}"
              class="btn" tabindex="-1"><i class="fa fa-search"></i></button>
    </span>
    </div>
</div>

<i ng-show="loadingNames" class="glyphicon glyphicon-refresh"></i>
<div ng-show="noResults">
    <i class="glyphicon glyphicon-remove"></i> Keine Ärzte gefunden
    <span class="btn btn-success" ng-click="$parent.$dismiss();drsCtrl.addDoctor()">Neuen Arzt eingeben</span>
</div>
