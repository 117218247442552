(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:patientList
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-list></patient-list>
   </file>
   </example>
   *
   */
  angular
    .module('patient')
    .directive('patientList', patientList);

  patientList.$inject = ['Patientcount', 'Patient', 'Patientpaged', '$log'];

  function patientList(Patientcount, Patient, Patientpaged, $log) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'patient/patient-list-directive.tpl.html',
      replace: false,
      controllerAs: 'plCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.pageChanged = pageChanged;

        vm.patientlist = Patientpaged.$search();
        Patientcount.$search().$asPromise().then(function (n) {
          console.log('n: ', n);
          vm.totalItems = n[0].count;
        });

        function pageChanged() {
          vm.patientlist.$refresh({page: vm.currentPage});
        }
      }
    };
  }
}());
