
<form>
  <input type="file" ng-file-select="onFileSelect($files)"/>

  <button type="submit" ng-click="frCtrl.upload()" class="btn btn-success">Upload</button>
</form>


<b>Vorschau:</b><br />
<i ng-hide="frCtrl.file.fileSrc">Keine Datei ausgewählt ...</i>
<div ng-show="frCtrl.file.img == true">
  <img ng-src="{{frCtrl.file.fileSrc}}" height="200px"/>
</div>
<div ng-show="frCtrl.file.text == true">
  <div ng-repeat="line in frCtrl.file.textpreview" class="pre">
    {{line}}
  </div>
</div>
<div ng-show="frCtrl.file.nopreview == true">
  Unbekannter Dateityp, keine Vorschau möglich
</div>

<!-- doesn't work TODO
<b>Fortschritt:</b>
<uib-progress value="{{frCtrl.file.progress}}" class="progress-striped active" type="primary"></uib-progress>
</div>
-->
