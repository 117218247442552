(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name doctype.factory:Doctype
   *
   * @description
   *
   */
  angular
    .module('doctype')
    .factory('Doctype', Doctype);

 
  
  Doctype.$inject = ['restmod'];
  function Doctype(restmod) {
    return restmod.model('/routes/doctypes').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true}
    });
  }
  
  
  // Klammerfriedhof
}());
