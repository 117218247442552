  <div class="row">
    <div class="form-group col-md-12">
      <label for="dtid">ID</label>
      <input type="text" ng-model="dteCtrl.doctype._id" class="form-control" id="dtid" tabindex="-1" readonly
             placeholder="ID" ng-blur="dteCtrl.save()">
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-12">
      <label for="name">Name</label>
      <input type="text" ng-model="dteCtrl.doctype.name" class="form-control" id="name" tabindex="1"
             placeholder="Name" ng-blur="dteCtrl.save()">
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-12">
      <label for="filename">Filename</label>
      <input type="text" ng-model="dteCtrl.doctype.filename" class="form-control" id="filename" tabindex="2"
             placeholder="Filename" ng-blur="dteCtrl.save()">
    </div>
  </div>

  <form class="form-vertical" id="editsvform">
    <div class="row">
      <div class="form-group col-md-12">
        <label for="xslt">Stylesheet (Filename, ohne Pfad)</label>
        <input type="text" ng-model="dteCtrl.doctype.stylesheet" class="form-control" id="xslt" tabindex="3"
               placeholder="XSLT Stylesheet Dateiname" ng-blur="dteCtrl.save()">
      </div>
    </div>

  <div class="row">
    <div class="form-group col-md-12">
      <label for="print">Drucker  (Derzeit gewählt: {{dteCtrl.doctype.printer}} )</label>
      <select ng-model="dteCtrl.doctype.printer" class="form-control" id="print"  tabindex="3">
        <option value="{{p.printername}}" ng-repeat="p in dteCtrl.allprinters">{{p.printername}}, {{p.description}}</option>
      </select>
    </div>
  </div>


  <div class=" row">
    <div class="form-group">
      <button class="btn btn-success" type="button" tabindex="9" ng-click="dteCtrl.save(); dteCtrl.close()">Speichern &amp; Schließen</button>
      <button class="btn btn-danger" type="button" tabindex="10" ng-click="dteCtrl.close()">Schließen</button>
    </div>
  </div>
</form>