(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:patientNew
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-new></patient-new>
   </file>
   </example>
   *
   */
  angular
    .module('patient')
    .directive('patientNew', patientNew);

  function patientNew() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'patient/patient-new-directive.tpl.html',
      replace: false,
      controllerAs: 'patCtrl',
      bindToController: true,
      controller: 'PatientCtrl',
      link: function (scope, element, attrs) {
        /*jshint unused:false */
        /*eslint "no-unused-vars": [2, {"args": "none"}]*/
      }
    };
  }
}());
