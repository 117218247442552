<h3>Zeitbestätigung</h3>




<form class="form-vertical" id="aideform" name="aideform">
  <div class="form-group">
    <label for="patient">Patient</label>
    <div class="input-group">
      <input type="text" class="form-control" id="patient"
             autofocus required
             placeholder="Nachname, SVNr, Stichwort"
             ng-model="teCtrl.patient"
             typeahead-on-select="teCtrl.showpatient($item, $model, $label)"
             uib-typeahead="pat as pat.text for pat in teCtrl.getNames($viewValue)"
             typeahead-loading="loadingNames"
             typeahead-no-results="noResults">
      <span class="input-group-btn">
      <button type="submit" class="btn btn-success"><i class="fa fa-search"></i></button>
    </span>
    </div>
  </div>
  
  
  
  <div class="form-group">
    <label for="date">Datum<span uib-tooltip="Pflichtfeld">*</span></label>
    <input type="text" required ng-model="teCtrl.timeevidence.fdate" class="form-control" id="date">
  </div>
  <div class="form-group">
    <label for="aidediag">Start-Uhrzeit (hh:mm)<span uib-tooltip="Pflichtfeld">*</span></label>
    <input type="text" required ng-model="teCtrl.timeevidence.starttime" class="form-control" id="aidediag">
  </div>
  <div class="form-group">
    <label for="totime">End-Uhrzeit (hh:mm)<span uib-tooltip="Pflichtfeld">*</span></label>
    <input type="text" class="form-control" required ng-model="teCtrl.timeevidence.endtime"
              id="totime">
  </div>
  
  <!-- Buttons -->
  <button class="btn btn-success" type="button" ng-click="teCtrl.print()"
          ng-disabled="aideform.$invalid">
    Drucken
  </button>
  <button class="btn btn-danger" type="button" ng-click="teCtrl.clear()">
    Abbrechen
  </button>
</form>
<div class="alert alert-success" ng-show="teCtrl.printed === true">
  <i class="fa fa-check"></i> Erfolgreich gedruckt. Weiter zur <a ui-sref="home">Übersicht</a>.
</div>
<div class="alert alert-error" ng-show="teCtrl.printed === false">
  <i class="fa fa-warning"></i> Druck ist fehlgeschlagen. Weiter zur <a ui-sref="home">Übersicht</a>.
</div>