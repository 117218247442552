(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name helpers.sv.factory:Sv
   *
   * @description
   *
   */
  angular
    .module('helpers.sv')
    .factory('Sv', Sv)
    .factory('Svahead', Svahead);


  Sv.$inject = ['restmod'];
  function Sv(restmod) {
    return restmod.model('/routes/svs').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true}
    });
  }

  Svahead.$inject = ['restmod'];
  function Svahead(restmod) {
    return restmod.model('/routes/svs/svahead');
  }

// klammerfriedhof
}());
