(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:patientLog
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-log></patient-log>
   </file>
   </example>
   *
   */
  angular
    .module('patient')
    .directive('patientLog', patientLog);



  function patientLog(Patient, $log) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'patient/patient-log-directive.tpl.html',
      replace: false,
      controllerAs: 'plogCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.addLogEntry = addLogEntry;
        function addLogEntry() {
          if (!vm.patient.hasOwnProperty('log') || vm.patient.log.constructor !== Array) {
            vm.patient.log = [];
          }
          vm.patient.log.push({created: new Date().toISOString(), entry: vm.patient.newlog});
          vm.patient.newlog = '';
          vm.patient.$save();
        }
      }
    };
  }
}());
