(function () {
  'use strict';

  /* @ngdoc object
   * @name examination
   * @description
   *
   */
  angular
    .module('examination', [
      'ui.router',
      'ngTagsInput',
      'ui.bootstrap.typeahead',
      'smart-table',
      'helpers.doctor',
      'consult',
      'rlog'
    ]);
}());
