(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name patient.directive:checkoutPayment
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <checkout-payment></checkout-payment>
   </file>
   </example>
   *
   */
  angular
    .module('consult')
    .directive('checkoutPayment', checkoutPayment);
  
  checkoutPayment.$inject = ['Consult', 'OpenConsults', 'Cashinvoice', 'Checkinvoice', '$log', '$state',
    '$timeout', 'ConsultRefresh', 'vertxEventBusService', '$cookies', '$window'];
  
  function checkoutPayment(Consult, OpenConsults, Cashinvoice, Checkinvoice, $log, $state,
                           $timeout, ConsultRefresh, vertxEventBusService, $cookies, $window) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'consult/checkout-payment-directive.tpl.html',
      replace: false,
      controllerAs: 'payCtrl',
      bindToController: true,
      controller: function ($scope) {
        var i;
        var vm = this;
        vm.Consult = Consult;
        vm.$state = $state;
        vm.saveInvoice = saveInvoice;
        vm.retryPrinting = retryPrinting;
        vm.redoBilling = redoBilling; // storno invoice and switch to redobilling state
        vm.backToBilling = backToBilling; // if no service items for invoice have been found
        vm.retrieveInvoice = retrieveInvoice;
        vm.retrieveAndFinish = retrieveAndFinish;
        vm.finishConsult = finishConsult;
        vm.reallyFinish = reallyFinish;
        vm.nonzeroitems = nonzeroitems;
        $log.debug('controller function');
  
  
        function nonzeroitems() {
          var i;
          var n = 0;
          if (angular.isObject(vm.patient.openConsult.service) && angular.isArray(vm.patient.openConsult.service.items)) {
            for (i = 0; i < vm.patient.openConsult.service.items.length; i++) {
              if (vm.patient.openConsult.service.items[i].selected === true) {
                n++;
              }
            }
          }
          // $log.debug('Nr of invoice items: ', n);
          return n;
        }
        
        // saveInvoice
        function saveInvoice() {
          var invoice = {};
          var invoiceobj = {};
       
          
          invoice.date = moment().toISOString();
          invoice.fdate = moment(invoice.date).locale('de-at').format('ll');
          invoice.ftime = moment(invoice.date).locale('de-at').format('LTS');
          
          invoice.storno = false;
          invoice.test = false;
          
          if (vm.patient.test === true) {
            invoice.test = true;
          }
          
          // invoice.items = vm.patient.openConsult.service.items;
          // get rid of unselected excess items that clutter up the database
          invoice.items = [];
          for (i = 0; i < vm.patient.openConsult.service.items.length; i++) {
            // $log.debug('item: ', vm.patient.openConsult.service.items[i]);
            if (vm.patient.openConsult.service.items[i].selected === true) {
              $log.debug('selected item: ', vm.patient.openConsult.service.items[i]);
              invoice.items.push(vm.patient.openConsult.service.items[i]);
            }
          }
          
          invoice.netto = vm.patient.openConsult.service.netto;
          invoice.totalbefore = vm.patient.openConsult.service.totalbefore;
          invoice.nettodiscounted = vm.patient.openConsult.service.nettodiscounted;
          invoice.bruttodiscounted = vm.patient.openConsult.service.bruttodiscounted;
          invoice.taxdiscounted = vm.patient.openConsult.service.taxdiscounted;
          invoice.discount = vm.patient.openConsult.service.discount;
          invoice.tax = vm.patient.openConsult.service.tax;
          // discounttaxsum is actually an array with the accumulated tax per tax bracket
          // discounttaxsum kept for backward compatibility, new type is discounttaxarray
          invoice.discounttaxsum = vm.patient.openConsult.service.discounttaxsum;
          invoice.discounttaxarray = vm.patient.openConsult.service.discounttaxsum;
          invoice.discountnettoarray = vm.patient.openConsult.service.discountnettoarray;
          invoice.discountbruttoarray = vm.patient.openConsult.service.discountbruttoarray;
          invoice.total = vm.patient.openConsult.service.total;
          invoice.invoicediagnoses = vm.patient.openConsult.invoicediagnoses;
          // invoice.consultid = vm.patient.openConsult._id;
          
          invoice.paytype = vm.patient.openConsult.service.paytype;
          
          invoiceobj.patient = clean(vm.patient);
          invoiceobj.patient.openConsult = clean(vm.patient.openConsult);
          invoiceobj.patientid = vm.patient._id;
          invoiceobj.consultid = vm.patient.openConsult._id;
          invoiceobj.rootelement = 'patient';
          
          if (vm.patient.openConsult.service.paytype === 'cash' || vm.patient.openConsult.service.paytype === 'card') {
            invoice.paid = true;
            invoice.type = 'Barrechnung';
            invoice.cash = true;
            invoice.paiddate = invoice.date;
            invoiceobj.patient.openConsult.invoice = invoice;
            Cashinvoice.$create(invoiceobj).$asPromise().then(function (inv) {
              $log.debug('Cashinvoice generated: ', inv);
              vm.patient.openConsult.cashinvoiceid = inv._id;
              saveInvoiceState(inv);
            });
          }
          
          if (vm.patient.openConsult.service.paytype === 'bill') {
            invoice.paydue = moment(invoice.date).add(14, 'days');
            invoice.fpaydue = moment(invoice.date).locale('de-at').add(14, 'days').format('ll');
            invoice.paid = false;
            invoice.cash = false;
            invoice.type = 'Zielrechnung';
            invoiceobj.patient.openConsult.invoice = invoice;
            Checkinvoice.$create(invoiceobj).$asPromise().then(function (inv) {
              $log.debug('Checkinvoice generated: ', inv);
              vm.patient.openConsult.checkinvoiceid = inv._id;
              saveInvoiceState(inv);
            });
          }
          
        } // saveInvoice()
        
        
        function retrieveInvoice() {
          retrieve(function () {
            vm.showpdfs = true;
            $log.debug('I am the the show pdf callback');
          });
        }
        
        
        function retrieveAndFinish() {
          retrieve(finishConsult);
        }
        
        
        function retrieve(callback) {
          vm.invoicecreated = true;
          $log.debug('I am the retrieve function');
          $log.debug('chashinvoiceid: ', angular.isString(vm.patient.openConsult.cashinvoiceid));
          if (angular.isString(vm.patient.openConsult.cashinvoiceid)) {
            Cashinvoice.$find(vm.patient.openConsult.cashinvoiceid).$asPromise().then(function (inv) {
              vm.createdinvoice = inv;
              $log.debug('the callback: ', callback);
              $log.debug('the invoice: ', inv);
              callback();
            });
          }
          if (angular.isString(vm.patient.openConsult.checkinvoiceid)) {
            Checkinvoice.$find(vm.patient.openConsult.checkinvoiceid).$asPromise().then(function (inv) {
              vm.createdinvoice = inv;
              callback();
            });
          }
        }
        
        function saveInvoiceState(inv) {
          vm.createdinvoice = inv;
          vm.invoicecreated = true;
          vm.patient.openConsult.invoicecreated = true;
          // $log.debug('oC before statesave: ', vm.patient.openConsult);
          vm.patient.openConsult.$save();
          vm.justcreated = true;
        }
        
        
        function retryPrinting() {
          vm.showpdfs = true;
        }
        
        // storno invoice and return to billing page
        function redoBilling() {
          $log.debug('payCtrl redoBilling entered');
          vm.createdinvoice.storno = true;
          vm.createdinvoice.stornoreason = 'Storno, inhaltlicher Fehler';
          vm.createdinvoice.$save();
          vm.createdinvoice.$destroy();
          vm.invoicecreated = false;
          vm.patient.openConsult.invoicecreated = false;
          if (vm.patient.openConsult.cashinvoiceid) {
            delete vm.patient.openConsult.cashinvoiceid;
          }
          if (vm.patient.openConsult.checkinvoiceid) {
            delete vm.patient.openConsult.checkinvoiceid;
          }
          vm.patient.openConsult.$save();
          vm.patient.checkoutstate = 'redobilling';
          vm.patient.$save();
          $state.go('patientrecord.checkout.redobilling');
        }
        
        // if no service items for invoice have been found
        // switch to redobilling anyway
        function backToBilling() {
          vm.patient.checkoutstate = 'redobilling';
          vm.patient.$save();
          $state.go('patientrecord.checkout.redobilling');
        }
        
        function reallyFinish() {
          
          // flatten exam labels for search
          var examlabels = [];
          var examlabelsPrimary = [];
          var examlabelsSecondary = [];
          var pexams = [];
          var sexams = [];
          var t = 0;
          var s = 0;
          var i = 0;
          
          $log.debug('payCtrl reallyFinish() entered');
          
          if (angular.isArray(vm.patient.openConsult.examinations) && vm.patient.openConsult.examinations.length > 0) {
            for (i = 0; i < vm.patient.openConsult.examinations.length; i++) {
              if (vm.patient.openConsult.examinations[i].active === true) {
                if (vm.patient.openConsult.examinations[i].template.includefindings === false) {
                  t = pexams.length;
                  pexams[t] = {};
                  pexams[t].type = vm.patient.openConsult.examinations[i].type;
                  pexams[t].examid = vm.patient.openConsult.examinations[i]._id;
                  if (angular.isString(vm.patient.openConsult.examinations[i].docid)) {
                    pexams[t].docid = vm.patient.openConsult.examinations[i].docid;
                  }
                  examlabelsPrimary.push(pexams[t]);
                }
                else {
                  s = sexams.length;
                  sexams[s] = {};
                  sexams[s].type = vm.patient.openConsult.examinations[i].type;
                  sexams[s].examid = vm.patient.openConsult.examinations[i]._id;
                  examlabelsSecondary.push(sexams[s]);
                }
                // always push to examlabels for search by exam type
                examlabels.push(vm.patient.openConsult.examinations[i].type);
                
              }
            }
            vm.patient.openConsult.examlabels = examlabels;
            vm.patient.openConsult.examlabelsPrimary = examlabelsPrimary;
            vm.patient.openConsult.examlabelsSecondary = examlabelsSecondary;
            // $log.debug('All Examlabels: ', vm.patient.openConsult.examlabels);
            // $log.debug('Primary: ', vm.patient.openConsult.examlabelsPrimary);
            // $log.debug('Secondary: ', vm.patient.openConsult.examlabelsSecondary);
          }
          
          
          // get rid of state variables
          vm.patient.openConsult.open = false;
          vm.patient.openConsult.service = null;
          vm.patient.openConsult.$save();
          
          vm.patient.consultstate = '';
          vm.patient.checkoutstate = '';
          vm.patient.inconsult = false;
          vm.patient.incheckout = false;
          if (vm.patient.inexam === true) {
            vm.patient.inexam = false;
          }
          vm.patient.$save().$asPromise().then(function () {
            OpenConsults.triggerUpdate();
            $log.debug('oc close, patient saved');
          });
          // TODO: nasty snow flake bug - figure pout why above code won't wok for rudi an needs below snowflake patch
          $timeout(OpenConsults.triggerUpdate(), 5000);
          $state.go('patients');
        }
        
        
        // after all checkout steps have run, finish consult
        // switch to patientslist
        function finishConsult() {
          $log.debug('payCtrl finishConsult() entered');
          $log.debug('created invoice in finish: ', vm.createdinvoice);
          if (angular.isObject(vm.createdinvoice)) {
            vm.createdinvoice.finished = true;
            vm.createdinvoice.$save();
          }
          vm.reallyFinish();
        } // finishconsult
        
        
        function clean(dirty) {
          var cleaned = {};
          var i;
          var reg = /^\$/;
          
          if (angular.isObject(dirty)) {
            for (i in dirty) {
              if (dirty.hasOwnProperty(i)) {
                // $log.debug('dirty i: ', i, dirty[i]);
                switch (i) {
                  case 'consults':
                    break;
                  case 'consult':
                    break;
                  case 'patient':
                    break;
                  case 'examinations':
                    break;
                  case 'openExam':
                    break;
                  case 'openConsult':
                    break;
                  default:
                    if (!reg.test(i)) {
                      cleaned[i] = dirty[i];
                    }
                }
              }
            }
          }
          return cleaned;
        }
      }, // controller
      link: function (scope, element, attrs, ctrl) {
        
        ConsultRefresh.refreshAll(ctrl.patient);
        
        /* ctrl.patient.openExam.$refresh();
         ctrl.patient.openConsult.$refresh().$asPromise().then(function (oc) {
         oc.examinations.$refresh();
         });
         */
        
        // listen for openconsult updates
        vertxEventBusService.on('consults.update', function (message) {
          var messages = message.split(':');
          console.log('Consult updated: ', messages[0]);
          // if (messages[1] !== $cookies.get('vertx-web.session') && ctrl.patient.openConsult._id === messages[0]) {
          if (messages[1] !== $window.sessionStorage.getItem('sprtabid') && ctrl.patient.openConsult._id === messages[0]) {
            ctrl.patient.openConsult.$refresh().$asPromise().then(function () {
              ctrl.patient.openConsult.examinations.$refresh();
            });
            $log.debug(' (cbd) refreshed openconsult for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
          else {
            $log.debug(' (cbd) ignored openconsult change for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
        });
        
      }
      
    };
  }
}());
