(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name labvalue.directive:labvalueEditor
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="labvalue">
       <file name="index.html">
        <labvalue-editor></labvalue-editor>
       </file>
     </example>
   *
   */
  angular
    .module('labvalue')
    .directive('labvalueEditor', labvalueEditor);


  labvalueEditor.$inject = ['Labvalue', '$log'];


  function labvalueEditor(Labvalue, $log) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'labvalue/labvalue-editor-directive.tpl.html',
      replace: false,
      controllerAs: 'labECtrl',
      controller: function (Labvalue, $log) {
        var vm = this;
        vm.name = 'labvalueEditor';
        vm.saveConfig = saveConfig;
        // vm.labconfig = Labvalue.getlabconfig();
        vm.labconfig = Labvalue.$find('labconfiguration');
        vm.loadValues = loadValues;
        vm.addGroup = addGroup;
        vm.removeGroup = removeGroup;
        vm.saveConfig = saveConfig;

        function addGroup() {
          // vm.labconfig.groups.push({name: vm.newgroup, values: []});
          vm.labconfig.groups.splice(0, 0, {name: vm.newgroup, values: []});
          vm.newgroup = '';
          vm.saveConfig();
          // vm.labconfig.$save();
        }

        function removeGroup(index) {
          vm.labconfig.groups.splice(index, 1);
          vm.saveConfig();
          // vm.labconfig.$save();
        }

/*
        function saveConfig() {
          Labvalue.replaceconfig(vm.labconfig);
        }
*/

        function saveConfig() {
          var i;
          var changedgroups = [];

          vm.labconfig.values = uniq(vm.labconfig.values);
          for (i = 0; i < vm.labconfig.groups.length; i++){
            changedgroups.push({
              name: vm.labconfig.groups[i].name,
              values: uniq(vm.labconfig.groups[i].values)
            });
          }
          vm.labconfig.groups = changedgroups;
          vm.labconfig.$save();
        }

        function uniq(a) {
          return a.sort().filter(function (item, pos, ary) {
            return !pos || item !== ary[pos - 1];
          });
        }

        // load and filter autocomplete values
        function loadValues($query) {
          var promise = new Promise(function (resolve, reject) {
            // TODO this is a rather nasty piece of code to generate a promise that is needed in the next step
            if (true) {
              resolve(vm.labconfig.values);
            }
            else {
              reject([]);
            }
          });
          return promise.then(function (values) {
            $log.debug('promise values: ', values);
            var filteredValues = values.filter(function (value) {
              return value.name.toLowerCase().indexOf($query.toLowerCase()) !== -1;
            });
            console.log('filtered values: ', filteredValues);
            return filteredValues;
          });
        }



      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
