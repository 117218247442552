(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name helpers.doctor.directive:doctor
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="helpers.doctor">
       <file name="index.html">
        <doctor></doctor>
       </file>
     </example>
   *
   */
  angular
    .module('helpers.doctor')
    .directive('doctors', doctors);

  doctors.$inject = ['Doctorcount', 'Doctorpaged', 'Doctor', '$uibModal', '$log'];

  function doctors(Doctorcount, Doctorpaged, Doctor, $uibModal, $log) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'helpers/doctor/doctor-directive.tpl.html',
      replace: false,
      controllerAs: 'drCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'doctor';
        vm.editDoctor = editDoctor;
        vm.addDoctor = addDoctor;
        vm.removeDoctor = removeDoctor;
        vm.pageChanged = pageChanged;
        vm.itemspp = 10;

        vm.doctorlist = Doctorpaged.$search();
        Doctorcount.$search().$asPromise().then(function (n) {
          console.log('n: ', n);
          vm.totalItems = n[0].count;
        });

        function pageChanged() {
          vm.doctorlist.$refresh({page: vm.currentPage});
        }


        function editDoctor(doctor) {
          var dialog = $uibModal.open({
            template: '<div class="modalspacer"><doctor-edit doctor="miCtrl.doctor" dialog="miCtrl.$uibModalInstance"></doctor-edit></div>',
            controllerAs: 'miCtrl',
            controller: ['doctor', '$uibModalInstance', function (ldoctor, $uibModalInstance) {
              var vmi = this;
              vmi.doctor = ldoctor;
              vmi.$uibModalInstance = $uibModalInstance;
            }],
            resolve: {
              doctor: function () {
                return doctor;
              },
              dialog: function () {
                return dialog;
              }
            },
            size: 'lg'
          });
        }


        function addDoctor() {
          var dialog = $uibModal.open({
            template: '<div class="modalspacer"><doctor-edit doctor="miCtrl.doctor" dialog="miCtrl.$uibModalInstance"></doctor-edit></div>',
            controllerAs: 'miCtrl',
            controller: ['doctor', '$uibModalInstance', function (ldoctor, $uibModalInstance) {
              var vmi = this;
              vmi.doctor = ldoctor;
              vmi.$uibModalInstance = $uibModalInstance;
            }],
            resolve: {
              doctor: function () {
                // return vm.doctorlist.$create();  // does not work - no $create on pagedDoctors!
                return Doctor.$create();
              },
              dialog: function () {
                return dialog;
              }
            },
            size: 'lg'
          });

          dialog.result.then(function () {
            vm.doctorlist.$refresh();
          }, function () {});
        }

        function removeDoctor(doctor) {
          doctor.active = false;
          doctor.$save();
          pageChanged();
        }

      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
