

<div class="btn btn-success" ng-click="drCtrl.addDoctor()">Neuen Arzt anlegen</div>

<h3>Liste</h3>
<table class="table table-striped">
    <thead>
    <tr>
        <th>Nachname/Institut</th>
        <th>Vorname</th>
        <th>Spezialisierung</th>
        <th>Ort</th>
        <th></th>
    </tr>
    </thead>
    <tr ng-repeat="doctor in drCtrl.doctorlist">
        <td>{{doctor.lastname}} {{doctor.institute}} </td>
        <td>{{doctor.firstname}}</td>
        <td>{{doctor.speciality}}</td>
        <td>{{doctor.city}}</td>
        <td>
            <button class="btn btn-primary" ng-click="drCtrl.editDoctor(doctor)">Editeren</button>
            <button class="btn btn-danger" ng-click="drCtrl.removeDoctor(doctor)">Löschen</button>
        </td>
    </tr>
</table>

<uib-pagination total-items="drCtrl.totalItems"
                items-per-page="{{drCtrl.itemspp}}"
                max-size="10"
                first-text="Erste"
                last-text="Letzte"
                next-text="N&auml;chste"
                previous-text="Vorige"
                boundary-links="true"
                rotate="false"
                ng-model="drCtrl.currentPage"
                ng-change="drCtrl.pageChanged()"></uib-pagination>
