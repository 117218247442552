(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name consult.directive:examtherapies
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="consult">
       <file name="index.html">
        <examtherapies></examtherapies>
       </file>
     </example>
   *
   */
  angular
    .module('consult')
    .directive('examtherapies', examtherapies);

  function examtherapies() {
    return {
      restrict: 'E',
      scope: {
        consult: '='
      },
      templateUrl: 'consult/examtherapies-directive.tpl.html',
      replace: false,
      controllerAs: 'etCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'examtherapies';

        vm.popover = 'examtherapies_popver.tpl.html';
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
