(function () {
  'use strict';
  // if you plan on recycling a fixed grid, all aptient states must be sperate states not child states!
  // a state name such as patient.new denotes a child of parent. view names a re lative to the the parent then!
  // the parent must exist, or all children will fail
  // oder is not important!
  angular
    .module('patient')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {

    // Patientenliste
    $stateProvider.state('patients', {
      url: '/patients',
      views: {
        main: {
          template: '<aside ui-view="leftbar" class="hidden-sm hidden-xs hidden-md col-lg-2 col-xl-3"></aside>' +
          '<div ui-view="patientmain" class="col-sm-12 col-md-12 col-lg-10 col-xl-9"><patient-list>' +
          '</patient-list></div>'
        },
        rightupper: {template: '<todowidget></todowidget>'},
        rightlower: {template: '<chat></chat>'}
      }
    });


    // show modal for searching and entering new patients
    $stateProvider.state('newpatient', {
      url: '/newpatient',
      onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {

        console.log('onenter newpatient state: ', $state);
        $uibModal.open({
          template: '<div class="modalspacer"><patient-newsearch dialog="miCtrl.$uibModalInstance"></patient-newsearch></div>',
          controllerAs: 'miCtrl',
          controller: ['$uibModalInstance', function ($uibModalInstance) {
            var vmi = this;
            vmi.$uibModalInstance = $uibModalInstance;
          }],
          size: 'lg'
        });
      }]
    });

    // show modal for patient searching
    $stateProvider.state('patientsearch', {
      url: 'patientsearch',
      onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
        $uibModal.open({
          template: '<div class="modalspacer"><form class="form-vertical"><patient-search></patient-search></form></div>'
        });
      }]
    });


    // Patientenakte
    $stateProvider.state('patientrecord', {
      url: '/patient/:patientid',
      views: {
        main: {template: '<patient-record-view></patient-record-view>'},
        rightupper: {template: '<todowidget></todowidget>'},
        rightlower: {template: '<chat></chat>'}
      }
    });


    // Patientenakte Historie
    $stateProvider.state('patientrecord.history', {
      url: '/consult',
      views: {
        'pr-details': {template: '<consult-list patient="patrCtrl.patient"></consult-list>'}
      }
    });

    // Patientenakte Medikamente
    $stateProvider.state('patientrecord.drugs', {
      url: '/drugs',
      views: {
        'pr-details': {template: '<patient-drug-view patient="patrCtrl.patient"></patient-drug-view>'}
      }
    });

    // Patientenakte abgeschlossene Untersuchungen
    $stateProvider.state('patientrecord.examinations', {
      url: '/examinations',
      views: {
        'pr-details': {template: '<exam-list patient="patrCtrl.patient"></exam-list>'}
      }
    });

    // Patietenakte Dokumente
    $stateProvider.state('patientrecord.documents', {
      url: '/documents',
      views: {
        'pr-details': {templateUrl: 'document/document.tpl.html'}
      }
    });
    
    // Patietenakte Tracker
    $stateProvider.state('patientrecord.trackers', {
      url: '/trackers',
      views: {
        'pr-details': {template: '<trackerlist patient="patrCtrl.patient"></trackerlist>'}
      }
    });

    // Patientenakte Stammdaten
    $stateProvider.state('patientrecord.basicdata', {
      url: '/basicdata',
      views: {
        'pr-details': {template: '<patient-edit patient="patrCtrl.patient"></patient-edit>'}
      }
    });

    // Patientenakte aktuelle Konsultation Automatische Unterseiten Auswahl
    $stateProvider.state('patientrecord.current', {
      url: '/current',
      views: {
        'pr-details': {template: '<current-consult-container patient="patrCtrl.patient"></current-consult-container>'}
      },
      params: {
        patientid: null
      },
      resolve: {
        patient: function (Patient, $stateParams, $state) {
          var route;
          console.log('entered patientrecord.current resolve, $stateParams: ', $stateParams);
          Patient.$new($stateParams.patientid).$fetch().$then(function (patient) {
            console.log('entered patientrecord.current resolve, return pat: ', patient);
            console.log('consultstate bool: ', patient.inconsult);
            console.log('checkoutstate bool: ', patient.incheckout);
            console.log('examstate bool: ', patient.inexam);
            console.log('consultstate: ', patient.consultstate);
            console.log('checkoutstate: ', patient.checkoutstate);
          
            if (patient.inconsult === false) {
              // redirect to patient consult history
              route = 'patientrecord.history';
            }
            else if (!patient.hasOwnProperty('consultstate')) {
              // force consultstate to empty
              patient.consultstate = '';
              patient.$save();
              // redirect to patient consult history
              route = 'patientrecord.history';
            }
            else if (patient.consultstate === null || patient.consultstate === '') {
              // redirect to patient consult history
              route = 'patientrecord.history';
            }
            else {
              switch (patient.consultstate) {
                case 'consult':
                  route = 'patientrecord.current.consult';
                  break;
                case 'exam':
                  route = 'patientrecord.current.exam';
                  break;
                case 'examfiles':
                  route = 'patientrecord.current.examfiles';
                  break;
                default:
                  route = 'patientrecord.current.consult';
                  break;
              }
            }
            console.log('patientrecord.current route: ', route);
            $state.go(route, {patientid: $stateParams.patientid, patient: patient});
            return patient;
          });
        }
      }
    });

    // Patientenakte aktuelle Konsultation - Konsultation
    $stateProvider.state('patientrecord.current.consult', {
      url: '/consult',
      views: {
        'cur-details': {template: '<current-consult patient="consCtrl.patient"></current-consult>'}
      },
      onEnter: function () {
        console.log('patientrecord.current.consult entered');
      }
    });


    // Patientenakte aktuelle Konsultation - aktuelle Untersuchung
    $stateProvider
      .state('patientrecord.current.exam', {
        url: '/exam',
        views: {
          'cur-details': {template: '<exameditor patient="consCtrl.patient"></exameditor>'}
        },
        onEnter: function () {
          console.log('patientrecord.current.exam entered');
        }
      });

    // Patientenakte aktuelle Konsultation - aktuelle Untersuchung Bilderauswahl
    $stateProvider.state('patientrecord.current.examfiles', {
      url: '/files',
      views: {
        'cur-details': {template: '<file-picker patient="consCtrl.patient"></file-picker>'}
      },
      onEnter: function () {
        console.log('patientrecord.current.exam.files entered');
      }
    });


    /*
     // obsolet


     $stateProvider
     .state('patientrecord.current.checkout', {
     url: '/checkout',
     abstract: true,
     views: {
     'cur-details': {templateUrl: 'consult/checkout.tpl.html'}
     },
     onEnter: function () {
     console.log('patientrecord.current.checkout entered');
     }
     });

     $stateProvider
     .state('patientrecord.current.checkout.findings', {
     url: '/findings',
     views: {
     checkout: {template: '<checkout-findings patient="consCtrl.patient"></checkout-findings>'}
     },
     onEnter: function () {
     console.log('patientrecord.current.checkout.findings entered');
     }
     });

     $stateProvider
     .state('patientrecord.current.checkout.aides', {
     url: '/aides',
     views: {
     checkout: {template: '<checkout-aides patient="consCtrl.patient"></checkout-aides>'}
     },
     onEnter: function () {
     console.log('patientrecord.current.checkout.aides entered');
     }
     });


     $stateProvider
     .state('patientrecord.current.checkout.referrals', {
     url: '/referrals',
     views: {
     checkout: {template: '<checkout-referrals patient="consCtrl.patient"></checkout-referrals>'}
     },
     onEnter: function () {
     console.log('patientrecord.current.checkout.referrals entered');
     }
     });

     $stateProvider
     .state('patientrecord.current.checkout.drugs', {
     url: '/drugs',
     views: {
     checkout: {template: '<checkout-drugs patient="consCtrl.patient"></checkout-drugs>'}
     },
     onEnter: function () {
     console.log('patientrecord.current.checkout.drugs entered');
     }
     });


     */

    // Patientenakte - aktuelle Rechnungspositionen
    $stateProvider.state('patientrecord.billing', {
      url: '/billing',
      views: {
        'pr-details': {template: '<checkout-billing patient="patrCtrl.patient"></checkout-billing>'}
      },
      onEnter: function () {
        console.log('patientrecord.billing entered');
      }
    });

    // Patientenakte - aktuelle Rechnungspositionen
    $stateProvider.state('patientrecord.error', {
      url: '/error',
      views: {
        'pr-details': {template: '<consult-error></consult-error>'}
      },
      onEnter: function () {
        console.log('patientrecord.error entered');
      },
      params: {
        patient: {},
        patientid: ''
      }
    });



    $stateProvider.state('patientrecord.checkout', {
      url: '/checkout',
      views: {
        'pr-details': {template: '<ui-view name="checkout-details"></ui-view>'}
      },
      onEnter: function (Patient, $stateParams, $state) {
        console.log('patientrecord.checkout switcher entered');

        // note to self: failed at avoiding to Patient.$new($stateParams.patientid).$fetch()
        Patient.$new($stateParams.patientid).$fetch().$asPromise().then(function (pat) {
          var route;
          if (!pat.hasOwnProperty('checkoutstate') || pat.checkoutstate === null) {
            pat.checkoutstate = 'ccdocs';
            route = 'patientrecord.checkout.ccdocs';
          }
          else {
            // route = 'patientrecord.checkout.' + pat.checkoutstate;
            switch (pat.checkoutstate) {
              case 'ccdocs': route = 'patientrecord.checkout.ccdocs'; break;
              case 'payment': route = 'patientrecord.checkout.payment'; break;
              case 'redobilling': route = 'patientrecord.checkout.redobilling'; break;
              default: route = 'patientrecord.checkout.ccdocs'; break;
            }

          }
          console.log('patientrecord.checkout route choosen: ', route);
          $state.go(route, {patient: pat});


          if (!pat.hasOwnProperty('consultstate') || pat.consultstate === null) {
            pat.consultstate = 'consult';
            route = 'patientrecord.current.consult';
          }
          else {

          }


        });
      },
      controller: 'ConsultCtrl',
      controllerAs: 'consCtrl'
    });


    $stateProvider.state('patientrecord.checkout.ccdocs', {
      url: '/ccdocuments',
      views: {
        'checkout-details': {template: '<checkout-documents patient="patrCtrl.patient"></checkout-documents>'}
      },
      onEnter: function () {
        console.log('patientrecord.checkout.ccdocs entered');
      }
    });


    $stateProvider.state('patientrecord.checkout.payment', {
      url: '/payment',
      views: {
        'checkout-details': {template: '<checkout-payment patient="patrCtrl.patient"></checkout-payment>'}
      },
      onEnter: function () {
        console.log('patientrecord.checkout.payment entered');
      }
    });

    $stateProvider.state('patientrecord.checkout.redobilling', {
      url: '/redobilling',
      views: {
        'checkout-details': {template: '<checkout-billing patient="patrCtrl.patient"></checkout-billing>'}
      },
      onEnter: function () {
        console.log('patientrecord.checkout.redobilling entered');
      }
    });

  }
}());
