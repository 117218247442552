
<h3 ng-bind="vm.title"></h3>

<div class="alert alert-info" ng-show="vm.cashinvoices.length === 0">
  <h4>Keine Barrechnungen mit diesen Merkmalen gefunden</h4>
</div>

<table class="table table-striped"
       ng-hide="vm.cashinvoices.length === 0">
  <tr>
    <th>RNr</th>
    <th>Datum</th>
    <th>Patient</th>
    <th align="right">Brutto</th>
    <th align="right">Steuer</th>
    <th align="center">PDF</th>
    <th align="center">Beleg</th>
    <th align="center">Aktion</th>
  </tr>
  <tr ng-repeat="inv in vm.cashinvoices"  ng-class="{testinv: inv.test}">
    <td ng-bind="inv.invoicenr">inv.invoicenr</td>
    <td ng-bind="inv.fdate"></td>
    <td><a ui-sref="patientrecord.history({patientid: inv.patientid})"   ng-class="{testinv: inv.test}">{{inv.patientid}}</a></td>
    <td align="right">{{inv.total | currency : '€' : 2 }}</td>
    <td align="right">{{inv.tax | currency : '€' : 2 }}</td>
    <td align="center">
      <a href="/routes/pdfreader/{{inv.doctorinvoiceid}}" target="_blank">
        <i class="fa fa-file-pdf-o text-primary"></i>
      </a>
    </td>
    <td align="center">
      <a href="/routes/pdfreader/{{inv.receiptid}}" target="_blank">
        <i class="fa fa-file-pdf-o text-success"></i></a>
      <a href="/routes/pdfreader/{{inv.stornoreceiptid}}"
         ng-show="inv.stornoreceiptid"
         target="_blank">
        <i class="fa fa-file-pdf-o text-danger"></i></a>
    </td>
    <td align="center">
      <span ng-hide="inv.storno === true" class="btn btn-danger" ng-click="vm.storno(inv)">Stornieren</span>
      <span ng-show="inv.storno === true && inv.test === true">test, </span>
      <span ng-show="inv.storno === true">storniert</span>
    </td>
  </tr>
</table>
<uib-pagination total-items="vm.totalItems"
                items-per-page="20"
                max-size="10"
                first-text="Erste"
                last-text="Letzte"
                next-text="N&auml;chste"
                previous-text="Vorige"
                boundary-links="true"
                rotate="false"
                ng-model="vm.currentPage"
                ng-change="vm.pageChanged()"></uib-pagination>
</div>
