(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name examination.directive:currentExamsList
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="examination">
   <file name="index.html">
   <current-exams-list></current-exams-list>
   </file>
   </example>
   *
   */
  angular
    .module('examination')
    .directive('currentExamsList', currentExamsList);


  currentExamsList.$inject = ['ExamHelper', '$log'];

  function currentExamsList(ExamHelper, $log) {
    return {
      restrict: 'EA',
      templateUrl: 'examination/current-exams-list-directive.tpl.html',
      replace: false,
      scope: {
        exams: '=',
        patient: '='
      },
      controllerAs: 'celCtrl',
      bindToController: true,
      controller: function celCtrl($scope) {
        var vm = this;
        vm.name = 'currentExamsList';
        vm.editExam = editExam;

        vm.deleteExam = ExamHelper.deleteExam;
        vm.editImage = ExamHelper.editImage;
  
  
        function editExam(exam) {
          ExamHelper.editExam(exam, vm.patient);

          // catch img edits
          $scope.$on('imgeditclosed', function () {
            exam.$save().$asPromise().then(function () {
              vm.patient.openConsult.examinations.$refresh();
            });
          });

          // catch exam saves
          $scope.$on('exameditclosed', function () {
            $log.debug('cel: scope on ExamHelper.editExam call exam: ', exam);
            vm.patient.openConsult.examinations.$refresh();
          });

        }
        
      }
    };
  }
}());
