(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name closing.directive:closing
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="closing">
   <file name="index.html">
   <closing></closing>
   </file>
   </example>
   *
   */
  angular
    .module('finance.closing')
    .directive('closing', closing);

  closing.$inject = ['Closing', '$log', '$http'];

  function closing(Closing, $log, $http) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'finance/closing/closing-directive.tpl.html',
      replace: false,
      controllerAs: 'clCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'clCtrl';
        vm.startdate = new Date(moment().year(), 0, 1).toISOString();
        // vm.enddate = new Date().toISOString();
        vm.enddate = moment().add(1, 'days').toISOString();
        vm.rangequery = rangequery;
        vm.lastmonth = lastmonth;
        vm.freeinterval = freeinterval;

        function lastmonth() {
          var lastm = moment().subtract(1, 'month');
          var start = lastm.startOf('month').toISOString();
          var end = lastm.endOf('month').toISOString();
          $log.debug('Startdate: ', start);
          $log.debug('enddate: ', end);
          var cashname = 'umsatz_bar_karte-' + lastm.format('YYYY_MM') + '.csv';
          var checkname = 'umsatz_zielrechnung-' + lastm.format('YYYY_MM') + '.csv';
          rangequery(start, end, '/routes/fiscaljournal', cashname);
          rangequery(start, end, '/routes/checkjournal', checkname);
        }

        function freeinterval() {
          var start = moment.utc(vm.startdate).toISOString();
          var end = moment.utc(vm.enddate).toISOString();
          $log.debug('Startdate: ', start);
          $log.debug('enddate: ', end);
          var cashname = 'umsatz_bar_karte.csv';
          var checkname = 'umsatz_zielrechnung.csv';
          rangequery(start, end, '/routes/fiscaljournal', cashname);
          rangequery(start, end, '/routes/checkjournal', checkname);
          vm.start = null;
          vm.end = null;
        }

        function rangequery(start, end, url, filename) {
          var query = {
            created: {
              $gte: start,
              $lt: end
            }
          };
          var qq = {
              $gte: start,
              $lt: end
            };

          $log.debug('query: ', JSON.stringify(qq));
          var tmp = url + '?created=' + encodeURIComponent(JSON.stringify(qq));
          $log.debug('tmp: ', tmp);
          // $log.debug('query: ', JSON.stringify(query));
          $http.get(tmp, {})
          .success(function (data, status, headers, config) {
            var anchor = angular.element('<a/>');
            anchor.attr({
              href: 'data:attachment/csv;charset=utf-8,' + encodeURI(data),
              target: '_blank',
              download: filename
            })[0].click();
          })
          .error(function (data, status, headers, config) {
            $log.error('Ficaljournal filegeneration and export failed');
            $log.error(status);
            $log.error(headers);
            $log.error(data);
          });
        }


      }
    };
  }
}());
