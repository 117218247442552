<!-- Attention! This html.tpl file belongs to ExamHelper factory, not to the directive! -->
<div class="modalspacer">
  <h3>Exameditor</h3>
  <form>
    <h4 ng-model="eeCtrl.exam.template.displayname"></h4>

    <!-- fieldgroups -->
    <div ng-repeat="fieldgroup in eeCtrl.exam.template.fieldgroups">
      <fieldgroup efieldgroup="fieldgroup"></fieldgroup>
    </div>

    <!-- Biopsien -->
    <div ng-show="eeCtrl.exam.template.biopsy" class="panel panel-default">
      <div class="panel-heading">Biopsien</div>
      <div class="panel-body">

        <!-- Biopsiefelder -->
        <div class="form-group">
          <bfield efield="bf" bvalues="eeCtrl.exam.template.biopsy.values"
                  ng-init="bf.label  = 'PE ' + ($index + 1) + ' ' + eeCtrl.exam.template.type"
                  ng-repeat="bf in eeCtrl.exam.template.biopsy.biopsies">
          </bfield>
        </div>
        <!-- Buttons -->
        <div class="form-group">
          <button type="submit" class="btn btn-success"
                  ng-click="eeCtrl.addBiopsyField()">
            <i class="fa fa-plus"></i> Biopsiefeld hinzufügen
          </button>
          <button type="submit" class="btn btn-primary"
                  ng-click="eeCtrl.printBiopsyLabels()">
            <i class="fa fa-print"></i> Etiketten drucken
          </button>
          <div ng-show="eeCtrl.exam.template.biopsy.labelspdf" class="btn btn-success">
            <i class="fa fa-file-pdf-o"></i> Etiketten als PDF
          </div>
        </div>
      </div>

    </div>

    <!-- special fields -->
    <field efield="eeCtrl.exam.template.diagnosis"
           ng-show="eeCtrl.exam.template.diagnosis"></field>
    <field efield="eeCtrl.exam.template.recommendation"
           ng-show="eeCtrl.exam.template.recommendation"></field>
    <field efield="eeCtrl.exam.template.comment"
           ng-show="eeCtrl.exam.template.comment"></field>
    
    
    <div ng-show="eeCtrl.exam.files.length" class="row">
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 imgcontainer"
           ng-repeat="img in eeCtrl.exam.files | filter: {mimetype: 'image/jpeg'} |  orderBy: '_id'">

        <img class="img-responsive" src="/routes/files/filereader/{{img.fileid}}"
             uib-tooltip="{{img.label}}"
             ng-click="eeCtrl.editImage(img)">
        <div class="imgcheckbox"><input type="checkbox" ng-model="img.include" ng-click="eeCtrl.exam.$save()"></div>
      </div>
    </div>

    <div class="clearfix spacer-top">&nbsp;</div>

    <div class="form-group clearfix">
      <!-- This was a stupid idea, clients could save without generating a new pdf-->
      <!--<button type="submit" class="btn btn-success" ng-click="eeCtrl.exam.$save()">Speichern</button>-->
      <button type="submit" class="btn btn-primary" ng-click="eeCtrl.saveAndQuit()">Speichern und Beenden</button>
      <button type="submit" class="btn btn-danger" ng-click="eeCtrl.cancel()">Beenden ohne Speichern</button>
    </div>

    <div style="min-height: 2000px">&nbsp;</div>
  </form>
</div>