(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name patient.directive:patientRecordView
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-record-view></patient-record-view>
   </file>
   </example>
   *
   */
  angular
    .module('patient')
    .directive('patientRecordView', patientRecordView);
  
  
  patientRecordView.$inject = ['Patient', '$stateParams', '$state', 'vertxEventBusService',
    '$log', 'Config', 'hotkeys', '$uibModal', 'File', 'Checkinvoice', '$cookies', '$window'];
  
  function patientRecordView(Patient, $stateParams, $state, vertxEventBusService,
                             $log, Config, hotkeys, $uibModal, File, Checkinvoice, $cookies, $window) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'patient/patient-record-view-directive.tpl.html',
      replace: false,
      // controller: 'PatientCtrl',
      controllerAs: 'patrCtrl',
      bindToController: true,
      controller: function ($scope) {
        var vm = this;
        vm.vertxEventBusService = vertxEventBusService;
        vm.patientid = $stateParams.patientid;
        // TODO: really test this unrwesolved refresh chain
        Patient.$find(vm.patientid).$then(function (pat) {
          vm.patient = pat;
          vm.patient.reminders.$refresh().$then(function () {
            $log.debug('patrCtrl patient reminders loaded');
          });
  
          if (pat.inconsult === true) {
            vm.patient.openConsult.$refresh().$then(function () {
              vm.patient.openConsult.examinations.$refresh();
            });
            if (pat.inexam === true) {
              vm.patient.openExam.$refresh();
            }
          }
        });
        
        vm.addPhoto = addPhoto;
        vm.enterPaidDate = enterPaidDate;
        
        
        /*
         if (vm.patient.inconsult) {
         vm.patient.openConsult.$refresh().$asPromise().then(function () {
         vm.patient.openConsult.examinations.$refresh();
         });
         vm.patient.openExam.$refresh();
         }
         */
        
        
        vm.openInvoices = Checkinvoice.$search({patientid: $stateParams.patientid, paid: false, storno: false});
        vm.unsignedScans = File.$search({patientid: $stateParams.patientid, signedoff: false});
        
        
        function addPhoto() {
          if (vm.newphoto && vm.newphoto.fileid) {
            File.$find(vm.newphoto.fileid).$asPromise().then(function (image) {
              image.patientid = vm.patient._id;
              image.$save();
              vm.patient.image = '/routes/files/filereader/' + image.fileid;
              vm.patient.$save();
            });
            vm.newphoto = {};
          }
        }
        
        // vm.conf = Config.getConfig();
        // vm.patient.printconf = vm.conf.printconf;
        // obsolete!
        Config.$find('configuration').$asPromise().then(function (conf) {
          vm.conf = conf;
          // vm.patient.printconf = conf.printconf;
        });
        
        
        function enterPaidDate(invoice) {
          $uibModal.open({
            templateUrl: 'finance/invoice/invoiceDateTpl.html',
            controllerAs: 'miCtrl',
            controller: ['invoice', '$uibModalInstance', function (inv, $uibModalInstance) {
              var vmi = this;
              vmi.invoice = inv;
              vmi.dialog = $uibModalInstance;
              
              // TODO: options are completyl ignored, figure out how to enable maxDate
              vmi.dateoptions = {
                maxDate: new Date(),
                showWeeks: true
              };
              vmi.setDate = setDate;
              
              function setDate() {
                vmi.invoice.paiddate = moment(vmi.paiddate).toISOString();
                vmi.invoice.fpaiddate = moment(vmi.paiddate).locale('de-at').format('ll');
                vmi.invoice.paid = true;
                // $log.debug('Paiddate: ', vmi.paiddate);
                // $log.debug('Invoice Paiddate: ', vmi.invoice.paiddate);
                $log.debug('Invoice FPaiddate: ', vmi.invoice.fpaiddate);
                vmi.invoice.$save().$asPromise().then(function () {
                  vm.openInvoices.$refresh();
                });
                vmi.dialog.close();
              }
            }],
            resolve: {
              invoice: function () {
                return invoice;
              }
            },
            size: 'sm'
          });
          
          
        }
        
        
        hotkeys.bindTo($scope)
          .add({
            combo: 'alt+m',
            description: 'Medikamente',
            callback: function () {
              $state.go('patientrecord.drugs');
            }
          })
          .add({
            combo: 'alt+a',
            description: 'Aktuelle Konsultation',
            callback: function () {
              $state.go('patientrecord.current');
            }
          })
          .add({
            combo: 'alt+d',
            description: 'Dokumente',
            callback: function () {
              $state.go('patientrecord.documents');
            }
          })
          .add({
            combo: 'alt+u',
            description: 'Untersuchungen',
            callback: function () {
              $state.go('patientrecord.examinations');
            }
          })
          .add({
            combo: 'alt+p',
            description: 'Patienten ToDo',
            callback: function () {
              
              $uibModal.open({
                template: '<div class="modalspacer"><patient-reminder patient="miCtrl.patient"></patient-reminder></div>',
                size: 'modal-sm',
                controller: ['$uibModalInstance', 'patient', ModalInstanceCtrl],
                controllerAs: 'miCtrl',
                resolve: {
                  patient: function () {
                    return vm.patient;
                  }
                }
              });
              
              function ModalInstanceCtrl($uibModalInstance, patient) {
                var vmi = this;
                vmi.patient = patient;
                vmi.$modalInstance = $uibModalInstance;
                
                vmi.cancel = function () {
                  vmi.$modalInstance.close();
                };
                
                vmi.ok = function () {
                  vmi.patient.$save();
                  vmi.$modalInstance.close();
                };
              }
              
            }
          })
          .add({
            combo: 'alt+h',
            description: 'Historie/Verlauf',
            callback: function () {
              $state.go('patientrecord.history');
            }
          });
        
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        
        // listen for patient updates
        ctrl.vertxEventBusService.on('patients.update', function (message) {
          var messages = message.split(':');
          console.log('Patient updated: ', message);
          // if (messages[1] !== $cookies.get('vertx-web.session') && ctrl.patientid === messages[0]) {
          if (messages[1] !== $window.sessionStorage.getItem('sprtabid') && ctrl.patientid === messages[0]) {
            ctrl.patient.$refresh().$asPromise().then(function () {
              if (ctrl.patient.inexam === false && angular.isObject(ctrl.patient.openConsult) && angular.isString(ctrl.patient.openConsult._id)) {
                console.log('(prv) refreshed patient openConsult: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
                ctrl.patient.openConsult.$refresh().$asPromise().then(function (c) {
                  console.log('(prv) refreshed patient openConsult->examinations: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
                  ctrl.patient.openConsult.examinations.$refresh();
                });
              }
            });
            console.log('(prv) refreshed patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
          if (ctrl.patientid !== messages[0]) {
            console.log('(prv) ignored patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
        });
        
        /*
         
         // this is not healthy!
         // listen for consult updates
         ctrl.vertxEventBusService.on('consults.update', function (message) {
         var messages = message.split(':');
         console.log('Consult updated: ', message);
         if (ctrl.patient.openConsult._id === messages[0]) {
         ctrl.patient.openConsult.$refresh();
         console.log('refreshed openConsult for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
         }
         if (ctrl.patient.openConsult._id !== messages[0]) {
         console.log('ignored openConsult update for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
         }
         });*/
        
        
        // watch for changes in patient diagnoses
        // the third argument 'true' ensures deep checking of contents, works better than watchCollection
        scope.$watch('patrCtrl.patient.diagnoses', function (patdiagnoses) {
          if (angular.isArray(patdiagnoses)) {
            ctrl.patient.flatdiagnoses = [];
            if (ctrl.patient.diagnoses && ctrl.patient.diagnoses.length > 0) {
              ctrl.patient.diagnoses.forEach(function (kw) {
                ctrl.patient.flatdiagnoses.push(kw.key);
              });
            }
            ctrl.patient.$save();
          }
        }, true);
        
        
        /*
         // watch for changes in patient trackers
         // the third argument 'true' ensures deep checking of contents, works better than watchCollection
         scope.$watch('patrCtrl.patient.trackers', function (pat) {
         ctrl.patient.$save();
         }, true);
         */
        
        
        // watch for changes in patient keywords
        /* scope.$watch('patrCtrl.patient.keywords', function (embed) {
         var kw;
         ctrl.patient.flatkeywords = [];
         ctrl.patient.keywords.forEach(function (kw){
         ctrl.patient.flatkeywords.push(kw.key);
         });
         ctrl.patient.$save();
         }, true); */
        
        // watch for patient portrait photo
        scope.$watch('patrCtrl.newphoto', function () {
          console.log('patrCtrl.newphoto watch triggered');
          ctrl.addPhoto();
        }, true);
        
        
      } // link function
    };
  }
}());
