<form class="form-vertical" id="editsvform">
  <div class="row">
    <div class="form-group col-md-3">
      <label for="short">Kürzel</label>
      <input type="text" ng-model="sveCtrl.sv.short" class="form-control" id="short" tabindex="1"
             placeholder="Kürzel" ng-blur="sveCtrl.save()">
    </div>
    <div class="form-group col-md-9">
      <label for="name">Name (vollständiger)</label>
      <input type="text" ng-model="sveCtrl.sv.name" class="form-control" id="name"  tabindex="2"
             placeholder="Vorname" ng-blur="sveCtrl.save()">
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-3">
      <label for="phone1">Telefon</label>
      <input type="text" ng-model="sveCtrl.sv.phone" class="form-control" id="phone1"  tabindex="3"
             placeholder="Telefon" ng-blur="sveCtrl.save()">
    </div>
    <div class="form-group col-md-3">
      <label for="fax">Fax</label>
      <input type="text" ng-model="sveCtrl.sv.fax" class="form-control" id="fax" tabindex="4"
             placeholder="Fax" ng-blur="sveCtrl.save()">
    </div>
    <div class="form-group col-md-6">
      <label for="email">E-Mail</label>
      <input type="text" ng-model="sveCtrl.sv.email" class="form-control" id="email"  tabindex="5"
             placeholder="E-Mail" ng-blur="sveCtrl.save()">
    </div>
  </div>
  
  <div class=" row">
    <div class="form-group col-md-6">
      <label for="address">Adresse</label>
      <input type="text" ng-model="sveCtrl.sv.address" class="form-control" id="address"  tabindex="6"
             placeholder="Adresse" ng-blur="sveCtrl.save()">
    </div>
    <div class="form-group col-md-6">
      <zipcode zip="sveCtrl.sv.postcode" city="sveCtrl.sv.city" ti="7" ti2="8"></zipcode>
    </div>
  </div>
  <div class=" row">
    <div class="form-group">
      <button class="btn btn-success" type="button" tabindex="9" ng-click="sveCtrl.save(); sveCtrl.close()">Speichern &amp; Schließen</button>
      <button class="btn btn-danger" type="button" tabindex="10" ng-click="sveCtrl.close()">Schließen</button>
    </div>
  </div>
</form>