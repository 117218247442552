<form class="form-inline">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h3 class="panel-title">Todo&nbsp;
        <span class="label label-default" ng-model="todoCtrl.count"></span>
      </h3>
    </div>
    <div class="panel-body">
      
      
      <!-- Neues Todo -->
      <form class="form-vertical">
        <div class="input-group">
          <input type="text" ng-model="vm.newitem.label" class="form-control" id="newtodo" placeholder="Neues Todo">
          <div uib-dropdown class="input-group-btn" ng-class="gtClass">
            <button uib-dropdown-toggle type="button" class="btn btn-secondary">
              <span ng-show="vm.newitem.duestr">{{vm.newitem.duestr}}</span>
              <span ng-hide="vm.newitem.duestr">Fällig  </span><span class="caret"></span>
            </button>
            <!-- override bootstrap .dropdown-menu { min-width: 160px } -->
            <ul class="dropdown-menu" style="min-width: 0;">
              <li><a ng-click="vm.calcdue(0, 'Sofort')">Sofort</a></li>
              <li><a ng-click="vm.calcdue(1, 'Morgen')">Morgen</a></li>
              <li><a ng-click="vm.calcdue(2, 'Übermorgen')">Übermorgen</a></li>
              <li><a ng-click="vm.calcdue(7, '1 Woche')">1 Woche</a></li>
              <li><a ng-click="vm.calcdue(14, '2 Wochen')">2 Wochen</a></li>
              <li><a ng-click="vm.calcdue(30, '1 Monat')">1 Monat</a></li>
              <li><a ng-click="vm.calcdue(90, '3 Monate')">3 Monate</a></li>
              <li><a ng-click="vm.calcdue(180, '6 Monate')">6 Monate</a></li>
              <li><a ng-click="vm.calcdue(365, '1 Jahr')">1 Jahr</a></li>
            </ul>
            <button class="btn btn-success" type="submit" ng-click="vm.add()">
              <i class="fa fa-plus"></i>
            </button>
          </div>
          </span>
        </div>
      </form>
      
      <!-- Todos gruppiert anzeigen -->
      <h4>Fällig &amp; Überfällig <span class="label label-xs label-danger">{{vm.todos.overdue.length}}</span> </h4>
      <!--<div class="alert alert-info" ng-hide="vm.todos.overdue.length > 0">Derzeit keine überfälligen Todos</div>-->
      <div class="spacer-top" ng-repeat="item in vm.todos.overdue | orderBy: 'due'">
        <label style="color: #bb3333">
          <input type="checkbox"
                 ng-model="item.done"
                 ng-change="vm.save(item)"/>&nbsp;{{item.due | moment: 'l' }}, {{item.label}} ({{item.user}})</label>
      </div>
      <h4>Offen <span class="label label-xs label-warning">{{vm.todos.upcoming.length}}</span> </h4>
      <!--<div class="alert alert-info" ng-hide="vm.todos.upcoming.length > 0">Derzeit keine offenen Todos</div>-->
      <div class="spacer-top" ng-repeat="item in vm.todos.upcoming | orderBy: 'due' ">
        <label style="color: #ccbb33">
          <input type="checkbox"
                 ng-model="item.done"
                 ng-change="vm.save(item)"/>&nbsp;{{item.due | moment: 'l' }}, {{item.label}} ({{item.user}})</label>
      </div>
      
      <h4>Zuletzt abgeschlossen <span class="label label-xs label-success">{{vm.todos.lastcompleted.length}}</span> </h4>
      <div class="spacer-top" ng-repeat="item in vm.todos.lastcompleted | orderBy: 'due' ">
        <label style="color: #33cc33">
          <input type="checkbox"
                 ng-model="item.done"
                 ng-change="vm.save(item)"/>&nbsp;{{item.due | moment: 'l' }}, {{item.label}} ({{item.user}})</label>
      </div>
    </div>
    <!-- panelbody -->
  </div>
  <!-- panel -->
</form>
