(function () {
  'use strict';

  /* @ngdoc object
   * @name cashbook
   * @description
   *
   */
  angular
    .module('finance.cashbook', [
      'ui.router'
    ]);
}());
