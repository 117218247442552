(function () {
  'use strict';

  /* @ngdoc object
   * @name utils.keywords
   * @description
   *
   */
  angular
    .module('utils.keywords', [
      'ui.router',
      'as.sortable'
    ])
    .filter('myindex', function () {
      return function (array, index) {
        var i = 0;
        if (!index) {
          index = 'myindex';
        }
        for (i = 0; i < array.length; ++i) {
          array[i][index] = i;
        }
        return array;
      };
    });
}());
