(function () {
  'use strict';

  angular
    .module('pdfprinter')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('pdfprinter', {
        url: '/pdfprinter',
        templateUrl: 'pdfprinter/pdfprinter.tpl.html',
        controller: 'PdfprinterCtrl',
        controllerAs: 'pdfprinter'
      });
  }
}());
