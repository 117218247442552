<!-- Beware!  ocCtrl.allOpenConsults actually contains PATIENTS who are in consult (patient.inconsult == true )
so we are iterating over patients here! -->
<div class="alert alert-info" ng-show="ocCtrl.allOpenConsults.length === 0">
  Keine Konsultationen<br>
<span class="btn btn-primary" ng-click="ctrl.allOpenConsults.$refresh()">Neu laden</span>
</div>
<div ng-repeat="pat in ocCtrl.allOpenConsults" ng-hide="ocCtrl.allOpenConsults.length === 0">
  <div class="row">
    <div class="col-xs-4 col-sm-2">
      <a ui-sref="patientrecord.current.consult({patientid: pat._id })">
        <img src="{{pat.image}}" class="img-responsive" alt="{{pat.firstname}} {{pat.lastname}}">
      </a>
    </div>
    <div class="col-xs-8 col-sm-10">
      <h4>
        <!--<a ui-sref="patientrecord.current.consult({patientid: pat._id })">
          <span ng-show="pat.titlefront">{{pat.titlefront}} </span>{{pat.firstname}} {{pat.lastname}}<span
          ng-show="pat.titleback">, {{pat.titleback}} </span>,
          *&nbsp;{{pat.birthdate}}
        </a>-->
        <a ng-click="ocCtrl.switchToPatient(pat)">
          <span ng-show="pat.titlefront">{{pat.titlefront}} </span>{{pat.firstname}} {{pat.lastname}}<span
          ng-show="pat.titleback">, {{pat.titleback}} </span>,
          *&nbsp;{{pat.birthdate}}
        </a>
      </h4>
      <span ng-repeat="(key, value) in pat.keywords">
        <span class="label label-{{value}}">{{key}}</span>
      </span>
      <span ng-repeat="(key, value) in pat.openConsult.keywords">
        <span class="label label-{{value}}">{{key}}</span>
      </span>
    </div>
  </div>
</div>
