(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name share.controller:ShareCtrl
   *
   * @description
   *
   */
  angular
    .module('share')
    .controller('ShareCtrl', ShareCtrl);

  ShareCtrl.$inject = ['Share', '$uibModal'];

  function ShareCtrl(Share, $uibModal) {
    var vm = this;
    vm.ctrlName = 'ShareCtrl';
    vm.Share = Share;
    vm.addShare = addShare;
    vm.allshares = vm.Share.$search();
    vm.editShare = editShare;

    function addShare() {
      vm.newshare.active = true;
      vm.newshare.created = new Date().toISOString();
      vm.newshare.isopen = false;
      vm.allshares.$create(vm.newshare);
      vm.newshare = {};
    }

    function editShare(share) {
      $uibModal.open({
        templateUrl: 'shareedittempl',
        size: 'lg',
        controller: ['$uibModalInstance', 'share', ShareEditCtrl],
        controllerAs: 'sheCtrl',
        resolve: {
          share: function () {
            return share;
          }
        }
      });


    }
  }

  function ShareEditCtrl($uibModalInstance, share) {
    var vm = this;
    vm.share = share;
    vm.$uibModalInstance = $uibModalInstance;


  }


}());
