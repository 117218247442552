(function () {
  'use strict';

  /* @ngdoc object
   * @name dms.scan
   * @description
   *
   */
  angular
    .module('dms.scan', [
      'ui.router',
      'pdf',
      'ngTagsInput',
      'ui.bootstrap.typeahead'
    ]);
}());
