<h2>File Test page</h2>
<p>{{file.ctrlName}}</p>

<file-demo></file-demo>





<h3>Filereader Demo</h3>
<filereader file="foo"></filereader>


<h3>Conventional File upload Test</h3>
<h4>posttestserver.com</h4>
<form enctype="multipart/form-data" action="http://posttestserver.com/post.php" method="post">
  <input type="file" name="file1">
  <input type="submit"></input>
</form>

<h4>vertx upload</h4>
<form enctype="multipart/form-data" action="/routes/upload" method="post">
  <input type="file" name="file2" >
  <input type="submit"></input>
</form>


<h4>posttestserver.com Image capture</h4>
<form enctype="multipart/form-data" action="http://posttestserver.com/post.php" method="post">
  <input type="file" name="image" accept="image/*" capture>
  <input type="submit"></input>
</form>


<h4>vertx upload Image capture</h4>
<form enctype="multipart/form-data" action="/routes/upload" method="post">
  <input type="file" name="image2" accept="image/*" capture>
  <input type="submit"></input>
</form>
