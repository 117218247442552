<div ng-repeat="patient in patCtrl.patients">
  <div class="row">
    <div class="col-sm-{{col}}">
      <div class="thumbnail">
        <img src="{{patient.image}}" class="img-responsive" alt="{{patient.firstname}} {{patient.lastname}}">

        <div class="caption">
          <h3><span ng-show="patient.titlefront">{{patient.titlefront}} </span>{{patient.firstname}}
            {{patient.lastname}}<span ng-show="titleback">, {{patient.titleback}}</span>
          </h3>

          <div ng-repeat="(key, value) in patient.keywords">
            <span class="label label-{{value}}">{{key}}</span>
          </div>
          <p ng-show="{{buttons}}">
            <a href="#" class="btn btn-default" role="button">Akte</a>
            <a href="#" class="btn btn-primary" role="button">Aufrufen</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
