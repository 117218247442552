(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name consult.directive:imglabeler
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="consult">
       <file name="index.html">
        <imglabeler></imglabeler>
       </file>
     </example>
   *
   */
  angular
    .module('consult')
    .directive('imglabeler', imglabeler);

  imglabeler.$inject = ['$log', '$rootScope'];

  function imglabeler($log, $rootScope) {
    return {
      restrict: 'EA',
      scope: {
        img: '=',
        dialog: '='
      },
      templateUrl: 'consult/imglabeler-directive.tpl.html',
      replace: false,
      controllerAs: 'ilCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        var backup = angular.copy(vm.img);
        if (!backup.hasOwnProperty('include')) {
          backup.include = false;
        }
        vm.name = 'imglabeler';
        vm.save = save;
        vm.cancel = cancel;

        $log.debug('imglabel directive controller function');

        function save() {
          $log.debug('img before: ', vm.img);
          $rootScope.$broadcast('imgeditclosed');
          vm.dialog.close();
        }

        function cancel() {
          $log.debug('img before: ', vm.img);
          vm.img.label = backup.label;
          vm.img.include = backup.include;
          $log.debug('img after: ', vm.img);
          vm.dialog.close();
        }
      }
    };
  }
}());
