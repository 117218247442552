<!-- This is a very important hack: DO NOT REMOVE this totally pointless invisible bindings -->
<!-- Removal of these bindings will cause exam and consult close detection to fail -->
<span style="visibility: hidden">Consult Status patient: <span ng-bind='docCtrl.patient.inconsult'></span> </span>
<span style="visibility: hidden">Consult Status openConsult: <span
    ng-bind='docCtrl.patient.openConsult.open'></span> </span>
<span style="visibility: hidden">Exam Status patient: <span ng-bind='docCtrl.patient.inexam'></span> </span>
<span style="visibility: hidden">Exam Status openExam: <span ng-bind='docCtrl.patient.openExam.open'></span> </span>
<span style="visibility: hidden">openExam type: <span
    ng-bind='docCtrl.patient.openExam.template.displayname'></span> </span>
<span style="visibility: hidden">Consultstate: <span ng-bind='docCtrl.patient.consultstate'></span> </span>
<span style="visibility: hidden">Checkoutstate: <span ng-bind='docCtrl.patient.checkoutstate'></span> </span>


<!-- not in consult -->
<div ng-hide="docCtrl.patient.inconsult == true && docCtrl.patient.openConsult.open === true">
  <div class="alert alert-info">
    <h4>Die Konsultation wurde von anderer Stelle beendet</h4>
    <span class="btn btn-primary"
          ng-click="docCtrl.$state.go('patientrecord.history')">Zur Patienten-Historie wechseln</span>
  </div>
</div>


<!-- In consult, not already in checkout -->
<div ng-show="docCtrl.patient.inconsult == true && docCtrl.patient.openConsult.open === true">


  <!--
  <h3>Termine</h3>
  <form>
    <div class="form-group">
      <div class="radio">
        <label for="nap1" class="radio-inline">
          <input type="radio" value="1"
                 ng-model="docCtrl.patient.openConsult.nextappointment" id="nap1">1 Woche</label>
        <label for="nap2" class="radio-inline">
          <input type="radio" value="2"
                 ng-model="docCtrl.patient.openConsult.nextappointment" id="nap2">2 Wochen</label>
        <label for="nap3" class="radio-inline">
          <input type="radio" value="4"
                 ng-model="docCtrl.patient.openConsult.nextappointment" id="nap3">1 Monat</label>
        <label for="nap3" class="radio-inline">
          <input type="radio" value="8"
                 ng-model="docCtrl.patient.openConsult.nextappointment" id="nap4">2 Monate</label>
      </div>
      <div class="radio">
        <label for="nap3" class="radio-inline">
          <input type="radio" value="13"
                 ng-model="docCtrl.patient.openConsult.nextappointment" id="nap5">3 Monate</label>
        <label for="nap3" class="radio-inline">
          <input type="radio" value="26"
                 ng-model="docCtrl.patient.openConsult.nextappointment" id="nap6">6 Monate</label>
        <label for="nap3" class="radio-inline">
          <input type="radio" value="52"
                 ng-model="docCtrl.patient.openConsult.nextappointment" id="nap7">1 Jahr</label>
      </div>
    </div>
  </form>
  -->

  <h3>Befundempänger</h3>
  <div class="row">
    <div class="col-xs-12">
      <div class="form-group form-vertical">
        <input type="radio" ng-model="docCtrl.recipient" value="patient" required="required"
               ng-change="docCtrl.setRecipient()">&nbsp;Patient
        <div data-ng-show="docCtrl.patient.practdoctor.fname">
        <input type="radio" ng-model="docCtrl.recipient" value="practdoctor" ng-required="!docCtrl.recipient"
               ng-change="docCtrl.setRecipient()"
               ng-show="docCtrl.patient.practdoctor">&nbsp;Hausarzt: {{docCtrl.patient.practdoctor.fname}}</div>
        <div ng-show="docCtrl.patient.refdoctor.fname">
        <input type="radio" ng-model="docCtrl.recipient" value="refdoctor" required="required"
               ng-change="docCtrl.setRecipient()"
               ng-show="docCtrl.patient.refdoctor">&nbsp;Überweisender Arzt: {{docCtrl.patient.refdoctor.fname}}
        </div>
      </div>
    </div>
  </div>


  <h3>Dokumentenübersicht</h3>

  <!-- Überweisungen -->
  <div class="row">
    <div class="col-xs-12 col-sm-9">
      <input type="checkbox" ng-init="docCtrl.consultDocs.referral = docCtrl.checkReferrals()"
             ng-model="docCtrl.consultDocs.referral">&nbsp;

      <!-- <input type="checkbox" ng-init="docCtrl.consultDocs.referral = true"
             ng-model="docCtrl.consultDocs.referral">&nbsp;-->
    <span
        ng-show="docCtrl.patient.openConsult.referrals.length > 0">{{docCtrl.patient.openConsult.referrals.length}}</span>
      <span ng-hide="docCtrl.patient.openConsult.referrals.length > 0">Keine</span>
      &nbsp;Überweisung(en)
    </div>
    <div class="col-xs-12 col-sm-3" style="text-align: right">
      <a ng-show="docCtrl.patient.openConsult.referrals.length > 0" class="btn btn-info" uib-tooltip="PDF Vorschau"
         target="_blank"
         href="/routes/pdfprinter/consultpreview?patientid={{docCtrl.patient._id}}&consultid={{docCtrl.patient.openConsult._id}}&doctype=referral">
        <i class="fa fa-eye"></i>
      </a>
      <button type="button" ng-click="docCtrl.addReferralModal(docCtrl.patient)"
              class="btn btn-primary" uib-tooltip="Überweisungen editieren/hinzufügen">
        <i class="fa fa-edit"></i>
      </button>
    </div>
  </div>


  <!-- Hilfsmittelverordnungen -->
  <div class="row">
    <div class="col-xs-12 col-sm-9">
      <input type="checkbox" ng-init="docCtrl.consultDocs.aide = docCtrl.checkAides()"
             ng-model="docCtrl.consultDocs.aide">&nbsp;
      <span ng-show="docCtrl.patient.openConsult.aides.length > 0">{{docCtrl.patient.openConsult.aides.length}} </span>
      <span ng-hide="docCtrl.patient.openConsult.aides.length > 0">Keine </span>
      Hilfsmittelverordnungen(en)
    </div>
    <div class="col-xs-12 col-sm-3" style="text-align: right">
      <a ng-show="docCtrl.patient.openConsult.aides.length > 0" class="btn btn-info" uib-tooltip="PDF Vorschau"
         target="_blank"
         href="/routes/pdfprinter/consultpreview?patientid={{docCtrl.patient._id}}&consultid={{docCtrl.patient.openConsult._id}}&doctype=aide">
        <i class="fa fa-eye"></i>
      </a>
      <button type="button" ng-click="docCtrl.addAideModal(docCtrl.patient)"
              class="btn btn-primary" uib-tooltip="Überweisungen editieren/hinzufügen">
        <i class="fa fa-edit"></i>
      </button>
    </div>
  </div>
  <!-- Rezepte -->
  <div class="row">
    <div class="col-xs-12 col-sm-9">
      <input type="checkbox" ng-init="docCtrl.checkPrescription()"
             ng-model="docCtrl.consultDocs.prescription">&nbsp;{{docCtrl.patient.activedrugs}}
      Medikamenteverordnung(en)/Rezepte(en)
    </div>
    <div class="col-xs-12 col-sm-3" style="text-align: right">
      <a ng-show="docCtrl.patient.activedrugs > 0" class="btn btn-info" uib-tooltip="PDF Vorschau" target="_blank"
         href="/routes/pdfprinter/consultpreview?patientid={{docCtrl.patient._id}}&consultid={{docCtrl.patient.openConsult._id}}&doctype=prescription">
        <i class="fa fa-eye"></i>
      </a>
      <button type="button" ng-click="docCtrl.editPrescription(docCtrl.patient)"
              class="btn btn-primary" uib-tooltip="Medikamente editieren/hinzufügen">
        <i class="fa fa-edit"></i>
      </button>
    </div>
  </div>
  <!-- Gesamtbefund -->
  <div class="row">
    <div class="col-xs-12 col-sm-9">
      <input type="checkbox" ng-init="docCtrl.consultDocs.consultfinding = true"
             ng-model="docCtrl.consultDocs.consultfinding">&nbsp;Gesamtbefund/Arztbrief
    </div>
    <div class="col-xs-12 col-sm-3" style="text-align: right">
      <a class="btn btn-info" uib-tooltip="PDF Vorschau" target="_blank"
         href="/routes/pdfprinter/consultpreview?patientid={{docCtrl.patient._id}}&consultid={{docCtrl.patient.openConsult._id}}&doctype=consultfinding">
        <i class="fa fa-eye"></i>
      </a>
      <!--<button type="button" ng-click="docCtrl.addReferralModal"
              class="btn btn-primary" uib-tooltip="Überweisungen editieren/hinzufügen">
        <i class="fa fa-edit"></i>
      </button>-->
    </div>
  </div>

  <!-- Untersuchungen -->

  <div class="row" ng-repeat="exam in docCtrl.patient.openConsult.examinations | filter: {active: true}">
    <div class="col-xs-12 col-sm-9">
      <input type="checkbox" ng-init="docCtrl.examDocs[$index] = true"
             ng-model="docCtrl.examDocs[$index]">&nbsp;Untersuchungsbefund {{exam.type}}
    </div>
    <div class="col-xs-12 col-sm-3" style="text-align: right">
      <a class="btn btn-info" uib-tooltip="PDF Vorschau" target="_blank"
         href="/routes/pdfprinter/exampreview?patientid={{docCtrl.patient._id}}&examid={{exam._id}}&doctype=examfinding">
        <i class="fa fa-eye"></i>
      </a>
      <button type="button" ng-click="docCtrl.editExam(exam)"
              class="btn btn-primary" uib-tooltip="Untersuchung editieren">
        <i class="fa fa-edit"></i>
      </button>
    </div>
  </div>

  <!-- Rechnungsvorschau -->
  <div class="row">
    <div class="col-xs-12 col-sm-9">
      <!--<input type="checkbox" ng-init="docCtrl.consultDocs.invoice = true"
             ng-model="docCtrl.consultDocs.consultfinding">-->Rechnungsvorschau
    </div>
    <div class="col-xs-12 col-sm-3" style="text-align: right">
      <a class="btn btn-info" uib-tooltip="PDF Vorschau" target="_blank"
         href="/routes/pdfprinter/consultpreview?patientid={{docCtrl.patient._id}}&consultid={{docCtrl.patient.openConsult._id}}&doctype=invoicepreview">
        <i class="fa fa-eye"></i>
      </a>
    </div>
  </div>

  <!-- Buttons -->
  <button class="btn btn-success" ng-click="docCtrl.printSome()" data-ng-disabled="!docCtrl.recipient">Ausgewählte Dateien Drucken &amp; Konsultation
    abschließen
  </button>
  <button class="btn btn-danger" ng-click="docCtrl.saveAll()"  data-ng-disabled="!docCtrl.recipient"
          uib-tooltip="Dateien können nachträglich aus der Patientenakte -&gt; Dokumente Reiter gedruckt werden">
    Konsultation ohne Drucken abschließen
  </button>


</div>
<div style="min-height:3cm">&nbsp;</div>