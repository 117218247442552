(function () {
  'use strict';

  /* @ngdoc object
   * @name examtemplate
   * @description
   *
   */
  angular
    .module('examtemplate', [
      'ui.router',
      'ui.tree',
      'ui.bootstrap',
      'ngTagsInput'
    ]);
}());
