<h3>Textverarbeitung</h3>

<form class="form-vertical" id="textform" name="textform">
  
  <!-- Patient -->
  <div class="form-group">
    <label for="patient">Patient</label>
    <div class="input-group">
      <input type="text" class="form-control" id="patient" tabindex="3"
             required
             placeholder="Patienten Nachname, SVNr, Stichwort"
             ng-model="textCtrl.patient"
             typeahead-on-select="textCtrl.showpatient($item, $model, $label)"
             uib-typeahead="pat as pat.text for pat in textCtrl.getNames($viewValue)"
             typeahead-loading="loadingNames"
             typeahead-no-results="noResults">
      <span class="input-group-btn">
      <button type="submit" class="btn btn-success"><i class="fa fa-search"></i></button>
    </span>
    </div>
  </div>
  
  <!-- Empfänger/Arzt -->
  <div class="form-group">
    <label for="refto" uib-tooltip="Wird kein Arzt ausgewählt, geht der Brief an den Patienten">
      <span>
        Empfänger / Arztauswahl
      </span>
      <span uib-tooltip="Pflichtfeld"></span>
    </label>
    <!-- Arztsuche -->
    <doctor-search doctorresult="textCtrl.doctor" ti="4" id="refto"></doctor-search>
  </div>
  
  <!-- Betreff -->
  <div class="form-group">
    <label for="subject">Betreff</label>
      <input type="text" class="form-control" id="subject" tabindex="5"
             required placeholder="Betreff" ng-model="textCtrl.newtext.subject">
  </div>
  
  
  <div class="form-group">
    <div><label>Inkludieren</label></div>
    <div class="checkbox">
      <label>
        <input type="checkbox" ng-model="textCtrl.newtext.getdiagnoses" ng-true-value="true" ng-false-value="false"> Diagnosen
      </label>
      <label>
        <input type="checkbox" ng-model="textCtrl.newtext.getdrugs" ng-true-value="true" ng-false-value="false"> Medikamente
      </label>
      <label>
        <input type="checkbox" ng-model="textCtrl.newtext.getrisks" ng-true-value="true" ng-false-value="false"> Risikofaktoren
      </label>
      <label>
        <input type="checkbox" ng-model="textCtrl.newtext.getnotes" ng-true-value="true" ng-false-value="false"> Notizen
      </label>
      
<!--      <label>
        <input type="checkbox" ng-model="textCtrl.newtext.gettodos" ng-true-value="true" ng-false-value="false"> Patienten-Todos
      </label>-->
    </div>
    <div class="checkbox">
      <label>
        <input type="checkbox" ng-model="textCtrl.newtext.greetings" ng-true-value="true" ng-false-value="false"> Schlußformel (Mit freundlichen Grüßen ...)
      </label>
      </div>
    
  </div>
  
  
  <!-- WYSIWYG tinymce editor -->
    <textarea ui-tinymce="textCtrl.tinymceOptions" ng-model="textCtrl.newtext.xhtml"></textarea>
    <!--<textarea ui-tinymce ng-model="textCtrl.newtext.text"></textarea>-->
    
  
  <!-- Buttons -->
  <div class="spacer-top">
  <button class="btn btn-success" ng-click="textCtrl.print()">Drucken</button>
  <button class="btn btn-danger" ng-click="textCtrl.clear()">Abbrechen</button>
  </div>
  </form>

<div class="alert alert-success spacer-top" ng-show="textCtrl.printed === true"><i class="fa fa-check-circle-o fa-2x"></i>&nbsp;Erfolgreich gedruckt</div>
<div class="alert alert-danger spacer-top" ng-show="textCtrl.printed === false"><i class="fa fa-warning fa-2x"></i>&nbsp;Druck fehlgeschlagen</div>