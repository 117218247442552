<h3>File Demo</h3>
<div ng-repeat="share in fileCtrl.allshares">
  <div class="btn btn-primary" ng-bind="share.name" ng-click="fileCtrl.demoStart(share)"></div>
</div>


<div ng-show="fileCtrl.openGrabbers != []">
  <h3>Offene Grabber</h3>

  <div ng-repeat="share in fileCtrl.openGrabbers">
    <div class="btn btn-primary" ng-bind="share.name"
         ng-click="fileCtrl.demoStop(share,{filetype: share.filetype},$index)"></div>
  </div>
</div>


<h4>Files bearbeitet</h4>
<div ng-repeat="f in fileCtrl.files | filter: { processed: true }">
  {{f.fileid}}
</div>


<h4>Files in Befund</h4>
<div ng-repeat="f in fileCtrl.files | filter: { include: true }">
  {{f.fileid}}
</div>

<h4>Files gelöscht</h4>
<div ng-repeat="f in fileCtrl.files | filter: { keep: false }">
  {{f.fileid}}
</div>


<h4>Files behalten</h4>
<div ng-repeat="f in fileCtrl.files | filter: { keep: true }">
  {{f.fileid}}
</div>
