(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:patientCurrentDrugs
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-current-drugs></patient-current-drugs>
   </file>
   </example>
   *
   */
  angular
    .module('drug')
    .directive('patientCurrentDrugs', patientCurrentDrugs);

  function patientCurrentDrugs() {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'drug/patient-current-drugs-directive.tpl.html',
      replace: false,
      // controller: 'PatientDrugsCtrl',
      controllerAs: 'pcdCtrl',
      bindToController: true,
      controller: function CurrentDrugsController() {
        var vm = this;
        vm.deactivateDrug = deactivateDrug;
        vm.updateDrugColor = updateDrugColor;
        vm.datecheck = datecheck;


        function updateDrugColor(drug, color) {
          var index = vm.patient.drugs.indexOf(drug);
          console.log('drug: ', drug);
          if (index > -1) {
            vm.patient.drugs[index].color = color;
            calculateActivedrugs();
            vm.patient.$save();
          }
        }

        function datecheck(drug) {
          return !moment(drug.endDate).isBefore(moment());
        }

        function deactivateDrug(drug) {
          var index = vm.patient.drugs.indexOf(drug);
          if (index > -1) {
            console.log('Drug to be deactivated: ', vm.patient.drugs[index]);
            vm.patient.drugs[index].active = false;
            vm.patient.drugs[index].print = false;
            vm.patient.drugs[index].endDate = moment().toISOString();
          }
          calculateActivedrugs();
          vm.patient.$save();
        }

        // calculate activedrugs helper variable
        function calculateActivedrugs() {
          var i;
          vm.patient.activedrugs = 0;
          for (i = 0; i < vm.patient.drugs.length; i++) {
            if (vm.patient.drugs[i].active === true && vm.patient.drugs[i].foreign === false) {
              vm.patient.activedrugs += 1;
            }
          }
        }



      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
