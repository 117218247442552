<div class="row">
    <div class="col-xs-12 col-md-6 col-lg-4">
        <h3>In Behandlung</h3>
        <open-consults></open-consults></div>

    <div class="col-xs-12 col-md-6 col-lg-4">
        <h3>Wartelisten</h3>
    <div class="alert alert-info">Derzeit keine Patienten auf der Warteliste</div>
        <!--<waitinglists></waitinglists>-->
    </div>
    <div class="col-xs-12 col-md-6 col-lg-4">
        <all-reminder></all-reminder>
    </div>
</div>

<div class="row">
    <div class="col-xs-12 col-md-6"></div>
    <div class="col-xs-12 col-md-6"></div>
</div>
