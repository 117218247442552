(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name examination.factory:Printfindings
   *
   * @description
   *
   */
  angular
    .module('examination')
    .factory('Printfindings', Printfindings);


  Printfindings.$inject = ['$window', '$log', 'Pdfprinter', 'Config', '$state', 'Doctor'];

  function Printfindings($window, $log, Pdfprinter, Config, $state, Doctor) {
    var PrintfindingsBase = {};
    var conf = Config.$find('configuration');
    PrintfindingsBase.printFindings = printFindings;
    


    // create printobj
    // function printFindings(patient) {
    function printFindings(patient, exam) {
      var printobj = {};
      $log.debug('Printfindings.printFindings entered');

      // clean to avoid restmod call stack size exceeded errors
      // printobj.patient.openExam = clean(patient.openExam);
      printobj.patient = clean(patient);

      $log.debug('exam vor clean: ', exam);

      // always assign exam to patient.openExam because xslt expects it to be
      printobj.patient.openExam = clean(exam);

      if (angular.isArray(printobj.patient.openExam.files)) {
        printobj.patient.openExam.files = cleanfiles(printobj.patient.openExam.files);
      }

      $log.debug('exam nach clean: ', exam);
      // if not called form consult, consultid will be undefined
      if (patient.hasOwnProperty('openConsult')) {
        printobj.consultid = patient.openConsult._id;
      }
      printobj.patientid = patient._id;
      printobj.rootelement = 'patient';
      printobj.examid = patient.openExam._id;
      printobj.type = 'Befund-' + exam.template.type;
      printobj.filename = 'befund-' + exam.template.type + '.pdf';
      printobj.foptemplate = 'exam.xslt';

      // resolve doctor references, where available
      // prefer refdoctor over practdoctor
      // do nothing if neither is found (xslt will insert patient data as recepient)
      if (angular.isObject(patient.refdoctor) && angular.isObject(patient.practdoctor)) {
        Doctor.$find(patient.refdoctor._id).$asPromise().then(function (doc) {
          $log.debug('refdoc: ', doc);
          printobj.patient.refdoctor = clean(doc);
          delete printobj.patient.practdoctor;
          buildFindings(printobj, patient);
        });
      }
      if (angular.isObject(patient.refdoctor) && !angular.isObject(patient.practdoctor)) {
        Doctor.$find(patient.refdoctor._id).$asPromise().then(function (doc) {
          $log.debug('refdoc: ', doc);
          printobj.patient.refdoctor = clean(doc);
          buildFindings(printobj, patient);
        });
      }
      if (angular.isObject(patient.practdoctor) && !angular.isObject(patient.refdoctor)) {
        Doctor.$find(patient.practdoctor._id).$asPromise().then(function (doc) {
          $log.debug('practdoc: ', doc);
          printobj.patient.practdoctor = clean(doc);
          buildFindings(printobj, patient);
        });
      }
      if (!angular.isObject(patient.refdoctor) && !angular.isObject(patient.practdoctor)) {
        $log.debug('nodoc! ');
        buildFindings(printobj, patient);
      }
    }


    // make pdf from printobj
    function buildFindings(printobj, patient) {
      var newprinter;

      $log.debug('patient/exam in build findings: ', patient);
      newprinter = Pdfprinter.$build(printobj);
      newprinter.$save().$asPromise().then(function (doc) {
        patient.openExam.docid = doc.docid;
        $log.debug('Exam Findings PDF: ', $window.location.origin + '/routes/pdfreader/' + doc.docid);

        printobj.docid = doc.docid;
        printobj.printername = conf.printers.findingsprinter;
        printobj.time = moment().toISOString();
        if (!patient.openConsult.hasOwnProperty('checkoutdocs')) {
          patient.openConsult.checkoutdocs = [];
        }
        $log.debug('patient/exam in build findings before oC save: ', patient);
        patient.openConsult.checkoutdocs.push(printobj);
        // patient.openConsult.$save();
        // cloesed earlier!
        // patient.openExam.open = false;
        patient.openConsult.$save().$asPromise().then(function () {
          patient.openConsult.examinations.$refresh();
        });


        // this doen't make sense!
        /*patient.openExam.$save().$asPromise().then(function () {
          patient.openConsult.examinations.$refresh();
        });*/
      });

    }
    
    // clean structures of restmod cruft and selfreferencing circles
    function clean(dirty) {
      var cleaned = {};
      var i;
      var reg = /^\$/;

      if (angular.isObject(dirty)) {
        for (i in dirty) {
          if (dirty.hasOwnProperty(i)) {
            switch (i) {
              case 'consults': break;
              case 'consult': break;
              case 'patient': break;
              case 'examinations': break;
              case 'openExam': break;
              case 'openConsult': break;
              default:
                if (!reg.test(i)) {
                  cleaned[i] = dirty[i];
                }
            }
          }
        }
      }
      return cleaned;
    }

    // pull in files, but get rid of restmod cruft
    // only get those files which have include === true
    // exam.xslt is not yet able to ahndle include condition on its own
    function cleanfiles(files) {
      var tmp = [];
      var i = 0;
      for (i = 0; i < files.length; i++) {
        if (files[i].include) {
          tmp.push(files[i]);
        }
      }
      return tmp;
    }

    return PrintfindingsBase;
  }
}());
