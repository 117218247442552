(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name utils.fileuploader.directive:fileupload
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="utils.fileuploader">
   <file name="index.html">
   <fileupload filehandle=""></fileupload>
   </file>
   </example>
   *
   */
  angular
    .module('utils.fileuploader')
    .directive('fileupload', fileupload);
  
  fileupload.$inject = ['Fileupload', '$log'];
  
  function fileupload(Fileupload, $log) {
    return {
      restrict: 'E',
      scope: {
        filehandle: '=',
        furl: '=?',
        label: '=?',
        color: '=?'
      },
      templateUrl: 'utils/fileuploader/fileupload-directive.tpl.html',
      replace: false,
      controllerAs: 'fuCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'fileupload';
        vm.upload = upload;
        
        // create unique identifiers for fileupload input ids
        vm.uuid = guid();
        
        
        // this doesn't work in angular 1.4
        /* vm.$onInit = $onInit;
         
         function $onInit() {
         $log.debug('$onInit() called');
         vm.furl = angular.isDefined(vm.furl) ? vm.furl : '/routes/upload';
         vm.label = angular.isDefined(vm.label) ? vm.label : 'Datei hinaufladen ...';
         vm.color = angular.isDefined(vm.color) ? vm.color : 'primary';
         $log.debug('$onInit() url: ', vm.furl);
         }*/
        
        function upload() {
          // vm.ufile.type = 'Dateiupload';
          $log.debug('upload url: ', vm.furl);
          if (angular.isUndefined(vm.furl)) {
            vm.furl = '/routes/upload';
          }
          $log.debug('upload url: ', vm.furl);
          Fileupload.uploadFileToUrl(vm.ufile, vm.furl)
            .success(function (res) {
              $log.debug('Fileupload successful: ', res);
              vm.filehandle = res;
              $log.debug('Filehandle: ', vm.filehandle);
            })
            .error(function (res) {
              $log.debug('Fileupload error');
              return res;
            });
        }
        
        
        function guid() {
          function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
              .toString(16)
              .substring(1);
          }
          
          return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
        }
        
        
      },
      /*
       // this sets the defaults, but hangs the fileupload on permanently
        function (element, attrs) {
       $log.debug('compile fnuction called');
       attrs.furl = angular.isDefined(attrs.furl) ? attrs.furl : "'/routes/upload'";
       attrs.label = angular.isDefined(attrs.label) ? attrs.label : "'Datei auswählen ...'";
       attrs.color = angular.isDefined(attrs.color) ? attrs.color : "'primary'";
       },*/
      compile: function () {
        return {
          pre: function (scope, element, attrs, ctrl) {
            // this is your average link function
            /* jshint unused:false */
            /* eslint "no-unused-vars": [2, {"args": "none"}] */
            element.bind('change', function (e) {
              $log.debug('Element: ', element);
              $log.debug('Element e: ', e);
              $log.debug('files e: ', e.srcElement.files);
              ctrl.ufile = (e.srcElement || e.target).files[0];
              ctrl.upload();
            });
          },
          post: function (scope, element, attrs, ctrl) {
            // this is non standard post link function that will set the defaults, if not already available
            /* jshint unused:false */
            /* eslint "no-unused-vars": [2, {"args": "none"}] */
            // TODO: this code does not works, neither as post-link nor as post-compile
           /* $log.debug('post link function called');
            // double and single quotes are necessary. system will otherwise search for variables instead!
            attrs.furl = angular.isDefined(attrs.furl) ? attrs.furl : "'/routes/upload'";
            attrs.label = angular.isDefined(attrs.label) ? attrs.label : "'Datei auswählen ...'";
            attrs.color = angular.isDefined(attrs.color) ? attrs.color : "'primary'";*/
          }
        };
      }
    };
  }
}());
