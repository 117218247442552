(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name utils.filereader.directive:filereader
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="utils.filereader">
        <filereader file=""></filereader>
     </example>
   *
   */
  angular
    .module('utils.filereader')
    .directive('filereader', filereader);

  filereader.$inject = [];

  function filereader() {
    return {
      restrict: 'EA',
      scope: {
        file: '='
      },
      templateUrl: 'utils/filereader/filereader-directive.tpl.html',
      replace: false,
      controller: 'FilereaderCtrl',
      controllerAs: 'frCtrl',
      bindToController: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        element.bind('change', function (e) {
          ctrl.file = (e.srcElement || e.target).files[0];
          ctrl.getFile();
        });
      }
    };
  }
}());
