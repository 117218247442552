<form>
  <div class="panel panel-default">

    <div class="panel-heading" ng-init="options={};patCtrl.newpat.test=false">
      <h3 class="panel-title">Neuer Patient </h3>

    </div>
    <div class="panel-body">
      <!-- Patientennummer: {{patCtrl.newpat._id}} -->
      <!--      <input type="hidden" ng-model="patCtrl.newpat._id"> -->
      <div class="row">
        Status: <input type="checkbox"
                       ng-model="patCtrl.newpat.test"
                       ng-checked="patCtrl.newpat.test"
                       ng-click="patCtrl.newpat.active=!patCtrl.newpat.test"> Testpatient
      </div>
      <div class="row">
        <div class="form-group col-md-2">
          <label for="titlefront">Titel</label>
          <input type="text" ng-model="patCtrl.newpat.titlefront" class="form-control" id="titlefront"
                 placeholder="Titel">
        </div>
        <div class="form-group col-md-4">
          <label for="firstname">Vorname</label>
          <input type="text" ng-model="patCtrl.newpat.firstname" class="form-control" id="firstname"
                 placeholder="Vorname">
        </div>
        <div class="form-group col-md-4">
          <label for="lastname">Nachname</label>
          <input type="text" ng-model="patCtrl.newpat.lastname" class="form-control" id="lastname"
                 placeholder="Nachname">
        </div>
        <div class="form-group col-md-2">
          <label for="titleback">Titel</label>
          <input type="text" ng-model="patCtrl.newpat.titleback" class="form-control" id="titleback"
                 placeholder="MSc">

        </div>
      </div>


      <div class="row">
        <div class="form-group col-md-2">
          <label for="birthdate">Geburtsdatum</label>
          <input type="text" ng-model="patCtrl.newpat.birthdate" class="form-control" id="birthdate"
                 placeholder="Geburtsdatum">
        </div>
        <div class="form-group col-md-4">
          <label for="svnr">SVNr</label>
          <input type="text" ng-model="patCtrl.newpat.svnr" class="form-control" id="svnr" placeholder="SVNr">
        </div>
        <div class="form-group col-md-6">
          <label for="sv">Sozialversicherung</label>
          <input type="text" ng-model="patCtrl.newpat.sv"
                 uib-typeahead="sv for sv in patCtrl.svs | filter:$viewValue | limitTo:8" class="form-control" id="sv"
                 placeholder="SV">
          <!--<input type="text" ng-model="selected" typeahead="state for state in states | filter:$viewValue | limitTo:8" class="form-control">-->
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <label for="phone">Telefon</label>
          <input type="text" ng-model="patCtrl.newpat.phone" class="form-control" id="phone" placeholder="Telefon">
        </div>
        <div class="form-group col-md-6">
          <label for="email">E-Mail</label>
          <input type="text" ng-model="patCtrl.newpat.email" class="form-control" id="email" placeholder="E-Mail">
        </div>

      </div>

      <div class=" row">
        <div class="form-group col-md-6">
          <label for="address">Adresse</label>
          <input type="text" ng-model="patCtrl.newpat.address" class="form-control" id="address"
                 placeholder="Adresse">
        </div>
        <div class="form-group col-md-2">
          <label for="postcode">PLZ</label>
          <input type="text" ng-model="patCtrl.newpat.postcode" class="form-control" id="postcode"
                 placeholder="PLZ">
        </div>

        <div class="form-group col-md-4">
          <label for="city">Ort</label>
          <input type="text" ng-model="patCtrl.newpat.city" class="form-control" id="city" placeholder="Ort">
        </div>

      </div>

      <div class=" row">
        <div class="form-group col-md-12" ng-init="patCtrl.newpat.country='Österreich'">
          <label for="country">Land</label>
          <input type="text" ng-model="patCtrl.newpat.country" class="form-control" id="country" value="Österreich">
        </div>
      </div>


      <div class=" row">
        <div class="form-group col-md-6">
          <label for="employer">Arbeitgeber</label>
          <input type="text" ng-model="patCtrl.newpat.employer" class="form-control" id="employer"
                 placeholder="Arbeitgeber">
        </div>
        <div class="form-group  col-md-6">
          <label for="practitioner">Hausarzt</label>
          <input type="text" ng-model="patCtrl.newpat.practitioner" class="form-control" id="practitioner"
                 placeholder="Hausarzt">
        </div>

      </div>

      <div class=" row">
        <div class="form-group col-md-6">
          <label for="insuredname">Mitversichert bei (Name)</label>
          <input type="text" ng-model="patCtrl.newpat.insuredwith.name" class="form-control" id="insuredname"
                 placeholder="Name">
        </div>
        <div class="form-group col-md-6">
          <label for="insurednr">Mitversichert bei (SVNr)</label>
          <input type="text" ng-model="patCtrl.newpat.insuredwith.svnr" class="form-control" id="insurednr"
                 placeholder="SVnr">
        </div>
      </div>

      <div class=" row">
        <div class="form-group col-md-6">
          <label for="iban">Bankverbindung (IBAN)</label>
          <input type="text" ng-model="patCtrl.newpat.bank.iban" class="form-control" id="iban" placeholder="IBAN">
        </div>
        <div class="form-group col-md-6">
          <label for="insurednr">Bankverbindung (SWIFT)</label>
          <input type="text" ng-model="patCtrl.newpat.bank.swift" class="form-control" id="swift"
                 placeholder="SWIFT">
        </div>
      </div>

      <div class=" row">
        <label>Patientstichwörter</label>
        <!--<div class="form-group col-md-12" ng-init="patCtrl.newpat.keywords['Erstbesuch']='info'">-->
        <div class="form-group col-md-12">
          <div keywords-widget keywords="patCtrl.newpat.keywords"></div>
        </div>
      </div>

      <div class="input-group">
        <span class="input-group-btn">
	        <button class="btn btn-default" type="button" ng-click="patCtrl.add(patCtrl.newpat);">Patient anlegen
          </button>
        </span>
      </div>

    </div>
    <!-- panelbody -->

  </div>
  <!-- panel -->
</form>
