(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name user.factory:Userservice
   *
   * @description
   *
   */
  angular
    .module('user')
    .factory('User', User)
    .config(config);

  config.$inject = ['restmodProvider'];

  function config(restmodProvider) {
    restmodProvider.rebase({
      $config: {
        primaryKey: '_id'
      }
    });
  }

  User.$inject = ['restmod'];

  function User(restmod) {
    return restmod.model('/routes/users').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true}
    });
  }
  
}());
