(function () {
  'use strict';

  /* @ngdoc object
   * @name file
   * @description
   *
   */
  angular
    .module('file', [
      'ui.router',
      'ui.bootstrap',
      'bootstrapLightbox',
      'utils.filereader',
      'utils.fileuploader',
      'ngTagsInput',
      'ui.bootstrap.typeahead',
      'dms.scan'
    ])
    .config(config);

  config.$inject = ['LightboxProvider'];

  function config(LightboxProvider) {
    // set a custom template
    LightboxProvider.templateUrl = 'file/lightbox.html';
    LightboxProvider.getImageUrl = function (image) {
      return '/routes/files/filereader/' + image.fileid;
    };

    LightboxProvider.getImageCaption = function (image) {
      return image.label;
    };
    // all images sclaed to the largest possible dimension
    LightboxProvider.fullScreenMode = true;
  }

}());
