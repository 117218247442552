(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name helpers.doctor.directive:doctorAdd
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="helpers.doctor">
       <file name="index.html">
        <doctor-add></doctor-add>
       </file>
     </example>
   *
   */
  angular
    .module('helpers.doctor')
    .directive('doctorAdd', doctorAdd);

  doctorAdd.$inject = ['Doctor', '$state', '$log'];


  function doctorAdd(Doctor, $state, $log) {
    return {
      restrict: 'EA',
      scope: {
        returndoc: '=?',
        dialog: '=?'
      },
      templateUrl: 'helpers/doctor/doctor-add-directive.tpl.html',
      replace: false,
      controllerAs: 'draCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'doctorAdd';
        vm.newdoctor = {};
        vm.addDoctor = addDoctor;

        $log.debug(vm.returndoc);
        function addDoctor() {
          if (angular.isString(vm.newdoctor.lastname) && !angular.isString(vm.newdoctor.institute)) {
            vm.newdoctor.fname = vm.newdoctor.titlefront + ' ' + vm.newdoctor.firstname + ' ' + vm.newdoctor.lastname + ', ' + vm.newdoctor.city;
          }
          if (!angular.isString(vm.newdoctor.lastname) && angular.isString(vm.newdoctor.institute)) {
            vm.newdoctor.fname = vm.newdoctor.institute + ', ' + vm.newdoctor.city;
          }
          if (angular.isString(vm.newdoctor.lastname) && angular.isString(vm.newdoctor.institute)) {
            vm.newdoctor.fname = vm.newdoctor.institute + ', ' + vm.newdoctor.titlefront + ' ' + vm.newdoctor.lastname + ', ' + vm.newdoctor.city;
          }
          // vm.newdoctor.fname = vm.newdoctor.titlefront + ' ' + vm.newdoctor.firstname + ' ' + vm.newdoctor.lastname + ', ' + vm.newdoctor.city;
          Doctor.$create(vm.newdoctor).$asPromise().then(function (doc) {
            vm.returndoc._id = doc._id;
            vm.returndoc.fname = doc.fname;
            $log.debug('freshly created doctor: ', vm.returndoc);
            if (angular.isObject(vm.dialog)) {
              vm.dialog.close();
            }
          });
          vm.newdoctor = {};
          /* $log.debug('previous state: ', $state.previousState);
          if ($state.previousState.name !== '') {
            $state.go($state.previousState);
          } */
        }
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
