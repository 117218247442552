<!-- This is a very important hack: DO NOT REMOVE this totally pointless invisible heading -->
<!-- Removal of these spans will cause exam and consult close detection to fail -->
<span style="visibility: hidden">Consult Status patient: <span ng-bind='consCtrl.patient.inconsult'></span></span>
<span style="visibility: hidden">Consult Status openConsult: <span ng-bind='consCtrl.patient.openConsult.open'></span>
</span>
<span style="visibility: hidden">Exam Status patient: <span ng-bind='consCtrl.patient.inexam'></span></span>
<span style="visibility: hidden">Exam Status openExam: <span ng-bind='consCtrl.patient.openExam.open'></span></span>
<span style="visibility: hidden">examinations: <span ng-bind='consCtrl.patient.openConsult.examinations'></span></span>


<!-- not in consult -->
<div ng-hide="consCtrl.patient.inconsult == true && consCtrl.patient.openConsult.open === true">
  <div class="alert alert-info"
       ng-show="consCtrl.patient.openConsult.open === false && consCtrl.patient.openConsult == null">
    <h4>Die Konsultation für diesen Patienten wurde von anderer Seite beendet.</h4>
  </div>


  <div class="alert alert-info" ng-show="consCtrl.patient.inconsult === false">
    <h4><i class="fa fa-info-circle"></i>
      Derzeit existiert keine aktuelle Konsultation für diesen Patienten.</h4>

    <form>
      <button class="btn btn-primary" ng-click="consCtrl.startConsult();">
        Neue Konsultation starten
      </button>
      <span class="btn btn-info" ng-click="consCtrl.$state.go('patientrecord.history')">Zur Patientenhistorie wechseln</span>
    </form>
  </div>

</div>

<!-- in consult -->
<div ng-show="consCtrl.patient.inconsult == true && consCtrl.patient.openConsult.open === true">
  <div class="row">
    <!-- starred consult and consult keywords -->
    <div class="col-xs-2 col-md-1 v-align" style="text-align: center">
      <i class="fa fa-3x fa-star-o" ng-hide="consCtrl.patient.openConsult.stared"
         ng-click="consCtrl.patient.openConsult.stared = true"
         uib-tooltip="Konsultation als wichtig markieren"></i>
      <i class="fa fa-3x fa-star" ng-show="consCtrl.patient.openConsult.stared"
         style="color: #dddd33"
         ng-click="consCtrl.patient.openConsult.stared = false"
         uib-tooltip="Konsultation als unwichtig markieren"></i>
    </div><!-- css sometimes totally sucks, DO NOT REMOVE this comment!
  --><div class="col-xs-10 col-md-11 v-align">
      <h4 ng-show="consCtrl.patient.inconsult">Stichworte zu dieser Konsultation</h4>

      <keywords-widget keywords="consCtrl.patient.openConsult.keywords"
                       text="Stichworte für diese Konsultation"></keywords-widget>
    </div>
  </div>

  <!-- ui view for consult data entry, emax data entry, checkout stages -->
  <div ui-view="cur-details"></div>

</div>



