(function () {
  'use strict';

  angular
    .module('user')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('users', {
        url: '/users',
        views: {
          main: {template: '<adduser></adduser>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });
  }
}());
