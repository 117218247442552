(function () {
  'use strict';

  angular
    .module('tracker')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('tracker', {
        url: '/tracker',
        templateUrl: 'tracker/tracker.tpl.html',
        controller: 'TrackerCtrl',
        controllerAs: 'tracker'
      });
  }
}());
