/**
 * Created by lari on 07/04/2017.
 */
(function () {
  'use strict';
  angular.module('finance.invoice')
    .component('checkinvoice', {
      restrict: 'E',
      templateUrl: 'finance/invoice/checkinvoice-component.tpl.html',
      controllerAs: 'vm',
      bindings: {
        mode: '@'
      },
      controller: ['Checkinvoice', '$log', '$http', '$uibModal',
        function (Checkinvoice, $log, $http, $uibModal) {
          var vm = this;
          
          this.$onInit = function () {
            var search = {};
  
            vm.currentpage = 1;
  
            vm.invdatetpl = 'finance/invoice/invoiceDateTpl.html';
            
            vm.pageChanged = pageChanged;
            vm.storno = storno;
            vm.sendReminder = sendReminder;
            vm.enterPaidDate = enterPaidDate;
            
            search.page = vm.currentpage;
            search.query = {};
            
            switch (vm.mode) {
              case 'showstorno':
                search.query.storno = true;
                vm.title = 'Stornierte Zielrechnungen';
                break;
              case 'hidestorno':
                search.query.storno = false;
                vm.title = 'Unstornierte Zielrechnungen';
                break;
              case 'showstest':
                search.query.test = true;
                vm.title = 'Test-Zielrechnungen';
                break;
              case 'hidetest':
                search.query.test = false;
                vm.title = 'Zielrechnungen (ohne Testrechnungen)';
                break;
              case 'open':
                search.query.paid = false;
                search.query.test = false;
                search.query.storno = false;
                vm.title = 'Offene Zielrechnungen';
                break;
              case 'paid':
                search.query.paid = true;
                search.query.test = false;
                search.query.storno = false;
                vm.title = 'Bezahlte Zielrechnungen';
                break;
              case 'overdue':
                search.query.paid = false;
                search.query.test = false;
                search.query.storno = false;
                search.query.date = {$lt: moment().subtract(15, 'days').toISOString()};
                vm.title = 'Überfällige Zielrechnungen';
                break;
              default:
                vm.title = 'Zielrechnungen';
                break;
            }
            
            search.options = {};
            search.options.fields = {
              _id: 1,
              invoicenr: 1,
              date: 1,
              fdate: 1,
              total: 1,
              tax: 1,
              storno: 1,
              test: 1,
              paytype: 1,
              patientinvoiceid: 1,
              doctorinvoiceid: 1,
              receiptid: 1,
              stornoreceiptid: 1,
              patientid: 1,
              paiddate: 1,
              fpaiddate: 1,
              duedate: 1,
              paid: 1
            };
            
            vm.checkinvoices = Checkinvoice.$search(search).$then(function (inv) {
              vm.totalItems = inv.$pageCount;
            });
            
          }; // $onInit
          
          
          function pageChanged() {
            vm.checkinvoices.$refresh({page: vm.currentPage});
          }
          
          function storno(invoice) {
            $log.debug('storno invoice: ', invoice);
            invoice.$destroy().$then(function () {
              vm.checkinvoices.$refresh().$then(function (inv) {
                vm.totalItems = inv.$pageCount;
              });
            });
          }
  
          function enterPaidDate(invoice) {
            $uibModal.open({
              templateUrl: 'finance/invoice/invoiceDateTpl.html',
              controllerAs: 'miCtrl',
              controller: ['invoice', '$uibModalInstance', function (inv, $uibModalInstance) {
                var vmi = this;
                vmi.invoice = inv;
                vmi.dialog = $uibModalInstance;
        
                // TODO: options are completyl ignored, figure out how to enable maxDate
                vmi.dateoptions = {
                  maxDate: new Date(),
                  showWeeks: true
                };
                vmi.setDate = setDate;
        
                function setDate() {
                  vmi.invoice.paiddate = moment(vmi.paiddate).toISOString();
                  vmi.invoice.fpaiddate = moment(vmi.paiddate).locale('de-at').format('ll');
                  vmi.invoice.paid = true;
                  // $log.debug('Paiddate: ', vmi.paiddate);
                  // $log.debug('Invoice Paiddate: ', vmi.invoice.paiddate);
                  $log.debug('Invoice FPaiddate: ', vmi.invoice.fpaiddate);
                  vmi.invoice.$save();
                  vmi.dialog.close();
                }
              }],
              resolve: {
                invoice: function () {
                  return invoice;
                }
              },
              size: 'md'
            });
          }
          
          // TODO: this does not work yet
          function sendReminder(invoice) {
            invoice.reminded = true;
            invoice.remindeddate = moment().toISOString();
            invoice.fremindeddate = moment().locale('de-at').format('ll');
    
            $http({
              url: '/routes/pdfprinter/consultprint',
              method: 'GET',
              params: {
                patientid: invoice.patientid,
                consultid: invoice.consultid,
                doctype: 'reminder'
              }
            }).then(
              function success(r) {
                if (!angular.isArray(invoice.reminders)) {
                  invoice.reminders = [];
                }
                invoice.reminders.push({date: invoice.remindeddate, fdate: invoice.fremindeddate, doc: r.docid});
                $log.debug('Reminder docid: ', r.docid);
                // invoice.$save();
              },
              function error(r) {
                $log.debug('Printsaving Reminder PDF failed: ', r);
              }
            );
    
          }
          
        }] // controller
    }); // component

// klammerfriedhof
}());