(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name finance.rksv.factory:finance.rksvService
   *
   * @description
   *
   */
  angular
    .module('finance.rksv')
    .factory('Deplist', Deplist)
    .config(config);

  config.$inject = ['restmodProvider'];

  function config(restmodProvider) {
    restmodProvider.rebase({
      $config: {
        primaryKey: '_id'
      }
    });
  }


  Deplist.$inject = ['restmod'];

  function Deplist(restmod) {
    return restmod.model('/routes/rksvdep', 'PagedModel');
  }

// klammerfriedhof
}());