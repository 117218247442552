(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name patient.directive:patientEdit
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-edit></patient-edit>
   </file>
   </example>
   *
   */
  angular
    .module('patient')
    .directive('patientEdit', patientEdit);
  
  function patientEdit() {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'patient/patient-edit-directive.tpl.html',
      replace: false,
      controller: 'PatientCtrl',
      controllerAs: 'patCtrl',
      bindToController: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        
        scope.$watch('patCtrl.patient.refdoctor', function () {
          ctrl.patient.$save();
        }, true);
        
        scope.$watch('patCtrl.patient.practdoctor', function () {
          ctrl.patient.$save();
        }, true);
        
        scope.$watch('patCtrl.patient.zip', function () {
          ctrl.patient.$save();
        }, true);
        
        scope.$watch('patCtrl.patient.city', function () {
          ctrl.patient.$save();
        }, true);
        
        scope.$watch('patCtrl.patient.sv', function () {
          ctrl.patient.$save();
        }, true);
      }
    };
  }
}());
