<div>
  <form class="form-inline">
    <div class="row">
    
      <!-- value groups -->
      <div ng-repeat="group in labRCtrl.labconfig.groups">
        <div class="form-group col-md-4">
          <label>
            <input type="checkbox"
                   ng-model="labRCtrl.ginclude[$index]"
                   tabindex="{{labRCtrl.tabi + $index}}"
                   ng-checked="labRCtrl.ginclude[$index]"
                   ng-init="labRCtrl.ginclude[$index] = false"
                   ng-click="labRCtrl.toggleGroup()"/> {{group.name}}</label>
        </div>
      </div>
    </div>
    
    <!-- add additional values -->
    <label for="addlabval">Zusätzliche Laborwerte</label>
    <tags-input ng-model="labRCtrl.additionalvalues"
                ng-init="labRCtrl.additionalvalues = []"
                tabindex="{{vm.tabi + labRCtrl.labconfig.groups.length}}"
                display-property="name" id="addlabval"
                add-on-comma="false"
                min-length="1"
                placeholder="Tippen zum Vervollständigen"
                replace-spaces-with-dashes="false"
                on-tag-added="labRCtrl.joinValues()"
                on-tag-removed="labRCtrl.joinValues()"
                template="tag-template">
      <auto-complete source="labRCtrl.loadValues($query)"
                     min-length="1"
                     load-on-focus="false"
                     load-on-down-arrow="true"
                     load-on-empty="true"
                     max-results-to-show="32"
                     template="autocomplete-template"></auto-complete>
    </tags-input>
    
    <!-- selected values -->
    <h4>Ausgewählte Laborwerte</h4>
    
    <div class="alert alert-info">
      <span ng-show="labRCtrl.allvalues.length > 0" ng-repeat="val in labRCtrl.allvalues">{{val}}{{$last ? '' : ', '}} </span>
      <span ng-hide="labRCtrl.allvalues.length > 0">Keine Werte ausgewählt</span>
    </div>
  
  
  </form>
  <script type="text/ng-template" id="tag-template">
    <div class="tag-template">
      <div class="right-panel">
        <span>{{$getDisplayText()}}</span>
        <a class="remove-button" ng-click="$removeTag()">&#10006;</a>
      </div>
    </div>
  </script>
  
  
  <script type="text/ng-template" id="autocomplete-template">
    <div class="autocomplete-template">
      <div>
        <span ng-bind-html="$highlight($getDisplayText())">{{name}}</span>
      </div>
    </div>
  </script>
</div>
