<span class="input-group" style="display: inline">
    <span ng-repeat="kw in skwCtrl.keywords | orderBy:['nr', '-timestamp']">
      <span uib-dropdown>
        <button type="button"
                class="btn btn-{{kw.col}}" uib-dropdown-toggle
                data-toggle="uib-dropdown" ng-show="(skwCtrl.selected.indexOf(kw.col) > -1 )"
                aria-haspopup="true" aria-expanded="false">
          {{kw.key}} <span class="caret"></span>
        </button>
        <ul uib-dropdown-menu>
          <li>
            <a ng-click="skwCtrl.updateColor(kw.key,'success')">
              <span class="label label-success">Grün (Erfolg)</span>
            </a>
          </li>
          <li>
            <a ng-click="skwCtrl.updateColor(kw.key,'warning')">
              <span class="label label-warning">Gelb (Warnung)</span>
            </a>
          </li>
          <li>
            <a ng-click="skwCtrl.updateColor(kw.key,'danger')">
              <span class="label label-danger">Rot (Gefahr)</span>
            </a>
          </li>
          <li>
            <a ng-click="skwCtrl.updateColor(kw.key,'info')">
              <span class="label label-info">Türkis (Hinweis)</span>
            </a>
          </li>
          <li>
            <a ng-click="skwCtrl.updateColor(kw.key,'primary')">
              <span class="label label-primary">Blau (Aktuell)</span>
            </a>
          </li>
          <li role="separator" class="divider" uib-dropdown></li>
          <li uib-dropdown ng-show="kw.created"><a><span class="label label-primary">Erstellt: {{kw.created}}</span></a></li>
          <li uib-dropdown ng-show="kw.updated"><a><span class="label label-primary">Geändert: {{kw.updated}}</span></a></li>
          <li ng-hide="kw.col === 'danger'" role="separator" class="divider"  uib-dropdown></li>
          <li>
            <a ng-hide="kw.col === 'danger'" ng-really-message="Wirklich löschen?" ng-really-click="skwCtrl.removeKeyword(kw.key)">
              <span class="label label-danger">X</span> Löschen
            </a>
          </li>
        </ul>
      </span>
    </span><!-- ng-repeat -->
  </span>
