
<div ng-repeat="a in listCtrl.ar" ng-show="listCtrl.ar.length > 0">
  <div class="input-group">
    <input type="text" class="form-control" ng-model="a" placeholder="Bestehendes ersetzen...">
      <span class="input-group-btn">
        <button class="btn btn-danger" type="button" ng-click="listCtrl.splice($index)"><i class="fa fa-remove"></i></button>
      </span>
  </div>
</div>
<ng-form name="listCtrl.formid">
<div class="input-group">
  <input type="text" class="form-control" ng-model="listCtrl.newvalue" placeholder="Neuer Wert..." ng-keyup="$event.keyCode == 13 ? listCtrl.add() : null">
      <span class="input-group-btn">
        <button class="btn btn-success" type="button" ng-click="listCtrl.add()"><i class="fa fa-plus"></i></button>
      </span>
</div>
</ng-form>