
<h3>Patienten Erinnerungen</h3>

<form>
    <h4>Fällig &amp; Überfällig</h4>
    <div class="alert alert-info" ng-hide="arCtrl.todos.overdue.length > 0">Derzeit keine überfälligen Erinnerungen</div>
    <div class="form-group spacer-top" ng-repeat="item in arCtrl.todos.overdue | orderBy: 'due'">
        <label style="color: #bb3333">
            <input type="checkbox"
                   ng-model="item.done"
                   ng-change="arCtrl.save(item)"/>&nbsp;{{item.due | moment: 'l' }}, {{item.label}},
            <a ui-sref="patientrecord.history({patientid: item.patientid})"> {{item.pfname}} {{item.plname}}</a></label>
    </div>
    <h4>Bald fällig (2 Wochen <span >
        <i class="fa fa-toggle-on fa-rotate-180 slactive"
           ng-show="arCtrl.weeks === 2"
           ng-click="arCtrl.switchRange(6);">
        </i>
        <i class="fa fa-toggle-on slinactive"
           ng-show="arCtrl.weeks === 6"
           ng-click="arCtrl.switchRange(2);">
        </i>
    </span> 6 Wochen)</h4>
    <div class="alert alert-info" ng-hide="arCtrl.todos.upcoming.length > 0">Derzeit keine fälligen Erinnerungen</div>
    <div class="form-group spacer-top" ng-repeat="item in arCtrl.todos.upcoming | orderBy: 'due' ">
        <label style="color: #ccbb33">
            <input type="checkbox"
                   ng-model="item.done"
                   ng-change="arCtrl.save(item)"/>&nbsp;{{item.due | moment: 'l' }}, {{item.label}},
            <a ui-sref="patientrecord.history({patientid: item.patientid})"> {{item.pfname}} {{item.plname}}</a></label>
    </div>

    <h4>Zuletzt abgeschlossen</h4>
    <div class="alert alert-info" ng-hide="arCtrl.todos.lastcompleted.length > 0">Zuletzt wurden keine Erinnerungen abgeschlossen</div>
    <div class="form-group spacer-top" ng-repeat="item in arCtrl.todos.lastcompleted | orderBy: 'due' ">
        <label style="color: #ccbb33">
            <input type="checkbox"
                   ng-model="item.done"
                   ng-change="arCtrl.save(item)"/>&nbsp;{{item.due | moment: 'l' }}, {{item.label}},
            <a ui-sref="patientrecord.history({patientid: item.patientid})"> {{item.pfname}} {{item.plname}}</a></label>
    </div>

</form>
