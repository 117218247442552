(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name file.directive:filePicker
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="examination">
   <file name="index.html">
   <file-picker></file-picker>
   </file>
   </example>
   *
   */
  angular
    .module('file')
    .directive('filePicker', filePicker);

  filePicker.$inject = ['File', '$log', '$state', '$http', '$timeout', 'hotkeys', 'ConsultRefresh', 'vertxEventBusService', '$cookies', 'Rlog', 'lodash'];

  function filePicker(File, $log, $state, $http, $timeout, hotkeys, ConsultRefresh, vertxEventBusService, $cookies, Rlog, _) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'examination/file-picker-directive.tpl.html',
      replace: false,
      controllerAs: 'fpCtrl',
      bindToController: true,
      controller: function filePickerCtrl() {
        var vm = this;
        vm.$state = $state;
        vm.image = 0;


        vm.fileDelete = fileDelete;
        vm.fileLabel = fileLabel;
        vm.next = next;
        vm.previous = previous;
        vm.close = close;

        vm.patient.openExam.filesloaded = false;
        $timeout(function () {
          vm.errorcondition = 'Bilder konnten nicht geladen werden!';
        }, 10000);


        $log.debug('filepicker entered ');
        $log.debug('filepicker openExam: ', vm.patient.openExam);

        // files can only be loaded once! check for existing files befor loading

        function previous() {
          if (!vm.patient.openExam.files[vm.image].processed) {
            vm.patient.openExam.files[vm.image].keep = true;
            vm.patient.openExam.files[vm.image].processed = true;
          }
          vm.patient.openExam.$save();
          vm.image = vm.image === 0 ? vm.patient.openExam.files.length - 1 : vm.image - 1;
        }

        function next() {
          if (!vm.patient.openExam.files[vm.image].processed) {
            vm.patient.openExam.files[vm.image].keep = true;
            vm.patient.openExam.files[vm.image].processed = true;
          }
          vm.patient.openExam.$save();
          vm.image = vm.image < vm.patient.openExam.files.length - 1 ? vm.image + 1 : 0;
        }

        function fileDelete() {
          vm.patient.openExam.files[vm.image].keep = false;
          vm.patient.openExam.files[vm.image].processed = true;
          vm.patient.openExam.files[vm.image].include = false;
          vm.patient.openExam.files[vm.image].label = 'zum L\u00F6schen markiert';
          vm.patient.openExam.$save();
          vm.image = vm.image < vm.patient.openExam.files.length - 1 ? vm.image + 1 : 0;
        }


        function fileLabel() {
          vm.patient.openExam.files[vm.image].keep = true;
          vm.patient.openExam.files[vm.image].include = false;
          vm.patient.openExam.files[vm.image].processed = true;
          vm.patient.openExam.$save();
          vm.image = vm.image < vm.patient.openExam.files.length - 1 ? vm.image + 1 : 0;
        }


        function close() {
          if (vm.patient.openExam.$hasPendingActions()) {
            $log.debug('openExam has pending actions!');
            Rlog.debug('examination', 'file-picker-directive', 'openExam has pending actions!',
              {
                openExamid: vm.patient.openExam._id,
                oeType: vm.patient.openExam.temaplte.type,
                patientid: vm.patient._id
              });

            vm.patient.openExam.$cancel();
            reallyFinish();
          }
          else {
            $log.debug('finished instantly');
            reallyFinish();
          }
        }

        function reallyFinish() {
          vm.patient.openExam.open = false;
          vm.patient.openExam.$save();
          vm.patient.inexam = false;
          vm.patient.consultstate = 'consult';
          vm.patient.$save();
          $state.go('patientrecord.current.consult');
        }


        /*
         // hotkeys for image picker
         hotkeys.bindTo($scope)
         .add({
         combo: 'enter',
         description: 'in Befund &uuml;bernehmen',
         callback: function () {
         vm.fileLabel();
         }
         })
         .add({
         combo: 'x',
         description: 'Löschen',
         callback: function () {
         vm.fileDelete();
         }
         })
         .add({
         combo: 'right',
         description: 'Vorheriges Bild',
         callback: function () {
         vm.next();
         }
         })
         .add({
         combo: 'left',
         description: 'N&auml;chste Bild',
         callback: function () {
         vm.previous();
         }
         });
         */
      },

      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        var stopobj = {};
        var i = 0;
        var tmp = {};

        // TODO: figure out ramifcations of refreshing at this state
        ConsultRefresh.refreshAll(ctrl.patient);

        /* scope.$watch('consCtrl.patient.consultstate', function (newval, oldval) {
          $log.debug('consultstate watch');
          $log.debug('oldval: ', oldval);
          $log.debug('newval: ', newval);
          if (newval !== oldval && $state.current.name !== ('patientrecord.current.' + newval)) {
            if (newval === '') {
              $state.go('patientrecord.history');
            }
            else {
              $state.go('patientrecord.current.' + newval);
            }
          }
        });*/


        if (ctrl.patient.openExam.filesloaded !== true) {
          // stopobj.shareid = ctrl.patient.openExam.shareid;
          // stopobj.metadata = {filetype: ctrl.patient.openExam.filetype};
          // $log.debug('stopobj: ', stopobj);
          console.log('stop - oE._id: ', ctrl.patient.openExam._id);
          $http.get('/routes/files/filegrabber/stop/' + ctrl.patient.openExam._id)
            .then(function success() {
              console.log('Filegrabber stop success!');
              var fullquery = {};
              fullquery.query = {examid: ctrl.patient.openExam._id};
              fullquery.options = {};
              fullquery.options.sort = {sortorder: 1};
              // File.$search(fullquery).$asPromise().then(function (files) {

                File.$search({examid: ctrl.patient.openExam._id}).$asPromise().then(function (unsortedfiles) {
                  ctrl.patient.openExam.files = [];
// _.orderBy(users, ['user', 'age'], ['asc', 'desc']);
                  var files = _.orderBy(unsortedfiles, ['sortorder'], ['asc']);
                  // this is weird, but necessary
                  // copy file metainformation into openExam
                  // restmod will trip otherwise
                  for (i = 0; i < files.length; i++) {
                    tmp = {};
                    tmp.fileid = files[i].fileid;
                    tmp.mimetype = files[i].mimetype;
                    tmp.idinfiles = files[i]._id;
                    ctrl.patient.openExam.files.push(tmp);
                  }
                  if (files.length === 0) {
                    ctrl.errorcondition = 'Es wurden keine Bilder zu dieser Untersuchung gefunden!';
                  }
                  else {
                    ctrl.patient.openExam.filesloaded = true;
                  }

                  ctrl.patient.openExam.$save();
                  ctrl.max = files.length - 1;
                  $log.debug('vm.files: ', ctrl.patient.openExam.files);
                },
                function failure() {
                  console.log('Filegrabber stop failure!');
                  ctrl.errorcondition = 'Bilder konnten nicht geladen werden!';
                });
            });
        }
        else {

        }


        // listen for examination updates
        vertxEventBusService.on('examinations.update', function (message) {
          var messages = message.split(':');
          $log.debug('exam update mesg: ', message);
          $log.debug('cookie: ', $cookies.get('vertx-web.session'));
          $log.debug('openexam: ', ctrl.patient.openExam);
          $log.debug('openexamid: ', ctrl.patient.openExam._id);
          // the current consult container always has to react to every exam update pertaining to patient, even those caused by himself
          if (ctrl.patient.openExam._id === messages[0]) {
            ctrl.patient.openExam.$refresh();
            $log.debug('(fpd) refreshed openexam for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
          else {
            $log.debug('(fpd) ignored openexam change for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
        });


      }
    };
  }
}());
