(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name helpers.sv.directive:sv
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="helpers.sv">
       <file name="index.html">
        <sv></sv>
       </file>
     </example>
   *
   */
  angular
    .module('helpers.sv')
    .directive('sv', sv);

  sv.$inject = ['Sv', '$uibModal', '$log', 'SvHelper'];

  function sv(Sv, $uibModal, $log, SvHelper) {
    return {
      restrict: 'E',
      scope: {},
      templateUrl: 'helpers/sv/sv-directive.tpl.html',
      replace: false,
      controllerAs: 'svCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'sv';
        vm.svlist = Sv.$search();

        vm.editSv = SvHelper.editSv;
        vm.addSv = addSv;
        vm.removeSv = removeSv;

        /* function editSv(lsv) {
          var dialog = $uibModal.open({
            template: '<div class="modalspacer"><sv-edit sv="miCtrl.sv" dialog="miCtrl.$uibModalInstance"></sv-edit></div>',
            controllerAs: 'miCtrl',
            controller: ['sv', '$uibModalInstance', function (ssv, $uibModalInstance) {
              var vmi = this;
              vmi.sv = ssv;
              vmi.$uibModalInstance = $uibModalInstance;
            }],
            resolve: {
              sv: function () {
                return lsv;
              },
              dialog: function () {
                return dialog;
              }
            },
            size: 'lg'
          });
        }*/

        function addSv() {
          var newsv = vm.svlist.$create();
          vm.editSv(newsv);
        }


/*
        function addSv() {
          var dialog = $uibModal.open({
            template: '<div class="modalspacer"><sv-edit doctor="miCtrl.sv" dialog="miCtrl.$uibModalInstance"></sv-edit></div>',
            controllerAs: 'miCtrl',
            controller: ['sv', '$uibModalInstance', function (lsv, $uibModalInstance) {
              var vmi = this;
              $log.debug('Lsv: ', lsv);
              vmi.sv = lsv;
              vmi.$uibModalInstance = $uibModalInstance;
            }],
            resolve: {
              sv: function () {
                return vm.svlist.$create();
              },
              dialog: function () {
                return dialog;
              }
            },
            size: 'lg'
          });


        }

      */

        function removeSv(tsv) {
          tsv.active = false;
          tsv.$save();
        }

      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
