(function () {
  'use strict';

  angular
    .module('file')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('file', {
        url: '/file',
        views: {
          //'main': {template: '<file-demo></file-demo>'},
          'main': {templateUrl: 'file/file.tpl.html'},
          //'main': {template: '<h3>file</h3>'},
          //'leftbar': {template: '<patient-today></patient-today>'},
          'rightupper': {template: '<todowidget></todowidget>'},
          'rightlower': {template: '<h3>I\'m a chat window</h3>'}
        }
      });
  }


}());
