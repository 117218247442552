(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name doctype.factory:DoctypeHelper
   *
   * @description
   *
   */
  angular
    .module('doctype')
    .factory('DoctypeHelper', DoctypeHelper);

  DoctypeHelper.$inject = ['Doctype', '$uibModal', '$log'];

  function DoctypeHelper(Doctype, $uibModal, $log) {
    var DoctypeHelperBase = {};

    DoctypeHelperBase.editDoctype = editDoctype;


    function editDoctype(ldt) {
      var dialog = $uibModal.open({
        template: '<div class="modalspacer"><doctype-edit doctype="miCtrl.doctype" dialog="miCtrl.$uibModalInstance"></doctype-edit></div>',
        controllerAs: 'miCtrl',
        controller: ['doctype', '$uibModalInstance', function (sdoctype, $uibModalInstance) {
          var vmi = this;
          vmi.doctype = sdoctype;
          vmi.$uibModalInstance = $uibModalInstance;
        }],
        resolve: {
          doctype: function () {
            return ldt;
          },
          dialog: function () {
            return dialog;
          }
        },
        size: 'lg'
      });
      return dialog;

    }


    return DoctypeHelperBase;
  }
}());
