(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:patientHoriz
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-horiz></patient-horiz>
   </file>
   </example>
   *
   */
  angular
    .module('patient')
    .directive('patientHoriz', patientHoriz);

  function patientHoriz() {
    return {
      restrict: 'EA',
      scope: false,
      /* scope: {
       patient: '=',
       editablekeywords: '@'
       }, */
      templateUrl: 'patient/patient-horiz-directive.tpl.html',
      replace: false,
      controller: 'PatientCtrl',
      controllerAs: 'patCtrl',
      bindToController: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        scope.editablekeywords = attrs.editablekeywords;
      }
    };
  }
}());
