(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name document.factory:Document
   *
   * @description
   *
   */
  angular
    .module('document')
    .factory('Document', Document);

  Document.$inject = ['restmod'];

  function Document(restmod) {
    return restmod.model('/routes/binarypdf').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true}
    });
  }


}());
