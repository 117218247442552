<h2>Rechnungen</h2>
<!-- Nav tabs -->
<ul class="nav nav-tabs hidden-xs hidden-sm" role="tablist" style="padding-top: 1ex;">
  <li ui-sref="finance.invoices.cashinv" ui-sref-active="active"><a>Barrechnungen</a></li>
  <li ui-sref="finance.invoices.checkinv" ui-sref-active="active"><a>Zielrechnungen</a></li>
  <li ui-sref="finance.invoices.checkpaid" ui-sref-active="active"><a>Bezahlt</a></li>
  <li ui-sref="finance.invoices.checkopen" ui-sref-active="active"><a>Offenen</a></li>
  <li ui-sref="finance.invoices.checkoverdue" ui-sref-active="active"><a>Überfällige</a></li>
</ul>
<!--view for invoice details-->
<div ui-view="invoicedetails"></div>