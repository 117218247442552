(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name turnover.directive:turnover
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="turnover">
   <file name="index.html">
   <turnover></turnover>
   </file>
   </example>
   *
   */
  angular
    .module('finance.turnover')
    .directive('turnover', turnover);
  
  turnover.$inject = ['Turnover', '$http', '$log'];
  
  function turnover(Turnover, $http, $log) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'finance/turnover/turnover-directive.tpl.html',
      replace: false,
      controllerAs: 'toCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        var monthlyquerycash;
        var monthlyquerycheck;
        var yearlyquerycash;
        var yearlyquerycheck;
        
        var thisquarterquerycash;
        var thisquarterquerycheck;
        var thismonthquerycash;
        var thismonthquerycheck;
        var thisyearquerycash;
        var thisyearquerycheck;
        
        
        // set default view on page open
        vm.view = 'summary';
        
        vm.today = moment().locale('de-at').format('ll');
        // vm.thisday = moment().format('YYYY-MM-DD');
        vm.thisday = moment().format('YYYY-MM-DD').toString();
        vm.thismonth = moment().format('YYYY-MM').toString();
        vm.thisyear = moment().format('YYYY').toString();
        
        // NOTE TO SELF: Statistics with Stornos and Discounts is REALLY tricky!!!!!!
        // please note the following
        
        // inv.netto = sum of net prices
        // inv.totalbefore = brutto sum before discount
        // inv.tax = tax sum before discount
        // inv.taxdiscounted = discounted tax sum
        // inv.nettodiscounted = nett sum discounted, also set when discount was 0
        // inv.discounttaxsum = array with taxes per steuersatz, post discount, also set when discount was 0
        
        // some fun new queries to cashinvoices
        // [{$match: {test:false}},{$group:{_id: {year: {$substr:['$date',0,4]},month: {$substr: ['$date',5,2]} },count: {$sum:1}, monthlytotal: {$sum: '$total'}, monthlytotalstorno: {$sum: {$cond:[{$eq: ['$storno', true]},'$total', 0]}}   }}]
        // moment().startOf('quarter')
        
        // TODO: before you roll out this feature, migrate client databases to add missing nettodiscounted, etc fields
        
        
        // this month, quarter, year statss
        vm.thisyear = {};
        
        function mongomatchstartend(start, end) {
          return {
            $match: {
              $and: [
                {date: {$gt: start}},
                {date: {$lt: end}},
                {test: false}
              ]
            }
          };
        }
        
        function mongogenblock(type, id) {
          var specficquery = {};
          var groupid = {};
          if (id === 'month') {
            groupid = {
              year: {$substr: ['$date', 0, 4]},
              month: {$substr: ['$date', 5, 2]}
            };
          }
          else if (id === 'year') {
            groupid = {
              year: {$substr: ['$date', 0, 4]}
            };
          }
          else {
            groupid = 1;
          }
          
          
          if (type === 'cash') {
            specficquery = {
              $group: {
                _id: groupid, totalcount: {$sum: 1},
                invcount: {$sum: {$cond: [{$eq: ['$storno', false]}, 1, 0]}},
                stornocount: {$sum: {$cond: [{$eq: ['$storno', true]}, 1, 0]}},
                total: {$sum: {$cond: [{$eq: ['$storno', false]}, '$total', 0]}},
                netto: {$sum: {$cond: [{$eq: ['$storno', false]}, '$netto', 0]}},
                nettodiscounted: {$sum: {$cond: [{$eq: ['$storno', false]}, '$nettodiscounted', 0]}},
                discount: {$sum: {$cond: [{$eq: ['$storno', false]}, '$discount', 0]}},
                tax: {$sum: {$cond: [{$eq: ['$storno', false]}, '$tax', 0]}},
                taxdiscounted: {$sum: {$cond: [{$eq: ['$storno', false]}, '$taxdiscounted', 0]}},
                storno: {$sum: {$cond: [{$eq: ['$storno', true]}, '$total', 0]}},
                avg: {$avg: {$cond: [{$eq: ['$storno', false]}, '$total', 0]}},
                stddev: {$stdDevPop: {$cond: [{$eq: ['$storno', false]}, '$total', 0]}},
                max: {$max: {$cond: [{$eq: ['$storno', false]}, '$total', 0]}},
                min: {$min: {$cond: [{$eq: ['$storno', false]}, '$total', 0]}},
                cash: {
                  $sum: {
                    $cond: [
                      {
                        $and: [
                          {$eq: ['$storno', false]},
                          {$eq: ['$paytype', 'cash']}
                        ]
                      }, '$total', 0]
                  }
                },
                card: {
                  $sum: {
                    $cond: [
                      {
                        $and: [
                          {$eq: ['$storno', false]},
                          {$eq: ['$paytype', 'card']}
                        ]
                      }, '$total', 0]
                  }
                }
              }
            };
          }
          else if (type === 'check') {
            specficquery = {
              $group: {
                _id: groupid, totalcount: {$sum: 1},
                invcount: {$sum: {$cond: [{$eq: ['$storno', false]}, 1, 0]}},
                stornocount: {$sum: {$cond: [{$eq: ['$storno', true]}, 1, 0]}},
                total: {$sum: {$cond: [{$eq: ['$storno', false]}, '$total', 0]}},
                netto: {$sum: {$cond: [{$eq: ['$storno', false]}, '$netto', 0]}},
                nettodiscounted: {$sum: {$cond: [{$eq: ['$storno', false]}, '$nettodiscounted', 0]}},
                discount: {$sum: {$cond: [{$eq: ['$storno', false]}, '$discount', 0]}},
                tax: {$sum: {$cond: [{$eq: ['$storno', false]}, '$tax', 0]}},
                taxdiscounted: {$sum: {$cond: [{$eq: ['$storno', false]}, '$taxdiscounted', 0]}},
                storno: {$sum: {$cond: [{$eq: ['$storno', true]}, '$total', 0]}},
                avg: {$avg: {$cond: [{$eq: ['$storno', false]}, '$total', 0]}},
                stddev: {$stdDevPop: {$cond: [{$eq: ['$storno', false]}, '$total', 0]}},
                max: {$max: {$cond: [{$eq: ['$storno', false]}, '$total', 0]}},
                min: {$min: {$cond: [{$eq: ['$storno', false]}, '$total', 0]}},
                open: {
                  $sum: {
                    $cond: [
                      {
                        $and: [
                          {$eq: ['$storno', false]},
                          {$eq: ['$paid', false]}
                        ]
                      }, '$total', 0]
                  }
                },
                paid: {
                  $sum: {
                    $cond: [
                      {
                        $and: [
                          {$eq: ['$storno', false]},
                          {$eq: ['$paid', true]}
                        ]
                      }, '$total', 0]
                  }
                }
              }
            };
          }
          
          return specficquery;
        }
        
        thismonthquerycash = [mongomatchstartend(moment().startOf('month').toISOString(), moment().add(1, 'days').toISOString()),
          mongogenblock('cash', 'this')];
        thismonthquerycheck = [mongomatchstartend(moment().startOf('month').toISOString(), moment().add(1, 'days').toISOString()),
          mongogenblock('check', 'this')];
        
        thisquarterquerycash = [mongomatchstartend(moment().startOf('quarter').toISOString(), moment().add(1, 'days').toISOString()),
          mongogenblock('cash', 'this')];
        thisquarterquerycheck = [mongomatchstartend(moment().startOf('quarter').toISOString(), moment().add(1, 'days').toISOString()),
          mongogenblock('check', 'this')];
        
        thisyearquerycash = [mongomatchstartend(moment().startOf('year').toISOString(), moment().add(1, 'days').toISOString()),
          mongogenblock('cash', 'this')];
        thisyearquerycheck = [mongomatchstartend(moment().startOf('year').toISOString(), moment().add(1, 'days').toISOString()),
          mongogenblock('check', 'this')];
        
     
        
        
        
        // cashinvoices this month
        $http.post('/routes/cashinvoices/aggregate', thismonthquerycash).then(
          function success(response) {
            if (angular.isArray(response.data) && response.data.length > 0) {
              vm.thisyear.monthcashstats = response.data[0];
            }
            else {
              vm.thisyear.monthcashstats = 0;
            }
          },
          function error(response) {
            $log.debug('thismonthquerycash Aggregate failed: ', response);
          });
        
        // checkinvoices this month
        $http.post('/routes/checkinvoices/aggregate', thismonthquerycheck).then(
          function success(response) {
            if (angular.isArray(response.data) && response.data.length > 0) {
              vm.thisyear.monthcheckstats = response.data[0];
            }
            else {
              vm.thisyear.monthcheckstats = 0;
            }
          },
          function error(response) {
            $log.debug('thismonthquerycheck Aggregate failed: ', response);
          });
        
        // cashinvoices this quarter
        $http.post('/routes/cashinvoices/aggregate', thisquarterquerycash).then(
          function success(response) {
            if (angular.isArray(response.data) && response.data.length > 0) {
              vm.thisyear.quartercashstats = response.data[0];
            }
            else {
              vm.thisyear.quartercashstats = 0;
            }
          },
          function error(response) {
            $log.debug('thisquarterquerycash Aggregate failed: ', response);
          });
        
        // checkinvoices this quarter
        $http.post('/routes/checkinvoices/aggregate', thisquarterquerycheck).then(
          function success(response) {
            if (angular.isArray(response.data) && response.data.length > 0) {
              vm.thisyear.quartercheckstats = response.data[0];
            }
            else {
              vm.thisyear.quartercheckstats = 0;
            }
          },
          function error(response) {
            $log.debug('thisquarterquerycheck Aggregate failed: ', response);
          });
        
        // cashinvoices this year
        $http.post('/routes/cashinvoices/aggregate', thisyearquerycash).then(
          function success(response) {
            if (angular.isArray(response.data) && response.data.length > 0) {
              vm.thisyear.yearcashstats = response.data[0];
            }
            else {
              vm.thisyear.yearcashstats = 0;
            }
          },
          function error(response) {
            $log.debug('thisyearquerycash Aggregate failed: ', response);
          });
        
        // checkinvoices this year
        $http.post('/routes/checkinvoices/aggregate', thisyearquerycheck).then(
          function success(response) {
            if (angular.isArray(response.data) && response.data.length > 0) {
              vm.thisyear.yearcheckstats = response.data[0];
            }
            else {
              vm.thisyear.yearcheckstats = 0;
            }
          },
          function error(response) {
            $log.debug('thisyearquerycheck Aggregate failed: ', response);
          });
        
        
        // monthly stats for the whole business period
        
        monthlyquerycash = [{$match: {test: false}}, mongogenblock('cash', 'month')];
        monthlyquerycheck = [{$match: {test: false}}, mongogenblock('check', 'month')];
      
        
        // cashinvoices per month
        $http.post('/routes/cashinvoices/aggregate', monthlyquerycash).then(
          function success(response) {
            if (angular.isArray(response.data) && response.data.length > 0) {
              vm.monthlycashstats = response.data;
              $log.debug('monthlycashstats: ', vm.monthlycashstats);
            }
            else {
              vm.monthlycashstats = 0;
            }
          },
          function error(response) {
            $log.debug('monthlychash Aggregate failed: ', response);
          });
        
        // checkinvoices per month
        $http.post('/routes/checkinvoices/aggregate', monthlyquerycheck).then(
          function success(response) {
            if (angular.isArray(response.data) && response.data.length > 0) {
              vm.monthlycheckstats = response.data;
              $log.debug('monthlycheckstats: ', vm.monthlycheckstats);
            }
            else {
              vm.monthlycheckstats = 0;
            }
          },
          function error(response) {
            $log.debug('monthlyquerycheck Aggregate failed: ', response);
          });
        
        // yearly stats for the whole business period
        yearlyquerycash = [{$match: {test: false}}, mongogenblock('cash', 'year')];
        yearlyquerycheck = [{$match: {test: false}}, mongogenblock('check', 'year')];
        
        // chashinvoices per year
        $http.post('/routes/cashinvoices/aggregate', yearlyquerycash).then(
          function success(response) {
            if (angular.isArray(response.data) && response.data.length > 0) {
              vm.yearlycashstats = response.data;
              $log.debug('yearlycashstats: ', vm.yearlycashstats);
            }
            else {
              vm.yearlycashstats = 0;
            }
          },
          function error(response) {
            $log.debug('yearlycashstats Aggregate failed: ', response);
          });
        
        // Checkinvoices per year
        $http.post('/routes/checkinvoices/aggregate', yearlyquerycheck).then(
          function success(response) {
            if (angular.isArray(response.data) && response.data.length > 0) {
              vm.yearlycheckstats = response.data;
              $log.debug('yearlycheckstats: ', vm.yearlycheckstats);
            }
            else {
              vm.yearlycheckstats = 0;
            }
          },
          function error(response) {
            $log.debug('yearlycheckstats Aggregate failed: ', response);
          });
        
      } // end controller
      
      
      // how to sum up money in mongodb
      // db.checkinvoices.aggregate([{$group: {_id:null, grandtotal: {$sum: "$total"}}}])
      // paid invoices
      // db.checkinvoices.aggregate({$match:{ $and: [{storno: false}, {paid: true}]}}, {$group: { _id:null, totalpaid: { $sum: "$total"}}})
      // open invoices
      // db.checkinvoices.aggregate({$match:{ $and: [{storno: false}, {paid: false}]}}, {$group: { _id:null, totalopen: { $sum: "$total"}}})
    };
  }

// klammerfriedhof
}());
