(function () {
  'use strict';

  angular
    .module('drug')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('drug', {
        url: '/drug',
        templateUrl: 'drug/drug.tpl.html',
        controller: 'DrugCtrl',
        controllerAs: 'drug'
      });
  }
}());
