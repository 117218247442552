(function () {
  'use strict';
  
  /**
   * @ngdoc service
   * @name tracker.factory:Trackercommons
   *
   * @description
   *
   */
  angular
    .module('tracker')
    .factory('Trackercommons', Trackercommons);
  
  
  Trackercommons.$inject = ['$log'];
  
  function Trackercommons($log) {
    var TrackercommonsBase = {};
    
    TrackercommonsBase.addTracker = function (patient, name, unit, min, max) {
      var tmp = {};
      var dataset;
      var normmin;
      var normmax;
      
      $log.debug('Min: ', min);
      $log.debug('Max: ', max);
      
      
      tmp.graphoptions = {
        legend: true
      };
      tmp.groups = [];
      tmp.items = [];
      tmp.normmin = min;
      tmp.normmax = max;
      tmp.name = name;
      tmp.unit = unit;
      tmp.active = true;
      
      
      // actual data
      dataset = {
        id: 0,
        content: name,
        options: {
          drawPoints: true,
          interpolation: true
        }
      };
      
      tmp.groups.push(dataset);
      
      // dummy data to show norm references
      if (angular.isNumber(min) && angular.isNumber(max)) {
        $log.debug('Min and Max are number');
        normmax = {
          id: 1,
          content: 'Normbereich',
          options: {
            drawPoints: false,
            interpolation: true,
            className: 'refgreen',
            shaded: {
              orientation: 'group',
              groupId: '2'
            }
          }
        };
        
        normmin = {
          id: 2,
          content: 'Unterer Normwert',
          options: {
            excludeFromLegend: true,
            drawPoints: false,
            interpolation: true
          }
        };
  
        tmp.groups.push(normmax);
        tmp.groups.push(normmin);
        $log.debug('tmp object: ', tmp);
      }
      
      
      if (!angular.isArray(patient.trackers)) {
        patient.trackers = [];
      }
      patient.trackers.push(tmp);
      patient.$save();
    };
    
    
    TrackercommonsBase.addDatapoint = function (patient, trackerindex, date, data, multi) {
      var tmp = {};
      var min = {};
      var max = {};
      tmp.x = date;
      tmp.y = data;
      tmp.group = 0; // actual data resides in group 0, reference data in groups 1 & 2

      if (!angular.isArray(patient.trackers[trackerindex].items)) {
        patient.trackers[trackerindex].items = [];
      }
      
      // push data
      patient.trackers[trackerindex].items.push(tmp);
      
      // push reference data
      if (angular.isNumber(patient.trackers[trackerindex].normmin) && angular.isNumber(patient.trackers[trackerindex].normmax)) {
        max.x = date;
        max.y = patient.trackers[trackerindex].normmax;
        max.group = 1;
        min.x = date;
        min.y = patient.trackers[trackerindex].normmin;
        min.group = 2;
        patient.trackers[trackerindex].items.push(max);
        patient.trackers[trackerindex].items.push(min);
      }

      // saving is not clever if run in a loop
      if (multi === false) {
        patient.$save();
      }
      
    };
    
    
    return TrackercommonsBase;
  }
}());
