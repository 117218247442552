(function () {
  'use strict';

  angular
    .module('utils.keywords')
    .config(config);

  config.$inject = ['$stateProvider'];
  function config($stateProvider) {
    $stateProvider
      .state('keywords', {
        url: '/keywords',
        templateUrl: 'utils/keywords/keywords.tpl.html',
        controller: 'KeywordsCtrl',
        controllerAs: 'keywords'
      });
  }
}());
