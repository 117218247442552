<h3>Testpatienten generieren</h3>

<form class="form-vertical">
  <div class="form-group">
    <label for="patnr">Anzahl</label>
    <div class="input-group">
      <input type="text" ng-model="tdCtrl.numberofsets" class="form-control" id="patnr">
      <button type="submit" class="bt btn-success" ng-click="tdCtrl.generatePatients()"><i class="fa fa-copy"></i></button>
    </div>

  </div>
</form>

<h3>Datensätze generiert: <span ng-bind="tdCtrl.setsgenerated"></span></h3>
<h1 ng-show="tdCtrl.setsgenerated === tdCtrl.numberofsets">Fertig!</h1>
