(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name service.factory:Service
   *
   * @description
   *
   */
  angular
    .module('service')
    .factory('Service', Service);

  Service.$inject = ['restmod'];

  function Service(restmod) {
    return restmod.model('/routes/servicecatalog').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true}
    });
  }


}());
