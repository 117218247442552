<div class="row">
  <div class="col-xs-12">
    <form class="form-vertical">
      <!-- -->
      <div class="row"
           ng-repeat="drug in dpCtrl.patient.drugs | filter: {active: true} | filter: {foreign: false} | orderBy: 'name'">
        <div class="col-xs-1">
          <input type="checkbox" ng-change="dpCtrl.calculateActivedrugs()" ng-model="drug.print"/>
        </div>
        <div class="col-xs-6 col-sm-6">{{drug.name}}, {{drug.amount}}, {{drug.op}}</div>
        <div class="col-xs-1 col-sm-1">
          <button uib-popover-template="dpCtrl.drugedittpl"
                  popover-placement="bottom" type="button"
                  class="btn btn-primary btn-small">
            Details
          </button>
        </div>
        <div class="col-xs-2 col-sm-2">
         
          <span class="input-group" ng-blur="dpCtrl.checkSignatureUpdate(drug)">
            <input class="form-control" ng-model="drug.newamount" id="newdrugamount"
                   uib-tooltip="Neue Einnnahmeverordnung eingeben"
                   autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                   ng-blur="dpCtrl.checkSignatureUpdate(drug)">
              <span class="input-group-btn" uib-dropdown>
                <button type="button" class="btn btn-default" uib-dropdown-toggle><span class="caret"></span></button>
                <ul id="amountmenu" class="dropdown-menu dropdown-menu-right" role="menu" uib-dropdown-menu
                    ng-hide="dpCtrl.conf.drugsignature.length">
                  <li ng-click="drug.newamount='1-0-0';dpCtrl.checkSignatureUpdate(drug)">1-0-0</li>
                  <li ng-click="drug.newamount='1-0-1';dpCtrl.checkSignatureUpdate(drug)">1-0-1</li>
                  <li ng-click="drug.newamount='0-1-0';dpCtrl.checkSignatureUpdate(drug)">0-1-0</li>
                  <li ng-click="drug.newamount='0-0-1';dpCtrl.checkSignatureUpdate(drug)">0-0-1</li>
                  <li ng-click="drug.newamount='1-1-1';dpCtrl.checkSignatureUpdate(drug)">1-1-1</li>
                  <li ng-click="drug.newamount='0-0-0-1';dpCtrl.checkSignatureUpdate(drug)">0-0-0-1</li>
                </ul>
                <ul id="amountmenu2" class="dropdown-menu dropdown-menu-right" role="menu" uib-dropdown-menu
                    ng-show="dpCtrl.conf.drugsignature.length">
                    <li ng-repeat="sig in dpCtrl.conf.drugsignature"
                        ng-click="drug.newamount=sig;dpCtrl.checkSignatureUpdate(drug)">{{sig}}</li>
                 </ul>
              </span>
            </span>
        </div>
      </div>
      
      <!--
         <div class="form-group" ng-repeat="drug in dpCtrl.patient.drugs">
           <label ng-show="drug.active && !(drug.foreign)">
             <input type="checkbox" ng-change="dpCtrl.calculateActivedrugs()"
                    ng-model="drug.print"/>
             {{drug.name}} {{drug.amount}}, {{drug.op}}
             <button uib-popover-template="dpCtrl.drugedittpl" popover-placement="bottom" type="button"
                     class="btn btn-primary btn-small">
               Details
             </button>
   
           </label>
         </div>-->
      <!-- gültig -->
      <div class="form-group">
      <label>Gültig </label>
        <div class="radio" ng-init="dpCtrl.pdate = true">
          <label class="radio-inline">
            <input type="radio" ng-value="true" ng-model="dpCtrl.pdate"
                   ng-click="dpCtrl.patient.prescriptiondate = moment().locale('de-at').format('ll')">
            1 Monat
          </label>
          <label class="radio-inline">
            <input type="radio" ng-value="false" ng-model="dpCtrl.pdate"
                   ng-click="dpCtrl.patient.prescriptiondate = ''">
            Kein Datum
          </label>
        </div>
      </div>
      <!-- buttons -->
      <div class="form-group">
        <button class="btn btn-info" ng-hide="dpCtrl.dialog" ng-click="dpCtrl.previewPrescription();">
          <i class="fa fa-eye"></i> PDF Vorschau
        </button>
        <button class="btn btn-primary" ng-show="dpCtrl.dialog" ng-click="dpCtrl.dialog.close();">Speichern &amp;
          Beenden
        </button>
        <button class="btn btn-primary" ng-hide="dpCtrl.dialog" ng-click="dpCtrl.printPrescription();">
          <i class="fa fa-print"></i> Speichern &amp;
          gleich drucken
        </button>
      </div>
    </form>
  </div>
</div>