(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name utils.fileuploader.directive:imagecapture
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="utils.fileuploader">
       <file name="index.html">
        <imagecapture></imagecapture>
       </file>
     </example>
   *
   */
  angular
    .module('utils.fileuploader')
    .directive('imagecapture', imagecapture);

  imagecapture.$inject = ['Fileupload', '$log'];

  function imagecapture(Fileupload, $log) {
    return {
      restrict: 'EA',
      scope: {
        filehandle: '=',
        captureid: '@',
        label: '=?'
      },
      templateUrl: 'utils/fileuploader/imagecapture-directive.tpl.html',
      replace: false,
      controllerAs: 'icCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'fileupload';
        vm.upload = upload;

        $log.debug('catureid: ', vm.captureid);
        function upload() {
          // vm.icfile.type = 'Bild/Foto';
          Fileupload.uploadFileToUrl(vm.icfile, '/routes/upload')
            .success(function (res) {
              $log.debug('Fileupload successful: ', res);
              vm.filehandle = res;
              $log.debug('Filehandle: ', vm.filehandle);
            })
            .error(function (res) {
              $log.debug('Fileupload error');
              return res;
            });
        }

      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        element.bind('change', function (e) {
          // ctrl.icfile = (e.srcElement || e.target).files[0];
          console.log('element: ', e);
          ctrl.icfile = (e.srcElement || e.target).files[0];
          ctrl.upload();
        });
      }
    };
  }
}());
