<div class="form-group">
  <div class="input-group">
    <input type="text" class="form-control"
           autofocus
           placeholder="Nachname, SVNr, Stichwort"
           ng-model="psCtrl.asyncSelected"
           typeahead-on-select="$parent.$dismiss();psCtrl.showpatient($item, $model, $label)"
           uib-typeahead="pat as pat.text for pat in psCtrl.getNames($viewValue)"
           typeahead-loading="loadingNames"
           typeahead-no-results="noResults">
    <span class="input-group-btn">
      <button type="submit" class="btn btn-success"><i class="fa fa-search"></i></button>
    </span>
  </div>
</div>


<i ng-show="loadingNames" class="glyphicon glyphicon-refresh"></i>
<div ng-show="noResults">
  <i class="glyphicon glyphicon-remove"></i> Keine Patienten gefunden
</div>
