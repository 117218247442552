(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name chat.factory:Chat
   *
   * @description
   *
   */
  angular
    .module('chat')
    .factory('Chat', Chat);


  Chat.$inject = ['vertxEventBusService', '$log'];

  function Chat(vertxEventBusService, $log) {
    var ChatBase = {};
    ChatBase.chatlog = [];
    ChatBase.sendMsg = sendMsg;
    ChatBase.getChatlog = getChatlog;

    function getChatlog() {
      return ChatBase.chatlog;
    }

    function sendMsg(msg) {
      vertxEventBusService.send('chat.to.server', msg);
    }

    vertxEventBusService.on('chat.to.client', function (message) {
      var audio = new Audio('img/submarine.wav');
      audio.play();
      $log.debug('Received a message: ', message);
      
      ChatBase.chatlog.push(message);
    });


    return ChatBase;
  }



}());
