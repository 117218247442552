<!-- This is a very important hack: DO NOT REMOVE this totally pointless invisible bindings -->
<!-- Removal of these bindings will cause exam and consult close detection to fail -->
<span style="visibility: hidden">Consult Status patient: <span ng-bind='fpCtrl.patient.inconsult'></span> </span>
<span style="visibility: hidden">Consult Status openConsult: <span
    ng-bind='fpCtrl.patient.openConsult.open'></span> </span>
<span style="visibility: hidden">Exam Status patient: <span ng-bind='fpCtrl.patient.inexam'></span> </span>
<span style="visibility: hidden">Exam Status openExam: <span ng-bind='fpCtrl.patient.openExam.open'></span> </span>
<span style="visibility: hidden">openExam type: <span
    ng-bind='fpCtrl.patient.openExam.template.displayname'></span> </span>
<span style="visibility: hidden">Consultstate: <span ng-bind='fpCtrl.patient.consultstate'></span> </span>
<span style="visibility: hidden">Checkoutstate: <span ng-bind='fpCtrl.patient.checkoutstate'></span> </span>


<!-- not in consult -->
<div ng-hide="fpCtrl.patient.inconsult == true && fpCtrl.patient.openConsult.open === true">
  <div class="alert alert-info">
    <h4>Die Konsultation wurde von anderer Stelle beendet</h4>
    <span class="btn btn-primary" ng-click="fpCtrl.$state.go('patientrecord.history')">Zur Patienten-Historie wechseln</span>
  </div>
</div>

<!-- not in exam -->
<div ng-hide="fpCtrl.patient.inexam == true && fpCtrl.patient.openExam.open === true">
  <div class="alert alert-info">
    <h4>Die Untersuchung wurde von anderer Stelle beendet</h4>
    <span class="btn btn-primary" ng-click="fpCtrl.$state.go('patientrecord.current.consult')">Zur Konsultation wechseln</span>
  </div>
</div>

<!-- in consult, already in checkout-->
<div
    ng-show="fpCtrl.patient.inconsult == true && fpCtrl.patient.openConsult.open === true && fpCtrl.patient.incheckout === true">
  <div class="alert alert-info">
    <h4>Diese Konsultation wird gerade abgeschlossen</h4>
    <span class="btn btn-primary" ng-click="fpCtrl.$state.go('patientrecord.checkout.' + fpCtrl.patient.checkoutstate)">
      Zum Konsultationsabschluß wechseln
    </span>
  </div>
</div>

<div ng-show="fpCtrl.patient.inconsult == true && fpCtrl.patient.openConsult.open === true && fpCtrl.patient.incheckout !== true &&
     fpCtrl.patient.inexam == true && fpCtrl.patient.openExam.open === true &&
     fpCtrl.$state.current.name !== 'patientrecord.current.' + fpCtrl.patient.consultstate">
  <div class="alert alert-info">
    <h4>Diese Untersuchung lädt gerade Bilder/Daten</h4>
    <span class="btn btn-primary" ng-click="fpCtrl.$state.go('patientrecord.current.' + fpCtrl.patient.consultstate)">
      Zum Daten/Bilderimport wechseln
    </span>
  </div>
</div>


<!-- In consult, inexam, not already in checkout, right exam-consultstate -->
<div ng-show="fpCtrl.patient.inconsult == true && fpCtrl.patient.openConsult.open === true && fpCtrl.patient.incheckout !== true &&
fpCtrl.patient.inexam == true && fpCtrl.patient.openExam.open === true &&
fpCtrl.$state.current.name === 'patientrecord.current.' + fpCtrl.patient.consultstate">

<!-- Waiting for file to load -->
<div class="alert alert-info" ng-hide="fpCtrl.patient.openExam.filesloaded">
  <h3>Die Dateien werden geladen ...</h3>
  <h4 ng-show="fpCtrl.errorcondition">{{fpCtrl.errorcondition}} <span class="btn btn-danger" ng-click="fpCtrl.close()">Abbrechen</span> </h4>
</div>

<div  ng-show="fpCtrl.patient.openExam.filesloaded">
  <div class="row spacer-top spacer-bottom">
    <div class="col-md-2" style="text-align: right">
    <a class="btn btn-primary" ng-click="fpCtrl.previous()">
      ‹ Zur&uuml;ck
    </a>
    </div>
    <div class="col-md-6">
    <form class="form-horizontal">
        <span class="input-group">
        <span class="input-group-btn">
          <button class="btn btn-danger" type="button" ng-click="fpCtrl.fileDelete()" uib-tooltip="Löschen">
            <i class="fa fa-remove"></i>
          </button>
        </span>
          <input type="text" class="form-control" ng-model="fpCtrl.patient.openExam.files[fpCtrl.image].label"
                 id="label" autofocus
                 placeholder="Bezeichnung">
          <span class="input-group-btn">
            <button class="btn btn-success" type="submit" ng-click="fpCtrl.fileLabel()"
                    uib-tooltip="In Befund übernehmen">
              <i class="fa fa-plus"></i>
            </button>
          </span>
        </span>
    </form>
    </div>
    <div class="col-md-2">
    <a class="btn btn-primary" ng-click="fpCtrl.next()">
      Weiter ›
    </a>
      </div>
      <div class="col-md-2">
      <a class="btn btn-danger" ng-click="fpCtrl.close()">
        Abschließen
      </a>
    </div>
  </div>

  <div style="text-align: center" class="spacer-top">
  <img src="{{'/routes/files/filereader/' + fpCtrl.patient.openExam.files[fpCtrl.image].fileid}}">
  </div>

</div>
</div>