<div class="row">
  <!-- Biopsie -->
  <div class="col-xs-12 col-sm-6">

      <label ng-model="bfCtrl.efield.label">{{bfCtrl.efield.label}}</label>
      <tags-input ng-model="bfCtrl.efield.avalues"
                  display-property="name"
                  placeholder="Tippen zum Vervollständigen"
                  add-on-comma="false"
                  min-length="1"
                  tabindex="{{bfCtrl.tabi}}"
                  enable-editing-last-tag="true"
                  replace-spaces-with-dashes="false"
                  ng-blur="bfCtrl.save()"
                  template="tag-template">
        <auto-complete source="bfCtrl.loadBValues($query)"
                       min-length="0"
                       load-on-focus="true"
                       loadOnDownArrow="true"
                       load-on-empty="true"
                       max-results-to-show="32"
                       template="autocomplete-template"></auto-complete>
      </tags-input>
    </div>

  <!-- Ergebnis -->
  <div class="col-xs-12 col-sm-6">
    <label>Ergebnis</label>
    <input class="form-control" ng-model="bfCtrl.efield.result" placeholder="Ergebnis der Biopsie">
  </div>
</div>

<!-- tag templates -->
<script type="text/ng-template" id="tag-template">
  <div class="tag-template">
    <div class="right-panel">
      <span>{{$getDisplayText()}}</span>
      <a class="remove-button" ng-click="$removeTag()">&#10006;</a>
    </div>
  </div>
</script>


<script type="text/ng-template" id="autocomplete-template">
  <div class="autocomplete-template">
    <div>
      <span ng-bind-html="$highlight($getDisplayText())">{{name}}</span>
    </div>
  </div>
</script>
