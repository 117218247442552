(function () {
  'use strict';

  angular
    .module('dms.scan')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('scan', {
        url: '/scan',
        templateUrl: 'dms/scan/scan.tpl.html',
        controller: 'ScanCtrl',
        controllerAs: 'scan'
      });
  }
}());
