(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name utils.stacktrace.service:Stacktrace
   *
   * @description
   *
   */
  angular
    .module('utils.stacktrace')
    .service('Stacktrace', Stacktrace);

  function Stacktrace() {
    Stacktrace.prototype.print = function ($window, exception) {
      return $window.printStackTrace({
        e: exception
      });
    };
  }
  

  /* var StacktraceService = function() {}
   StacktraceService.prototype.print = function($window, exception) {
   return $window.printStackTrace({
   e: exception
   });
   };
   angular.module('plunker').service('stacktraceService', StacktraceService);*/

// a call to printStackTrace function with exception object will output an array of
// string as shown below:
// ["ReferenceError: $logs is not defined", "    at Scope.$scope.cancelForm (https://run.plnkr.co/iNUZMYs0Ss3pprmS/app.js:54:5)",
// "    at fn (eval at <anonymous> (https://code.angularjs.org/1.4.9/angular.js:1:0) ]


  // klammerfriedhof
}());
