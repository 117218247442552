(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name file.directive:fileBrowser
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="file">
   <file name="index.html">
   <file-browser></file-browser>
   </file>
   </example>
   *
   */
  angular
    .module('file')
    .directive('fileBrowser', fileBrowser);

  fileBrowser.$inject = ['File', 'FileAhead', '$log', '$http'];

  function fileBrowser(File, FileAhead, $log, $http) {
    return {
      restrict: 'E',
      scope: {
        patient: '=?'
      },
      templateUrl: 'file/file-browser-directive.tpl.html',
      replace: false,
      controllerAs: 'fbCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'fileBrowser';
        vm.addFile = addFile;
        vm.getTags = getTags;
        vm.saveDoc = saveDoc;
        vm.addFileComplex = addFileComplex;
        
        
        // if called in patient view only search for patient related documents
        if (angular.isObject(vm.patient)) {
          vm.allFiles = File.$search({patientid: vm.patient._id});
          vm.patdocs = true;
        }
        // otherwise grab all files
        else {
          vm.allFiles = File.$search({patientid: {$exists: false}, doccat: 'generic'});
          vm.gendocs = true;
        }

        // needed for st-table search sort
        vm.af = vm.allFiles;


        
        // upload without Vidierung
        function addFile() {
          if (vm.newfile) {
            File.$find(vm.newfile.fileid).$asPromise().then(function (file) {
              if (angular.isObject(vm.patient)) {
                if (angular.isString(vm.patient.firstname) && angular.isString(vm.patient.lastname) && angular.isString(vm.patient._id) ) {
                  file.patientid = vm.patient._id;
                  file.patientfname = vm.patient.firstname + ' ' + vm.patient.lastname;
                }
                file.$save();
              }
              vm.allFiles.$refresh();
              vm.af = vm.allFiles;
            });
            vm.newfile = null;
          }
        }

        // upload with Vidierung
        function addFileComplex() {
          if (vm.newfilecomplex) {
            File.$find(vm.newfilecomplex.fileid).$asPromise().then(function (file) {
              if (angular.isObject(vm.patient)) {
                if (angular.isString(vm.patient.firstname) && angular.isString(vm.patient.lastname) && angular.isString(vm.patient._id) ) {
                  file.patientid = vm.patient._id;
                  file.patientfname = vm.patient.firstname + ' ' + vm.patient.lastname;
                }
                file.$save();
              }
              vm.allFiles.$refresh();
              vm.af = vm.allFiles;
            });
            vm.newfilecomplex = null;
          }
        }

        
        function saveDoc(doc) {
          var flattags = [];
          var i = 0;
          $log.debug('doc: ', doc);
          if (angular.isArray(doc.keywords)) {
            for (i = 0; i < doc.keywords.length; i++) {
              flattags.push(doc.keywords[i].text);
            }
            doc.flattags = flattags;
            doc.$save().$then(function () {
              vm.allFiles.$refresh();
            });
          }
        }


        // tagahead search
        function getTags(val) {
          var myregex = '^' + val; // when using $regex, do not include '/' for regex delimiter
          var match = [
            {'$match': {'flattags': {'$regex': myregex, '$options': 'i'}}},
            {'$unwind': '$flattags'},
            {'$match': {'flattags': {'$regex': myregex, '$options': 'i'}}},
            {'$group': {'_id': '$flattags',  'total': {$sum: 1}}}];
    
          return $http.post('/routes/files/aggregate', match).then(function (response) {
            var i;
            var tags = [];
            // $log.debug('tags data received: ', response);
            for (i = 0; i < response.data.length; i++) {
              tags.push({text: response.data[i]._id});
            }
            return tags;
          });
        }
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */

        // watch for fileuploads and image captures
        scope.$watch('fbCtrl.newfile', function () {
          ctrl.addFile();
        }, true);
  
        scope.$watch('fbCtrl.newfilecomplex', function () {
          ctrl.addFileComplex();
        }, true);
      }
    };
  }
}());
