 (function () {
  'use strict';

  /* @ngdoc object
   * @name document
   * @description
   *
   */
  angular
    .module('document', [
      'ui.router',
      'smart-table'
    ]);
}());
