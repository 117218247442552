(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name bmfexport.factory:Bmfexport
   *
   * @description
   *
   */
  angular
    .module('finance.bmfexport')
    .factory('Bmfexport', Bmfexport);

  Bmfexport.$inject = ['restmod'];

  function Bmfexport(restmod) {
    return restmod.model('/routes/bmfexport');
  }
}());
