<h3>Dokumente</h3>

<div class="alert alert-info" ng-show="fbCtrl.allFiles.length === 0">Keine Dokumente gefunden</div>

<!-- upload buttons -->
<!-- Use of " ' ' " is necessary! won't be identified as string otherwise, will look for variable instead! -->
<!-- Can't have attributes with default values - no @? allowed :-( -->
<fileupload filehandle="fbCtrl.newfile" furl="'/routes/upload'" color="'warning'" label="'Datei laden OHNE Vidierung'"></fileupload>&nbsp;&nbsp;
<fileupload filehandle="fbCtrl.newfilecomplex" furl="'/routes/uploadcomplex'" color="'success'" label="'Datei laden MIT Vidierung'"></fileupload>

<!-- uploaded and scaned files -->
<table st-table="fbCtrl.af" st-safe-src="fbCtrl.allFiles" class="table table-striped">
    <thead>
    <tr>
        <th st-sort="created" st-sort-default="reverse">Datum</th>
        <th st-sort="type">Typ</th>
        <th st-sort="keywords">Stichworte</th>
        <th>Datei</th>
    </tr>
    <tr>
        <th><input st-search="created" placeholder="Suche nach Datum" class="input-sm form-control" type="search"/></th>
        <th><input st-search="type" placeholder="Suche nach Typ" class="input-sm form-control" type="search"/></th>
        <th><input st-search="keywords" placeholder="Suche nach Stichwort" class="input-sm form-control" type="search"/>
        </th>
        <th></th>
    </tr>
    </thead>
    <tbody>
    <tr ng-repeat="doc in fbCtrl.af">
        <!--<td>{{doc.created | javadate: 'll'}}</td>-->
        <td><a href="/routes/files/filereader/{{doc.fileid}}" target="_blank">{{doc.created | moment: 'll'}}</a></td>
        <td><input type="text" class="form-control" ng-model="doc.type" ng-blur="doc.$save()"></td>
        <td>
            <tags-input ng-model="doc.keywords"
                        display-property="text"
                        id="dockeywords"
                        add-on-paste="true"
                        add-on-comma="false"
                        min-length="1"
                        ng-blur="fbCtrl.saveDoc(doc)"
                        enable-editing-last-tag="true"
                        add-from-autocomplete-only="false"
                        replace-spaces-with-dashes="false"
                        template="tag-template">

                <auto-complete source="fbCtrl.getTags($query)"
                               min-length="1"
                               load-on-focus="true"
                               load-on-empty="false"
                               load-on-down-arrow="true"
                               max-results-to-show="32"
                               select-first-match="true"
                               template="autocomplete-template">
                </auto-complete>

            </tags-input>
        </td>

        <!--<td><a href="/routes/files/filereader/{{doc.fileid}}" target="_blank">{{doc.type}}</a></td>
        <td><a href="/routes/files/filereader/{{doc.fileid}}" target="_blank"><span ng-repeat="tag in doc.flattags">
            <span class="label label-primary">{{tag}}</span>
            </span></a>
        </td>-->
        <td>
            <a href="/routes/files/filereader/{{doc.fileid}}" target="_blank" class="btn btn-primary"><i class="fa fa-eye"></i></a>
            <!--TODO: get the backend working, than enable this-->
            <!--<a href="/routes/files/filedownload/{{doc.fileid}}" class="btn btn-primary"><i class="fa fa-download"></i></a>-->
        </td>
        <td></td>
    </tr>
    </tbody>
</table>


<!-- Tag autocomplete templates -->
<script type="text/ng-template" id="tag-template">
    <div class="tag-template">
        <div class="right-panel">
            <span>{{$getDisplayText()}}</span>
            <a class="remove-button" ng-click="$removeTag()">&#10006;</a>
        </div>
    </div>
</script>

<script type="text/ng-template" id="autocomplete-template">
    <div class="autocomplete-template">
        <div>
            <span ng-bind-html="$highlight($getDisplayText())">{{text}}</span>
        </div>
    </div>
</script>


