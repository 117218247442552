(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name todo.factory:Todoservice
   *
   * @description
   *
   */
  angular
    .module('todo')
    .factory('Todo', Todo)
    .config(config);

  config.$inject = ['restmodProvider'];

  function config(restmodProvider) {
    restmodProvider.rebase({
      $config: {
        primaryKey: '_id'
      }
    });
  }


  Todo.$inject = ['restmod'];

  function Todo(restmod) {
    return restmod.model('/routes/todos').mix({
      // owner: { belongsTo: 'User', key: 'userid' }, // todo add user to todowidget
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true},
      done: {init: false},
      owner: {init: ''}
    });
  }


}());
