(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name helpers.zipcode.factory:Zipcode
   *
   * @description
   *
   */

  angular
    .module('helpers.zipcode')
    .factory('Zipcode', Zipcode)
    .factory('Zipcodesearch', Zipcodesearch);

  Zipcode.$inject = ['restmod'];
  function Zipcode(restmod) {
    return restmod.model('/routes/zipcodes');
  }

  Zipcodesearch.$inject = ['restmod'];
  function Zipcodesearch(restmod) {
    return restmod.model('/routes/zipcodes/numahead');
  }



}());
