(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name examtemplate.factory:Examtemplate
   *
   * @description
   *
   */

  var obauchsono = {
    type: 'obauchsono',
    displayname: 'Oberbauchsonografie',
    device: {label: 'Ger\u00E4t', name: 'Alpinion E-Cube-9'},
    loadfiles: true,
    shareid: '',
    fieldgroups: [
      {
        label: 'Metainformationen',
        fields: [
          {
            label: 'Untersuchender Arzt',
            avalues: [{name: 'OA. Dr. Rudolf Kantner'}],
            values: [{name: 'OA. Dr. Rudolf Kantner'}, {name: 'OA. Dr. Manfred Fleischer'}]
          },
          {
            label: 'Assistenz',
            avalues: [{name: 'DGKS Alexandra Kantner'}],
            values: [{name: 'DGKS Alexandra Kantner'}, {name: 'Keine Assistenz'}]
          }
        ]
      },
      {
        label: '',
        fields: [
          {
            label: 'Schallbedingungen',
            avalues: [[{name: 'gut'}]],
            values: [[{name: 'gut'}, {name: 'eingeschr\u00E4nkt'}, {name: 'darmgas\u00FCberlagert'}]]
          },
          {
            label: 'Leber',
            avalues: [{name: 'normal gross'}, {name: 'Lebervenen normal kalabriert'}],
            values: [{name: 'normal gross'}, {name: 'homogen'}, {name: 'glattrandig begrenzt'}, {name: 'Lebervenen normal kalabriert'}, {name: 'keine fokalen Herde'}, {name: 'vergr\u00F6ssert'}, {name: 'plumprandig'}, {name: 'Echodichte erh\u00F6ht'}, {name: 'Lebervenen verschm\u00E4lert'}, {name: 'keine fokalen L\u00E4sionen'}, {name: 'verkleinert'}, {name: 'Oberfl\u00E4che h\u00F6ckrig'}, {name: 'Bild einer Cirrhosis hepatis'}]
          },
          {
            label: 'Gallengang',
            avalues: [{name: 'normal weit'}],
            values: [{name: 'normal weit'}, {name: 'erweitert'}, {name: 'nicht darstellbar'}]
          },
          {
            label: 'Gallenblase',
            avalues: [{name: 'zartwandig'}],
            values: [{name: 'zartwandig'}, {name: 'steinfrei'}, {name: 'Konkremente'}, {name: 'entfernt'}]
          },
          {
            label: 'Pankreas',
            avalues: [{name: 'unauff\u00E4llig'}],
            values: [{name: 'unauff\u00E4llig'}, {name: 'darmgas\u00FCberlagert'}, {name: 'caput'}, {name: 'corpus unauff\u00E4llig'}, {name: 'cauda \u00FCberlagert'}]
          },
          {
            label: 'Milz',
            avalues: [{name: 'normal gross'}],
            values: [{name: 'normal gross'}, {name: 'glattrandig'}, {name: 'vergr\u00F6ssert'}, {name: 'entfernt'}]
          }
        ]
      },
      {
        label: 'Nieren',
        'marginleft': '3em',
        fields: [
          {
            label: 'rechts',
            avalues: [{name: 'normal gross'}, {name: 'normales Parenchym-Pyelonverh\u00E4ltnis'}],
            values: [{name: 'normal gross'}, {name: 'normales Parenchym-Pyelonverh\u00E4ltnis'}, {name: 'keine Stauungszeichen'}, {name: 'kein Steinnachweis'}, {name: 'erstgradige Stauungszeichen'}, {name: 'zweitgradige Stauuungszeichen'}, {name: 'drittgradige Stauungszeichen'}]
          },
          {
            label: 'links',
            avalues: [{name: 'normal gross'}, {name: 'normales Parenchym-Pyelonverh\u00E4ltnis'}],
            values: [{name: 'normal gross'}, {name: 'normales Parenchym-Pyelonverh\u00E4ltnis'}, {name: 'keine Stauungszeichen'}, {name: 'kein Steinnachweis'}, {name: 'erstgradige Stauungszeichen'}, {name: 'zweitgradige Stauuungszeichen'}, {name: 'drittgradige Stauungszeichen'}]
          }
        ]
      }
    ],
    comment: {label: 'Anmerkungen', avalues: [], values: []},
    diagnosis: {label: 'Diagnose', avalues: [], values: []},
    recommendation: {label: 'Empfehlung', avalues: [], values: []}
  };

  var emptytemplate = {
    type: 'newexam',
    displayname: 'Neue Untersuchung',
    device: {label: 'Ger\u00E4t', name: ''},
    loadfiles: false,
    shareid: '',
    fieldgroups: [
      {
        label: 'Metainformationen',
        fields: [
          {label: 'Untersuchender Arzt', avalues: [], values: []},
          {label: 'Assistenz', avalues: [], values: []},
          {label: 'Sedierung', avalues: [], values: []},
          {label: 'Narkose', avalues: [], values: []}
        ]
      },
      {
        label: 'Feldgruppe',
        fields: [
          {label: 'Neues Feld', avalues: [], values: []},
          {label: 'Neues Feld', avalues: [], values: []}
        ]
      },
      {
        label: 'Feldgruppe',
        fields: [
          {label: 'Neues Feld', avalues: [], values: []},
          {label: 'Neues Feld', avalues: [], values: []}
        ]
      }
    ],
    biopsy: {
      label: 'Biopsien',
      values: [],
      biopsies: [{label: '', avalues: [], values: []}]
    },
    comment: {label: 'Anmerkungen', avalues: [], values: []},
    diagnosis: {label: 'Diagnose', avalues: [], values: []},
    recommendation: {label: 'Empfehlung', avalues: [], values: []}
  };

  angular
    .module('examtemplate')
    .factory('Examtemplate', Examtemplate)
    .factory('Sampletemplate', Sampletemplate)
    .config(config);

  config.$inject = ['restmodProvider'];

  function config(restmodProvider) {
    restmodProvider.rebase({
      $config: {
        primaryKey: '_id'
      }
    });
  }


  Examtemplate.$inject = ['restmod'];

  function Examtemplate(restmod) {
    return restmod.model('/routes/examtemplates').mix({

      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      description: {init: ''},
      active: {init: true},
      template: {init: emptytemplate}
    });
  }


  /* todo: why dosn't this work?
   LoadSampletemplate.$inject = ['Examtemplate'];

   function LoadSampletemplate() {
   var samples = Examtemplate.$search();
   return samples.$create(obauchsono);
   }*/


  Sampletemplate.$inject = ['restmod'];

  function Sampletemplate(restmod) {
    return restmod.model('/routes/examtemplates').mix({

      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      description: {init: ''},
      active: {init: true},
      template: {init: obauchsono}
    });
  }


// klammerfriedhof
}());
