(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name config.factory:RksvHelper
   *
   * @description
   *
   */
  angular
    .module('finance.rksv')
    .factory('RksvHelper', RksvHelper);

  function RksvHelper() {
    var RksvHelperBase = {};
    
    
    
    function getNullBeleg() {
      var invoice = {};
      var invoiceobj = {};
      var patient = {
        _id: '123412341234123412341234',
        firstname: 'Maximilian',
        lastname: 'Mustermann'
      };
    
      invoice.date = moment().toISOString();
      invoice.fdate = moment(invoice.date).locale('de-at').format('ll');
      invoice.ftime = moment(invoice.date).locale('de-at').format('LTS');
    
      invoice.storno = false;
      invoice.test = false;
    
      // invoice.items = vm.patient.openConsult.service.items;
      // get rid of unselected excess items that clutter up the database
      invoice.items = [
        {
          _id: '56ae72d68dd4bd59ac0423af',
          created: '2016-02-09T21:21:30.978Z',
          active: true,
          cat: '0',
          group: '0',
          num: '00',
          name: 'Nullleistung',
          opoints: 0,
          points: 0,
          tax: 0,
          comment: 'Nullbeleg für Finanzamt',
          price: 0.0,
          include: true,
          selected: true
        }
      ];
    
      invoice.netto = 0.00;
      invoice.totalbefore = 0.00;
      invoice.nettodiscounted = 0.00;
      invoice.bruttodiscounted = 0.00;
      invoice.taxdiscounted = 0.00;
      invoice.discount = 0.00;
      invoice.tax = 0.00;
      // discounttaxsum is actually an array with the accumulated tax per tax bracket
      // discounttaxsum kept for backward compatibility, new type is discounttaxarray
      invoice.discounttaxsum = {'0': 0.00};
      invoice.discounttaxarray = {'0': 0.00};
      invoice.discountnettoarray = {'0': 0.00};
      invoice.discountbruttoarray = {'0': 0.00};
      invoice.total = 0.00;
      invoice.invoicediagnoses = 'foo';
      // invoice.consultid = vm.patient.openConsult._id;
    
      invoice.paytype = 'cash';
      invoice.paid = true;
      invoice.type = 'Barrechnung';
      invoice.cash = true;
      invoice.paiddate = invoice.date;
      invoiceobj.patient = patient;
      invoiceobj.patient.openConsult = {};
      invoiceobj.patient.openConsult.invoice = invoice;
    
      invoiceobj.patientid = patient._id;
      invoiceobj.consultid = 'nullbeleg';
      invoiceobj.rootelement = 'patient';
      
      return invoiceobj;
    }
    
    
    RksvHelperBase.getNullBeleg = getNullBeleg;
    return RksvHelperBase;
  }
}());
