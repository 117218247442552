(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name forms.directive:aide
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="forms">
       <file name="index.html">
        <aide></aide>
       </file>
     </example>
   *
   */
  angular
    .module('forms')
    .directive('aide', aide);
  
  aide.$inject = ['$log', 'Patientsearch', '$http', '$state', '$timeout'];
  
  function aide($log, Patientsearch, $http, $state, $timeout) {
    return {
      restrict: 'E',
      scope: {},
      templateUrl: 'forms/aide-directive.tpl.html',
      replace: false,
      controllerAs: 'aidCtrl',
      controller: function () {
        var vm = this;

        vm.print = print;
        vm.clear = clear;
        vm.getNames = getNames;
        vm.showpatient = showpatient;
        vm.newaide = {};
        vm.newaide.pdate = true;
        vm.newaide.aidedate = moment().locale('de-at').format('ll');
  
        // typeahead patient search backend query
        function getNames(val) {
          console.log('loading names');
    
          return Patientsearch.$search({query: val}).$asPromise().then(function (response) {
            console.log('response: ', response);
            return response.map(function (patient) {
              patient.text = patient.firstname + ' ' + patient.lastname + ', ' + patient.svnr;
              return patient;
            });
          });
        }
  
        // typeahead patient search action
        function showpatient($item, $model, $label) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          vm.patientid = $model._id;
        }
        
        
        function print() {
          var request;
          var payload = {};
          payload.aides = [];
          payload.aides.push(vm.newaide);
          
          request = $http({
            url: '/routes/pdfprinter/genpatientprint',
            method: 'POST',
            data: {
              patientid: vm.patientid,
              doctype: 'aide',
              payload: payload
            }
          });
          
          request.success(function () {
            vm.printed = true;
            $timeout($state.go('home'), 3000);
          });
          request.error(function () {
            vm.printed = false;
          });
          
        }
        
        function clear() {
          vm.patient = null;
          vm.newaide = {};
          vm.newaide.pdate = true;
          vm.newaide.aidedate = moment().locale('de-at').format('ll');
        }
      
        
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
