(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name patient.controller:PatientCtrl
   *
   * @description
   *
   */
  angular
    .module('patient')
    .controller('PatientCtrl', PatientCtrl);

  PatientCtrl.$inject = ['Patient', '$stateParams', 'Config', 'OpenConsults', 'Svahead', '$log'];

  function PatientCtrl(Patient, $stateParams, Config, OpenConsults, Svahead, $log) {
    var vm = this;
    vm.Patient = Patient;
    vm.calcBMI = calcBMI;
    vm.clearRefDoctor = clearRefDoctor;
    vm.clearPractDoctor = clearPractDoctor;
    vm.clearSv = clearSv;
    // vm.validateSVNR = validateSVNR;
    // vm.vertxEventBusService = vertxEventBusService;
    vm.patientid = $stateParams.patientid;
    // vm.svs = ['N\u00D6GKK', 'SVA', 'WGKK', 'BVA', 'BKK'];

    // vm.showsv = showsv;
    // vm.getNames = getNames;
    // vm.setsvid = setsvid;
  
    // add custom valdiator directive
    //
    
    
    // on load calculate BMI
    vm.calcBMI();

    vm.clearOpenConsult = clearOpenConsult;

    vm.conf = Config.$find('configuration');

    function calcBMI() {
      if (angular.isObject(vm.patient)) {
        if (angular.isNumber(vm.patient.weight) && angular.isNumber(vm.patient.height) && vm.patient.height !== 0) {
          vm.patient.bmi = Math.round(vm.patient.weight * 10000 / (vm.patient.height * vm.patient.height) * 10) / 10;
        }
        else {
          vm.patient.bmi = 0;
        }
      }
    }


   /* function validateSVNR(svnr) {
      var nr = svnr.split('');
      var checksum = (5 * parseInt(nr[4], 10) +
        8 * parseInt(nr[5], 10) +
        4 * parseInt(nr[6], 10) +
        2 * parseInt(nr[7], 10) +
        parseInt(nr[8], 10) +
        6 * parseInt(nr[9], 10) +
        3 * parseInt(nr[0], 10) +
        7 * parseInt(nr[1], 10) +
        9 * parseInt(nr[2], 10)) % 11;
      
      $log.debug(svnr, ' valid: ', checksum === parseInt(nr[3], 10));
      return (checksum === parseInt(nr[3], 10));
    }*/
    
    function clearRefDoctor() {
      delete vm.patient.refdoctor;
      vm.patient.$save();
    }

    function clearPractDoctor() {
      delete vm.patient.practdoctor;
      vm.patient.$save();
    }
    function clearSv() {
      delete vm.patient.sv;
      vm.patient.svref = {};
      vm.patient.$save();
    }

   /* // typeahead sv search backend query
    function getNames(val) {
      console.log('loading names');

      return Svahead.$search({query: val}).$asPromise().then(function (response) {
        console.log('response: ', response);
        return response.map(function (sv) {
          sv.text = sv.short + ' ' + sv.name;
          return sv;
        });
      });
    }
  */
  
    /* function setsvid($model) {
      /!* jshint unused:false *!/
      /!* eslint "no-unused-vars": [2, {"args": "none"}] *!/
      vm.patient.svid = $model._id;
      vm.patient.svs = $model.short;
    }
    */

    function clearOpenConsult(patient) {
      patient.openConsult = null;
      patient.openExam = null;
      patient.consultstate = 'consult';
      patient.checkoutdocs = [];
      patient.inconsult = false;
      if (patient.hasOwnProperty('invoice')) {
        delete patient.invoice;
      }
      patient.$save().$asPromise().then(function () {
        OpenConsults.triggerUpdate();
      });
    }

  }
}());
