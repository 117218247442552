(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name reminder.directive:allReminder
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="reminder">
   <file name="index.html">
   <all-reminder></all-reminder>
   </file>
   </example>
   *
   */
  angular
    .module('reminder')
    .directive('allReminder', allReminder);

  allReminder.$inject = ['Reminder', 'Patient', '$log'];

  function allReminder(Reminder, Patient, $log) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'reminder/all-reminder-directive.tpl.html',
      replace: false,
      controllerAs: 'arCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.weeks = 2;
        // vm.upcoming = moment().add(2, 'weeks').toISOString();
        vm.name = 'allReminder';
        vm.save = save;
        vm.todos = {};


        vm.todos.upcoming = upcoming();
        vm.todos.overdue = dueandoverdue();
        vm.todos.lastcompleted = lastcompleted();

        vm.switchRange = switchRange;

        function lastcompleted() {
          return Reminder.$search({
            completed: {
              $lt: moment().toISOString(),
              $gte: moment().subtract(2, 'days').toISOString()
            }, done: true
          });
        }

        function upcoming() {
          return Reminder.$search({
            due: {
              $lt: moment().add(vm.weeks, 'weeks').toISOString(),
              $gte: moment().toISOString()
            }, done: false
          });
        }

        function dueandoverdue() {
          return Reminder.$search({
            due: {$lt: moment().toISOString()},
            done: false
          });
        }

        function switchRange(weeks) {
          vm.weeks = weeks;
          vm.todos.upcoming = upcoming();
        }

        function save(item) {
          var logentry = {};
          logentry.created = moment().toISOString();
          if (item.done === true) {
            item.completed = moment().toISOString();
            logentry.entry = 'Todo abgeschlossen: ' + item.label;
          }
          if (item.done === false) {
            delete item.completed;
            logentry.entry = 'Todo wieder geöffnet: ' + item.label;
          }
          item.$save().$asPromise().then(function () {
            vm.todos.upcoming = upcoming();
            vm.todos.overdue = dueandoverdue();
            vm.todos.lastcompleted = lastcompleted();
            Patient.$find(item.patientid).$asPromise().then(function (pat) {
              if (!angular.isArray(pat.log)) {
                pat.log = [];
              }
              pat.log.push(logentry);
              pat.$save();
            });
          });
        }


      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
