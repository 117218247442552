(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name utils.fileuploader.factory:Fileupload
   *
   * @description
   *
   */
  angular
    .module('utils.fileuploader')
    .factory('Fileupload', Fileupload);

  Fileupload.$inject = ['$http', '$log'];

  function Fileupload($http, $log) {
    var FileuploadBase = {};
    FileuploadBase.uploadFileToUrl = function (file, uploadUrl) {
      var fd = new FormData();
      fd.append('file', file);
      return $http.post(uploadUrl, fd, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined}
      });
    };
    return FileuploadBase;
  }
}());
