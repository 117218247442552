(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name examination.directive:fieldgroup
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="examination">
       <file name="index.html">
        <fieldgroup gi="?"></fieldgroup>
       </file>
     </example>
   *
   */
  angular
    .module('examination')
    .directive('fieldgroup', fieldgroup);

  function fieldgroup() {
    return {
      restrict: 'E',
      scope: {
        efieldgroup: '=',
        gi: '@?'
      },
      templateUrl: 'examination/exameditor-fieldgroup.tpl.html',
      replace: false,
      controllerAs: 'efgCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;  // yep, this is an empty controller - only holds the parameter
      }
    };
  }
}());
