<form class="form-inline">
  <h3>Überweisung</h3>

  <div class="form-group">
    <div class="radio">
      <b><span style="font-weight: bold">Überweisungsart</span></b><br>
      <label for="stdref" class="radio-inline"><input type="radio" value="std" tabindex="1"
                                                      ng-model="refCtrl.newreferral.reftype"
                                                      id="stdref"> Standard</label>
      <label for="labref" class="radio-inline"><input type="radio" value="lab" tabindex="2"
                                                      ng-model="refCtrl.newreferral.reftype"
                                                      ng-click="refCtrl.setlab()"
                                                      id="labref"> Labor</label>
      <!--      <label for="imgref" class="radio-inline"><input type="radio" value="img"
                                                           ng-model="refCtrl.newreferral.reftype"
                                                           ng-click="refCtrl.setimg()"
                                                           id="imgref"> Bildgebende Diagnostik</label>-->
    
    </div>
  </div>
</form>


<form class="form-vertical" id="refform" name="refform">
  <!-- Patient -->
  <div class="form-group">
    <label for="patient">Patient</label>
    <div class="input-group">
      <input type="text" class="form-control" id="patient" tabindex="3"
             required
             placeholder="Patienten Nachname, SVNr, Stichwort"
             ng-model="refCtrl.patient"
             typeahead-on-select="refCtrl.showpatient($item, $model, $label)"
             uib-typeahead="pat as pat.text for pat in refCtrl.getNames($viewValue)"
             typeahead-loading="loadingNames"
             typeahead-no-results="noResults">
      <span class="input-group-btn">
      <button type="submit" class="btn btn-success"><i class="fa fa-search"></i></button>
    </span>
    </div>
  </div>
  
  <!-- Überweisung an: -->
  <div class="form-group">
    <label for="refto">
      <span uib-tooltip="Es muß entweder ein Arzt aus der Liste ausgewählt werden oder das Feld Freitext ausgefüllt werden">
        An/Zu: Arztauswahl ODER Freie Texteingabe
      </span>
      <span uib-tooltip="Pflichtfeld">*</span>
    </label>
    <!-- Arztsuche -->
    <div class="form-group" ng-hide="refCtrl.doctor._id">
      <doctor-search doctorresult="refCtrl.doctor" ti="4"></doctor-search>
    </div>
    <div class="form-group" ng-show="refCtrl.doctor._id">
      <div class="input-group">
        <input type="text" class="form-control"
               id="refdoctorname"
               readonly
               ng-model="refCtrl.doctor.fname">
        <span class="input-group-btn">
                    <button class="btn btn-danger"
                            ng-click="refCtrl.clearRefDoctor()"><i class="fa fa-remove"></i>
                    </button>
        </span>
      </div>
    </div>
    <p ng-hide="refCtrl.doctor">ODER</p>
    <!-- Freitext -->
    <input type="text" ng-model="refCtrl.newreferral.to" tabindex="5"
           ng-required="!(refCtrl.newreferral.to || refCtrl.doctor)"
           ng-hide="refCtrl.doctor"
           placeholder="Freitext für Überweisungsziel" class="form-control" id="refto">
  </div>
  <!-- diagnose/Begründung -->
  <div class="form-group">
    <label for="refdiag">Diagnose/Begründung<span uib-tooltip="Pflichtfeld">*</span></label>
    <input required type="text" ng-model="refCtrl.newreferral.diag"
           class="form-control" id="refdiag" tabindex="6">
  </div>
  
  <!-- Selected Labvalue Output -->
  <div ng-show="refCtrl.newreferral.reftype == 'lab'">
    <labreferral allvalues="refCtrl.newreferral.labvalues"
                 additionalvalues="refCtrl.newreferral.additionalvalues"
                 ginclude="refCtrl.newreferral.ginclude"
                 ti="7">
    </labreferral>
  </div>
  
  
  <!-- Erbeten -->
  <div class="form-group" ng-hide="refCtrl.newreferral.reftype == 'lab'">
    <label for="refref">Erbeten<span uib-tooltip="Pflichtfeld">*</span></label>
    <textarea class="form-control" ng-model="refCtrl.newreferral.ref" rows="5"
              ng-required="!(refCtrl.newreferral.ref || refCtrl.newreferral.labvalues)"
              id="refref" tabindex="8"></textarea>
  </div>
  
  <!-- gültig -->
  <div class="form-group">
    <label>Gültig </label>
    <div class="radio">
      <label class="radio-inline">
        <input type="radio" ng-value="true" ng-model="refCtrl.newreferral.pdate"
               ng-click="refCtrl.newreferral.refdate = moment().locale('de-at').format('ll')">
        1 Monat</label>
      <label class="radio-inline">
        <input type="radio" ng-value="false" ng-model="refCtrl.newreferral.pdate"
               ng-click="refCtrl.newreferral.refdate = ''">
        Kein Datum</label>
    </div>
  </div>
  
  <!-- Buttons -->
  <button class="btn btn-success" ng-disabled="refform.$invalid" type="button" ng-click="refCtrl.print();" tabindex="50">
    Drucken
  </button>

  <button class="btn btn-danger" type="button" ng-click="refCtrl.clear()" tabindex="51">
    Abbrechen
  </button>
</form>


<!-- Alerts -->
<div class="alert alert-info spacer-top"
     ng-show="refCtrl.printing === true">
  <i class="fa fa-spinner fa-spin"></i> Überweisung wird gedruckt</a>.
</div><div class="alert alert-success spacer-top" ng-show="refCtrl.printed === true">
  <i class="fa fa-check"></i> Erfolgreich gedruckt. Weiter zur <a ui-sref="home">Übersicht</a>.
</div>
<div class="alert alert-error spacer-top" ng-show="refCtrl.printed === false">
  <i class="fa fa-warning"></i> Druck ist fehlgeschlagen. Weiter zur <a ui-sref="home">Übersicht</a>.
</div>

</form>
