(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name examtemplate.directive:templategenerator
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="examtemplate">
   <file name="index.html">
   <templategenerator></templategenerator>
   </file>
   </example>
   *
   */
  angular
    .module('examtemplate')
    .directive('templategenerator', templategenerator);

  function templategenerator() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'examtemplate/templategenerator-directive.tpl.html',
      replace: false,
      controller: 'ExamtemplateCtrl',
      controllerAs: 'tmCtrl',
      bondToController: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
