(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name chat.directive:chat
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="chat">
       <file name="index.html">
        <chat></chat>
       </file>
     </example>
   *
   */
  angular
    .module('chat')
    .directive('chat', chat);

  chat.$inject = ['$log', 'Chat', '$cookies'];

  function chat($log, Chat, $cookies) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'chat/chat-directive.tpl.html',
      replace: false,
      controllerAs: 'chatCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'chat';
        vm.Chat = Chat;
        vm.chatlog = vm.Chat.getChatlog();
        vm.sendMsg = sendMsg;

        function sendMsg() {
          vm.Chat.sendMsg($cookies.get('username') + ': ' + vm.newmessage);
          vm.newmessage = '';
        }



      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
