(function () {
  'use strict';

  angular
    .module('home')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    console.log('Home templates entered');
    $stateProvider
      .state('home', {
        url: '/home',
        views: {
          main: {templateUrl: 'home/home.tpl.html'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });
  }
}());
