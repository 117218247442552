(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name helpers.doctor.directive:doctorEdit
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="helpers.doctor">
       <file name="index.html">
        <doctor-edit></doctor-edit>
       </file>
     </example>
   *
   */
  angular
    .module('helpers.doctor')
    .directive('doctorEdit', doctorEdit);


  function doctorEdit() {
    return {
      restrict: 'EA',
      scope: {
        doctor: '=',
        dialog: '='
      },
      templateUrl: 'helpers/doctor/doctor-edit-directive.tpl.html',
      replace: false,
      controllerAs: 'dreCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'doctorEdit';
        vm.save = save;
        vm.close = close;

        function save() {
          if (angular.isString(vm.doctor.lastname) && !angular.isString(vm.doctor.institute)) {
            vm.doctor.fname = vm.doctor.titlefront + ' ' + vm.doctor.firstname + ' ' + vm.doctor.lastname + ', ' + vm.doctor.city;
          }
          if (!angular.isString(vm.doctor.lastname) && angular.isString(vm.doctor.institute)) {
            vm.doctor.fname = vm.doctor.institute + ', ' + vm.doctor.city;
          }
          if (angular.isString(vm.doctor.lastname) && angular.isString(vm.doctor.institute)) {
            vm.doctor.fname = vm.doctor.institute + ', ' + vm.doctor.titlefront + ' ' + vm.doctor.lastname + ', ' + vm.doctor.city;
          }

          vm.doctor.$save();
        }


        function close() {
          vm.dialog.close();
        }
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */

        scope.$watch('dreCtrl.doctor.zip', function () {
          ctrl.save();
        }, true);

        scope.$watch('dreCtrl.doctor.city', function () {
          ctrl.save();
        }, true);

      }
    };
  }
}());
