(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name consult.directive:openConsults
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="consult">
   <file name="index.html">
   <open-consults></open-consults>
   </file>
   </example>
   *
   */
  angular
    .module('consult')
    .directive('openConsults', openConsults);

  openConsults.$inject = ['OpenConsults', 'vertxEventBusService', '$state'];

  function openConsults(OpenConsults, vertxEventBusService, $state) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'consult/open-consults-directive.tpl.html',
      replace: false,
      controllerAs: 'ocCtrl',
      bindToController: true,
      controller: function ocCtrl() {
        var vm = this;
        vm.allOpenConsults = OpenConsults.allOpenConsults;

        vm.switchToPatient = switchToPatient;
        function switchToPatient(p) {
          if (p.inconsult === true && p.incheckout === true) {
            $state.go('patientrecord.checkout.' + p.checkoutstate, {patientid: p._id});
          }
          else {
            $state.go('patientrecord.current.' + p.consultstate, {patientid: p._id});
          }
        }
      },

      link: function (scope, element, attrs, ctrl) {
        // jshint unused:false
        // eslint "no-unused-vars": [2, {"args": "none"}]
        
        vertxEventBusService.on('oc.update', function () {
          ctrl.allOpenConsults.$refresh();
          console.log('oc.update caught: ', ctrl.allOpenConsults);
        });

        vertxEventBusService.on('patients.update', function () {
          ctrl.allOpenConsults.$refresh();
          console.log('oc.update caught: ', ctrl.allOpenConsults);
        });
      }
    };
  }
}());
