(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name forms.directive:referral
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="forms">
   <file name="index.html">
   <referral></referral>
   </file>
   </example>
   *
   */
  angular
    .module('forms')
    .directive('referral', referral);
  
  referral.$inject = ['$log', 'Patientsearch', '$http', '$state', '$timeout'];
  
  function referral($log, Patientsearch, $http, $state, $timeout) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'forms/referral-directive.tpl.html',
      replace: false,
      controllerAs: 'refCtrl',
      controller: function () {
        var vm = this;
        vm.print = print;
        vm.clear = clear;
        vm.getNames = getNames;
        vm.showpatient = showpatient;
        vm.clearRefDoctor = clearRefDoctor;
        initNewRef();
        
        function initNewRef() {
          vm.newreferral = {};
          vm.newreferral.refdate = '';
          vm.newreferral.pdate = false;
          vm.newreferral.ginclude = [];
          vm.newreferral.additionalvalues = [];
          vm.newreferral.reftype = 'std';
        }
        
        function clearRefDoctor() {
          delete vm.doctor;
        }
        
        // typeahead patient search backend query
        function getNames(val) {
          console.log('loading names');
          
          return Patientsearch.$search({query: val}).$asPromise().then(function (response) {
            console.log('response: ', response);
            return response.map(function (patient) {
              patient.text = patient.firstname + ' ' + patient.lastname + ', ' + patient.svnr;
              return patient;
            });
          });
        }
        
        // typeahead patient search action
        function showpatient($item, $model, $label) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          vm.patientid = $model._id;
        }
        
        
        function print() {
          var request;
          var payload = {};
          vm.printing = true;
  
          if (vm.newreferral.pdate === true) {
            vm.newreferral.refdate = moment().locale('de-at').format('ll');
          }
          else {
            vm.newreferral.refdate = '';
          }
          
          // payload.doctor = {};
          // payload.doctor.fulldoc = {};
  
          vm.newreferral.doctor = {};
          vm.newreferral.doctor.fulldoc = {};
          
          
          if (angular.isObject(vm.doctor)) {
            if (vm.doctor.fulldoc.titlefront) {
              vm.newreferral.doctor.fulldoc.titlefront = vm.doctor.fulldoc.titlefront;
            }
            if (vm.doctor.fulldoc.titleback) {
              vm.newreferral.doctor.fulldoc.titleback = vm.doctor.fulldoc.titleback;
            }
            if (vm.doctor.fulldoc.firstname) {
              vm.newreferral.doctor.fulldoc.firstname = vm.doctor.fulldoc.firstname;
            }
            if (vm.doctor.fulldoc.lastname) {
              vm.newreferral.doctor.fulldoc.lastname = vm.doctor.fulldoc.lastname;
            }
            if (vm.doctor.fulldoc.institute) {
              vm.newreferral.doctor.fulldoc.institute = vm.doctor.fulldoc.institute;
            }
            if (vm.doctor.fulldoc.speciality) {
              vm.newreferral.doctor.fulldoc.speciality = vm.doctor.fulldoc.speciality;
            }
            if (vm.doctor.fulldoc.address) {
              vm.newreferral.doctor.fulldoc.address = vm.doctor.fulldoc.address;
            }
            if (vm.doctor.fulldoc.city) {
              vm.newreferral.doctor.fulldoc.city = vm.doctor.fulldoc.city;
            }
            if (vm.doctor.fulldoc.postcode) {
              vm.newreferral.doctor.fulldoc.postcode = vm.doctor.fulldoc.postcode;
            }
            if (vm.doctor.fulldoc.phone1) {
              vm.newreferral.doctor.fulldoc.phone1 = vm.doctor.fulldoc.phone1;
            }
          }
          
          payload.referrals = [];
          payload.referrals.push(vm.newreferral);
          
          request = $http({
            url: '/routes/pdfprinter/genpatientprint',
            method: 'POST',
            data: {
              patientid: vm.patientid,
              doctype: 'referral',
              payload: payload
            }
          });
          
          request.success(function () {
            vm.printed = true;
            delete vm.printing;
            $timeout(function () {
              $state.go('home');
            }, 3000);
          });
          request.error(function () {
            delete vm.printing;
            vm.printed = false;
          });
          
        }
        
        function clear() {
          delete vm.patient;
          delete vm.doctor;
          initNewRef();
        }
        
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
