(function () {
  'use strict';

  /* @ngdoc object
   * @name turnover
   * @description
   *
   */
  angular
    .module('finance.turnover', [
      'ui.router'
    ]);
}());
