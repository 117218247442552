(function () {
  'use strict';

  angular
    .module('utils')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('utils', {
        url: '/utils',
        templateUrl: 'utils/utils.tpl.html',
        controller: 'UtilsCtrl',
        controllerAs: 'utils'
      });
  }
}());
