<h3>Monatsabschluß &amp; Datenexport</h3>
<span class="btn btn-primary" ng-click="clCtrl.lastmonth()">Letztes Monat</span>


<h3>Datenexport für beliebige Zeiträume</h3>
<form class="form-vertical">
  <div class="row">
    <div class="col-md-6">
      <label for="startdate">Von</label>

      <p class="input-group">
        <input type="text" class="form-control" uib-datepicker-popup="yyyy-MM-dd" ng-model="clCtrl.startdate"
               is-open="start.opened" ng-required="true" id="startdate" name="startdate"
               clear-text="Löschen" close-text="Schließen" current-text="Heute" starting-day="1"
               alt-input-formats="clCtrl.altInputFormats"/>
              <span class="input-group-btn">
                <button type="button" class="btn btn-default" ng-click="start.opened = true;"><i
                  class="glyphicon glyphicon-calendar"></i></button>
              </span>
      </p>
    </div>
    <div class="col-md-6">
      <label for="enddate">Bis</label>
      <p class="input-group">
        <input type="text" class="form-control" uib-datepicker-popup="yyyy-MM-dd" ng-model="clCtrl.enddate"
               is-open="end.opened" ng-required="true" id="enddate" name="enddate"
               close-text="Schließen" clear-text="Löschen" current-text="Heute" starting-day="1"
               alt-input-formats="clCtrl.altInputFormats"/>
              <span class="input-group-btn">
                <button type="button" class="btn btn-default" ng-click="end.opened = true;"><i
                  class="glyphicon glyphicon-calendar"></i></button>
              </span>
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <button class="btn btn-success" ng-click="clCtrl.freeinterval()"><i class="fa fa-download"></i> Exportieren</button>
    </div>
  </div>
</form>
