(function () {
  'use strict';

  angular
    .module('reminder')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('reminder', {
        url: '/reminder',
        templateUrl: 'reminder/reminder.tpl.html',
        controller: 'ReminderCtrl',
        controllerAs: 'reminder'
      });
  }
}());
