/**
 * Created by lari on 03/09/15.
 */
(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name consult.factory:ConsultService
   *
   * @description
   *
   */
  angular
    .module('consult')
    .factory('Consult', Consult)
    .config(config);

  config.$inject = ['restmodProvider'];

  function config(restmodProvider) {
    restmodProvider.rebase({
      $config: {
        primaryKey: '_id'
      }
    });
  }

  Consult.$inject = ['restmod'];

  function Consult(restmod) {
    return restmod.model('/routes/consults').mix({
      patient: {belongsTo: 'Patient', key: 'patientid'},
      examinations: {hasMany: 'Examination'},
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true},
      open: {init: false},
      symptoms: {init: ''},
      notes: {init: ''},
      keywords: {init: []}
    });
  }

}());


