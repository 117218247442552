<h3>Untersuchungsvorlagen</h3>

<div class="btn btn-success" ng-click="tmCtrl.createTemplate()">Neue Untersuchungsvorlage erstellen</div>
<div class="btn btn-success" ng-click="tmCtrl.loadSampletemplate()">Beispielvorlage laden (Oberbauchsonografie)</div>


<h4>Bestehende Untersuchungsvorlagen</h4>
<table>
  <tr>
    <th width="15%">Kürzel</th>
    <th width="50%">Name</th>
    <th width="25%">Status</th>
    <th width="20%"></th>
  </tr>
  <tr ng-repeat="t in tmCtrl.alltemplates">
    <td ng-bind="t.template.type"></td>
    <td ng-bind="t.template.displayname"></td>
    <td>
      <div class="btn"
           ng-class="t.active ? 'btn-success' : 'btn-danger'"
           ng-model="t.active"
           ng-click="t.active = !t.active; t.$save()"
           uib-tooltip="Status ändern">
        <span ng-show="t.active == true">Aktiv</span>
        <span ng-show="t.active == false">Nicht aktiv</span>
      </div>
    </td>
    <td>
      <div class="btn btn-primary"
           ng-click="tmCtrl.editTemplate(t)"
           uib-tooltip="Editieren"><i class="fa fa-edit"></i>
      </div>
      <div class="btn btn-primary"
           ng-click="tmCtrl.testTemplate(t)"
           uib-tooltip="Ausprobieren"><i class="fa fa-eye"></i>
      </div>
    </td>
    <!--
    <td>
      <div class="btn btn-danger" ng-click="tmCtrl.deleteTemplate(t)" uib-tooltip="Löschen"><i class="fa fa-remove"></i>
      </div>
    </td>-->
  </tr>
</table>

<!-- Template Editor Modal -->
<script type="text/ng-template" id="tepledittpl">
  <div>
    <div class="modal-header">
      <h3 class="modal-title">Untersuchungsvorlage editieren</h3>
    </div>
    <div class="modal-body">
      <form class="form-vertical">
        <div class="form-group myform">
          <label>Kürzel/Typ
            <input type="text" ng-model="tpeCtrl.tpl.template.type" class="form-control"
                   ng-blur="tpeCtrl.tpl.$save()" placeholder="Kürzel/Typ"></label>
        </div>
        <div class="form-group myform">
          <label>Vollständige Bezeichnung
            <input type="text" ng-model="tpeCtrl.tpl.template.displayname" class="form-control"
                   ng-blur="tpeCtrl.tpl.$save()" placeholder="Vollständige Bezeichnung"></label>
        </div>
        <div class="form-group">
          <label>Befundinhalte in Gesamtbefund inkludieren
            <div class="radio">
              <label for="tinc1" class="radio-inline"><input type="radio" ng-value="true"
                                                             ng-model="tpeCtrl.tpl.template.includefindings" id="tinc1">Ja</label>
              <label for="tinc2" class="radio-inline"><input type="radio" ng-value="false"
                                                             ng-model="tpeCtrl.tpl.template.includefindings" id="tinc2">Nein</label>
            </div>
          </label>
        </div>
        <div class="form-group">
          <label>Bilder/Dateien von Netzwerklaufwerk einbeziehen
            <div class="radio">
              <label for="tnet1" class="radio-inline"><input type="radio" ng-value="true"
                                                             ng-model="tpeCtrl.tpl.template.loadfiles"
                                                             id="tnet1">Ja</label>
              <label for="tnet2" class="radio-inline"><input type="radio" ng-value="false"
                                                             ng-model="tpeCtrl.tpl.template.loadfiles"
                                                             id="tnet2">Nein</label>
            </div>
          </label>
        </div>
        <div class="form-group" ng-show="tpeCtrl.tpl.template.loadfiles === true">
          <label for="tname">Netzwerklaufwerk</label>
          <select ng-model="tpeCtrl.tpl.template.shareid" class="form-control" id="tname">
            <option ng-repeat="share in tpeCtrl.allshares" value="{{share._id}}">{{share.name}}</option>
          </select>
        </div>


        <div>
          <h4>Feldgruppen</h4>
          <a class="btn btn-success btn-xs"
             ng-click="tpeCtrl.newFieldGroup()"
             style="margin-right: 8px;">
            <span class="fa fa-plus"> Neue Feldgruppe</span>
          </a>
        </div>

        <div class="clear: both"></div>

        <div ui-tree class="mtree" data-drag-delay="500" data-max-depth="2">
          <ol ui-tree-nodes="" ng-model="tpeCtrl.tpl.template.fieldgroups">
            <li ui-tree-node ng-repeat="fieldgroup in tpeCtrl.tpl.template.fieldgroups" data-drag-delay="500" >
              <div ui-tree-handle class="tree-node tree-node-content"  data-drag-delay="500">
                <a class="btn btn-success btn-xs"
                   ng-if="fieldgroup.fields && fieldgroup.fields.length > 0"
                   data-nodrag ng-click="toggle(this)">
                  <span class="glyphicon"
                        ng-class="{
                        'glyphicon-chevron-right': collapsed,
                        'glyphicon-chevron-down': !collapsed
                      }">
                  </span>
                </a>
                <span ng-hide="fieldgroup.label == ''" data-drag-delay="100" >{{fieldgroup.label}}</span>
                <span ng-show="fieldgroup.label == ''" data-drag-delay="100" >Felder ohne Gruppe</span>
                <input type="text" data-nodrag
                       ng-model-options="{ updateOn: 'default blur', debounce: { default: 500, blur: 0 } }"
                       class="form-control small-input"
                       ng-model="fieldgroup.label"/>
                <!--
                <span ng-hide="fieldgroup.label == ''">{{fieldgroup.label}} <input type="text"
                                                                                   ng-model-options="{ updateOn: 'blur default' }"
                                                                                   class="form-control small-input"
                                                                                   ng-model="fieldgroup.label"/></span>
                <span ng-show="fieldgroup.label == ''">Felder ohne Gruppe
                  <input type="text" class="form-control small-input" ng-model="fieldgroup.label" ng-model-options="{ updateOn: 'blur' }
                         uib-tooltip="Leer lassen damit Felder direkt ohne Feldgruppe erscheinen"/></span>
                -->
                <a class="pull-right btn btn-danger btn-xs"
                   uib-tooltip="Feldgruppe Löschen"
                   data-nodrag ng-click="tpeCtrl.remove(this)">
                  <span class="fa fa-remove"></span>
                </a>
                <a class="pull-right btn btn-success btn-xs"
                   uib-tooltip="Neues Feld in diese Gruppe einfügen"
                   data-nodrag ng-click="tpeCtrl.newSubItem(this)"
                   style="margin-right: 8px;">
                  <span class="fa fa-plus"></span>
                </a>

              </div>
              <ol ui-tree-nodes="" ng-model="fieldgroup.fields" ng-class="{'hidden': !collapsed}">
                <li ui-tree-node
                    ng-repeat="field in fieldgroup.fields"
                    ng-include="'field_renderer.html'">
                  <div ui-tree-handle>
                    {{field.label}}
                  </div>
              </ol>
            </li>
            <!-- ui-tree-node -->
          </ol>
          <!-- ui-tree-nodes -->
        </div>
        <!-- ui-tree -->

        <div ng-hide="tpeCtrl.tpl.template.includefindings">
          <h4>Zusatzfelder</h4>
          <!-- pseduo tree for named fields -->
          <div ui-tree data-nodrop-enabled="true" data-drag-enabled="false" class="mtree">
            <ol ui-tree-nodes="" ng-model="tpeCtrl.tpl.template" data-nodrop-enabled="true" data-drag-enabled="false">
              <li ui-tree-node ng-model="tpeCtrl.tpl.template.biopsy"
                  ng-show="tpeCtrl.tpl.template.biopsy"
                  data-drag-enabled="false"
                  ng-include="'biopsy_renderer.html'">
                <div ui-tree-handle>
                  {{tpeCtrl.tpl.template.biopsy.label}}
                </div>
              </li>
              <li ui-tree-node ng-model="tpeCtrl.tpl.template.comment"
                  ng-show="tpeCtrl.tpl.template.comment"
                  data-drag-enabled="false"
                  ng-include="'comment_renderer.html'">
                <div ui-tree-handle>
                  {{tpeCtrl.tpl.template.comment.label}}
                </div>
              </li>
              <li ui-tree-node
                  ng-model="tpeCtrl.tpl.template.diagnosis"
                  ng-show="tpeCtrl.tpl.template.diagnosis"
                  data-drag-enabled="false"
                  ng-include="'diagnosis_renderer.html'">
                <div ui-tree-handle>
                  {{tpeCtrl.tpl.template.diagnosis.label}}
                </div>
              </li>
              <li ui-tree-node ng-model="tpeCtrl.tpl.template.recommendation"
                  ng-show="tpeCtrl.tpl.template.recommendation"
                  data-drag-enabled="false"
                  ng-include="'recommendation_renderer.html'">
                <div ui-tree-handle>
                  {{tpeCtrl.tpl.template.recommendation.label}}
                </div>
              </li>
              <div ng-hide="tpeCtrl.tpl.template.biopsy"
                   class="btn btn-success"
                   ng-click="tpeCtrl.addNamedField('biopsy')">Biopsiefeld hinzufügen
              </div>

              <div ng-hide="tpeCtrl.tpl.template.diagnosis"
                   class="btn btn-success"
                   ng-click="tpeCtrl.addNamedField('diagnosis')">Diagnosefeld hinzufügen
              </div>
              <div ng-hide="tpeCtrl.tpl.template.recommendation"
                   class="btn btn-success"
                   ng-click="tpeCtrl.addNamedField('recommendation')">(Therapie-) Empfehlungsfeld hinzufügen
              </div>
              <div ng-hide="tpeCtrl.tpl.template.comment"
                   class="btn btn-success"
                   ng-click="tpeCtrl.addNamedField('comment')">Kommentarfeld hinzufügen
              </div>
            </ol>
          </div>
        </div>
        <input type="submit" class="btn btn-success" ng-click="tpeCtrl.tpl.$save()" value="Speichern"/>
        <input type="submit" class="btn btn-success" ng-click="tpeCtrl.tpl.$save(); tpeCtrl.closeModal();"
               value="Speichern und beenden"/>
      </form>

      </form>
    </div>
  </div>
</script><!-- ende modal template -->


<!-- Nested field render template -->
<script type="text/ng-template" id="field_renderer.html">
  <div ui-tree-handle class="tree-node tree-node-content">
    <div>
      <div>
        {{field.label}}
        <a class="pull-right btn btn-danger btn-xs"
           uib-tooltip="Feld entfernen"
           data-nodrag ng-click="tpeCtrl.remove(this)">
          <span class="glyphicon glyphicon-remove"></span>
        </a>
        <a class="pull-right btn btn-primary btn-xs"
           uib-tooltip="Feld editieren"
           data-nodrag ng-click="ecollapsed=!ecollapsed"
           style="margin-right: 8px;">
            <span
                class="fa fa-edit"></span>
        </a>


        <div id="meta{{$index}}" ng-class="{'hidden':ecollapsed}" ng-init="ecollapsed = true">
          <div class="form-group">
            <label>Feldname
              <input type="text" ng-model="field.label" class="form-control"></label>
          </div>
          <div class="form-group">
            <label uib-tooltip="Wird automatisch in der Vorlage vorausgefüllt">Standardwert(e)
              <tags-input ng-model="field.avalues"
                          display-property="name"
                          add-on-comma="false"
                          enableEditingLastTag="true"
                          placeholder="Neuer Eintrag <Eingabetaste>"
                          replace-spaces-with-dashes="false"
                          template="tag-template">
              </tags-input>
            </label>
          </div>
          <div class="form-group">
            <label>Mögliche Werte
              <tags-input ng-model="field.values"
                          display-property="name"
                          add-on-comma="false"
                          enableEditingLastTag="true"
                          placeholder="Neuer Eintrag <Eingabetaste>"
                          replace-spaces-with-dashes="false"
                          template="tag-template">
              </tags-input>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</script>

<!-- Nested biopsy render template -->
<script type="text/ng-template" id="biopsy_renderer.html">
  <div ui-tree-handle class="tree-node tree-node-content">
    <div>
      <div class="row">
        <div class="col-md-10">
          {{tpeCtrl.tpl.template.biopsy.label}}
          <div ng-class="{'hidden':ecollapsed}" ng-init="ecollapsed = true">
            <div class="form-group">
              <label>Feldname
                <input type="text" ng-model="tpeCtrl.tpl.template.biopsy.label"
                       ng-blur="tpeCtrl.tpl.$save().$save()" class="form-control"></label>
            </div>
            <div class="form-group">
              <label>Defaultwert(e)
                <tags-input ng-model="tpeCtrl.tpl.template.biopsy.avalues"
                            display-property="name"
                            add-on-comma="false"
                            enableEditingLastTag="true"
                            placeholder="Neuer Eintrag <Eingabetaste>"
                            replace-spaces-with-dashes="false"
                            template="tag-template">
                </tags-input>
              </label>
            </div>
            <div class="form-group">
              <label>Mögliche Werte (mit Eingabetaste trennen)
                <tags-input ng-model="tpeCtrl.tpl.template.biopsy.values"
                            display-property="name"
                            add-on-comma="false"
                            enableEditingLastTag="true"
                            placeholder="Neuer Eintrag <Eingabetaste>"
                            replace-spaces-with-dashes="false"
                            template="tag-template">
                </tags-input>
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <a class="pull-right btn btn-danger btn-xs" data-nodrag ng-click="tpeCtrl.removeNamedField('biopsy')">
            <span class="fa fa-remove"></span>
          </a>
          <a class="pull-right btn btn-primary btn-xs" data-nodrag ng-click="ecollapsed=!ecollapsed"
             style="margin-right: 8px;">
            <span
                class="fa fa-edit"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</script>

<!-- Nested comment render template -->
<script type="text/ng-template" id="comment_renderer.html">
  <div ui-tree-handle class="tree-node tree-node-content">
    <div>
      <div class="row">
        <div class="col-md-10">
          {{tpeCtrl.tpl.template.comment.label}}
          <div ng-class="{'hidden':ecollapsed}" ng-init="ecollapsed = true">
            <div class="form-group">
              <label>Feldname
                <input type="text" ng-model="tpeCtrl.tpl.template.comment.label"
                       ng-blur="tpeCtrl.tpl.$save().$save()" class="form-control"></label>
            </div>
            <div class="form-group">
              <label>Defaultwert(e)
                <tags-input ng-model="tpeCtrl.tpl.template.comment.avalues"
                            display-property="name"
                            add-on-comma="false"
                            enableEditingLastTag="true"
                            placeholder="Neuer Eintrag <Eingabetaste>"
                            replace-spaces-with-dashes="false"
                            template="tag-template">
                </tags-input>
              </label>
            </div>
            <div class="form-group">
              <label>Mögliche Werte (mit Komma oder Eingabetaste trennen)
                <tags-input ng-model="tpeCtrl.tpl.template.comment.values"
                            display-property="name"
                            add-on-comma="false"
                            enableEditingLastTag="true"
                            placeholder="Neuer Eintrag <Eingabetaste>"
                            replace-spaces-with-dashes="false"
                            template="tag-template">
                </tags-input>
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <a class="pull-right btn btn-danger btn-xs" data-nodrag ng-click="tpeCtrl.removeNamedField('comment')">
            <span class="fa fa-remove"></span>
          </a>
          <a class="pull-right btn btn-primary btn-xs" data-nodrag ng-click="ecollapsed=!ecollapsed"
             style="margin-right: 8px;">
            <span
                class="fa fa-edit"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</script>

<!-- Nested diagnosis render template -->
<script type="text/ng-template" id="diagnosis_renderer.html">
  <div ui-tree-handle class="tree-node tree-node-content">
    <div>
      <div class="row">
        <div class="col-md-10">
          {{tpeCtrl.tpl.template.diagnosis.label}}
          <div ng-class="{'hidden':ecollapsed}" ng-init="ecollapsed = true">
            <div class="form-group">
              <label>Feldname
                <input type="text" ng-model="tpeCtrl.tpl.template.diagnosis.label"
                       ng-blur="tpeCtrl.tpl.$save().$save()" class="form-control"></label>
            </div>
            <div class="form-group">
              <label>Defaultwert
                <tags-input ng-model="tpeCtrl.tpl.template.diagnosis.avalues"
                            display-property="name"
                            add-on-comma="false"
                            enableEditingLastTag="true"
                            placeholder="Neuer Eintrag <Eingabetaste>"
                            replace-spaces-with-dashes="false"
                            template="tag-template">
                </tags-input>
              </label>
            </div>
            <div class="form-group">
              <label>Mögliche Werte (mit Komma oder Eingabetaste trennen)
                <tags-input ng-model="tpeCtrl.tpl.template.diagnosis.values"
                            display-property="name"
                            add-on-comma="false"
                            enableEditingLastTag="true"
                            placeholder="Neuer Eintrag <Eingabetaste>"
                            replace-spaces-with-dashes="false"
                            template="tag-template">
                </tags-input>
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <a class="pull-right btn btn-danger btn-xs" data-nodrag ng-click="tpeCtrl.removeNamedField('diagnosis')">
            <span class="fa fa-remove"></span>
          </a>
          <a class="pull-right btn btn-primary btn-xs" data-nodrag ng-click="ecollapsed=!ecollapsed"
             style="margin-right: 8px;">
            <span
                class="fa fa-edit"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</script>

<!-- Nested recommendation render template -->
<script type="text/ng-template" id="recommendation_renderer.html">
  <div ui-tree-handle class="tree-node tree-node-content">
    <div>
      <div class="row">
        <div class="col-md-10">
          {{tpeCtrl.tpl.template.recommendation.label}}
          <div ng-class="{'hidden':ecollapsed}" ng-init="ecollapsed = true">
            <div class="form-group">
              <label>Feldname
                <input type="text" ng-model="tpeCtrl.tpl.template.recommendation.label"
                       ng-blur="tpeCtrl.tpl.$save().$save()" class="form-control"></label>
            </div>
            <div class="form-group">
              <label>Defaultwert(e)
                <tags-input ng-model="tpeCtrl.tpl.template.recommendation.avalues"
                            display-property="name"
                            add-on-comma="false"
                            enableEditingLastTag="true"
                            placeholder="Neuer Eintrag <Eingabetaste>"
                            replace-spaces-with-dashes="false"
                            template="tag-template">
                </tags-input>
              </label>
            </div>
            <div class="form-group">
              <label>Mögliche Werte (mit Komma oder Eingabetaste trennen)
                <tags-input ng-model="tpeCtrl.tpl.template.recommendation.values"
                            display-property="name"
                            add-on-comma="false"
                            enableEditingLastTag="true"
                            placeholder="Neuer Eintrag <Eingabetaste>"
                            replace-spaces-with-dashes="false"
                            template="tag-template">
                </tags-input>
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <a class="pull-right btn btn-danger btn-xs" data-nodrag ng-click="tpeCtrl.removeNamedField('recommendation')">
            <span class="fa fa-remove"></span>
          </a>
          <a class="pull-right btn btn-primary btn-xs" data-nodrag ng-click="ecollapsed=!ecollapsed"
             style="margin-right: 8px;">
            <span
                class="fa fa-edit"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</script>


<!-- ngTagsInput tag formatting -->
<script type="text/ng-template" id="tag-template">
  <div class="tag-template">
    <div class="right-panel">
      <span>{{$getDisplayText()}}</span>
      <a class="remove-button" ng-click="$removeTag()">&#10006;</a>
    </div>
  </div>
</script>

