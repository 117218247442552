(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:checkoutAides
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <checkout-aides patient="consCtrl.patient"></checkout-aides>
   </file>
   </example>
   *
   */
  angular
    .module('consult')
    .directive('checkoutAides', checkoutAides);

  checkoutAides.$inject = [];

  function checkoutAides() {
    return {
      restrict: 'EA',
      scope: {
        patient: '=',
        dialog: '=?'
      },
      templateUrl: 'consult/checkout-aides-directive.tpl.html',
      replace: false,
      controllerAs: 'aidCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.removeAide = removeAide;
        vm.addAnotherAide = addAnotherAide;
        vm.newaide = {};
        vm.newaide.pdate = true;
        vm.newaide.aidedate = moment().locale('de-at').format('ll');

        function removeAide(i) {
          vm.patient.openConsult.aides.splice(i, 1);
          vm.patient.openConsult.$save();
        }

        function addAnotherAide() {
          if (!angular.isArray(vm.patient.openConsult.aides)) {
            vm.patient.openConsult.aides = [];
          }
          vm.patient.openConsult.aides.push(vm.newaide);
          vm.patient.openConsult.$save();

          vm.newaide = {};
          vm.newaide.pdate = true;
          vm.newaide.aidedate = moment().locale('de-at').format('ll');
        }

      }
    };
  }
}());
