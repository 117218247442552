(function () {
  'use strict';

  angular
    .module('helpers.doctor')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('doctors', {
        url: '/doctors',
        views: {
          main: {templateUrl: 'helpers/doctor/doctor.tpl.html'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });



    // show modal for doctor new
    $stateProvider.state('doctornew', {
      url: '/doctornew',
      onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
        $uibModal.open({
          template: '<div class="modalspacer"><doctor-add></doctor-add></div>',
          size: 'lg'
        });
      }]
    });

  }

  // klammerfriedhof
}());
