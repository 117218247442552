(function () {
  'use strict';

  /* @ngdoc object
   * @name helpers
   * @description
   *
   */
  angular
    .module('helpers', [
      'ui.router',
      'helpers.doctor',
      'helpers.zipcode',
      'helpers.sv'
    ]);
}());
