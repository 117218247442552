<h3>Patienten</h3>

<table class="table table-striped">
  <thead>
  <tr>
    <th>Nachname</th>
    <th>Vorname</th>
    <th>Geburtsdatum</th>
    <th>SVNr</th>
    <th></th>
  </tr>
  </thead>
  <tr ng-repeat="patient in plCtrl.patientlist">
    <td>{{patient.lastname}}</td>
    <td>{{patient.firstname}}</td>
    <td>{{patient.birthdate}}</td>
    <td>{{patient.svnr}}</td>
    <td>
      <button class="btn btn-primary" ui-sref="patientrecord.history({patientid: '{{patient._id}}'})">Akte</button>
    </td>
  </tr>
</table>



<uib-pagination total-items="plCtrl.totalItems"
            items-per-page="20"
            max-size="10"
            first-text="Erste"
            last-text="Letzte"
            next-text="N&auml;chste"
            previous-text="Vorige"
            boundary-links="true"
            rotate="false"
            ng-model="plCtrl.currentPage"
            ng-change="plCtrl.pageChanged()"></uib-pagination>

