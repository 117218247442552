(function () {
  'use strict';

  /* @ngdoc object
   * @name patient
   * @description
   *
   */
  angular
    .module('patient', [
      'ui.router',
      'ui.bootstrap',
      'utils.keywords',
      'consult',
      'examination',
      'helpers.doctor',
      'helpers.sv',
      'drug',
      'labvalue',
      'knalli.angular-vertxbus',
      'cfp.hotkeys',
      'config',
      'reminder',
      'helpers.zipcode',
      'datetime'
    ])
    .filter('drugname', function () {
      return function (value) {
        if (!value) {
          return '';
        }
        return value.split(' ')[0];
      };
    });
}());
