
  <div class="modalspacer"> <!-- complete template must be inclosed inside single tag-->
    <div>
      <label for="drugamount">Bestehnde Einnahmeverordnung</label>
      <input class="form-control" ng-model="drug.amount" id="drugamount" disabled
             autocomplete="off" autocorect="off" autocapitalize="off" spellcheck="false"
             
    </div>
    <span class="form-group">
              <label for="newdrugamount">Neue Einnahmeverordnung</label>
              <span class="input-group">
                <input class="form-control" ng-model="drug.newamount" id="newdrugamount"
                       autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                       ng-change="dpCtrl.checkSignatureUpdate(drug)">
                <span class="input-group-btn" uib-dropdown>
                  <button type="button" class="btn btn-default" uib-dropdown-toggle><span class="caret"></span></button>
                  <ul id="amountmenu" class="dropdown-menu dropdown-menu-right" role="menu" uib-dropdown-menu ng-hide="dpCtrl.conf.drugsignature.length">
                    <li ng-click="drug.newamount='1-0-0'">1-0-0</li>
                    <li ng-click="drug.newamount='1-0-1'">1-0-1</li>
                    <li ng-click="drug.newamount='0-1-0'">0-1-0</li>
                    <li ng-click="drug.newamount='0-0-1'">0-0-1</li>
                    <li ng-click="drug.newamount='1-1-1'">1-1-1</li>
                    <li ng-click="drug.newamount='0-0-0-1'">0-0-0-1</li>
                  </ul>
                  <ul id="amountmenu2" class="dropdown-menu dropdown-menu-right" role="menu" uib-dropdown-menu ng-show="dpCtrl.conf.drugsignature.length">
                    <li ng-repeat="sig in dpCtrl.conf.drugsignature" ng-click="drug.newamount=sig">{{sig}}</li>
                  </ul>
                </span>
              </span>
            </span>
   <!-- <div class="input-group">
      <label for="drugamount">Einnahme</label>
      <input class="form-control" ng-model="drug.amount" id="drugamount" placeholder="1-0-1-0">
    </div>-->
    <div class="input-group">
      <strong>Spezifika</strong>

      <div class="checkbox">
        <label for="drugind" class="checkbox-inline"><input type="checkbox" ng-model="drug.ind" id="drugind">IND
          (Indikation)</label><br>
      </div>
      <div class="radio">
        <label for="drugop1" class="radio-inline"><input type="radio" value="OP I" ng-model="drug.op" id="drugop1">OP I</label>
        <label for="drugop2" class="radio-inline"><input type="radio" value="OP II" ng-model="drug.op" id="drugop2">OP
          II</label>
        <label for="drugop3" class="radio-inline"><input type="radio" value="OP III" ng-model="drug.op" id="drugop3">OP
          III</label>
      </div>
      <label for="drugpkgsize">Packungsgröße</label>
      <input class="form-control" ng-model="drug.pkgsize" id="drugpkgsize" placeholder="30 Stk">
    </div>
    <div class="input-group">
      <div class="checkbox">
        <label for="drugperm" class="checkbox-inline"><input type="checkbox" ng-model="drug.permanent" id="drugperm">Dauermedikamentation</label><br>
      </div>
    </div>
    <div class="form-group">
      <label for="drugcomment">Rezeptvermerk</label>
      <textarea class="form-control" ng-model="drug.comment" id="drugcomment" placeholder=""></textarea>
    </div>
  </div>

  <!-- This from the old druginfo popover ^-->
<!--
<script type="text/ng-template" id="myPopoverTemplate.html">
  <div>
    <div class="zbez">{{dpCtrl.selectedDrug.ZBEZ}}
      <span ng-hide="dpCtrl.selectedDrug.ZSUCHT==''"
            class="zsucht"
            uib-tooltip="Sucht-Kategorisierung">{{dpCtrl.selectedDrug.ZSUCHT}}</span>
    </div>
    <div class="desc">{{dpCtrl.selectedDrug.ZANWEND}}</div>
  </div>
</script>
-->
