(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name doctype.directive:doctypeEdit
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="doctype">
       <file name="index.html">
        <doctype-edit></doctype-edit>
       </file>
     </example>
   *
   */
  angular
    .module('doctype')
    .directive('doctypeEdit', doctypeEdit);


  doctypeEdit.$inject = ['Doctype', 'Printerlist', '$log'];

  function doctypeEdit(Doctype, Printerlist, $log) {
    return {
      restrict: 'E',
      scope: {
        doctype: '=',
        dialog: '='
      },
      templateUrl: 'doctype/doctype-edit-directive.tpl.html',
      replace: false,
      controllerAs: 'dteCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'doctypeEdit';
        vm.save = save;
        vm.close = close;

        vm.allprinters = Printerlist.getPrinters();

        function save() {
          vm.doctype.$save();
        }

        function close() {
          vm.dialog.close();
        }

      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
