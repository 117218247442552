(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.controller:UserCtrl
   *
   * @description
   *
   */
  angular
    .module('user')
    .controller('UserCtrl', UserCtrl);

  UserCtrl.$inject = ['User'];

  function UserCtrl(User) {
    var vm = this;
    vm.ctrlName = 'UserCtrl';

    vm.users = User.$search();
    vm.add = add;


    function add() {
      vm.users.$create(vm.newuser);
    }


  }
}());
