(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name pdfprinter.controller:PdfprinterCtrl
   *
   * @description
   *
   */
  angular
    .module('pdfprinter')
    .controller('PdfprinterCtrl', PdfprinterCtrl);

  function PdfprinterCtrl() {
    var vm = this;
    vm.ctrlName = 'PdfprinterCtrl';
  }
}());
