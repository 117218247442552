(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name helpers.doctor.directive:doctorSearch
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="helpers.doctor">
   <file name="index.html">
   <doctor-search doctorresult="" ti="?"></doctor-search>
   </file>
   </example>
   *
   */
  angular
    .module('helpers.doctor')
    .directive('doctorSearch', doctorSearch);

  doctorSearch.$inject = ['Doctorsearch', 'Doctor', '$uibModal', '$log'];

  function doctorSearch(Doctorsearch, Doctor, $uibModal, $log) {
    return {
      restrict: 'EA',
      scope: {
        doctorresult: '=',
        ti: '@?'
      },
      templateUrl: 'helpers/doctor/doctor-search-directive.tpl.html',
      replace: false,
      controllerAs: 'drsCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'doctorSearch';

        if (angular.isString(vm.ti)) {
          vm.tabi = parseInt(vm.ti, 10);
        }
        else {
          vm.tabi = 0;
        }
        
        vm.newdoc = {};
        vm.newdoc.foo = 'quux';

        vm.showdoctor = showdoctor;
        vm.getNames = getNames;
        vm.addDoctor = addDoctor;

        // typeahead doctor search backend query
        function getNames(val) {
          var tmp;
          return Doctorsearch.$search({query: val}).$asPromise().then(function (response) {
            // $log.debug('response: ', response);
            return response.map(function (doctor) {
              /* $log.debug('doctor:', doctor);
              $log.debug('alle: ', angular.isString(doctor.firstname) && angular.isString(doctor.lastname) && angular.isString(doctor.institute));
              $log.debug('nur doc: ', angular.isString(doctor.firstname) && angular.isString(doctor.lastname) && !angular.isString(doctor.institute));
              $log.debug('doc inst: ', !angular.isString(doctor.firstname) && angular.isString(doctor.lastname) && angular.isString(doctor.institute)
              );
              $log.debug('inst: ', !angular.isString(doctor.firstname) && !angular.isString(doctor.lastname) && angular.isString(doctor.institute)); */
              if (angular.isString(doctor.firstname) && angular.isString(doctor.lastname) && angular.isString(doctor.institute)) {
                doctor.text = doctor.firstname + ' ' + doctor.lastname + ', ' + doctor.institute + ', ' + doctor.speciality;
              }
              if (angular.isString(doctor.firstname) && angular.isString(doctor.lastname) && !angular.isString(doctor.institute)) {
                doctor.text = doctor.firstname + ' ' + doctor.lastname + ', ' + doctor.speciality;
              }
              if (!angular.isString(doctor.firstname) && angular.isString(doctor.lastname) && angular.isString(doctor.institute)) {
                doctor.text = doctor.lastname + ', ' + doctor.institute + ', ' + doctor.speciality;
              }
              if (!angular.isString(doctor.firstname) && !angular.isString(doctor.lastname) && angular.isString(doctor.institute)) {
                doctor.text = doctor.institute + ', ' + doctor.speciality;
              }

              return doctor;
            });
          });
        }


        // typeahead doctor search action
        function showdoctor($item, $model, $label) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          // vm.doctorresult = Doctor.$find($model._id);
          Doctor.$find($model._id).$asPromise().then(function (doc) {
            var property;
            // vm.doctorresult = doc;
            vm.doctorresult = {};
            vm.doctorresult._id = doc._id;
            vm.doctorresult.fname = doc.fname;
            vm.doctorresult.fulldoc = doc;
          });
          delete vm.newdoc.text;
        }


        function addDoctor() {
          var dialog = $uibModal.open({
            template: '<div class="modalspacer"><doctor-add returndoc="miCtrl.returndoc" dialog="miCtrl.$uibModalInstance"></doctor-add></div>',
            controllerAs: 'miCtrl',
            controller: ['returndoc', '$uibModalInstance', function (returndoc, $uibModalInstance) {
              var vmi = this;
              vmi.returndoc = returndoc;
              vmi.$uibModalInstance = $uibModalInstance;
              $log.debug('vmi: ', returndoc);
            }],
            resolve: {
              returndoc: function () {
                $log.debug('newdoc init: ', vm.newdoc);
                return vm.newdoc;
              },
              dialog: function () {
                $log.debug('dialog init: ', dialog);
                return dialog;
              }
            },
            size: 'lg'
          });

          dialog.result.finally(function () {
            $log.debug('new doc added: ', vm.newdoc);
            $log.debug('modal closed finally: ', vm.newdoc);
            vm.doctorresult = {};
            vm.doctorresult._id = vm.newdoc._id;
            vm.doctorresult.fname = vm.newdoc.fname;
            vm.asyncSelected = vm.newdoc.fname;
          });

        }
      }
    };
  }
}());
