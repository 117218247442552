(function () {
  'use strict';

  /* @ngdoc object
   * @name unicatalog
   * @description
   *
   */
  angular
    .module('unicatalog', [
      'ui.router',
      'ui.tree',
      'ui.bootstrap'
    ]);
}());
