<h3>Dokumente vidieren <span ng-show="soCtrl.patientid.length > 0">f&uuml;r Patient {{soCtrl.patient.lastname}} {{soCtrl.patient.firstname}}</span>: <span class="label label-danger">{{soCtrl.unsignedScans.length}}</span> unbearbeite Dokumente</h3>

<div ng-hide="soCtrl.ready" class="alert alert-info">
    <h3><i class="fa fa-info-circle"></i> Scans werden geladen</h3>
</div>

<div ng-show="soCtrl.nomoredocuments" class="alert alert-info">
    <h3><i class="fa fa-info-circle"></i> Keine Dokumente verfügbar
        <span class="btn btn-primary" ng-click="soCtrl.reloadPdfs()">Nach neuen Dokumenten suchen</span>
    </h3>
</div>


<!-- Menubar -->
<div ng-show="soCtrl.ready">
    <button class="btn btn-primary" ng-click="soCtrl.previousPdf()" uib-tooltip="Vorige PDF Datei bearbeiten"><i class="fa fa-arrow-left"></i> PDF</button>
    <button class="btn btn-info" ng-click="soCtrl.zoomIn()" uib-tooltip="Hineinzoomen"><i class="fa fa-search-plus"></i></button>
    <button class="btn btn-info" ng-click="soCtrl.zoomOut()" uib-tooltip="Herauszoomen"><i class="fa fa-search-minus"></i></button>
    <button class="btn btn-info" ng-click="soCtrl.rotatePage()" uib-tooltip="Dokument drehen"><i class="fa fa-rotate-right"></i></button>
    <button class="btn btn-info" ng-click="soCtrl.previousPage()" uib-tooltip="Zurückblättern"><i class="fa fa-arrow-left"></i></button>
    <!--  Seite: {{soCtrl.currentpage}} / {{soCtrl.maxpc}}  -->
    <form style="display: inline" class="form-horizontal">
        <label for="pagenr"> Seite: </label>
        <input type="number" class="form-control"
               style="display: inline; width: 4em"
               width="5em" name="pagenr" id="pagenr"
               ng-change="soCtrl.gotoPage()"
               ng-model="soCtrl.currentpage">
        <label>/{{soCtrl.maxpc}}</label></form>
    <button class="btn btn-info" ng-click="soCtrl.nextPage()" uib-tooltip="Vorblättern"><i class="fa fa-arrow-right"></i></button>
    <a class="btn btn-info" href="/routes/files/filereader/{{soCtrl.unsignedScans[soCtrl.pdf].fileid}}"
       target="blank" uib-tooltip="PDF in neuem Fenster öffnen"><i class="fa fa-eye"></i></a>
    <button class="btn btn-primary" ng-click="soCtrl.reloadPdfs()" uib-tooltip="PDFs neu laden">PDF <i class="fa fa-undo"></i></button>
    <button class="btn btn-primary" ng-click="soCtrl.nextPdf()" uib-tooltip="Nächste PDF Datei bearbeiten">PDF <i class="fa fa-arrow-right"></i></button>
</div>

<!-- DMS Actions -->
<div  ng-show="soCtrl.ready && !soCtrl.nomoredocument">
<h4>Patient: <a ng-click="soCtrl.$state.go('patientrecord.history',{patientid: soCtrl.patient._id})">
  {{soCtrl.unsignedScans[soCtrl.pdf].patientfname}}<span ng-show="soCtrl.patient.phone"> , {{soCtrl.patient.phone}}</span><span ng-show="soCtrl.patient.email"> , {{soCtrl.patient.email}}</span></a>
</h4>

    <!-- keywords -->
    <form name="signatureform">
        <div class="row" ng-show="soCtrl.unsignedScans[soCtrl.pdf].doccat">
        <div class="form-group col-xs-12">
            <label for="dockeywords">Stichworte</label>
            <tags-input ng-model="soCtrl.unsignedScans[soCtrl.pdf].keywords"
                        display-property="text"
                        id="dockeywords"
                        add-on-paste="true"
                        add-on-comma="false"
                        min-length="1"
                        add-from-autocomplete-only="false"
                        enable-editing-last-tag="true"
                        replace-spaces-with-dashes="false"
                        template="tag-template">
                <auto-complete source="soCtrl.getTags($query)"
                               min-length="1"
                               load-on-focus="true"
                               load-on-empty="true"
                               load-on-down-arrow="true"
                               max-results-to-show="32"
                               select-first-match="false"
                               template="autocomplete-template">
                </auto-complete>

            </tags-input>
        </div>
            </div>
    <div class="row" ng-show="soCtrl.unsignedScans[soCtrl.pdf].doccat">
        <!-- Logging -->
        <div class="form-group col-xs-3">
            <label for="dockeywords">Logging</label>
            <button type="button" ng-click="soCtrl.patientlogger()" uib-tooltip="Logeintrag für Patienten erstellen"
                    class="btn btn-success form-control"><i class="fa fa-plus"></i> Logeintrag</button>
        </div>
        <!-- Todo -->
        <div class="form-group col-xs-3">
            <label for="dockeywords">Patientenerinnerung</label>
            <button type="button" ng-click="soCtrl.patienttodo()" uib-tooltip="Todo für Patienten erstellen"
                    class="btn btn-success form-control"><i class="fa fa-plus"></i> Todo</button>
        </div>

        <div class="form-group col-xs-6">
            <label for="dockeywords">Signatur</label>
            <div class="input-group">
                <input type="text" class="form-control" required
                       ng-model="soCtrl.unsignedScans[soCtrl.pdf].signature">
                <span class="input-group-btn">
                    <input type="submit" class="btn btn-success"
                           ng-click="soCtrl.closePdf()"
                           value="Abschließen"
                           ng-disabled="signatureform.$invalid"
                           uib-tooltip="Dieses Dokument abschließen">
                </span>
            </div>
        </div>
    </div>
</form>

<!-- PDF Viewer -->
<pdf-viewer ng-show="soCtrl.ready && !soCtrl.nomoredocuments"
            delegate-handle="unsigned-pdf-container"
            url="pdfUrl"
            scale="1"
            show-toolbar="false"></pdf-viewer>


</div>
<script type="text/ng-template" id="tag-template">
    <div class="tag-template">
        <div class="right-panel">
            <span>{{$getDisplayText()}}</span>
            <a class="remove-button" ng-click="$removeTag()">&#10006;</a>
        </div>
    </div>
</script>


<script type="text/ng-template" id="autocomplete-template">
    <div class="autocomplete-template">
        <div>
            <span ng-bind-html="$highlight($getDisplayText())">{{text}}</span>
        </div>
    </div>
</script>
