<div>
  <h3>Tracker: {{etCtrl.tracker.name}}</h3>
  <div class="row spacer-top">
    <div class="col-xs-12">
      <h4>Tracker-Daten</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-6">
      <label for="tname">Name des Trackers</label>
      <input type="text" ng-model="etCtrl.patient.trackers[etCtrl.tracker].name" ng-blur="etCtrl.patient.$save()" class="form-control"
             id="tname">
    </div>
    <div class="col-xs-12 col-sm-6">
      <label for="tunit">Einheit/Größe</label>
      <input type="text" ng-model="etCtrl.patient.trackers[etCtrl.tracker].unit" ng-blur="etCtrl.patient.$save()" class="form-control"
             id="tunit">
    </div>
    <div class="col-xs-12 col-sm-6">
      <label for="tmin">Norm-Min</label>
      <input type="number" ng-model="etCtrl.patient.trackers[etCtrl.tracker].normmin" ng-blur="etCtrl.recalcRef()" class="form-control"
             id="tmin">
    </div>
    <div class="col-xs-12 col-sm-6">
      <label for="tmax">Norm-Max</label>
      <input type="number" ng-model="etCtrl.patient.trackers[etCtrl.tracker].normmax" ng-blur="etCtrl.recalcRef()" class="form-control"
             id="tmax">
    </div>
  </div>
  <div class="row spacer-top">
    <div class="col-xs-12">
      <h4>Datensätze</h4>
    </div>
  </div>
  <div class="row spacer-top">
    <div class="col-xs-6" style="font-weight: bold">Datum (JJJJ-MM-TT)</div>
    <div class="col-xs-6" style="font-weight: bold">Wert</div>
  </div>
  <!-- Only edit real data, not norm reference data -->
  <div class="row" ng-repeat="i in etCtrl.patient.trackers[etCtrl.tracker].items | filter: {group: 0}">
    <div class="col-xs-6"><input type="text" ng-model="i.x" class="form-control" ng-blur="etCtrl.patient.$save()"></div>
    <div class="col-xs-6"><input type="number" ng-model="i.y" class="form-control" ng-blur="etCtrl.patient.$save()">
    </div>
  </div>
  <!-- Neuen datenpunkt anlegen -->
  <form id="newdpform" name="newdpform">
    <div class="row spacer-top">
      <div class="col-xs-12">
        <h3>Neuen Datensatz anlegen</h3>
      </div>
    </div>
    <div class="row">
      
      <div class="col-xs-6">
        <label for="newdpx">Datum</label>
        <input type="text" ng-model="etCtrl.newdp.x" class="form-control"
               id="newdpx"
               placeholder="JJJJ-MM-TT" required>
      </div>
      <div class="col-xs-6">
        <label for="newdpy">Wert</label>
        <input type="number" ng-model="etCtrl.newdp.y" class="form-control"
               id="newdpy" required>
      </div>
    </div>
    <div class="row spacer-top">
      <div class="col-xs-12">
        <button type="submit" class="btn btn-success" ng-click="etCtrl.addDatapoint()">Datensatz anlegen</button>
      </div>
    </div>
  </form>
</div>