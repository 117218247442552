<h3>Datenerfassungsprotokoll (DEP)</h3>

<table class="table table-striped">
  <tr>
    <th>Datum</th>
    <th>BelegNr.</th>
    <th>Typ</th>
    <th>Signiert</th>
    <th>PDF</th>
  </tr>
  <tr ng-repeat="entry in vm.dep">
    <td>{{entry.created | datetime}}</td>
    <td ng-bind="entry.receiptnr"></td>
    <td ng-bind="entry.type"></td>
    <td><input type="checkbox" ng-model="entry.signatureok" disabled></td>
    <td><a href="/routes/pdfreader/{{entry.pdfid}}" target="_blank"><i class="fa fa-file-pdf-o"></i></a></td>
  </tr>
  
</table>

<uib-pagination total-items="vm.totalItems"
                items-per-page="20"
                max-size="10"
                first-text="Erste"
                last-text="Letzte"
                next-text="N&auml;chste"
                previous-text="Vorige"
                boundary-links="true"
                rotate="false"
                ng-model="vm.currentPage"
                ng-change="vm.pageChanged()"></uib-pagination>