(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name helpers.sv.factory:SvHelper
   *
   * @description
   *
   */
  angular
    .module('helpers.sv')
    .factory('SvHelper', SvHelper);


  SvHelper.$inject = ['Sv', '$uibModal', '$log'];

  function SvHelper(Sv, $uibModal, $log) {
    var SvHelperBase = {};

    SvHelperBase.editSv = editSv;

    function editSv(lsv) {
      var dialog = $uibModal.open({
        template: '<div class="modalspacer"><sv-edit sv="miCtrl.sv" dialog="miCtrl.$uibModalInstance"></sv-edit></div>',
        controllerAs: 'miCtrl',
        controller: ['sv', '$uibModalInstance', function (ssv, $uibModalInstance) {
          var vmi = this;
          vmi.sv = ssv;
          vmi.$uibModalInstance = $uibModalInstance;
        }],
        resolve: {
          sv: function () {
            return lsv;
          },
          dialog: function () {
            return dialog;
          }
        },
        size: 'lg'
      });
      return dialog;
    }
    
    return SvHelperBase;
  }
}());
