/**
 * Created by lari on 04/03/2017.
 */

(function () {
  'use strict';
  /**
   * @ngdoc directive
   * @name config.component:todowidget
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="todo">
   <file name="index.html">
   <rksv-init></rksv-init>
   </file>
   </example>
   *
   */
  
  // var todowidget;
  
  angular
    .module('finance.rksv')
    .component('rksvInit', {
      restrict: 'E',
      // templateUrl: 'config/todowidget-directive.tpl.html',
      template: '<h2>Startbeleg</h2>' +
      '<button class="btn btn-primary" ng-click="vm.createStartBeleg()">Start-Beleg erstellen</button>' +
      '<div class="alert alert-{{vm.status || info}} spacer-top" ng-hide="vm.status === \'init\'">' +
      '<h3>{{vm.message}}</h3>' +
      '<p ng-show="vm.pdfid"><a href="/routes/pdfreader/{{vm.pdfid}}" target="_blank">PDF anzeigen</a></p>' +
      '<p ng-show="vm.hint">{{vm.hint}}</p>' +
      '</div>',
      controllerAs: 'vm',
      controller: ['$log', '$http', 'RksvHelper',
        function ($log, $http, RksvHelper) {
          var vm = this;
          vm.status = 'init';
          vm.createStartBeleg = createStartBeleg;
          
          $log.debug('RKSV Init called');
          
          function createStartBeleg() {
            var invoiceobj = RksvHelper.getNullBeleg();
  
            $http.post('/routes/rksvinit', invoiceobj).then(function (res) {
              vm.status = 'success';
              vm.message = 'Erstellung des Startbelegs ' + res.data.nbnum + ' war erfolgreich';
              vm.pdfid = res.data.pdfid;
              vm.hint = 'QR Code: ' + res.data.qrcode;
              $log.debug('RKSV init record sent successfully');
            }, function (res) {
              $log.error('RKSV init record failed');
              vm.status = 'danger';
              vm.message = 'Erstellung des Startbelegs ist fehlgeschlagen';
              vm.hint = 'Hinweis: Der Startbeleg kann nur ein mal erstellt werden!';
            });
  
          }
          
        }]
    });
  
  // klammerfriedhof
}());
