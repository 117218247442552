(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name examtemplate.controller:ExamtemplateCtrl
   *
   * @description
   *
   */
  angular
    .module('examtemplate')
    .controller('ExamtemplateCtrl', ExamtemplateCtrl)
    .controller('TemplateEditCtrl', TemplateEditCtrl)
    .controller('TemplateTestCtrl', TemplateTestCtrl);

  ExamtemplateCtrl.$inject = ['Examtemplate', 'Examination', 'TestPatient', '$uibModal', 'Sampletemplate', '$log', 'Share'];

  function ExamtemplateCtrl(Examtemplate, Examination, TestPatient, $uibModal, Sampletemplate, $log, Share) {
    var vm = this;
    vm.ctrlName = 'ExamtemplateCtrl';
    vm.loadSampletemplate = loadSampletemplate;
    vm.Exam = Examination;
    vm.Examtemplate = Examtemplate;
    vm.alltemplates = vm.Examtemplate.$search();


    vm.createTemplate = createTemplate;
    vm.deleteTemplate = deleteTemplate;
    vm.editTemplate = editTemplate;
    vm.testTemplate = testTemplate;
    
    

    function loadSampletemplate() {
      var samples = Sampletemplate.$search();
      samples.$create();
      vm.alltemplates.$refresh();
    }


    function createTemplate() {
      if (vm.alltemplates.length === 0) {
        vm.newtemplate = vm.Examtemplate.$create();
        vm.alltemplates.$refresh();
      }
      else {
        vm.newtemplate = vm.alltemplates.$create();
      }
      console.log('newtemplate: ', vm.newtemplate);
      vm.editTemplate(vm.newtemplate);
    }

    function editTemplate(tpl) {
      $uibModal.open({
        templateUrl: 'tepledittpl',
        size: 'lg',
        controller: ['$uibModalInstance', 'tpl', 'Share', TemplateEditCtrl],
        controllerAs: 'tpeCtrl',
        resolve: {
          tpl: function () {
            return tpl;
          }
        }
      });
    }

    function testTemplate(tpl) {
      var newexam = {};
      var patient = TestPatient.$create();

      newexam.created = new Date().toISOString();
      newexam.test = true;
      newexam.templateid = tpl._id;
      newexam.template = tpl.template;
      newexam.patientid = patient._id;

      vm.Exam.$create(newexam).$asPromise().then(function (oe) {
        patient.openExam = oe;
        patient.$save();
        // $state.go('patientrecord.current.exam');
        $uibModal.open({
          // templateUrl: 'tepledittpl',
          template: '<exameditor patient="tptCtrl.patient"></exameditor>',
          size: 'lg',
          controller: ['$uibModalInstance', 'tpl', 'patient', TemplateTestCtrl],
          controllerAs: 'tptCtrl',
          resolve: {
            tpl: function () {
              return tpl;
            },
            patient: function () {
              return patient;
            }
          }
        });

      });
    }

    function deleteTemplate(tpl) {
      tpl.active = false;
      tpl.$save();
    }
  } // ExamtemplateCtrl


  function TemplateTestCtrl($uibModalInstance, tpl, patient) {
    var vm = this;
    vm.tpl = tpl;
    vm.patient = patient;
    vm.$uibModalInstance = $uibModalInstance;
  }


  function TemplateEditCtrl($uibModalInstance, tpl, Share) {
    var vm = this;
    vm.tpl = tpl;
    vm.allshares = Share.$search({active: true});
    vm.$uibModalInstance = $uibModalInstance;

    vm.newFieldGroup = function () {
      vm.tpl.template.fieldgroups.push({
        label: 'Neue Feldgruppe',
        fields: [{
          label: 'Neues Feld',
          avalues: [],
          values: []
        }]
      });
    };

    vm.newSubItem = function (scope) {
      var nodeData = scope.$modelValue;
      nodeData.fields.push({
        label: 'Neues Feld',
        avalues: [],
        values: []
      });
    };

    vm.remove = function (scope) {
      scope.remove();
      vm.tpl.$save();
    };

    vm.addNamedField = function (type) {
      var labels = {
        comment: 'Kommentar',
        biopsy: 'Biopsie',
        recommendation: 'Empfehlung',
        diagnosis: 'Diagnose'
      };
      var biopsy = {
        label: '',
        avalues: [],
        values: []
      };
      vm.tpl.template[type] = {
        label: labels[type],
        avalues: [],
        values: []
      };
      if (type === 'biopsy') {
        vm.tpl.template[type].biopsies = [];
        vm.tpl.template[type].biopsies.push(biopsy);
      }
      vm.tpl.$save();
    };

    vm.removeNamedField = function (type) {
      delete vm.tpl.template[type];
      vm.tpl.$save();
    };

    vm.toggle = function (scope) {
      scope.toggle();
    };

    vm.closeModal = function () {
      vm.$uibModalInstance.close();
    };

    vm.collapseAll = function () {
      vm.$broadcast('collapseAll');
    };

    vm.expandAll = function () {
      vm.$broadcast('expandAll');
    };
  }


// klammerfriedhof
}());
