<div class="form-group" style="padding: 0px; margin-bottom: 0px">
    <span class="col-md-4" style="padding: 0px; margin-top: 0px"><label for="postcode">PLZ</label>
        <span class="input-group">
            <input type="text" ng-model="zcCtrl.zip" ng-show="zcCtrl.zip" tabindex="{{zcCtrl.ti}}"
                    class="form-control" id="postcode" placeholder="PLZ">
            <input type="text" class="form-control" ng-hide="zcCtrl.zip" tabindex="{{zcCtrl.ti}}"
                   autocomplete="off"
                   id="postcode"
                   placeholder="PLZ"
                   ng-model="zcCtrl.asyncSelected"
                   ng-blur="zcCtrl.foreignzip()"
                   typeahead-on-select="zcCtrl.getzipcity($item, $model, $label)"
                   uib-typeahead="zip as zip.zip for zip in zcCtrl.getNames($viewValue)"
                   typeahead-loading="loadingNames"
                   typeahead-template-url="customTemplate.html"
                   typeahead-min-length="4"
                   typeahead-no-results="noResults">
             <span class="input-group-btn">
                  <button class="btn btn-danger" tabindex="-1"
                          ng-click="zcCtrl.zip = ''; zcCtrl.city = ''; zcCtrl.asyncSelected = ''">
                      <i class="fa fa-remove"></i>
                  </button>
             </span>
        </span>
    </span><!-- css sucks, do not remove this comment
 --><span class="col-md-8" style="padding: 0px">
    <label for="postcode">Ort</label>
        <input type="text" ng-model="zcCtrl.city" autocomplete="off" tabindex="{{zcCtrl.ti2}}"
               class="form-control" id="city" placeholder="Ort">
    </span>
</div>
<div ng-show="noResults">
    <i class="glyphicon glyphicon-remove"></i> Keine Orte gefunden
</div>
<i ng-show="loadingNames" class="glyphicon glyphicon-refresh"></i>

<script type="text/ng-template" id="customTemplate.html">
    <a href
       tabindex="-1"
       ng-bind-html="match.model.text | uibTypeaheadHighlight:query"
       ng-attr-title="{{match.model.text}}"></a>
</script>
