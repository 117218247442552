(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name checkinvoice.factory:Checkinvoice
   *
   * @description
   *
   */
  angular
    .module('finance.invoice')
    .factory('Checkinvoice', Checkinvoice);

  Checkinvoice.$inject = ['restmod'];

  function Checkinvoice(restmod) {
    return restmod.model('/routes/checkinvoices', 'PagedModel').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      }
    });
  }
}());
