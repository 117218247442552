(function () {
  'use strict';

  angular
    .module('share')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('shares', {
        url: '/shares',
        views: {
          'main': {template: '<share-editor></share-editor>'},
          //'leftbar': {template: '<patient-today></patient-today>'},
          'rightupper': {template: '<todowidget></todowidget>'},
          'rightlower': {template: '<h3>I\'m a chat window</h3>'}
        }
      });


  }


}());
