(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name dms.signoff.factory:SignHelper
   *
   * @description
   *
   */
  angular
    .module('dms.signoff')
    .factory('SignHelper', SignHelper);

  SignHelper.$inject = ['vertxEventBusService', '$log'];

  function SignHelper(vertxEventBusService, $log) {
    var SignHelperBase = {};

    SignHelperBase.triggerUpdate = function () {
      vertxEventBusService.publish('signoff.update', {});
      $log.debug('signoff update triggered');
    };

    return SignHelperBase;
  }
}());
