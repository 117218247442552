(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name testdata.directive:testdata
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="testdata">
       <file name="index.html">
        <testdata></testdata>
       </file>
     </example>
   *
   */
  angular
    .module('testdata')
    .directive('testdata', testdata);

  testdata.$inject = ['Patient', 'Consult', 'Examination', '$log'];

  function testdata(Patient, Consult, Examination, $log) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'testdata/testdata-directive.tpl.html',
      replace: false,
      controllerAs: 'tdCtrl',
      controller: function () {
        var vm = this;
        var i = 0;
        var femnames = [];
        var malenames = [];
        var lastnames = [];
        var streets = [];
        var cities = [];
        var svs = [];
        var numbers = [];
        var workplaces = [];
        var titlefronts = [];
        var titlebacks = [];
        var keywords = [];
        var gender = [];
        vm.numberofsets = 10;
        vm.setsgenerated = 0;
        vm.incConsults = false;
        vm.incExams = false;
        vm.incFamily = true;
        vm.generatePatients = generatePatients;

        function generatePatients() {
          var newpat = {};
          var date;
          vm.setsgenerated = 0;
          vm.numberofsets = parseInt(vm.numberofsets, 10);
          for (i = 0; i < vm.numberofsets; i++) {
            newpat = {};
            newpat.titlefront = randomize(titlefronts);
            newpat.titleback = randomize(titlebacks);
            newpat.address = randomize(streets) + ' ' + randomize(numbers);
            newpat.postcode = Math.floor(Math.random() * 1000 + 1000);
            newpat.titlefront = randomize(titlefronts);
            newpat.titlefront = randomize(titlefronts);
            date = randombirthdate();
            newpat.birthdate = date.format('DD.MM.YYYY');
            newpat.svnr = '9999' + date.format('DDMMYY');
            // newpat.svs = randomize(svs);
            newpat.lastname = randomize(lastnames);
            newpat.city = randomize(cities);
            newpat.employer = randomize(workplaces);
            newpat.practitioner = 'Dr. M. Musterarzt';
            newpat.keywords = [randomize(keywords)];
            newpat.test = true;
            newpat.created = randomcreatedate();
            if (i % 2 === 0) {
              newpat.firstname = randomize(femnames);
              newpat.gender = 'weiblich';
              newpat.image = '/img/head_female.png';
            }
            else {
              newpat.firstname = randomize(malenames);
              newpat.gender = 'männlich';
              newpat.image = '/img/head_male.png';
            }


            Patient.$create(newpat).$asPromise().then(function () {
              vm.setsgenerated++;
            });
          }

        }

        function randombirthdate() {
          var day = Math.floor(Math.random() * 28 + 1);
          var month = Math.floor(Math.random() * 12 + 1);
          var year = Math.floor(Math.random() * 100 + 1914);
          return moment(new Date(year, month, day));
        }

        function randomcreatedate() {
          var day = Math.floor(Math.random() * 28 + 1);
          var month = Math.floor(Math.random() * 12 + 1);
          var year = Math.floor(Math.random() * 2 + 2013);
          return new Date(year, month, day);
        }

        function randomize(value) {
          var val = value[Math.floor(Math.random() * value.length)];
          return val;
        }

        keywords = [
          {col: 'success', key: 'Gastritis', created: '4.11.2015', nr: 1, updated: '4.3.2016'},
          {col: 'info', key: 'Erstbesuch', created: '4.11.2015', nr: 1, updated: '4.3.2016'},
          {col: 'danger', key: 'Hep C', created: '4.11.2015', nr: 1, updated: '4.3.2016'},
          {col: 'primary', key: 'Ulcus duodeni', created: '4.11.2015', nr: 1, updated: '4.3.2016'},
          {col: 'info', key: 'Erstbesuch', created: '4.11.2015', nr: 1, updated: '4.3.2016'},
          {col: 'success', key: 'Ulcus', created: '4.11.2015', nr: 1, updated: '4.3.2016'},
          {col: 'warning', key: 'Morbus Chron', created: '4.11.2015', nr: 1, updated: '4.3.2016'}
        ];

        gender = ['weiblich','männlich','unbekannt'];
        numbers = [3, 34, 11, 56, 73, 47, 62, 79, 24, 23, 67, 114, 345, '24/Loft 3', '110-116/7/34', '34/2/17', '51-57/3/24']
        streets = ['Mustergasse', 'Beispielsplatz', 'Kirschenweg', 'Landstra\u00DFe', 'Haupstra\u00DFe', 'Ring'];
        cities = ['Satellitenstadt', 'Musterort', 'Mustergemeinde', 'Entenhausen', 'Musterstadt', 'Phantasia'];
        svs = ['WGGK', 'N\u00D6GKK', 'StGKK', 'O\u00D6', 'BGKK', 'SVA', 'BVA', 'EBK'];
        workplaces = ['ACME GmbH', 'REWE AG', 'Telekom Austria AG'];
        titlefronts = ['Dr.', 'Dr. DI.', 'Dipl.Ing.', 'Mag', '', '', '', '', '', '', ''];
        titlebacks = ['BSc', 'MSc.', 'MBA', 'BA', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
        lastnames = ['Mustermensch', 'Musterpatient', 'Musterfamilie', 'Mustermax', 'Mustermann', 'Musterquux',
          'Musterfoo', 'Musterix', 'Musterax', 'Mustermini', 'Mustermaxi', 'Musterm\u00FCller', 'Mustermayr',
          'Testmensch', 'Testpatient', 'Testmayer', 'Testiwitsch', 'Testorax', 'Quuxmuster', 'Foomuster',
          'Quuxmensch', 'Quuxpatient', 'Foopatient', 'Meyerpatient', 'Maierpatient', 'Mayerpatient',
          'M\u00FCllerpatient', 'Huberpatient', 'Prohaskapatient', 'Patientmuster', 'Patienthuber'];

        femnames = ['Anna', 'Sophia', 'Emilia', 'Emma', 'Sophie', 'Marie', 'Johanna', 'Mia', 'Helena', 'Luisa', 'Laura', 'Lena',
          'Ella', 'Amelie', 'Clara', 'Lara', 'Julia', 'Lina', 'Theresa', 'Paula', 'Antonia', 'Emily', 'Elisa', 'Lea',
          'Marlene', 'Charlotte', 'Magdalena', 'Hannah', 'Greta', 'Leonie', 'Matilda', 'Mila', 'Hanna', 'Valentina',
          'Maja', 'Sarah', 'Sara', 'Franziska', 'Maria', 'Josephine', 'Mathilda', 'Isabella', 'Elena', 'Lisa', 'Sofia',
          'Victoria', 'Katharina', 'Klara', 'Lilly', 'Annika', 'Elisabeth', 'Alina', 'Ida', 'Carla', 'Leni', 'Carlotta',
          'Lucia', 'Maya', 'Nora', 'Isabel', 'Frida', 'Jasmin', 'Linda', 'Mina', 'Paulina', 'Alma', 'Luise', 'Rosalie',
          'Fiona', 'Nele', 'Viktoria', 'Pauline', 'Jana', 'Louisa', 'Miriam', 'Olivia', 'Stella', 'Zoe', 'Elif', 'Emilie',
          'Eva', 'Helene', 'Josefine', 'Nina', 'Romy', 'Julie', 'Lia', 'Lotta', 'Melina', 'Pia', 'Alexandra', 'Frieda',
          'Isabelle', 'Nisa', 'Philippa', 'Vanessa', 'Anastasia', 'Flora', 'Larissa', 'Marla', 'Martha', 'Medina',
          'Melisa', 'Milena', 'Mira', 'Natalie', 'Rosa', 'Aurelia', 'Ava', 'Ela', 'Lorena', 'Veronika', 'Aylin',
          'Cecilia', 'Elina', 'Hana', 'Karla', 'Luna', 'Mara', 'Melissa', 'Meryem', 'Rebecca', 'Ronja', 'Valerie',
          'Alisa', 'Ana', 'Chiara', 'Christina', 'Elin', 'Elise', 'Felicitas', 'Leyla', 'Livia', 'Lotte', 'Milla',
          'Noemi', 'Selin', 'Alea', 'Alicia', 'Amira', 'Annabelle', 'Eleni', 'Elsa', 'Emelie', 'Florentine', 'Giulia',
          'Leandra', 'Lilli', 'Liv', 'Marta', 'Michelle', 'Selma', 'Zara', 'Alessia', 'Alice', 'Alissa', 'Angelina',
          'Anika', 'Cosima', 'Kristina', 'Lana', 'Lejla', 'Mariam', 'Mona', 'Natalia', 'Nicole', 'Nika', 'Sina',
          'Smilla', 'Sonja', 'Tara', 'Teresa', 'Tuana', 'Vivien', 'Yara', 'Zeynep', 'Ada', 'Alba', 'Anouk', 'Carolina',
          'Filippa', 'Finja', 'Kira', 'Liliana', 'Lola', 'Madita', 'Marlena', 'Talia', 0, 'Agnes', 'Alexa', 'Amalia',
          'Amanda', 'Amelia', 'Amina', 'Anisa', 'Anja', 'Anne', 'Anni', 'Ariana', 'Ariane', 'Diana', 'Emely', 'Franka',
          'Helen', 'Ines', 'Jule', 'Leah', 'Leona', 'Lily', 'Luana', 'Lucie', 'Malina', 'Mariella', 'Marina', 'Maryam',
          'Mathilde', 'Sofija', 'Tamara', 'Alva', 'Annabell', 'Cataleya', 'Celina', 'Cleo', 'Dalia'];
        malenames = ['Maximilian', 'Felix', 'David', 'Moritz', 'Jakob', 'Paul', 'Julian', 'Alexander', 'Vincent', 'Leon',
          'Lukas', 'Noah', 'Anton', 'Emil', 'Benjamin', 'Luis', 'Valentin', 'Jonas', 'Leo', 'Daniel', 'Luca', 'Philipp',
          'Elias', 'Jonathan', 'Tim', 'Niklas', 'Samuel', 'Simon', 'Leopold', 'Louis', 'Ben', 'Leonard', 'Oskar', 'Benedikt',
          'Ludwig', 'Sebastian', 'Fabian', 'Ferdinand', 'Florian', 'Julius', 'Max', 'Adrian', 'Gabriel', 'Raphael', 'Konstantin',
          'Lucas', 'Henri', 'Johann', 'Jan', 'Finn', 'Linus', 'Nicolas', 'Rafael', 'Theo', 'Tobias', 'Xaver', 'Lorenz', 'Levi',
          'Oscar', 'Johannes', 'Korbinian', 'Michael', 'Nico', 'Quirin', 'Christian', 'Kilian', 'Bastian', 'Nils', 'Ali',
          'Leonhard', 'Liam', 'Theodor', 'Adam', 'Arthur', 'Constantin', 'Dominik', 'Erik', 'Karl', 'Luka', 'Mateo', 'Aaron',
          'Mustafa', 'Oliver', 'Timo', 'Tom', 'Emir', 'Leonardo', 'Jonah', 'Levin', 'Manuel', 'Mats', 'Matteo', 'Patrick',
          'Severin', 'Yusuf', 'Antonio', 'Diego', 'Franz', 'Frederik', 'Georg', 'Gustav', 'Henry', 'Jacob', 'Jason',
          'Martin', 'Toni', 'Viktor', 'Deniz', 'Emilio', 'Hannes', 'Lennart', 'Malik', 'Milan', 'Carl', 'Joel', 'Josef',
          'Justus', 'Lars', 'Marcel', 'Mika', 'Nick', 'Noel', 'Otto', 'Sami', 'Tristan', 0, 'Alexandros', 'Bruno',
          'Damian', 'Eren', 'Fritz', 'Joshua', 'Marc', 'Marko', 'Marlon', 'Maxim', 'Philip', 'Richard', 'Robert', 'Robin',
          'Yasin', 'Amin', 'Benno', 'Christoph', 'Clemens', 'Colin', 'Enes', 'Fabio', 'Ismail', 'Leander', 'Marco', 'Mark',
          'Markus', 'Mert', 'Mikail', 'Ole', 'Victor', 'Alex', 'Amar', 'Amir', 'Andreas', 'Bilal', 'Carlos', 'Filip', 'Hugo',
          'Armin', 'Batuhan', 'Cornelius', 'Dario', 'Diar', 'Emanuel', 'Eric', 'Frederic', 'Friedrich', 'Henrik', 'Jack', 'James',
          'Jannes', 'Jannik', 'Jannis', 'Jayden', 'Jeremy', 'John', 'Joseph', 'Kaspar', 'Kerem', 'Koray', 'Levent', 'Lian',
          'Lovis', 'Luke', 'Mohammed', 'Muhammad', 'Muhammed', 'Nathaniel', 'Neo', 'Raffael', 'Sam', 'Selim', 'Tiago', 'Timon',
          'Vinzenz', 'Vitus', 'Adriano', 'Aiden', 'Alessio', 'Andrej', 'Angelo', 'Arda', 'Augustin', 'Aurel', 'Bela', 'Can',
          'Caspar', 'Cem', 'Cinar', 'Cristiano', 'Daris', 'Ege', 'Eliah', 'Elijah', 'Elliot', 'Emin'];



      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2,  {"args": "none"}] */
      }
    };
  }
}());
