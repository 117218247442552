(function () {
  'use strict';

  /* @ngdoc object
   * @name chat
   * @description
   *
   */
  angular
    .module('chat', [
      'ui.router',
      'knalli.angular-vertxbus',
      'luegg.directives'
    ]);
}());
