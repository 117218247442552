<h3>Wartelisten</h3>

<div class="alert alert-info" ng-hide="cwlCtrl.waitinglists.length > 0">Es wurden noch keine Wartelisten angelegt</div>

<div ng-repeat="a in cwlCtrl.waitinglists">
  <div class="input-group">
    <input type="text" class="form-control" ng-model="a.name" ng-blur="a.$save()">
    <span class="input-group-btn">
        <button class="btn btn-danger" type="button" ng-click="cwlCtrl.splice($index, a)"><i class="fa fa-remove"></i></button>
      </span>
  </div>
</div>
<ng-form name="cwlCtrl.formid">
  <div class="input-group">
    <input type="text" class="form-control" ng-model="cwlCtrl.newlist" placeholder="Neue Warteliste..." ng-keyup="$event.keyCode == 13 ? cwlCtrl.add() : null">
    <span class="input-group-btn">
        <button class="btn btn-success" type="button" ng-click="cwlCtrl.add()"><i class="fa fa-plus"></i></button>
      </span>
  </div>
</ng-form>