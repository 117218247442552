<div class="row">
  <div class="col-md-2">
    <img src="patient.image" class="img-responsive" alt="{{patient.firstname}} {{patient.lastname}}">
  </div>
  <div class="col-md-10">

    <h3><span ng-show="patient.titlefront">{{patient.titlefront}} </span>{{patient.firstname}} {{patient.lastname}}<span
      ng-show="patient.titleback">, {{patient.titleback}} </span>,
      * {{patient.birthdate}}</h3>

    <div ng-show="editablekeywords == 'true'">
      <keywords-widget keywords="patient.keywords"></keywords-widget>
    </div>
    <div ng-hide="editablekeywords == 'true'">
      <div ng-repeat="(key, value) in patient.keywords">
        <span class="label label-{{value}}">{{key}}</span>
      </div>
    </div>
  </div>
</div>

