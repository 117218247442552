(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name rlog.service:Rlog
   *
   * @description
   *
   */
  angular
    .module('rlog')
    .service('Rlog', Rlog);

  Rlog.$inject = ['vertxEventBusService'];
  
  function Rlog(vertxEventBusService) {
    this.debug = function (module, directive, text, payload) {
      var message = {};
      message.module = module;
      message.directive = directive;
      message.text = text;
      message.payload = payload;
      
      return vertxEventBusService.publish('rlog.entry', message);
    };
  }
}());
