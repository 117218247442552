<h3>Durchgeführte Untersuchungen</h3>
<uib-accordion close-others="oneAtATime">
  <uib-accordion-group ng-repeat="exam in celCtrl.patient.openConsult.examinations | filter: {active: true}"
                       is-open="status.isOpen[$index]">
    <!-- exam header -->
    <uib-accordion-heading>
      <div class="row">
        <div class="col-xs-12 col-sm-9">
          <h4>
            <span ng-show="status.isOpen[$index]" ng-click="status.isOpen[$index] = !status.isOpen[$index]"><i class="fa fa-caret-up"></i></span>
            <span ng-hide="status.isOpen[$index]" ng-click="status.isOpen[$index] = !status.isOpen[$index]"><i class="fa fa-caret-down"></i></span>
            &nbsp;{{exam.template.displayname}}</h4>
          </div>
        <div class="col-xs-12 col-sm-3" style="text-align: right">
          <h4>
            <a class="btn btn-info btn-sm"
               href="/routes/pdfprinter/exampreview?patientid={{celCtrl.patient._id}}&examid={{exam._id}}&doctype=examfinding"
               target="_blank" uib-tooltip="PDF Vorschau"><i class="fa fa-eye"></i></a>
            <a class="btn btn-primary btn-sm" ng-click="celCtrl.editExam(exam, celCtrl.patient)" uib-tooltip="Editieren">
              <i class="fa fa-edit"></i></a>
            <a class="btn btn-danger btn-sm" ng-click="celCtrl.deleteExam(exam, celCtrl.patient)" uib-tooltip="Entfernen">
              <i class="fa fa-remove"></i></a>
          </h4>
        </div>
      </div>
    </uib-accordion-heading>
    <!-- exam data -->
    <div class="row">
      <div class="col-xs-12">
        <!-- fieldgroups -->
        <div ng-repeat="grp in exam.template.fieldgroups">
          <h5 class="dl-horizontal" ng-hide="grp.label == ''" ng-model="grp.label"
              style="border-bottom: 1px solid black; border-top: 1px solid black;  padding-top: 5px; padding-bottom: 5px">
            {{grp.label}}
          </h5>
          <h5 class="dl-horizontal" ng-show="grp.label == ''" ng-model="grp.label"
              style="border-bottom: 1px solid black; padding-top: 5px; padding-bottom: 5px">
          </h5>
          <!-- fields -->
          <dl class="dl-horizontal">
          <span class="dl-horizontal" ng-repeat="field in grp.fields">
            <dt>{{field.label}}</dt>
            <dd><span ng-repeat="val in field.avalues">{{val.name}}{{$last ? '' : ', '}}</span></dd>
          </span>
          </dl>
        </div>

        <!-- exam images -->
        <div class="row spacer-top" ng-show="exam.files">
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 imgcontainer"
               ng-repeat="img in exam.files | filter: {mimetype: 'image/jpeg'} |  orderBy: '_id'">

            <img class="img-responsive" src="/routes/files/filereader/{{img.fileid}}"
                 uib-tooltip="{{img.label}}"
                 ng-click="celCtrl.editImage(img)">
            <div class="imgcheckbox"><input type="checkbox" ng-model="img.include" ng-click="exam.$save()"></div>
          </div>
        </div>

        <!-- Befundblock  -->
        <h5 class="dl-horizontal"
            style="border-bottom: 1px solid black; border-top: 1px solid black; padding-top: 5px; padding-bottom: 5px">
          Befund</h5>
        <dl class="dl-horizontal">
          <dt>Diagnose</dt>
          <dd><span
              ng-repeat="diagnosis in exam.template.diagnosis.avalues">{{diagnosis.name}}{{$last ? '' : ', '}}</span>
          </dd>
          <dt>Empfehlung</dt>
          <dd><span
              ng-repeat="diagnosis in exam.template.recommendation.avalues">{{diagnosis.name}}{{$last ? '' : ', '}}</span>
          </dd>
          <dt>Anmerkung</dt>
          <dd><span
              ng-repeat="diagnosis in exam.template.comment.avalues">{{diagnosis.name}}{{$last ? '' : ', '}}</span>
          </dd>
        </dl>

      </div>
    </div>
  </uib-accordion-group>  <!-- exam in openConsult.examinations -->
</uib-accordion>

<!--<div class="row" ng-repeat="exam in celCtrl.exams | filter:{active: true}| orderBy:'-created'">
  <div class="col-xs-12 col-lg-8">
    <strong>{{exam.template.displayname}} ({{exam.time}})</strong><br>
    <span ng-repeat="diagnosis in exam.template.diagnosis.avalues">{{diagnosis.name}}{{$last ? '' : ', '}}</span>
  </div>
  <div class="col-xs-12 col-md-4" style="text-align: right" >
    <a class="btn btn-success" ng-init="exam.showpictures = false" ng-click="exam.showpictures = !exam.showpictures"
       uib-tooltip="Bilder anzeigen" ng-show="exam.files"><i class="fa fa-image"></i></a>
    &lt;!&ndash;<a class="btn btn-primary" ng-click="celCtrl.editExam(exam, celCtrl.patient)"  uib-tooltip="Editieren"><i class="fa fa-edit"></i></a>&ndash;&gt;
    <a class="btn btn-primary" href="/routes/pdfprinter/exampreview?patientid={{celCtrl.patient._id}}&examid={{exam._id}}&doctype=examfinding"
       target="_blank" uib-tooltip="PDF Vorschau"><i class="fa fa-eye"></i></a>
    <a class="btn btn-primary" ng-click="celCtrl.editExam(exam)"  uib-tooltip="Editieren"><i class="fa fa-edit"></i></a>
    <a class="btn btn-danger" ng-click="celCtrl.deleteExam(exam, celCtrl.patient)" uib-tooltip="Entfernen"><i class="fa fa-remove"></i></a>
</div>
  <div class="col-xs-12" ng-show="exam.showpictures">
    <div class="row spacer-top">
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 imgcontainer"
           ng-repeat="img in exam.files | filter: {mimetype: 'image/jpeg'} |  orderBy: '_id'">

        <img class="img-responsive" src="/routes/files/filereader/{{img.fileid}}"
             uib-tooltip="{{img.label}}"
             ng-click="celCtrl.editImage(img)">
        <div class="imgcheckbox"><input type="checkbox" ng-model="img.include" ng-click="exam.$save()"></div>
      </div>
    </div>
  </div>
</div>-->

