/**
 * Created by lari on 09/04/2017.
 */
(function () {
  'use strict';
  angular.module('finance.cashbook')
    .component('cashbook', {
      restrict: 'E',
      templateUrl: 'finance/cashbook/cashbook-component.tpl.html',
      controllerAs: 'vm',
      controller: ['Cashbook', 'Fiscaljournal', '$log',
        function (Cashbook, Fiscaljournal, $log) {
          var vm = this;
          vm.currentPage = 1;
          vm.pageChanged = pageChanged;
          
          vm.cashbook = Cashbook.$search();
          vm.cashlog = Fiscaljournal.$search().$then(function (log) {
            vm.totalItems = log.$pageCount;
          });
          
          vm.deposit = deposit;
          vm.withdrawal = withdrawal;
  
          function deposit() {
            vm.cashbook[0].amount = vm.depositvalue;
            vm.cashbook[0].add = true;
            vm.cashbook[0].$save().$then(function () {
              vm.depositvalue = 0;
              vm.cashbook.$refresh();
              vm.cashlog.$refresh();
            });
    
          }
  
          function withdrawal() {
            vm.cashbook[0].amount = vm.withdrawalvalue;
            vm.cashbook[0].add = false;
            vm.cashbook[0].$save().$then(function () {
              vm.withdrawalvalue = 0;
              vm.cashbook.$refresh();
              vm.cashlog.$refresh();
            });
          }
  
          function pageChanged() {
            vm.cashlog.$refresh({page: vm.currentPage}).$then(function (log) {
              vm.totalItems = log.$pageCount;
            });
          }
          
        }] // controller
    }); // component

// klammerfriedhof
}());