(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name consult.directive:currentConsultContainer
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="consult">
   <file name="index.html">
   <current-consult-container></current-consult-container>
   </file>
   </example>
   *
   */
  
  angular
    .module('consult')
    .directive('currentConsultContainer', currentConsultContainer);
  
  currentConsultContainer.$inject = ['ConsultRefresh', '$log'];
  
  function currentConsultContainer(ConsultRefresh, $log) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'consult/current-consult-container-directive.tpl.html',
      replace: false,
      controllerAs: 'consCtrl',
      controller: 'ConsultCtrl',
      bindToController: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */

        // this ist NOT healthy!!!!‚ refresh all must be called by each child state!
        // ConsultRefresh.refreshAll(ctrl.patient);

        // if (typeof ctrl.patient.diagnoses == "undefined" || ctrl.patient.diagnoses == null) {
        if (!angular.isObject(ctrl.patient.diagnoses)) {
          ctrl.patient.diagnoses = {};
        }

        /*
        // watch patient new diagnoses
        scope.$watch('consCtrl.patient.diagnoses', function (newd, oldd) {
          /!*
          // TODO: this does not work reliably, consult keywords are attached to wrong consult
          if (newd.length > oldd.length && ctrl.patient.inconsult) {
            ctrl.patient.openConsult.keywords.push(newd[newd.length - 1]);
            // ctrl.patient.openConsult.$save(); // not necessary, consult keywords watched autonomously
          }*!/
          // ctrl.patient.$save(); // do not save patient, patient diagnoses watched from patient-record-view
        }, true);*/
        
        // the third argument 'true' ensures deep checking of contents, works better than watchCollection
        scope.$watch('consCtrl.patient.openConsult.keywords', function () {
          if (angular.isObject(ctrl.patient) && angular.isObject(ctrl.patient.openConsult)) {
            ctrl.patient.openConsult.flatkeywords = [];
            ctrl.patient.openConsult.keywords.forEach(function (kw) {
              ctrl.patient.openConsult.flatkeywords.push(kw.key);
            });
            // $log.debug('new consult flatkeywords: ', ctrl.patient.openConsult.flatkeywords);
            // $log.debug('consult keywords on change: ', ctrl.patient.openConsult.keywords);
            ctrl.patient.openConsult.$save();
          }
          // ctrl.patient.$save(); // unnecessary at this point!!
        }, true);

        // the third argument 'true' ensures deep checking of contents, works better than watchCollection
        scope.$watch('consCtrl.patient.diagnoses', function (patdiagnoses) {
          if (angular.isObject(ctrl.patient) && angular.isArray(ctrl.patient.diagnoses)) {
            ctrl.patient.$save();
          }
        }, true);

      }
    };
  }
}());
