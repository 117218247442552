(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name share.factory:Share
   *
   * @description
   *
   */
  angular
    .module('share')
    .factory('Share', Share);

  Share.$inject = ['restmod'];

  function Share(restmod) {
    return restmod.model('/routes/shares');
  }


}());
