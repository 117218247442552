<h2>Laborwerte und Laborwertgruppen</h2>

<h3>Werte</h3>

<tags-input ng-model="labECtrl.labconfig.values"
            display-property="name"
            placeholder="Neuen Laborwert hinzufügen"
            replace-spaces-with-dashes="false"
            add-on-comma="false"
            min-length="1"
            on-tag-added="labECtrl.saveConfig()"
            on-tag-removed="labECtrl.saveConfig()"
            template="tag-template">
  <auto-complete source="labECtrl.loadValues($query)"
                 min-length="0"
                 load-on-focus="true"
                 load-on-empty="true"
                 max-results-to-show="32"
                 template="autocomplete-template"></auto-complete>
</tags-input>


<h3>Neue Laborwertgruppe</h3>


<form>
  <div class="input-group">
    <input class="form-control"
           ng-model="labECtrl.newgroup" id="newgroup" placeholder="Neue Laborwertgruppe"/>
        <span class="input-group-btn">
          <button class="btn btn-success"
                  type="submit"
                  ng-click="labECtrl.addGroup()"><i class="fa fa-plus"></i> Neue Gruppe anlegen</button>
        </span>
  </div>
  </div>
</form>

<h3>Gruppen</h3>
<form class="form-vertical">
  <div ng-repeat="group in labECtrl.labconfig.groups">
    <label ng-bind="group.name" class="spacer-top"></label>
    <span class="btn btn-danger btn-sm"
          ng-click="labECtrl.removeGroup($index)"
          style="margin-top: 2ex; margin-bottom: 0.8ex;"><i class="fa fa-remove"></i> Gruppe löschen</span>
    <tags-input ng-model="group.values"
                display-property="name"
                placeholder="Tippen zum Vervollständigen"
                replace-spaces-with-dashes="false"
                on-tag-added="labECtrl.saveConfig()"
                min-length="1"
                add-on-comma="false"
                on-tag-removed="labECtrl.saveConfig()"
                template="tag-template">
      <auto-complete source="labECtrl.loadValues($query)"
                     min-length="0"
                     load-on-focus="false"
                     load-on-down-arrow="true"
                     load-on-empty="true"
                     max-results-to-show="32"
                     template="autocomplete-template"></auto-complete>
    </tags-input>
  </div>

  <script type="text/ng-template" id="tag-template">
    <div class="tag-template">
      <div class="right-panel">
        <span>{{$getDisplayText()}}</span>
        <a class="remove-button" ng-click="$removeTag()">&#10006;</a>
      </div>
    </div>
  </script>


  <script type="text/ng-template" id="autocomplete-template">
    <div class="autocomplete-template">
      <div>
        <span ng-bind-html="$highlight($getDisplayText())">{{name}}</span>
      </div>
    </div>
  </script>


</form>
