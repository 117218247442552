(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name consult.factory:ConsultModalsHelper
   *
   * @description
   *
   */
  angular
    .module('consult')
    .factory('ConsultModalsHelper', ConsultModalsHelper);
  
  ConsultModalsHelper.$inject = ['$uibModal', '$log'];
  
  
  function ConsultModalsHelper($uibModal, $log) {
    var ConsultModalsHelperBase = {};

    ConsultModalsHelperBase.addAideModal = addAideModal;
    ConsultModalsHelperBase.addReferralModal = addReferralModal;


    function addAideModal(patient) {
      $uibModal.open({
        template: '<div class="modalspacer"><checkout-aides patient="miCtrl.patient" dialog="miCtrl.$modalInstance"></checkout-aides></div>',
        size: 'modal-sm',
        controller: ['$uibModalInstance', 'patient', function ($uibModalInstance, lpatient) {
          var vmi = this;
          vmi.patient = lpatient;
          vmi.$modalInstance = $uibModalInstance;

          vmi.cancel = function () {
            vmi.$modalInstance.close();
          };

          vmi.ok = function () {
            vmi.patient.$save();
            vmi.$modalInstance.close();
          };
        }],
        controllerAs: 'miCtrl',
        resolve: {
          patient: function () {
            return patient;
          }
        }
      });
    }

    function addReferralModal(patient) {
      $uibModal.open({
        template: '<div class="modalspacer"><checkout-referrals patient="miCtrl.patient" dialog="miCtrl.$modalInstance"></checkout-referrals></div>',
        size: 'modal-sm',
        controller: ['$uibModalInstance', 'patient', function ($uibModalInstance, lpatient) {
          var vmi = this;
          vmi.patient = lpatient;
          vmi.$modalInstance = $uibModalInstance;

          vmi.cancel = function () {
            vmi.$modalInstance.close();
          };

          vmi.ok = function () {
            vmi.patient.$save();
            vmi.$modalInstance.close();
          };
        }],
        controllerAs: 'miCtrl',
        resolve: {
          patient: function () {
            return patient;
          }
        }
      });
    }

    return ConsultModalsHelperBase;
  }
}());
