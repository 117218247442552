(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name utils.listEditor.directive:listEditor
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="utils.listEditor">
       <file name="index.html">
        <list-editor ar=""></list-editor>
       </file>
     </example>
   *
   */
  angular
    .module('utils.listEditor')
    .directive('listEditor', listEditor);

  listEditor.$inject = ['$log'];

  function listEditor($log) {
    return {
      restrict: 'EA',
      scope: {
        ar: '='
      },
      templateUrl: 'utils/list-editor/list-editor-directive.tpl.html',
      replace: false,
      controllerAs: 'listCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;

        vm.splice = splice;
        vm.add = add;
        vm.formid = guid;

        $log.debug('vm.ar: ', vm.ar);

        /*
        if (!angular.isArray(vm.ar)) {
          vm.ar = [];
          $log.debug('array new: ', vm.ar);
        } */
        
        function splice(i) {
          vm.ar.splice(i, 1);
          $log.debug('array: ', vm.ar);
        }

        function add() {
          if (!angular.isArray(vm.ar)) {
            vm.ar = [];
          }
          vm.ar.push(vm.newvalue);
          $log.debug('array: ', vm.ar);
          vm.newvalue = '';
        }
        
        function guid() {
          function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
              .toString(16)
              .substring(1);
          }
          return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
        }

      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
