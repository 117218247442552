(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name forms.directive:textprocessing
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="forms">
       <file name="index.html">
        <textprocessing></textprocessing>
       </file>
     </example>
   *
   */
  angular
    .module('forms')
    .directive('textprocessing', textprocessing);
  
  textprocessing.$inject = ['$log', 'Patientsearch', '$http', '$state', '$timeout'];
  
  function textprocessing($log, Patientsearch, $http, $state, $timeout) {
    return {
      restrict: 'E',
      scope: {},
      templateUrl: 'forms/textprocessing-directive.tpl.html',
      replace: false,
      bindToController: true,
      controllerAs: 'textCtrl',
      controller: function () {
        var vm = this;
        vm.name = 'textprocessing';
        vm.doctorr = {};
        
        vm.print = print;
        vm.clear = clear;
        vm.getNames = getNames;
        vm.showpatient = showpatient;
        
        
        // start with a clean slate
        vm.clear();
        
        
        
        function clear() {
          vm.patient = null;
          vm.doctor = null;
          vm.newtext = {};
          vm.newtext.fdate = moment().locale('de-at').format('ll');
          vm.recipient = {};
          vm.newtext.getdiagnoses = false;
          vm.newtext.getdrugs = false;
          vm.newtext.getrisks = false;
          vm.newtext.getnotes = false;
          vm.newtext.gettodos = false;
          vm.newtext.greetings = true;
        }
        
        
        // vm.tinymceOptions = {selector: 'textarea', skin: 'lightgray', theme: 'modern', resize: true, width: '100%', height: 300, plugins: 'link image media searchreplace table spellchecker print preview template', menubar: 'file edit insert view format table tools', toolbar: 'undo redo | formatselect fontsizeselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent'};
        vm.tinymceOptions = {selector: 'textarea', skin: 'lightgray', theme: 'modern', resize: true, width: '100%', height: 300, entity_encoding: 'raw', plugins: 'link searchreplace table spellchecker print preview template', menubar: 'file edit insert view format table tools', toolbar: 'undo redo | formatselect fontsizeselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent'};
          
        // typeahead patient search backend query
        function getNames(val) {
          console.log('loading names');
    
          return Patientsearch.$search({query: val}).$asPromise().then(function (response) {
            console.log('response: ', response);
            return response.map(function (patient) {
              patient.text = patient.firstname + ' ' + patient.lastname + ', ' + patient.svnr;
              return patient;
            });
          });
        }
  
        // typeahead patient search action
        function showpatient($item, $model, $label) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          vm.patientid = $model._id;
        }
  
        function print() {
          var request;
          var payload = {};
  
          payload.recipient = {};
          
          if (angular.isObject(vm.doctor) && angular.isObject(vm.doctor.fulldoc) && (angular.isString(vm.doctor.fulldoc.lastname) || angular.isString(vm.doctor.fulldoc.institute) )) {
            if (vm.doctor.fulldoc.titlefront) {payload.recipient.titlefront = vm.doctor.fulldoc.titlefront;}
            if (vm.doctor.fulldoc.titleback) {payload.recipient.titleback = vm.doctor.fulldoc.titleback;}
            if (vm.doctor.fulldoc.firstname) {payload.recipient.firstname = vm.doctor.fulldoc.firstname;}
            if (vm.doctor.fulldoc.lastname) {payload.recipient.lastname = vm.doctor.fulldoc.lastname;}
            if (vm.doctor.fulldoc.institute) {payload.recipient.institute = vm.doctor.fulldoc.institute;}
            if (vm.doctor.fulldoc.address) {payload.recipient.address = vm.doctor.fulldoc.address;}
            if (vm.doctor.fulldoc.city) {payload.recipient.city = vm.doctor.fulldoc.city;}
            if (vm.doctor.fulldoc.postcode) {payload.recipient.postcode = vm.doctor.fulldoc.postcode;}
          }
          else {
            if (vm.patient.titlefront) {payload.recipient.titlefront = vm.patient.titlefront;}
            if (vm.patient.titleback) {payload.recipient.titleback = vm.patient.titleback;}
            if (vm.patient.firstname) {payload.recipient.firstname = vm.patient.firstname;}
            if (vm.patient.lastname) {payload.recipient.lastname = vm.patient.lastname;}
            if (vm.patient.address) {payload.recipient.address = vm.patient.address;}
            if (vm.patient.city) {payload.recipient.city = vm.patient.city;}
            if (vm.patient.postcode) {payload.recipient.postcode = vm.patient.postcode;}
          }
  
          vm.newtext.xhtml = '<xhtml>' + vm.newtext.xhtml + '</xhtml>';
          payload.freeform = vm.newtext;
              
          request = $http({
            url: '/routes/pdfprinter/genpatientprint',
            method: 'POST',
            data: {
              patientid: vm.patientid,
              doctype: 'freeform',
              payload: payload
            }
          });
    
          request.success(function () {
            vm.printed = true;
            $timeout($state.go('home'), 5000);
          });
          request.error(function () {
            vm.printed = false;
          });
    
        }
        
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
