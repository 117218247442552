(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name helpers.sv.directive:svEdit
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="helpers.sv">
       <file name="index.html">
        <sv-edit></sv-edit>
       </file>
     </example>
   *
   */
  angular
    .module('helpers.sv')
    .directive('svEdit', svEdit);

  function svEdit() {
    return {
      restrict: 'E',
      scope: {
        sv: '=',
        dialog: '='
      },
      templateUrl: 'helpers/sv/sv-edit-directive.tpl.html',
      replace: false,
      controllerAs: 'sveCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'svEdit';
        vm.save = save;
        vm.close = close;

        function save() {
          vm.sv.$save();
        }

        function close() {
          vm.dialog.close();
        }

      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
