<!-- This is a very important hack: DO NOT REMOVE this totally pointless invisible bindings -->
<!-- Removal of these bindings will cause exam and consult close detection to fail -->
<span style="visibility: hidden">Consult Status patient: <span ng-bind='consCtrl.patient.inconsult'></span> </span>
<span style="visibility: hidden">Consult Status openConsult: <span ng-bind='consCtrl.patient.openConsult.open'></span> </span>
<span style="visibility: hidden">Exam Status patient: <span ng-bind='consCtrl.patient.inexam'></span> </span>
<span style="visibility: hidden">Exam Status openExam: <span ng-bind='consCtrl.patient.openExam.open'></span> </span>
<span style="visibility: hidden">openExam type: <span ng-bind='consCtrl.patient.openExam.template.displayname'></span> </span>
<span style="visibility: hidden">Consultstate: <span ng-bind='consCtrl.patient.consultstate'></span> </span>
<span style="visibility: hidden">Checkoutstate: <span ng-bind='consCtrl.patient.checkoutstate'></span> </span>


<!-- not in consult -->
<div ng-hide="consCtrl.patient.inconsult == true && consCtrl.patient.openConsult.open === true">
  <div class="alert alert-info">
    <h4>Die Konsultation wurde von anderer Stelle beendet</h4>
    <span class="btn btn-primary"
          ng-click="consCtrl.$state.go('patientrecord.history')">Zur Patienten-Historie wechseln</span>
  </div>
</div>

<!-- in consult, already in checkout-->
<div
    ng-show="consCtrl.patient.inconsult == true && consCtrl.patient.openConsult.open === true && consCtrl.patient.incheckout === true">
  <div class="alert alert-info">
    <h4>Diese Konsultation wird gerade abgeschlossen</h4>
    <span class="btn btn-primary" ng-click="consCtrl.switchToExit()">
      Zum Konsultationsabschluß wechseln
    </span>
  </div>


</div>

<!-- In consult, not already in checkout -->
<div
    ng-show="consCtrl.patient.inconsult == true && consCtrl.patient.openConsult.open === true && consCtrl.patient.incheckout !== true">
  <!-- Current Consult data entry GUI -->
  <div class="row">
    <div class="col-xs-12 col-sm-4">
      <h3>Aktuelle Konsultation </h3>
    </div>
    <div class="col-xs-12 col-sm-8" style="text-align: right">
      <h3>
        <!-- Get media -->
        <div class="spacer-top">
          <imagecapture filehandle="consCtrl.newimage" captureid="cconsultphoto"></imagecapture>
          <fileupload filehandle="consCtrl.newfile"></fileupload>
          <button class="btn btn-primary" uib-tooltip="Überweisung anlegen"
                  ng-click="consCtrl.addReferralModal(consCtrl.patient)">
            <i class="fa fa-plus"></i> Überweisung
          </button>
          <button class="btn btn-primary" uib-tooltip="Hilfsmitel verordnen"
                  ng-click="consCtrl.addAideModal(consCtrl.patient)">
            <i class="fa fa-plus"></i> Hilfsmittel
          </button>
        </div>
      </h3>
    </div>
  </div>
  
  
  <form>
    <!-- Consult Findings data upper row (Symptome, Therapie) -->
    <div class="row">
      <!-- Symptome -->
      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <div class="form-group">
            <label for="consultsymptoms"><h4>Symptome <span class="btn btn-sm">&nbsp;</span></h4></label>
            <textarea class="form-control" ng-model="consCtrl.patient.openConsult.symptoms"
                      rows="7"
                      ng-change="consCtrl.patient.openConsult.$save()"
                      ng-model-options="{ updateOn: 'default blur', debounce: { default: 20000, blur: 0 } }"
                      id="consultsymptoms"></textarea>
          </div>
        </div>
      </div>
      <!-- Therapie -->
      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <label for="findingstherapy">
            <h4>Therapieempfehlung
              <examtherapies consult="consCtrl.patient.openConsult"></examtherapies>
              <span class="btn btn-sm">&nbsp;</span>
            </h4>
          </label>
          <textarea type="text" ng-model="consCtrl.patient.openConsult.therapy" class="form-control"
                    rows="7"
                    ng-change="consCtrl.patient.openConsult.$save()"
                    ng-model-options="{ updateOn: 'default blur', debounce: { default: 20000, blur: 0 } }"
                    id="findingstherapy"></textarea>
        </div>
      </div>
    </div>
    
    <!-- Consult Findings data lower row (Anmerkungen, Notizen) -->
    <div class="row">
      <!-- Befund -->
      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <label for="findingsfindings"
                 uib-tooltip="Ergänzung zu Detailbefunden der einzelnen Untersuchungen">
            <h4>Gesamtbefund
              <examsummaries consult="consCtrl.patient.openConsult"></examsummaries>
              <examcomments consult="consCtrl.patient.openConsult"></examcomments>
              <span class="btn btn-sm">&nbsp;</span>
            </h4>
          </label>
          <textarea type="text" ng-model="consCtrl.patient.openConsult.findings" class="form-control"
                    rows="7"
                    ng-change="consCtrl.patient.openConsult.$save()"
                    ng-model-options="{ updateOn: 'default blur', debounce: { default: 20000, blur: 0 } }"
                    id="findingsfindings"></textarea>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6">
        <!-- Patientenotizen -->
        <div class="form-group">
          <label for="consultnotes"
                 uib-tooltip="Diese Patientennotizen werden nicht in Befunde oder andere Dokumente übernommen.">
            <h4>Permanente Notizen zum Patienten (privat) <span class="btn btn-sm">&nbsp;</span></h4></label>
          <textarea class="form-control" ng-model="consCtrl.patient.notes"
                    rows="7"
                    ng-change="consCtrl.patient.$save()"
                    ng-model-options="{ updateOn: 'default blur', debounce: { default: 20000, blur: 0 } }"
                    id="consultnotes"></textarea>
        </div>
      </div>
    </div>
    
    <!-- show images -->
    <div class="row spacer-top" ng-show="consCtrl.patient.openConsult.images">
      <h4 class="col-xs-12">Bilder &amp; Videos</h4>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
           ng-repeat="img in consCtrl.patient.openConsult.images | filter: {mimetype: 'image/jpeg'} |  orderBy: '_id'">
        
        <img class="img-responsive" src="/routes/files/filereader/{{img.fileid}}"
             uib-tooltip="{{img.label}}"
             ng-click="consCtrl.editImage(img)">
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
           ng-repeat="video in consCtrl.patient.openConsult.images | filter: {mimetype: 'video/quicktime'} |  orderBy: '_id'">
        
        <video controls class="img-responsive" ng-src="{{video.fileid | trustUrl}}"></video>
      </div>
    </div>
    
    <!-- show files -->
    <div class="row spacer-top" ng-show="consCtrl.patient.openConsult.files">
      <div class="col-xs-12">
        <h4>Dateien</h4>
        <div ng-repeat="file in consCtrl.patient.openConsult.files">
          <div class="col col-xs-12 col-md-3"><a href="/routes/files/filereader/{{file.fileid}}" class="btn btn-primary"
                                                 uib-tooltip="Anzeige/download">
            <i class="fa fa-file-pdf-o" ng-show="file.mimetype === 'application/pdf'"></i>
            <i class="fa fa-file-o" ng-hide="file.mimetype === 'application/pdf'"></i>&nbsp;{{file.mimetype}}</a>
          </div>
          <div class="col col-xs-12 col-md-9">
            <input type="text" ng-model="file.label" class="form-control"
                   ng-change="consCtrl.patient.openConsult.$save()"
                   ng-model-options="{ updateOn: 'default blur', debounce: { default: 20000, blur: 0 } }"
                   placeholder="Beschreibung des Inhalts">
          </div>
        </div>
      </div>
    </div>
    
    <!-- Untersuchungsstarter (keine offene Untersuchung) -->
    <div ng-hide="consCtrl.patient.inexam === true">
      <exam-starter patient="consCtrl.patient"></exam-starter>
    </div>
    
    <!-- Laufende Untersuchung -->
    <div ng-show="consCtrl.patient.inexam === true">
      <div class="alert alert-info">
        <h4>Laufende Untersuchung!</h4>
        <h4 ng-show="consCtrl.swteerror">Inkonsistenter Datenstand - Versuche Fehler zu beheben
          <i class="fa fa-spin fa-spinner"></i></h4>
        <span class="btn btn-danger"
              uib-tooltip="Achtung! Gefahr! Nicht gleichzeitig auf mehreren Rechnern Daten eingeben!"
              ng-click="consCtrl.switchToOpenExam()">
           <i class="fa fa-spin fa-spinner" ng-show="consCtrl.swtespin"></i>
          &nbsp;Zur laufenden Untersuchung wechseln: {{consCtrl.patient.openExam.template.displayname}}
        </span>
      </div>
    </div>
    
    
    <!-- Buttons zum weiterschalten-->
    <div>
      
      <!-- Rechnungspositionen -->
      <button class="btn btn-info spacer-top" type="button"
              ng-hide="consCtrl.patient.incheckout === true"
              ng-click="consCtrl.$state.go('patientrecord.billing')">
        Weiter zu den Rechnungspositionen
      </button>
      <!-- Drucken & Abschluss -->
      <button class="btn btn-info spacer-top" type="button"
              ng-hide="consCtrl.patient.incheckout === true"
              ng-click="consCtrl.$state.go('patientrecord.checkout.ccdocs')">
        Weiter zu Drucken & Abschluss
      </button>
    
    </div>
  </form>
  
  <!-- currentConsult Examinations -->
  <div ng-show="consCtrl.patient.openConsult.examinations.length > 0" class="spacer-top">
    <!-- TODO openConsult.examinations do not contain valid restmod handles FIXME with separate query -->
    <current-exams-list exams="consCtrl.patient.openConsult.examinations"
                        patient="consCtrl.patient"></current-exams-list>
  </div>
  
  <div style="min-height: 40pt">&nbsp;</div>

</div>


