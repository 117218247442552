(function () {
  'use strict';

  /* @ngdoc object
   * @name docsaver
   * @description
   *
   */

  angular
    .module('docsaver', [
      'ngAria',
      'ui.router',
      'ui.bootstrap',
      'ngCookies',
      'ngTagsInput',
      'ui.bootstrap.typeahead',
      'restmod',
      'bootstrapLightbox',
      'cfp.hotkeys',
      'ngLodash',
      'todo',
      'user',
      'patient',
      'consult',
      'examtemplate',
      'examination',
      'pdfprinter',
      'service',
      'config',
      'document',
      'drug',
      'file',
      'share',
      'home',
      'printer',
      'labvalue',
      'chat',
      'testdata',
      'finance',
      'finance.invoice',
      'finance.cashbook',
      'finance.turnover',
      'finance.bmfexport',
      'finance.rksv',
      'finance.closing',
      'journal',
      'utils',
      'utils.fileuploader',
      'utils.filereader',
      'utils.listEditor',
      'login',
      'dms',
      'helpers',
      'reminder',
      'unicatalog',
      'utils.stacktrace',
      'doctype',
      'forms',
      'waitinglist',
      'tracker',
      'heartbeat',
      'rlog'
    ]);

  angular.module('docsaver')
    .filter('moment', function () {
      return function (dateString, format) {
        return moment(dateString).locale('de-at').format(format);
      };
    })
    .filter('addweeks', function () {
      return function (weeks, format) {
        return moment().add(weeks * 7, 'days').locale('de-at').format(format);
      };
    })
    .filter('javadate', function () {
      return function (javadate, format) {
        return moment(javadate, 'YYYYMMDD-HHmmss').locale('de-at').format(format);
      };
    })
    .filter('trustUrl', ['$sce', function ($sce) {
      return function (recordingUrl) {
        return $sce.trustAsResourceUrl('/routes/files/filereader/' + recordingUrl);
      };
    }])
    // this interceptor will ad a custom header containing the tabid to every request
    .factory('tabidInterceptor', ['$log', '$window', function ($log, $window) {
      return {
        request: function (config) {
          var uuid;
          // helper function to create uuid
          function guid() {
            function s4() {
              return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
            }

            return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
              s4() + '-' + s4() + s4() + s4();
          }

          if ($window.sessionStorage.getItem('sprtabid')) {
            uuid = $window.sessionStorage.getItem('sprtabid');
          }
          else {
            uuid = guid();
            $log.debug('Tab UUID created: ', uuid);
            $window.sessionStorage.setItem('sprtabid', uuid);
          }
          config.headers['X-tabid'] = uuid;
          return config;
        }
      };
    }])
    .config(["$httpProvider", function ($httpProvider) {
      $httpProvider.interceptors.push('tabidInterceptor');
    }])
    /*
     .run(['Config', '$rootScope', '$log', function (Config, $rootScope, $log) {
     $log.debug('Config initialised');
     Config.init();
     }])*/
    /*
     .run(['Labvalue', '$rootScope', '$log', function (Labvalue, $rootScope, $log) {
     $log.debug('Labvalues initialised');
     Labvalue.init();
     }]) */
    /*
     // this ould be nice, but it inferes with hotkey triggered state changes
     .run(['$rootScope', '$uibModalStack', '$log',
     function ($rootScope, $uibModalStack, $log) {
     // close the opened modal on location change.
     var lcshandle = $rootScope.$on('$locationChangeSuccess', function ($event) {
     var openedModal = $uibModalStack.getTop();
     $log.debug('openedModal: ', openedModal);
     $log.debug('Close modal entered');
     if (openedModal) {
     if (!$event.preventDefault) {
     $event.preventDefault();
     }
     $uibModalStack.dismiss(openedModal.key);
     }
     });
     }]) */
    // restmod cookie handling? todo
    .run(['$cookies', '$rootScope', '$log', function ($cookies, $rootScope, $log) {
      $rootScope.username = $cookies.get('username');
      $log.debug('cookies initialised: ', $cookies.get('username'));
    }])
    .config(['restmodProvider', '$httpProvider', function (restmodProvider, $httpProvider) {
      restmodProvider.rebase({
        /* $config: {

         },*/
        $hooks: {
          'before-request': function () {
            $httpProvider.defaults.withCredentials = true;
          }
        }
      });
    }])
    // login: HTTP 401 interceptor
    .factory('AuthInterceptor', ['$injector', '$location', '$q', '$log', function ($injector, $location, $q, $log) {
      return {
        responseError: function (rejection) {
          var $state;
          console.log('rejection: ', rejection);
          if (rejection.status === 401 && rejection.config.url !== '/login') {
            $state = $injector.get('$state');
            // This is the interesting bit: (redirect to initially entered url)
            $log.debug('app-module location url: ', $location.url());
            if ($location.url() !== '/login') {
              $state.go('login', {url: $location.url()});
            }

          }
          return $q.reject(rejection);
        }
      };
    }])
    .config(['$httpProvider', function ($httpProvider) {
      $httpProvider.interceptors.push('AuthInterceptor');
    }])
    // make sure that hotkeys ngroute support does not interfere with ui.router
    .config(['hotkeysProvider', function (hotkeysProvider) {
      hotkeysProvider.useNgRoute = false;
    }])
    .run(['hotkeys', '$state', '$log', function (hotkeys, $state, $log) {
      $log.debug('app hotkeys entered');
      hotkeys
        .add({
          combo: 'alt+n',
          description: 'Neuer Patient',
          callback: function () {
            $state.go('newpatient');
          }
        });
      hotkeys
        .add({
          combo: 'alt+f',
          description: 'Patient Finden',
          callback: function () {
            $state.go('patientsearch');
          }
        })
      ;
    }])
    /* .config(['$provide', function ($provide) {
     // $provide provider is used to register the components in angular internally.

     // use decorator to customise the behaviour of a service.
     $provide.decorator('$exceptionHandler', ['$delegate', '$window', 'Stacktrace',
     function ($delegate, $window, Stacktrace) {
     var stacktrace;
     var clientSideErrorInfo;

     // exception: exception associated with the error
     // cause: optional information about the context in which the error was thrown.

     return function (exception, cause) {

     // $delegate: provides the original service to the method which is used to call the base implementation
     // of $exceptionHandler service which internally delegates to $log.error.
     $delegate(exception, cause);

     stacktrace = Stacktrace.print($window, exception);

     clientSideErrorInfo = {
     cause: cause || '', // the cause of the issue
     message: exception.message, // the message in the exception
     url: $window.location.href, // the location in the browser's address bar when error occurred
     stacktrace: stacktrace.join('\n') // join array items to populate a string
     };

     console.log(clientSideErrorInfo.stacktrace);

     // the angular $http service cannot be used in the $log
     // decorator because it will cause a circular dependecy.
     // to overcome this  a direct ajax call should be made.

     $.ajax({
     type: 'POST',
     url: '/routes/logger', // this is the server end-point where you can log this error
     contentType: 'application/json; charset=UTF-8',
     data: JSON.stringify(clientSideErrorInfo)
     });
     };
     }]);
     }]) */
    // get previous state tagged onto new state
    .run(['$rootScope', '$state', function ($rootScope, $state) {
      $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        $state.previousState = fromState;
        $state.previousParams = fromParams;
      });
    }])
    .directive('ngReallyClick', [function () {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          element.bind('click', function () {
            var message = attrs.ngReallyMessage;
            if (message && confirm(message)) {
              scope.$apply(attrs.ngReallyClick);
            }
          });
        }
      };
    }])
    .directive('autofocus', function () {
      return {
        restrict: 'A',
        link: function (scope, element) {
          element[0].focus();
        }
      };
    })
    .directive('showfocus', ["$timeout", function ($timeout) {
      return function (scope, element, attrs) {
        scope.$watch(attrs.showFocus,
          function (newValue) {
            $timeout(function () {
              newValue && element[0].focus();
            });
          }, true);
      };
    }])
    .directive('validateSVNR', function () {
      return {
        require: 'ngModel',
        link: function (scope, element, attr, mCtrl) {
          function validateSVNR(svnr, element) {
            var nr = svnr.split('');
            var checksum = (5 * parseInt(nr[4], 10) +
              8 * parseInt(nr[5], 10) +
              4 * parseInt(nr[6], 10) +
              2 * parseInt(nr[7], 10) +
              parseInt(nr[8], 10) +
              6 * parseInt(nr[9], 10) +
              3 * parseInt(nr[0], 10) +
              7 * parseInt(nr[1], 10) +
              9 * parseInt(nr[2], 10)) % 11;

            var result = (checksum === parseInt(nr[3], 10));

            console.log(svnr, ' valid: ', result);

            if (result) {
              mCtrl.$setValidity('', true);
            } else {
              mCtrl.$setValidity('', false);
            }

            return svnr;
          }

          mCtrl.$parsers.push(validateSVNR);
        }
      };
    })
  ;

// klammerfriedhof
}());




angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('utils/list-editor/list-editor-directive.tpl.html',
    '\n' +
    '<div ng-repeat="a in listCtrl.ar" ng-show="listCtrl.ar.length > 0">\n' +
    '  <div class="input-group">\n' +
    '    <input type="text" class="form-control" ng-model="a" placeholder="Bestehendes ersetzen...">\n' +
    '      <span class="input-group-btn">\n' +
    '        <button class="btn btn-danger" type="button" ng-click="listCtrl.splice($index)"><i class="fa fa-remove"></i></button>\n' +
    '      </span>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<ng-form name="listCtrl.formid">\n' +
    '<div class="input-group">\n' +
    '  <input type="text" class="form-control" ng-model="listCtrl.newvalue" placeholder="Neuer Wert..." ng-keyup="$event.keyCode == 13 ? listCtrl.add() : null">\n' +
    '      <span class="input-group-btn">\n' +
    '        <button class="btn btn-success" type="button" ng-click="listCtrl.add()"><i class="fa fa-plus"></i></button>\n' +
    '      </span>\n' +
    '</div>\n' +
    '</ng-form>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('utils/keywords/sortable-keywords-directive.tpl.html',
    '<!-- <label for="newkeyword">{{vmKw.text}}</label> -->\n' +
    '<span class="input-group" style="display: inline" as-sortable="vmKw.sortableOptions" ng-model="vmKw.keywords" id="sortable-container">\n' +
    '    <span ng-repeat="kw in vmKw.keywords" as-sortable-item style="display: inline;">\n' +
    '      <span uib-dropdown>\n' +
    '        <button type="button"  as-sortable-item-handle\n' +
    '                class="btn btn-{{kw.col}}" uib-dropdown-toggle\n' +
    '                data-toggle="uib-dropdown"\n' +
    '                aria-haspopup="true" aria-expanded="false">\n' +
    '          {{kw.key}} <span class="caret"></span>\n' +
    '        </button>\n' +
    '        <ul uib-dropdown-menu>\n' +
    '          <li>\n' +
    '            <a ng-click="vmKw.updateColor(kw.key,\'success\')">\n' +
    '              <span class="label label-success">Grün (st.p.)</span>\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li>\n' +
    '            <a ng-click="vmKw.updateColor(kw.key,\'warning\')">\n' +
    '              <span class="label label-warning">Gelb (Warnung)</span>\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li>\n' +
    '            <a ng-click="vmKw.updateColor(kw.key,\'danger\')">\n' +
    '              <span class="label label-danger">Rot (Gefahr)</span>\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li>\n' +
    '            <a ng-click="vmKw.updateColor(kw.key,\'info\')">\n' +
    '              <span class="label label-info">Türkis (Aktuell)</span>\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li>\n' +
    '            <a ng-click="vmKw.updateColor(kw.key,\'primary\')">\n' +
    '              <span class="label label-primary">Blau (Neu)</span>\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li role="separator" class="divider" uib-dropdown></li>\n' +
    '          <li uib-dropdown ng-show="kw.created"><a><span class="label label-default">Erstellt: {{kw.created}}</span></a></li>\n' +
    '          <li uib-dropdown ng-show="kw.updated"><a><span class="label label-default">Geändert: {{kw.updated}}</span></a></li>\n' +
    '          <li ng-hide="kw.col === \'danger\'" role="separator" class="divider"  uib-dropdown></li>\n' +
    '          <li ng-hide="kw.col === \'danger\'">\n' +
    '            <a ng-really-message="Stichwort wirklich löschen?"\n' +
    '               ng-really-click="vmKw.removeKeyword(kw.key)">\n' +
    '              <span class="label label-danger">X</span> Löschen\n' +
    '            </a>\n' +
    '        </ul>\n' +
    '      </span>\n' +
    '    </span><!-- ng-repeat -->\n' +
    '  </span>\n' +
    '\n' +
    '<form class="spacer-top">\n' +
    '  <div class="input-group spacer-top">\n' +
    '    <input class="form-control" ng-model="vmKw.newkeyword" id="newkeyword"\n' +
    '           ng-blur="vmKw.addKeyword()"\n' +
    '           placeholder="{{vmKw.text}}"/>\n' +
    '    <span class="input-group-btn">\n' +
    '          <button class="btn btn-success" type="submit" ng-click="vmKw.addKeyword()">+</button>\n' +
    '        </span>\n' +
    '  </div>\n' +
    '  </div>\n' +
    '</form>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('utils/keywords/selected-keywords-directive.tpl.html',
    '<span class="input-group" style="display: inline">\n' +
    '    <span ng-repeat="kw in skwCtrl.keywords | orderBy:[\'nr\', \'-timestamp\']">\n' +
    '      <span uib-dropdown>\n' +
    '        <button type="button"\n' +
    '                class="btn btn-{{kw.col}}" uib-dropdown-toggle\n' +
    '                data-toggle="uib-dropdown" ng-show="(skwCtrl.selected.indexOf(kw.col) > -1 )"\n' +
    '                aria-haspopup="true" aria-expanded="false">\n' +
    '          {{kw.key}} <span class="caret"></span>\n' +
    '        </button>\n' +
    '        <ul uib-dropdown-menu>\n' +
    '          <li>\n' +
    '            <a ng-click="skwCtrl.updateColor(kw.key,\'success\')">\n' +
    '              <span class="label label-success">Grün (Erfolg)</span>\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li>\n' +
    '            <a ng-click="skwCtrl.updateColor(kw.key,\'warning\')">\n' +
    '              <span class="label label-warning">Gelb (Warnung)</span>\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li>\n' +
    '            <a ng-click="skwCtrl.updateColor(kw.key,\'danger\')">\n' +
    '              <span class="label label-danger">Rot (Gefahr)</span>\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li>\n' +
    '            <a ng-click="skwCtrl.updateColor(kw.key,\'info\')">\n' +
    '              <span class="label label-info">Türkis (Hinweis)</span>\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li>\n' +
    '            <a ng-click="skwCtrl.updateColor(kw.key,\'primary\')">\n' +
    '              <span class="label label-primary">Blau (Aktuell)</span>\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li role="separator" class="divider" uib-dropdown></li>\n' +
    '          <li uib-dropdown ng-show="kw.created"><a><span class="label label-primary">Erstellt: {{kw.created}}</span></a></li>\n' +
    '          <li uib-dropdown ng-show="kw.updated"><a><span class="label label-primary">Geändert: {{kw.updated}}</span></a></li>\n' +
    '          <li ng-hide="kw.col === \'danger\'" role="separator" class="divider"  uib-dropdown></li>\n' +
    '          <li>\n' +
    '            <a ng-hide="kw.col === \'danger\'" ng-really-message="Wirklich löschen?" ng-really-click="skwCtrl.removeKeyword(kw.key)">\n' +
    '              <span class="label label-danger">X</span> Löschen\n' +
    '            </a>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </span>\n' +
    '    </span><!-- ng-repeat -->\n' +
    '  </span>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('utils/keywords/keywords-widget-directive.tpl.html',
    '<!-- <label for="newkeyword">{{vmKw.text}}</label> -->\n' +
    '\n' +
    '  <span class="input-group" style="display: inline">\n' +
    '    <span ng-repeat="kw in vmKw.keywords | orderBy: [vmKw.orderkey, \'-timestamp\'] ">\n' +
    '      <span uib-dropdown>\n' +
    '        <button type="button"\n' +
    '                class="btn btn-{{kw.col || \'primary\'}}" uib-dropdown-toggle\n' +
    '                data-toggle="uib-dropdown"\n' +
    '                aria-haspopup="true" aria-expanded="false">\n' +
    '          {{kw.key}} <span class="caret"></span>\n' +
    '        </button>\n' +
    '        <ul uib-dropdown-menu>\n' +
    '          <li>\n' +
    '            <a ng-click="vmKw.updateColor(kw.key,\'success\')">\n' +
    '              <span class="label label-success">Grün (st.p.)</span>\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li>\n' +
    '            <a ng-click="vmKw.updateColor(kw.key,\'warning\')">\n' +
    '              <span class="label label-warning">Gelb (Warnung)</span>\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li>\n' +
    '            <a ng-click="vmKw.updateColor(kw.key,\'danger\')">\n' +
    '              <span class="label label-danger">Rot (Gefahr)</span>\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li>\n' +
    '            <a ng-click="vmKw.updateColor(kw.key,\'info\')">\n' +
    '              <span class="label label-info">Türkis (Aktuell)</span>\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li>\n' +
    '            <a ng-click="vmKw.updateColor(kw.key,\'primary\')">\n' +
    '              <span class="label label-primary">Blau (Neu)</span>\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li role="separator" class="divider" uib-dropdown></li>\n' +
    '          <li uib-dropdown ng-show="kw.created"><a><span class="label label-default">Erstellt: {{kw.created}}</span></a></li>\n' +
    '          <li uib-dropdown ng-show="kw.updated"><a><span class="label label-default">Geändert: {{kw.updated}}</span></a></li>\n' +
    '          <li ng-hide="kw.col === \'danger\'" role="separator" class="divider" uib-dropdown></li>\n' +
    '          <li ng-hide="kw.col === \'danger\'">\n' +
    '            <a ng-really-message="Stichwort wirklich löschen?"\n' +
    '               ng-really-click="vmKw.removeKeyword(kw.key)">\n' +
    '              <span class="label label-danger">X</span> Löschen\n' +
    '            </a>\n' +
    '        </ul>\n' +
    '      </span>\n' +
    '    </span><!-- ng-repeat -->\n' +
    '  </span>\n' +
    '  \n' +
    '  <form class="spacer-top">\n' +
    '    <div class="input-group spacer-top">\n' +
    '      <input class="form-control" ng-model="vmKw.newkeyword" id="newkeyword"\n' +
    '             ng-blur="vmKw.addKeyword()"\n' +
    '             placeholder="{{vmKw.text}}"/>\n' +
    '      <span class="input-group-btn">\n' +
    '          <button class="btn btn-success" type="submit" ng-click="vmKw.addKeyword()">+</button>\n' +
    '      </span>\n' +
    '    </div>\n' +
    '  </form>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('utils/fileuploader/imagecapture-directive.tpl.html',
    '<input type="file" name="imgc{{icCtrl.captureid}}" id="imgc{{icCtrl.captureid}}" class="inputfile"  accept="image/*;capture=camera" />\n' +
    '<label class="btn btn-primary" for="imgc{{icCtrl.captureid}}" uib-tooltip="Kamera oder Photo upload"><i class="fa fa-camera"></i> {{icCtrl.label || \'\'}}</label>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('utils/fileuploader/fileuploader.tpl.html',
    '<h2>fileuploader test</h2>\n' +
    '<fileupload filehandle=""></fileupload>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('utils/fileuploader/fileupload-directive.tpl.html',
    '<input type="file" name="{{fuCtrl.uuid}}" id="{{fuCtrl.uuid}}" class="inputfile" />\n' +
    '<label class="btn btn-{{fuCtrl.color || \'primary\'}}" for="{{fuCtrl.uuid}}" uib-tooltip="Datei upload"><i class="fa fa-upload" ></i> {{fuCtrl.label || \'Datei hinaufladen\'}} ...</label>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('utils/filereader/filereader.tpl.html',
    '<h2>filereader</h2>\n' +
    '<p>{{filereader.ctrlName}}</p>\n' +
    '\n' +
    '\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('utils/filereader/filereader-directive.tpl.html',
    '\n' +
    '<form>\n' +
    '  <input type="file" ng-file-select="onFileSelect($files)"/>\n' +
    '\n' +
    '  <button type="submit" ng-click="frCtrl.upload()" class="btn btn-success">Upload</button>\n' +
    '</form>\n' +
    '\n' +
    '\n' +
    '<b>Vorschau:</b><br />\n' +
    '<i ng-hide="frCtrl.file.fileSrc">Keine Datei ausgewählt ...</i>\n' +
    '<div ng-show="frCtrl.file.img == true">\n' +
    '  <img ng-src="{{frCtrl.file.fileSrc}}" height="200px"/>\n' +
    '</div>\n' +
    '<div ng-show="frCtrl.file.text == true">\n' +
    '  <div ng-repeat="line in frCtrl.file.textpreview" class="pre">\n' +
    '    {{line}}\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div ng-show="frCtrl.file.nopreview == true">\n' +
    '  Unbekannter Dateityp, keine Vorschau möglich\n' +
    '</div>\n' +
    '\n' +
    '<!-- doesn\'t work TODO\n' +
    '<b>Fortschritt:</b>\n' +
    '<uib-progress value="{{frCtrl.file.progress}}" class="progress-striped active" type="primary"></uib-progress>\n' +
    '</div>\n' +
    '-->\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('helpers/zipcode/zipcode-directive.tpl.html',
    '<div class="form-group" style="padding: 0px; margin-bottom: 0px">\n' +
    '    <span class="col-md-4" style="padding: 0px; margin-top: 0px"><label for="postcode">PLZ</label>\n' +
    '        <span class="input-group">\n' +
    '            <input type="text" ng-model="zcCtrl.zip" ng-show="zcCtrl.zip" tabindex="{{zcCtrl.ti}}"\n' +
    '                    class="form-control" id="postcode" placeholder="PLZ">\n' +
    '            <input type="text" class="form-control" ng-hide="zcCtrl.zip" tabindex="{{zcCtrl.ti}}"\n' +
    '                   autocomplete="off"\n' +
    '                   id="postcode"\n' +
    '                   placeholder="PLZ"\n' +
    '                   ng-model="zcCtrl.asyncSelected"\n' +
    '                   ng-blur="zcCtrl.foreignzip()"\n' +
    '                   typeahead-on-select="zcCtrl.getzipcity($item, $model, $label)"\n' +
    '                   uib-typeahead="zip as zip.zip for zip in zcCtrl.getNames($viewValue)"\n' +
    '                   typeahead-loading="loadingNames"\n' +
    '                   typeahead-template-url="customTemplate.html"\n' +
    '                   typeahead-min-length="4"\n' +
    '                   typeahead-no-results="noResults">\n' +
    '             <span class="input-group-btn">\n' +
    '                  <button class="btn btn-danger" tabindex="-1"\n' +
    '                          ng-click="zcCtrl.zip = \'\'; zcCtrl.city = \'\'; zcCtrl.asyncSelected = \'\'">\n' +
    '                      <i class="fa fa-remove"></i>\n' +
    '                  </button>\n' +
    '             </span>\n' +
    '        </span>\n' +
    '    </span><!-- css sucks, do not remove this comment\n' +
    ' --><span class="col-md-8" style="padding: 0px">\n' +
    '    <label for="postcode">Ort</label>\n' +
    '        <input type="text" ng-model="zcCtrl.city" autocomplete="off" tabindex="{{zcCtrl.ti2}}"\n' +
    '               class="form-control" id="city" placeholder="Ort">\n' +
    '    </span>\n' +
    '</div>\n' +
    '<div ng-show="noResults">\n' +
    '    <i class="glyphicon glyphicon-remove"></i> Keine Orte gefunden\n' +
    '</div>\n' +
    '<i ng-show="loadingNames" class="glyphicon glyphicon-refresh"></i>\n' +
    '\n' +
    '<script type="text/ng-template" id="customTemplate.html">\n' +
    '    <a href\n' +
    '       tabindex="-1"\n' +
    '       ng-bind-html="match.model.text | uibTypeaheadHighlight:query"\n' +
    '       ng-attr-title="{{match.model.text}}"></a>\n' +
    '</script>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('helpers/sv/sv-search-directive.tpl.html',
    '<div class="form-group">\n' +
    '  <div class="input-group">\n' +
    '        <input type="text" class="form-control" tabindex="{{svsCtrl.tabi}}"\n' +
    '           ng-class="{\'dirtyfield\': svsCtrl.asyncSelected.length > 0}"\n' +
    '           autofocus\n' +
    '           placeholder="Kürzel"\n' +
    '           ng-model="svsCtrl.asyncSelected"\n' +
    '           typeahead-on-select="$parent.$dismiss();svsCtrl.showsv($item, $model, $label)"\n' +
    '           uib-typeahead="sv as sv.text for sv in svsCtrl.getSvs($viewValue)"\n' +
    '           typeahead-loading="loadingNames"\n' +
    '           typeahead-no-results="noResults">\n' +
    '    <span class="input-group-btn">\n' +
    '      <button type="submit"\n' +
    '              ng-class="{\'btn-warning\': noResults, \'btn-info\': !noResults}"\n' +
    '              class="btn" tabindex="-1"><i class="fa fa-search"></i></button>\n' +
    '    </span>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '<i ng-show="loadingNames" class="glyphicon glyphicon-refresh"></i>\n' +
    '<div ng-show="noResults">\n' +
    '  <i class="glyphicon glyphicon-remove"></i> Keine SV gefunden\n' +
    '  <span class="btn btn-success" ng-click="svsCtrl.addSv()">Neue Sozialversicherung eingeben</span>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('helpers/sv/sv-edit-directive.tpl.html',
    '<form class="form-vertical" id="editsvform">\n' +
    '  <div class="row">\n' +
    '    <div class="form-group col-md-3">\n' +
    '      <label for="short">Kürzel</label>\n' +
    '      <input type="text" ng-model="sveCtrl.sv.short" class="form-control" id="short" tabindex="1"\n' +
    '             placeholder="Kürzel" ng-blur="sveCtrl.save()">\n' +
    '    </div>\n' +
    '    <div class="form-group col-md-9">\n' +
    '      <label for="name">Name (vollständiger)</label>\n' +
    '      <input type="text" ng-model="sveCtrl.sv.name" class="form-control" id="name"  tabindex="2"\n' +
    '             placeholder="Vorname" ng-blur="sveCtrl.save()">\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row">\n' +
    '    <div class="form-group col-md-3">\n' +
    '      <label for="phone1">Telefon</label>\n' +
    '      <input type="text" ng-model="sveCtrl.sv.phone" class="form-control" id="phone1"  tabindex="3"\n' +
    '             placeholder="Telefon" ng-blur="sveCtrl.save()">\n' +
    '    </div>\n' +
    '    <div class="form-group col-md-3">\n' +
    '      <label for="fax">Fax</label>\n' +
    '      <input type="text" ng-model="sveCtrl.sv.fax" class="form-control" id="fax" tabindex="4"\n' +
    '             placeholder="Fax" ng-blur="sveCtrl.save()">\n' +
    '    </div>\n' +
    '    <div class="form-group col-md-6">\n' +
    '      <label for="email">E-Mail</label>\n' +
    '      <input type="text" ng-model="sveCtrl.sv.email" class="form-control" id="email"  tabindex="5"\n' +
    '             placeholder="E-Mail" ng-blur="sveCtrl.save()">\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  \n' +
    '  <div class=" row">\n' +
    '    <div class="form-group col-md-6">\n' +
    '      <label for="address">Adresse</label>\n' +
    '      <input type="text" ng-model="sveCtrl.sv.address" class="form-control" id="address"  tabindex="6"\n' +
    '             placeholder="Adresse" ng-blur="sveCtrl.save()">\n' +
    '    </div>\n' +
    '    <div class="form-group col-md-6">\n' +
    '      <zipcode zip="sveCtrl.sv.postcode" city="sveCtrl.sv.city" ti="7" ti2="8"></zipcode>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class=" row">\n' +
    '    <div class="form-group">\n' +
    '      <button class="btn btn-success" type="button" tabindex="9" ng-click="sveCtrl.save(); sveCtrl.close()">Speichern &amp; Schließen</button>\n' +
    '      <button class="btn btn-danger" type="button" tabindex="10" ng-click="sveCtrl.close()">Schließen</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</form>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('helpers/sv/sv-directive.tpl.html',
    '\n' +
    '\n' +
    '<div class="btn btn-success" ng-click="svCtrl.addSv()">Neue Sozialversicherung anlegen</div>\n' +
    '\n' +
    '<h3>Liste</h3>\n' +
    '<table class="table table-striped">\n' +
    '  <thead>\n' +
    '  <tr>\n' +
    '    <th>SV Kürzel</th>\n' +
    '    <th>Vollername</th>\n' +
    '    <th>Adresse</th>\n' +
    '    <th>PLZ</th>\n' +
    '    <th>Ort</th>\n' +
    '    <th>Ort</th>\n' +
    '  </tr>\n' +
    '  </thead>\n' +
    '  <tr ng-repeat="sv in svCtrl.svlist">\n' +
    '    <td>{{sv.short}}</td>\n' +
    '    <td>{{sv.name}}</td>\n' +
    '    <td>{{sv.address}}</td>\n' +
    '    <td>{{sv.zipcode}}</td>\n' +
    '    <td>{{sv.city}}</td>\n' +
    '    <td>\n' +
    '      <button class="btn btn-primary" ng-click="svCtrl.editSv(sv)">Editeren</button>\n' +
    '      <button class="btn btn-danger" ng-click="svCtrl.removeSv(sv)">Löschen</button>\n' +
    '    </td>\n' +
    '  </tr>\n' +
    '</table>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('helpers/doctor/doctor.tpl.html',
    '<div ui-view="doctormain" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">\n' +
    '<h2>Ärzteverzeichnis</h2>\n' +
    '\n' +
    '\n' +
    '    <!--<div><a class="btn btn-success" ui-sref="doctornew">Neuen Arzt anlegen</a></div>-->\n' +
    '    <h3>Ärztesuche</h3>\n' +
    '    <div><doctor-search></doctor-search></div>\n' +
    '\n' +
    '\n' +
    '    <doctors></doctors>\n' +
    '</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('helpers/doctor/doctor-search-directive.tpl.html',
    '<div class="form-group">\n' +
    '    <div class="input-group">\n' +
    '        <input type="text" class="form-control" tabindex="{{drsCtrl.tabi}}"\n' +
    '               ng-class="{\'dirtyfield\': drsCtrl.newdoc.text.length > 0}"\n' +
    '               placeholder="Nachname, Spezialisierung"\n' +
    '               ng-model="drsCtrl.newdoc.text"\n' +
    '               typeahead-on-select="$parent.$dismiss();drsCtrl.showdoctor($item, $model, $label)"\n' +
    '               uib-typeahead="doc as doc.text for doc in drsCtrl.getNames($viewValue)"\n' +
    '               typeahead-loading="loadingNames"\n' +
    '               typeahead-no-results="noResults">\n' +
    '    <span class="input-group-btn">\n' +
    '      <button type="submit"\n' +
    '              ng-class="{\'btn-warning\': noResults, \'btn-info\': !noResults}"\n' +
    '              class="btn" tabindex="-1"><i class="fa fa-search"></i></button>\n' +
    '    </span>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<i ng-show="loadingNames" class="glyphicon glyphicon-refresh"></i>\n' +
    '<div ng-show="noResults">\n' +
    '    <i class="glyphicon glyphicon-remove"></i> Keine Ärzte gefunden\n' +
    '    <span class="btn btn-success" ng-click="$parent.$dismiss();drsCtrl.addDoctor()">Neuen Arzt eingeben</span>\n' +
    '</div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('helpers/doctor/doctor-edit-directive.tpl.html',
    '<form class="form-vertical" id="newdoctorform">\n' +
    '    <div class="row">\n' +
    '        <div class="form-group col-md-2">\n' +
    '            <label for="titlefront">Titel</label>\n' +
    '            <input type="text" ng-model="dreCtrl.doctor.titlefront" class="form-control" id="titlefront"\n' +
    '                   placeholder="Dr." ng-blur="dreCtrl.save()">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-4">\n' +
    '            <label for="firstname">Vorname</label>\n' +
    '            <input type="text" ng-model="dreCtrl.doctor.firstname" class="form-control" id="firstname"\n' +
    '                   placeholder="Vorname" ng-blur="dreCtrl.save()">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-4">\n' +
    '            <label for="lastname">Nachname</label>\n' +
    '            <input type="text" ng-model="dreCtrl.doctor.lastname" class="form-control" id="lastname"\n' +
    '                   placeholder="Nachname" ng-blur="dreCtrl.save()">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-2">\n' +
    '            <label for="titleback">Titel</label>\n' +
    '            <input type="text" ng-model="dreCtrl.doctor.titleback" class="form-control" id="titleback"\n' +
    '                   placeholder="MSc" ng-blur="dreCtrl.save()">\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '        <div class="form-group col-md-12">\n' +
    '            <label for="institute">Institut</label>\n' +
    '            <input type="text" ng-model="dreCtrl.doctor.institute" class="form-control" id="institute"\n' +
    '                   placeholder="Nachname" ng-blur="dreCtrl.save()">\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '        <div class="form-group col-md-12">\n' +
    '            <label for="speciality">Fachgebiet</label>\n' +
    '            <select id="speciality" ng-model="dreCtrl.doctor.speciality" ng-blur="dreCtrl.save()">\n' +
    '                <option value="Allgemeinmedizin">Allgemeinmedizin</option>\n' +
    '                <option value="Anästhesiologie und Intensivmedizin">Anästhesiologie und Intensivmedizin</option>\n' +
    '                <option value="Anatomie">Anatomie</option>\n' +
    '                <option value="Arbeitsmedizin">Arbeitsmedizin</option>\n' +
    '                <option value="Augenheilkunde und Optometrie">Augenheilkunde und Optometrie</option>\n' +
    '                <option value="Blutgruppenserologie und Transfusionsmedizin">Blutgruppenserologie und Transfusionsmedizin</option>\n' +
    '                <option value="Chirurgie">Chirurgie</option>\n' +
    '                <option value="Frauenheilkunde und Geburtshilfe">Frauenheilkunde und Geburtshilfe</option>\n' +
    '                <option value="Gerichtsmedizin">Gerichtsmedizin</option>\n' +
    '                <option value="Hals-, Nasen- und Ohrenkrankheiten">Hals-, Nasen- und Ohrenkrankheiten</option>\n' +
    '                <option value="Haut- und Geschlechtskrankheiten">Haut- und Geschlechtskrankheiten</option>\n' +
    '                <option value="Herzchirurgie">Herzchirurgie</option>\n' +
    '                <option value="Histologie und Embryologie">Histologie und Embryologie</option>\n' +
    '                <option value="Hygiene und Mikrobiologie">Hygiene und Mikrobiologie</option>\n' +
    '                <option value="Immunologie">Immunologie</option>\n' +
    '                <option value="Innere Medizin">Innere Medizin</option>\n' +
    '                <option value="Kinder- und Jugendchirurgie">Kinder- und Jugendchirurgie</option>\n' +
    '                <option value="Kinder- und Jugendheilkunde">Kinder- und Jugendheilkunde</option>\n' +
    '                <option value="Kinder- und Jugendpsychiatrie">Kinder- und Jugendpsychiatrie</option>\n' +
    '                <option value="Lungenkrankheiten">Lungenkrankheiten</option>\n' +
    '                <option value="Medizinische Biologie">Medizinische Biologie</option>\n' +
    '                <option value="Medizinische Biophysik">Medizinische Biophysik</option>\n' +
    '                <option value="Medizinische Genetik">Medizinische Genetik</option>\n' +
    '                <option value="Medizinische und Chemische Labordiagnostik">Medizinische und Chemische Labordiagnostik</option>\n' +
    '                <option value="Medizinische Leistungsphysiologie">Medizinische Leistungsphysiologie</option>\n' +
    '                <option value="Mikrobiologisch-Serologische Labordiagnostik">Mikrobiologisch-Serologische Labordiagnostik</option>\n' +
    '                <option value="Mund-, Kiefer- und Gesichtschirurgie">Mund-, Kiefer- und Gesichtschirurgie</option>\n' +
    '                <option value="Neurobiologie">Neurobiologie</option>\n' +
    '                <option value="Neurochirurgie">Neurochirurgie</option>\n' +
    '                <option value="Neurologie">Neurologie</option>\n' +
    '                <option value="Neurologie und Psychiatrie">Neurologie und Psychiatrie</option>\n' +
    '                <option value="Neuropathologie">Neuropathologie</option>\n' +
    '                <option value="Nuklearmedizin">Nuklearmedizin</option>\n' +
    '                <option value="Orthopädie und Orthopädische Chirurgie">Orthopädie und Orthopädische Chirurgie</option>\n' +
    '                <option value="Pathologie">Pathologie</option>\n' +
    '                <option value="Pathophysiologie">Pathophysiologie</option>\n' +
    '                <option value="Pharmakologie und Toxikologie">Pharmakologie und Toxikologie</option>\n' +
    '                <option value="Physikalische Medizin und Allgemeine Rehabilitation">Physikalische Medizin und Allgemeine Rehabilitation</option>\n' +
    '                <option value="Physiologie">Physiologie</option>\n' +
    '                <option value="Plastische, Ästhetische und Rekonstruktive Chirurgie">Plastische, Ästhetische und Rekonstruktive Chirurgie</option>\n' +
    '                <option value="Psychiatrie">Psychiatrie</option>\n' +
    '                <option value="Psychiatrie und Neurologie">Psychiatrie und Neurologie</option>\n' +
    '                <option value="Psychiatrie und Psychotherapeutische Medizin">Psychiatrie und Psychotherapeutische Medizin</option>\n' +
    '                <option value="Radiologie">Radiologie</option>\n' +
    '                <option value="Sozialmedizin">Sozialmedizin</option>\n' +
    '                <option value="Spezifische Prophylaxe und Tropenmedizin">Spezifische Prophylaxe und Tropenmedizin</option>\n' +
    '                <option value="Strahlentherapie-Radioonkologie">Strahlentherapie-Radioonkologie</option>\n' +
    '                <option value="Theoretische Sonderfächer">Theoretische Sonderfächer</option>\n' +
    '                <option value="Thoraxchirurgie">Thoraxchirurgie</option>\n' +
    '                <option value="Tumorbiologie">Tumorbiologie</option>\n' +
    '                <option value="Unfallchirurgie">Unfallchirurgie</option>\n' +
    '                <option value="Urologie">Urologie</option>\n' +
    '                <option value="Virologie">Virologie</option>\n' +
    '                <option value="Zahn-, Mund- und Kieferheilkunde">Zahn-, Mund- und Kieferheilkunde</option>\n' +
    '            </select>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '        <div class="form-group col-md-3">\n' +
    '            <label for="phone1">Telefon 1</label>\n' +
    '            <input type="text" ng-model="dreCtrl.doctor.phone1" class="form-control" id="phone1" placeholder="Telefon" ng-blur="dreCtrl.save()">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-3">\n' +
    '            <label for="phone2">Telefon 2</label>\n' +
    '            <input type="text" ng-model="dreCtrl.doctor.phone2" class="form-control" id="phone2" placeholder="Telefon" ng-blur="dreCtrl.save()">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-3">\n' +
    '            <label for="fax">Fax</label>\n' +
    '            <input type="text" ng-model="dreCtrl.doctor.fax" class="form-control" id="fax" placeholder="Telefon" ng-blur="dreCtrl.save()">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-3">\n' +
    '            <label for="email">E-Mail</label>\n' +
    '            <input type="text" ng-model="dreCtrl.doctor.email" class="form-control" id="email" placeholder="E-Mail" ng-blur="dreCtrl.save()">\n' +
    '        </div>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '    <div class=" row">\n' +
    '        <div class="form-group col-md-6">\n' +
    '            <label for="address">Adresse</label>\n' +
    '            <input type="text" ng-model="dreCtrl.doctor.address" class="form-control" id="address"\n' +
    '                   placeholder="Adresse" ng-blur="dreCtrl.save()">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-6">\n' +
    '            <zipcode zip="dreCtrl.doctor.postcode" city="dreCtrl.doctor.city"></zipcode>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class=" row">\n' +
    '        <div class="form-group">\n' +
    '            <button class="btn btn-success" type="button" ng-click="dreCtrl.save(); dreCtrl.close()">Speichern &amp; Schließen</button>\n' +
    '            <button class="btn btn-danger" type="button" ng-click="dreCtrl.close()">Schließen</button>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</form>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('helpers/doctor/doctor-directive.tpl.html',
    '\n' +
    '\n' +
    '<div class="btn btn-success" ng-click="drCtrl.addDoctor()">Neuen Arzt anlegen</div>\n' +
    '\n' +
    '<h3>Liste</h3>\n' +
    '<table class="table table-striped">\n' +
    '    <thead>\n' +
    '    <tr>\n' +
    '        <th>Nachname/Institut</th>\n' +
    '        <th>Vorname</th>\n' +
    '        <th>Spezialisierung</th>\n' +
    '        <th>Ort</th>\n' +
    '        <th></th>\n' +
    '    </tr>\n' +
    '    </thead>\n' +
    '    <tr ng-repeat="doctor in drCtrl.doctorlist">\n' +
    '        <td>{{doctor.lastname}} {{doctor.institute}} </td>\n' +
    '        <td>{{doctor.firstname}}</td>\n' +
    '        <td>{{doctor.speciality}}</td>\n' +
    '        <td>{{doctor.city}}</td>\n' +
    '        <td>\n' +
    '            <button class="btn btn-primary" ng-click="drCtrl.editDoctor(doctor)">Editeren</button>\n' +
    '            <button class="btn btn-danger" ng-click="drCtrl.removeDoctor(doctor)">Löschen</button>\n' +
    '        </td>\n' +
    '    </tr>\n' +
    '</table>\n' +
    '\n' +
    '<uib-pagination total-items="drCtrl.totalItems"\n' +
    '                items-per-page="{{drCtrl.itemspp}}"\n' +
    '                max-size="10"\n' +
    '                first-text="Erste"\n' +
    '                last-text="Letzte"\n' +
    '                next-text="N&auml;chste"\n' +
    '                previous-text="Vorige"\n' +
    '                boundary-links="true"\n' +
    '                rotate="false"\n' +
    '                ng-model="drCtrl.currentPage"\n' +
    '                ng-change="drCtrl.pageChanged()"></uib-pagination>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('helpers/doctor/doctor-add-directive.tpl.html',
    '<form class="form-vertical" id="newdoctorform">\n' +
    '    <div class="row">\n' +
    '        <div class="form-group col-md-2"  ng-init="draCtrl.newdoctor.titlefront=\'Dr.\'">\n' +
    '            <label for="titlefront">Titel</label>\n' +
    '            <input type="text" ng-model="draCtrl.newdoctor.titlefront" class="form-control" id="titlefront"\n' +
    '                   placeholder="Dr." ng-init="draCtrl.newdoctor.titlefront=\'Dr.\'">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-4">\n' +
    '            <label for="firstname">Vorname</label>\n' +
    '            <input type="text" ng-model="draCtrl.newdoctor.firstname" class="form-control" id="firstname"\n' +
    '                   placeholder="Vorname">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-4">\n' +
    '            <label for="lastname">Nachname</label>\n' +
    '            <input type="text" ng-model="draCtrl.newdoctor.lastname" class="form-control" id="lastname"\n' +
    '                   placeholder="Nachname">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-2">\n' +
    '            <label for="titleback">Titel</label>\n' +
    '            <input type="text" ng-model="draCtrl.newdoctor.titleback" class="form-control" id="titleback"\n' +
    '                   placeholder="MSc">\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '        <div class="form-group col-md-12">\n' +
    '            <label for="institute">Institut</label>\n' +
    '            <input type="text" ng-model="dreCtrl.newdoctor.institute" class="form-control" id="institute"\n' +
    '                   placeholder="Nachname" ng-blur="dreCtrl.save()">\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '        <div class="form-group col-md-12">\n' +
    '            <label for="speciality">Fachgebiet</label>\n' +
    '            <select id="speciality" ng-model="draCtrl.newdoctor.speciality">\n' +
    '                <option value="Allgemeinmedizin">Allgemeinmedizin</option>\n' +
    '                <option value="Anästhesiologie und Intensivmedizin">Anästhesiologie und Intensivmedizin</option>\n' +
    '                <option value="Anatomie">Anatomie</option>\n' +
    '                <option value="Arbeitsmedizin">Arbeitsmedizin</option>\n' +
    '                <option value="Augenheilkunde und Optometrie">Augenheilkunde und Optometrie</option>\n' +
    '                <option value="Blutgruppenserologie und Transfusionsmedizin">Blutgruppenserologie und Transfusionsmedizin</option>\n' +
    '                <option value="Chirurgie">Chirurgie</option>\n' +
    '                <option value="Frauenheilkunde und Geburtshilfe">Frauenheilkunde und Geburtshilfe</option>\n' +
    '                <option value="Gerichtsmedizin">Gerichtsmedizin</option>\n' +
    '                <option value="Hals-, Nasen- und Ohrenkrankheiten">Hals-, Nasen- und Ohrenkrankheiten</option>\n' +
    '                <option value="Haut- und Geschlechtskrankheiten">Haut- und Geschlechtskrankheiten</option>\n' +
    '                <option value="Herzchirurgie">Herzchirurgie</option>\n' +
    '                <option value="Histologie und Embryologie">Histologie und Embryologie</option>\n' +
    '                <option value="Hygiene und Mikrobiologie">Hygiene und Mikrobiologie</option>\n' +
    '                <option value="Immunologie">Immunologie</option>\n' +
    '                <option value="Innere Medizin">Innere Medizin</option>\n' +
    '                <option value="Kinder- und Jugendchirurgie">Kinder- und Jugendchirurgie</option>\n' +
    '                <option value="Kinder- und Jugendheilkunde">Kinder- und Jugendheilkunde</option>\n' +
    '                <option value="Kinder- und Jugendpsychiatrie">Kinder- und Jugendpsychiatrie</option>\n' +
    '                <option value="Lungenkrankheiten">Lungenkrankheiten</option>\n' +
    '                <option value="Medizinische Biologie">Medizinische Biologie</option>\n' +
    '                <option value="Medizinische Biophysik">Medizinische Biophysik</option>\n' +
    '                <option value="Medizinische Genetik">Medizinische Genetik</option>\n' +
    '                <option value="Medizinische und Chemische Labordiagnostik">Medizinische und Chemische Labordiagnostik</option>\n' +
    '                <option value="Medizinische Leistungsphysiologie">Medizinische Leistungsphysiologie</option>\n' +
    '                <option value="Mikrobiologisch-Serologische Labordiagnostik">Mikrobiologisch-Serologische Labordiagnostik</option>\n' +
    '                <option value="Mund-, Kiefer- und Gesichtschirurgie">Mund-, Kiefer- und Gesichtschirurgie</option>\n' +
    '                <option value="Neurobiologie">Neurobiologie</option>\n' +
    '                <option value="Neurochirurgie">Neurochirurgie</option>\n' +
    '                <option value="Neurologie">Neurologie</option>\n' +
    '                <option value="Neurologie und Psychiatrie">Neurologie und Psychiatrie</option>\n' +
    '                <option value="Neuropathologie">Neuropathologie</option>\n' +
    '                <option value="Nuklearmedizin">Nuklearmedizin</option>\n' +
    '                <option value="Orthopädie und Orthopädische Chirurgie">Orthopädie und Orthopädische Chirurgie</option>\n' +
    '                <option value="Pathologie">Pathologie</option>\n' +
    '                <option value="Pathophysiologie">Pathophysiologie</option>\n' +
    '                <option value="Pharmakologie und Toxikologie">Pharmakologie und Toxikologie</option>\n' +
    '                <option value="Physikalische Medizin und Allgemeine Rehabilitation">Physikalische Medizin und Allgemeine Rehabilitation</option>\n' +
    '                <option value="Physiologie">Physiologie</option>\n' +
    '                <option value="Plastische, Ästhetische und Rekonstruktive Chirurgie">Plastische, Ästhetische und Rekonstruktive Chirurgie</option>\n' +
    '                <option value="Psychiatrie">Psychiatrie</option>\n' +
    '                <option value="Psychiatrie und Neurologie">Psychiatrie und Neurologie</option>\n' +
    '                <option value="Psychiatrie und Psychotherapeutische Medizin">Psychiatrie und Psychotherapeutische Medizin</option>\n' +
    '                <option value="Radiologie">Radiologie</option>\n' +
    '                <option value="Sozialmedizin">Sozialmedizin</option>\n' +
    '                <option value="Spezifische Prophylaxe und Tropenmedizin">Spezifische Prophylaxe und Tropenmedizin</option>\n' +
    '                <option value="Strahlentherapie-Radioonkologie">Strahlentherapie-Radioonkologie</option>\n' +
    '                <option value="Theoretische Sonderfächer">Theoretische Sonderfächer</option>\n' +
    '                <option value="Thoraxchirurgie">Thoraxchirurgie</option>\n' +
    '                <option value="Tumorbiologie">Tumorbiologie</option>\n' +
    '                <option value="Unfallchirurgie">Unfallchirurgie</option>\n' +
    '                <option value="Urologie">Urologie</option>\n' +
    '                <option value="Virologie">Virologie</option>\n' +
    '                <option value="Zahn-, Mund- und Kieferheilkunde">Zahn-, Mund- und Kieferheilkunde</option>\n' +
    '            </select>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '        <div class="form-group col-md-3">\n' +
    '            <label for="phone1">Telefon 1</label>\n' +
    '            <input type="text" ng-model="draCtrl.newdoctor.phone1" class="form-control" id="phone1" placeholder="Telefon">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-3">\n' +
    '            <label for="phone2">Telefon 2</label>\n' +
    '            <input type="text" ng-model="draCtrl.newdoctor.phone2" class="form-control" id="phone2" placeholder="Telefon">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-3">\n' +
    '            <label for="fax">Fax</label>\n' +
    '            <input type="text" ng-model="draCtrl.newdoctor.fax" class="form-control" id="fax" placeholder="Telefon">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-3">\n' +
    '            <label for="email">E-Mail</label>\n' +
    '            <input type="text" ng-model="draCtrl.newdoctor.email" class="form-control" id="email" placeholder="E-Mail">\n' +
    '        </div>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '    <div class=" row">\n' +
    '        <div class="form-group col-md-6">\n' +
    '            <label for="address">Adresse</label>\n' +
    '            <input type="text" ng-model="draCtrl.newdoctor.address" class="form-control" id="address"\n' +
    '                   placeholder="Adresse">\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="form-group col-md-6">\n' +
    '            <zipcode zip="draCtrl.newdoctor.postcode" city="draCtrl.newdoctor.city"></zipcode>\n' +
    '        </div>\n' +
    '\n' +
    '    </div>\n' +
    '    <div class=" row">\n' +
    '        <div class="form-group col-md-12">\n' +
    '            <button class="btn btn-success" type="button" ng-click="draCtrl.addDoctor()">Arzt anlegen</button>\n' +
    '            <button class="btn btn-danger" type="button" ng-click="$parent.$dismiss();">Abbrechen</button>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</form>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('finance/turnover/turnover-directive.tpl.html',
    '<h2>Umsätze</h2>\n' +
    '\n' +
    '<!-- Menü / Nav pills -->\n' +
    '<div class="btn-group spacer-top spacer-bottom" style="align-content: center;">\n' +
    '  <label class="btn btn-primary" ng-model="toCtrl.view" uib-btn-radio="\'summary\'">Überblick</label>\n' +
    '  <label class="btn btn-primary" ng-model="toCtrl.view" uib-btn-radio="\'cash\'">Bar-Umsätze</label>\n' +
    '  <label class="btn btn-primary" ng-model="toCtrl.view" uib-btn-radio="\'check\'">Umsatz auf Rechnung</label>\n' +
    '</div>\n' +
    '\n' +
    '<!-- --------------------------------------------------------------------------------------------------------------- -->\n' +
    '<!-- Summary view -->\n' +
    '<!-- --------------------------------------------------------------------------------------------------------------- -->\n' +
    '\n' +
    '<div ng-show="toCtrl.view === \'summary\'">\n' +
    '  \n' +
    '  <h3>Gesamtumsatz</h3>\n' +
    '  \n' +
    '  <table class="table table-striped">\n' +
    '    <tr>\n' +
    '      <th>Zeitraum</th>\n' +
    '      <th class="nrr"><span uib-tooltip="abzgl. Stornos, inkl. offene Beträge">Gesamt Netto</span></th>\n' +
    '      <th class="nrr"><span uib-tooltip="abzgl. Stornos, exkl. offene Beträge">Netto verfügbar</span></th>\n' +
    '      <th class="nrr"><span uib-tooltip="abzgl. Stornos">Gesamt Brutto</span></th>\n' +
    '      <th class="nrr"><span uib-tooltip="Noch nicht beglichene Rechnungen">Offen</span></th>\n' +
    '      <th class="nrr"><span uib-tooltip="Summiert über alle Umsatzsteuersätze">USt.</span></th>\n' +
    '      <th class="nrr"><span uib-tooltip="Summe stornierter Einzelrechnungen">Storno</span></th>\n' +
    '    \n' +
    '    </tr>\n' +
    '    <tr>\n' +
    '      <td>Dieses Monat</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.monthcashstats.total + toCtrl.thisyear.monthcheckstats.total\n' +
    '        - toCtrl.thisyear.monthcashstats.tax - toCtrl.thisyear.monthcheckstats.tax | currency : \'\' : 2}} €\n' +
    '      </td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.monthcashstats.total + toCtrl.thisyear.monthcheckstats.total\n' +
    '        - toCtrl.thisyear.monthcashstats.tax - toCtrl.thisyear.monthcheckstats.tax\n' +
    '        - toCtrl.thisyear.monthcheckstats.open\n' +
    '        | currency : \'\' : 2}} €\n' +
    '      </td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.monthcashstats.total + toCtrl.thisyear.monthcheckstats.total | currency : \'\' :\n' +
    '        2}} €\n' +
    '      </td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.monthcheckstats.open | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.monthcashstats.tax + toCtrl.thisyear.monthcheckstats.tax | currency : \'\' : 2}}\n' +
    '        €\n' +
    '      </td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.monthcashstats.storno + toCtrl.thisyear.monthcheckstats.storno | currency : \'\' :\n' +
    '        2}} €\n' +
    '      </td>\n' +
    '    </tr>\n' +
    '    <tr>\n' +
    '      <td>Dieses Quartal</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.quartercashstats.total + toCtrl.thisyear.quartercheckstats.total\n' +
    '        - toCtrl.thisyear.quartercashstats.tax - toCtrl.thisyear.quartercheckstats.tax | currency : \'\' : 2}} €\n' +
    '      </td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.quartercashstats.total + toCtrl.thisyear.quartercheckstats.total\n' +
    '        - toCtrl.thisyear.quartercashstats.tax - toCtrl.thisyear.quartercheckstats.tax\n' +
    '        - toCtrl.thisyear.quartercheckstats.open\n' +
    '        | currency : \'\' : 2}} €\n' +
    '      </td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.quartercashstats.total + toCtrl.thisyear.quartercheckstats.total | currency : \'\'\n' +
    '        : 2}} €\n' +
    '      </td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.quartercheckstats.open | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.quartercashstats.tax + toCtrl.thisyear.quartercheckstats.tax | currency : \'\' :\n' +
    '        2}} €\n' +
    '      </td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.quartercashstats.storno + toCtrl.thisyear.quartercheckstats.storno | currency :\n' +
    '        \'\' : 2}} €\n' +
    '      </td>\n' +
    '    </tr>\n' +
    '    <tr>\n' +
    '      <td>Dieses Jahr</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.yearcashstats.total + toCtrl.thisyear.yearcheckstats.total\n' +
    '        - toCtrl.thisyear.yearcashstats.tax - toCtrl.thisyear.yearcheckstats.tax | currency : \'\' : 2}} €\n' +
    '      </td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.yearcashstats.total + toCtrl.thisyear.yearcheckstats.total\n' +
    '        - toCtrl.thisyear.yearcashstats.tax - toCtrl.thisyear.yearcheckstats.tax\n' +
    '        - toCtrl.thisyear.yearcheckstats.open\n' +
    '        | currency : \'\' : 2}} €\n' +
    '      </td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.yearcashstats.total + toCtrl.thisyear.yearcheckstats.total | currency : \'\' : 2}}\n' +
    '        €\n' +
    '      </td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.yearcheckstats.open | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.yearcashstats.tax + toCtrl.thisyear.yearcheckstats.tax | currency : \'\' : 2}} €\n' +
    '      </td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.yearcashstats.storno + toCtrl.thisyear.yearcheckstats.storno | currency : \'\' :\n' +
    '        2}} €\n' +
    '      </td>\n' +
    '    </tr>\n' +
    '  </table>\n' +
    '  \n' +
    '  \n' +
    '  <h3>Barumsätze</h3>\n' +
    '  \n' +
    '  <table class="table table-striped">\n' +
    '    <tr>\n' +
    '      <th>Zeitraum</th>\n' +
    '      <th class="nrr"><span uib-tooltip="abzgl. Stornos, inkl. offene Beträge">Gesamt Netto</span></th>\n' +
    '      <th class="nrr"><span uib-tooltip="abzgl. Stornos">Gesamt Brutto</span></th>\n' +
    '      <th class="nrr"><span uib-tooltip="Umsätze durch Bargeld">Bargeld</span></th>\n' +
    '      <th class="nrr"><span uib-tooltip="Umsätze durch Bankomat oder Kreditkarten">Karte</span></th>\n' +
    '      <th class="nrr"><span uib-tooltip="Summiert über alle Umsatzsteuersätze">USt.</span></th>\n' +
    '      <th class="nrr"><span uib-tooltip="Summe stornierter Einzelrechnungen">Storno</span></th>\n' +
    '      <th class="nrr" uib-tooltip="Rechnung/Storno, Durchschnitt (+- Standardabweichung)">Statistik</th>\n' +
    '    </tr>\n' +
    '    <tr>\n' +
    '      <td>Dieses Monat</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.monthcashstats.total - toCtrl.thisyear.monthcashstats.tax | currency : \'\' : 2}}\n' +
    '        €\n' +
    '      </td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.monthcashstats.total | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.monthcashstats.cash | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.monthcashstats.card | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.monthcashstats.tax | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.monthcashstats.storno | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr" uib-tooltip="Rechnung/Storno, Durchschnitt (+- Standardabweichung)">\n' +
    '        <span class="text-success">{{toCtrl.thisyear.monthcashstats.totalcount - toCtrl.thisyear.monthcashstats.stornocount}}</span>/\n' +
    '        <span class="text-danger">{{toCtrl.thisyear.monthcashstats.stornocount}}</span>,\n' +
    '        {{toCtrl.thisyear.monthcashstats.avg | currency : \'\' : 2}} € ({{toCtrl.thisyear.monthcashstats.stddev | currency\n' +
    '        : \'\' : 2}} €) </span>,\n' +
    '      </td>\n' +
    '    </tr>\n' +
    '    <tr>\n' +
    '      <td>Dieses Quartal</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.quartercashstats.total - toCtrl.thisyear.quartercashstats.tax | currency : \'\' :\n' +
    '        2}} €\n' +
    '      </td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.quartercashstats.total | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.quartercashstats.cash | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.quartercashstats.card | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.quartercashstats.tax | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.quartercashstats.storno | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr" uib-tooltip="Rechnung/Storno, Durchschnitt (+- Standardabweichung)">\n' +
    '        <span class="text-success">{{toCtrl.thisyear.quartercashstats.totalcount - toCtrl.thisyear.quartercashstats.stornocount}}</span>/\n' +
    '        <span class="text-danger">{{toCtrl.thisyear.quartercashstats.stornocount}}</span>,\n' +
    '        {{toCtrl.thisyear.quartercashstats.avg | currency : \'\' : 2}} € ({{toCtrl.thisyear.quartercashstats.stddev |\n' +
    '        currency : \'\' : 2}} €) </span>,\n' +
    '      </td>\n' +
    '    </tr>\n' +
    '    <tr>\n' +
    '      <td>Dieses Jahr</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.yearcashstats.total - toCtrl.thisyear.yearcashstats.tax | currency : \'\' : 2}}\n' +
    '        €\n' +
    '      </td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.yearcashstats.total | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.yearcashstats.cash | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.yearcashstats.card | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.yearcashstats.tax | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.yearcashstats.storno | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr" uib-tooltip="Rechnung/Storno, Durchschnitt (+- Standardabweichung)">\n' +
    '        <span class="text-success">{{toCtrl.thisyear.yearcashstats.totalcount - toCtrl.thisyear.yearcashstats.stornocount}}</span>/\n' +
    '        <span class="text-danger">{{toCtrl.thisyear.yearcashstats.stornocount}}</span>,\n' +
    '        {{toCtrl.thisyear.yearcashstats.avg | currency : \'\' : 2}} € ({{toCtrl.thisyear.yearcashstats.stddev | currency :\n' +
    '        \'\' : 2}} €) </span>,\n' +
    '      </td>\n' +
    '    </tr>\n' +
    '  </table>\n' +
    '  \n' +
    '  \n' +
    '  <h3>Umsatz auf Rechnung</h3>\n' +
    '  \n' +
    '  <table class="table table-striped">\n' +
    '    <tr>\n' +
    '      <th>Zeitraum</th>\n' +
    '      <th class="nrr"><span uib-tooltip="abzgl. Stornos, inkl. offene Beträge">Gesamt Netto</span></th>\n' +
    '      <th class="nrr"><span uib-tooltip="abzgl. Stornos, exkl. offene Beträge">Netto verfügbar</span></th>\n' +
    '      <th class="nrr"><span uib-tooltip="abzgl. Stornos">Gesamt Brutto</span></th>\n' +
    '      <th class="nrr"><span uib-tooltip="Bereits beglichene Rechnungen">Bezahlt</span></th>\n' +
    '      <th class="nrr"><span uib-tooltip="Noch nicht beglichene Rechnungen">Offen</span></th>\n' +
    '      <th class="nrr"><span uib-tooltip="Summiert über alle Umsatzsteuersätze">USt.</span></th>\n' +
    '      <th class="nrr"><span uib-tooltip="Summe stornierter Einzelrechnungen">Storno</span></th>\n' +
    '      <th class="nrr" uib-tooltip="Rechnung/Storno, Durchschnitt (+- Standardabweichung)">Statistik</th>\n' +
    '    </tr>\n' +
    '    <tr>\n' +
    '      <td>Dieses Monat</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.monthcheckstats.total - toCtrl.thisyear.monthcheckstats.tax -\n' +
    '        toCtrl.thisyear.monthcheckstats.storno| currency : \'\' : 2}} €\n' +
    '      </td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.monthcheckstats.total - toCtrl.thisyear.monthcheckstats.tax -\n' +
    '        toCtrl.thisyear.monthcheckstats.open | currency : \'\' : 2}} €\n' +
    '      </td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.monthcheckstats.total| currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.monthcheckstats.paid | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.monthcheckstats.open | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.monthcheckstats.tax | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.monthcheckstats.storno | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">\n' +
    '        <span class="text-success">{{toCtrl.thisyear.monthcheckstats.totalcount - toCtrl.thisyear.monthcheckstats.stornocount}}</span>/<span\n' +
    '          class="text-danger">{{toCtrl.thisyear.monthcheckstats.stornocount}}</span>,\n' +
    '        {{toCtrl.thisyear.monthcheckstats.avg | currency : \'\' : 2}} € ({{toCtrl.thisyear.monthcheckstats.stddev |\n' +
    '        currency : \'\' : 2}} €)</span>,\n' +
    '      </td>\n' +
    '    </tr>\n' +
    '    <tr>\n' +
    '      <td>Dieses Quartal</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.quartercheckstats.total - toCtrl.thisyear.quartercheckstats.tax | currency : \'\'\n' +
    '        : 2}} €\n' +
    '      </td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.quartercheckstats.total - toCtrl.thisyear.quartercheckstats.tax -\n' +
    '        toCtrl.thisyear.quartercheckstats.open | currency : \'\' : 2}} €\n' +
    '      </td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.quartercheckstats.total| currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.quartercheckstats.paid | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.quartercheckstats.open | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.quartercheckstats.tax | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.quartercheckstats.storno | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">\n' +
    '        <span class="text-success">{{toCtrl.thisyear.quartercheckstats.totalcount - toCtrl.thisyear.quartercheckstats.stornocount}}</span>/<span\n' +
    '          class="text-danger">{{toCtrl.thisyear.quartercheckstats.stornocount}}</span>,\n' +
    '        {{toCtrl.thisyear.quartercheckstats.avg | currency : \'\' : 2}} € ({{toCtrl.thisyear.quartercheckstats.stddev |\n' +
    '        currency : \'\' : 2}} €)</span>,\n' +
    '      </td>\n' +
    '    </tr>\n' +
    '    <tr>\n' +
    '      <td>Dieses Jahr</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.yearcheckstats.total - toCtrl.thisyear.yearcheckstats.tax | currency : \'\' : 2}}\n' +
    '        €\n' +
    '      </td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.yearcheckstats.total - toCtrl.thisyear.yearcheckstats.tax -\n' +
    '        toCtrl.thisyear.yearcheckstats.open | currency : \'\' : 2}} €\n' +
    '      </td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.yearcheckstats.total| currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.yearcheckstats.paid | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.yearcheckstats.open | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.yearcheckstats.tax | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{toCtrl.thisyear.yearcheckstats.storno | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">\n' +
    '        <span class="text-success">{{toCtrl.thisyear.yearcheckstats.totalcount - toCtrl.thisyear.yearcheckstats.stornocount}}</span>/<span\n' +
    '          class="text-danger">{{toCtrl.thisyear.yearcheckstats.stornocount}}</span>,\n' +
    '        {{toCtrl.thisyear.yearcheckstats.avg | currency : \'\' : 2}} € ({{toCtrl.thisyear.yearcheckstats.stddev | currency\n' +
    '        : \'\' : 2}} €)</span>,\n' +
    '      </td>\n' +
    '    </tr>\n' +
    '  </table>\n' +
    '\n' +
    '\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '<!-- --------------------------------------------------------------------------------------------------------------- -->\n' +
    '<!-- Cash View -->\n' +
    '<!-- --------------------------------------------------------------------------------------------------------------- -->\n' +
    '<div ng-show="toCtrl.view === \'cash\'">\n' +
    '  <h3>Barumsätze</h3>\n' +
    '  \n' +
    '  <!-- per year -->\n' +
    '  <h4>Jahresübersicht</h4>\n' +
    '  <table class="table table-striped">\n' +
    '    <tr>\n' +
    '      <th class="nrr">Jahr</th>\n' +
    '      <th class="nrr">Gesamt</th>\n' +
    '      <th class="nrr">Bar</th>\n' +
    '      <th class="nrr">Karte</th>\n' +
    '      <th class="nrr">Ust.</th>\n' +
    '      <th class="nrr" uib-tooltip="Rechnung/Storno, Durchschnitt (+- Standardabweichung)">Statistik</th>\n' +
    '    </tr>\n' +
    '    <tr ng-repeat="line in toCtrl.yearlycashstats">\n' +
    '      <td class="nrr">{{ line._id.year}}</td>\n' +
    '      <td class="nrr">{{ line.total | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{ line.cash | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{ line.card | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{ line.tax | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr" uib-tooltip="Gesamtzahl/Stornozahl, Durchschnittl. Rechnungshöhe">\n' +
    '        <span class="text-success" ng-bind="line.invcount"></span>/\n' +
    '        <span class="text-danger" ng-bind="line.stornocount"></span>&nbsp;\n' +
    '        <span class="text-primary">{{ line.avg | currency : \'\' : 2}} €</span>&nbsp;\n' +
    '      </td>\n' +
    '    </tr>\n' +
    '  </table>\n' +
    '  <!-- per month -->\n' +
    '  <h4>Monatsübersicht</h4>\n' +
    '  <table class="table table-striped">\n' +
    '    <tr>\n' +
    '      <th class="nrr">Monat</th>\n' +
    '      <th class="nrr">Gesamt</th>\n' +
    '      <th class="nrr">Bar</th>\n' +
    '      <th class="nrr">Karte</th>\n' +
    '      <th class="nrr">Ust.</th>\n' +
    '      <th class="nrr" uib-tooltip="Rechnung/Storno, Durchschnitt (+- Standardabweichung)">Statistik</th>\n' +
    '    </tr>\n' +
    '    <tr ng-repeat="line in toCtrl.monthlycashstats">\n' +
    '      <td class="nrr">{{ line._id.month}}/{{ line._id.year}}</td>\n' +
    '      <td class="nrr">{{ line.total | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{ line.cash | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{ line.card | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{ line.tax | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr" uib-tooltip="Gesamtzahl/Stornozahl, Durchschnittl. Rechnungshöhe">\n' +
    '        <span class="text-success" ng-bind="line.invcount"></span>/\n' +
    '        <span class="text-danger" ng-bind="line.stornocount"></span>&nbsp;\n' +
    '        <span class="text-primary">{{ line.avg | currency : \'\' : 2}} €</span>&nbsp;\n' +
    '      </td>\n' +
    '    </tr>\n' +
    '  </table>\n' +
    '  \n' +
    '</div>\n' +
    '\n' +
    '<!-- --------------------------------------------------------------------------------------------------------------- -->\n' +
    '<!-- Check View -->\n' +
    '<!-- --------------------------------------------------------------------------------------------------------------- -->\n' +
    '<div ng-show="toCtrl.view === \'check\'">\n' +
    '  <h3>Umsatz auf Rechnung</h3>\n' +
    '  \n' +
    '  \n' +
    '  <!-- per year -->\n' +
    '  <h4>Jahresübersicht</h4>\n' +
    '  <table class="table table-striped">\n' +
    '    <tr>\n' +
    '      <th class="nrr">Jahr</th>\n' +
    '      <th class="nrr">Gesamt</th>\n' +
    '      <th class="nrr">Ust.</th>\n' +
    '      <th class="nrr">Bezahlt</th>\n' +
    '      <th class="nrr">Offen</th>\n' +
    '      <th class="nrr">Stats</th>\n' +
    '    </tr>\n' +
    '    <tr ng-repeat="line in toCtrl.yearlycheckstats">\n' +
    '      <td class="nrr">{{ line._id.year}}</td>\n' +
    '      <td class="nrr">{{ line.total | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{ line.tax | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{ line.paid | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{ line.open | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr" uib-tooltip="Gesamtzahl/Stornozahl, Durchschnittl. Rechnungshöhe">\n' +
    '        <span class="text-success" ng-bind="line.invcount"></span>/\n' +
    '        <span class="text-danger" ng-bind="line.stornocount"></span>&nbsp;\n' +
    '        <span class="text-primary">{{ line.avg | currency : \'\' : 2}} €</span>&nbsp;\n' +
    '      </td>\n' +
    '    </tr>\n' +
    '  </table>\n' +
    '  \n' +
    '  <!-- per month -->\n' +
    '  <h4>Monatsübersicht</h4>\n' +
    '  <table class="table table-striped">\n' +
    '    <tr>\n' +
    '      <th class="nrr">Monat</th>\n' +
    '      <th class="nrr">Gesamt</th>\n' +
    '      <th class="nrr">Ust.</th>\n' +
    '      <th class="nrr">Bezahlt</th>\n' +
    '      <th class="nrr">Offen</th>\n' +
    '      <th class="nrr">Stats</th>\n' +
    '    </tr>\n' +
    '    <tr ng-repeat="line in toCtrl.monthlycheckstats">\n' +
    '      <td class="nrr">{{ line._id.month}}/{{ line._id.year}}</td>\n' +
    '      <td class="nrr">{{ line.total | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{ line.tax | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{ line.paid | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr">{{ line.open | currency : \'\' : 2}} €</td>\n' +
    '      <td class="nrr" uib-tooltip="Gesamtzahl/Stornozahl, Durchschnittl. Rechnungshöhe">\n' +
    '        <span class="text-success" ng-bind="line.invcount"></span>/\n' +
    '        <span class="text-danger" ng-bind="line.stornocount"></span>&nbsp;\n' +
    '        <span class="text-primary">{{ line.avg | currency : \'\' : 2}} €</span>&nbsp;\n' +
    '      </td>\n' +
    '    </tr>\n' +
    '  </table>\n' +
    '</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('finance/rksv/deplist-component.tpl.html',
    '<h3>Datenerfassungsprotokoll (DEP)</h3>\n' +
    '\n' +
    '<table class="table table-striped">\n' +
    '  <tr>\n' +
    '    <th>Datum</th>\n' +
    '    <th>BelegNr.</th>\n' +
    '    <th>Typ</th>\n' +
    '    <th>Signiert</th>\n' +
    '    <th>PDF</th>\n' +
    '  </tr>\n' +
    '  <tr ng-repeat="entry in vm.dep">\n' +
    '    <td>{{entry.created | datetime}}</td>\n' +
    '    <td ng-bind="entry.receiptnr"></td>\n' +
    '    <td ng-bind="entry.type"></td>\n' +
    '    <td><input type="checkbox" ng-model="entry.signatureok" disabled></td>\n' +
    '    <td><a href="/routes/pdfreader/{{entry.pdfid}}" target="_blank"><i class="fa fa-file-pdf-o"></i></a></td>\n' +
    '  </tr>\n' +
    '  \n' +
    '</table>\n' +
    '\n' +
    '<uib-pagination total-items="vm.totalItems"\n' +
    '                items-per-page="20"\n' +
    '                max-size="10"\n' +
    '                first-text="Erste"\n' +
    '                last-text="Letzte"\n' +
    '                next-text="N&auml;chste"\n' +
    '                previous-text="Vorige"\n' +
    '                boundary-links="true"\n' +
    '                rotate="false"\n' +
    '                ng-model="vm.currentPage"\n' +
    '                ng-change="vm.pageChanged()"></uib-pagination>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('finance/rksv/depexport-component.tpl.html',
    '<h3>Registrierkassen DEP Datenexport (Datenerfassungsprotokoll)</h3>\n' +
    '\n' +
    '<div class="alert alert-info">\n' +
    '  <h3>Anleitung</h3>\n' +
    '  <p>Diese Routine exportiert das Registrierkassen Datenerfassungsprotokoll gemäß RKSV17.\n' +
    '    Da diese Registrierkasse Klasse III als Teil einer integrierten Branchenlösung ausschließlich Belege für Rechnungen erzeugt,\n' +
    '    sind diese Daten möglicherweise nicht ausreichend. Bitte Rücksprache halten und ggf. den\n' +
    '    <a ui-sref="finance.bmfexport">Finanzamtsexport</a> anwenden, der mehr Daten beinhaltet.</p>\n' +
    '  <p>Daten können beginnend mit 1.1.2017, für das aktuelle Jahr oder einen beliebigen Zeitraum exportiert werden.</p>\n' +
    '  <p>Das Ergebnis des Exports ist eine Datei namens <tt>depexport.json</tt>. Diese Datei muss lokal gespeichert und auf\n' +
    '    einen Memorystick kopiert werden. Der Memorystick wird an die Finanz übergeben.\n' +
    '    Nach Rücksprache mit dem Finanzbeamten kann die Datei eventuell auch per E-Mail übermittelt werden.</p>\n' +
    '</div>\n' +
    '\n' +
    '<a ng-click="vm.thisyear()" class="btn btn-success" >Dieses Jahr</a>\n' +
    '<a ng-click="vm.allyears()" class="btn btn-success" >Seit 1.1.2017</a>\n' +
    '\n' +
    '<form class="form-vertical">\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-6">\n' +
    '      <label for="startdate">Von</label>\n' +
    '\n' +
    '      <p class="input-group">\n' +
    '        <input type="text" class="form-control" uib-datepicker-popup="yyyy-MM-dd" ng-model="vm.startdate"\n' +
    '               is-open="start.opened" ng-required="true" id="startdate" name="startdate"\n' +
    '               clear-text="Löschen" close-text="Schließen" current-text="Heute" starting-day="1"\n' +
    '               alt-input-formats="vm.altInputFormats"/>\n' +
    '              <span class="input-group-btn">\n' +
    '                <button type="button" class="btn btn-default" ng-click="start.opened = true;"><i\n' +
    '                  class="glyphicon glyphicon-calendar"></i></button>\n' +
    '              </span>\n' +
    '      </p>\n' +
    '    </div>\n' +
    '    <div class="col-md-6">\n' +
    '      <label for="enddate">Bis</label>\n' +
    '      <p class="input-group">\n' +
    '        <input type="text" class="form-control" uib-datepicker-popup="yyyy-MM-dd" ng-model="vm.enddate"\n' +
    '               is-open="end.opened" ng-required="true" id="enddate" name="enddate"\n' +
    '               close-text="Schließen" clear-text="Löschen" current-text="Heute" starting-day="1"\n' +
    '               alt-input-formats="vm.altInputFormats"/>\n' +
    '              <span class="input-group-btn">\n' +
    '                <button type="button" class="btn btn-default" ng-click="end.opened = true;"><i\n' +
    '                  class="glyphicon glyphicon-calendar"></i></button>\n' +
    '              </span>\n' +
    '      </p>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <button class="btn btn-success" ng-click="vm.exportDEP()"><i class="fa fa-download"></i> Exportieren</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</form>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('finance/cashbook/cashbook-component.tpl.html',
    '<h3>Kassabuch</h3>\n' +
    '\n' +
    '<div class="alert alert-info"><h3>Aktueller Bargeldbestand: {{vm.cashbook[0].cashvalue | currency: \'€ \' : 2}}</h3></div>\n' +
    '\n' +
    '<div class="row">\n' +
    '  <div class="col-xs-12 col-md-6">\n' +
    '    <form class="form-vertical">\n' +
    '      <div class="form-group">\n' +
    '        <label for="deposit">Bareinlage</label>\n' +
    '        \n' +
    '        <div class="input-group">\n' +
    '          <input type="number" class="form-control" ng-model="vm.depositvalue" id="deposit" placeholder="Bareinlage">\n' +
    '          <span class="input-group-btn">\n' +
    '          <button class="btn btn-success" type="submit" ng-click="vm.deposit()" uib-tooltip="Bareinlage">\n' +
    '            <i class="fa fa-plus"></i>\n' +
    '          </button>\n' +
    '            </span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '  <div class="col-xs-12 col-md-6">\n' +
    '    <form class="form-vertical">\n' +
    '      <div class="form-group">\n' +
    '        <label for="withdrawal">Barentnahme</label>\n' +
    '        \n' +
    '        <div class="input-group">\n' +
    '          <input type="number" class="form-control" id="withdrawal" ng-model="vm.withdrawalvalue"\n' +
    '                 placeholder="Barentnahme">\n' +
    '          <span class="input-group-btn">\n' +
    '            <button class="btn btn-danger" type="submit" ng-click="vm.withdrawal()" uib-tooltip="Barentnahme">\n' +
    '              <i class="fa fa-minus"></i>\n' +
    '            </button>\n' +
    '          </span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row" ng-hide="vm.cashlog.length === 0">\n' +
    '  <div class="col-xs-12">\n' +
    '    <table class="table table-striped">\n' +
    '      <tr>\n' +
    '        <th>Datum</th>\n' +
    '        <th align="center">Bareinlage</th>\n' +
    '        <th align="center">Barentnahme</th>\n' +
    '        <th align="center">Bareinnahme</th>\n' +
    '        <th align="center">Bareinnahmestorno</th>\n' +
    '      </tr>\n' +
    '      \n' +
    '      <tr ng-repeat="entry in vm.cashlog">\n' +
    '        <td ng-bind="entry.created | datetime"></td>\n' +
    '        <td ng-bind="entry.bareinlagebetrag | currency" align="right"></td>\n' +
    '        <td ng-bind="entry.barentnahmebetrag | currency" align="right"></td>\n' +
    '        <td ng-bind="entry.bareinnahmebetrag | currency" align="right"></td>\n' +
    '        <td ng-bind="entry.bareinnahmestornobetrag | currency" align="right"></td>\n' +
    '      </tr>\n' +
    '    </table>\n' +
    '    \n' +
    '    \n' +
    '    <uib-pagination total-items="vm.totalItems"\n' +
    '                    items-per-page="20"\n' +
    '                    max-size="10"\n' +
    '                    first-text="Erste"\n' +
    '                    last-text="Letzte"\n' +
    '                    next-text="N&auml;chste"\n' +
    '                    previous-text="Vorige"\n' +
    '                    boundary-links="true"\n' +
    '                    rotate="false"\n' +
    '                    ng-model="vm.currentPage"\n' +
    '                    ng-change="vm.pageChanged()"></uib-pagination>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('finance/invoice/invoicebrowser-directive.tpl.html',
    '<h2>Rechnungen</h2>\n' +
    '<!-- Nav tabs -->\n' +
    '<ul class="nav nav-tabs hidden-xs hidden-sm" role="tablist" style="padding-top: 1ex;">\n' +
    '  <li ui-sref="finance.invoices.cashinv" ui-sref-active="active"><a>Barrechnungen</a></li>\n' +
    '  <li ui-sref="finance.invoices.checkinv" ui-sref-active="active"><a>Zielrechnungen</a></li>\n' +
    '  <li ui-sref="finance.invoices.checkpaid" ui-sref-active="active"><a>Bezahlt</a></li>\n' +
    '  <li ui-sref="finance.invoices.checkopen" ui-sref-active="active"><a>Offenen</a></li>\n' +
    '  <li ui-sref="finance.invoices.checkoverdue" ui-sref-active="active"><a>Überfällige</a></li>\n' +
    '</ul>\n' +
    '<!--view for invoice details-->\n' +
    '<div ui-view="invoicedetails"></div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('finance/invoice/invoiceDateTpl.html',
    '<div style="text-align: center">\n' +
    '  <uib-datepicker ng-model="miCtrl.paiddate" class="well well-sm" datepicker-options="miCtrl.dateoptions"></uib-datepicker>\n' +
    '  \n' +
    '  <div  style="margin-bottom: 1em">Zahlung am: {{miCtrl.paiddate | date : \'dd.MM.yyyy\'}}</div>\n' +
    '  <!--<div  style="margin-bottom: 1em">max Date: {{miCtrl.dateoptions.maxDate | date : \'dd.MM.yyyy\'}}</div>-->\n' +
    '  \n' +
    '  <div style="margin-bottom: 1em"><span class="btn btn-primary" ng-click="miCtrl.setDate(miCtrl.invoice)" data-ng-disabled="!miCtrl.paiddate">\n' +
    '          Zahlung registrieren</span>&nbsp;<span class="btn btn-danger" ng-click="miCtrl.dialog.close()">Schliessen</span>\n' +
    '        </div>\n' +
    '</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('finance/invoice/checkinvoice-component.tpl.html',
    '\n' +
    '<h3 ng-bind="vm.title"></h3>\n' +
    '\n' +
    '\n' +
    '<div class="alert alert-info" ng-show="vm.checkinvoices.length === 0">\n' +
    '  <h4>Keine Zielrechnungen mit diesen Merkmalen gefunden</h4>\n' +
    '</div>\n' +
    '\n' +
    '<table class="table table-striped"\n' +
    '       ng-hide="vm.checkinvoices.length === 0">\n' +
    '  <tr>\n' +
    '    <th>RNr</th>\n' +
    '    <th>Datum</th>\n' +
    '    <th>Patient</th>\n' +
    '    <th align="right">Brutto</th>\n' +
    '    <th align="right">Steuer</th>\n' +
    '    <th align="center">PDF</th>\n' +
    '    <th align="center">Beleg</th>\n' +
    '    <th align="center">Aktion</th>\n' +
    '  </tr>\n' +
    '  <tr ng-repeat="inv in vm.checkinvoices"  ng-class="{testinv: inv.test}">\n' +
    '    <td ng-bind="inv.invoicenr">inv.invoicenr</td>\n' +
    '    <td ng-bind="inv.fdate"></td>\n' +
    '    <td><a ui-sref="patientrecord.history({patientid: inv.patientid})"   ng-class="{testinv: inv.test}">{{inv.patientid}}</a></td>\n' +
    '    <td align="right">{{inv.total | currency : \'€\' : 2 }}</td>\n' +
    '    <td align="right">{{inv.tax | currency : \'€\' : 2 }}</td>\n' +
    '    <td align="center">\n' +
    '      <a href="/routes/pdfreader/{{inv.doctorinvoiceid}}" target="_blank">\n' +
    '        <i class="fa fa-file-pdf-o text-primary"></i>\n' +
    '      </a>\n' +
    '    </td>\n' +
    '    <td align="center">\n' +
    '      <a href="/routes/pdfreader/{{inv.receiptid}}" target="_blank">\n' +
    '        <i class="fa fa-file-pdf-o text-success"></i></a>\n' +
    '      <a href="/routes/pdfreader/{{inv.stornoreceiptid}}"\n' +
    '         ng-show="inv.stornoreceiptid"\n' +
    '         target="_blank">\n' +
    '        <i class="fa fa-file-pdf-o text-danger"></i></a>\n' +
    '    </td>\n' +
    '    <td align="center">\n' +
    '      <span ng-show="inv.paid">\n' +
    '        {{inv.paid | bezahlt }}, {{inv.fpaiddate}}\n' +
    '      </span>\n' +
    '      <span class="btn btn-primary btn-sm" ng-hide="inv.paid || inv.storno"\n' +
    '            ng-click="vm.enterPaidDate(inv)"\n' +
    '            uib-tooltip="Zahlungseingang registrieren">Zahlung eintragen</span>\n' +
    '      <span ng-hide="inv.storno || inv.paid" class="btn btn-danger btn-sm" ng-click="vm.storno(inv)">Stornieren</span>\n' +
    '      <span ng-show="inv.storno === true && inv.test === true">test, </span>\n' +
    '      <span ng-show="inv.storno === true">storniert</span>\n' +
    '    </td>\n' +
    '  </tr>\n' +
    '</table>\n' +
    '<uib-pagination total-items="vm.totalItems"\n' +
    '                items-per-page="20"\n' +
    '                max-size="10"\n' +
    '                first-text="Erste"\n' +
    '                last-text="Letzte"\n' +
    '                next-text="N&auml;chste"\n' +
    '                previous-text="Vorige"\n' +
    '                boundary-links="true"\n' +
    '                rotate="false"\n' +
    '                ng-model="vm.currentPage"\n' +
    '                ng-change="vm.pageChanged()"></uib-pagination>\n' +
    '</div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('finance/invoice/cashinvoice-component.tpl.html',
    '\n' +
    '<h3 ng-bind="vm.title"></h3>\n' +
    '\n' +
    '<div class="alert alert-info" ng-show="vm.cashinvoices.length === 0">\n' +
    '  <h4>Keine Barrechnungen mit diesen Merkmalen gefunden</h4>\n' +
    '</div>\n' +
    '\n' +
    '<table class="table table-striped"\n' +
    '       ng-hide="vm.cashinvoices.length === 0">\n' +
    '  <tr>\n' +
    '    <th>RNr</th>\n' +
    '    <th>Datum</th>\n' +
    '    <th>Patient</th>\n' +
    '    <th align="right">Brutto</th>\n' +
    '    <th align="right">Steuer</th>\n' +
    '    <th align="center">PDF</th>\n' +
    '    <th align="center">Beleg</th>\n' +
    '    <th align="center">Aktion</th>\n' +
    '  </tr>\n' +
    '  <tr ng-repeat="inv in vm.cashinvoices"  ng-class="{testinv: inv.test}">\n' +
    '    <td ng-bind="inv.invoicenr">inv.invoicenr</td>\n' +
    '    <td ng-bind="inv.fdate"></td>\n' +
    '    <td><a ui-sref="patientrecord.history({patientid: inv.patientid})"   ng-class="{testinv: inv.test}">{{inv.patientid}}</a></td>\n' +
    '    <td align="right">{{inv.total | currency : \'€\' : 2 }}</td>\n' +
    '    <td align="right">{{inv.tax | currency : \'€\' : 2 }}</td>\n' +
    '    <td align="center">\n' +
    '      <a href="/routes/pdfreader/{{inv.doctorinvoiceid}}" target="_blank">\n' +
    '        <i class="fa fa-file-pdf-o text-primary"></i>\n' +
    '      </a>\n' +
    '    </td>\n' +
    '    <td align="center">\n' +
    '      <a href="/routes/pdfreader/{{inv.receiptid}}" target="_blank">\n' +
    '        <i class="fa fa-file-pdf-o text-success"></i></a>\n' +
    '      <a href="/routes/pdfreader/{{inv.stornoreceiptid}}"\n' +
    '         ng-show="inv.stornoreceiptid"\n' +
    '         target="_blank">\n' +
    '        <i class="fa fa-file-pdf-o text-danger"></i></a>\n' +
    '    </td>\n' +
    '    <td align="center">\n' +
    '      <span ng-hide="inv.storno === true" class="btn btn-danger" ng-click="vm.storno(inv)">Stornieren</span>\n' +
    '      <span ng-show="inv.storno === true && inv.test === true">test, </span>\n' +
    '      <span ng-show="inv.storno === true">storniert</span>\n' +
    '    </td>\n' +
    '  </tr>\n' +
    '</table>\n' +
    '<uib-pagination total-items="vm.totalItems"\n' +
    '                items-per-page="20"\n' +
    '                max-size="10"\n' +
    '                first-text="Erste"\n' +
    '                last-text="Letzte"\n' +
    '                next-text="N&auml;chste"\n' +
    '                previous-text="Vorige"\n' +
    '                boundary-links="true"\n' +
    '                rotate="false"\n' +
    '                ng-model="vm.currentPage"\n' +
    '                ng-change="vm.pageChanged()"></uib-pagination>\n' +
    '</div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('finance/closing/closing-directive.tpl.html',
    '<h3>Monatsabschluß &amp; Datenexport</h3>\n' +
    '<span class="btn btn-primary" ng-click="clCtrl.lastmonth()">Letztes Monat</span>\n' +
    '\n' +
    '\n' +
    '<h3>Datenexport für beliebige Zeiträume</h3>\n' +
    '<form class="form-vertical">\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-6">\n' +
    '      <label for="startdate">Von</label>\n' +
    '\n' +
    '      <p class="input-group">\n' +
    '        <input type="text" class="form-control" uib-datepicker-popup="yyyy-MM-dd" ng-model="clCtrl.startdate"\n' +
    '               is-open="start.opened" ng-required="true" id="startdate" name="startdate"\n' +
    '               clear-text="Löschen" close-text="Schließen" current-text="Heute" starting-day="1"\n' +
    '               alt-input-formats="clCtrl.altInputFormats"/>\n' +
    '              <span class="input-group-btn">\n' +
    '                <button type="button" class="btn btn-default" ng-click="start.opened = true;"><i\n' +
    '                  class="glyphicon glyphicon-calendar"></i></button>\n' +
    '              </span>\n' +
    '      </p>\n' +
    '    </div>\n' +
    '    <div class="col-md-6">\n' +
    '      <label for="enddate">Bis</label>\n' +
    '      <p class="input-group">\n' +
    '        <input type="text" class="form-control" uib-datepicker-popup="yyyy-MM-dd" ng-model="clCtrl.enddate"\n' +
    '               is-open="end.opened" ng-required="true" id="enddate" name="enddate"\n' +
    '               close-text="Schließen" clear-text="Löschen" current-text="Heute" starting-day="1"\n' +
    '               alt-input-formats="clCtrl.altInputFormats"/>\n' +
    '              <span class="input-group-btn">\n' +
    '                <button type="button" class="btn btn-default" ng-click="end.opened = true;"><i\n' +
    '                  class="glyphicon glyphicon-calendar"></i></button>\n' +
    '              </span>\n' +
    '      </p>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <button class="btn btn-success" ng-click="clCtrl.freeinterval()"><i class="fa fa-download"></i> Exportieren</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</form>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('finance/bmfexport/bmfexport-directive.tpl.html',
    '<h3>Datenexport für Finanzamt</h3>\n' +
    '\n' +
    '\n' +
    '<a href="{{beCtrl.thisyearquery}}" class="btn btn-success" >Dieses Jahr</a>\n' +
    '\n' +
    '\n' +
    '<form class="form-vertical">\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-6">\n' +
    '      <label for="startdate">Von</label>\n' +
    '\n' +
    '      <p class="input-group">\n' +
    '        <input type="text" class="form-control" uib-datepicker-popup="yyyy-MM-dd" ng-model="beCtrl.startdate"\n' +
    '               is-open="start.opened" ng-required="true" id="startdate" name="startdate"\n' +
    '               clear-text="Löschen" close-text="Schließen" current-text="Heute" starting-day="1"\n' +
    '               alt-input-formats="beCtrl.altInputFormats"/>\n' +
    '              <span class="input-group-btn">\n' +
    '                <button type="button" class="btn btn-default" ng-click="start.opened = true;"><i\n' +
    '                  class="glyphicon glyphicon-calendar"></i></button>\n' +
    '              </span>\n' +
    '      </p>\n' +
    '    </div>\n' +
    '    <div class="col-md-6">\n' +
    '      <label for="enddate">Bis</label>\n' +
    '      <p class="input-group">\n' +
    '        <input type="text" class="form-control" uib-datepicker-popup="yyyy-MM-dd" ng-model="beCtrl.enddate"\n' +
    '               is-open="end.opened" ng-required="true" id="enddate" name="enddate"\n' +
    '               close-text="Schließen" clear-text="Löschen" current-text="Heute" starting-day="1"\n' +
    '               alt-input-formats="beCtrl.altInputFormats"/>\n' +
    '              <span class="input-group-btn">\n' +
    '                <button type="button" class="btn btn-default" ng-click="end.opened = true;"><i\n' +
    '                  class="glyphicon glyphicon-calendar"></i></button>\n' +
    '              </span>\n' +
    '      </p>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <button class="btn btn-success" ng-click="beCtrl.rangequery()"><i class="fa fa-download"></i> Exportieren</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</form>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('dms/signoff/signoff-all-directive.tpl.html',
    '<h3>Dokumente vidieren <span ng-show="soCtrl.patientid.length > 0">f&uuml;r Patient {{soCtrl.patient.lastname}} {{soCtrl.patient.firstname}}</span>: <span class="label label-danger">{{soCtrl.unsignedScans.length}}</span> unbearbeite Dokumente</h3>\n' +
    '\n' +
    '<div ng-hide="soCtrl.ready" class="alert alert-info">\n' +
    '    <h3><i class="fa fa-info-circle"></i> Scans werden geladen</h3>\n' +
    '</div>\n' +
    '\n' +
    '<div ng-show="soCtrl.nomoredocuments" class="alert alert-info">\n' +
    '    <h3><i class="fa fa-info-circle"></i> Keine Dokumente verfügbar\n' +
    '        <span class="btn btn-primary" ng-click="soCtrl.reloadPdfs()">Nach neuen Dokumenten suchen</span>\n' +
    '    </h3>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '<!-- Menubar -->\n' +
    '<div ng-show="soCtrl.ready">\n' +
    '    <button class="btn btn-primary" ng-click="soCtrl.previousPdf()" uib-tooltip="Vorige PDF Datei bearbeiten"><i class="fa fa-arrow-left"></i> PDF</button>\n' +
    '    <button class="btn btn-info" ng-click="soCtrl.zoomIn()" uib-tooltip="Hineinzoomen"><i class="fa fa-search-plus"></i></button>\n' +
    '    <button class="btn btn-info" ng-click="soCtrl.zoomOut()" uib-tooltip="Herauszoomen"><i class="fa fa-search-minus"></i></button>\n' +
    '    <button class="btn btn-info" ng-click="soCtrl.rotatePage()" uib-tooltip="Dokument drehen"><i class="fa fa-rotate-right"></i></button>\n' +
    '    <button class="btn btn-info" ng-click="soCtrl.previousPage()" uib-tooltip="Zurückblättern"><i class="fa fa-arrow-left"></i></button>\n' +
    '    <!--  Seite: {{soCtrl.currentpage}} / {{soCtrl.maxpc}}  -->\n' +
    '    <form style="display: inline" class="form-horizontal">\n' +
    '        <label for="pagenr"> Seite: </label>\n' +
    '        <input type="number" class="form-control"\n' +
    '               style="display: inline; width: 4em"\n' +
    '               width="5em" name="pagenr" id="pagenr"\n' +
    '               ng-change="soCtrl.gotoPage()"\n' +
    '               ng-model="soCtrl.currentpage">\n' +
    '        <label>/{{soCtrl.maxpc}}</label></form>\n' +
    '    <button class="btn btn-info" ng-click="soCtrl.nextPage()" uib-tooltip="Vorblättern"><i class="fa fa-arrow-right"></i></button>\n' +
    '    <a class="btn btn-info" href="/routes/files/filereader/{{soCtrl.unsignedScans[soCtrl.pdf].fileid}}"\n' +
    '       target="blank" uib-tooltip="PDF in neuem Fenster öffnen"><i class="fa fa-eye"></i></a>\n' +
    '    <button class="btn btn-primary" ng-click="soCtrl.reloadPdfs()" uib-tooltip="PDFs neu laden">PDF <i class="fa fa-undo"></i></button>\n' +
    '    <button class="btn btn-primary" ng-click="soCtrl.nextPdf()" uib-tooltip="Nächste PDF Datei bearbeiten">PDF <i class="fa fa-arrow-right"></i></button>\n' +
    '</div>\n' +
    '\n' +
    '<!-- DMS Actions -->\n' +
    '<div  ng-show="soCtrl.ready && !soCtrl.nomoredocument">\n' +
    '<h4>Patient: <a ng-click="soCtrl.$state.go(\'patientrecord.history\',{patientid: soCtrl.patient._id})">\n' +
    '  {{soCtrl.unsignedScans[soCtrl.pdf].patientfname}}<span ng-show="soCtrl.patient.phone"> , {{soCtrl.patient.phone}}</span><span ng-show="soCtrl.patient.email"> , {{soCtrl.patient.email}}</span></a>\n' +
    '</h4>\n' +
    '\n' +
    '    <!-- keywords -->\n' +
    '    <form name="signatureform">\n' +
    '        <div class="row" ng-show="soCtrl.unsignedScans[soCtrl.pdf].doccat">\n' +
    '        <div class="form-group col-xs-12">\n' +
    '            <label for="dockeywords">Stichworte</label>\n' +
    '            <tags-input ng-model="soCtrl.unsignedScans[soCtrl.pdf].keywords"\n' +
    '                        display-property="text"\n' +
    '                        id="dockeywords"\n' +
    '                        add-on-paste="true"\n' +
    '                        add-on-comma="false"\n' +
    '                        min-length="1"\n' +
    '                        add-from-autocomplete-only="false"\n' +
    '                        enable-editing-last-tag="true"\n' +
    '                        replace-spaces-with-dashes="false"\n' +
    '                        template="tag-template">\n' +
    '                <auto-complete source="soCtrl.getTags($query)"\n' +
    '                               min-length="1"\n' +
    '                               load-on-focus="true"\n' +
    '                               load-on-empty="true"\n' +
    '                               load-on-down-arrow="true"\n' +
    '                               max-results-to-show="32"\n' +
    '                               select-first-match="false"\n' +
    '                               template="autocomplete-template">\n' +
    '                </auto-complete>\n' +
    '\n' +
    '            </tags-input>\n' +
    '        </div>\n' +
    '            </div>\n' +
    '    <div class="row" ng-show="soCtrl.unsignedScans[soCtrl.pdf].doccat">\n' +
    '        <!-- Logging -->\n' +
    '        <div class="form-group col-xs-3">\n' +
    '            <label for="dockeywords">Logging</label>\n' +
    '            <button type="button" ng-click="soCtrl.patientlogger()" uib-tooltip="Logeintrag für Patienten erstellen"\n' +
    '                    class="btn btn-success form-control"><i class="fa fa-plus"></i> Logeintrag</button>\n' +
    '        </div>\n' +
    '        <!-- Todo -->\n' +
    '        <div class="form-group col-xs-3">\n' +
    '            <label for="dockeywords">Patientenerinnerung</label>\n' +
    '            <button type="button" ng-click="soCtrl.patienttodo()" uib-tooltip="Todo für Patienten erstellen"\n' +
    '                    class="btn btn-success form-control"><i class="fa fa-plus"></i> Todo</button>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="form-group col-xs-6">\n' +
    '            <label for="dockeywords">Signatur</label>\n' +
    '            <div class="input-group">\n' +
    '                <input type="text" class="form-control" required\n' +
    '                       ng-model="soCtrl.unsignedScans[soCtrl.pdf].signature">\n' +
    '                <span class="input-group-btn">\n' +
    '                    <input type="submit" class="btn btn-success"\n' +
    '                           ng-click="soCtrl.closePdf()"\n' +
    '                           value="Abschließen"\n' +
    '                           ng-disabled="signatureform.$invalid"\n' +
    '                           uib-tooltip="Dieses Dokument abschließen">\n' +
    '                </span>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</form>\n' +
    '\n' +
    '<!-- PDF Viewer -->\n' +
    '<pdf-viewer ng-show="soCtrl.ready && !soCtrl.nomoredocuments"\n' +
    '            delegate-handle="unsigned-pdf-container"\n' +
    '            url="pdfUrl"\n' +
    '            scale="1"\n' +
    '            show-toolbar="false"></pdf-viewer>\n' +
    '\n' +
    '\n' +
    '</div>\n' +
    '<script type="text/ng-template" id="tag-template">\n' +
    '    <div class="tag-template">\n' +
    '        <div class="right-panel">\n' +
    '            <span>{{$getDisplayText()}}</span>\n' +
    '            <a class="remove-button" ng-click="$removeTag()">&#10006;</a>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</script>\n' +
    '\n' +
    '\n' +
    '<script type="text/ng-template" id="autocomplete-template">\n' +
    '    <div class="autocomplete-template">\n' +
    '        <div>\n' +
    '            <span ng-bind-html="$highlight($getDisplayText())">{{text}}</span>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</script>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('dms/signoff/sign-counter-directive.tpl.html',
    '<div>{{signCounter.name}}</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('dms/scan/scan.tpl.html',
    '<h2>scan</h2>\n' +
    '<p>{{scan.ctrlName}}</p>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('dms/scan/scan-sorter-directive.tpl.html',
    '<h3>Dokumente zuordnen: <span class="label label-danger">{{scanCtrl.scans.length}}</span> unbearbeite Dokumente</h3>\n' +
    '\n' +
    '<div ng-hide="scanCtrl.ready" class="alert alert-info">\n' +
    '    <h3><i class="fa fa-info-circle"></i> Scans werden geladen</h3>\n' +
    '</div>\n' +
    '\n' +
    '<div ng-show="scanCtrl.nomoredocuments" class="alert alert-info">\n' +
    '        <h3><i class="fa fa-info-circle"></i> Keine Dokumente verfügbar\n' +
    '            <span class="btn btn-primary" ng-click="scanCtrl.reloadPdfs()">Nach neuen Dokumenten suchen</span>\n' +
    '        </h3>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '<!-- Menubar -->\n' +
    '<div>\n' +
    '            <button class="btn btn-primary" ng-click="scanCtrl.previousPdf()" uib-tooltip="Vorige PDF Datei bearbeiten"><i class="fa fa-arrow-left"></i> PDF</button>\n' +
    '    <button class="btn btn-info" ng-click="scanCtrl.zoomIn()" uib-tooltip="Hineinzoomen"><i class="fa fa-search-plus"></i></button>\n' +
    '    <button class="btn btn-info" ng-click="scanCtrl.zoomOut()" uib-tooltip="Herauszoomen"><i class="fa fa-search-minus"></i></button>\n' +
    '    <button class="btn btn-info" ng-click="scanCtrl.rotatePage()" uib-tooltip="Dokument drehen"><i class="fa fa-rotate-right"></i></button>\n' +
    '    <button class="btn btn-info" ng-click="scanCtrl.previousPage()" uib-tooltip="Zurückblättern"><i class="fa fa-arrow-left"></i></button>\n' +
    '    <!--  Seite: {{scanCtrl.currentpage}} / {{scanCtrl.maxpc}}  -->\n' +
    '    <form style="display: inline" class="form-horizontal">\n' +
    '        <label for="pagenr"> Seite: </label>\n' +
    '        <input type="number" class="form-control"\n' +
    '               style="display: inline; width: 4em"\n' +
    '               width="5em" name="pagenr" id="pagenr"\n' +
    '               ng-change="scanCtrl.gotoPage()"\n' +
    '               ng-model="scanCtrl.currentpage">\n' +
    '        <label>/{{scanCtrl.maxpc}}</label></form>\n' +
    '    <button class="btn btn-info" ng-click="scanCtrl.nextPage()" uib-tooltip="Vorblättern"><i class="fa fa-arrow-right"></i></button>\n' +
    '    <button class="btn btn-success" ng-click="scanCtrl.scans[scanCtrl.pdf].doccat = \'patient\'"\n' +
    '            uib-tooltip="Dokument Patient zuordnen">\n' +
    '        <i class="fa fa-arrow-down"></i><i class="fa fa-user"></i>\n' +
    '    </button>\n' +
    '    <button class="btn btn-success" ng-click="scanCtrl.scans[scanCtrl.pdf].doccat = \'generic\'"\n' +
    '            uib-tooltip="Allgemeines Dokument zuordnen">\n' +
    '        <i class="fa fa-arrow-down"></i><i class="fa fa-book"></i>\n' +
    '    </button>\n' +
    '    <button class="btn btn-primary" ng-click="scanCtrl.reloadPdfs()" uib-tooltip="PDFs neu laden">PDF <i class="fa fa-undo"></i></button>\n' +
    '    <button class="btn btn-primary" ng-click="scanCtrl.nextPdf()" uib-tooltip="Nächste PDF Datei bearbeiten">PDF <i class="fa fa-arrow-right"></i></button>\n' +
    '</div>\n' +
    '\n' +
    '<!-- DMS Actions -->\n' +
    '<form class="form-vertical spacer-top">\n' +
    '    <!-- patient docs -->\n' +
    '    <div class="row" ng-show="scanCtrl.scans[scanCtrl.pdf].doccat === \'patient\'">\n' +
    '        <!-- Assign Patient -->\n' +
    '        <div class="form-group col-xs-12 col-md-6" ng-hide="scanCtrl.scans[scanCtrl.pdf].patientid">\n' +
    '            <label for="scanpatient">Patient</label>\n' +
    '            <div  class="input-group">\n' +
    '                <input type="text" class="form-control"\n' +
    '                       id="scanpatient"\n' +
    '                       placeholder="Patient auswählen ..."\n' +
    '                       ng-model="scanCtrl.asyncSelected"\n' +
    '                       typeahead-on-select="$parent.$dismiss();scanCtrl.assignpatient($item, $model, $label)"\n' +
    '                       uib-typeahead="pat as pat.text for pat in scanCtrl.getNames($viewValue)"\n' +
    '                       typeahead-loading="loadingNames"\n' +
    '                       typeahead-no-results="noResults">\n' +
    '                <span class="input-group-btn">\n' +
    '                    <button type="submit" class="btn btn-success"><i class="fa fa-search"></i></button>\n' +
    '                </span>\n' +
    '                <i ng-show="loadingNames" class="glyphicon glyphicon-refresh"></i>\n' +
    '\n' +
    '                <div ng-show="noResults">\n' +
    '                    <i class="glyphicon glyphicon-remove"></i> Keine Patienten gefunden\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="form-group col-xs-12 col-md-6" ng-show="scanCtrl.scans[scanCtrl.pdf].patientid">\n' +
    '            <label for="scanpatientn">Patient</label>\n' +
    '            <div class="input-group">\n' +
    '                <input type="text" class="form-control"\n' +
    '                       id="scanpatientn"\n' +
    '                       readonly\n' +
    '                       ng-model="scanCtrl.scans[scanCtrl.pdf].patientfname">\n' +
    '                <span class="input-group-btn">\n' +
    '                    <button class="btn btn-danger"\n' +
    '                        ng-click="scanCtrl.clearPatient()"><i class="fa fa-remove"></i>\n' +
    '                    </button>\n' +
    '                </span>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <!-- PatientDokument type -->\n' +
    '        <div class="col-xs-12 col-md-6">\n' +
    '            <div class="form-group">\n' +
    '                <label for="patdoctype">Patienten-Dokumenttyp</label>\n' +
    '                <select id="patdoctype" ng-model="scanCtrl.scans[scanCtrl.pdf].type" class="form-control">\n' +
    '                    <option ng-repeat="v in scanCtrl.conf.dmspatientcat" value="{{v}}">{{v}}</option>\n' +
    '                </select>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '<!-- generic docs -->\n' +
    '    <div class="row" ng-show="scanCtrl.scans[scanCtrl.pdf].doccat === \'generic\'">\n' +
    '        <div class="col-xs-12">\n' +
    '            <div class="form-group">\n' +
    '                <label for="gendoctype">Allgemeiner Dokumenttyp</label>\n' +
    '                <select id="gendoctype" ng-model="scanCtrl.scans[scanCtrl.pdf].type" class="form-control">\n' +
    '                    <option ng-repeat="v in scanCtrl.conf.dmsgencat"  value="{{v}}" >{{v}}</option>\n' +
    '                </select>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- keywords -->\n' +
    '    <div class="row" ng-show="scanCtrl.scans[scanCtrl.pdf].doccat">\n' +
    '        <div class="form-group col-xs-10">\n' +
    '            <label for="dockeywords">Stichworte</label>\n' +
    '            <tags-input ng-model="scanCtrl.scans[scanCtrl.pdf].keywords"\n' +
    '                        display-property="text"\n' +
    '                        id="dockeywords"\n' +
    '                        add-on-paste="true"\n' +
    '                        add-on-comma="false"\n' +
    '                        min-length="1"\n' +
    '                        enable-editing-last-tag="true"\n' +
    '                        add-from-autocomplete-only="false"\n' +
    '                        replace-spaces-with-dashes="false"\n' +
    '                        template="tag-template">\n' +
    '\n' +
    '                <auto-complete source="scanCtrl.getTags($query)"\n' +
    '                               min-length="1"\n' +
    '                               load-on-focus="true"\n' +
    '                               load-on-empty="false"\n' +
    '                               load-on-down-arrow="true"\n' +
    '                               max-results-to-show="32"\n' +
    '                               select-first-match="false"\n' +
    '                               template="autocomplete-template">\n' +
    '                </auto-complete>\n' +
    '\n' +
    '            </tags-input>\n' +
    '        </div>\n' +
    '        <div class="form-group col-xs-2">\n' +
    '            <label for="dockeywords">&nbsp;</label>\n' +
    '            <button class="btn btn-success" ng-click="scanCtrl.closePdf()" uib-tooltip="Dieses Dokument abschließen"\n' +
    '            ng-disabled="scanCtrl.formDisabled(scanCtrl.scans[scanCtrl.pdf])">\n' +
    '                Abschließen\n' +
    '            </button>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</form>\n' +
    '\n' +
    '<!-- PDF Viewer -->\n' +
    '<pdf-viewer ng-show="scanCtrl.ready && !scanCtrl.nomoredocuments"\n' +
    '            delegate-handle="scans-pdf-container"\n' +
    '            url="pdfUrl"\n' +
    '            scale="1"\n' +
    '            show-toolbar="false"></pdf-viewer>\n' +
    '\n' +
    '\n' +
    '<!-- Tag autocomplete templates -->\n' +
    '<script type="text/ng-template" id="tag-template">\n' +
    '    <div class="tag-template">\n' +
    '        <div class="right-panel">\n' +
    '            <span>{{$getDisplayText()}}</span>\n' +
    '            <a class="remove-button" ng-click="$removeTag()">&#10006;</a>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</script>\n' +
    '\n' +
    '<script type="text/ng-template" id="autocomplete-template">\n' +
    '    <div class="autocomplete-template">\n' +
    '        <div>\n' +
    '            <span ng-bind-html="$highlight($getDisplayText())">{{text}}</span>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</script>\n' +
    '\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('waitinglist/waitinglists-directive.tpl.html',
    '<div>{{waitinglists.name}}</div>\n' +
    '\n' +
    '<div class="panel-group">\n' +
    '<div ng-repeat="list in wlCtrl.waitinglists" class="panel panel-default">\n' +
    '  <div class="panel-heading"><h4>{{list.name}}</h4></div>\n' +
    '  <div class="panel-body" as-sortable="wlCtrl.sortableOptions" ng-model="list.patients">\n' +
    '    <div class="alert alert-info" ng-repeat="pat in list.patients" as-sortable-item style="font-size: larger">\n' +
    '      <span as-sortable-item-handle>{{pat.lastname}} {{pat.firstname}}</span>\n' +
    '      <span class="pull-right">\n' +
    '        <button type="button" class="btn btn-success" uib-tooltip="Patient aufrufen" ng-click="">\n' +
    '          <i class="fa fa-bullhorn"></i>\n' +
    '        </button>\n' +
    '        <button type="button" class="btn btn-primary" uib-tooltip="Patientenakte" ng-click="">\n' +
    '          <i class="fa fa-folder"></i>\n' +
    '        </button>\n' +
    '        <button type="button" class="btn btn-danger" uib-tooltip="von Warteliste löschen" ng-click="">\n' +
    '          <i class="fa fa-remove"></i>\n' +
    '        </button>\n' +
    '      </span>\n' +
    '    </div>\n' +
    '    <div style="min-height: 40px">\n' +
    '      &nbsp;\n' +
    '      <!-- Comfort whitespace -->\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '\n' +
    '<!--\n' +
    '<ul dnd-list="list.people"\n' +
    '    dnd-allowed-types="list.allowedTypes"\n' +
    '    dnd-disable-if="list.people.length >= list.max">\n' +
    '  \n' +
    '  <li ng-repeat="person in list.people"\n' +
    '      dnd-draggable="person"\n' +
    '      dnd-type="person.type"\n' +
    '      dnd-disable-if="person.type == \'unknown\'"\n' +
    '      dnd-moved="list.people.splice($index, 1)"\n' +
    '      dnd-effect-allowed="move"\n' +
    '      class="background-{{person.type}}"\n' +
    '  >\n' +
    '    <dnd-nodrag>\n' +
    '      <div dnd-handle class="handle">:::</div>\n' +
    '      <div class="name">\n' +
    '        <input type="text" ng-model="person.name" class="background-{{person.type}} form-control input-sm">\n' +
    '      </div>\n' +
    '    </dnd-nodrag>\n' +
    '  </li>\n' +
    '  \n' +
    '  <li class="dndPlaceholder">\n' +
    '    Drop any <strong>{{list.allowedTypes.join(\' or \')}}</strong> here\n' +
    '  </li>\n' +
    '\n' +
    '</ul>\n' +
    '-->\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('waitinglist/waitinglist.tpl.html',
    '<h2>waitinglist</h2>\n' +
    '<p>{{waitinglist.ctrlName}}</p>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('waitinglist/configurewl-directive.tpl.html',
    '<h3>Wartelisten</h3>\n' +
    '\n' +
    '<div class="alert alert-info" ng-hide="cwlCtrl.waitinglists.length > 0">Es wurden noch keine Wartelisten angelegt</div>\n' +
    '\n' +
    '<div ng-repeat="a in cwlCtrl.waitinglists">\n' +
    '  <div class="input-group">\n' +
    '    <input type="text" class="form-control" ng-model="a.name" ng-blur="a.$save()">\n' +
    '    <span class="input-group-btn">\n' +
    '        <button class="btn btn-danger" type="button" ng-click="cwlCtrl.splice($index, a)"><i class="fa fa-remove"></i></button>\n' +
    '      </span>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<ng-form name="cwlCtrl.formid">\n' +
    '  <div class="input-group">\n' +
    '    <input type="text" class="form-control" ng-model="cwlCtrl.newlist" placeholder="Neue Warteliste..." ng-keyup="$event.keyCode == 13 ? cwlCtrl.add() : null">\n' +
    '    <span class="input-group-btn">\n' +
    '        <button class="btn btn-success" type="button" ng-click="cwlCtrl.add()"><i class="fa fa-plus"></i></button>\n' +
    '      </span>\n' +
    '  </div>\n' +
    '</ng-form>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('utils/utils.tpl.html',
    '<h2>utils</h2>\n' +
    '<p>{{utils.ctrlName}}</p>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('user/user.tpl.html',
    '<h2>user</h2>\n' +
    '<p>{{user.ctrlName}}</p>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('user/adduser-directive.tpl.html',
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <h3 class="panel-title">Neuer Anwender</h3>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <form>\n' +
    '      <div>\n' +
    '        <input type="checkbox" ng-model="user.newuser.active"> Account aktiv\n' +
    '      </div>\n' +
    '      <div class="form-group">\n' +
    '        <label for="username">Username</label>\n' +
    '        <input type="text" ng-model="uCtrl.newuser.username" class="form-control" id="username" placeholder="Username">\n' +
    '      </div>\n' +
    '      <!--<div class="form-group">\n' +
    '        <label for="formattedname">Vollständiger (formatierter) Name</label>\n' +
    '        <input type="text" ng-model="uCtrl.newuser.formattedname" class="form-control" id="formattedname"\n' +
    '               placeholder="Voller Name">\n' +
    '      </div>-->\n' +
    '      <div class="form-group">\n' +
    '        <label for="password">Passwort</label>\n' +
    '        <input type="password" ng-model="uCtrl.newuser.password" class="form-control" id="password"\n' +
    '               placeholder="Voller Name">\n' +
    '      </div>\n' +
    '      <div class="input-group">\n' +
    '        <span class="input-group-btn">\n' +
    '	        <button class="btn btn-default" type="button" ng-click="uCtrl.add()">Neuen Anwender anlegen</button>\n' +
    '        </span>\n' +
    '      </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '  <!-- panelbody -->\n' +
    '</div><!-- panel -->\n' +
    '\n' +
    '\n' +
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <h3 class="panel-title">Alle Anwender</h3>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <table class="table table-striped">\n' +
    '      <thead>\n' +
    '      <tr>\n' +
    '        <th>ID</th>\n' +
    '        <!--<th>Aktiv</th>-->\n' +
    '        <th>Benutzername</th>\n' +
    '        <!--<th>Vollständiger Name</th>-->\n' +
    '        <!--<th>Aktion</th>-->\n' +
    '      </tr>\n' +
    '      </thead>\n' +
    '      <tbody>\n' +
    '      <tr ng-repeat="u in uCtrl.users">\n' +
    '        <td>\n' +
    '          uid: {{u._id}}\n' +
    '        </td>\n' +
    '        <!--<td>\n' +
    '          <input type="checkbox" ng-model="u.active">\n' +
    '        </td>-->\n' +
    '        <td>\n' +
    '          <input type="text" ng-model="u.username">\n' +
    '        </td>\n' +
    '        <!--<td>\n' +
    '          <input type="text" ng-model="u.formattedname">\n' +
    '        </td>-->\n' +
    '        <td>\n' +
    '          <!--<button class="btn btn-default" type="button" ng-click="u.$save()">Ändern</button>-->\n' +
    '          <!--<button class="btn btn-default" type="button" ng-click="u.active=false;u.$save()">Löschen</button>-->\n' +
    '        </td>\n' +
    '      </tr>\n' +
    '  </div>\n' +
    '  <!-- panelbody -->\n' +
    '</div><!-- panel -->\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('unicatalog/unicatalog.tpl.html',
    '<h2>unicatalog</h2>\n' +
    '<p>{{unicatalog.ctrlName}}</p>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('unicatalog/item-list-directive.tpl.html',
    '<div>{{itemList.name}}</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('unicatalog/item-editor-directive.tpl.html',
    '<h3>Leistung editieren</h3>\n' +
    '\n' +
    '<form class="form-vertical">\n' +
    '    <div class="row">\n' +
    '        <div class="col-md-12">\n' +
    '            <label>Displayname</label>\n' +
    '            <input class="form-control" type="text" ng-model="ieCtrl.item.displayname">\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '        <div class="col-md-12">\n' +
    '            <label>Canonical Name</label>\n' +
    '            <input class="form-control" type="text" ng-model="ieCtrl.item.cname">\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '        <div class="col-md-12">\n' +
    '            <label>Beschreibung</label>\n' +
    '            <input class="form-control" type="text" ng-model="ieCtrl.item.description">\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '        <div class="col-md-4">\n' +
    '            <label>AEK Nummer</label>\n' +
    '            <input class="form-control" type="text" ng-model="ieCtrl.item.aeknum">\n' +
    '        </div>\n' +
    '        <div class="col-md-4">\n' +
    '            <label>AEK Punkte</label>\n' +
    '            <input class="form-control" type="text" ng-model="ieCtrl.item.aekpoints">\n' +
    '        </div>\n' +
    '        <div class="col-md-4">\n' +
    '            <label>AEK Preisempfehlung</label>\n' +
    '            <input class="form-control" type="text" ng-model="ieCtrl.item.aekprice">\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <uib-accordion close-others="true">\n' +
    '        <uib-accordion-group heading="{{sv}}" ng-repeat="sv in Object.keys(svdata)">\n' +
    '            <div class="form-group">\n' +
    '                <div class="row">\n' +
    '                    <div class="col-md-6">\n' +
    '                        <label>Codes 1</label>\n' +
    '                        <array-editor array="ieCtrl.item.svdata[sv].codes1"></array-editor>\n' +
    '                    </div>\n' +
    '                    <div class="col-md-6">\n' +
    '                        <label>Codes 2</label>\n' +
    '                        <array-editor array="ieCtrl.item.svdata[sv].codes2"></array-editor>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="row">\n' +
    '                    <div class="col-md-3">\n' +
    '                        <label>Primärcode</label>\n' +
    '                        <input class="form-control" type="text" ng-model="ieCtrl.item.svdata[sv].primarycode">\n' +
    '                    </div>\n' +
    '                    <div class="col-md-3">\n' +
    '                        <label>Punkte</label>\n' +
    '                        <input class="form-control" type="text" ng-model="ieCtrl.item.svdata[sv].points">\n' +
    '                    </div>\n' +
    '                    <div class="col-md-3">\n' +
    '                        <label>Pkt-Kat</label>\n' +
    '                        <input class="form-control" type="text" ng-model="ieCtrl.item.svdata[sv].pointcat">\n' +
    '                    </div>\n' +
    '                    <div class="col-md-3">\n' +
    '                        <label>Preis</label>\n' +
    '                        <input class="form-control" type="text" ng-model="ieCtrl.item.svdata[sv].price">\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="row">\n' +
    '                    <div class="col-md-6">\n' +
    '                        <label>Konflikte (intern)</label>\n' +
    '                        <array-editor array="ieCtrl.item.svdata[sv].conflictssv"></array-editor>\n' +
    '                    </div>\n' +
    '                    <div class="col-md-6">\n' +
    '                        <label>Konflikte (uni)</label>\n' +
    '                        <array-editor array="ieCtrl.item.svdata[sv].conflictsuni"></array-editor>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="row">\n' +
    '                    <div class="col-md-12">\n' +
    '                        <label>Konditionen</label>\n' +
    '                        <textarea class="form-control" ng-model="ieCtrl.item.svdata[sv].conditions"></textarea>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="row">\n' +
    '                    <div class="col-md-6">\n' +
    '                        <label>Konflikte (intern)</label>\n' +
    '                        <array-editor array="ieCtrl.item.svdata[sv].conflictssv"></array-editor>\n' +
    '                    </div>\n' +
    '                    <div class="col-md-6">\n' +
    '                        <label>Konflikte (uni)</label>\n' +
    '                        <array-editor array="ieCtrl.item.svdata[sv].conflictsuni"></array-editor>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="row">\n' +
    '                    <div class="col-md-6">\n' +
    '                        <label>Wiederholung</label>\n' +
    '                        <input type="checkbox" ng-model="ieCtrl.item.svdata[sv].repeatable"> Wiederholbar\n' +
    '                    </div>\n' +
    '                    <div class="col-md-6">\n' +
    '                        <label>Limit</label>\n' +
    '                        <input class="form-control" type="text" ng-model="ieCtrl.item.svdata[sv].price">\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </uib-accordion-group>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '</form>\n' +
    '\n' +
    '\n' +
    '\n' +
    '<!--\n' +
    '\n' +
    '\n' +
    'svdata:\n' +
    'wggk: {\n' +
    '              codes1: [],\n' +
    '              codes2: [],\n' +
    '              primarycode: \'\',\n' +
    '              points: 0,\n' +
    '              pointcat: \'\',\n' +
    '              price: 0,\n' +
    '              conflictssv: [],\n' +
    '              conflictsuni: [],\n' +
    '              conditions: [],\n' +
    '              repeatable: false,\n' +
    '              repeatlimit: 0\n' +
    '            },\n' +
    '            -->');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('unicatalog/catalog-editor-directive.tpl.html',
    '<!-- Template Editor Modal -->\n' +
    '<script type="text/ng-template" id="tepledittpl">\n' +
    '  <div>\n' +
    '    <div class="modal-header">\n' +
    '      <h3 class="modal-title">Untersuchungsvorlage editieren</h3>\n' +
    '    </div>\n' +
    '    <div class="modal-body">\n' +
    '      <form class="form-vertical">\n' +
    '        <div class="form-group myform">\n' +
    '          <label>Kürzel/Typ\n' +
    '            <input type="text" ng-model="tpeCtrl.tpl.template.type" class="form-control"\n' +
    '                   ng-blur="tpeCtrl.tpl.$save()" placeholder="Kürzel/Typ"></label>\n' +
    '        </div>\n' +
    '        <div class="form-group myform">\n' +
    '          <label>Vollständige Bezeichnung\n' +
    '            <input type="text" ng-model="tpeCtrl.tpl.template.displayname" class="form-control"\n' +
    '                   ng-blur="tpeCtrl.tpl.$save()" placeholder="Vollständige Bezeichnung"></label>\n' +
    '        </div>\n' +
    '        <div class="form-group">\n' +
    '          <label>Befundinhalte in Gesamtbefund inkludieren\n' +
    '            <div class="radio">\n' +
    '              <label for="tinc1" class="radio-inline"><input type="radio" ng-value="true"  ng-model="tpeCtrl.tpl.template.includefindings" id="tinc1">Ja</label>\n' +
    '              <label for="tinc2" class="radio-inline"><input type="radio" ng-value="false" ng-model="tpeCtrl.tpl.template.includefindings" id="tinc2">Nein</label>\n' +
    '            </div>\n' +
    '          </label>\n' +
    '        </div>\n' +
    '        <div class="form-group">\n' +
    '          <label>Bilder/Dateien von Netzwerklaufwerk einbeziehen\n' +
    '            <div class="radio">\n' +
    '              <label for="tnet1" class="radio-inline"><input type="radio" ng-value="true"  ng-model="tpeCtrl.tpl.template.loadfiles" id="tnet1">Ja</label>\n' +
    '              <label for="tnet2" class="radio-inline"><input type="radio" ng-value="false" ng-model="tpeCtrl.tpl.template.loadfiles" id="tnet2">Nein</label>\n' +
    '            </div>\n' +
    '          </label>\n' +
    '        </div>\n' +
    '        <div class="form-group" ng-show="tpeCtrl.tpl.template.loadfiles === true">\n' +
    '          <label for="tname">Netzwerklaufwerk</label>\n' +
    '          <select ng-model="tpeCtrl.tpl.template.shareid" class="form-control" id="tname">\n' +
    '            <option ng-repeat="share in tpeCtrl.allshares" value="{{share._id}}">{{share.name}}</option>\n' +
    '          </select>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <div>\n' +
    '          <h4>Feldgruppen</h4>\n' +
    '          <a class="btn btn-success btn-xs"\n' +
    '             ng-click="tpeCtrl.newFieldGroup()"\n' +
    '             style="margin-right: 8px;">\n' +
    '            <span class="fa fa-plus"> Neue Feldgruppe</span>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="clear: both"></div>\n' +
    '\n' +
    '        <div ui-tree class="mtree">\n' +
    '          <ol ui-tree-nodes="" ng-model="tpeCtrl.tpl.template.fieldgroups">\n' +
    '            <li ui-tree-node ng-repeat="fieldgroup in tpeCtrl.tpl.template.fieldgroups">\n' +
    '              <div ui-tree-handle class="tree-node tree-node-content">\n' +
    '                <a class="btn btn-success btn-xs"\n' +
    '                   ng-if="fieldgroup.fields && fieldgroup.fields.length > 0"\n' +
    '                   data-nodrag ng-click="toggle(this)">\n' +
    '                  <span class="glyphicon"\n' +
    '                        ng-class="{\n' +
    '                        \'glyphicon-chevron-right\': collapsed,\n' +
    '                        \'glyphicon-chevron-down\': !collapsed\n' +
    '                      }">\n' +
    '                  </span>\n' +
    '                </a>\n' +
    '                <span ng-hide="fieldgroup.label == \'\'">{{fieldgroup.label}}</span>\n' +
    '                <span ng-show="fieldgroup.label == \'\'">Felder ohne Gruppe</span>\n' +
    '                <input type="text"\n' +
    '                       ng-model-options="{ updateOn: \'default blur\', debounce: { default: 500, blur: 0 } }"\n' +
    '                       class="form-control small-input"\n' +
    '                       ng-model="fieldgroup.label"/>\n' +
    '                <!--\n' +
    '                <span ng-hide="fieldgroup.label == \'\'">{{fieldgroup.label}} <input type="text"\n' +
    '                                                                                   ng-model-options="{ updateOn: \'blur default\' }"\n' +
    '                                                                                   class="form-control small-input"\n' +
    '                                                                                   ng-model="fieldgroup.label"/></span>\n' +
    '                <span ng-show="fieldgroup.label == \'\'">Felder ohne Gruppe\n' +
    '                  <input type="text" class="form-control small-input" ng-model="fieldgroup.label" ng-model-options="{ updateOn: \'blur\' }\n' +
    '                         uib-tooltip="Leer lassen damit Felder direkt ohne Feldgruppe erscheinen"/></span>\n' +
    '                -->\n' +
    '                <a class="pull-right btn btn-danger btn-xs"\n' +
    '                   uib-tooltip="Feldgruppe Löschen"\n' +
    '                   data-nodrag ng-click="tpeCtrl.remove(this)">\n' +
    '                  <span class="fa fa-remove"></span>\n' +
    '                </a>\n' +
    '                <a class="pull-right btn btn-success btn-xs"\n' +
    '                   uib-tooltip="Neues Feld in diese Gruppe einfügen"\n' +
    '                   data-nodrag ng-click="tpeCtrl.newSubItem(this)"\n' +
    '                   style="margin-right: 8px;">\n' +
    '                  <span class="fa fa-plus"></span>\n' +
    '                </a>\n' +
    '\n' +
    '              </div>\n' +
    '              <ol ui-tree-nodes="" ng-model="fieldgroup.fields" ng-class="{\'hidden\': !collapsed}">\n' +
    '                <li ui-tree-node\n' +
    '                    ng-repeat="field in fieldgroup.fields"\n' +
    '                    ng-include="\'field_renderer.html\'">\n' +
    '                  <div ui-tree-handle>\n' +
    '                    {{field.label}}\n' +
    '                  </div>\n' +
    '              </ol>\n' +
    '            </li>\n' +
    '            <!-- ui-tree-node -->\n' +
    '          </ol>\n' +
    '          <!-- ui-tree-nodes -->\n' +
    '        </div>\n' +
    '        <!-- ui-tree -->\n' +
    '\n' +
    '\n' +
    '        <h4>Zusatzfelder</h4>\n' +
    '        <!-- pseduo tree for named fields -->\n' +
    '        <div ui-tree data-nodrop-enabled="true" data-drag-enabled="false" class="mtree">\n' +
    '          <ol ui-tree-nodes="" ng-model="tpeCtrl.tpl.template" data-nodrop-enabled="true" data-drag-enabled="false">\n' +
    '            <li ui-tree-node ng-model="tpeCtrl.tpl.template.biopsy"\n' +
    '                ng-show="tpeCtrl.tpl.template.biopsy"\n' +
    '                data-drag-enabled="false"\n' +
    '                ng-include="\'biopsy_renderer.html\'">\n' +
    '              <div ui-tree-handle>\n' +
    '                {{tpeCtrl.tpl.template.biopsy.label}}\n' +
    '              </div>\n' +
    '            </li>\n' +
    '            <li ui-tree-node ng-model="tpeCtrl.tpl.template.comment"\n' +
    '                ng-show="tpeCtrl.tpl.template.comment"\n' +
    '                data-drag-enabled="false"\n' +
    '                ng-include="\'comment_renderer.html\'">\n' +
    '              <div ui-tree-handle>\n' +
    '                {{tpeCtrl.tpl.template.comment.label}}\n' +
    '              </div>\n' +
    '            </li>\n' +
    '            <li ui-tree-node\n' +
    '                ng-model="tpeCtrl.tpl.template.diagnosis"\n' +
    '                ng-show="tpeCtrl.tpl.template.diagnosis"\n' +
    '                data-drag-enabled="false"\n' +
    '                ng-include="\'diagnosis_renderer.html\'">\n' +
    '              <div ui-tree-handle>\n' +
    '                {{tpeCtrl.tpl.template.diagnosis.label}}\n' +
    '              </div>\n' +
    '            </li>\n' +
    '            <li ui-tree-node ng-model="tpeCtrl.tpl.template.recommendation"\n' +
    '                ng-show="tpeCtrl.tpl.template.recommendation"\n' +
    '                data-drag-enabled="false"\n' +
    '                ng-include="\'recommendation_renderer.html\'">\n' +
    '              <div ui-tree-handle>\n' +
    '                {{tpeCtrl.tpl.template.recommendation.label}}\n' +
    '              </div>\n' +
    '            </li>\n' +
    '            <div ng-hide="tpeCtrl.tpl.template.biopsy"\n' +
    '                 class="btn btn-success"\n' +
    '                 ng-click="tpeCtrl.addNamedField(\'biopsy\')">Biopsiefeld hinzufügen\n' +
    '            </div>\n' +
    '            <div ng-hide="tpeCtrl.tpl.template.comment"\n' +
    '                 class="btn btn-success"\n' +
    '                 ng-click="tpeCtrl.addNamedField(\'comment\')">Kommentarfeld hinzufügen\n' +
    '            </div>\n' +
    '            <div ng-hide="tpeCtrl.tpl.template.diagnosis"\n' +
    '                 class="btn btn-success"\n' +
    '                 ng-click="tpeCtrl.addNamedField(\'diagnosis\')">Diagnosefeld hinzufügen\n' +
    '            </div>\n' +
    '            <div ng-hide="tpeCtrl.tpl.template.recommendation"\n' +
    '                 class="btn btn-success"\n' +
    '                 ng-click="tpeCtrl.addNamedField(\'recommendation\')">Empfehlungsfeld hinzufügen\n' +
    '            </div>\n' +
    '          </ol>\n' +
    '        </div>\n' +
    '\n' +
    '        <input type="submit" class="btn btn-success" ng-click="tpeCtrl.tpl.$save()" value="Speichern"/>\n' +
    '        <input type="submit" class="btn btn-success" ng-click="tpeCtrl.tpl.$save(); tpeCtrl.closeModal();"\n' +
    '               value="Speichern und beenden"/>\n' +
    '      </form>\n' +
    '\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</script><!-- ende modal template -->\n' +
    '\n' +
    '\n' +
    '<!-- Nested field render template -->\n' +
    '<script type="text/ng-template" id="field_renderer.html">\n' +
    '  <div ui-tree-handle class="tree-node tree-node-content">\n' +
    '    <div>\n' +
    '      <div>\n' +
    '        {{field.label}}\n' +
    '        <a class="pull-right btn btn-danger btn-xs"\n' +
    '           uib-tooltip="Feld entfernen"\n' +
    '           data-nodrag ng-click="tpeCtrl.remove(this)">\n' +
    '          <span class="glyphicon glyphicon-remove"></span>\n' +
    '        </a>\n' +
    '        <a class="pull-right btn btn-primary btn-xs"\n' +
    '           uib-tooltip="Feld editieren"\n' +
    '           data-nodrag ng-click="ecollapsed=!ecollapsed"\n' +
    '           style="margin-right: 8px;">\n' +
    '            <span\n' +
    '                class="fa fa-edit"></span>\n' +
    '        </a>\n' +
    '\n' +
    '\n' +
    '        <div id="meta{{$index}}" ng-class="{\'hidden\':ecollapsed}" ng-init="ecollapsed = true">\n' +
    '          <div class="form-group">\n' +
    '            <label>Feldname\n' +
    '              <input type="text" ng-model="field.label" class="form-control"></label>\n' +
    '          </div>\n' +
    '          <div class="form-group">\n' +
    '            <label>Defaultwert(e)\n' +
    '              <tags-input ng-model="field.avalues"\n' +
    '                          display-property="name"\n' +
    '                          add-on-comma="false"\n' +
    '                          enableEditingLastTag="true"\n' +
    '                          placeholder="Neuer Eintrag <Eingabetaste>"\n' +
    '                          replace-spaces-with-dashes="false"\n' +
    '                          template="tag-template">\n' +
    '              </tags-input>\n' +
    '            </label>\n' +
    '          </div>\n' +
    '          <div class="form-group">\n' +
    '            <label>Mögliche Werte\n' +
    '              <tags-input ng-model="field.values"\n' +
    '                          display-property="name"\n' +
    '                          add-on-comma="false"\n' +
    '                          enableEditingLastTag="true"\n' +
    '                          placeholder="Neuer Eintrag <Eingabetaste>"\n' +
    '                          replace-spaces-with-dashes="false"\n' +
    '                          template="tag-template">\n' +
    '              </tags-input>\n' +
    '            </label>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</script>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('unicatalog/array-editor-directive.tpl.html',
    '<div>{{arrayEditor.name}}</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('tracker/trackerlist-directive.tpl.html',
    '<h3>Tracker</h3>\n' +
    '\n' +
    '<div ng-hide="tlCtrl.patient.trackers.length > 0" class="alert alert-info">\n' +
    '  Noch keine Tracker angelegt\n' +
    '</div>\n' +
    '\n' +
    '<!-- Neuer Tracker-->\n' +
    '<h4>Neuen Tracker anlegen</h4>\n' +
    '<form id="newtr" name="newtr">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12 col-sm-6">\n' +
    '      <label for="trname">Name</label>\n' +
    '      <input type="text" class="form-control" ng-model="tlCtrl.newtr.name" required placeholder="Name des Trackers"\n' +
    '             id="trname">\n' +
    '    </div>\n' +
    '    <div class="col-xs-12 col-sm-2">\n' +
    '      <label for="trunit">Einheit</label>\n' +
    '      <input type="text" class="form-control" ng-model="tlCtrl.newtr.unit" required placeholder="z.B. mmHg ..."\n' +
    '             id="trunit">\n' +
    '    </div>\n' +
    '    <div class="col-xs-12 col-sm-2">\n' +
    '      <label for="truplim" uib-tooltip="Untere Grenze Normbereich">Norm-Min</label>\n' +
    '      <input type="number" class="form-control" ng-model="tlCtrl.newtr.min" placeholder="" id="truplim">\n' +
    '    </div>\n' +
    '    <div class="col-xs-12 col-sm-2">\n' +
    '      <label for="trdownlim" uib-tooltip="Obere Grenze Normbereich">Norm-Max</label>\n' +
    '      <input type="number" class="form-control" ng-model="tlCtrl.newtr.max" placeholder="" id="trdownlim">\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row spacer-top">\n' +
    '    <div class="col-xs-12">\n' +
    '      <button type="button" class="btn btn-success" ng-click="tlCtrl.addTracker()"><i class="fa fa-plus"></i> Tracker\n' +
    '        hinzufügen\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</form>\n' +
    '\n' +
    '<!-- Dateneingabe -->\n' +
    '<form name="dpentry" id="dpentry" ng-show="tlCtrl.patient.trackers.length > 0">\n' +
    '  <h4>Dateneingabe für bestehende Tracker</h4>\n' +
    '  <div ng-repeat="tr in tlCtrl.patient.trackers | filter: {active: true}" class="row" ng-init="tlCtrl.newdp[$index].date = tlCtrl.today();">\n' +
    '    <div class="col-xs-12 col-sm-4">\n' +
    '      <label for="dpname">Name</label>\n' +
    '      <input type="text" disabled class="form-control" ng-model="tr.name" id="dpname">\n' +
    '    </div>\n' +
    '    <div class="col-xs-12 col-sm-4">\n' +
    '      <label for="trname">Datum</label>\n' +
    '      <input type="text" class="form-control" ng-model="tlCtrl.newdp[$index].date"\n' +
    '      \n' +
    '             required placeholder="JJJJ-MM-DD" id="tpdate">\n' +
    '    </div>\n' +
    '    <div class="col-xs-12 col-sm-4">\n' +
    '      <label for="dpval">Wert</label>\n' +
    '      <input type="number" class="form-control" ng-model="tlCtrl.newdp[$index].data" required placeholder="" id="dpval">\n' +
    '    </div>\n' +
    '  \n' +
    '  </div>\n' +
    '  <div class="row spacer-top">\n' +
    '    <div class="col-xs-12">\n' +
    '      <button type="submit" ng-click="tlCtrl.addDatapoints()" class="btn btn-success">\n' +
    '        <span ng-hide="tlCtrl.dpsaved">Daten speichern</span>\n' +
    '        <span ng-show="tlCtrl.dpsaved"><i class="fa fa-check"></i> Daten gespeichert</span>\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  \n' +
    '  </div>\n' +
    '</form>\n' +
    '\n' +
    '<!-- Graphiken -->\n' +
    '<div ng-show="tlCtrl.patient.trackers.length > 0" class="row spacer-top spacer-bottom">\n' +
    '  <div ng-repeat="tr in tlCtrl.patient.trackers | filter: {active: true}" class="col-xs-12 col-sm-6">\n' +
    '    <h4>\n' +
    '      <span>{{tr.name}} ({{tr.unit}})</span>\n' +
    '      <span class="pull-right">\n' +
    '        <button class="btn btn-primary btn-xs" type="button" uib-tooltip="Daten editieren" ng-click="tlCtrl.editTracker($index)"> <i class="fa fa-edit"></i></button>\n' +
    '        <button class="btn btn-danger btn-xs" type="button" uib-tooltip="Tracker löschen" ng-click="tr.active = false; tlCtrl.patient.$save();"> <i class="fa fa-remove"></i></button>\n' +
    '      </span>\n' +
    '    </h4>\n' +
    '    <twodgraph data="tr" graphid="$index" events=""></twodgraph>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<!-- Comfort whitespace -->\n' +
    '<div class="spacer-top spacer-bottom" style="min-height: 25px">&nbsp;</div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('tracker/edit-tracker-directive.tpl.html',
    '<div>\n' +
    '  <h3>Tracker: {{etCtrl.tracker.name}}</h3>\n' +
    '  <div class="row spacer-top">\n' +
    '    <div class="col-xs-12">\n' +
    '      <h4>Tracker-Daten</h4>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12 col-sm-6">\n' +
    '      <label for="tname">Name des Trackers</label>\n' +
    '      <input type="text" ng-model="etCtrl.patient.trackers[etCtrl.tracker].name" ng-blur="etCtrl.patient.$save()" class="form-control"\n' +
    '             id="tname">\n' +
    '    </div>\n' +
    '    <div class="col-xs-12 col-sm-6">\n' +
    '      <label for="tunit">Einheit/Größe</label>\n' +
    '      <input type="text" ng-model="etCtrl.patient.trackers[etCtrl.tracker].unit" ng-blur="etCtrl.patient.$save()" class="form-control"\n' +
    '             id="tunit">\n' +
    '    </div>\n' +
    '    <div class="col-xs-12 col-sm-6">\n' +
    '      <label for="tmin">Norm-Min</label>\n' +
    '      <input type="number" ng-model="etCtrl.patient.trackers[etCtrl.tracker].normmin" ng-blur="etCtrl.recalcRef()" class="form-control"\n' +
    '             id="tmin">\n' +
    '    </div>\n' +
    '    <div class="col-xs-12 col-sm-6">\n' +
    '      <label for="tmax">Norm-Max</label>\n' +
    '      <input type="number" ng-model="etCtrl.patient.trackers[etCtrl.tracker].normmax" ng-blur="etCtrl.recalcRef()" class="form-control"\n' +
    '             id="tmax">\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row spacer-top">\n' +
    '    <div class="col-xs-12">\n' +
    '      <h4>Datensätze</h4>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row spacer-top">\n' +
    '    <div class="col-xs-6" style="font-weight: bold">Datum (JJJJ-MM-TT)</div>\n' +
    '    <div class="col-xs-6" style="font-weight: bold">Wert</div>\n' +
    '  </div>\n' +
    '  <!-- Only edit real data, not norm reference data -->\n' +
    '  <div class="row" ng-repeat="i in etCtrl.patient.trackers[etCtrl.tracker].items | filter: {group: 0}">\n' +
    '    <div class="col-xs-6"><input type="text" ng-model="i.x" class="form-control" ng-blur="etCtrl.patient.$save()"></div>\n' +
    '    <div class="col-xs-6"><input type="number" ng-model="i.y" class="form-control" ng-blur="etCtrl.patient.$save()">\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <!-- Neuen datenpunkt anlegen -->\n' +
    '  <form id="newdpform" name="newdpform">\n' +
    '    <div class="row spacer-top">\n' +
    '      <div class="col-xs-12">\n' +
    '        <h3>Neuen Datensatz anlegen</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '      \n' +
    '      <div class="col-xs-6">\n' +
    '        <label for="newdpx">Datum</label>\n' +
    '        <input type="text" ng-model="etCtrl.newdp.x" class="form-control"\n' +
    '               id="newdpx"\n' +
    '               placeholder="JJJJ-MM-TT" required>\n' +
    '      </div>\n' +
    '      <div class="col-xs-6">\n' +
    '        <label for="newdpy">Wert</label>\n' +
    '        <input type="number" ng-model="etCtrl.newdp.y" class="form-control"\n' +
    '               id="newdpy" required>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="row spacer-top">\n' +
    '      <div class="col-xs-12">\n' +
    '        <button type="submit" class="btn btn-success" ng-click="etCtrl.addDatapoint()">Datensatz anlegen</button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </form>\n' +
    '</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('tracker/2dgraph-directive.tpl.html',
    '<div id="{{tdCtrl.graphid}}"></div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('todo/todowidget-directive.tpl.html',
    '<form class="form-inline">\n' +
    '  <div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '      <h3 class="panel-title">Todo&nbsp;\n' +
    '        <span class="label label-default" ng-model="todoCtrl.count"></span>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      \n' +
    '      \n' +
    '      <!-- Neues Todo -->\n' +
    '      <form class="form-vertical">\n' +
    '        <div class="input-group">\n' +
    '          <input type="text" ng-model="vm.newitem.label" class="form-control" id="newtodo" placeholder="Neues Todo">\n' +
    '          <div uib-dropdown class="input-group-btn" ng-class="gtClass">\n' +
    '            <button uib-dropdown-toggle type="button" class="btn btn-secondary">\n' +
    '              <span ng-show="vm.newitem.duestr">{{vm.newitem.duestr}}</span>\n' +
    '              <span ng-hide="vm.newitem.duestr">Fällig  </span><span class="caret"></span>\n' +
    '            </button>\n' +
    '            <!-- override bootstrap .dropdown-menu { min-width: 160px } -->\n' +
    '            <ul class="dropdown-menu" style="min-width: 0;">\n' +
    '              <li><a ng-click="vm.calcdue(0, \'Sofort\')">Sofort</a></li>\n' +
    '              <li><a ng-click="vm.calcdue(1, \'Morgen\')">Morgen</a></li>\n' +
    '              <li><a ng-click="vm.calcdue(2, \'Übermorgen\')">Übermorgen</a></li>\n' +
    '              <li><a ng-click="vm.calcdue(7, \'1 Woche\')">1 Woche</a></li>\n' +
    '              <li><a ng-click="vm.calcdue(14, \'2 Wochen\')">2 Wochen</a></li>\n' +
    '              <li><a ng-click="vm.calcdue(30, \'1 Monat\')">1 Monat</a></li>\n' +
    '              <li><a ng-click="vm.calcdue(90, \'3 Monate\')">3 Monate</a></li>\n' +
    '              <li><a ng-click="vm.calcdue(180, \'6 Monate\')">6 Monate</a></li>\n' +
    '              <li><a ng-click="vm.calcdue(365, \'1 Jahr\')">1 Jahr</a></li>\n' +
    '            </ul>\n' +
    '            <button class="btn btn-success" type="submit" ng-click="vm.add()">\n' +
    '              <i class="fa fa-plus"></i>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '          </span>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '      \n' +
    '      <!-- Todos gruppiert anzeigen -->\n' +
    '      <h4>Fällig &amp; Überfällig <span class="label label-xs label-danger">{{vm.todos.overdue.length}}</span> </h4>\n' +
    '      <!--<div class="alert alert-info" ng-hide="vm.todos.overdue.length > 0">Derzeit keine überfälligen Todos</div>-->\n' +
    '      <div class="spacer-top" ng-repeat="item in vm.todos.overdue | orderBy: \'due\'">\n' +
    '        <label style="color: #bb3333">\n' +
    '          <input type="checkbox"\n' +
    '                 ng-model="item.done"\n' +
    '                 ng-change="vm.save(item)"/>&nbsp;{{item.due | moment: \'l\' }}, {{item.label}} ({{item.user}})</label>\n' +
    '      </div>\n' +
    '      <h4>Offen <span class="label label-xs label-warning">{{vm.todos.upcoming.length}}</span> </h4>\n' +
    '      <!--<div class="alert alert-info" ng-hide="vm.todos.upcoming.length > 0">Derzeit keine offenen Todos</div>-->\n' +
    '      <div class="spacer-top" ng-repeat="item in vm.todos.upcoming | orderBy: \'due\' ">\n' +
    '        <label style="color: #ccbb33">\n' +
    '          <input type="checkbox"\n' +
    '                 ng-model="item.done"\n' +
    '                 ng-change="vm.save(item)"/>&nbsp;{{item.due | moment: \'l\' }}, {{item.label}} ({{item.user}})</label>\n' +
    '      </div>\n' +
    '      \n' +
    '      <h4>Zuletzt abgeschlossen <span class="label label-xs label-success">{{vm.todos.lastcompleted.length}}</span> </h4>\n' +
    '      <div class="spacer-top" ng-repeat="item in vm.todos.lastcompleted | orderBy: \'due\' ">\n' +
    '        <label style="color: #33cc33">\n' +
    '          <input type="checkbox"\n' +
    '                 ng-model="item.done"\n' +
    '                 ng-change="vm.save(item)"/>&nbsp;{{item.due | moment: \'l\' }}, {{item.label}} ({{item.user}})</label>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <!-- panelbody -->\n' +
    '  </div>\n' +
    '  <!-- panel -->\n' +
    '</form>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('testdata/testdata.tpl.html',
    '<h2>testdata</h2>\n' +
    '<p>{{testdata.ctrlName}}</p>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('testdata/testdata-directive.tpl.html',
    '<h3>Testpatienten generieren</h3>\n' +
    '\n' +
    '<form class="form-vertical">\n' +
    '  <div class="form-group">\n' +
    '    <label for="patnr">Anzahl</label>\n' +
    '    <div class="input-group">\n' +
    '      <input type="text" ng-model="tdCtrl.numberofsets" class="form-control" id="patnr">\n' +
    '      <button type="submit" class="bt btn-success" ng-click="tdCtrl.generatePatients()"><i class="fa fa-copy"></i></button>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '</form>\n' +
    '\n' +
    '<h3>Datensätze generiert: <span ng-bind="tdCtrl.setsgenerated"></span></h3>\n' +
    '<h1 ng-show="tdCtrl.setsgenerated === tdCtrl.numberofsets">Fertig!</h1>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('share/share.tpl.html',
    '<h2>share</h2>\n' +
    '<p>{{share.ctrlName}}</p>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('share/share-editor-directive.tpl.html',
    '<h3>Netzwerkshares</h3>\n' +
    '\n' +
    '<form class="form-vertical">\n' +
    '  <div class="form-group">\n' +
    '    <label for="sharename">Name</label>\n' +
    '    <input type="text" ng-model="shareCtrl.newshare.name" class="form-control" id="sharename">\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <label for="sharepath">Pfad</label>\n' +
    '    <input type="text" ng-model="shareCtrl.newshare.path" class="form-control" id="sharepath">\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <label for="sharedesc">Beschreibung</label>\n' +
    '    <input type="text" ng-model="shareCtrl.newshare.desc" class="form-control" id="sharedesc">\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <label for="sharefiletype">Filetyp</label>\n' +
    '    <select class="form-control" id="sharefiletype" ng-model="shareCtrl.newshare.filetype">\n' +
    '      <option>image/jpeg</option>\n' +
    '      <option>image/gif</option>\n' +
    '      <option>image/tiff</option>\n' +
    '      <option>application/pdf</option>\n' +
    '      <option>video/mpeg</option>\n' +
    '      <option>audio/mpeg3</option>\n' +
    '    </select>\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <div class="btn btn-success" ng-click="shareCtrl.addShare()">Speichern</div>\n' +
    '    <div class="btn btn-danger" ng-click="shareCtrl.newshare=\'\'">Abbrechen</div>\n' +
    '  </div>\n' +
    '</form>\n' +
    '\n' +
    '\n' +
    '<table>\n' +
    '  <tr>\n' +
    '    <th width="20%">Name</th>\n' +
    '    <th width="40%">Beschreibung</th>\n' +
    '    <th width="30%">Pfad</th>\n' +
    '    <th width="20%">Filetype</th>\n' +
    '    <th width="5%"></th>\n' +
    '    <th width="5%"></th>\n' +
    '  </tr>\n' +
    '  <tr ng-repeat="share in shareCtrl.allshares">\n' +
    '    <td ng-bind="share.name"></td>\n' +
    '    <td ng-bind="share.desc"></td>\n' +
    '    <td ng-bind="share.path"></td>\n' +
    '    <td ng-bind="share.filetype"></td>\n' +
    '    <td>\n' +
    '      <div class="btn btn-primary" ng-click="shareCtrl.editShare(share)" uib-tooltip="Editieren"><i class="fa fa-edit"></i>\n' +
    '      </div>\n' +
    '    </td>\n' +
    '    <td>\n' +
    '      <div class="btn btn-danger" ng-click="share.active=false; share.$save();" uib-tooltip="Löschen"><i\n' +
    '        class="fa fa-remove"></i></div>\n' +
    '    </td>\n' +
    '  </tr>\n' +
    '</table>\n' +
    '\n' +
    '\n' +
    '<script type="text/ng-template" id="shareedittempl">\n' +
    '  <div>\n' +
    '    <div class="modal-header">\n' +
    '      <h3 class="modal-title">Netzwerk-Share editieren</h3>\n' +
    '    </div>\n' +
    '    <div class="modal-body">\n' +
    '      <form class="form-vertical">\n' +
    '        <div class="form-group">\n' +
    '          <label for="shareename">Name</label>\n' +
    '          <input type="text" ng-model="sheCtrl.share.name" class="form-control" id="shareename">\n' +
    '        </div>\n' +
    '        <div class="form-group">\n' +
    '          <label for="shareepath">Pfad</label>\n' +
    '          <input type="text" ng-model="sheCtrl.share.path" class="form-control" id="shareepath">\n' +
    '        </div>\n' +
    '        <div class="form-group">\n' +
    '          <label for="shareedesc">Beschreibung</label>\n' +
    '          <input type="text" ng-model="sheCtrl.share.desc" class="form-control" id="shareedesc">\n' +
    '        </div>\n' +
    '        <div class="form-group">\n' +
    '          <label for="shareefiletype">Filetyp</label>\n' +
    '          <select class="form-control" id="shareefiletype" ng-model="sheCtrl.share.filetype">\n' +
    '            <option>image/jpeg</option>\n' +
    '            <option>image/gif</option>\n' +
    '            <option>image/tiff</option>\n' +
    '            <option>application/pdf</option>\n' +
    '            <option>video/mpeg</option>\n' +
    '            <option>audio/mpeg3</option>\n' +
    '          </select>\n' +
    '        </div>\n' +
    '        <div class="form-group">\n' +
    '          <div class="btn btn-success" ng-click="sheCtrl.share.$save()">Speichern</div>\n' +
    '          <div class="btn btn-danger" ng-click="sheCtrl.$uibModalInstance.dismiss(\'cancel\')">Abbrechen</div>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</script>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('service/serviceeditor-directive.tpl.html',
    '<h3>Neue Privat-Dienstleistung</h3>\n' +
    '\n' +
    '<form class="form-vertical">\n' +
    '  <div class="row">\n' +
    '    <div class="form-group col-md-9">\n' +
    '      <label for="servname">Name</label>\n' +
    '      <input type="text" ng-model="servCtrl.newservice.name" class="form-control" id="servname">\n' +
    '    </div>\n' +
    '    <div class="form-group col-md-3">\n' +
    '      <label for="servhint" uib-tooltip="Wird nicht auf der Rechnung ausgegeben">Hinweis</label>\n' +
    '      <input type="text" ng-model="servCtrl.newservice.hint" class="form-control" id="servhint">\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="form-group col-md-12">\n' +
    '      <label for="servdetails" uib-tooltip="Detaillierte Beschreibung der Leistung, wird auf Rechhnung ausgegeben">Details</label>\n' +
    '      <textarea ng-model="servCtrl.newservice.details" class="form-control" id="servdetails" placeholder="Detaillierte Beschreibung der Leistung, wird auf Rechhnung ausgegeben"></textarea>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <!--<div class="row">\n' +
    '    <div class="form-group col-md-12">\n' +
    '      <label for="servcomment">Zusatz/Kommentar</label>\n' +
    '      <textarea class="form-control" ng-model="servCtrl.newservice.comment" rows="5" id="servcomment"></textarea>\n' +
    '    </div>\n' +
    '  </div>-->\n' +
    '  <div class="row">\n' +
    '    <div class="form-group col-md-6">\n' +
    '      <label for="servprice">Preis</label>\n' +
    '      <input type="number" min="0" max="5000" step="0.01" ng-model="servCtrl.newservice.price" class="form-control" style="text-align: right;" id="servprice">\n' +
    '    </div>\n' +
    '    <div class="form-group col-md-6" ng-init="servCtrl.newservice.tax = 0">\n' +
    '      <label for="servtax">Steuersatz</label>\n' +
    '      <input type="number" min=0 max=100 ng-model="servCtrl.newservice.tax" class="form-control" style="text-align: right;" id="servtax">\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="form-group col-md-12">\n' +
    '      <button class="btn btn-default" type="button" ng-click="servCtrl.addAnother();">\n' +
    '        Speichern\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</form>\n' +
    '\n' +
    '<h3>Dienstleistungskatalog</h3>\n' +
    '\n' +
    '\n' +
    '<table>\n' +
    '  <tr>\n' +
    '    <th>Kat</th>\n' +
    '    <th>Num</th>\n' +
    '    <th width="50%">Bezeichnung</th>\n' +
    '    <th align="right" colspan="2">NöAEK Emf.</th>\n' +
    '    <th></th>\n' +
    '    <th>Preis (EUR)</th>\n' +
    '    <th>USt %</th>\n' +
    '    <th></th>\n' +
    '    <th></th>\n' +
    '  </tr>\n' +
    '  <tr ng-repeat="serv in servCtrl.services | filter: { active: true }" style="vertical-align: top">\n' +
    '    <td ng-bind="serv.cat"></td>\n' +
    '    <td ng-bind="serv.num"></td>\n' +
    '    <td><span ng-bind="serv.name" ng-hide="serv.cat === \'Priv\'"></span>\n' +
    '      <input type="text" ng-model="serv.name" ng-show="serv.cat === \'Priv\'"\n' +
    '             ng-change="serv.$save()"\n' +
    '             ng-model-options="{ updateOn: \'default blur\', debounce: { default: 5, blur: 0 } }"\n' +
    '             class="form-control">\n' +
    '      <input type="text" ng-model="serv.hint" ng-show="serv.cat === \'Priv\'"\n' +
    '             ng-change="serv.$save()"\n' +
    '             ng-model-options="{ updateOn: \'default blur\', debounce: { default: 5, blur: 0 } }"\n' +
    '             class="form-control">\n' +
    '      <textarea ng-model="serv.details" ng-show="serv.cat === \'Priv\'" class="form-control"\n' +
    '             ng-change="serv.$save()" ng-model-options="{ updateOn: \'default blur\', debounce: { default: 5, blur: 0 } }"\n' +
    '             placeholder="Details zur Leistung (werden auf der Rechnung angezeigt)"></textarea>\n' +
    '    </td>\n' +
    '    </td>\n' +
    '    <td align="right"><span ng-hide="serv.cat === \'Priv\'">{{serv.points | aek | currency:"":2 }}</span></td>\n' +
    '    <td><span ng-hide="serv.cat === \'Priv\'">&nbsp;EUR</span></td>\n' +
    '    <td align="center">\n' +
    '      <button class="btn btn-primary" type="button" uib-tooltip="Empfehlung übernehmen"\n' +
    '              ng-click="serv.price = serv.points  * 0.15; serv.$save();"\n' +
    '              ng-hide="serv.cat === \'Priv\'">\n' +
    '        <i class="fa fa-copy"></i>\n' +
    '      </button>\n' +
    '    </td>\n' +
    '    <td><input type="number" ng-model="serv.price"\n' +
    '               ng-change="serv.$save()"\n' +
    '               ng-model-options="{ updateOn: \'default blur\', debounce: { default: 5, blur: 0 } }"\n' +
    '               class="form-control" size="5" style="text-align: right;"></td>\n' +
    '\n' +
    '    <td><input type="number" ng-model="serv.tax"\n' +
    '               ng-change="serv.$save()"\n' +
    '               ng-model-options="{ updateOn: \'default blur\', debounce: { default: 5, blur: 0 } }"\n' +
    '               class="form-control" size="5" style="text-align: right;"></td>\n' +
    '    <td align="center">\n' +
    '     <!-- <button class="btn btn-success" type="button" uib-tooltip="Speichern" ng-click="serv.$save()">\n' +
    '        <i class="fa fa-check"></i>\n' +
    '      </button>-->\n' +
    '    </td>\n' +
    '    <td align="center">\n' +
    '      <button class="btn btn-danger" type="button" uib-tooltip="Löschen" ng-click="serv.active=false; serv.$save()">\n' +
    '        <i class="fa fa-remove"></i>\n' +
    '      </button>\n' +
    '    </td>\n' +
    '  </tr>\n' +
    '</table>\n' +
    '</div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('reminder/reminder.tpl.html',
    '<h2>reminder</h2>\n' +
    '<p>{{reminder.ctrlName}}</p>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('reminder/patient-reminder-directive.tpl.html',
    '<h4>Patienten-Todos</h4>\n' +
    '<div class="alert alert-info" ng-hide="premCtrl.patient.reminders.length > 0">Keine Todos</div>\n' +
    '<form class="form-vertical">\n' +
    '    <div class="input-group">\n' +
    '        <input type="text" ng-model="premCtrl.newreminder.label" class="form-control" id="newtodo" placeholder="Neues Todo">\n' +
    '        <div uib-dropdown class="input-group-btn" ng-class="gtClass">\n' +
    '            <button uib-dropdown-toggle type="button" class="btn btn-secondary">\n' +
    '                 <span ng-show="premCtrl.newreminder.duestr">{{premCtrl.newreminder.duestr}}</span>\n' +
    '                <span ng-hide="premCtrl.newreminder.duestr">Fällig  </span><span class="caret"></span>\n' +
    '            </button>\n' +
    '            <!-- override bootstrap .dropdown-menu { min-width: 160px } -->\n' +
    '            <ul class="dropdown-menu" style="min-width: 0;">\n' +
    '                <li><a ng-click="premCtrl.calcdue(0, \'Sofort\')">Sofort</a></li>\n' +
    '                <li><a ng-click="premCtrl.calcdue(14, \'2 Wochen\')">2 Wochen</a></li>\n' +
    '                <li><a ng-click="premCtrl.calcdue(30, \'1 Monat\')">1 Monat</a></li>\n' +
    '                <li><a ng-click="premCtrl.calcdue(90, \'3 Monate\')">3 Monate</a></li>\n' +
    '                <li><a ng-click="premCtrl.calcdue(180, \'6 Monate\')">6 Monate</a></li>\n' +
    '                <li><a ng-click="premCtrl.calcdue(365, \'1 Jahr\')">1 Jahr</a></li>\n' +
    '                <li><a ng-click="premCtrl.calcdue(730, \'2 Jahre\')">2 Jahre</a></li>\n' +
    '                <li><a ng-click="premCtrl.calcdue(1100, \'3 Jahre\')">3 Jahre</a></li>\n' +
    '            </ul>\n' +
    '            <button class="btn btn-success" type="submit" ng-click="premCtrl.addTodoEntry()">\n' +
    '            <i class="fa fa-plus"></i>\n' +
    '            </button>\n' +
    '        </div>\n' +
    '    </span>\n' +
    '    </div>\n' +
    '</form>\n' +
    '<div class="myscrollbox">\n' +
    '<form>\n' +
    '    <div class="form-group spacer-top" ng-repeat="item in premCtrl.patient.reminders | orderBy: \'due\' | filter: {done: false}">\n' +
    '        <label>\n' +
    '            <input type="checkbox"\n' +
    '                   ng-model="item.done"\n' +
    '                   ng-change="premCtrl.save(item)"/>&nbsp;{{item.label}}, {{item.due | moment: \'l\' }}</label>\n' +
    '    </div>\n' +
    '</form>\n' +
    '</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('reminder/all-reminder-directive.tpl.html',
    '\n' +
    '<h3>Patienten Erinnerungen</h3>\n' +
    '\n' +
    '<form>\n' +
    '    <h4>Fällig &amp; Überfällig</h4>\n' +
    '    <div class="alert alert-info" ng-hide="arCtrl.todos.overdue.length > 0">Derzeit keine überfälligen Erinnerungen</div>\n' +
    '    <div class="form-group spacer-top" ng-repeat="item in arCtrl.todos.overdue | orderBy: \'due\'">\n' +
    '        <label style="color: #bb3333">\n' +
    '            <input type="checkbox"\n' +
    '                   ng-model="item.done"\n' +
    '                   ng-change="arCtrl.save(item)"/>&nbsp;{{item.due | moment: \'l\' }}, {{item.label}},\n' +
    '            <a ui-sref="patientrecord.history({patientid: item.patientid})"> {{item.pfname}} {{item.plname}}</a></label>\n' +
    '    </div>\n' +
    '    <h4>Bald fällig (2 Wochen <span >\n' +
    '        <i class="fa fa-toggle-on fa-rotate-180 slactive"\n' +
    '           ng-show="arCtrl.weeks === 2"\n' +
    '           ng-click="arCtrl.switchRange(6);">\n' +
    '        </i>\n' +
    '        <i class="fa fa-toggle-on slinactive"\n' +
    '           ng-show="arCtrl.weeks === 6"\n' +
    '           ng-click="arCtrl.switchRange(2);">\n' +
    '        </i>\n' +
    '    </span> 6 Wochen)</h4>\n' +
    '    <div class="alert alert-info" ng-hide="arCtrl.todos.upcoming.length > 0">Derzeit keine fälligen Erinnerungen</div>\n' +
    '    <div class="form-group spacer-top" ng-repeat="item in arCtrl.todos.upcoming | orderBy: \'due\' ">\n' +
    '        <label style="color: #ccbb33">\n' +
    '            <input type="checkbox"\n' +
    '                   ng-model="item.done"\n' +
    '                   ng-change="arCtrl.save(item)"/>&nbsp;{{item.due | moment: \'l\' }}, {{item.label}},\n' +
    '            <a ui-sref="patientrecord.history({patientid: item.patientid})"> {{item.pfname}} {{item.plname}}</a></label>\n' +
    '    </div>\n' +
    '\n' +
    '    <h4>Zuletzt abgeschlossen</h4>\n' +
    '    <div class="alert alert-info" ng-hide="arCtrl.todos.lastcompleted.length > 0">Zuletzt wurden keine Erinnerungen abgeschlossen</div>\n' +
    '    <div class="form-group spacer-top" ng-repeat="item in arCtrl.todos.lastcompleted | orderBy: \'due\' ">\n' +
    '        <label style="color: #ccbb33">\n' +
    '            <input type="checkbox"\n' +
    '                   ng-model="item.done"\n' +
    '                   ng-change="arCtrl.save(item)"/>&nbsp;{{item.due | moment: \'l\' }}, {{item.label}},\n' +
    '            <a ui-sref="patientrecord.history({patientid: item.patientid})"> {{item.pfname}} {{item.plname}}</a></label>\n' +
    '    </div>\n' +
    '\n' +
    '</form>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('pdfprinter/pdfprinter.tpl.html',
    '<h2>pdfprinter</h2>\n' +
    '<p>{{pdfprinter.ctrlName}}</p>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('patient/patient.tpl.html',
    '<aside ui-view="leftbar" class="hidden-sm hidden-xs hidden-md col-xl-2">\n' +
    '  <!-- <patient-today></patient-today> -->\n' +
    '</aside>\n' +
    '<div ui-view="patientmain" class="col-sm-12 col-md-12 col-lg-10 col-xl-7">\n' +
    '  <patient-list></patient-list>\n' +
    '</div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('patient/patient-vert-directive.tpl.html',
    '<div ng-repeat="patient in patCtrl.patients">\n' +
    '  <div class="row">\n' +
    '    <div class="col-sm-{{col}}">\n' +
    '      <div class="thumbnail">\n' +
    '        <img src="{{patient.image}}" class="img-responsive" alt="{{patient.firstname}} {{patient.lastname}}">\n' +
    '\n' +
    '        <div class="caption">\n' +
    '          <h3><span ng-show="patient.titlefront">{{patient.titlefront}} </span>{{patient.firstname}}\n' +
    '            {{patient.lastname}}<span ng-show="titleback">, {{patient.titleback}}</span>\n' +
    '          </h3>\n' +
    '\n' +
    '          <div ng-repeat="(key, value) in patient.keywords">\n' +
    '            <span class="label label-{{value}}">{{key}}</span>\n' +
    '          </div>\n' +
    '          <p ng-show="{{buttons}}">\n' +
    '            <a href="#" class="btn btn-default" role="button">Akte</a>\n' +
    '            <a href="#" class="btn btn-primary" role="button">Aufrufen</a>\n' +
    '          </p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('patient/patient-todos-directive.tpl.html',
    '<h4>Patienten-Todos</h4>\n' +
    '<div ng-show="ptdCtrl.patient.todos == null">Keine Todos</div>\n' +
    '<form class="form-vertical">\n' +
    '  <div class="input-group">\n' +
    '    <input type="text" ng-model="ptdCtrl.patient.newtodo" class="form-control" id="newlog" placeholder="Neues Todo">\n' +
    '    <span class="input-group-btn">\n' +
    '      <button class="btn btn-success" type="submit" ng-click="ptdCtrl.addTodoEntry()">\n' +
    '        <i class="fa fa-plus"></i>\n' +
    '      </button>\n' +
    '    </span>\n' +
    '  </div>\n' +
    '</form>\n' +
    '<form>\n' +
    '  <div class="form-group" ng-repeat="item in ptdCtrl.patient.todos | filter: {done: false} | orderBy: \'created\' ">\n' +
    '    <label>\n' +
    '      <input type="checkbox"\n' +
    '             ng-model="item.done"\n' +
    '             ng-click="item.done != item.done; ptdCtrl.patient.$save()"/> {{item.label}}</label>\n' +
    '  </div>\n' +
    '</form>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('patient/patient-search-directive.tpl.html',
    '<div class="form-group">\n' +
    '  <div class="input-group">\n' +
    '    <input type="text" class="form-control"\n' +
    '           autofocus\n' +
    '           placeholder="Nachname, SVNr, Stichwort"\n' +
    '           ng-model="psCtrl.asyncSelected"\n' +
    '           typeahead-on-select="$parent.$dismiss();psCtrl.showpatient($item, $model, $label)"\n' +
    '           uib-typeahead="pat as pat.text for pat in psCtrl.getNames($viewValue)"\n' +
    '           typeahead-loading="loadingNames"\n' +
    '           typeahead-no-results="noResults">\n' +
    '    <span class="input-group-btn">\n' +
    '      <button type="submit" class="btn btn-success"><i class="fa fa-search"></i></button>\n' +
    '    </span>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '<i ng-show="loadingNames" class="glyphicon glyphicon-refresh"></i>\n' +
    '<div ng-show="noResults">\n' +
    '  <i class="glyphicon glyphicon-remove"></i> Keine Patienten gefunden\n' +
    '</div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('patient/patient-record-view-directive.tpl.html',
    '<aside ui-view="leftbar" class="hidden-sm hidden-xs hidden-md col-lg-2 col-xl-3">\n' +
    '  <patient-memo patient="patrCtrl.patient" ng-show="patrCtrl.conf.patientmemo == true"></patient-memo>\n' +
    '  <!-- <patient-today ng-hide="patrCtrl.conf.patientmemo == true"></patient-today>-->\n' +
    '</aside>\n' +
    '<div class="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-9">\n' +
    '  <!-- patient header large screens md, lg, xl -->\n' +
    '  <div class="hidden-xs hidden-sm">\n' +
    '    <!-- Photo, Name, Risiken, Diagnosen -->\n' +
    '    <div class="row">\n' +
    '      <!-- Photo -->\n' +
    '      <div class="col-sm-2 portraitcontainer">\n' +
    '        <img ng-src="{{patrCtrl.patient.image}}" class="img-responsive"\n' +
    '             alt="{{patrCtrl.patient.firstname}} {{patrCtrl.patient.lastname}}">\n' +
    '        <imagecapture filehandle="patrCtrl.newphoto" class="imgcapture"></imagecapture>\n' +
    '      </div>\n' +
    '      <!-- Name, Risiken, Diagnosen -->\n' +
    '      <div class="col-sm-10">\n' +
    '\n' +
    '        <h3><span ng-show="patrCtrl.patient.titlefront">{{patrCtrl.patient.titlefront}} </span>{{patrCtrl.patient.firstname}}\n' +
    '          {{patrCtrl.patient.lastname}}<span\n' +
    '              ng-show="patrCtrl.patient.titleback">, {{patrCtrl.patient.titleback}} </span>,\n' +
    '          * {{patrCtrl.patient.birthdate}}\n' +
    '\n' +
    '          <!-- Risikofaktoren -->\n' +
    '          <i class="fa fa-glass text-danger" uib-tooltip="Alkohol: {{patrCtrl.patient.risk.alcoholdetails}}"\n' +
    '             ng-show="patrCtrl.patient.risk.alcohol === true"></i>\n' +
    '          <i class="fa fa-glass text-muted" uib-tooltip="Alkohol: {{patrCtrl.patient.risk.alcoholdetails}}"\n' +
    '             ng-show="patrCtrl.patient.risk.alcohol === false"></i>\n' +
    '          <i class="fa fa-magic text-danger" uib-tooltip="Nikotin: {{patrCtrl.patient.risk.nicotindetails}}"\n' +
    '             ng-show="patrCtrl.patient.risk.nicotin === true"></i>\n' +
    '          <i class="fa fa-magic text-muted" uib-tooltip="Nikotin: {{patrCtrl.patient.risk.nicotindetails}}"\n' +
    '             ng-show="patrCtrl.patient.risk.nicotin === false"></i>\n' +
    '          <i class="fa fa-warning text-danger" uib-tooltip="{{patrCtrl.patient.risk.other}}"\n' +
    '             ng-show="patrCtrl.patient.risk.otherb === true"></i>\n' +
    '          <i class="fa fa-warning text-muted" uib-tooltip="{{patrCtrl.patient.risk.other}}"\n' +
    '             ng-show="patrCtrl.patient.risk.otherb === false"></i>\n' +
    '        </h3>\n' +
    '\n' +
    '\n' +
    '        <!-- Diagnosen-->\n' +
    '        <!--      <keywords-widget keywords="patrCtrl.patient.diagnoses" text="Neue Diagnose für Patienten"\n' +
    '                               orderbycolor="true"></keywords-widget>-->\n' +
    '        <sortable-keywords keywords="patrCtrl.patient.diagnoses" text="Neue Diagnose für Patienten"\n' +
    '                           orderbycolor="true"></sortable-keywords>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <!-- Nav tabs -->\n' +
    '    <ul class="nav nav-tabs hidden-xs hidden-sm" role="tablist" style="padding-top: 1ex;">\n' +
    '      <!-- Aktuell -->\n' +
    '      <li role="presentation" ui-sref="patientrecord.current" ui-sref-active="active"\n' +
    '          ng-show="patrCtrl.patient.inconsult === true && !patrCtrl.patient.incheckout === true" class="curtab">\n' +
    '        <a aria-controls="aktuell"><u>A</u>ktuell</a>\n' +
    '      </li>\n' +
    '      <!-- Rechnungspositionen -->\n' +
    '      <li role="presentation" ui-sref="patientrecord.billing" ui-sref-active="active"\n' +
    '          ng-show="patrCtrl.patient.inconsult === true && !patrCtrl.patient.incheckout === true" class="curtab">\n' +
    '        <a aria-controls="Rechnugspositionen" uib-tooltip="Rechnungspositionen"><u>R</u>echnung</a>\n' +
    '      </li>\n' +
    '      <!-- Abschluss -->\n' +
    '      <li role="presentation" ui-sref="patientrecord.checkout" ui-sref-active="active"\n' +
    '          ng-show="patrCtrl.patient.inconsult === true" class="curtab" uib-tooltip="Aktuelle Dokumente und Abschluß">\n' +
    '        <a aria-controls="aktuell"><u>S</u>chluss</a>\n' +
    '      </li>\n' +
    '      <!-- Historie -->\n' +
    '      <li role="presentation" ui-sref="patientrecord.history" ui-sref-active="active">\n' +
    '        <a aria-controls="ordination"><u>H</u>istorie</a>\n' +
    '      </li>\n' +
    '      <!-- Medikamente -->\n' +
    '      <li role="presentation" ui-sref="patientrecord.drugs" ui-sref-active="active">\n' +
    '        <a aria-controls="Medikamente"><u>M</u>edikamente</a>\n' +
    '      </li>\n' +
    '      <!-- Untersuchungen -->\n' +
    '      <li role="presentation" ui-sref="patientrecord.examinations" ui-sref-active="active">\n' +
    '        <a aria-controls="Untersuchungen"><u>U</u>ntersuchungen</a>\n' +
    '      </li>\n' +
    '      <!-- Dokumente -->\n' +
    '      <li role="presentation" ui-sref="patientrecord.documents" ui-sref-active="active">\n' +
    '        <a aria-controls="Dokumente"><u>D</u>okumente</a>\n' +
    '      </li>\n' +
    '      <!-- TRacker -->\n' +
    '      <li role="presentation" ui-sref="patientrecord.trackers" ui-sref-active="active">\n' +
    '        <a aria-controls="TRacker"><u>T</u>racker</a>\n' +
    '      </li>\n' +
    '      <!-- Stammdaten -->\n' +
    '      <li role="presentation" ui-sref="patientrecord.basicdata" ui-sref-active="active">\n' +
    '        <a aria-controls="Stammdaten">Stammdaten</a>\n' +
    '      </li>\n' +
    '    </ul>\n' +
    '  </div> <!-- end of large screen patient header -->\n' +
    '\n' +
    '\n' +
    '  <!-- patient header small screens xs, sm -->\n' +
    '  <div class="hidden-md hidden-lg hidden-xl">\n' +
    '    <div class="row">\n' +
    '      <!-- Photo -->\n' +
    '      <div class="col-xs-3 col-sm-3 portraitcontainer">\n' +
    '        <img ng-src="{{patrCtrl.patient.image}}" class="img-responsive"\n' +
    '             alt="{{patrCtrl.patient.firstname}} {{patrCtrl.patient.lastname}}">\n' +
    '        <imagecapture filehandle="patrCtrl.newphoto" captureid="patientphoto" class="imgcapture"></imagecapture>\n' +
    '      </div>\n' +
    '      <!-- Name, Risiken -->\n' +
    '      <div class="col-xs-9 col-sm-9">\n' +
    '        <h3><span ng-show="patient.titlefront">{{patrCtrl.patient.titlefront}} </span>{{patrCtrl.patient.firstname}}\n' +
    '          {{patrCtrl.patient.lastname}}<span\n' +
    '              ng-show="patrCtrl.patient.titleback">, {{patrCtrl.patient.titleback}} </span>,\n' +
    '          *&nbsp;{{patrCtrl.patient.birthdate}}&nbsp;<i class="fa fa-warning text-danger" uib-tooltip="Testpatient"\n' +
    '                                                        ng-show="patrCtrl.patient.test"></i>\n' +
    '          <i class="fa fa-glass text-danger" uib-tooltip="Alkohol: {{patrCtrl.patient.risk.alcoholdetails}}"\n' +
    '             ng-show="patrCtrl.patient.risk.alcohol === true"></i>\n' +
    '          <i class="fa fa-glass text-muted" uib-tooltip="Alkohol: {{patrCtrl.patient.risk.alcoholdetails}}"\n' +
    '             ng-show="patrCtrl.patient.risk.alcohol === false"></i>\n' +
    '          <i class="fa fa-magic text-danger" uib-tooltip="Nikotin: {{patrCtrl.patient.risk.nicotindetails}}"\n' +
    '             ng-show="patrCtrl.patient.risk.nicotin === true"></i>\n' +
    '          <i class="fa fa-magic text-muted" uib-tooltip="Nikotin: {{patrCtrl.patient.risk.nicotindetails}}"\n' +
    '             ng-show="patrCtrl.patient.risk.nicotin === false"></i>\n' +
    '          <i class="fa fa-warning text-danger" uib-tooltip="{{patrCtrl.patient.risk.other}}"\n' +
    '             ng-show="patrCtrl.patient.risk.otherb === true"></i>\n' +
    '          <i class="fa fa-warning text-muted" uib-tooltip="{{patrCtrl.patient.risk.other}}"\n' +
    '             ng-show="patrCtrl.patient.risk.otherb === false"></i></h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <!-- patient diagnoses xs -->\n' +
    '    <div class="row spacer-top">\n' +
    '      <sortable-keywords keywords="patrCtrl.patient.diagnoses"\n' +
    '                         text="Neue Diagnose für Patienten"\n' +
    '                         orderbycolor="true">\n' +
    '      </sortable-keywords>\n' +
    '    </div>\n' +
    '    <!-- dropdown nav small screens -->\n' +
    '    <div class="spacer-bottom row" uib-dropdown style="padding-top: 1ex;">\n' +
    '      <span uib-dropdown-toggle class="btn btn-info spacer-bottom" style="width: 100%">Navigation</span>\n' +
    '      <ul uib-dropdown-menu="">\n' +
    '        <!-- Aktuell -->\n' +
    '        <li role="presentation" ui-sref="patientrecord.current" ui-sref-active="active"\n' +
    '            ng-show="patrCtrl.patient.inconsult === true && !patrCtrl.patient.incheckout === true">\n' +
    '          <a aria-controls="aktuell">Aktuell</a>\n' +
    '        </li>\n' +
    '        <!-- Rechnungspositionen -->\n' +
    '        <li role="presentation" ui-sref="patientrecord.billing" ui-sref-active="active"\n' +
    '            ng-show="patrCtrl.patient.inconsult === true && !patrCtrl.patient.incheckout === true">\n' +
    '          <a aria-controls="Rechnugspositionen">Rechnung</a>\n' +
    '        </li>\n' +
    '        <!-- Abschluss -->\n' +
    '        <li role="presentation" ui-sref="patientrecord.checkout" ui-sref-active="active"\n' +
    '            ng-show="patrCtrl.patient.inconsult === true"><a aria-controls="aktuell">Schluss</a>\n' +
    '        </li>\n' +
    '        <li role="presentation" ui-sref-active="active"><a aria-controls="ordination"\n' +
    '                                                           ui-sref="patientrecord.history">Historie</a>\n' +
    '        </li>\n' +
    '        <li role="presentation" ui-sref-active="active"><a aria-controls="Medikamente"\n' +
    '                                                           ui-sref="patientrecord.drugs">Medikamente</a>\n' +
    '        </li>\n' +
    '        <li role="presentation" ui-sref-active="active"><a ui-sref="patientrecord.examinations"\n' +
    '                                                           aria-controls="Untersuchungen">Untersuchungen</a></li>\n' +
    '        <li role="presentation" ui-sref-active="active"><a aria-controls="Dokumente" ui-sref="patientrecord.documents">Dokumente</a>\n' +
    '        </li>\n' +
    '        <li role="presentation" ui-sref-active="active"><a aria-controls="Dokumente" ui-sref="patientrecord.trackers">Tracker</a>\n' +
    '        </li>\n' +
    '        <li role="presentation" ui-sref-active="active"><a ui-sref="patientrecord.basicdata" aria-controls="Stammdaten">Stammdaten</a>\n' +
    '        </li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '  </div> <!-- end of small screen header -->\n' +
    '\n' +
    '  <div class="row">\n' +
    '    <!-- Unsignierte Dokumente -->\n' +
    '    <div class="alert alert-danger spacer-top" ng-show="patrCtrl.unsignedScans.length > 0">\n' +
    '      <h4>Unsignierte Dokumente für den Patienten gefunden.\n' +
    '        <a class="btn btn-primary" ui-sref="dms.patsignoff({patientid: patrCtrl.patient._id})">\n' +
    '          Dokumente ansehen\n' +
    '        </a>\n' +
    '      </h4>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '  <!-- Offene Rechnungen -->\n' +
    '  <div class="alert alert-info spacer-top" ng-show="patrCtrl.openInvoices.length > 0">\n' +
    '    <h4>Offene Rechnungen des Patienten gefunden.</h4>\n' +
    '    <table class="table table-striped">\n' +
    '      <tr>\n' +
    '        <th>Datum</th>\n' +
    '        <th>Betrag</th>\n' +
    '        <th>Fällig</th>\n' +
    '        <th>Datei</th>\n' +
    '        <th>Aktion</th>\n' +
    '      </tr>\n' +
    '      <tr ng-repeat="inv in patrCtrl.openInvoices">\n' +
    '        <td ng-bind="inv.date"></td>\n' +
    '        <td>{{inv.total | currency: \'\': 2}}&nbsp;€</td>\n' +
    '        <td>\n' +
    '          <span ng-show="moment(inv.duedate).isBefore(moment())" style="color: #AA3333">{{inv.fduedate}}</span>\n' +
    '          <span ng-hide="moment(inv.duedate).isBefore(moment())" style="color: #33AA33">{{inv.fduedate}}</span>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '          <a href="/routes/pdfreader/{{inv.patientinvoiceid}}" uib-tooltip="Patientenrechnung" target="_blank"><i\n' +
    '              class="fa fa-file-pdf-o"></i></a>\n' +
    '        </td>\n' +
    '        <td><span class="btn btn-primary" ng-hide="inv.paid"\n' +
    '                  ng-click="patrCtrl.enterPaidDate(inv)">Zahlung registrieren</span></td>\n' +
    '      </tr>\n' +
    '    </table>\n' +
    '  </div>\n' +
    '\n' +
    '  <!-- View für alle Teile der Patienteakte -->\n' +
    '  <div ui-view="pr-details" style="padding-top: 1ex;"></div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('patient/patient-newsearch-directive.tpl.html',
    '<form class="form-vertical">\n' +
    '  <div class="form-group">\n' +
    '    <label for="nlname">Nachname</label>\n' +
    '    <input type="text" class="form-control"\n' +
    '           autofocus autocomplete="off" autocorrect="off" autocapitalize="on" spellcheck="false"\n' +
    '           placeholder="Nachname"\n' +
    '           ng-model="npCtrl.asyncSelected" id="nlname"\n' +
    '           typeahead-on-select="npCtrl.setLastname($model)"\n' +
    '           uib-typeahead="pat as pat.text for pat in npCtrl.getNewNames($viewValue)"\n' +
    '           typeahead-no-results="noResults">\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <label for="nfname">Vorname</label>\n' +
    '    <input type="text" ng-model="npCtrl.newpatient.firstname" class="form-control"\n' +
    '           autocomplete="off" autocorrect="off" autocapitalize="on" spellcheck="false"\n' +
    '           id="nfname" placeholder="Vorname">\n' +
    '  </div>\n' +
    '</form>\n' +
    '\n' +
    '<!--<i ng-show="loadingNames" class="glyphicon glyphicon-refresh"></i>-->\n' +
    '<div ng-repeat="pat in npCtrl.newnames">\n' +
    '  <span ng-click="npCtrl.showRecord(pat._id)" class="btn btn-info">Akte</span>\n' +
    '  {{pat.lastname | uppercase }} {{pat.firstname}}, * {{pat.birthdate}}\n' +
    '</div>\n' +
    '\n' +
    '<div ng-show="noResults" class="form-group">\n' +
    '  <i class="fa fa-remove"></i> Keine Patienten gefunden\n' +
    '</div>\n' +
    '\n' +
    '<div class="form-group">\n' +
    '  <div class="btn btn-success btn-spacer-top" ng-click="npCtrl.createPatient(); $parent.$dismiss();">Neuen Patienten anlegen</div>\n' +
    '  <div class="btn btn-danger btn-spacer-top" ng-click="npCtrl.cancel();">Abbrechen</div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('patient/patient-new-directive.tpl.html',
    '<form>\n' +
    '  <div class="panel panel-default">\n' +
    '\n' +
    '    <div class="panel-heading" ng-init="options={};patCtrl.newpat.test=false">\n' +
    '      <h3 class="panel-title">Neuer Patient </h3>\n' +
    '\n' +
    '    </div>\n' +
    '    <div class="panel-body">\n' +
    '      <!-- Patientennummer: {{patCtrl.newpat._id}} -->\n' +
    '      <!--      <input type="hidden" ng-model="patCtrl.newpat._id"> -->\n' +
    '      <div class="row">\n' +
    '        Status: <input type="checkbox"\n' +
    '                       ng-model="patCtrl.newpat.test"\n' +
    '                       ng-checked="patCtrl.newpat.test"\n' +
    '                       ng-click="patCtrl.newpat.active=!patCtrl.newpat.test"> Testpatient\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <div class="form-group col-md-2">\n' +
    '          <label for="titlefront">Titel</label>\n' +
    '          <input type="text" ng-model="patCtrl.newpat.titlefront" class="form-control" id="titlefront"\n' +
    '                 placeholder="Titel">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-4">\n' +
    '          <label for="firstname">Vorname</label>\n' +
    '          <input type="text" ng-model="patCtrl.newpat.firstname" class="form-control" id="firstname"\n' +
    '                 placeholder="Vorname">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-4">\n' +
    '          <label for="lastname">Nachname</label>\n' +
    '          <input type="text" ng-model="patCtrl.newpat.lastname" class="form-control" id="lastname"\n' +
    '                 placeholder="Nachname">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-2">\n' +
    '          <label for="titleback">Titel</label>\n' +
    '          <input type="text" ng-model="patCtrl.newpat.titleback" class="form-control" id="titleback"\n' +
    '                 placeholder="MSc">\n' +
    '\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="form-group col-md-2">\n' +
    '          <label for="birthdate">Geburtsdatum</label>\n' +
    '          <input type="text" ng-model="patCtrl.newpat.birthdate" class="form-control" id="birthdate"\n' +
    '                 placeholder="Geburtsdatum">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-4">\n' +
    '          <label for="svnr">SVNr</label>\n' +
    '          <input type="text" ng-model="patCtrl.newpat.svnr" class="form-control" id="svnr" placeholder="SVNr">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-6">\n' +
    '          <label for="sv">Sozialversicherung</label>\n' +
    '          <input type="text" ng-model="patCtrl.newpat.sv"\n' +
    '                 uib-typeahead="sv for sv in patCtrl.svs | filter:$viewValue | limitTo:8" class="form-control" id="sv"\n' +
    '                 placeholder="SV">\n' +
    '          <!--<input type="text" ng-model="selected" typeahead="state for state in states | filter:$viewValue | limitTo:8" class="form-control">-->\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="row">\n' +
    '        <div class="form-group col-md-6">\n' +
    '          <label for="phone">Telefon</label>\n' +
    '          <input type="text" ng-model="patCtrl.newpat.phone" class="form-control" id="phone" placeholder="Telefon">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-6">\n' +
    '          <label for="email">E-Mail</label>\n' +
    '          <input type="text" ng-model="patCtrl.newpat.email" class="form-control" id="email" placeholder="E-Mail">\n' +
    '        </div>\n' +
    '\n' +
    '      </div>\n' +
    '\n' +
    '      <div class=" row">\n' +
    '        <div class="form-group col-md-6">\n' +
    '          <label for="address">Adresse</label>\n' +
    '          <input type="text" ng-model="patCtrl.newpat.address" class="form-control" id="address"\n' +
    '                 placeholder="Adresse">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-2">\n' +
    '          <label for="postcode">PLZ</label>\n' +
    '          <input type="text" ng-model="patCtrl.newpat.postcode" class="form-control" id="postcode"\n' +
    '                 placeholder="PLZ">\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="form-group col-md-4">\n' +
    '          <label for="city">Ort</label>\n' +
    '          <input type="text" ng-model="patCtrl.newpat.city" class="form-control" id="city" placeholder="Ort">\n' +
    '        </div>\n' +
    '\n' +
    '      </div>\n' +
    '\n' +
    '      <div class=" row">\n' +
    '        <div class="form-group col-md-12" ng-init="patCtrl.newpat.country=\'Österreich\'">\n' +
    '          <label for="country">Land</label>\n' +
    '          <input type="text" ng-model="patCtrl.newpat.country" class="form-control" id="country" value="Österreich">\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '\n' +
    '      <div class=" row">\n' +
    '        <div class="form-group col-md-6">\n' +
    '          <label for="employer">Arbeitgeber</label>\n' +
    '          <input type="text" ng-model="patCtrl.newpat.employer" class="form-control" id="employer"\n' +
    '                 placeholder="Arbeitgeber">\n' +
    '        </div>\n' +
    '        <div class="form-group  col-md-6">\n' +
    '          <label for="practitioner">Hausarzt</label>\n' +
    '          <input type="text" ng-model="patCtrl.newpat.practitioner" class="form-control" id="practitioner"\n' +
    '                 placeholder="Hausarzt">\n' +
    '        </div>\n' +
    '\n' +
    '      </div>\n' +
    '\n' +
    '      <div class=" row">\n' +
    '        <div class="form-group col-md-6">\n' +
    '          <label for="insuredname">Mitversichert bei (Name)</label>\n' +
    '          <input type="text" ng-model="patCtrl.newpat.insuredwith.name" class="form-control" id="insuredname"\n' +
    '                 placeholder="Name">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-6">\n' +
    '          <label for="insurednr">Mitversichert bei (SVNr)</label>\n' +
    '          <input type="text" ng-model="patCtrl.newpat.insuredwith.svnr" class="form-control" id="insurednr"\n' +
    '                 placeholder="SVnr">\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class=" row">\n' +
    '        <div class="form-group col-md-6">\n' +
    '          <label for="iban">Bankverbindung (IBAN)</label>\n' +
    '          <input type="text" ng-model="patCtrl.newpat.bank.iban" class="form-control" id="iban" placeholder="IBAN">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-6">\n' +
    '          <label for="insurednr">Bankverbindung (SWIFT)</label>\n' +
    '          <input type="text" ng-model="patCtrl.newpat.bank.swift" class="form-control" id="swift"\n' +
    '                 placeholder="SWIFT">\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class=" row">\n' +
    '        <label>Patientstichwörter</label>\n' +
    '        <!--<div class="form-group col-md-12" ng-init="patCtrl.newpat.keywords[\'Erstbesuch\']=\'info\'">-->\n' +
    '        <div class="form-group col-md-12">\n' +
    '          <div keywords-widget keywords="patCtrl.newpat.keywords"></div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="input-group">\n' +
    '        <span class="input-group-btn">\n' +
    '	        <button class="btn btn-default" type="button" ng-click="patCtrl.add(patCtrl.newpat);">Patient anlegen\n' +
    '          </button>\n' +
    '        </span>\n' +
    '      </div>\n' +
    '\n' +
    '    </div>\n' +
    '    <!-- panelbody -->\n' +
    '\n' +
    '  </div>\n' +
    '  <!-- panel -->\n' +
    '</form>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('patient/patient-memo-directive.tpl.html',
    '<h3>Patientdetails</h3>\n' +
    '\n' +
    '<patient-reminder patient="pmCtrl.patient"></patient-reminder>\n' +
    '<patient-current-drugs patient="pmCtrl.patient"></patient-current-drugs>\n' +
    '<patient-log patient="pmCtrl.patient"></patient-log>\n' +
    '\n' +
    '\n' +
    '\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('patient/patient-log-directive.tpl.html',
    '<h4>Logbuch</h4>\n' +
    '<div ng-show="plogCtrl.patient.log == null">Keine Logeinträge</div>\n' +
    '<form class="form-vertical">\n' +
    '  <div class="input-group">\n' +
    '    <input type="text" ng-model="plogCtrl.patient.newlog" class="form-control" id="newlog"\n' +
    '           placeholder="Neuer Logeintrag">\n' +
    '    <span class="input-group-btn">\n' +
    '      <button class="btn btn-success" type="submit" ng-click="plogCtrl.addLogEntry()">\n' +
    '        <i class="fa fa-plus"></i>\n' +
    '      </button>\n' +
    '    </span>\n' +
    '  </div>\n' +
    '</form>\n' +
    '<div class="myscrollbox">\n' +
    '<dl class="dl-vertical" ng-repeat="le in plogCtrl.patient.log | orderBy: \'-created\' ">\n' +
    '  <dt ng-bind="le.created | moment: \'ll\' "></dt>\n' +
    '  <dd ng-bind="le.entry"></dd>\n' +
    '</dl>\n' +
    '</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('patient/patient-list-directive.tpl.html',
    '<h3>Patienten</h3>\n' +
    '\n' +
    '<table class="table table-striped">\n' +
    '  <thead>\n' +
    '  <tr>\n' +
    '    <th>Nachname</th>\n' +
    '    <th>Vorname</th>\n' +
    '    <th>Geburtsdatum</th>\n' +
    '    <th>SVNr</th>\n' +
    '    <th></th>\n' +
    '  </tr>\n' +
    '  </thead>\n' +
    '  <tr ng-repeat="patient in plCtrl.patientlist">\n' +
    '    <td>{{patient.lastname}}</td>\n' +
    '    <td>{{patient.firstname}}</td>\n' +
    '    <td>{{patient.birthdate}}</td>\n' +
    '    <td>{{patient.svnr}}</td>\n' +
    '    <td>\n' +
    '      <button class="btn btn-primary" ui-sref="patientrecord.history({patientid: \'{{patient._id}}\'})">Akte</button>\n' +
    '    </td>\n' +
    '  </tr>\n' +
    '</table>\n' +
    '\n' +
    '\n' +
    '\n' +
    '<uib-pagination total-items="plCtrl.totalItems"\n' +
    '            items-per-page="20"\n' +
    '            max-size="10"\n' +
    '            first-text="Erste"\n' +
    '            last-text="Letzte"\n' +
    '            next-text="N&auml;chste"\n' +
    '            previous-text="Vorige"\n' +
    '            boundary-links="true"\n' +
    '            rotate="false"\n' +
    '            ng-model="plCtrl.currentPage"\n' +
    '            ng-change="plCtrl.pageChanged()"></uib-pagination>\n' +
    '\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('patient/patient-horiz-directive.tpl.html',
    '<div class="row">\n' +
    '  <div class="col-md-2">\n' +
    '    <img src="patient.image" class="img-responsive" alt="{{patient.firstname}} {{patient.lastname}}">\n' +
    '  </div>\n' +
    '  <div class="col-md-10">\n' +
    '\n' +
    '    <h3><span ng-show="patient.titlefront">{{patient.titlefront}} </span>{{patient.firstname}} {{patient.lastname}}<span\n' +
    '      ng-show="patient.titleback">, {{patient.titleback}} </span>,\n' +
    '      * {{patient.birthdate}}</h3>\n' +
    '\n' +
    '    <div ng-show="editablekeywords == \'true\'">\n' +
    '      <keywords-widget keywords="patient.keywords"></keywords-widget>\n' +
    '    </div>\n' +
    '    <div ng-hide="editablekeywords == \'true\'">\n' +
    '      <div ng-repeat="(key, value) in patient.keywords">\n' +
    '        <span class="label label-{{value}}">{{key}}</span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('patient/patient-edit-directive.tpl.html',
    '\n' +
    '  <form name="patdata">\n' +
    '    <div class="panel panel-default">\n' +
    '      <div class="panel-heading" style="padding-top: 1ex">\n' +
    '        <h3 class="panel-title">\n' +
    '          <span ng-show="patCtrl.patient.firstname">{{patCtrl.patient.firstname}} {{patCtrl.patient.lastname}} </span>\n' +
    '          <span ng-hide="patCtrl.patient.firstname">Neuer Patient</span>, <span style="color: #999999">Patientennummer: {{patCtrl.patient._id}}</span>\n' +
    '        </h3>\n' +
    '      </div>\n' +
    '      <div class="panel-body">\n' +
    '        <div class="row">\n' +
    '          <!-- Geschlecht -->\n' +
    '          <div class="form-group col-md-4">\n' +
    '            <label for="gender1">Geschlecht</label>\n' +
    '            <div class="radio">\n' +
    '              <label for="gender1" class="radio-inline">\n' +
    '                <input type="radio" value="weiblich" tabindex="1"\n' +
    '                       ng-model="patCtrl.patient.gender"\n' +
    '                       id="gender1">weiblich</label>\n' +
    '              <label for="gender2" class="radio-inline">\n' +
    '                <input type="radio" value="männlich" tabindex="2"\n' +
    '                       ng-model="patCtrl.patient.gender"\n' +
    '                       id="gender2">männlich</label>\n' +
    '              <label for="gender3" class="radio-inline">\n' +
    '                <input type="radio" value="unbekannt" tabindex="3"\n' +
    '                       ng-model="patCtrl.patient.gender"\n' +
    '                       id="gender3">unbekannt</label>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <!-- Testpatient -->\n' +
    '          <div class="form-group col-md-2">\n' +
    '            <label for="testpat">Status</label>\n' +
    '            <div class="form-group">\n' +
    '              <label for="testpat" class="checkbox-inline">\n' +
    '                <input type="checkbox" id="testpat" ng-blur="patCtrl.patient.$save()" tabindex="4"\n' +
    '                       ng-model="patCtrl.patient.test"/> Testpatient</label>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <!-- Größe -->\n' +
    '          <div class="form-group col-md-2">\n' +
    '            <div class="form-group">\n' +
    '              <label for="height">Größe (cm)</label>\n' +
    '              <input type="number" id="height" ng-model="patCtrl.patient.height"\n' +
    '                     ng-blur="patCtrl.patient.$save()" tabindex="5"\n' +
    '                     class="form-control" ng-change="patCtrl.calcBMI()"/>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <!-- Gewicht -->\n' +
    '          <div class="form-group col-md-2">\n' +
    '            <div class="form-group">\n' +
    '              <label for="weight">Gewicht (kg)</label>\n' +
    '              <input type="number" id="weight" ng-model="patCtrl.patient.weight"\n' +
    '                     ng-blur="patCtrl.patient.$save()" tabindex="6"\n' +
    '                     class="form-control" ng-change="patCtrl.calcBMI()"/>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <!-- BMI -->\n' +
    '          <div class="form-group col-md-2">\n' +
    '            <div class="form-group">\n' +
    '              <label for="bmi">BMI</label>\n' +
    '              <input type="number" id="bmi" ng-model="patCtrl.patient.bmi" step="0.1" class="form-control"/>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!-- Titel, Name -->\n' +
    '        <div class="row">\n' +
    '          <div class="form-group col-md-2">\n' +
    '            <label for="titlefront">Titel</label>\n' +
    '            <input type="text" ng-model="patCtrl.patient.titlefront" tabindex="7"\n' +
    '                   ng-blur="patCtrl.patient.$save()"\n' +
    '                   class="form-control" id="titlefront"\n' +
    '                   placeholder="Titel">\n' +
    '          </div>\n' +
    '          <div class="form-group col-md-4">\n' +
    '            <label for="firstname">Vorname</label>\n' +
    '            <input type="text" ng-model="patCtrl.patient.firstname" tabindex="8"\n' +
    '                   ng-blur="patCtrl.patient.$save()"\n' +
    '                   class="form-control" id="firstname"\n' +
    '                   placeholder="Vorname">\n' +
    '          </div>\n' +
    '          <div class="form-group col-md-4">\n' +
    '            <label for="lastname">Nachname</label>\n' +
    '            <input type="text" ng-model="patCtrl.patient.lastname" tabindex="9"\n' +
    '                   ng-blur="patCtrl.patient.$save()"\n' +
    '                   class="form-control" id="lastname"\n' +
    '                   placeholder="Nachname">\n' +
    '          </div>\n' +
    '          <div class="form-group col-md-2">\n' +
    '            <label for="titleback">Titel</label>\n' +
    '            <input type="text" ng-model="patCtrl.patient.titleback" tabindex="10"\n' +
    '                   ng-blur="patCtrl.patient.$save()"\n' +
    '                   class="form-control" id="titleback"\n' +
    '                   placeholder="MSc">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <div class="row">\n' +
    '          <!-- Geburtsdatum -->\n' +
    '          <div class="form-group col-md-2">\n' +
    '            <label for="birthdate">Geburtsdatum</label>\n' +
    '            <input type="text" ng-model="patCtrl.patient.birthdate" tabindex="11"\n' +
    '                   ng-blur="patCtrl.patient.$save()"\n' +
    '                   class="form-control" id="birthdate"\n' +
    '                   placeholder="Geburtsdatum">\n' +
    '          </div>\n' +
    '          <!-- SVNr -->\n' +
    '          <div class="form-group col-md-4">\n' +
    '            <label for="svnr">SVNr <span ng-show=\'!patdata.svnr.$valid && patdata.svnr.$dirty\' style="color: #cc3333"> ungültig!</span> </label>\n' +
    '            <input type="text" ng-model="patCtrl.patient.svnr" tabindex="12"\n' +
    '                   ng-blur="patCtrl.patient.$save()"\n' +
    '                   validate-s-v-n-r\n' +
    '                   class="form-control" id="svnr" placeholder="nnnnddmmjj">\n' +
    '          </div>\n' +
    '          <!-- Sozialversicherung -->\n' +
    '          <div class="col-md-2">\n' +
    '            <div ng-hide="patCtrl.patient.sv">\n' +
    '              <label for="sv">Sozialversicherung</label>\n' +
    '              <sv-search\n' +
    '                  svres="patCtrl.patient.sv"\n' +
    '                  svresult="patCtrl.patient.svref"\n' +
    '                  ti="13" id="sv"></sv-search>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-show="patCtrl.patient.sv">\n' +
    '              <label for="svs">Sozialversicherung</label>\n' +
    '\n' +
    '              <div class="input-group">\n' +
    '                <input type="text" class="form-control"\n' +
    '                       id="svs"\n' +
    '                       readonly\n' +
    '                       ng-model="patCtrl.patient.sv">\n' +
    '                <span class="input-group-btn">\n' +
    '                    <button class="btn btn-danger"\n' +
    '                            ng-click="patCtrl.clearSv()"><i class="fa fa-remove"></i>\n' +
    '                    </button>\n' +
    '                </span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <!-- Versichertengruppe -->\n' +
    '          <div class="form-group col-md-4">\n' +
    '            <label for="svstatus">Versichertengruppe</label>\n' +
    '            <select ng-model="patCtrl.patient.svstatus" tabindex="14"\n' +
    '                    ng-blur="patCtrl.patient.$save()"\n' +
    '                    class="form-control" id="svstatus">\n' +
    '              <option value="01">01 - Erwerbstätig, Arbeitslos, Selbstversichert</option>\n' +
    '              <option value="02">02 - Pensionist(in)</option>\n' +
    '              <option value="03">03 - Kriegshinterbliebene(r)</option>\n' +
    '              <option value="05">05 - Angehörige(r) von 01</option>\n' +
    '              <option value="06">06 - Angehörige(r) von 02</option>\n' +
    '              <option value="08">08 - Anspruch KOVG, OFG, HVG D u. DH</option>\n' +
    '              <option value="10">10 - VAEB (A/B), BVA</option>\n' +
    '              <option value="11">11 - Angehörige(r) von 10</option>\n' +
    '              <option value="12">12 - Sozialversicherungsabkommen</option>\n' +
    '            </select>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row">\n' +
    '          <!-- Telefon -->\n' +
    '          <div class="form-group col-md-6">\n' +
    '            <label for="phone">Telefon</label>\n' +
    '            <input type="text" ng-model="patCtrl.patient.phone" tabindex="15"\n' +
    '                   uib-tooltip="Mehrfacheinträge möglich"\n' +
    '                   ng-blur="patCtrl.patient.$save()"\n' +
    '                   class="form-control" id="phone" placeholder="Telefon">\n' +
    '          </div>\n' +
    '          <!-- E-Mail -->\n' +
    '          <div class="form-group col-md-6">\n' +
    '            <label for="email">E-Mail</label>\n' +
    '            <input type="email" ng-model="patCtrl.patient.email" tabindex="16"\n' +
    '                   ng-blur="patCtrl.patient.$save()"\n' +
    '                   class="form-control" id="email" placeholder="E-Mail">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class=" row">\n' +
    '          <!-- Adresse -->\n' +
    '          <div class="form-group col-md-6">\n' +
    '            <label for="address">Adresse</label>\n' +
    '            <input type="text" ng-model="patCtrl.patient.address" tabindex="17"\n' +
    '                   ng-blur="patCtrl.patient.$save()"\n' +
    '                   class="form-control" id="address"\n' +
    '                   placeholder="Adresse">\n' +
    '          </div>\n' +
    '          <!-- PLZ & Ort -->\n' +
    '          <div class="form-group col-md-6">\n' +
    '            <zipcode zip="patCtrl.patient.postcode" city="patCtrl.patient.city" ti="18" ti2="19"></zipcode>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!-- Land -->\n' +
    '        <div class=" row">\n' +
    '          <div class="form-group col-md-12" ng-init="patCtrl.patient.country=\'Österreich\'">\n' +
    '            <label for="country">Land</label>\n' +
    '            <input type="text" ng-model="patCtrl.patient.country" tabindex="20"\n' +
    '                   ng-blur="patCtrl.patient.$save()"\n' +
    '                   class="form-control" id="country" value="Österreich">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <!-- Arbeitgeber -->\n' +
    '        <div class=" row">\n' +
    '          <div class="form-group col-md-12">\n' +
    '            <label for="employer">Arbeitgeber</label>\n' +
    '            <input type="text" ng-model="patCtrl.patient.employer" tabindex="21"\n' +
    '                   ng-blur="patCtrl.patient.$save()"\n' +
    '                   class="form-control" id="employer"\n' +
    '                   placeholder="Arbeitgeber">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <div class=" row">\n' +
    '          <!-- Hausarzt- -->\n' +
    '          <div class="form-group  col-md-6" ng-hide="patCtrl.patient.practdoctor._id">\n' +
    '            <label for="doctor">Hausarzt</label>\n' +
    '            <doctor-search doctorresult="patCtrl.patient.practdoctor" id="doctor" ti="22"></doctor-search>\n' +
    '          </div>\n' +
    '          <div class="form-group col-md-6" ng-show="patCtrl.patient.practdoctor._id">\n' +
    '            <label for="pdoctorname">Hausarzt</label>\n' +
    '\n' +
    '            <div class="input-group">\n' +
    '              <input type="text" class="form-control"\n' +
    '                     id="pdoctorname"\n' +
    '                     readonly\n' +
    '                     ng-model="patCtrl.patient.practdoctor.fname">\n' +
    '                <span class="input-group-btn">\n' +
    '                    <button class="btn btn-danger"\n' +
    '                            ng-click="patCtrl.clearPractDoctor()"><i class="fa fa-remove"></i>\n' +
    '                    </button>\n' +
    '                </span>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <!-- Überweisender Arzt -->\n' +
    '          <div class="form-group  col-md-6" ng-hide="patCtrl.patient.refdoctor._id">\n' +
    '            <label for="refdoctor">Überweisender Arzt</label>\n' +
    '            <doctor-search doctorresult="patCtrl.patient.refdoctor" id="refdoctor" ti="23"></doctor-search>\n' +
    '          </div>\n' +
    '          <div class="form-group col-md-6" ng-show="patCtrl.patient.refdoctor._id">\n' +
    '            <label for="rdoctorname">Überweisender Arzt</label>\n' +
    '\n' +
    '            <div class="input-group">\n' +
    '              <input type="text" class="form-control"\n' +
    '                     id="rdoctorname"\n' +
    '                     readonly\n' +
    '                     ng-model="patCtrl.patient.refdoctor.fname">\n' +
    '                <span class="input-group-btn">\n' +
    '                    <button class="btn btn-danger"\n' +
    '                            ng-click="patCtrl.clearRefDoctor()"><i class="fa fa-remove"></i>\n' +
    '                    </button>\n' +
    '                </span>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!-- Mitversicherung -->\n' +
    '        <div class=" row">\n' +
    '          <div class="form-group col-md-6">\n' +
    '            <label for="insuredname">Mitversichert bei (Name)</label>\n' +
    '            <input type="text" ng-model="patCtrl.patient.insuredwith.name" tabindex="24"\n' +
    '                   ng-blur="patCtrl.patient.$save()"\n' +
    '                   class="form-control" id="insuredname"\n' +
    '                   placeholder="Name">\n' +
    '          </div>\n' +
    '          <div class="form-group col-md-6">\n' +
    '            <label for="insurednr">Mitversichert bei (SVNr)</label>\n' +
    '            <input type="text" ng-model="patCtrl.patient.insuredwith.svnr" tabindex="25"\n' +
    '                   ng-blur="patCtrl.patient.$save()"\n' +
    '                   validate-s-v-n-r\n' +
    '                   class="form-control" id="insurednr"\n' +
    '                   placeholder="SVnr">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!-- Risikofaktoren -->\n' +
    '        <!-- Nikotin -->\n' +
    '        <div class="row">\n' +
    '          <div class="col-md-6">\n' +
    '            <label for="nicr">Nikotin</label><br>\n' +
    '            <label class="radio-inline"><input type="radio" name="nicr" ng-value="true" tabindex="26"\n' +
    '                                               ng-change="patCtrl.patient.$save()"\n' +
    '                                               ng-model="patCtrl.patient.risk.nicotin">ja</label>\n' +
    '            <label class="radio-inline"><input type="radio" name="nicr" ng-value="false" tabindex="27"\n' +
    '                                               ng-change="patCtrl.patient.$save()"\n' +
    '                                               ng-model="patCtrl.patient.risk.nicotin">nein</label>\n' +
    '          </div>\n' +
    '          <div class="col-md-6">\n' +
    '            <label for="nicrd">Nikotin Details</label>\n' +
    '            <input type="text" ng-model="patCtrl.patient.risk.nicotindetails" tabindex="28"\n' +
    '                   ng-blur="patCtrl.patient.$save()"\n' +
    '                   class="form-control" id="nicrd"\n' +
    '                   placeholder="Details zum Nikotinkonsum">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!-- Alkohol -->\n' +
    '        <div class="row">\n' +
    '          <div class="col-md-6">\n' +
    '            <label for="alcr">Alkohol</label><br>\n' +
    '            <label class="radio-inline"><input type="radio" name="alcr" ng-value="true" tabindex="29"\n' +
    '                                               ng-change="patCtrl.patient.$save()"\n' +
    '                                               ng-model="patCtrl.patient.risk.alcohol">ja</label>\n' +
    '            <label class="radio-inline"><input type="radio" name="alcr" ng-value="false" tabindex="30"\n' +
    '                                               ng-change="patCtrl.patient.$save()"\n' +
    '                                               ng-model="patCtrl.patient.risk.alcohol">nein</label>\n' +
    '          </div>\n' +
    '          <div class="col-md-6">\n' +
    '            <label for="alcrd">Alkohol Details</label>\n' +
    '            <input type="text" ng-model="patCtrl.patient.risk.alcoholdetails" tabindex="31"\n' +
    '                   ng-blur="patCtrl.patient.$save()"\n' +
    '                   class="form-control" id="alcrd"\n' +
    '                   placeholder="Details zum Alkoholkonsum">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <!-- Andere Risiken -->\n' +
    '        <div class="row">\n' +
    '          <div class="col-md-6">\n' +
    '            <label for="or">Andere Risiken</label><br>\n' +
    '            <label class="radio-inline"><input type="radio" name="or" ng-value="true" tabindex="32"\n' +
    '                                               ng-change="patCtrl.patient.$save()"\n' +
    '                                               ng-model="patCtrl.patient.risk.otherb">ja</label>\n' +
    '            <label class="radio-inline"><input type="radio" name="or" ng-value="false" tabindex="33"\n' +
    '                                               ng-change="patCtrl.patient.$save()"\n' +
    '                                               ng-model="patCtrl.patient.risk.otherb">nein</label>\n' +
    '          </div>\n' +
    '          <div class="col-md-6">\n' +
    '            <label for="riskother">Andere Risiken Details</label>\n' +
    '            <textarea ng-model="patCtrl.patient.risk.other" tabindex="34"\n' +
    '                      ng-blur="patCtrl.patient.$save()"\n' +
    '                      placeholder="Andere Risikofaktoren ..." class="form-control" id="riskother"></textarea>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <!-- Rezeptgebührenbefreiung -->\n' +
    '        <div class="row">\n' +
    '          <div class="form-group col-md-12">\n' +
    '            <label for="feefree">Rezeptgebührenbefreiung</label>\n' +
    '            <div class="checkbox">\n' +
    '              <label>\n' +
    '                <input type="checkbox" ng-model="patCtrl.patient.feefree" tabindex="35"\n' +
    '                       ng-blur="patCtrl.patient.$save()"\n' +
    '                       id="feefree"\n' +
    '                >&nbsp;befreit\n' +
    '              </label>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <!--\n' +
    '      <div class=" row">\n' +
    '        <div class="form-group col-md-6">\n' +
    '          <label for="iban">Bankverbindung (IBAN)</label>\n' +
    '          <input type="text" ng-model="patCtrl.patient.bank.iban" tabindex="30"\n' +
    '                 ng-blur="patCtrl.patient.$save()"\n' +
    '                 class="form-control" id="iban" placeholder="IBAN">\n' +
    '        </div>\n' +
    '        <div class="form-group col-md-6">\n' +
    '          <label for="swift">Bankverbindung (SWIFT)</label>\n' +
    '          <input type="text" ng-model="patCtrl.patient.bank.swift" tabindex="31"\n' +
    '                 ng-blur="patCtrl.patient.$save()"\n' +
    '                 class="form-control" id="swift"\n' +
    '                 placeholder="SWIFT">\n' +
    '        </div>\n' +
    '      </div>\n' +
    '-->\n' +
    '      <!-- <div class=" row">\n' +
    '\n' +
    '           <div class="form-group col-md-12">\n' +
    '               <label>Stichworte zum Patienten</label>\n' +
    '               <div keywords-widget keywords="patCtrl.patient.keywords"></div>\n' +
    '           </div>\n' +
    '       </div>-->\n' +
    '      <!--\n' +
    '              <div class="input-group">\n' +
    '              <span class="input-group-btn">\n' +
    '                <span class="btn btn-default" type="button" ng-click="patCtrl.patient.$save();">Daten speichern</span>\n' +
    '                <span ng-show="patCtrl.conf.devmode" class="btn btn-danger" type="button"\n' +
    '                      ng-click="patCtrl.clearOpenConsult(patient)">Clear openConsult</span>\n' +
    '              </span>\n' +
    '              </div>\n' +
    '      -->\n' +
    '    </div><!-- panelbody -->\n' +
    '\n' +
    '    </div><!-- panel -->\n' +
    '  </form>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('login/login-directive.tpl.html',
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">\n' +
    '    <h3>Login</h3>\n' +
    '  </div>\n' +
    '  <div class="panel-body">\n' +
    '    <div class="alert alert-danger" ng-show="liCtrl.error">Benutzername und/oder Passwort falsch!</div>\n' +
    '    <form class="form-vertical">\n' +
    '      <div class="form-group">\n' +
    '        <label for="username">Benutzername</label>\n' +
    '        <input type="text" class="form-control" name="username" id="username"\n' +
    '               autocapitalize="off" autocorrect="off"  spellcheck="false"\n' +
    '               ng-model="liCtrl.username">\n' +
    '      </div>\n' +
    '      <div class="form-group">\n' +
    '        <label for="password">Passwort</label>\n' +
    '        <input type="password" class="form-control" name="password" id="password"\n' +
    '               autocapitalize="off" autocorrect="off" spellcheck="false"\n' +
    '               ng-model="liCtrl.password">\n' +
    '      </div>\n' +
    '      <div class="form-group">\n' +
    '        <input type="submit" class="btn btn-success" ng-click="liCtrl.sendcreds()" value="Einloggen">\n' +
    '      </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('labvalue/labvalue-editor-directive.tpl.html',
    '<h2>Laborwerte und Laborwertgruppen</h2>\n' +
    '\n' +
    '<h3>Werte</h3>\n' +
    '\n' +
    '<tags-input ng-model="labECtrl.labconfig.values"\n' +
    '            display-property="name"\n' +
    '            placeholder="Neuen Laborwert hinzufügen"\n' +
    '            replace-spaces-with-dashes="false"\n' +
    '            add-on-comma="false"\n' +
    '            min-length="1"\n' +
    '            on-tag-added="labECtrl.saveConfig()"\n' +
    '            on-tag-removed="labECtrl.saveConfig()"\n' +
    '            template="tag-template">\n' +
    '  <auto-complete source="labECtrl.loadValues($query)"\n' +
    '                 min-length="0"\n' +
    '                 load-on-focus="true"\n' +
    '                 load-on-empty="true"\n' +
    '                 max-results-to-show="32"\n' +
    '                 template="autocomplete-template"></auto-complete>\n' +
    '</tags-input>\n' +
    '\n' +
    '\n' +
    '<h3>Neue Laborwertgruppe</h3>\n' +
    '\n' +
    '\n' +
    '<form>\n' +
    '  <div class="input-group">\n' +
    '    <input class="form-control"\n' +
    '           ng-model="labECtrl.newgroup" id="newgroup" placeholder="Neue Laborwertgruppe"/>\n' +
    '        <span class="input-group-btn">\n' +
    '          <button class="btn btn-success"\n' +
    '                  type="submit"\n' +
    '                  ng-click="labECtrl.addGroup()"><i class="fa fa-plus"></i> Neue Gruppe anlegen</button>\n' +
    '        </span>\n' +
    '  </div>\n' +
    '  </div>\n' +
    '</form>\n' +
    '\n' +
    '<h3>Gruppen</h3>\n' +
    '<form class="form-vertical">\n' +
    '  <div ng-repeat="group in labECtrl.labconfig.groups">\n' +
    '    <label ng-bind="group.name" class="spacer-top"></label>\n' +
    '    <span class="btn btn-danger btn-sm"\n' +
    '          ng-click="labECtrl.removeGroup($index)"\n' +
    '          style="margin-top: 2ex; margin-bottom: 0.8ex;"><i class="fa fa-remove"></i> Gruppe löschen</span>\n' +
    '    <tags-input ng-model="group.values"\n' +
    '                display-property="name"\n' +
    '                placeholder="Tippen zum Vervollständigen"\n' +
    '                replace-spaces-with-dashes="false"\n' +
    '                on-tag-added="labECtrl.saveConfig()"\n' +
    '                min-length="1"\n' +
    '                add-on-comma="false"\n' +
    '                on-tag-removed="labECtrl.saveConfig()"\n' +
    '                template="tag-template">\n' +
    '      <auto-complete source="labECtrl.loadValues($query)"\n' +
    '                     min-length="0"\n' +
    '                     load-on-focus="false"\n' +
    '                     load-on-down-arrow="true"\n' +
    '                     load-on-empty="true"\n' +
    '                     max-results-to-show="32"\n' +
    '                     template="autocomplete-template"></auto-complete>\n' +
    '    </tags-input>\n' +
    '  </div>\n' +
    '\n' +
    '  <script type="text/ng-template" id="tag-template">\n' +
    '    <div class="tag-template">\n' +
    '      <div class="right-panel">\n' +
    '        <span>{{$getDisplayText()}}</span>\n' +
    '        <a class="remove-button" ng-click="$removeTag()">&#10006;</a>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </script>\n' +
    '\n' +
    '\n' +
    '  <script type="text/ng-template" id="autocomplete-template">\n' +
    '    <div class="autocomplete-template">\n' +
    '      <div>\n' +
    '        <span ng-bind-html="$highlight($getDisplayText())">{{name}}</span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </script>\n' +
    '\n' +
    '\n' +
    '</form>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('labvalue/labreferral-directive.tpl.html',
    '<div>\n' +
    '  <form class="form-inline">\n' +
    '    <div class="row">\n' +
    '    \n' +
    '      <!-- value groups -->\n' +
    '      <div ng-repeat="group in labRCtrl.labconfig.groups">\n' +
    '        <div class="form-group col-md-4">\n' +
    '          <label>\n' +
    '            <input type="checkbox"\n' +
    '                   ng-model="labRCtrl.ginclude[$index]"\n' +
    '                   tabindex="{{labRCtrl.tabi + $index}}"\n' +
    '                   ng-checked="labRCtrl.ginclude[$index]"\n' +
    '                   ng-init="labRCtrl.ginclude[$index] = false"\n' +
    '                   ng-click="labRCtrl.toggleGroup()"/> {{group.name}}</label>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    \n' +
    '    <!-- add additional values -->\n' +
    '    <label for="addlabval">Zusätzliche Laborwerte</label>\n' +
    '    <tags-input ng-model="labRCtrl.additionalvalues"\n' +
    '                ng-init="labRCtrl.additionalvalues = []"\n' +
    '                tabindex="{{vm.tabi + labRCtrl.labconfig.groups.length}}"\n' +
    '                display-property="name" id="addlabval"\n' +
    '                add-on-comma="false"\n' +
    '                min-length="1"\n' +
    '                placeholder="Tippen zum Vervollständigen"\n' +
    '                replace-spaces-with-dashes="false"\n' +
    '                on-tag-added="labRCtrl.joinValues()"\n' +
    '                on-tag-removed="labRCtrl.joinValues()"\n' +
    '                template="tag-template">\n' +
    '      <auto-complete source="labRCtrl.loadValues($query)"\n' +
    '                     min-length="1"\n' +
    '                     load-on-focus="false"\n' +
    '                     load-on-down-arrow="true"\n' +
    '                     load-on-empty="true"\n' +
    '                     max-results-to-show="32"\n' +
    '                     template="autocomplete-template"></auto-complete>\n' +
    '    </tags-input>\n' +
    '    \n' +
    '    <!-- selected values -->\n' +
    '    <h4>Ausgewählte Laborwerte</h4>\n' +
    '    \n' +
    '    <div class="alert alert-info">\n' +
    '      <span ng-show="labRCtrl.allvalues.length > 0" ng-repeat="val in labRCtrl.allvalues">{{val}}{{$last ? \'\' : \', \'}} </span>\n' +
    '      <span ng-hide="labRCtrl.allvalues.length > 0">Keine Werte ausgewählt</span>\n' +
    '    </div>\n' +
    '  \n' +
    '  \n' +
    '  </form>\n' +
    '  <script type="text/ng-template" id="tag-template">\n' +
    '    <div class="tag-template">\n' +
    '      <div class="right-panel">\n' +
    '        <span>{{$getDisplayText()}}</span>\n' +
    '        <a class="remove-button" ng-click="$removeTag()">&#10006;</a>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </script>\n' +
    '  \n' +
    '  \n' +
    '  <script type="text/ng-template" id="autocomplete-template">\n' +
    '    <div class="autocomplete-template">\n' +
    '      <div>\n' +
    '        <span ng-bind-html="$highlight($getDisplayText())">{{name}}</span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </script>\n' +
    '</div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('journal/journal.tpl.html',
    '<h2>journal</h2>\n' +
    '<p>{{journal.ctrlName}}</p>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/home.tpl.html',
    '<div class="row">\n' +
    '    <div class="col-xs-12 col-md-6 col-lg-4">\n' +
    '        <h3>In Behandlung</h3>\n' +
    '        <open-consults></open-consults></div>\n' +
    '\n' +
    '    <div class="col-xs-12 col-md-6 col-lg-4">\n' +
    '        <h3>Wartelisten</h3>\n' +
    '    <div class="alert alert-info">Derzeit keine Patienten auf der Warteliste</div>\n' +
    '        <!--<waitinglists></waitinglists>-->\n' +
    '    </div>\n' +
    '    <div class="col-xs-12 col-md-6 col-lg-4">\n' +
    '        <all-reminder></all-reminder>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '    <div class="col-xs-12 col-md-6"></div>\n' +
    '    <div class="col-xs-12 col-md-6"></div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('heartbeat/heartbeat-directive.tpl.html',
    '<div>{{heartbeat.name}}</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('forms/transport-directive.tpl.html',
    '<div>{{transport.name}}</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('forms/timeevidence-directive.tpl.html',
    '<h3>Zeitbestätigung</h3>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '<form class="form-vertical" id="aideform" name="aideform">\n' +
    '  <div class="form-group">\n' +
    '    <label for="patient">Patient</label>\n' +
    '    <div class="input-group">\n' +
    '      <input type="text" class="form-control" id="patient"\n' +
    '             autofocus required\n' +
    '             placeholder="Nachname, SVNr, Stichwort"\n' +
    '             ng-model="teCtrl.patient"\n' +
    '             typeahead-on-select="teCtrl.showpatient($item, $model, $label)"\n' +
    '             uib-typeahead="pat as pat.text for pat in teCtrl.getNames($viewValue)"\n' +
    '             typeahead-loading="loadingNames"\n' +
    '             typeahead-no-results="noResults">\n' +
    '      <span class="input-group-btn">\n' +
    '      <button type="submit" class="btn btn-success"><i class="fa fa-search"></i></button>\n' +
    '    </span>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  \n' +
    '  \n' +
    '  \n' +
    '  <div class="form-group">\n' +
    '    <label for="date">Datum<span uib-tooltip="Pflichtfeld">*</span></label>\n' +
    '    <input type="text" required ng-model="teCtrl.timeevidence.fdate" class="form-control" id="date">\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <label for="aidediag">Start-Uhrzeit (hh:mm)<span uib-tooltip="Pflichtfeld">*</span></label>\n' +
    '    <input type="text" required ng-model="teCtrl.timeevidence.starttime" class="form-control" id="aidediag">\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <label for="totime">End-Uhrzeit (hh:mm)<span uib-tooltip="Pflichtfeld">*</span></label>\n' +
    '    <input type="text" class="form-control" required ng-model="teCtrl.timeevidence.endtime"\n' +
    '              id="totime">\n' +
    '  </div>\n' +
    '  \n' +
    '  <!-- Buttons -->\n' +
    '  <button class="btn btn-success" type="button" ng-click="teCtrl.print()"\n' +
    '          ng-disabled="aideform.$invalid">\n' +
    '    Drucken\n' +
    '  </button>\n' +
    '  <button class="btn btn-danger" type="button" ng-click="teCtrl.clear()">\n' +
    '    Abbrechen\n' +
    '  </button>\n' +
    '</form>\n' +
    '<div class="alert alert-success" ng-show="teCtrl.printed === true">\n' +
    '  <i class="fa fa-check"></i> Erfolgreich gedruckt. Weiter zur <a ui-sref="home">Übersicht</a>.\n' +
    '</div>\n' +
    '<div class="alert alert-error" ng-show="teCtrl.printed === false">\n' +
    '  <i class="fa fa-warning"></i> Druck ist fehlgeschlagen. Weiter zur <a ui-sref="home">Übersicht</a>.\n' +
    '</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('forms/textprocessing-directive.tpl.html',
    '<h3>Textverarbeitung</h3>\n' +
    '\n' +
    '<form class="form-vertical" id="textform" name="textform">\n' +
    '  \n' +
    '  <!-- Patient -->\n' +
    '  <div class="form-group">\n' +
    '    <label for="patient">Patient</label>\n' +
    '    <div class="input-group">\n' +
    '      <input type="text" class="form-control" id="patient" tabindex="3"\n' +
    '             required\n' +
    '             placeholder="Patienten Nachname, SVNr, Stichwort"\n' +
    '             ng-model="textCtrl.patient"\n' +
    '             typeahead-on-select="textCtrl.showpatient($item, $model, $label)"\n' +
    '             uib-typeahead="pat as pat.text for pat in textCtrl.getNames($viewValue)"\n' +
    '             typeahead-loading="loadingNames"\n' +
    '             typeahead-no-results="noResults">\n' +
    '      <span class="input-group-btn">\n' +
    '      <button type="submit" class="btn btn-success"><i class="fa fa-search"></i></button>\n' +
    '    </span>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  \n' +
    '  <!-- Empfänger/Arzt -->\n' +
    '  <div class="form-group">\n' +
    '    <label for="refto" uib-tooltip="Wird kein Arzt ausgewählt, geht der Brief an den Patienten">\n' +
    '      <span>\n' +
    '        Empfänger / Arztauswahl\n' +
    '      </span>\n' +
    '      <span uib-tooltip="Pflichtfeld"></span>\n' +
    '    </label>\n' +
    '    <!-- Arztsuche -->\n' +
    '    <doctor-search doctorresult="textCtrl.doctor" ti="4" id="refto"></doctor-search>\n' +
    '  </div>\n' +
    '  \n' +
    '  <!-- Betreff -->\n' +
    '  <div class="form-group">\n' +
    '    <label for="subject">Betreff</label>\n' +
    '      <input type="text" class="form-control" id="subject" tabindex="5"\n' +
    '             required placeholder="Betreff" ng-model="textCtrl.newtext.subject">\n' +
    '  </div>\n' +
    '  \n' +
    '  \n' +
    '  <div class="form-group">\n' +
    '    <div><label>Inkludieren</label></div>\n' +
    '    <div class="checkbox">\n' +
    '      <label>\n' +
    '        <input type="checkbox" ng-model="textCtrl.newtext.getdiagnoses" ng-true-value="true" ng-false-value="false"> Diagnosen\n' +
    '      </label>\n' +
    '      <label>\n' +
    '        <input type="checkbox" ng-model="textCtrl.newtext.getdrugs" ng-true-value="true" ng-false-value="false"> Medikamente\n' +
    '      </label>\n' +
    '      <label>\n' +
    '        <input type="checkbox" ng-model="textCtrl.newtext.getrisks" ng-true-value="true" ng-false-value="false"> Risikofaktoren\n' +
    '      </label>\n' +
    '      <label>\n' +
    '        <input type="checkbox" ng-model="textCtrl.newtext.getnotes" ng-true-value="true" ng-false-value="false"> Notizen\n' +
    '      </label>\n' +
    '      \n' +
    '<!--      <label>\n' +
    '        <input type="checkbox" ng-model="textCtrl.newtext.gettodos" ng-true-value="true" ng-false-value="false"> Patienten-Todos\n' +
    '      </label>-->\n' +
    '    </div>\n' +
    '    <div class="checkbox">\n' +
    '      <label>\n' +
    '        <input type="checkbox" ng-model="textCtrl.newtext.greetings" ng-true-value="true" ng-false-value="false"> Schlußformel (Mit freundlichen Grüßen ...)\n' +
    '      </label>\n' +
    '      </div>\n' +
    '    \n' +
    '  </div>\n' +
    '  \n' +
    '  \n' +
    '  <!-- WYSIWYG tinymce editor -->\n' +
    '    <textarea ui-tinymce="textCtrl.tinymceOptions" ng-model="textCtrl.newtext.xhtml"></textarea>\n' +
    '    <!--<textarea ui-tinymce ng-model="textCtrl.newtext.text"></textarea>-->\n' +
    '    \n' +
    '  \n' +
    '  <!-- Buttons -->\n' +
    '  <div class="spacer-top">\n' +
    '  <button class="btn btn-success" ng-click="textCtrl.print()">Drucken</button>\n' +
    '  <button class="btn btn-danger" ng-click="textCtrl.clear()">Abbrechen</button>\n' +
    '  </div>\n' +
    '  </form>\n' +
    '\n' +
    '<div class="alert alert-success spacer-top" ng-show="textCtrl.printed === true"><i class="fa fa-check-circle-o fa-2x"></i>&nbsp;Erfolgreich gedruckt</div>\n' +
    '<div class="alert alert-danger spacer-top" ng-show="textCtrl.printed === false"><i class="fa fa-warning fa-2x"></i>&nbsp;Druck fehlgeschlagen</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('forms/referral-directive.tpl.html',
    '<form class="form-inline">\n' +
    '  <h3>Überweisung</h3>\n' +
    '\n' +
    '  <div class="form-group">\n' +
    '    <div class="radio">\n' +
    '      <b><span style="font-weight: bold">Überweisungsart</span></b><br>\n' +
    '      <label for="stdref" class="radio-inline"><input type="radio" value="std" tabindex="1"\n' +
    '                                                      ng-model="refCtrl.newreferral.reftype"\n' +
    '                                                      id="stdref"> Standard</label>\n' +
    '      <label for="labref" class="radio-inline"><input type="radio" value="lab" tabindex="2"\n' +
    '                                                      ng-model="refCtrl.newreferral.reftype"\n' +
    '                                                      ng-click="refCtrl.setlab()"\n' +
    '                                                      id="labref"> Labor</label>\n' +
    '      <!--      <label for="imgref" class="radio-inline"><input type="radio" value="img"\n' +
    '                                                           ng-model="refCtrl.newreferral.reftype"\n' +
    '                                                           ng-click="refCtrl.setimg()"\n' +
    '                                                           id="imgref"> Bildgebende Diagnostik</label>-->\n' +
    '    \n' +
    '    </div>\n' +
    '  </div>\n' +
    '</form>\n' +
    '\n' +
    '\n' +
    '<form class="form-vertical" id="refform" name="refform">\n' +
    '  <!-- Patient -->\n' +
    '  <div class="form-group">\n' +
    '    <label for="patient">Patient</label>\n' +
    '    <div class="input-group">\n' +
    '      <input type="text" class="form-control" id="patient" tabindex="3"\n' +
    '             required\n' +
    '             placeholder="Patienten Nachname, SVNr, Stichwort"\n' +
    '             ng-model="refCtrl.patient"\n' +
    '             typeahead-on-select="refCtrl.showpatient($item, $model, $label)"\n' +
    '             uib-typeahead="pat as pat.text for pat in refCtrl.getNames($viewValue)"\n' +
    '             typeahead-loading="loadingNames"\n' +
    '             typeahead-no-results="noResults">\n' +
    '      <span class="input-group-btn">\n' +
    '      <button type="submit" class="btn btn-success"><i class="fa fa-search"></i></button>\n' +
    '    </span>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  \n' +
    '  <!-- Überweisung an: -->\n' +
    '  <div class="form-group">\n' +
    '    <label for="refto">\n' +
    '      <span uib-tooltip="Es muß entweder ein Arzt aus der Liste ausgewählt werden oder das Feld Freitext ausgefüllt werden">\n' +
    '        An/Zu: Arztauswahl ODER Freie Texteingabe\n' +
    '      </span>\n' +
    '      <span uib-tooltip="Pflichtfeld">*</span>\n' +
    '    </label>\n' +
    '    <!-- Arztsuche -->\n' +
    '    <div class="form-group" ng-hide="refCtrl.doctor._id">\n' +
    '      <doctor-search doctorresult="refCtrl.doctor" ti="4"></doctor-search>\n' +
    '    </div>\n' +
    '    <div class="form-group" ng-show="refCtrl.doctor._id">\n' +
    '      <div class="input-group">\n' +
    '        <input type="text" class="form-control"\n' +
    '               id="refdoctorname"\n' +
    '               readonly\n' +
    '               ng-model="refCtrl.doctor.fname">\n' +
    '        <span class="input-group-btn">\n' +
    '                    <button class="btn btn-danger"\n' +
    '                            ng-click="refCtrl.clearRefDoctor()"><i class="fa fa-remove"></i>\n' +
    '                    </button>\n' +
    '        </span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <p ng-hide="refCtrl.doctor">ODER</p>\n' +
    '    <!-- Freitext -->\n' +
    '    <input type="text" ng-model="refCtrl.newreferral.to" tabindex="5"\n' +
    '           ng-required="!(refCtrl.newreferral.to || refCtrl.doctor)"\n' +
    '           ng-hide="refCtrl.doctor"\n' +
    '           placeholder="Freitext für Überweisungsziel" class="form-control" id="refto">\n' +
    '  </div>\n' +
    '  <!-- diagnose/Begründung -->\n' +
    '  <div class="form-group">\n' +
    '    <label for="refdiag">Diagnose/Begründung<span uib-tooltip="Pflichtfeld">*</span></label>\n' +
    '    <input required type="text" ng-model="refCtrl.newreferral.diag"\n' +
    '           class="form-control" id="refdiag" tabindex="6">\n' +
    '  </div>\n' +
    '  \n' +
    '  <!-- Selected Labvalue Output -->\n' +
    '  <div ng-show="refCtrl.newreferral.reftype == \'lab\'">\n' +
    '    <labreferral allvalues="refCtrl.newreferral.labvalues"\n' +
    '                 additionalvalues="refCtrl.newreferral.additionalvalues"\n' +
    '                 ginclude="refCtrl.newreferral.ginclude"\n' +
    '                 ti="7">\n' +
    '    </labreferral>\n' +
    '  </div>\n' +
    '  \n' +
    '  \n' +
    '  <!-- Erbeten -->\n' +
    '  <div class="form-group" ng-hide="refCtrl.newreferral.reftype == \'lab\'">\n' +
    '    <label for="refref">Erbeten<span uib-tooltip="Pflichtfeld">*</span></label>\n' +
    '    <textarea class="form-control" ng-model="refCtrl.newreferral.ref" rows="5"\n' +
    '              ng-required="!(refCtrl.newreferral.ref || refCtrl.newreferral.labvalues)"\n' +
    '              id="refref" tabindex="8"></textarea>\n' +
    '  </div>\n' +
    '  \n' +
    '  <!-- gültig -->\n' +
    '  <div class="form-group">\n' +
    '    <label>Gültig </label>\n' +
    '    <div class="radio">\n' +
    '      <label class="radio-inline">\n' +
    '        <input type="radio" ng-value="true" ng-model="refCtrl.newreferral.pdate"\n' +
    '               ng-click="refCtrl.newreferral.refdate = moment().locale(\'de-at\').format(\'ll\')">\n' +
    '        1 Monat</label>\n' +
    '      <label class="radio-inline">\n' +
    '        <input type="radio" ng-value="false" ng-model="refCtrl.newreferral.pdate"\n' +
    '               ng-click="refCtrl.newreferral.refdate = \'\'">\n' +
    '        Kein Datum</label>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  \n' +
    '  <!-- Buttons -->\n' +
    '  <button class="btn btn-success" ng-disabled="refform.$invalid" type="button" ng-click="refCtrl.print();" tabindex="50">\n' +
    '    Drucken\n' +
    '  </button>\n' +
    '\n' +
    '  <button class="btn btn-danger" type="button" ng-click="refCtrl.clear()" tabindex="51">\n' +
    '    Abbrechen\n' +
    '  </button>\n' +
    '</form>\n' +
    '\n' +
    '\n' +
    '<!-- Alerts -->\n' +
    '<div class="alert alert-info spacer-top"\n' +
    '     ng-show="refCtrl.printing === true">\n' +
    '  <i class="fa fa-spinner fa-spin"></i> Überweisung wird gedruckt</a>.\n' +
    '</div><div class="alert alert-success spacer-top" ng-show="refCtrl.printed === true">\n' +
    '  <i class="fa fa-check"></i> Erfolgreich gedruckt. Weiter zur <a ui-sref="home">Übersicht</a>.\n' +
    '</div>\n' +
    '<div class="alert alert-error spacer-top" ng-show="refCtrl.printed === false">\n' +
    '  <i class="fa fa-warning"></i> Druck ist fehlgeschlagen. Weiter zur <a ui-sref="home">Übersicht</a>.\n' +
    '</div>\n' +
    '\n' +
    '</form>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('forms/aide-directive.tpl.html',
    '<h3>Verordnungen von Hilfsmitteln</h3>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '<form class="form-vertical" id="aideform" name="aideform">\n' +
    '<div class="form-group">\n' +
    '  <label for="patient">Patient</label>\n' +
    '  <div class="input-group">\n' +
    '    <input type="text" class="form-control" id="patient"\n' +
    '           autofocus required\n' +
    '           placeholder="Nachname, SVNr, Stichwort"\n' +
    '           ng-model="aidCtrl.patient"\n' +
    '           typeahead-on-select="aidCtrl.showpatient($item, $model, $label)"\n' +
    '           uib-typeahead="pat as pat.text for pat in aidCtrl.getNames($viewValue)"\n' +
    '           typeahead-loading="loadingNames"\n' +
    '           typeahead-no-results="noResults">\n' +
    '    <span class="input-group-btn">\n' +
    '      <button type="submit" class="btn btn-success"><i class="fa fa-search"></i></button>\n' +
    '    </span>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '  <div class="form-group">\n' +
    '    <label for="aideto">An/Zu<span uib-tooltip="Pflichtfeld">*</span></label>\n' +
    '    <input type="text" required ng-model="aidCtrl.newaide.to" class="form-control" id="aideto">\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <label for="aidediag">Diagnose/Begründung<span uib-tooltip="Pflichtfeld">*</span></label>\n' +
    '    <input type="text" required ng-model="aidCtrl.newaide.diag" class="form-control" id="aidediag">\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <label for="aideaide">Verordnung<span uib-tooltip="Pflichtfeld">*</span></label>\n' +
    '    <textarea class="form-control" required ng-model="aidCtrl.newaide.aide" rows="5"\n' +
    '              id="aideaide"></textarea>\n' +
    '  </div>\n' +
    '  \n' +
    '  <!-- gültig -->\n' +
    '  <div class="form-group">\n' +
    '    <label>Gültig </label>\n' +
    '    <div class="radio">\n' +
    '      <label class="radio-inline">\n' +
    '        <input type="radio" ng-value="true" ng-model="aidCtrl.newaide.pdate"\n' +
    '               ng-click="aidCtrl.newaide.aidedate = moment().locale(\'de-at\').format(\'ll\')">\n' +
    '        1 Monat</label>\n' +
    '      <label class="radio-inline">\n' +
    '        <input type="radio" ng-value="false" ng-model="aidCtrl.newaide.pdate"\n' +
    '               ng-click="aidCtrl.newaide.aidedate = \'\'">\n' +
    '        Kein Datum</label>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  \n' +
    '  <!-- Buttons -->\n' +
    '  <button class="btn btn-success" type="button" ng-click="aidCtrl.print()"\n' +
    '          ng-disabled="aideform.$invalid">\n' +
    '    Drucken\n' +
    '  </button>\n' +
    '  <button class="btn btn-danger" type="button" ng-click="aidCtrl.clear()">\n' +
    '    Abbrechen\n' +
    '  </button>\n' +
    '</form>\n' +
    '<div class="alert alert-success" ng-show="aidCtrl.printed === true">\n' +
    '  <i class="fa fa-check"></i> Erfolgreich gedruckt. Weiter zur <a ui-sref="home">Übersicht</a>.\n' +
    '</div>\n' +
    '<div class="alert alert-error" ng-show="aidCtrl.printed === false">\n' +
    '  <i class="fa fa-warning"></i> Druck ist fehlgeschlagen. Weiter zur <a ui-sref="home">Übersicht</a>.\n' +
    '</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('file/lightbox.html',
    '<div class="modal-body"\n' +
    '     ng-controller="FileCtrl as fC"\n' +
    '     ng-swipe-left="Lightbox.nextImage()"\n' +
    '     ng-swipe-right="Lightbox.prevImage()">\n' +
    '  <!-- navigation -->\n' +
    '  <div class="lightbox-nav">\n' +
    '    <!-- close button -->\n' +
    '    <button class="close" aria-hidden="true" ng-click="$dismiss()">×</button>\n' +
    '\n' +
    '    <div>\n' +
    '      <a class="btn btn-xs btn-default" ng-click="fC.previous()" style="display: inline">\n' +
    '        ‹ Zur&uuml;ck\n' +
    '      </a>\n' +
    '\n' +
    '      <form class="form-horizontal" style="display: inline">\n' +
    '        <span class="input-group" style="width: 70%">\n' +
    '        <span class="input-group-btn">\n' +
    '          <button class="btn btn-danger" ng-click="fC.fileDelete(Lightbox.image)" uib-tooltip="Löschen">\n' +
    '            <i class="fa fa-remove"></i>\n' +
    '          </button>\n' +
    '        </span>\n' +
    '          <input type="text" class="form-control" ng-model="Lightbox.image.label" id="deposit"\n' +
    '                 placeholder="Bezeichnung">\n' +
    '          <span class="input-group-btn">\n' +
    '            <button class="btn btn-success" type="submit" ng-click="fC.fileLabel(Lightbox.image)"\n' +
    '                    uib-tooltip="In Befund übernehmen">\n' +
    '              <i class="fa fa-plus"></i>\n' +
    '            </button>\n' +
    '          </span>\n' +
    '        </span>\n' +
    '      </form>\n' +
    '      <a class="btn btn-xs btn-default" ng-click="fC.next()" style="display: inline">\n' +
    '        Weiter ›\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <!--\n' +
    '    <div class="btn-group-md">\n' +
    '      <a class="btn btn-xs btn-default" ng-click="Lightbox.prevImage()">\n' +
    '        ‹ Zurück\n' +
    '      </a>\n' +
    '\n' +
    '      <a ng-click="fC.fileInclude(Lightbox.image)"\n' +
    '         class="btn btn-xs btn-default" title="-&gt; Befund">\n' +
    '        <i class="fa fa-text-o"></i> in Befund\n' +
    '      </a>\n' +
    '      <a ng-click="fC.fileKeep(Lightbox.image)"\n' +
    '         class="btn btn-xs btn-default" title="Speichern">\n' +
    '        <i class="fa fa-save"></i> Speichern\n' +
    '      </a>\n' +
    '      <a ng-click="fC.fileDelete(Lightbox.image)"\n' +
    '         class="btn btn-xs btn-default" title="Löschen">\n' +
    '        <i class="fa fa-remove text-danger"></i> Löschen\n' +
    '      </a>\n' +
    '      <a ng-href="{{Lightbox.imageUrl}}" target="_blank"\n' +
    '         class="btn btn-xs btn-default" title="Open in new tab">\n' +
    '        Bild in neuem Tab öffnen\n' +
    '      </a>\n' +
    '\n' +
    '      <form>\n' +
    '      <div class="input-group" style="width: 30%">\n' +
    '        <input type="text" class="form-control" ng-model="Lightbox.image.label" id="deposit" placeholder="Bezeichnung">\n' +
    '    <span class="input-group-btn">\n' +
    '    <button class="btn btn-success" type="submit" ng-click="fC.fileLabel(Lightbox.image)" uib-tooltip="Bezeichnung">\n' +
    '      <i class="fa fa-plus"></i>\n' +
    '    </button>\n' +
    '      </span>\n' +
    '      </div>\n' +
    '      </form>\n' +
    '      <a class="btn btn-xs btn-default" ng-click="Lightbox.nextImage()">\n' +
    '        Weiter ›\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    -->\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="lightbox-image-container">\n' +
    '    <!-- caption -->\n' +
    '    <div class="lightbox-image-caption">\n' +
    '      <span>{{Lightbox.imageCaption}}</span>\n' +
    '      <span>{{Lightbox.image.label}}</span>\n' +
    '      <span ng-show="Lightbox.image.processed == true"><i class="fa fa-check text-success"></i> Bearbeitet </span>\n' +
    '      <span ng-show="Lightbox.image.keep == true"><i class="fa fa-check text-success"></i> Behalten </span>\n' +
    '      <span ng-show="Lightbox.image.keep == false"><i class="fa fa-remove text-danger"></i> Löschen </span>\n' +
    '      <span ng-show="Lightbox.image.include == true"><i class="fa fa-check text-success"></i> in Befund </span>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- image -->\n' +
    '    <img ng-if="!Lightbox.isVideo(Lightbox.image)"\n' +
    '         lightbox-src="{{Lightbox.imageUrl}}">\n' +
    '\n' +
    '    <!-- video -->\n' +
    '    <div ng-if="Lightbox.isVideo(Lightbox.image)"\n' +
    '         class="embed-responsive embed-responsive-16by9">\n' +
    '      <!-- video file embedded directly -->\n' +
    '      <video ng-if="!Lightbox.isSharedVideo(Lightbox.image)"\n' +
    '             lightbox-src="{{Lightbox.imageUrl}}"\n' +
    '             controls\n' +
    '             autoplay="true">\n' +
    '      </video>\n' +
    '\n' +
    '      <!-- video embedded with an external service using\n' +
    '           `ng-videosharing-embed` -->\n' +
    '      <embed-video ng-if="Lightbox.isSharedVideo(Lightbox.image)"\n' +
    '                   lightbox-src="{{Lightbox.imageUrl}}"\n' +
    '                   ng-href="{{Lightbox.imageUrl}}"\n' +
    '                   iframe-id="lightbox-video"\n' +
    '                   class="embed-responsive-item">\n' +
    '        <a ng-href="{{Lightbox.imageUrl}}">Video ansehen</a>\n' +
    '      </embed-video>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('file/file.tpl.html',
    '<h2>File Test page</h2>\n' +
    '<p>{{file.ctrlName}}</p>\n' +
    '\n' +
    '<file-demo></file-demo>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '<h3>Filereader Demo</h3>\n' +
    '<filereader file="foo"></filereader>\n' +
    '\n' +
    '\n' +
    '<h3>Conventional File upload Test</h3>\n' +
    '<h4>posttestserver.com</h4>\n' +
    '<form enctype="multipart/form-data" action="http://posttestserver.com/post.php" method="post">\n' +
    '  <input type="file" name="file1">\n' +
    '  <input type="submit"></input>\n' +
    '</form>\n' +
    '\n' +
    '<h4>vertx upload</h4>\n' +
    '<form enctype="multipart/form-data" action="/routes/upload" method="post">\n' +
    '  <input type="file" name="file2" >\n' +
    '  <input type="submit"></input>\n' +
    '</form>\n' +
    '\n' +
    '\n' +
    '<h4>posttestserver.com Image capture</h4>\n' +
    '<form enctype="multipart/form-data" action="http://posttestserver.com/post.php" method="post">\n' +
    '  <input type="file" name="image" accept="image/*" capture>\n' +
    '  <input type="submit"></input>\n' +
    '</form>\n' +
    '\n' +
    '\n' +
    '<h4>vertx upload Image capture</h4>\n' +
    '<form enctype="multipart/form-data" action="/routes/upload" method="post">\n' +
    '  <input type="file" name="image2" accept="image/*" capture>\n' +
    '  <input type="submit"></input>\n' +
    '</form>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('file/file-demo-directive.tpl.html',
    '<h3>File Demo</h3>\n' +
    '<div ng-repeat="share in fileCtrl.allshares">\n' +
    '  <div class="btn btn-primary" ng-bind="share.name" ng-click="fileCtrl.demoStart(share)"></div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '<div ng-show="fileCtrl.openGrabbers != []">\n' +
    '  <h3>Offene Grabber</h3>\n' +
    '\n' +
    '  <div ng-repeat="share in fileCtrl.openGrabbers">\n' +
    '    <div class="btn btn-primary" ng-bind="share.name"\n' +
    '         ng-click="fileCtrl.demoStop(share,{filetype: share.filetype},$index)"></div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '<h4>Files bearbeitet</h4>\n' +
    '<div ng-repeat="f in fileCtrl.files | filter: { processed: true }">\n' +
    '  {{f.fileid}}\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '<h4>Files in Befund</h4>\n' +
    '<div ng-repeat="f in fileCtrl.files | filter: { include: true }">\n' +
    '  {{f.fileid}}\n' +
    '</div>\n' +
    '\n' +
    '<h4>Files gelöscht</h4>\n' +
    '<div ng-repeat="f in fileCtrl.files | filter: { keep: false }">\n' +
    '  {{f.fileid}}\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '<h4>Files behalten</h4>\n' +
    '<div ng-repeat="f in fileCtrl.files | filter: { keep: true }">\n' +
    '  {{f.fileid}}\n' +
    '</div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('file/file-browser-directive.tpl.html',
    '<h3>Dokumente</h3>\n' +
    '\n' +
    '<div class="alert alert-info" ng-show="fbCtrl.allFiles.length === 0">Keine Dokumente gefunden</div>\n' +
    '\n' +
    '<!-- upload buttons -->\n' +
    '<!-- Use of " \' \' " is necessary! won\'t be identified as string otherwise, will look for variable instead! -->\n' +
    '<!-- Can\'t have attributes with default values - no @? allowed :-( -->\n' +
    '<fileupload filehandle="fbCtrl.newfile" furl="\'/routes/upload\'" color="\'warning\'" label="\'Datei laden OHNE Vidierung\'"></fileupload>&nbsp;&nbsp;\n' +
    '<fileupload filehandle="fbCtrl.newfilecomplex" furl="\'/routes/uploadcomplex\'" color="\'success\'" label="\'Datei laden MIT Vidierung\'"></fileupload>\n' +
    '\n' +
    '<!-- uploaded and scaned files -->\n' +
    '<table st-table="fbCtrl.af" st-safe-src="fbCtrl.allFiles" class="table table-striped">\n' +
    '    <thead>\n' +
    '    <tr>\n' +
    '        <th st-sort="created" st-sort-default="reverse">Datum</th>\n' +
    '        <th st-sort="type">Typ</th>\n' +
    '        <th st-sort="keywords">Stichworte</th>\n' +
    '        <th>Datei</th>\n' +
    '    </tr>\n' +
    '    <tr>\n' +
    '        <th><input st-search="created" placeholder="Suche nach Datum" class="input-sm form-control" type="search"/></th>\n' +
    '        <th><input st-search="type" placeholder="Suche nach Typ" class="input-sm form-control" type="search"/></th>\n' +
    '        <th><input st-search="keywords" placeholder="Suche nach Stichwort" class="input-sm form-control" type="search"/>\n' +
    '        </th>\n' +
    '        <th></th>\n' +
    '    </tr>\n' +
    '    </thead>\n' +
    '    <tbody>\n' +
    '    <tr ng-repeat="doc in fbCtrl.af">\n' +
    '        <!--<td>{{doc.created | javadate: \'ll\'}}</td>-->\n' +
    '        <td><a href="/routes/files/filereader/{{doc.fileid}}" target="_blank">{{doc.created | moment: \'ll\'}}</a></td>\n' +
    '        <td><input type="text" class="form-control" ng-model="doc.type" ng-blur="doc.$save()"></td>\n' +
    '        <td>\n' +
    '            <tags-input ng-model="doc.keywords"\n' +
    '                        display-property="text"\n' +
    '                        id="dockeywords"\n' +
    '                        add-on-paste="true"\n' +
    '                        add-on-comma="false"\n' +
    '                        min-length="1"\n' +
    '                        ng-blur="fbCtrl.saveDoc(doc)"\n' +
    '                        enable-editing-last-tag="true"\n' +
    '                        add-from-autocomplete-only="false"\n' +
    '                        replace-spaces-with-dashes="false"\n' +
    '                        template="tag-template">\n' +
    '\n' +
    '                <auto-complete source="fbCtrl.getTags($query)"\n' +
    '                               min-length="1"\n' +
    '                               load-on-focus="true"\n' +
    '                               load-on-empty="false"\n' +
    '                               load-on-down-arrow="true"\n' +
    '                               max-results-to-show="32"\n' +
    '                               select-first-match="true"\n' +
    '                               template="autocomplete-template">\n' +
    '                </auto-complete>\n' +
    '\n' +
    '            </tags-input>\n' +
    '        </td>\n' +
    '\n' +
    '        <!--<td><a href="/routes/files/filereader/{{doc.fileid}}" target="_blank">{{doc.type}}</a></td>\n' +
    '        <td><a href="/routes/files/filereader/{{doc.fileid}}" target="_blank"><span ng-repeat="tag in doc.flattags">\n' +
    '            <span class="label label-primary">{{tag}}</span>\n' +
    '            </span></a>\n' +
    '        </td>-->\n' +
    '        <td>\n' +
    '            <a href="/routes/files/filereader/{{doc.fileid}}" target="_blank" class="btn btn-primary"><i class="fa fa-eye"></i></a>\n' +
    '            <!--TODO: get the backend working, than enable this-->\n' +
    '            <!--<a href="/routes/files/filedownload/{{doc.fileid}}" class="btn btn-primary"><i class="fa fa-download"></i></a>-->\n' +
    '        </td>\n' +
    '        <td></td>\n' +
    '    </tr>\n' +
    '    </tbody>\n' +
    '</table>\n' +
    '\n' +
    '\n' +
    '<!-- Tag autocomplete templates -->\n' +
    '<script type="text/ng-template" id="tag-template">\n' +
    '    <div class="tag-template">\n' +
    '        <div class="right-panel">\n' +
    '            <span>{{$getDisplayText()}}</span>\n' +
    '            <a class="remove-button" ng-click="$removeTag()">&#10006;</a>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</script>\n' +
    '\n' +
    '<script type="text/ng-template" id="autocomplete-template">\n' +
    '    <div class="autocomplete-template">\n' +
    '        <div>\n' +
    '            <span ng-bind-html="$highlight($getDisplayText())">{{text}}</span>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</script>\n' +
    '\n' +
    '\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('examtemplate/templategenerator-directive.tpl.html',
    '<h3>Untersuchungsvorlagen</h3>\n' +
    '\n' +
    '<div class="btn btn-success" ng-click="tmCtrl.createTemplate()">Neue Untersuchungsvorlage erstellen</div>\n' +
    '<div class="btn btn-success" ng-click="tmCtrl.loadSampletemplate()">Beispielvorlage laden (Oberbauchsonografie)</div>\n' +
    '\n' +
    '\n' +
    '<h4>Bestehende Untersuchungsvorlagen</h4>\n' +
    '<table>\n' +
    '  <tr>\n' +
    '    <th width="15%">Kürzel</th>\n' +
    '    <th width="50%">Name</th>\n' +
    '    <th width="25%">Status</th>\n' +
    '    <th width="20%"></th>\n' +
    '  </tr>\n' +
    '  <tr ng-repeat="t in tmCtrl.alltemplates">\n' +
    '    <td ng-bind="t.template.type"></td>\n' +
    '    <td ng-bind="t.template.displayname"></td>\n' +
    '    <td>\n' +
    '      <div class="btn"\n' +
    '           ng-class="t.active ? \'btn-success\' : \'btn-danger\'"\n' +
    '           ng-model="t.active"\n' +
    '           ng-click="t.active = !t.active; t.$save()"\n' +
    '           uib-tooltip="Status ändern">\n' +
    '        <span ng-show="t.active == true">Aktiv</span>\n' +
    '        <span ng-show="t.active == false">Nicht aktiv</span>\n' +
    '      </div>\n' +
    '    </td>\n' +
    '    <td>\n' +
    '      <div class="btn btn-primary"\n' +
    '           ng-click="tmCtrl.editTemplate(t)"\n' +
    '           uib-tooltip="Editieren"><i class="fa fa-edit"></i>\n' +
    '      </div>\n' +
    '      <div class="btn btn-primary"\n' +
    '           ng-click="tmCtrl.testTemplate(t)"\n' +
    '           uib-tooltip="Ausprobieren"><i class="fa fa-eye"></i>\n' +
    '      </div>\n' +
    '    </td>\n' +
    '    <!--\n' +
    '    <td>\n' +
    '      <div class="btn btn-danger" ng-click="tmCtrl.deleteTemplate(t)" uib-tooltip="Löschen"><i class="fa fa-remove"></i>\n' +
    '      </div>\n' +
    '    </td>-->\n' +
    '  </tr>\n' +
    '</table>\n' +
    '\n' +
    '<!-- Template Editor Modal -->\n' +
    '<script type="text/ng-template" id="tepledittpl">\n' +
    '  <div>\n' +
    '    <div class="modal-header">\n' +
    '      <h3 class="modal-title">Untersuchungsvorlage editieren</h3>\n' +
    '    </div>\n' +
    '    <div class="modal-body">\n' +
    '      <form class="form-vertical">\n' +
    '        <div class="form-group myform">\n' +
    '          <label>Kürzel/Typ\n' +
    '            <input type="text" ng-model="tpeCtrl.tpl.template.type" class="form-control"\n' +
    '                   ng-blur="tpeCtrl.tpl.$save()" placeholder="Kürzel/Typ"></label>\n' +
    '        </div>\n' +
    '        <div class="form-group myform">\n' +
    '          <label>Vollständige Bezeichnung\n' +
    '            <input type="text" ng-model="tpeCtrl.tpl.template.displayname" class="form-control"\n' +
    '                   ng-blur="tpeCtrl.tpl.$save()" placeholder="Vollständige Bezeichnung"></label>\n' +
    '        </div>\n' +
    '        <div class="form-group">\n' +
    '          <label>Befundinhalte in Gesamtbefund inkludieren\n' +
    '            <div class="radio">\n' +
    '              <label for="tinc1" class="radio-inline"><input type="radio" ng-value="true"\n' +
    '                                                             ng-model="tpeCtrl.tpl.template.includefindings" id="tinc1">Ja</label>\n' +
    '              <label for="tinc2" class="radio-inline"><input type="radio" ng-value="false"\n' +
    '                                                             ng-model="tpeCtrl.tpl.template.includefindings" id="tinc2">Nein</label>\n' +
    '            </div>\n' +
    '          </label>\n' +
    '        </div>\n' +
    '        <div class="form-group">\n' +
    '          <label>Bilder/Dateien von Netzwerklaufwerk einbeziehen\n' +
    '            <div class="radio">\n' +
    '              <label for="tnet1" class="radio-inline"><input type="radio" ng-value="true"\n' +
    '                                                             ng-model="tpeCtrl.tpl.template.loadfiles"\n' +
    '                                                             id="tnet1">Ja</label>\n' +
    '              <label for="tnet2" class="radio-inline"><input type="radio" ng-value="false"\n' +
    '                                                             ng-model="tpeCtrl.tpl.template.loadfiles"\n' +
    '                                                             id="tnet2">Nein</label>\n' +
    '            </div>\n' +
    '          </label>\n' +
    '        </div>\n' +
    '        <div class="form-group" ng-show="tpeCtrl.tpl.template.loadfiles === true">\n' +
    '          <label for="tname">Netzwerklaufwerk</label>\n' +
    '          <select ng-model="tpeCtrl.tpl.template.shareid" class="form-control" id="tname">\n' +
    '            <option ng-repeat="share in tpeCtrl.allshares" value="{{share._id}}">{{share.name}}</option>\n' +
    '          </select>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <div>\n' +
    '          <h4>Feldgruppen</h4>\n' +
    '          <a class="btn btn-success btn-xs"\n' +
    '             ng-click="tpeCtrl.newFieldGroup()"\n' +
    '             style="margin-right: 8px;">\n' +
    '            <span class="fa fa-plus"> Neue Feldgruppe</span>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="clear: both"></div>\n' +
    '\n' +
    '        <div ui-tree class="mtree" data-drag-delay="500" data-max-depth="2">\n' +
    '          <ol ui-tree-nodes="" ng-model="tpeCtrl.tpl.template.fieldgroups">\n' +
    '            <li ui-tree-node ng-repeat="fieldgroup in tpeCtrl.tpl.template.fieldgroups" data-drag-delay="500" >\n' +
    '              <div ui-tree-handle class="tree-node tree-node-content"  data-drag-delay="500">\n' +
    '                <a class="btn btn-success btn-xs"\n' +
    '                   ng-if="fieldgroup.fields && fieldgroup.fields.length > 0"\n' +
    '                   data-nodrag ng-click="toggle(this)">\n' +
    '                  <span class="glyphicon"\n' +
    '                        ng-class="{\n' +
    '                        \'glyphicon-chevron-right\': collapsed,\n' +
    '                        \'glyphicon-chevron-down\': !collapsed\n' +
    '                      }">\n' +
    '                  </span>\n' +
    '                </a>\n' +
    '                <span ng-hide="fieldgroup.label == \'\'" data-drag-delay="100" >{{fieldgroup.label}}</span>\n' +
    '                <span ng-show="fieldgroup.label == \'\'" data-drag-delay="100" >Felder ohne Gruppe</span>\n' +
    '                <input type="text" data-nodrag\n' +
    '                       ng-model-options="{ updateOn: \'default blur\', debounce: { default: 500, blur: 0 } }"\n' +
    '                       class="form-control small-input"\n' +
    '                       ng-model="fieldgroup.label"/>\n' +
    '                <!--\n' +
    '                <span ng-hide="fieldgroup.label == \'\'">{{fieldgroup.label}} <input type="text"\n' +
    '                                                                                   ng-model-options="{ updateOn: \'blur default\' }"\n' +
    '                                                                                   class="form-control small-input"\n' +
    '                                                                                   ng-model="fieldgroup.label"/></span>\n' +
    '                <span ng-show="fieldgroup.label == \'\'">Felder ohne Gruppe\n' +
    '                  <input type="text" class="form-control small-input" ng-model="fieldgroup.label" ng-model-options="{ updateOn: \'blur\' }\n' +
    '                         uib-tooltip="Leer lassen damit Felder direkt ohne Feldgruppe erscheinen"/></span>\n' +
    '                -->\n' +
    '                <a class="pull-right btn btn-danger btn-xs"\n' +
    '                   uib-tooltip="Feldgruppe Löschen"\n' +
    '                   data-nodrag ng-click="tpeCtrl.remove(this)">\n' +
    '                  <span class="fa fa-remove"></span>\n' +
    '                </a>\n' +
    '                <a class="pull-right btn btn-success btn-xs"\n' +
    '                   uib-tooltip="Neues Feld in diese Gruppe einfügen"\n' +
    '                   data-nodrag ng-click="tpeCtrl.newSubItem(this)"\n' +
    '                   style="margin-right: 8px;">\n' +
    '                  <span class="fa fa-plus"></span>\n' +
    '                </a>\n' +
    '\n' +
    '              </div>\n' +
    '              <ol ui-tree-nodes="" ng-model="fieldgroup.fields" ng-class="{\'hidden\': !collapsed}">\n' +
    '                <li ui-tree-node\n' +
    '                    ng-repeat="field in fieldgroup.fields"\n' +
    '                    ng-include="\'field_renderer.html\'">\n' +
    '                  <div ui-tree-handle>\n' +
    '                    {{field.label}}\n' +
    '                  </div>\n' +
    '              </ol>\n' +
    '            </li>\n' +
    '            <!-- ui-tree-node -->\n' +
    '          </ol>\n' +
    '          <!-- ui-tree-nodes -->\n' +
    '        </div>\n' +
    '        <!-- ui-tree -->\n' +
    '\n' +
    '        <div ng-hide="tpeCtrl.tpl.template.includefindings">\n' +
    '          <h4>Zusatzfelder</h4>\n' +
    '          <!-- pseduo tree for named fields -->\n' +
    '          <div ui-tree data-nodrop-enabled="true" data-drag-enabled="false" class="mtree">\n' +
    '            <ol ui-tree-nodes="" ng-model="tpeCtrl.tpl.template" data-nodrop-enabled="true" data-drag-enabled="false">\n' +
    '              <li ui-tree-node ng-model="tpeCtrl.tpl.template.biopsy"\n' +
    '                  ng-show="tpeCtrl.tpl.template.biopsy"\n' +
    '                  data-drag-enabled="false"\n' +
    '                  ng-include="\'biopsy_renderer.html\'">\n' +
    '                <div ui-tree-handle>\n' +
    '                  {{tpeCtrl.tpl.template.biopsy.label}}\n' +
    '                </div>\n' +
    '              </li>\n' +
    '              <li ui-tree-node ng-model="tpeCtrl.tpl.template.comment"\n' +
    '                  ng-show="tpeCtrl.tpl.template.comment"\n' +
    '                  data-drag-enabled="false"\n' +
    '                  ng-include="\'comment_renderer.html\'">\n' +
    '                <div ui-tree-handle>\n' +
    '                  {{tpeCtrl.tpl.template.comment.label}}\n' +
    '                </div>\n' +
    '              </li>\n' +
    '              <li ui-tree-node\n' +
    '                  ng-model="tpeCtrl.tpl.template.diagnosis"\n' +
    '                  ng-show="tpeCtrl.tpl.template.diagnosis"\n' +
    '                  data-drag-enabled="false"\n' +
    '                  ng-include="\'diagnosis_renderer.html\'">\n' +
    '                <div ui-tree-handle>\n' +
    '                  {{tpeCtrl.tpl.template.diagnosis.label}}\n' +
    '                </div>\n' +
    '              </li>\n' +
    '              <li ui-tree-node ng-model="tpeCtrl.tpl.template.recommendation"\n' +
    '                  ng-show="tpeCtrl.tpl.template.recommendation"\n' +
    '                  data-drag-enabled="false"\n' +
    '                  ng-include="\'recommendation_renderer.html\'">\n' +
    '                <div ui-tree-handle>\n' +
    '                  {{tpeCtrl.tpl.template.recommendation.label}}\n' +
    '                </div>\n' +
    '              </li>\n' +
    '              <div ng-hide="tpeCtrl.tpl.template.biopsy"\n' +
    '                   class="btn btn-success"\n' +
    '                   ng-click="tpeCtrl.addNamedField(\'biopsy\')">Biopsiefeld hinzufügen\n' +
    '              </div>\n' +
    '\n' +
    '              <div ng-hide="tpeCtrl.tpl.template.diagnosis"\n' +
    '                   class="btn btn-success"\n' +
    '                   ng-click="tpeCtrl.addNamedField(\'diagnosis\')">Diagnosefeld hinzufügen\n' +
    '              </div>\n' +
    '              <div ng-hide="tpeCtrl.tpl.template.recommendation"\n' +
    '                   class="btn btn-success"\n' +
    '                   ng-click="tpeCtrl.addNamedField(\'recommendation\')">(Therapie-) Empfehlungsfeld hinzufügen\n' +
    '              </div>\n' +
    '              <div ng-hide="tpeCtrl.tpl.template.comment"\n' +
    '                   class="btn btn-success"\n' +
    '                   ng-click="tpeCtrl.addNamedField(\'comment\')">Kommentarfeld hinzufügen\n' +
    '              </div>\n' +
    '            </ol>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <input type="submit" class="btn btn-success" ng-click="tpeCtrl.tpl.$save()" value="Speichern"/>\n' +
    '        <input type="submit" class="btn btn-success" ng-click="tpeCtrl.tpl.$save(); tpeCtrl.closeModal();"\n' +
    '               value="Speichern und beenden"/>\n' +
    '      </form>\n' +
    '\n' +
    '      </form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</script><!-- ende modal template -->\n' +
    '\n' +
    '\n' +
    '<!-- Nested field render template -->\n' +
    '<script type="text/ng-template" id="field_renderer.html">\n' +
    '  <div ui-tree-handle class="tree-node tree-node-content">\n' +
    '    <div>\n' +
    '      <div>\n' +
    '        {{field.label}}\n' +
    '        <a class="pull-right btn btn-danger btn-xs"\n' +
    '           uib-tooltip="Feld entfernen"\n' +
    '           data-nodrag ng-click="tpeCtrl.remove(this)">\n' +
    '          <span class="glyphicon glyphicon-remove"></span>\n' +
    '        </a>\n' +
    '        <a class="pull-right btn btn-primary btn-xs"\n' +
    '           uib-tooltip="Feld editieren"\n' +
    '           data-nodrag ng-click="ecollapsed=!ecollapsed"\n' +
    '           style="margin-right: 8px;">\n' +
    '            <span\n' +
    '                class="fa fa-edit"></span>\n' +
    '        </a>\n' +
    '\n' +
    '\n' +
    '        <div id="meta{{$index}}" ng-class="{\'hidden\':ecollapsed}" ng-init="ecollapsed = true">\n' +
    '          <div class="form-group">\n' +
    '            <label>Feldname\n' +
    '              <input type="text" ng-model="field.label" class="form-control"></label>\n' +
    '          </div>\n' +
    '          <div class="form-group">\n' +
    '            <label uib-tooltip="Wird automatisch in der Vorlage vorausgefüllt">Standardwert(e)\n' +
    '              <tags-input ng-model="field.avalues"\n' +
    '                          display-property="name"\n' +
    '                          add-on-comma="false"\n' +
    '                          enableEditingLastTag="true"\n' +
    '                          placeholder="Neuer Eintrag <Eingabetaste>"\n' +
    '                          replace-spaces-with-dashes="false"\n' +
    '                          template="tag-template">\n' +
    '              </tags-input>\n' +
    '            </label>\n' +
    '          </div>\n' +
    '          <div class="form-group">\n' +
    '            <label>Mögliche Werte\n' +
    '              <tags-input ng-model="field.values"\n' +
    '                          display-property="name"\n' +
    '                          add-on-comma="false"\n' +
    '                          enableEditingLastTag="true"\n' +
    '                          placeholder="Neuer Eintrag <Eingabetaste>"\n' +
    '                          replace-spaces-with-dashes="false"\n' +
    '                          template="tag-template">\n' +
    '              </tags-input>\n' +
    '            </label>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</script>\n' +
    '\n' +
    '<!-- Nested biopsy render template -->\n' +
    '<script type="text/ng-template" id="biopsy_renderer.html">\n' +
    '  <div ui-tree-handle class="tree-node tree-node-content">\n' +
    '    <div>\n' +
    '      <div class="row">\n' +
    '        <div class="col-md-10">\n' +
    '          {{tpeCtrl.tpl.template.biopsy.label}}\n' +
    '          <div ng-class="{\'hidden\':ecollapsed}" ng-init="ecollapsed = true">\n' +
    '            <div class="form-group">\n' +
    '              <label>Feldname\n' +
    '                <input type="text" ng-model="tpeCtrl.tpl.template.biopsy.label"\n' +
    '                       ng-blur="tpeCtrl.tpl.$save().$save()" class="form-control"></label>\n' +
    '            </div>\n' +
    '            <div class="form-group">\n' +
    '              <label>Defaultwert(e)\n' +
    '                <tags-input ng-model="tpeCtrl.tpl.template.biopsy.avalues"\n' +
    '                            display-property="name"\n' +
    '                            add-on-comma="false"\n' +
    '                            enableEditingLastTag="true"\n' +
    '                            placeholder="Neuer Eintrag <Eingabetaste>"\n' +
    '                            replace-spaces-with-dashes="false"\n' +
    '                            template="tag-template">\n' +
    '                </tags-input>\n' +
    '              </label>\n' +
    '            </div>\n' +
    '            <div class="form-group">\n' +
    '              <label>Mögliche Werte (mit Eingabetaste trennen)\n' +
    '                <tags-input ng-model="tpeCtrl.tpl.template.biopsy.values"\n' +
    '                            display-property="name"\n' +
    '                            add-on-comma="false"\n' +
    '                            enableEditingLastTag="true"\n' +
    '                            placeholder="Neuer Eintrag <Eingabetaste>"\n' +
    '                            replace-spaces-with-dashes="false"\n' +
    '                            template="tag-template">\n' +
    '                </tags-input>\n' +
    '              </label>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="col-md-2">\n' +
    '          <a class="pull-right btn btn-danger btn-xs" data-nodrag ng-click="tpeCtrl.removeNamedField(\'biopsy\')">\n' +
    '            <span class="fa fa-remove"></span>\n' +
    '          </a>\n' +
    '          <a class="pull-right btn btn-primary btn-xs" data-nodrag ng-click="ecollapsed=!ecollapsed"\n' +
    '             style="margin-right: 8px;">\n' +
    '            <span\n' +
    '                class="fa fa-edit"></span>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</script>\n' +
    '\n' +
    '<!-- Nested comment render template -->\n' +
    '<script type="text/ng-template" id="comment_renderer.html">\n' +
    '  <div ui-tree-handle class="tree-node tree-node-content">\n' +
    '    <div>\n' +
    '      <div class="row">\n' +
    '        <div class="col-md-10">\n' +
    '          {{tpeCtrl.tpl.template.comment.label}}\n' +
    '          <div ng-class="{\'hidden\':ecollapsed}" ng-init="ecollapsed = true">\n' +
    '            <div class="form-group">\n' +
    '              <label>Feldname\n' +
    '                <input type="text" ng-model="tpeCtrl.tpl.template.comment.label"\n' +
    '                       ng-blur="tpeCtrl.tpl.$save().$save()" class="form-control"></label>\n' +
    '            </div>\n' +
    '            <div class="form-group">\n' +
    '              <label>Defaultwert(e)\n' +
    '                <tags-input ng-model="tpeCtrl.tpl.template.comment.avalues"\n' +
    '                            display-property="name"\n' +
    '                            add-on-comma="false"\n' +
    '                            enableEditingLastTag="true"\n' +
    '                            placeholder="Neuer Eintrag <Eingabetaste>"\n' +
    '                            replace-spaces-with-dashes="false"\n' +
    '                            template="tag-template">\n' +
    '                </tags-input>\n' +
    '              </label>\n' +
    '            </div>\n' +
    '            <div class="form-group">\n' +
    '              <label>Mögliche Werte (mit Komma oder Eingabetaste trennen)\n' +
    '                <tags-input ng-model="tpeCtrl.tpl.template.comment.values"\n' +
    '                            display-property="name"\n' +
    '                            add-on-comma="false"\n' +
    '                            enableEditingLastTag="true"\n' +
    '                            placeholder="Neuer Eintrag <Eingabetaste>"\n' +
    '                            replace-spaces-with-dashes="false"\n' +
    '                            template="tag-template">\n' +
    '                </tags-input>\n' +
    '              </label>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="col-md-2">\n' +
    '          <a class="pull-right btn btn-danger btn-xs" data-nodrag ng-click="tpeCtrl.removeNamedField(\'comment\')">\n' +
    '            <span class="fa fa-remove"></span>\n' +
    '          </a>\n' +
    '          <a class="pull-right btn btn-primary btn-xs" data-nodrag ng-click="ecollapsed=!ecollapsed"\n' +
    '             style="margin-right: 8px;">\n' +
    '            <span\n' +
    '                class="fa fa-edit"></span>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</script>\n' +
    '\n' +
    '<!-- Nested diagnosis render template -->\n' +
    '<script type="text/ng-template" id="diagnosis_renderer.html">\n' +
    '  <div ui-tree-handle class="tree-node tree-node-content">\n' +
    '    <div>\n' +
    '      <div class="row">\n' +
    '        <div class="col-md-10">\n' +
    '          {{tpeCtrl.tpl.template.diagnosis.label}}\n' +
    '          <div ng-class="{\'hidden\':ecollapsed}" ng-init="ecollapsed = true">\n' +
    '            <div class="form-group">\n' +
    '              <label>Feldname\n' +
    '                <input type="text" ng-model="tpeCtrl.tpl.template.diagnosis.label"\n' +
    '                       ng-blur="tpeCtrl.tpl.$save().$save()" class="form-control"></label>\n' +
    '            </div>\n' +
    '            <div class="form-group">\n' +
    '              <label>Defaultwert\n' +
    '                <tags-input ng-model="tpeCtrl.tpl.template.diagnosis.avalues"\n' +
    '                            display-property="name"\n' +
    '                            add-on-comma="false"\n' +
    '                            enableEditingLastTag="true"\n' +
    '                            placeholder="Neuer Eintrag <Eingabetaste>"\n' +
    '                            replace-spaces-with-dashes="false"\n' +
    '                            template="tag-template">\n' +
    '                </tags-input>\n' +
    '              </label>\n' +
    '            </div>\n' +
    '            <div class="form-group">\n' +
    '              <label>Mögliche Werte (mit Komma oder Eingabetaste trennen)\n' +
    '                <tags-input ng-model="tpeCtrl.tpl.template.diagnosis.values"\n' +
    '                            display-property="name"\n' +
    '                            add-on-comma="false"\n' +
    '                            enableEditingLastTag="true"\n' +
    '                            placeholder="Neuer Eintrag <Eingabetaste>"\n' +
    '                            replace-spaces-with-dashes="false"\n' +
    '                            template="tag-template">\n' +
    '                </tags-input>\n' +
    '              </label>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="col-md-2">\n' +
    '          <a class="pull-right btn btn-danger btn-xs" data-nodrag ng-click="tpeCtrl.removeNamedField(\'diagnosis\')">\n' +
    '            <span class="fa fa-remove"></span>\n' +
    '          </a>\n' +
    '          <a class="pull-right btn btn-primary btn-xs" data-nodrag ng-click="ecollapsed=!ecollapsed"\n' +
    '             style="margin-right: 8px;">\n' +
    '            <span\n' +
    '                class="fa fa-edit"></span>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</script>\n' +
    '\n' +
    '<!-- Nested recommendation render template -->\n' +
    '<script type="text/ng-template" id="recommendation_renderer.html">\n' +
    '  <div ui-tree-handle class="tree-node tree-node-content">\n' +
    '    <div>\n' +
    '      <div class="row">\n' +
    '        <div class="col-md-10">\n' +
    '          {{tpeCtrl.tpl.template.recommendation.label}}\n' +
    '          <div ng-class="{\'hidden\':ecollapsed}" ng-init="ecollapsed = true">\n' +
    '            <div class="form-group">\n' +
    '              <label>Feldname\n' +
    '                <input type="text" ng-model="tpeCtrl.tpl.template.recommendation.label"\n' +
    '                       ng-blur="tpeCtrl.tpl.$save().$save()" class="form-control"></label>\n' +
    '            </div>\n' +
    '            <div class="form-group">\n' +
    '              <label>Defaultwert(e)\n' +
    '                <tags-input ng-model="tpeCtrl.tpl.template.recommendation.avalues"\n' +
    '                            display-property="name"\n' +
    '                            add-on-comma="false"\n' +
    '                            enableEditingLastTag="true"\n' +
    '                            placeholder="Neuer Eintrag <Eingabetaste>"\n' +
    '                            replace-spaces-with-dashes="false"\n' +
    '                            template="tag-template">\n' +
    '                </tags-input>\n' +
    '              </label>\n' +
    '            </div>\n' +
    '            <div class="form-group">\n' +
    '              <label>Mögliche Werte (mit Komma oder Eingabetaste trennen)\n' +
    '                <tags-input ng-model="tpeCtrl.tpl.template.recommendation.values"\n' +
    '                            display-property="name"\n' +
    '                            add-on-comma="false"\n' +
    '                            enableEditingLastTag="true"\n' +
    '                            placeholder="Neuer Eintrag <Eingabetaste>"\n' +
    '                            replace-spaces-with-dashes="false"\n' +
    '                            template="tag-template">\n' +
    '                </tags-input>\n' +
    '              </label>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="col-md-2">\n' +
    '          <a class="pull-right btn btn-danger btn-xs" data-nodrag ng-click="tpeCtrl.removeNamedField(\'recommendation\')">\n' +
    '            <span class="fa fa-remove"></span>\n' +
    '          </a>\n' +
    '          <a class="pull-right btn btn-primary btn-xs" data-nodrag ng-click="ecollapsed=!ecollapsed"\n' +
    '             style="margin-right: 8px;">\n' +
    '            <span\n' +
    '                class="fa fa-edit"></span>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</script>\n' +
    '\n' +
    '\n' +
    '<!-- ngTagsInput tag formatting -->\n' +
    '<script type="text/ng-template" id="tag-template">\n' +
    '  <div class="tag-template">\n' +
    '    <div class="right-panel">\n' +
    '      <span>{{$getDisplayText()}}</span>\n' +
    '      <a class="remove-button" ng-click="$removeTag()">&#10006;</a>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</script>\n' +
    '\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('examtemplate/examtemplate.tpl.html',
    '<exam-starter></exam-starter>\n' +
    '<div ui-view="exameditor"></div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('examination/tags.html',
    '<!-- This a template for ng-tags-input directive -->\n' +
    '\n' +
    '<div class="decipher-tags" data-ng-mousedown="selectArea()">\n' +
    '\n' +
    '  <div class="decipher-tags-taglist">\n' +
    '    <span data-ng-repeat="tag in tags|orderBy:orderBy"\n' +
    '          data-ng-mousedown="$event.stopPropagation()">\n' +
    '      <ng-include src="options.tagTemplateUrl"></ng-include>\n' +
    '    </span>\n' +
    '  </div>\n' +
    '\n' +
    '  <span class="container-fluid" data-ng-show="toggles.inputActive">\n' +
    '    <input ng-if="!srcTags.length"\n' +
    '           type="text"\n' +
    '           data-ng-model="inputTag"\n' +
    '           class="decipher-tags-input"/>\n' +
    '    <!-- may want to fiddle with limitTo here, but it was inhibiting my results\n' +
    '    so perhaps there is another way -->\n' +
    '    <input ng-if="srcTags.length"\n' +
    '           type="text"\n' +
    '           data-ng-model="inputTag"\n' +
    '           class="decipher-tags-input"\n' +
    '           data-typeahead="stag as stag.name for stag in srcTags|filter:$viewValue|orderBy:orderBy"\n' +
    '           data-typeahead-input-formatter="{{typeaheadOptions.inputFormatter}}"\n' +
    '           data-typeahead-loading="{{typeaheadOptions.loading}}"\n' +
    '           data-typeahead-min-length="{{typeaheadOptions.minLength}}"\n' +
    '           data-typeahead-template-url="{{typeaheadOptions.templateUrl}}"\n' +
    '           data-typeahead-wait-ms="{{typeaheadOptions.waitMs}}"\n' +
    '\n' +
    '           data-typeahead-editable="{{typeaheadOptions.allowsEditable}}"\n' +
    '           data-typeahead-on-select="add($item) && selectArea() && typeaheadOptions.onSelect()"\n' +
    '      />\n' +
    '\n' +
    '  </span>\n' +
    '</div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('examination/tag.html',
    '<!-- This a template for ng-tags-input directive -->\n' +
    '\n' +
    '<span class="decipher-tags-tag"\n' +
    '      data-ng-class="getClasses(tag)">{{tag.name}}\n' +
    '      <i class="icon-remove"\n' +
    '         data-ng-click="remove(tag)">\n' +
    '      </i>\n' +
    '</span>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('examination/file-picker-directive.tpl.html',
    '<!-- This is a very important hack: DO NOT REMOVE this totally pointless invisible bindings -->\n' +
    '<!-- Removal of these bindings will cause exam and consult close detection to fail -->\n' +
    '<span style="visibility: hidden">Consult Status patient: <span ng-bind=\'fpCtrl.patient.inconsult\'></span> </span>\n' +
    '<span style="visibility: hidden">Consult Status openConsult: <span\n' +
    '    ng-bind=\'fpCtrl.patient.openConsult.open\'></span> </span>\n' +
    '<span style="visibility: hidden">Exam Status patient: <span ng-bind=\'fpCtrl.patient.inexam\'></span> </span>\n' +
    '<span style="visibility: hidden">Exam Status openExam: <span ng-bind=\'fpCtrl.patient.openExam.open\'></span> </span>\n' +
    '<span style="visibility: hidden">openExam type: <span\n' +
    '    ng-bind=\'fpCtrl.patient.openExam.template.displayname\'></span> </span>\n' +
    '<span style="visibility: hidden">Consultstate: <span ng-bind=\'fpCtrl.patient.consultstate\'></span> </span>\n' +
    '<span style="visibility: hidden">Checkoutstate: <span ng-bind=\'fpCtrl.patient.checkoutstate\'></span> </span>\n' +
    '\n' +
    '\n' +
    '<!-- not in consult -->\n' +
    '<div ng-hide="fpCtrl.patient.inconsult == true && fpCtrl.patient.openConsult.open === true">\n' +
    '  <div class="alert alert-info">\n' +
    '    <h4>Die Konsultation wurde von anderer Stelle beendet</h4>\n' +
    '    <span class="btn btn-primary" ng-click="fpCtrl.$state.go(\'patientrecord.history\')">Zur Patienten-Historie wechseln</span>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<!-- not in exam -->\n' +
    '<div ng-hide="fpCtrl.patient.inexam == true && fpCtrl.patient.openExam.open === true">\n' +
    '  <div class="alert alert-info">\n' +
    '    <h4>Die Untersuchung wurde von anderer Stelle beendet</h4>\n' +
    '    <span class="btn btn-primary" ng-click="fpCtrl.$state.go(\'patientrecord.current.consult\')">Zur Konsultation wechseln</span>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<!-- in consult, already in checkout-->\n' +
    '<div\n' +
    '    ng-show="fpCtrl.patient.inconsult == true && fpCtrl.patient.openConsult.open === true && fpCtrl.patient.incheckout === true">\n' +
    '  <div class="alert alert-info">\n' +
    '    <h4>Diese Konsultation wird gerade abgeschlossen</h4>\n' +
    '    <span class="btn btn-primary" ng-click="fpCtrl.$state.go(\'patientrecord.checkout.\' + fpCtrl.patient.checkoutstate)">\n' +
    '      Zum Konsultationsabschluß wechseln\n' +
    '    </span>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div ng-show="fpCtrl.patient.inconsult == true && fpCtrl.patient.openConsult.open === true && fpCtrl.patient.incheckout !== true &&\n' +
    '     fpCtrl.patient.inexam == true && fpCtrl.patient.openExam.open === true &&\n' +
    '     fpCtrl.$state.current.name !== \'patientrecord.current.\' + fpCtrl.patient.consultstate">\n' +
    '  <div class="alert alert-info">\n' +
    '    <h4>Diese Untersuchung lädt gerade Bilder/Daten</h4>\n' +
    '    <span class="btn btn-primary" ng-click="fpCtrl.$state.go(\'patientrecord.current.\' + fpCtrl.patient.consultstate)">\n' +
    '      Zum Daten/Bilderimport wechseln\n' +
    '    </span>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '<!-- In consult, inexam, not already in checkout, right exam-consultstate -->\n' +
    '<div ng-show="fpCtrl.patient.inconsult == true && fpCtrl.patient.openConsult.open === true && fpCtrl.patient.incheckout !== true &&\n' +
    'fpCtrl.patient.inexam == true && fpCtrl.patient.openExam.open === true &&\n' +
    'fpCtrl.$state.current.name === \'patientrecord.current.\' + fpCtrl.patient.consultstate">\n' +
    '\n' +
    '<!-- Waiting for file to load -->\n' +
    '<div class="alert alert-info" ng-hide="fpCtrl.patient.openExam.filesloaded">\n' +
    '  <h3>Die Dateien werden geladen ...</h3>\n' +
    '  <h4 ng-show="fpCtrl.errorcondition">{{fpCtrl.errorcondition}} <span class="btn btn-danger" ng-click="fpCtrl.close()">Abbrechen</span> </h4>\n' +
    '</div>\n' +
    '\n' +
    '<div  ng-show="fpCtrl.patient.openExam.filesloaded">\n' +
    '  <div class="row spacer-top spacer-bottom">\n' +
    '    <div class="col-md-2" style="text-align: right">\n' +
    '    <a class="btn btn-primary" ng-click="fpCtrl.previous()">\n' +
    '      ‹ Zur&uuml;ck\n' +
    '    </a>\n' +
    '    </div>\n' +
    '    <div class="col-md-6">\n' +
    '    <form class="form-horizontal">\n' +
    '        <span class="input-group">\n' +
    '        <span class="input-group-btn">\n' +
    '          <button class="btn btn-danger" type="button" ng-click="fpCtrl.fileDelete()" uib-tooltip="Löschen">\n' +
    '            <i class="fa fa-remove"></i>\n' +
    '          </button>\n' +
    '        </span>\n' +
    '          <input type="text" class="form-control" ng-model="fpCtrl.patient.openExam.files[fpCtrl.image].label"\n' +
    '                 id="label" autofocus\n' +
    '                 placeholder="Bezeichnung">\n' +
    '          <span class="input-group-btn">\n' +
    '            <button class="btn btn-success" type="submit" ng-click="fpCtrl.fileLabel()"\n' +
    '                    uib-tooltip="In Befund übernehmen">\n' +
    '              <i class="fa fa-plus"></i>\n' +
    '            </button>\n' +
    '          </span>\n' +
    '        </span>\n' +
    '    </form>\n' +
    '    </div>\n' +
    '    <div class="col-md-2">\n' +
    '    <a class="btn btn-primary" ng-click="fpCtrl.next()">\n' +
    '      Weiter ›\n' +
    '    </a>\n' +
    '      </div>\n' +
    '      <div class="col-md-2">\n' +
    '      <a class="btn btn-danger" ng-click="fpCtrl.close()">\n' +
    '        Abschließen\n' +
    '      </a>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div style="text-align: center" class="spacer-top">\n' +
    '  <img src="{{\'/routes/files/filereader/\' + fpCtrl.patient.openExam.files[fpCtrl.image].fileid}}">\n' +
    '  </div>\n' +
    '\n' +
    '</div>\n' +
    '</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('examination/examination.tpl.html',
    '<h2>examination</h2>\n' +
    '<p>{{examination.ctrlName}}</p>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('examination/exameditor-fieldgroup.tpl.html',
    '\n' +
    '<!-- labelled groups -->\n' +
    '<div class="panel panel-default" ng-hide="efgCtrl.efieldgroup.label == \'\'" tabindex="-1">\n' +
    '  <div class="panel-heading"  tabindex="-1"\n' +
    '       ng-model="efgCtrl.efieldgroup.label">{{efgCtrl.efieldgroup.label}}</div>\n' +
    '  <div class="panel-body"  tabindex="-1">\n' +
    '    <div ng-style="{\'margin-left\': efgCtrl.efieldgroup.marginleft}">\n' +
    '      <div ng-repeat="field in efgCtrl.efieldgroup.fields">\n' +
    '        <field efield="field" ti="{{$index}}" gi="{{efgCtrl.gi}}"></field>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<!-- unlabelled groups -->\n' +
    '<div ng-show="efgCtrl.efieldgroup.label == \'\'"  tabindex="-1">\n' +
    '  <div ng-repeat="field in efgCtrl.efieldgroup.fields">\n' +
    '    <field efield="field" ti="{{$index}}" gi="{{efgCtrl.gi}}"></field>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('examination/exameditor-field.tpl.html',
    '<div class="form-group">\n' +
    '  <label ng-model="efCtrl.efield.label" tabindex="-1">{{efCtrl.efield.label}}</label>\n' +
    '  <tags-input ng-model="efCtrl.efield.avalues"\n' +
    '              display-property="name"\n' +
    '              placeholder="Tippen zum Vervollständigen"\n' +
    '              add-on-comma="false"\n' +
    '              min-length="1"\n' +
    '              id="{{$id}}"\n' +
    '              tabindex="{{efCtrl.fti}}"\n' +
    '              enable-editing-last-tag="true"\n' +
    '              ng-blur="efCtrl.save()"\n' +
    '              replace-spaces-with-dashes="false"\n' +
    '              template="tag-template">\n' +
    '    <auto-complete source="efCtrl.loadValues($query)"\n' +
    '                   min-length="0"\n' +
    '                   load-on-focus="true"\n' +
    '                   load-on-empty="true"\n' +
    '                   load-on-down-arrow="true"\n' +
    '                   max-results-to-show="32"\n' +
    '                   select-first-match="false"\n' +
    '                   template="autocomplete-template"></auto-complete>\n' +
    '  </tags-input>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '  <script type="text/ng-template" id="tag-template">\n' +
    '    <div class="tag-template">\n' +
    '      <div class="right-panel">\n' +
    '        <span>{{$getDisplayText()}}</span>\n' +
    '        <a class="remove-button" ng-click="$removeTag()">&#10006;</a>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </script>\n' +
    '\n' +
    '\n' +
    '  <script type="text/ng-template" id="autocomplete-template">\n' +
    '    <div class="autocomplete-template">\n' +
    '      <div>\n' +
    '        <span ng-bind-html="$highlight($getDisplayText())">{{name}}</span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </script>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('examination/exameditor-directive.tpl.html',
    '<!-- This is a very important hack: DO NOT REMOVE this totally pointless invisible bindings -->\n' +
    '<!-- Removal of these bindings will cause exam and consult close detection to fail -->\n' +
    '<span style="visibility: hidden">Consult Status patient: <span ng-bind=\'exeCtrl.patient.inconsult\'></span> </span>\n' +
    '<span style="visibility: hidden">Consult Status openConsult: <span\n' +
    '    ng-bind=\'exeCtrl.patient.openConsult.open\'></span> </span>\n' +
    '<span style="visibility: hidden">Exam Status patient: <span ng-bind=\'exeCtrl.patient.inexam\'></span> </span>\n' +
    '<span style="visibility: hidden">Exam Status openExam: <span ng-bind=\'exeCtrl.patient.openExam.open\'></span> </span>\n' +
    '<span style="visibility: hidden">openExam type: <span\n' +
    '    ng-bind=\'exeCtrl.patient.openExam.template.displayname\'></span> </span>\n' +
    '<span style="visibility: hidden">Consultstate: <span ng-bind=\'exeCtrl.patient.consultstate\'></span> </span>\n' +
    '<span style="visibility: hidden">Checkoutstate: <span ng-bind=\'exeCtrl.patient.checkoutstate\'></span> </span>\n' +
    '\n' +
    '\n' +
    '<!-- not in consult -->\n' +
    '<div ng-hide="exeCtrl.patient.inconsult === true && exeCtrl.patient.openConsult.open === true">\n' +
    '  <div class="alert alert-info">\n' +
    '    <h4>Die Konsultation wurde von anderer Stelle beendet</h4>\n' +
    '    <span class="btn btn-primary" ng-click="exeCtrl.$state.go(\'patientrecord.history\')">Zur Patienten-Historie wechseln</span>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<!-- not in exam -->\n' +
    '<div ng-hide="exeCtrl.patient.inexam === true && exeCtrl.patient.openExam.open === true">\n' +
    '  <div class="alert alert-info">\n' +
    '    <h4>Die Untersuchung wurde von anderer Stelle beendet</h4>\n' +
    '    <span class="btn btn-primary" ng-click="exeCtrl.$state.go(\'patientrecord.current.consult\')">Zur Konsultation wechseln</span>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<!-- in consult, already in checkout-->\n' +
    '<div\n' +
    '    ng-show="exeCtrl.patient.inconsult === true && exeCtrl.patient.openConsult.open === true && exeCtrl.patient.incheckout === true">\n' +
    '  <div class="alert alert-info">\n' +
    '    <h4>Diese Konsultation wird gerade abgeschlossen</h4>\n' +
    '    <span class="btn btn-primary" ng-click="exeCtrl.$state.go(\'patientrecord.checkout.\' + exeCtrl.patient.checkoutstate)">\n' +
    '      Zum Konsultationsabschluß wechseln\n' +
    '    </span>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<!-- Daten/bilderimport  -->\n' +
    '<div ng-show="exeCtrl.patient.inconsult === true && exeCtrl.patient.openConsult.open === true && exeCtrl.patient.incheckout !== true &&\n' +
    '     exeCtrl.patient.inexam === true && exeCtrl.patient.openExam.open === true &&\n' +
    '     exeCtrl.$state.current.name !== \'patientrecord.current.\' + exeCtrl.patient.consultstate">\n' +
    '  <div class="alert alert-info">\n' +
    '    <h4>Diese Untersuchung lädt gerade Bilder/Daten</h4>\n' +
    '    <h4>Beenden Sie den Datenimport dort wo Sie ihn begonnen haben</h4>\n' +
    '   <!-- Does not work -> images are duplicated, other browser is not resresehd -->\n' +
    '    <!-- <span class="btn btn-primary" ng-click="exeCtrl.$state.go(\'patientrecord.current.\' + exeCtrl.patient.consultstate)">\n' +
    '      Zum Daten/Bilderimport wechseln\n' +
    '    </span>-->\n' +
    '    <span class="btn btn-primary" ng-click="exeCtrl.$state.go(\'patientrecord.current.consult\')">\n' +
    '      Zur Konsultation wechseln\n' +
    '    </span>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '<!-- In consult, inexam, not already in checkout, -> correct exam-consultstate -->\n' +
    '<div ng-show="exeCtrl.patient.inconsult === true && exeCtrl.patient.openConsult.open === true && exeCtrl.patient.incheckout !== true &&\n' +
    'exeCtrl.patient.inexam === true && exeCtrl.patient.openExam.open === true &&\n' +
    'exeCtrl.$state.current.name === \'patientrecord.current.\' + exeCtrl.patient.consultstate">\n' +
    '\n' +
    '  <form name="exameditorform">\n' +
    '    <h3 ng-bind="exeCtrl.patient.openExam.template.displayname"></h3>\n' +
    '    \n' +
    '    <div ng-hide="exeCtrl.patient.openExam.open === true">\n' +
    '      <div class="alert alert-info"><h4>Die Untersuchung wurde mittlerweile von anderer Stelle geschlossen</h4>\n' +
    '        <span ng-show="exeCtrl.patient.inconsult === true" ng-click="exeCtrl.backToConsult()" class="btn btn-primary">Zur Konsultation wechseln</span>\n' +
    '        <span ng-show="exeCtrl.patient.inconsult === false" ng-click="exeCtrl.backToRecord()" class="btn btn-primary">Zur Patientenakte wechseln</span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    \n' +
    '    <!-- This is a very important hack: DO NOT REMOVE this totally pointless invisible heading -->\n' +
    '    <!-- Removal of this heading will cause exam close detection to fail -->\n' +
    '    <h4 style="visibility: hidden">Status: <span ng-bind=\'exeCtrl.patient.openExam.open\'></span></h4>\n' +
    '    \n' +
    '    <div ng-show="exeCtrl.patient.openExam.open === true">\n' +
    '      <!-- fieldgroups -->\n' +
    '      <div ng-repeat="fieldgroup in exeCtrl.patient.openExam.template.fieldgroups">\n' +
    '        <fieldgroup efieldgroup="fieldgroup" gi="{{$index}}"></fieldgroup>\n' +
    '      </div>\n' +
    '      \n' +
    '      \n' +
    '      <!-- Biopsy fields -->\n' +
    '      <!-- biopsy format in db\n' +
    '          "biopsy" : {\n' +
    '            "label" : "Biopsien",\n' +
    '            "values" : [\n' +
    '              {\n' +
    '                "name" : "Magenpförtner"\n' +
    '              },\n' +
    '              {\n' +
    '                "name" : "Magenrückwand"\n' +
    '              }\n' +
    '            ],\n' +
    '            "biopsies" : [\n' +
    '              {\n' +
    '                "label" : "",\n' +
    '                "avalues" : "",\n' +
    '                "values" : ""\n' +
    '              }\n' +
    '            ]\n' +
    '          }\n' +
    '      -->\n' +
    '      \n' +
    '      \n' +
    '      <!-- Biopsy fields -->\n' +
    '      <div ng-show="exeCtrl.patient.openExam.template.biopsy" class="panel panel-default">\n' +
    '        <div class="panel-heading">Biopsien</div>\n' +
    '        <div class="panel-body">\n' +
    '          \n' +
    '          <label for="patho">Labor/Pathologie</label>\n' +
    '          <!--<doctor-search doctorresult="exeCtrl.pathology" ti="900" id="patho"></doctor-search>-->\n' +
    '  \n' +
    '          <div class="form-group" ng-hide="exeCtrl.pathology._id">\n' +
    '            <doctor-search doctorresult="exeCtrl.pathology" ti="900" id="patho"></doctor-search>\n' +
    '          </div>\n' +
    '          <div class="form-group" ng-show="exeCtrl.pathology._id">\n' +
    '            <div class="input-group">\n' +
    '              <input type="text" class="form-control"\n' +
    '                     readonly\n' +
    '                     ng-model="exeCtrl.pathology.fname">\n' +
    '              <span class="input-group-btn">\n' +
    '                    <button class="btn btn-danger"\n' +
    '                            ng-click="exeCtrl.clearRefDoctor()"><i class="fa fa-remove"></i>\n' +
    '                    </button>\n' +
    '        </span>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '  \n' +
    '  \n' +
    '  \n' +
    '  \n' +
    '          <label for="pathdiag">Diagnose/Begründung</label>\n' +
    '          <input type="text" tabindex="901" id="pathdiag" class="form-control" ng-model="exeCtrl.pathdiag">\n' +
    '          \n' +
    '          <label for="pathcomment">Kommentar/Anweisung</label>\n' +
    '          <textarea tabindex="901" id="pathcomment" class="form-control" ng-model="exeCtrl.pathcomment"></textarea>\n' +
    '          \n' +
    '          <bfield efield="bf" bvalues="exeCtrl.patient.openExam.template.biopsy.values"\n' +
    '                  ng-init="bf.label  = \'PE \' + ($index + 1) + \' \' + exeCtrl.patient.openExam.template.type"\n' +
    '                  ng-repeat="bf in exeCtrl.patient.openExam.template.biopsy.biopsies">\n' +
    '          </bfield>\n' +
    '          <div class="form-group">\n' +
    '            <button type="submit" class="btn btn-success"\n' +
    '                    ng-click="exeCtrl.addBiopsyField()">\n' +
    '              <i class="fa fa-plus"></i> Biopsiefeld hinzufügen\n' +
    '            </button>\n' +
    '            <button type="submit" class="btn btn-primary" ng-class="{\'btn-success\': exeCtrl.labelsprinted}"\n' +
    '                    ng-click="exeCtrl.printBiopsyLabels()">\n' +
    '              <span ng-show="!exeCtrl.labelsprinted"><i class="fa fa-print"></i>&nbsp;Etiketten drucken</span>\n' +
    '              <span ng-show="exeCtrl.labelsprinted"><i class="fa fa-check"></i>&nbsp;Etiketten gedruckt</span>\n' +
    '            </button>\n' +
    '            <!-- <a class="btn btn-success"\n' +
    '                href="/routes/pdfprinter/examprint?patientid={{exeCtrl.patient._id}}&examid={{exeCtrl.patient.openExam._id}}&doctype=labels">\n' +
    '               <i class="fa fa-print"></i> Etiketten drucken</a>-->\n' +
    '            <a class="btn btn-info" target="_blank"\n' +
    '               href="/routes/pdfprinter/exampreview?patientid={{exeCtrl.patient._id}}&examid={{exeCtrl.patient.openExam._id}}&doctype=labels">\n' +
    '              <i class="fa fa-eye"></i> Etiketten als PDF</a>\n' +
    '            <!-- <div ng-show="exeCtrl.patient.openExam.template.biopsy.labelspdf" class="btn btn-success">\n' +
    '               <i class="fa fa-file-pdf-o"></i> Etiketten als PDF\n' +
    '             </div>-->\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      \n' +
    '      </div>\n' +
    '      \n' +
    '      \n' +
    '      <!-- special fields -->\n' +
    '      <field efield="exeCtrl.patient.openExam.template.diagnosis"\n' +
    '             ng-show="exeCtrl.patient.openExam.template.diagnosis"></field>\n' +
    '      <field efield="exeCtrl.patient.openExam.template.recommendation"\n' +
    '             ng-show="exeCtrl.patient.openExam.template.recommendation"></field>\n' +
    '      <field efield="exeCtrl.patient.openExam.template.comment"\n' +
    '             ng-show="exeCtrl.patient.openExam.template.comment"></field>\n' +
    '      \n' +
    '      \n' +
    '      <div class="form-group">\n' +
    '        <!--<button type="submit" class="btn btn-success" ng-click="exeCtrl.patient.openExam.$save()">Speichern</button>-->\n' +
    '        <button type="submit" class="btn btn-primary" ng-click="exeCtrl.finishExam()">Beenden</button>\n' +
    '      </div>\n' +
    '      \n' +
    '      <div class="alert alert-info" ng-show="exeCtrl.waitingforsave === true">\n' +
    '        <h3><i class="fa fa-spin fa-spinner"></i> Untersuchung wird abgeschlossen ...</h3>\n' +
    '      </div>\n' +
    '      \n' +
    '      <div class="alert alert-success" ng-show="exeCtrl.waitingforsave === false">\n' +
    '        <h3><i class="fa fa-check"></i> Untersuchung abgeschlossen!</h3>\n' +
    '      </div>\n' +
    '           \n' +
    '      <!-- comfort white space to stop exameditor from bouncing up an down on page refresh of longer forms -->\n' +
    '      <!-- only remove if found an alternative way of stopping this ery annoying behaviour! -->\n' +
    '      <div style="min-height: 2000px">&nbsp;</div>\n' +
    '    </div>\n' +
    '  \n' +
    '  \n' +
    '  </form>\n' +
    '</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('examination/exameditor-bfield.tpl.html',
    '<div class="row">\n' +
    '  <!-- Biopsie -->\n' +
    '  <div class="col-xs-12 col-sm-6">\n' +
    '\n' +
    '      <label ng-model="bfCtrl.efield.label">{{bfCtrl.efield.label}}</label>\n' +
    '      <tags-input ng-model="bfCtrl.efield.avalues"\n' +
    '                  display-property="name"\n' +
    '                  placeholder="Tippen zum Vervollständigen"\n' +
    '                  add-on-comma="false"\n' +
    '                  min-length="1"\n' +
    '                  tabindex="{{bfCtrl.tabi}}"\n' +
    '                  enable-editing-last-tag="true"\n' +
    '                  replace-spaces-with-dashes="false"\n' +
    '                  ng-blur="bfCtrl.save()"\n' +
    '                  template="tag-template">\n' +
    '        <auto-complete source="bfCtrl.loadBValues($query)"\n' +
    '                       min-length="0"\n' +
    '                       load-on-focus="true"\n' +
    '                       loadOnDownArrow="true"\n' +
    '                       load-on-empty="true"\n' +
    '                       max-results-to-show="32"\n' +
    '                       template="autocomplete-template"></auto-complete>\n' +
    '      </tags-input>\n' +
    '    </div>\n' +
    '\n' +
    '  <!-- Ergebnis -->\n' +
    '  <div class="col-xs-12 col-sm-6">\n' +
    '    <label>Ergebnis</label>\n' +
    '    <input class="form-control" ng-model="bfCtrl.efield.result" placeholder="Ergebnis der Biopsie">\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<!-- tag templates -->\n' +
    '<script type="text/ng-template" id="tag-template">\n' +
    '  <div class="tag-template">\n' +
    '    <div class="right-panel">\n' +
    '      <span>{{$getDisplayText()}}</span>\n' +
    '      <a class="remove-button" ng-click="$removeTag()">&#10006;</a>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</script>\n' +
    '\n' +
    '\n' +
    '<script type="text/ng-template" id="autocomplete-template">\n' +
    '  <div class="autocomplete-template">\n' +
    '    <div>\n' +
    '      <span ng-bind-html="$highlight($getDisplayText())">{{name}}</span>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</script>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('examination/exam-starter-directive.tpl.html',
    '<h4>Untersuchung starten</h4>\n' +
    '<div>\n' +
    '<span ng-repeat="et in esCtrl.alltemplates | filter:{ active: true } " ng-show="esCtrl.alltemplates">\n' +
    '\n' +
    '  <span class="btn btn-primary btn-spacer-top"\n' +
    '          ng-click="esCtrl.startExam(et);">{{et.template.displayname}}\n' +
    '  </span>\n' +
    '</span>\n' +
    '</div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('examination/exam-list-directive.tpl.html',
    '<h3>Untersuchungen</h3>\n' +
    '\n' +
    '<div class="alert alert-info" ng-show="exlCtrl.patExams.length == 0">Keine Untersuchungen gefunden</div>\n' +
    '<table st-table="exlCtrl.pexs" st-safe-src="exlCtrl.patExams" class="table table-striped">\n' +
    '    <thead>\n' +
    '    <tr>\n' +
    '        <th st-sort="created" st-sort-default="reverse">Datum</th>\n' +
    '        <th st-sort="type">Typ</th>\n' +
    '        <th st-sort="diagnosis">Befund</th>\n' +
    '        <th>Datei</th>\n' +
    '    </tr>\n' +
    '    <tr>\n' +
    '        <th><input st-search="created" placeholder="Suche nach Datum" class="input-sm form-control" type="search"/></th>\n' +
    '        <th><input st-search="type" placeholder="Suche nach Typ" class="input-sm form-control" type="search"/></th>\n' +
    '        <th><input st-search="diagnosis" placeholder="Suche nach Diagnose" class="input-sm form-control" type="search"/>\n' +
    '        </th>\n' +
    '        <th></th>\n' +
    '    </tr>\n' +
    '    </thead>\n' +
    '    <tbody>\n' +
    '    <tr ng-repeat="exam in exlCtrl.pexs">\n' +
    '        <td>{{exam.created | moment: \'ll\'}}</td>\n' +
    '        <td><a href="/routes/pdfreader/{{exam.docid}}" target="_blank">{{exam.type}}</a></td>\n' +
    '        <td><a href="/routes/pdfreader/{{exam.docid}}" target="_blank">{{exam.diagnosis}}</a></td>\n' +
    '        <td align="right">\n' +
    '            <!-- Biopsie eintragen -->\n' +
    '            <a ng-click="exlCtrl.editExam(exam, $index)" class="btn btn-success"\n' +
    '               ng-show="exam.template.biopsy"\n' +
    '               uib-tooltip="Biopsiebericht eintragen"><i class="fa fa-flask"></i></a>\n' +
    '            <!-- Existierendes PDF anzeigen -->\n' +
    '            <a href="/routes/pdfreader/{{exam.docid}}" target="_blank" class="btn btn-primary" uib-tooltip="Anzeigen">\n' +
    '                <i ng-show="!exlCtrl.spinners[$index]" class="fa fa-eye"></i>\n' +
    '                <i ng-show="exlCtrl.spinners[$index]" class="fa fa-spinner fa-spin"></i>\n' +
    '            </a>\n' +
    '            <!-- exisierendes PDF laden -->\n' +
    '            <a href="/routes/pdfdownload/{{exam.docid}}" class="btn btn-primary"  uib-tooltip="Herunterladen"><i class="fa fa-download"></i></a>\n' +
    '            <!-- Untersuchung nachträglich editieren, mit Rückfrage -->\n' +
    '            <a ng-really-click="exlCtrl.editExam(exam, $index)"\n' +
    '               ng-really-message="Wollen Sie wirklich diese Untersuchung nachträglich editieren und einen neuen PDF Befund erstellen?"\n' +
    '               class="btn btn-warning"\n' +
    '               uib-tooltip="Editieren &amp; neues PDF erzeugen"><i class="fa fa-edit"></i></a>\n' +
    '        </td>\n' +
    '    </tr>\n' +
    '    </tbody>\n' +
    '</table>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('examination/exam-helper.tpl.html',
    '<!-- Attention! This html.tpl file belongs to ExamHelper factory, not to the directive! -->\n' +
    '<div class="modalspacer">\n' +
    '  <h3>Exameditor</h3>\n' +
    '  <form>\n' +
    '    <h4 ng-model="eeCtrl.exam.template.displayname"></h4>\n' +
    '\n' +
    '    <!-- fieldgroups -->\n' +
    '    <div ng-repeat="fieldgroup in eeCtrl.exam.template.fieldgroups">\n' +
    '      <fieldgroup efieldgroup="fieldgroup"></fieldgroup>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- Biopsien -->\n' +
    '    <div ng-show="eeCtrl.exam.template.biopsy" class="panel panel-default">\n' +
    '      <div class="panel-heading">Biopsien</div>\n' +
    '      <div class="panel-body">\n' +
    '\n' +
    '        <!-- Biopsiefelder -->\n' +
    '        <div class="form-group">\n' +
    '          <bfield efield="bf" bvalues="eeCtrl.exam.template.biopsy.values"\n' +
    '                  ng-init="bf.label  = \'PE \' + ($index + 1) + \' \' + eeCtrl.exam.template.type"\n' +
    '                  ng-repeat="bf in eeCtrl.exam.template.biopsy.biopsies">\n' +
    '          </bfield>\n' +
    '        </div>\n' +
    '        <!-- Buttons -->\n' +
    '        <div class="form-group">\n' +
    '          <button type="submit" class="btn btn-success"\n' +
    '                  ng-click="eeCtrl.addBiopsyField()">\n' +
    '            <i class="fa fa-plus"></i> Biopsiefeld hinzufügen\n' +
    '          </button>\n' +
    '          <button type="submit" class="btn btn-primary"\n' +
    '                  ng-click="eeCtrl.printBiopsyLabels()">\n' +
    '            <i class="fa fa-print"></i> Etiketten drucken\n' +
    '          </button>\n' +
    '          <div ng-show="eeCtrl.exam.template.biopsy.labelspdf" class="btn btn-success">\n' +
    '            <i class="fa fa-file-pdf-o"></i> Etiketten als PDF\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- special fields -->\n' +
    '    <field efield="eeCtrl.exam.template.diagnosis"\n' +
    '           ng-show="eeCtrl.exam.template.diagnosis"></field>\n' +
    '    <field efield="eeCtrl.exam.template.recommendation"\n' +
    '           ng-show="eeCtrl.exam.template.recommendation"></field>\n' +
    '    <field efield="eeCtrl.exam.template.comment"\n' +
    '           ng-show="eeCtrl.exam.template.comment"></field>\n' +
    '    \n' +
    '    \n' +
    '    <div ng-show="eeCtrl.exam.files.length" class="row">\n' +
    '      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 imgcontainer"\n' +
    '           ng-repeat="img in eeCtrl.exam.files | filter: {mimetype: \'image/jpeg\'} |  orderBy: \'_id\'">\n' +
    '\n' +
    '        <img class="img-responsive" src="/routes/files/filereader/{{img.fileid}}"\n' +
    '             uib-tooltip="{{img.label}}"\n' +
    '             ng-click="eeCtrl.editImage(img)">\n' +
    '        <div class="imgcheckbox"><input type="checkbox" ng-model="img.include" ng-click="eeCtrl.exam.$save()"></div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="clearfix spacer-top">&nbsp;</div>\n' +
    '\n' +
    '    <div class="form-group clearfix">\n' +
    '      <!-- This was a stupid idea, clients could save without generating a new pdf-->\n' +
    '      <!--<button type="submit" class="btn btn-success" ng-click="eeCtrl.exam.$save()">Speichern</button>-->\n' +
    '      <button type="submit" class="btn btn-primary" ng-click="eeCtrl.saveAndQuit()">Speichern und Beenden</button>\n' +
    '      <button type="submit" class="btn btn-danger" ng-click="eeCtrl.cancel()">Beenden ohne Speichern</button>\n' +
    '    </div>\n' +
    '\n' +
    '    <div style="min-height: 2000px">&nbsp;</div>\n' +
    '  </form>\n' +
    '</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('examination/current-exams-list-directive.tpl.html',
    '<h3>Durchgeführte Untersuchungen</h3>\n' +
    '<uib-accordion close-others="oneAtATime">\n' +
    '  <uib-accordion-group ng-repeat="exam in celCtrl.patient.openConsult.examinations | filter: {active: true}"\n' +
    '                       is-open="status.isOpen[$index]">\n' +
    '    <!-- exam header -->\n' +
    '    <uib-accordion-heading>\n' +
    '      <div class="row">\n' +
    '        <div class="col-xs-12 col-sm-9">\n' +
    '          <h4>\n' +
    '            <span ng-show="status.isOpen[$index]" ng-click="status.isOpen[$index] = !status.isOpen[$index]"><i class="fa fa-caret-up"></i></span>\n' +
    '            <span ng-hide="status.isOpen[$index]" ng-click="status.isOpen[$index] = !status.isOpen[$index]"><i class="fa fa-caret-down"></i></span>\n' +
    '            &nbsp;{{exam.template.displayname}}</h4>\n' +
    '          </div>\n' +
    '        <div class="col-xs-12 col-sm-3" style="text-align: right">\n' +
    '          <h4>\n' +
    '            <a class="btn btn-info btn-sm"\n' +
    '               href="/routes/pdfprinter/exampreview?patientid={{celCtrl.patient._id}}&examid={{exam._id}}&doctype=examfinding"\n' +
    '               target="_blank" uib-tooltip="PDF Vorschau"><i class="fa fa-eye"></i></a>\n' +
    '            <a class="btn btn-primary btn-sm" ng-click="celCtrl.editExam(exam, celCtrl.patient)" uib-tooltip="Editieren">\n' +
    '              <i class="fa fa-edit"></i></a>\n' +
    '            <a class="btn btn-danger btn-sm" ng-click="celCtrl.deleteExam(exam, celCtrl.patient)" uib-tooltip="Entfernen">\n' +
    '              <i class="fa fa-remove"></i></a>\n' +
    '          </h4>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </uib-accordion-heading>\n' +
    '    <!-- exam data -->\n' +
    '    <div class="row">\n' +
    '      <div class="col-xs-12">\n' +
    '        <!-- fieldgroups -->\n' +
    '        <div ng-repeat="grp in exam.template.fieldgroups">\n' +
    '          <h5 class="dl-horizontal" ng-hide="grp.label == \'\'" ng-model="grp.label"\n' +
    '              style="border-bottom: 1px solid black; border-top: 1px solid black;  padding-top: 5px; padding-bottom: 5px">\n' +
    '            {{grp.label}}\n' +
    '          </h5>\n' +
    '          <h5 class="dl-horizontal" ng-show="grp.label == \'\'" ng-model="grp.label"\n' +
    '              style="border-bottom: 1px solid black; padding-top: 5px; padding-bottom: 5px">\n' +
    '          </h5>\n' +
    '          <!-- fields -->\n' +
    '          <dl class="dl-horizontal">\n' +
    '          <span class="dl-horizontal" ng-repeat="field in grp.fields">\n' +
    '            <dt>{{field.label}}</dt>\n' +
    '            <dd><span ng-repeat="val in field.avalues">{{val.name}}{{$last ? \'\' : \', \'}}</span></dd>\n' +
    '          </span>\n' +
    '          </dl>\n' +
    '        </div>\n' +
    '\n' +
    '        <!-- exam images -->\n' +
    '        <div class="row spacer-top" ng-show="exam.files">\n' +
    '          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 imgcontainer"\n' +
    '               ng-repeat="img in exam.files | filter: {mimetype: \'image/jpeg\'} |  orderBy: \'_id\'">\n' +
    '\n' +
    '            <img class="img-responsive" src="/routes/files/filereader/{{img.fileid}}"\n' +
    '                 uib-tooltip="{{img.label}}"\n' +
    '                 ng-click="celCtrl.editImage(img)">\n' +
    '            <div class="imgcheckbox"><input type="checkbox" ng-model="img.include" ng-click="exam.$save()"></div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!-- Befundblock  -->\n' +
    '        <h5 class="dl-horizontal"\n' +
    '            style="border-bottom: 1px solid black; border-top: 1px solid black; padding-top: 5px; padding-bottom: 5px">\n' +
    '          Befund</h5>\n' +
    '        <dl class="dl-horizontal">\n' +
    '          <dt>Diagnose</dt>\n' +
    '          <dd><span\n' +
    '              ng-repeat="diagnosis in exam.template.diagnosis.avalues">{{diagnosis.name}}{{$last ? \'\' : \', \'}}</span>\n' +
    '          </dd>\n' +
    '          <dt>Empfehlung</dt>\n' +
    '          <dd><span\n' +
    '              ng-repeat="diagnosis in exam.template.recommendation.avalues">{{diagnosis.name}}{{$last ? \'\' : \', \'}}</span>\n' +
    '          </dd>\n' +
    '          <dt>Anmerkung</dt>\n' +
    '          <dd><span\n' +
    '              ng-repeat="diagnosis in exam.template.comment.avalues">{{diagnosis.name}}{{$last ? \'\' : \', \'}}</span>\n' +
    '          </dd>\n' +
    '        </dl>\n' +
    '\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </uib-accordion-group>  <!-- exam in openConsult.examinations -->\n' +
    '</uib-accordion>\n' +
    '\n' +
    '<!--<div class="row" ng-repeat="exam in celCtrl.exams | filter:{active: true}| orderBy:\'-created\'">\n' +
    '  <div class="col-xs-12 col-lg-8">\n' +
    '    <strong>{{exam.template.displayname}} ({{exam.time}})</strong><br>\n' +
    '    <span ng-repeat="diagnosis in exam.template.diagnosis.avalues">{{diagnosis.name}}{{$last ? \'\' : \', \'}}</span>\n' +
    '  </div>\n' +
    '  <div class="col-xs-12 col-md-4" style="text-align: right" >\n' +
    '    <a class="btn btn-success" ng-init="exam.showpictures = false" ng-click="exam.showpictures = !exam.showpictures"\n' +
    '       uib-tooltip="Bilder anzeigen" ng-show="exam.files"><i class="fa fa-image"></i></a>\n' +
    '    &lt;!&ndash;<a class="btn btn-primary" ng-click="celCtrl.editExam(exam, celCtrl.patient)"  uib-tooltip="Editieren"><i class="fa fa-edit"></i></a>&ndash;&gt;\n' +
    '    <a class="btn btn-primary" href="/routes/pdfprinter/exampreview?patientid={{celCtrl.patient._id}}&examid={{exam._id}}&doctype=examfinding"\n' +
    '       target="_blank" uib-tooltip="PDF Vorschau"><i class="fa fa-eye"></i></a>\n' +
    '    <a class="btn btn-primary" ng-click="celCtrl.editExam(exam)"  uib-tooltip="Editieren"><i class="fa fa-edit"></i></a>\n' +
    '    <a class="btn btn-danger" ng-click="celCtrl.deleteExam(exam, celCtrl.patient)" uib-tooltip="Entfernen"><i class="fa fa-remove"></i></a>\n' +
    '</div>\n' +
    '  <div class="col-xs-12" ng-show="exam.showpictures">\n' +
    '    <div class="row spacer-top">\n' +
    '      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 imgcontainer"\n' +
    '           ng-repeat="img in exam.files | filter: {mimetype: \'image/jpeg\'} |  orderBy: \'_id\'">\n' +
    '\n' +
    '        <img class="img-responsive" src="/routes/files/filereader/{{img.fileid}}"\n' +
    '             uib-tooltip="{{img.label}}"\n' +
    '             ng-click="celCtrl.editImage(img)">\n' +
    '        <div class="imgcheckbox"><input type="checkbox" ng-model="img.include" ng-click="exam.$save()"></div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>-->\n' +
    '\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('drug/sprtimeline-directive.tpl.html',
    '\n' +
    '<h3>Medikamenten-Historie</h3>\n' +
    '<div id="visualization"></div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('drug/repopovertpl.html',
    '<div ng-bind-html="pdvCtrl.newdrug.recriteria"></div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('drug/prescription-directive.tpl.html',
    '<div class="row">\n' +
    '  <div class="col-xs-12">\n' +
    '    <form class="form-vertical">\n' +
    '      <!-- -->\n' +
    '      <div class="row"\n' +
    '           ng-repeat="drug in dpCtrl.patient.drugs | filter: {active: true} | filter: {foreign: false} | orderBy: \'name\'">\n' +
    '        <div class="col-xs-1">\n' +
    '          <input type="checkbox" ng-change="dpCtrl.calculateActivedrugs()" ng-model="drug.print"/>\n' +
    '        </div>\n' +
    '        <div class="col-xs-6 col-sm-6">{{drug.name}}, {{drug.amount}}, {{drug.op}}</div>\n' +
    '        <div class="col-xs-1 col-sm-1">\n' +
    '          <button uib-popover-template="dpCtrl.drugedittpl"\n' +
    '                  popover-placement="bottom" type="button"\n' +
    '                  class="btn btn-primary btn-small">\n' +
    '            Details\n' +
    '          </button>\n' +
    '        </div>\n' +
    '        <div class="col-xs-2 col-sm-2">\n' +
    '         \n' +
    '          <span class="input-group" ng-blur="dpCtrl.checkSignatureUpdate(drug)">\n' +
    '            <input class="form-control" ng-model="drug.newamount" id="newdrugamount"\n' +
    '                   uib-tooltip="Neue Einnnahmeverordnung eingeben"\n' +
    '                   autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"\n' +
    '                   ng-blur="dpCtrl.checkSignatureUpdate(drug)">\n' +
    '              <span class="input-group-btn" uib-dropdown>\n' +
    '                <button type="button" class="btn btn-default" uib-dropdown-toggle><span class="caret"></span></button>\n' +
    '                <ul id="amountmenu" class="dropdown-menu dropdown-menu-right" role="menu" uib-dropdown-menu\n' +
    '                    ng-hide="dpCtrl.conf.drugsignature.length">\n' +
    '                  <li ng-click="drug.newamount=\'1-0-0\';dpCtrl.checkSignatureUpdate(drug)">1-0-0</li>\n' +
    '                  <li ng-click="drug.newamount=\'1-0-1\';dpCtrl.checkSignatureUpdate(drug)">1-0-1</li>\n' +
    '                  <li ng-click="drug.newamount=\'0-1-0\';dpCtrl.checkSignatureUpdate(drug)">0-1-0</li>\n' +
    '                  <li ng-click="drug.newamount=\'0-0-1\';dpCtrl.checkSignatureUpdate(drug)">0-0-1</li>\n' +
    '                  <li ng-click="drug.newamount=\'1-1-1\';dpCtrl.checkSignatureUpdate(drug)">1-1-1</li>\n' +
    '                  <li ng-click="drug.newamount=\'0-0-0-1\';dpCtrl.checkSignatureUpdate(drug)">0-0-0-1</li>\n' +
    '                </ul>\n' +
    '                <ul id="amountmenu2" class="dropdown-menu dropdown-menu-right" role="menu" uib-dropdown-menu\n' +
    '                    ng-show="dpCtrl.conf.drugsignature.length">\n' +
    '                    <li ng-repeat="sig in dpCtrl.conf.drugsignature"\n' +
    '                        ng-click="drug.newamount=sig;dpCtrl.checkSignatureUpdate(drug)">{{sig}}</li>\n' +
    '                 </ul>\n' +
    '              </span>\n' +
    '            </span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      \n' +
    '      <!--\n' +
    '         <div class="form-group" ng-repeat="drug in dpCtrl.patient.drugs">\n' +
    '           <label ng-show="drug.active && !(drug.foreign)">\n' +
    '             <input type="checkbox" ng-change="dpCtrl.calculateActivedrugs()"\n' +
    '                    ng-model="drug.print"/>\n' +
    '             {{drug.name}} {{drug.amount}}, {{drug.op}}\n' +
    '             <button uib-popover-template="dpCtrl.drugedittpl" popover-placement="bottom" type="button"\n' +
    '                     class="btn btn-primary btn-small">\n' +
    '               Details\n' +
    '             </button>\n' +
    '   \n' +
    '           </label>\n' +
    '         </div>-->\n' +
    '      <!-- gültig -->\n' +
    '      <div class="form-group">\n' +
    '      <label>Gültig </label>\n' +
    '        <div class="radio" ng-init="dpCtrl.pdate = true">\n' +
    '          <label class="radio-inline">\n' +
    '            <input type="radio" ng-value="true" ng-model="dpCtrl.pdate"\n' +
    '                   ng-click="dpCtrl.patient.prescriptiondate = moment().locale(\'de-at\').format(\'ll\')">\n' +
    '            1 Monat\n' +
    '          </label>\n' +
    '          <label class="radio-inline">\n' +
    '            <input type="radio" ng-value="false" ng-model="dpCtrl.pdate"\n' +
    '                   ng-click="dpCtrl.patient.prescriptiondate = \'\'">\n' +
    '            Kein Datum\n' +
    '          </label>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <!-- buttons -->\n' +
    '      <div class="form-group">\n' +
    '        <button class="btn btn-info" ng-hide="dpCtrl.dialog" ng-click="dpCtrl.previewPrescription();">\n' +
    '          <i class="fa fa-eye"></i> PDF Vorschau\n' +
    '        </button>\n' +
    '        <button class="btn btn-primary" ng-show="dpCtrl.dialog" ng-click="dpCtrl.dialog.close();">Speichern &amp;\n' +
    '          Beenden\n' +
    '        </button>\n' +
    '        <button class="btn btn-primary" ng-hide="dpCtrl.dialog" ng-click="dpCtrl.printPrescription();">\n' +
    '          <i class="fa fa-print"></i> Speichern &amp;\n' +
    '          gleich drucken\n' +
    '        </button>\n' +
    '      </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('drug/patient-drug-view-directive.tpl.html',
    '<patient-current-drugs patient="pdvCtrl.patient"></patient-current-drugs>\n' +
    '<div >\n' +
    '  <h4>Medikamenten-Historie</h4>\n' +
    '  <button type="button" ng-click="pdvCtrl.showHistory()" class="btn btn-info">Medikamentehistorie anzeigen</button>\n' +
    '</div>\n' +
    '\n' +
    '<h4>Neue Verordnung</h4>\n' +
    '<form class="form-vertical" name="newdrugform" id="newdrugform">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12 col-md-6">\n' +
    '\n' +
    '      <div class="input-group col-xs-12">\n' +
    '        <label for="newdrugname">Medikament *\n' +
    '\n' +
    '          <!-- Button SV Box -->\n' +
    '          <span class="btn btn-success btn-sm" ng-show="pdvCtrl.newdrug.box === \'G\'"\n' +
    '                uib-tooltip="SV Green Box">G</span>\n' +
    '          <span class="btn btn-warning btn-sm" ng-show="pdvCtrl.newdrug.box === \'Y\'"\n' +
    '                uib-tooltip="SV Yellow Box">Y</span>\n' +
    '          <span class="btn btn-danger btn-sm" ng-hide="pdvCtrl.newdrug.box" uib-tooltip="SV No Box">NB</span>\n' +
    '\n' +
    '          <!-- Fachinformationen -->\n' +
    '          <a class="btn btn-info btn-sm"\n' +
    '             href="/routes{{pdvCtrl.newdrug.fi}}" target="_blank"\n' +
    '             ng-show="pdvCtrl.newdrug.fi"\n' +
    '             uib-tooltip="Fachinformationen">FI PDF</a>\n' +
    '\n' +
    '          <!-- Button Restriktionen, Ind -->\n' +
    '          <span class="btn btn-danger btn-sm" ng-show="pdvCtrl.newdrug.re"\n' +
    '                uib-tooltip="Restriktionen" ng-bind="pdvCtrl.newdrug.re"\n' +
    '                uib-popover-template="pdvCtrl.repopovertpl" popover-title="Restriktionen"\n' +
    '                popover-placement="auto bottom" popover-trigger="outsideClick"></span>\n' +
    '          <span class="btn btn-primary btn-sm" ng-show="pdvCtrl.newdrug.ind"\n' +
    '                uib-tooltip="Indikationen" popover-trigger="outsideClick"\n' +
    '                uib-popover-template="pdvCtrl.indpopovertpl" popover-title="Indikationen"\n' +
    '                popover-placement="auto bottom">IND</span>\n' +
    '          <span class="btn btn-info btn-sm" ng-show="pdvCtrl.newdrug.hint"\n' +
    '                uib-tooltip="Hinweis" popover-trigger="outsideClick"\n' +
    '                uib-popover-template="pdvCtrl.hintpopovertpl" popover-title="Hinweis" popover-placement="auto bottom"><i\n' +
    '              class="fa fa-info-circle"></i></span>\n' +
    '          <span class="btn btn-warning btn-sm" ng-show="pdvCtrl.newdrug.kz"\n' +
    '                uib-tooltip="Kassenzeichen"\n' +
    '                uib-popover-template="pdvCtrl.kzpopover" popover-title="Hinweis" popover-placement="auto bottom"\n' +
    '                ng-bind="pdvCtrl.newdrug.kz"></span>\n' +
    '\n' +
    '          <!-- Alternativen -->\n' +
    '          <span class="btn btn-{{pdvCtrl.newdrug.alttype}} btn-sm" ng-show="pdvCtrl.newdrug.alternatives.length > 0"\n' +
    '                uib-popover-template="pdvCtrl.drugalternativestpl" popover-title="Alternativen"\n' +
    '                popover-placement="auto bottom" popover-trigger="outsideClick"\n' +
    '                uib-tooltip="{{pdvCtrl.newdrug.alttypetext}}">Alternativen</span>\n' +
    '\n' +
    '          <!-- SV ATC Alternativen -->\n' +
    '          <span class="btn btn-warning btn-sm" ng-show="pdvCtrl.newdrug.atcalternatives.length > 0"\n' +
    '                uib-popover-template="pdvCtrl.atcalternativestpl" popover-title="SV erstattete Alternativen laut ATC Code"\n' +
    '                popover-placement="auto bottom" popover-trigger="outsideClick"\n' +
    '                uib-tooltip="SV erstattete Alternativen laut ATC Code">SV Alternativen lt. ATC Code</span>\n' +
    '\n' +
    '\n' +
    '        </label>\n' +
    '\n' +
    '        <!-- searchbox -->\n' +
    '        <div class="input-group">\n' +
    '          <input type="text" class="form-control" placeholder="Medikament oder PharmaNr."\n' +
    '                 ng-model="pdvCtrl.newdrug.name"\n' +
    '                 ng-required="!pdvCtrl.newdrug.name"\n' +
    '                 autocomplete="off"\n' +
    '                 ng-class="{ \'has-error\': newdrugform.$invalid }"\n' +
    '                 typeahead-on-select="pdvCtrl.showdruginfo($item)"\n' +
    '                 typeahead-min-length="3"\n' +
    '                 uib-typeahead="drug as drug.text for drug in pdvCtrl.getDrugs($viewValue)"\n' +
    '                 typeahead-loading="loadingNames"\n' +
    '                 id="newdrugname"\n' +
    '                 select-first-match="false"\n' +
    '                 typeahead-no-results="pdvCtrl.noResults">\n' +
    '          <span class="input-group-btn">\n' +
    '            <button class="btn btn-info" type="button"><i class="fa fa-search"></i></button>\n' +
    '          </span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <i ng-show="loadingNames" class="glyphicon glyphicon-refresh"></i>\n' +
    '\n' +
    '      <div ng-show="pdvCtrl.noResults" style="color: #cc3333; font-weight: bold">\n' +
    '        <i class="fa fa-warning"></i> Keine Medikamente gefunden!\n' +
    '      </div>\n' +
    '\n' +
    '\n' +
    '      <!-- Einnahmeanweisung -->\n' +
    '      <div class="form-group">\n' +
    '        <label for="newdrugamount">Einnahme *</label>\n' +
    '        <div class="input-group">\n' +
    '          <input class="form-control" ng-model="pdvCtrl.newdrug.amount" id="newdrugamount"\n' +
    '                 autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"\n' +
    '                 placeholder="1-0-1-0" ng-required="!pdvCtrl.newdrug.amount"\n' +
    '                 ng-class="{ \'has-error\': newdrugform.$invalid }">\n' +
    '          <div class="input-group-btn" uib-dropdown>\n' +
    '            <button type="button" class="btn btn-default" uib-dropdown-toggle><span class="caret"></span></button>\n' +
    '            <ul id="amountmenu" class="dropdown-menu dropdown-menu-right" role="menu" uib-dropdown-menu ng-hide="pdvCtrl.conf.drugsignature.length">\n' +
    '              <li ng-click="pdvCtrl.newdrug.amount=\'1-0-0\'">1-0-0</li>\n' +
    '              <li ng-click="pdvCtrl.newdrug.amount=\'1-0-1\'">1-0-1</li>\n' +
    '              <li ng-click="pdvCtrl.newdrug.amount=\'0-1-0\'">0-1-0</li>\n' +
    '              <li ng-click="pdvCtrl.newdrug.amount=\'0-0-1\'">0-0-1</li>\n' +
    '              <li ng-click="pdvCtrl.newdrug.amount=\'1-1-1\'">1-1-1</li>\n' +
    '              <li ng-click="pdvCtrl.newdrug.amount=\'0-0-0-1\'">0-0-0-1</li>\n' +
    '            </ul>\n' +
    '            <ul id="amountmenu2" class="dropdown-menu dropdown-menu-right" role="menu" uib-dropdown-menu ng-show="pdvCtrl.conf.drugsignature.length">\n' +
    '              <li ng-repeat="sig in pdvCtrl.conf.drugsignature" ng-click="pdvCtrl.newdrug.amount=sig">{{sig}}</li>\n' +
    '            </ul>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <!--Dauermedikation-->\n' +
    '      <div class="input-group">\n' +
    '        <div class="checkbox">\n' +
    '          <label for="newdrugperm" class="checkbox-inline">\n' +
    '            <input type="checkbox"\n' +
    '                   ng-model="pdvCtrl.newdrug.permanent"\n' +
    '                   id="newdrugperm">Dauermedikamentation\n' +
    '            <span class="fa fa-info-circle" style="color: #3333ff"\n' +
    '                  uib-tooltip="Ist das Medikament nicht zur dauerhaften Einanhme/Anwendung vorgesehen, wird als Ende-Datum das heutige Datum +14 Tage eingesetzt"></span>\n' +
    '          </label><br>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <!-- Spezifika, IND, OP, RE -->\n' +
    '    <div class="col-xs-12 col-md-6">\n' +
    '      <div class="input-group">\n' +
    '        <strong>Spezifika</strong>\n' +
    '\n' +
    '        <!--IND-->\n' +
    '        <div class="checkbox">\n' +
    '          <label for="newdrugind" class="checkbox-inline">\n' +
    '            <input type="checkbox"\n' +
    '                   ng-model="pdvCtrl.newdrug.ind"\n' +
    '                   id="newdrugind">IND (Indikation)\n' +
    '            <!-- <span ng-show="pdvCtrl.newdrug.indcriteria" uib-tooltip="Indikationen"\n' +
    '                   uib-popover-template="pdvCtrl.indpopovertpl"\n' +
    '                   popover-title="Indikationen" popover-placement="auto bottom"><i class="fa fa-info-circle btn btn-primary"></i></span> -->\n' +
    '          </label><br>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--OP-->\n' +
    '        <div class="radio" ng-init="pdvCtrl.newdrug.op=\'OP I\'">\n' +
    '          <label for="newdrugop1" class="radio-inline">\n' +
    '            <input type="radio" value="OP I" required\n' +
    '                   ng-model="pdvCtrl.newdrug.op"\n' +
    '                   id="newdrugop1">OP I</label>\n' +
    '          <label for="newdrugop2" class="radio-inline">\n' +
    '            <input type="radio" value="OP II"\n' +
    '                   ng-model="pdvCtrl.newdrug.op"\n' +
    '                   id="newdrugop2">OP II</label>\n' +
    '          <label for="newdrugop3" class="radio-inline">\n' +
    '            <input type="radio" value="OP III"\n' +
    '                   ng-model="pdvCtrl.newdrug.op"\n' +
    '                   id="newdrugop3">OP III</label>\n' +
    '        </div>\n' +
    '        <!--Packungsgroesse-->\n' +
    '        <label for="newdrugpkgsize">Packungsgröße</label>\n' +
    '        <input class="form-control" ng-model="pdvCtrl.newdrug.pkgsize" id="newdrugpkgsize"\n' +
    '               placeholder="30 Stk">\n' +
    '      </div>\n' +
    '\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <!--Rezeptvermerk-->\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <div class="form-group">\n' +
    '        <label for="newdrugcomment">Rezeptvermerk</label>\n' +
    '                    <textarea class="form-control" ng-model="pdvCtrl.newdrug.comment" id="newdrugcomment"\n' +
    '                              placeholder=""></textarea>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <!--Verschreiben-->\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <button class="btn btn-success spacer-top" type="button"\n' +
    '              ng-disabled="!newdrugform.$valid"\n' +
    '              ng-click="pdvCtrl.addDrug()">\n' +
    '        Medikament verordnen\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</form>\n' +
    '\n' +
    '<!--Fremdverordnung-->\n' +
    '<h4>Fremdverordnung vermerken</h4>\n' +
    '<form class="form-vertical" name="newfdrugform">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12 col-md-6">\n' +
    '      <label for="newfdrugname">Medikament *</label>\n' +
    '      <input class="form-control" ng-model="pdvCtrl.newfdrug.name"\n' +
    '             type="text" class="form-control" placeholder="Medikament oder PharmaNr."\n' +
    '             autocomplete="off"\n' +
    '             typeahead-on-select="pdvCtrl.showfdruginfo($item)"\n' +
    '             typeahead-min-length="3"\n' +
    '             uib-typeahead="drug as drug.text for drug in pdvCtrl.getDrugs($viewValue)"\n' +
    '             typeahead-loading="loadingNames"\n' +
    '             id="newfdrugname"\n' +
    '             select-first-match="false"\n' +
    '             typeahead-no-results="pdvCtrl.noFDResults"\n' +
    '             required\n' +
    '      />\n' +
    '      <div ng-show="pdvCtrl.noFDResults" style="color: #cc3333; font-weight: bold">\n' +
    '        <i class="fa fa-warning"></i> Keine Medikamente gefunden!\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="cpol-xs-12 col-md-6">\n' +
    '      <div class="form-group">\n' +
    '        <label for="fdrugamount">Einnahme *</label>\n' +
    '        <div class="input-group">\n' +
    '          <input class="form-control" ng-model="pdvCtrl.newfdrug.amount" id="fdrugamount"\n' +
    '                 autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"\n' +
    '                 placeholder="1-0-1-0" required ng-class="{ \'has-error\': newfdrugform.$invalid }">\n' +
    '          <div class="input-group-btn" uib-dropdown>\n' +
    '            <button type="button" class="btn btn-default" uib-dropdown-toggle><span class="caret"></span></button>\n' +
    '            <ul id="famountmenu" class="dropdown-menu dropdown-menu-right" role="menu" uib-dropdown-menu>\n' +
    '              <li ng-click="pdvCtrl.newfdrug.amount=\'1-0-0\'">1-0-0</li>\n' +
    '              <li ng-click="pdvCtrl.newfdrug.amount=\'1-0-1\'">1-0-1</li>\n' +
    '              <li ng-click="pdvCtrl.newfdrug.amount=\'0-1-0\'">0-1-0</li>\n' +
    '              <li ng-click="pdvCtrl.newfdrug.amount=\'0-0-1\'">0-0-1</li>\n' +
    '              <li ng-click="pdvCtrl.newfdrug.amount=\'1-1-1\'">1-1-1</li>\n' +
    '              <li ng-click="pdvCtrl.newfdrug.amount=\'0-0-0-1\'">0-0-0-1</li>\n' +
    '            </ul>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <button class="btn btn-success" type="submit" ng-disabled="!newfdrugform.$valid"\n' +
    '              ng-click="pdvCtrl.addForeignDrug()">Fremdverordnung speichern\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '</form>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '<h4>Rezept austellen</h4>\n' +
    '<prescription patient="pdvCtrl.patient"></prescription>\n' +
    '\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('drug/patient-current-drugs-directive.tpl.html',
    '<h4>Aktuelle Medikamente</h4>\n' +
    '<div class="input-group">\n' +
    '  <span ng-repeat="drug in pcdCtrl.patient.drugs" id="quuxbar">\n' +
    '    <span ng-show="drug.active && pcdCtrl.datecheck(drug)" uib-dropdown>\n' +
    '      <button type="button" class="btn btn-{{drug.color}}" uib-dropdown-toggle\n' +
    '              ng-class="btn-{{drug.color || \'primary\'}}"\n' +
    '              data-toggle="uib-dropdown" aria-haspopup="true" aria-expanded="false"\n' +
    '              uib-tooltip="{{drug.name}} / {{drug.amount}} / {{drug.comment}}">\n' +
    '        {{drug.name | drugname }} {{drug.amount}} <span class="caret"></span>\n' +
    '      </button>\n' +
    '      <ul uib-dropdown-menu>\n' +
    '        <li>\n' +
    '          <a ng-click="pcdCtrl.updateDrugColor(drug, \'success\')">\n' +
    '            <span class="label label-success">Grün (Erfolg)</span>\n' +
    '          </a>\n' +
    '        </li>\n' +
    '        <li>\n' +
    '          <a ng-click="pcdCtrl.updateDrugColor(drug, \'warning\')">\n' +
    '            <span class="label label-warning">Gelb (Warnung)</span>\n' +
    '          </a>\n' +
    '        </li>\n' +
    '        <li>\n' +
    '          <a ng-click="pcdCtrl.updateDrugColor(drug, \'danger\')">\n' +
    '            <span class="label label-danger">Rot (Gefahr)</span>\n' +
    '          </a>\n' +
    '        </li>\n' +
    '        <li>\n' +
    '          <a ng-click="pcdCtrl.updateDrugColor(drug, \'info\')">\n' +
    '            <span class="label label-info">Türkis (Fremdverordnung)</span>\n' +
    '          </a>\n' +
    '        </li>\n' +
    '        <li>\n' +
    '          <a ng-click="pcdCtrl.updateDrugColor(drug, \'primary\')">\n' +
    '            <span class="label label-primary">Blau (Aktuell)</span>\n' +
    '          </a>\n' +
    '        </li>\n' +
    '\n' +
    '        <li>\n' +
    '          <a ng-really-message="Dieses Medikament wirklich absetzen?"\n' +
    '             ng-really-click="pcdCtrl.deactivateDrug(drug, patient)">\n' +
    '            <span class="label label-danger">X</span> Medikament absetzen\n' +
    '          </a>\n' +
    '        </li>\n' +
    '        <li role="separator" class="divider"></li>\n' +
    '          <form id="commentform">\n' +
    '          <label for="dcomment">Kommentar</label>\n' +
    '          <textarea class="form-control dropdown" ng-model="drug.privcomment" id="dcomment"\n' +
    '                 ng-click="$event.stopPropagation()"\n' +
    '                 ng-blur="pcdCtrl.patient.$save()"></textarea>\n' +
    '          </form>\n' +
    '        </li>\n' +
    '        <li role="separator"   ng-show="drug.fi" class="divider"></li>\n' +
    '        <li ng-show="drug.fi"><a\n' +
    '               href="/routes{{drug.fi}}" target="_blank"\n' +
    '               uib-tooltip="Fachinformationen"><span class="fa fa-file-pdf-o btn btn-info btn-sm"></span> Fachinformationen</a></li>\n' +
    '      </ul>\n' +
    '    </span>\n' +
    '  </span><!-- ng-repeat -->\n' +
    '</div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('drug/kzpopover.html',
    '<div>\n' +
    '  <h3>Allgmeine Kassenzeichen</h3>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-1">F2J</div>\n' +
    '    <div class="col-xs-11">Die Arzneispezialität ist nur für die Behandlung von Kindern bis zum vollendeten 2.\n' +
    '      Lebensjahr in den Grünen Bereich des Erstattungskodex aufgenommen. Für Verordnungen außerhalb der angegebenen\n' +
    '      Altersbeschränkung ist die Arzneispezialität wie eine Arzneispezialität des Gelben Bereichs des Erstattungskodex\n' +
    '      zu behandeln, wobei, wenn die Notwendigkeit für die Verordnung einer kleinkindergerechten Therapie oder Dosierung\n' +
    '      entsprechend den Bestimmungen der Heilmittel-Bewilligungs- und Kontroll-Verordnung und den Richtlinien über die\n' +
    '      ökonomische Verschreibweise dokumentiert wird, die vorherige ärztliche Bewilligung des chef- und\n' +
    '      kontrollärztlichen Dienstes durch eine nachfolgende Kontrolle der Einhaltung der bestimmten Verwendung ersetzt\n' +
    '      werden kann.\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-1">F6J</div>\n' +
    '    <div class="col-xs-11">Die Arzneispezialität ist nur für die Behandlung von Kindern bis zum vollendeten 6.\n' +
    '      Lebensjahr in den Grünen Bereich des Erstattungskodex aufgenommen. Für Verordnungen außerhalb der angegebenen\n' +
    '      Altersbeschränkung ist die Arzneispezialität wie eine Arzneispezialität des Gelben Bereichs des Erstattungskodex\n' +
    '      zu behandeln, wobei, wenn die Notwendigkeit für die Verordnung einer kindergerechten Therapie oder Dosierung\n' +
    '      entsprechend den Bestimmungen der Heilmittel-Bewilligungs- und Kontroll-Verordnung und den Richtlinien über die\n' +
    '      ökonomische Verschreibweise dokumentiert wird, die vorherige ärztliche Bewilligung des chef- und\n' +
    '      kontrollärztlichen Dienstes durch eine nachfolgende Kontrolle der Einhaltung der bestimmten Verwendung ersetzt\n' +
    '      werden kann.\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-1">F14</div>\n' +
    '    <div class="col-xs-11">Die Arzneispezialität ist nur für die Behandlung von Kindern und Jugendlichen bis zum\n' +
    '      vollendeten 14. Lebensjahr in den Grünen Bereich des Erstattungskodex aufgenommen. Für Verordnungen außerhalb der\n' +
    '      angegebenen Altersbeschränkung ist die Arzneispezialität wie eine Arzneispezialität des Gelben Bereichs des\n' +
    '      Erstattungskodex zu behandeln, wobei, wenn die Notwendigkeit für die Verordnung einer kindergerechten Zubereitung\n' +
    '      (z.B.: Saft) oder Dosierung entsprechend den Bestimmungen der Heilmittel-Bewilligungs- und Kontroll-Verordnung und\n' +
    '      den Richtlinien über die ökonomische Verschreibweise dokumentiert wird, die vorherige ärztliche Bewilligung des\n' +
    '      chef- und kontrollärztlichen Dienstes durch eine nachfolgende Kontrolle der Einhaltung der bestimmten Verwendung\n' +
    '      ersetzt werden kann.\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-1">DSX</div>\n' +
    '    <div class="col-xs-11">(steht für die Facharztkennzeichen A, D, K, L): Desensibilisierungsprodukt; frei\n' +
    '      verschreibbar durch Facharzt/Fachärztin für Lungenkrankheiten, Haut- und Geschlechtskrankheiten, Kinderheilkunde\n' +
    '      und Hals-, Nasen- und Ohrenkrankheiten.\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <h3>Kassenzeichen Facharztkennung</h3>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-1"></div>\n' +
    '    <div class="col-xs-11"></div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-1">A</div>\n' +
    '    <div class="col-xs-11">Facharzt/Fachärztin für Lungenkrankheiten</div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-1">Au</div>\n' +
    '    <div class="col-xs-11">Facharzt/Fachärztin für Augenkrankheiten</div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-1">D</div>\n' +
    '    <div class="col-xs-11">Facharzt/Fachärztin für Haut- und Geschlechtskrankheiten</div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-1">F</div>\n' +
    '    <div class="col-xs-11">Facharzt/Fachärztin für Frauenheilkunde und Geburtshilfe</div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-1">K</div>\n' +
    '    <div class="col-xs-11">Facharzt/Fachärztin für Kinderheilkunde</div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-1">L</div>\n' +
    '    <div class="col-xs-11">Facharzt/Fachärztin für Hals-, Nasen- und Ohrenkrankheiten</div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-1">P</div>\n' +
    '    <div class="col-xs-11">Facharzt/Fachärztin für Psychiatrie oder Psychiatrie und Neurologie oder Neurologie und\n' +
    '      Psychiatrie oder Kinder- und Jugendpsychiatrie\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-1">P</div>\n' +
    '    <div class="col-xs-11">Facharzt/Fachärztin für Psychiatrie oder Psychiatrie und Neurologie oder Neurologie und\n' +
    '      Psychiatrie oder Kinder- und Jugendpsychiatrie\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-1">U</div>\n' +
    '    <div class="col-xs-11">Facharzt/Fachärztin für Urologie</div>\n' +
    '  </div>\n' +
    '\n' +
    '\n' +
    '</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('drug/indpopovertpl.html',
    '<div ng-bind-html="pdvCtrl.newdrug.indcriteria"></div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('drug/hintpopovertpl.html',
    '<div ng-bind-html="pdvCtrl.newdrug.hint"></div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('drug/drughistory-directive.tpl.html',
    '<div>{{drughistory.name}}</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('drug/drugalternativestpl.html',
    '<div style="padding: 20px"><!-- template must be enclosed by single tag -->\n' +
    '  <div style="padding: 20px"><!-- template must be enclosed by single tag -->\n' +
    '\n' +
    '\n' +
    '    <div class="row" style="margin-bottom: 2px" ng-repeat="alt in pdvCtrl.newdrug.alternatives">\n' +
    '      <div class="btn btn-{{alt.color}} col-xs-12"\n' +
    '           uib-tooltip="{{alt.tooltip}}"\n' +
    '           ng-click="pdvCtrl.replaceDrug(alt)"\n' +
    '           ng-bind="alt.name">\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="alert alert-danger"><h3><i class="fa fa-warning"></i> Wichtiger Hinweis!</h3>\n' +
    '      <p>\n' +
    '        Die hier gelisteten Medikamente wurden auf Basis des EKO Tools der SV ausgewählt. Sie sind nach Preis sortiert -\n' +
    '        das günstigste zuerst. Es können echte Generika (gleicher Wirkstoff, gleiche Stärke), Alternativen mit\n' +
    '        geringfügig anderer Wirkstoffkonzentration, oder Biosimilars (änhlicher Wirksstoff) aufgelistet sein.\n' +
    '        Farbcodierung/Hinweise der jeweiligen Alternative beachten!</p>\n' +
    '\n' +
    '      <p>Prüfung der Alternativen auf ihre Einsatzmöglichkeit und ggf. Auswahl eines Ersatzpräparats obliegt\n' +
    '        AUSSCHLIESSLICH der berhandelnden Ärztin / dem behandelndem Arzt!</p>\n' +
    '    </div>\n' +
    '  </div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('drug/drugPopoverTemplate.html',
    '\n' +
    '  <div class="modalspacer"> <!-- complete template must be inclosed inside single tag-->\n' +
    '    <div>\n' +
    '      <label for="drugamount">Bestehnde Einnahmeverordnung</label>\n' +
    '      <input class="form-control" ng-model="drug.amount" id="drugamount" disabled\n' +
    '             autocomplete="off" autocorect="off" autocapitalize="off" spellcheck="false"\n' +
    '             \n' +
    '    </div>\n' +
    '    <span class="form-group">\n' +
    '              <label for="newdrugamount">Neue Einnahmeverordnung</label>\n' +
    '              <span class="input-group">\n' +
    '                <input class="form-control" ng-model="drug.newamount" id="newdrugamount"\n' +
    '                       autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"\n' +
    '                       ng-change="dpCtrl.checkSignatureUpdate(drug)">\n' +
    '                <span class="input-group-btn" uib-dropdown>\n' +
    '                  <button type="button" class="btn btn-default" uib-dropdown-toggle><span class="caret"></span></button>\n' +
    '                  <ul id="amountmenu" class="dropdown-menu dropdown-menu-right" role="menu" uib-dropdown-menu ng-hide="dpCtrl.conf.drugsignature.length">\n' +
    '                    <li ng-click="drug.newamount=\'1-0-0\'">1-0-0</li>\n' +
    '                    <li ng-click="drug.newamount=\'1-0-1\'">1-0-1</li>\n' +
    '                    <li ng-click="drug.newamount=\'0-1-0\'">0-1-0</li>\n' +
    '                    <li ng-click="drug.newamount=\'0-0-1\'">0-0-1</li>\n' +
    '                    <li ng-click="drug.newamount=\'1-1-1\'">1-1-1</li>\n' +
    '                    <li ng-click="drug.newamount=\'0-0-0-1\'">0-0-0-1</li>\n' +
    '                  </ul>\n' +
    '                  <ul id="amountmenu2" class="dropdown-menu dropdown-menu-right" role="menu" uib-dropdown-menu ng-show="dpCtrl.conf.drugsignature.length">\n' +
    '                    <li ng-repeat="sig in dpCtrl.conf.drugsignature" ng-click="drug.newamount=sig">{{sig}}</li>\n' +
    '                  </ul>\n' +
    '                </span>\n' +
    '              </span>\n' +
    '            </span>\n' +
    '   <!-- <div class="input-group">\n' +
    '      <label for="drugamount">Einnahme</label>\n' +
    '      <input class="form-control" ng-model="drug.amount" id="drugamount" placeholder="1-0-1-0">\n' +
    '    </div>-->\n' +
    '    <div class="input-group">\n' +
    '      <strong>Spezifika</strong>\n' +
    '\n' +
    '      <div class="checkbox">\n' +
    '        <label for="drugind" class="checkbox-inline"><input type="checkbox" ng-model="drug.ind" id="drugind">IND\n' +
    '          (Indikation)</label><br>\n' +
    '      </div>\n' +
    '      <div class="radio">\n' +
    '        <label for="drugop1" class="radio-inline"><input type="radio" value="OP I" ng-model="drug.op" id="drugop1">OP I</label>\n' +
    '        <label for="drugop2" class="radio-inline"><input type="radio" value="OP II" ng-model="drug.op" id="drugop2">OP\n' +
    '          II</label>\n' +
    '        <label for="drugop3" class="radio-inline"><input type="radio" value="OP III" ng-model="drug.op" id="drugop3">OP\n' +
    '          III</label>\n' +
    '      </div>\n' +
    '      <label for="drugpkgsize">Packungsgröße</label>\n' +
    '      <input class="form-control" ng-model="drug.pkgsize" id="drugpkgsize" placeholder="30 Stk">\n' +
    '    </div>\n' +
    '    <div class="input-group">\n' +
    '      <div class="checkbox">\n' +
    '        <label for="drugperm" class="checkbox-inline"><input type="checkbox" ng-model="drug.permanent" id="drugperm">Dauermedikamentation</label><br>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="form-group">\n' +
    '      <label for="drugcomment">Rezeptvermerk</label>\n' +
    '      <textarea class="form-control" ng-model="drug.comment" id="drugcomment" placeholder=""></textarea>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <!-- This from the old druginfo popover ^-->\n' +
    '<!--\n' +
    '<script type="text/ng-template" id="myPopoverTemplate.html">\n' +
    '  <div>\n' +
    '    <div class="zbez">{{dpCtrl.selectedDrug.ZBEZ}}\n' +
    '      <span ng-hide="dpCtrl.selectedDrug.ZSUCHT==\'\'"\n' +
    '            class="zsucht"\n' +
    '            uib-tooltip="Sucht-Kategorisierung">{{dpCtrl.selectedDrug.ZSUCHT}}</span>\n' +
    '    </div>\n' +
    '    <div class="desc">{{dpCtrl.selectedDrug.ZANWEND}}</div>\n' +
    '  </div>\n' +
    '</script>\n' +
    '-->\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('drug/drug.tpl.html',
    '<h2>drug</h2>\n' +
    '<p>{{drug.ctrlName}}</p>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('drug/atcalternativestpl.html',
    '<div style="padding: 20px"><!-- template must be enclosed by single tag -->\n' +
    '  <div class="alert alert-danger"><h3><i class="fa fa-warning"></i> Wichtiger Hinweis!</h3>\n' +
    '    <p>\n' +
    '      Die hier gelisteten Medikamente wurden auf Basis des ATC Codes ausgewählt. Wenn das ursprüngliche - nicht mehr\n' +
    '      erstattungsfähige - Präparat mehrere Wirkstoffe beinhaltet hat, werden auch Arzneien die nur einen der Wirkstoffe\n' +
    '      beinhalten angezeigt. Die Alternativen varieren in der Stärke des Wirkstoffes. </p>\n' +
    '\n' +
    '    <p>Prüfung der Alternativen auf ihre Einsatzmöglichkeit und ggf. Auswahl eines Ersatzpräparats obliegt AUSSCHLIESSLICH der berhandelnden Ärztin\n' +
    '      / dem behandelndem Arzt!</p>\n' +
    '\n' +
    '   <!-- <p>\n' +
    '    <div class="btn btn-danger" ng-click="atcaltshow = true" ng-hide="atcaltshow">Habe ich verstanden, Alternativen jetzt anzeigen</div>\n' +
    '    </p>-->\n' +
    '</div>\n' +
    '\n' +
    '    <!--<div ng-show="atcaltshow">-->\n' +
    '    <div>\n' +
    '      <div class="row" style="margin-bottom: 2px" ng-repeat="alt in pdvCtrl.newdrug.atcalternatives">\n' +
    '        <div ng-show="alt.box === \'G\'" class="btn btn-success col-xs-12"\n' +
    '             uib-tooltip="SV Green Box Alternative"\n' +
    '             ng-click="pdvCtrl.replaceDrug(alt)"\n' +
    '             ng-bind="alt.name">\n' +
    '        </div>\n' +
    '        <div ng-show="alt.box === \'Y\'" class="btn btn-warning col-xs-12"\n' +
    '             uib-tooltip="SV Yellow Box Alternative"\n' +
    '             ng-click="pdvCtrl.replaceDrug(alt)"\n' +
    '             ng-bind="alt.name">\n' +
    '        </div>\n' +
    '        <div ng-hide="alt.box" class="btn btn-danger col-xs-12"\n' +
    '             uib-tooltip="Nicht erstattungsfähige Alternative"\n' +
    '             ng-click="pdvCtrl.replaceDrug(alt)"\n' +
    '             ng-bind="alt.ZBEZ">\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '\n' +
    '\n' +
    '  <div class="row" style="margin-bottom: 2px" ng-repeat="alt in pdvCtrl.newdrug.alternatives">\n' +
    '    <div class="btn btn-{{alt.color}} col-xs-12"\n' +
    '         uib-tooltip="{{alt.tooltip}}"\n' +
    '         ng-click="pdvCtrl.replaceDrug(alt)"\n' +
    '         ng-bind="alt.name">\n' +
    '    </div>\n' +
    '    <!--<div class="col-xs-9" ng-bind="alt.name"></div>\n' +
    '    <div class="col-xs-3">\n' +
    '      <button class="btn btn-{{alt.color}}" uib-tooltip="{{alt.tooltip}}" ng-click="replaceDrug(alt)">\n' +
    '        Auswählen\n' +
    '      </button>\n' +
    '    </div>-->\n' +
    '  </div>\n' +
    '</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('document/document.tpl.html',
    '\n' +
    '<file-browser patient="patrCtrl.patient"></file-browser>\n' +
    '<document-list patient="patrCtrl.patient"></document>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('document/document-list-directive.tpl.html',
    '<h3>Lokal erstellte Dokumente</h3>\n' +
    '\n' +
    '<div class="alert alert-info" ng-show="docCtrl.patDocuments.length === 0">Keine Dokumente gefunden</div>\n' +
    '<table st-table="docCtrl.pdocs" st-safe-src="docCtrl.patDocuments" class="table table-striped">\n' +
    '  <thead>\n' +
    '  <tr>\n' +
    '    <th st-sort="created" st-sort-default="reverse">Datum</th>\n' +
    '    <th st-sort="type">Typ</th>\n' +
    '    <th st-sort="keywords">Stichworte</th>\n' +
    '    <th>Datei</th>\n' +
    '  </tr>\n' +
    '  <tr>\n' +
    '    <th><input st-search="created" placeholder="Suche nach Datum" class="input-sm form-control" type="search"/></th>\n' +
    '    <th><input st-search="type" placeholder="Suche nach Typ" class="input-sm form-control" type="search"/></th>\n' +
    '    <th><input st-search="keywords" placeholder="Suche nach Stichwort" class="input-sm form-control" type="search"/>\n' +
    '    </th>\n' +
    '    <th></th>\n' +
    '  </tr>\n' +
    '  </thead>\n' +
    '  <tbody>\n' +
    '  <tr ng-repeat="doc in docCtrl.pdocs">\n' +
    '    <!--<td>{{doc.created | javadate: \'ll\'}}</td>-->\n' +
    '    <td>{{doc.created | moment: \'ll\'}}</td>\n' +
    '    <td>{{doc.type}}</td>\n' +
    '    <td>{{doc.keywords}}</td>\n' +
    '    <td>\n' +
    '      <a href="/routes/pdfreader/{{doc._id}}" target="_blank" class="btn btn-primary"><i class="fa fa-eye"></i></a>\n' +
    '      <a href="/routes/pdfdownload/{{doc._id}}" class="btn btn-primary"><i class="fa fa-download"></i></a>\n' +
    '    </td>\n' +
    '    <td></td>\n' +
    '  </tr>\n' +
    '  </tbody>\n' +
    '</table>\n' +
    '\n' +
    '\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('doctype/doctype.tpl.html',
    '<h2>doctype</h2>\n' +
    '<p>{{doctype.ctrlName}}</p>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('doctype/doctype-list-directive.tpl.html',
    '<!--<div class="btn btn-success" ng-click="dtlCtrl.addDoctype()">Neuen Dokumenttyp anlegen</div>-->\n' +
    '\n' +
    '<h3>Liste</h3>\n' +
    '<table class="table table-striped">\n' +
    '  <thead>\n' +
    '  <tr>\n' +
    '    <th>ID</th>\n' +
    '    <th>Name</th>\n' +
    '    <th>XSLT</th>\n' +
    '    <th>Drucker</th>\n' +
    '  </tr>\n' +
    '  </thead>\n' +
    '  <tr ng-repeat="dt in dtlCtrl.doctypelist">\n' +
    '    <td>{{dt._id}}</td>\n' +
    '    <td>{{dt.name}}</td>\n' +
    '    <td>{{dt.stylesheet}}</td>\n' +
    '    <td>{{dt.printer}}</td>\n' +
    '\n' +
    '    <td>\n' +
    '      <button class="btn btn-primary" ng-click="dtlCtrl.editDoctype(dt)">Editeren</button>\n' +
    '      <button class="btn btn-danger" ng-click="dtlCtrl.removeDoctype(dt)">Löschen</button>\n' +
    '    </td>\n' +
    '  </tr>\n' +
    '</table>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('doctype/doctype-edit-directive.tpl.html',
    '  <div class="row">\n' +
    '    <div class="form-group col-md-12">\n' +
    '      <label for="dtid">ID</label>\n' +
    '      <input type="text" ng-model="dteCtrl.doctype._id" class="form-control" id="dtid" tabindex="-1" readonly\n' +
    '             placeholder="ID" ng-blur="dteCtrl.save()">\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row">\n' +
    '    <div class="form-group col-md-12">\n' +
    '      <label for="name">Name</label>\n' +
    '      <input type="text" ng-model="dteCtrl.doctype.name" class="form-control" id="name" tabindex="1"\n' +
    '             placeholder="Name" ng-blur="dteCtrl.save()">\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row">\n' +
    '    <div class="form-group col-md-12">\n' +
    '      <label for="filename">Filename</label>\n' +
    '      <input type="text" ng-model="dteCtrl.doctype.filename" class="form-control" id="filename" tabindex="2"\n' +
    '             placeholder="Filename" ng-blur="dteCtrl.save()">\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <form class="form-vertical" id="editsvform">\n' +
    '    <div class="row">\n' +
    '      <div class="form-group col-md-12">\n' +
    '        <label for="xslt">Stylesheet (Filename, ohne Pfad)</label>\n' +
    '        <input type="text" ng-model="dteCtrl.doctype.stylesheet" class="form-control" id="xslt" tabindex="3"\n' +
    '               placeholder="XSLT Stylesheet Dateiname" ng-blur="dteCtrl.save()">\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  <div class="row">\n' +
    '    <div class="form-group col-md-12">\n' +
    '      <label for="print">Drucker  (Derzeit gewählt: {{dteCtrl.doctype.printer}} )</label>\n' +
    '      <select ng-model="dteCtrl.doctype.printer" class="form-control" id="print"  tabindex="3">\n' +
    '        <option value="{{p.printername}}" ng-repeat="p in dteCtrl.allprinters">{{p.printername}}, {{p.description}}</option>\n' +
    '      </select>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '\n' +
    '  <div class=" row">\n' +
    '    <div class="form-group">\n' +
    '      <button class="btn btn-success" type="button" tabindex="9" ng-click="dteCtrl.save(); dteCtrl.close()">Speichern &amp; Schließen</button>\n' +
    '      <button class="btn btn-danger" type="button" tabindex="10" ng-click="dteCtrl.close()">Schließen</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</form>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('dms/dms.tpl.html',
    '<aside ui-view="leftbar" class="hidden-sm hidden-xs hidden-md col-lg-2 col-xl-3"></aside>\n' +
    '<div ui-view="dmsmain" class="col-sm-12 col-md-12 col-lg-10 col-xl-9"></div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('consult/open-consults-directive.tpl.html',
    '<!-- Beware!  ocCtrl.allOpenConsults actually contains PATIENTS who are in consult (patient.inconsult == true )\n' +
    'so we are iterating over patients here! -->\n' +
    '<div class="alert alert-info" ng-show="ocCtrl.allOpenConsults.length === 0">\n' +
    '  Keine Konsultationen<br>\n' +
    '<span class="btn btn-primary" ng-click="ctrl.allOpenConsults.$refresh()">Neu laden</span>\n' +
    '</div>\n' +
    '<div ng-repeat="pat in ocCtrl.allOpenConsults" ng-hide="ocCtrl.allOpenConsults.length === 0">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-4 col-sm-2">\n' +
    '      <a ui-sref="patientrecord.current.consult({patientid: pat._id })">\n' +
    '        <img src="{{pat.image}}" class="img-responsive" alt="{{pat.firstname}} {{pat.lastname}}">\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="col-xs-8 col-sm-10">\n' +
    '      <h4>\n' +
    '        <!--<a ui-sref="patientrecord.current.consult({patientid: pat._id })">\n' +
    '          <span ng-show="pat.titlefront">{{pat.titlefront}} </span>{{pat.firstname}} {{pat.lastname}}<span\n' +
    '          ng-show="pat.titleback">, {{pat.titleback}} </span>,\n' +
    '          *&nbsp;{{pat.birthdate}}\n' +
    '        </a>-->\n' +
    '        <a ng-click="ocCtrl.switchToPatient(pat)">\n' +
    '          <span ng-show="pat.titlefront">{{pat.titlefront}} </span>{{pat.firstname}} {{pat.lastname}}<span\n' +
    '          ng-show="pat.titleback">, {{pat.titleback}} </span>,\n' +
    '          *&nbsp;{{pat.birthdate}}\n' +
    '        </a>\n' +
    '      </h4>\n' +
    '      <span ng-repeat="(key, value) in pat.keywords">\n' +
    '        <span class="label label-{{value}}">{{key}}</span>\n' +
    '      </span>\n' +
    '      <span ng-repeat="(key, value) in pat.openConsult.keywords">\n' +
    '        <span class="label label-{{value}}">{{key}}</span>\n' +
    '      </span>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('consult/imgviewer-directive.tpl.html',
    '<div class="modalspacer">\n' +
    '  <form class="form-vertical">\n' +
    '    <div class="row">\n' +
    '      <div class="col-xs-12">\n' +
    '        <img class="img-responsive" ng-src="/routes/files/filereader/{{ilCtrl.img.fileid}}">\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '      <div class="col-xs-12 col-sm-9">\n' +
    '        <label for="imglabel">Bildbeschreibung</label>\n' +
    '        <input id="imglabel" type="text" readonly ng-model="ilCtrl.img.label" class="form-control"\n' +
    '               ng-model-options="{ debounce: 2000 }">\n' +
    '      </div>\n' +
    '      <div class="col-xs-12 col-sm-3">\n' +
    '        <label for="imginc">In Befund</label>\n' +
    '        <input id="imginc" type="checkbox" readonly ng-model="ilCtrl.img.include" class="form-control">\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </form>\n' +
    '</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('consult/imglabeler-directive.tpl.html',
    '<div class="modalspacer">\n' +
    '  <form class="form-vertical">\n' +
    '    <div class="row">\n' +
    '      <div class="col-xs-12">\n' +
    '        <img class="img-responsive" ng-src="/routes/files/filereader/{{ilCtrl.img.fileid}}">\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '      <div class="col-xs-12 col-sm-9">\n' +
    '        <label for="imglabel">Bildbeschreibung</label>\n' +
    '        <input id="imglabel" type="text" ng-model="ilCtrl.img.label" class="form-control"\n' +
    '               ng-model-options="{ debounce: 2000 }">\n' +
    '      </div>\n' +
    '      <div class="col-xs-12 col-sm-3">\n' +
    '        <label for="imginc">In Befund</label>\n' +
    '        <input id="imginc" type="checkbox" ng-model="ilCtrl.img.include" class="form-control">\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '      <div class="col-xs-12">\n' +
    '        <span class="btn btn-success" ng-click="ilCtrl.save()">Speichern</span>\n' +
    '        <span class="btn btn-danger" ng-click="ilCtrl.cancel()">Abbrechen</span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </form>\n' +
    '</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('consult/examtherapies-directive.tpl.html',
    '<span class="btn btn-info btn-sm"\n' +
    '      ng-show="etCtrl.consult.examinations.length"\n' +
    '      popover-trigger="outsideClick"\n' +
    '      uib-popover-template="etCtrl.popover"\n' +
    '      popover-title="Therapie-Einträge der Untersuchungen"\n' +
    '      popover-placement="auto top">Therapie-Emfehlungen der Untersuchungen</span>\n' +
    '\n' +
    '\n' +
    '<script type="text/ng-template" id="examtherapies_popver.tpl.html">\n' +
    '  <div>\n' +
    '    <div ng-repeat="exam in etCtrl.consult.examinations  | filter: {active: true}">\n' +
    '      <h4 ng-bind="exam.type"></h4>\n' +
    '      <span ng-repeat="val in exam.template.recommendation.avalues">{{val.name}}{{$last ? \'\' : \', \'}}</span>\n' +
    '    </div>\n' +
    '\n' +
    '<p class="alert alert-info" style="margin-top: 10pt;">\n' +
    '  Die Therapie-Empfehlungen der einzelnen Untersuchungen werden automatisch in den Gesamtbefund/Arztbrief übernommen.</p>\n' +
    '\n' +
    '  </div>\n' +
    '</script>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('consult/examsummaries-directive.tpl.html',
    '<span class="btn btn-info btn-sm"\n' +
    '      ng-show="esCtrl.consult.examinations.length"\n' +
    '      popover-trigger="outsideClick"\n' +
    '      uib-popover-template="esCtrl.popover"\n' +
    '      popover-title="Zusammenfassungen der U."\n' +
    '      popover-placement="auto top">Zusammenfassung d. U.</span>\n' +
    '\n' +
    '\n' +
    '<script type="text/ng-template" id="examsummaries_popver.tpl.html">\n' +
    '  <div>\n' +
    '    <div ng-repeat="exam in esCtrl.consult.examinations | filter: {active: true}">\n' +
    '      <h4 ng-bind="exam.type"></h4>\n' +
    '      <span ng-bind="exam.diagnosis"></span>\n' +
    '      <!--<span ng-repeat="val in exam.template.diagnosis.avalues">{{val.name}}{{$last ? \'\' : \', \'}}</span>-->\n' +
    '    </div>\n' +
    '\n' +
    '    <p class="alert alert-info" style="margin-top: 10pt;">\n' +
    '      Die Befundzusammenfassungen der einzelne Untersuchungen werden automatisch in den\n' +
    '      Gesamtbefund/Arztbrief übernommen.\n' +
    '    </p>\n' +
    '\n' +
    '  </div>\n' +
    '</script>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('consult/examcomments-directive.tpl.html',
    '<span class="btn btn-info btn-sm"\n' +
    '      ng-show="ecCtrl.consult.examinations.length"\n' +
    '      popover-trigger="outsideClick"\n' +
    '      uib-popover-template="ecCtrl.popover"\n' +
    '      popover-title="Kommentare/Anmerkungen der Untersuchungen"\n' +
    '      popover-placement="auto top">Anmerkungen der U.</span>\n' +
    '\n' +
    '\n' +
    '<script type="text/ng-template" id="examcomments_popver.tpl.html">\n' +
    '  <div>\n' +
    '    <div ng-repeat="exam in ecCtrl.consult.examinations | filter: {active: true}">\n' +
    '      <h4 ng-bind="exam.type"></h4>\n' +
    '      <span ng-repeat="val in exam.template.comment.avalues">{{val.name}}{{$last ? \'\' : \', \'}}</span>\n' +
    '    </div>\n' +
    '\n' +
    '    <p class="alert alert-warning" style="margin-top: 10pt;">\n' +
    '      Die Kommentare der einzelnen Untersuchungen werden NICHT automatisch in den Gesamtbefund/Arztbrief übernommen.\n' +
    '    </p>\n' +
    '\n' +
    '  </div>\n' +
    '</script>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('consult/current-consult-directive.tpl.html',
    '<!-- This is a very important hack: DO NOT REMOVE this totally pointless invisible bindings -->\n' +
    '<!-- Removal of these bindings will cause exam and consult close detection to fail -->\n' +
    '<span style="visibility: hidden">Consult Status patient: <span ng-bind=\'consCtrl.patient.inconsult\'></span> </span>\n' +
    '<span style="visibility: hidden">Consult Status openConsult: <span ng-bind=\'consCtrl.patient.openConsult.open\'></span> </span>\n' +
    '<span style="visibility: hidden">Exam Status patient: <span ng-bind=\'consCtrl.patient.inexam\'></span> </span>\n' +
    '<span style="visibility: hidden">Exam Status openExam: <span ng-bind=\'consCtrl.patient.openExam.open\'></span> </span>\n' +
    '<span style="visibility: hidden">openExam type: <span ng-bind=\'consCtrl.patient.openExam.template.displayname\'></span> </span>\n' +
    '<span style="visibility: hidden">Consultstate: <span ng-bind=\'consCtrl.patient.consultstate\'></span> </span>\n' +
    '<span style="visibility: hidden">Checkoutstate: <span ng-bind=\'consCtrl.patient.checkoutstate\'></span> </span>\n' +
    '\n' +
    '\n' +
    '<!-- not in consult -->\n' +
    '<div ng-hide="consCtrl.patient.inconsult == true && consCtrl.patient.openConsult.open === true">\n' +
    '  <div class="alert alert-info">\n' +
    '    <h4>Die Konsultation wurde von anderer Stelle beendet</h4>\n' +
    '    <span class="btn btn-primary"\n' +
    '          ng-click="consCtrl.$state.go(\'patientrecord.history\')">Zur Patienten-Historie wechseln</span>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<!-- in consult, already in checkout-->\n' +
    '<div\n' +
    '    ng-show="consCtrl.patient.inconsult == true && consCtrl.patient.openConsult.open === true && consCtrl.patient.incheckout === true">\n' +
    '  <div class="alert alert-info">\n' +
    '    <h4>Diese Konsultation wird gerade abgeschlossen</h4>\n' +
    '    <span class="btn btn-primary" ng-click="consCtrl.switchToExit()">\n' +
    '      Zum Konsultationsabschluß wechseln\n' +
    '    </span>\n' +
    '  </div>\n' +
    '\n' +
    '\n' +
    '</div>\n' +
    '\n' +
    '<!-- In consult, not already in checkout -->\n' +
    '<div\n' +
    '    ng-show="consCtrl.patient.inconsult == true && consCtrl.patient.openConsult.open === true && consCtrl.patient.incheckout !== true">\n' +
    '  <!-- Current Consult data entry GUI -->\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12 col-sm-4">\n' +
    '      <h3>Aktuelle Konsultation </h3>\n' +
    '    </div>\n' +
    '    <div class="col-xs-12 col-sm-8" style="text-align: right">\n' +
    '      <h3>\n' +
    '        <!-- Get media -->\n' +
    '        <div class="spacer-top">\n' +
    '          <imagecapture filehandle="consCtrl.newimage" captureid="cconsultphoto"></imagecapture>\n' +
    '          <fileupload filehandle="consCtrl.newfile"></fileupload>\n' +
    '          <button class="btn btn-primary" uib-tooltip="Überweisung anlegen"\n' +
    '                  ng-click="consCtrl.addReferralModal(consCtrl.patient)">\n' +
    '            <i class="fa fa-plus"></i> Überweisung\n' +
    '          </button>\n' +
    '          <button class="btn btn-primary" uib-tooltip="Hilfsmitel verordnen"\n' +
    '                  ng-click="consCtrl.addAideModal(consCtrl.patient)">\n' +
    '            <i class="fa fa-plus"></i> Hilfsmittel\n' +
    '          </button>\n' +
    '        </div>\n' +
    '      </h3>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  \n' +
    '  \n' +
    '  <form>\n' +
    '    <!-- Consult Findings data upper row (Symptome, Therapie) -->\n' +
    '    <div class="row">\n' +
    '      <!-- Symptome -->\n' +
    '      <div class="col-xs-12 col-sm-6">\n' +
    '        <div class="form-group">\n' +
    '          <div class="form-group">\n' +
    '            <label for="consultsymptoms"><h4>Symptome <span class="btn btn-sm">&nbsp;</span></h4></label>\n' +
    '            <textarea class="form-control" ng-model="consCtrl.patient.openConsult.symptoms"\n' +
    '                      rows="7"\n' +
    '                      ng-change="consCtrl.patient.openConsult.$save()"\n' +
    '                      ng-model-options="{ updateOn: \'default blur\', debounce: { default: 20000, blur: 0 } }"\n' +
    '                      id="consultsymptoms"></textarea>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <!-- Therapie -->\n' +
    '      <div class="col-xs-12 col-sm-6">\n' +
    '        <div class="form-group">\n' +
    '          <label for="findingstherapy">\n' +
    '            <h4>Therapieempfehlung\n' +
    '              <examtherapies consult="consCtrl.patient.openConsult"></examtherapies>\n' +
    '              <span class="btn btn-sm">&nbsp;</span>\n' +
    '            </h4>\n' +
    '          </label>\n' +
    '          <textarea type="text" ng-model="consCtrl.patient.openConsult.therapy" class="form-control"\n' +
    '                    rows="7"\n' +
    '                    ng-change="consCtrl.patient.openConsult.$save()"\n' +
    '                    ng-model-options="{ updateOn: \'default blur\', debounce: { default: 20000, blur: 0 } }"\n' +
    '                    id="findingstherapy"></textarea>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    \n' +
    '    <!-- Consult Findings data lower row (Anmerkungen, Notizen) -->\n' +
    '    <div class="row">\n' +
    '      <!-- Befund -->\n' +
    '      <div class="col-xs-12 col-sm-6">\n' +
    '        <div class="form-group">\n' +
    '          <label for="findingsfindings"\n' +
    '                 uib-tooltip="Ergänzung zu Detailbefunden der einzelnen Untersuchungen">\n' +
    '            <h4>Gesamtbefund\n' +
    '              <examsummaries consult="consCtrl.patient.openConsult"></examsummaries>\n' +
    '              <examcomments consult="consCtrl.patient.openConsult"></examcomments>\n' +
    '              <span class="btn btn-sm">&nbsp;</span>\n' +
    '            </h4>\n' +
    '          </label>\n' +
    '          <textarea type="text" ng-model="consCtrl.patient.openConsult.findings" class="form-control"\n' +
    '                    rows="7"\n' +
    '                    ng-change="consCtrl.patient.openConsult.$save()"\n' +
    '                    ng-model-options="{ updateOn: \'default blur\', debounce: { default: 20000, blur: 0 } }"\n' +
    '                    id="findingsfindings"></textarea>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="col-xs-12 col-sm-6">\n' +
    '        <!-- Patientenotizen -->\n' +
    '        <div class="form-group">\n' +
    '          <label for="consultnotes"\n' +
    '                 uib-tooltip="Diese Patientennotizen werden nicht in Befunde oder andere Dokumente übernommen.">\n' +
    '            <h4>Permanente Notizen zum Patienten (privat) <span class="btn btn-sm">&nbsp;</span></h4></label>\n' +
    '          <textarea class="form-control" ng-model="consCtrl.patient.notes"\n' +
    '                    rows="7"\n' +
    '                    ng-change="consCtrl.patient.$save()"\n' +
    '                    ng-model-options="{ updateOn: \'default blur\', debounce: { default: 20000, blur: 0 } }"\n' +
    '                    id="consultnotes"></textarea>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    \n' +
    '    <!-- show images -->\n' +
    '    <div class="row spacer-top" ng-show="consCtrl.patient.openConsult.images">\n' +
    '      <h4 class="col-xs-12">Bilder &amp; Videos</h4>\n' +
    '      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"\n' +
    '           ng-repeat="img in consCtrl.patient.openConsult.images | filter: {mimetype: \'image/jpeg\'} |  orderBy: \'_id\'">\n' +
    '        \n' +
    '        <img class="img-responsive" src="/routes/files/filereader/{{img.fileid}}"\n' +
    '             uib-tooltip="{{img.label}}"\n' +
    '             ng-click="consCtrl.editImage(img)">\n' +
    '      </div>\n' +
    '      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"\n' +
    '           ng-repeat="video in consCtrl.patient.openConsult.images | filter: {mimetype: \'video/quicktime\'} |  orderBy: \'_id\'">\n' +
    '        \n' +
    '        <video controls class="img-responsive" ng-src="{{video.fileid | trustUrl}}"></video>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    \n' +
    '    <!-- show files -->\n' +
    '    <div class="row spacer-top" ng-show="consCtrl.patient.openConsult.files">\n' +
    '      <div class="col-xs-12">\n' +
    '        <h4>Dateien</h4>\n' +
    '        <div ng-repeat="file in consCtrl.patient.openConsult.files">\n' +
    '          <div class="col col-xs-12 col-md-3"><a href="/routes/files/filereader/{{file.fileid}}" class="btn btn-primary"\n' +
    '                                                 uib-tooltip="Anzeige/download">\n' +
    '            <i class="fa fa-file-pdf-o" ng-show="file.mimetype === \'application/pdf\'"></i>\n' +
    '            <i class="fa fa-file-o" ng-hide="file.mimetype === \'application/pdf\'"></i>&nbsp;{{file.mimetype}}</a>\n' +
    '          </div>\n' +
    '          <div class="col col-xs-12 col-md-9">\n' +
    '            <input type="text" ng-model="file.label" class="form-control"\n' +
    '                   ng-change="consCtrl.patient.openConsult.$save()"\n' +
    '                   ng-model-options="{ updateOn: \'default blur\', debounce: { default: 20000, blur: 0 } }"\n' +
    '                   placeholder="Beschreibung des Inhalts">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    \n' +
    '    <!-- Untersuchungsstarter (keine offene Untersuchung) -->\n' +
    '    <div ng-hide="consCtrl.patient.inexam === true">\n' +
    '      <exam-starter patient="consCtrl.patient"></exam-starter>\n' +
    '    </div>\n' +
    '    \n' +
    '    <!-- Laufende Untersuchung -->\n' +
    '    <div ng-show="consCtrl.patient.inexam === true">\n' +
    '      <div class="alert alert-info">\n' +
    '        <h4>Laufende Untersuchung!</h4>\n' +
    '        <h4 ng-show="consCtrl.swteerror">Inkonsistenter Datenstand - Versuche Fehler zu beheben\n' +
    '          <i class="fa fa-spin fa-spinner"></i></h4>\n' +
    '        <span class="btn btn-danger"\n' +
    '              uib-tooltip="Achtung! Gefahr! Nicht gleichzeitig auf mehreren Rechnern Daten eingeben!"\n' +
    '              ng-click="consCtrl.switchToOpenExam()">\n' +
    '           <i class="fa fa-spin fa-spinner" ng-show="consCtrl.swtespin"></i>\n' +
    '          &nbsp;Zur laufenden Untersuchung wechseln: {{consCtrl.patient.openExam.template.displayname}}\n' +
    '        </span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    \n' +
    '    \n' +
    '    <!-- Buttons zum weiterschalten-->\n' +
    '    <div>\n' +
    '      \n' +
    '      <!-- Rechnungspositionen -->\n' +
    '      <button class="btn btn-info spacer-top" type="button"\n' +
    '              ng-hide="consCtrl.patient.incheckout === true"\n' +
    '              ng-click="consCtrl.$state.go(\'patientrecord.billing\')">\n' +
    '        Weiter zu den Rechnungspositionen\n' +
    '      </button>\n' +
    '      <!-- Drucken & Abschluss -->\n' +
    '      <button class="btn btn-info spacer-top" type="button"\n' +
    '              ng-hide="consCtrl.patient.incheckout === true"\n' +
    '              ng-click="consCtrl.$state.go(\'patientrecord.checkout.ccdocs\')">\n' +
    '        Weiter zu Drucken & Abschluss\n' +
    '      </button>\n' +
    '    \n' +
    '    </div>\n' +
    '  </form>\n' +
    '  \n' +
    '  <!-- currentConsult Examinations -->\n' +
    '  <div ng-show="consCtrl.patient.openConsult.examinations.length > 0" class="spacer-top">\n' +
    '    <!-- TODO openConsult.examinations do not contain valid restmod handles FIXME with separate query -->\n' +
    '    <current-exams-list exams="consCtrl.patient.openConsult.examinations"\n' +
    '                        patient="consCtrl.patient"></current-exams-list>\n' +
    '  </div>\n' +
    '  \n' +
    '  <div style="min-height: 40pt">&nbsp;</div>\n' +
    '\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('consult/current-consult-container-directive.tpl.html',
    '<!-- This is a very important hack: DO NOT REMOVE this totally pointless invisible heading -->\n' +
    '<!-- Removal of these spans will cause exam and consult close detection to fail -->\n' +
    '<span style="visibility: hidden">Consult Status patient: <span ng-bind=\'consCtrl.patient.inconsult\'></span></span>\n' +
    '<span style="visibility: hidden">Consult Status openConsult: <span ng-bind=\'consCtrl.patient.openConsult.open\'></span>\n' +
    '</span>\n' +
    '<span style="visibility: hidden">Exam Status patient: <span ng-bind=\'consCtrl.patient.inexam\'></span></span>\n' +
    '<span style="visibility: hidden">Exam Status openExam: <span ng-bind=\'consCtrl.patient.openExam.open\'></span></span>\n' +
    '<span style="visibility: hidden">examinations: <span ng-bind=\'consCtrl.patient.openConsult.examinations\'></span></span>\n' +
    '\n' +
    '\n' +
    '<!-- not in consult -->\n' +
    '<div ng-hide="consCtrl.patient.inconsult == true && consCtrl.patient.openConsult.open === true">\n' +
    '  <div class="alert alert-info"\n' +
    '       ng-show="consCtrl.patient.openConsult.open === false && consCtrl.patient.openConsult == null">\n' +
    '    <h4>Die Konsultation für diesen Patienten wurde von anderer Seite beendet.</h4>\n' +
    '  </div>\n' +
    '\n' +
    '\n' +
    '  <div class="alert alert-info" ng-show="consCtrl.patient.inconsult === false">\n' +
    '    <h4><i class="fa fa-info-circle"></i>\n' +
    '      Derzeit existiert keine aktuelle Konsultation für diesen Patienten.</h4>\n' +
    '\n' +
    '    <form>\n' +
    '      <button class="btn btn-primary" ng-click="consCtrl.startConsult();">\n' +
    '        Neue Konsultation starten\n' +
    '      </button>\n' +
    '      <span class="btn btn-info" ng-click="consCtrl.$state.go(\'patientrecord.history\')">Zur Patientenhistorie wechseln</span>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '\n' +
    '</div>\n' +
    '\n' +
    '<!-- in consult -->\n' +
    '<div ng-show="consCtrl.patient.inconsult == true && consCtrl.patient.openConsult.open === true">\n' +
    '  <div class="row">\n' +
    '    <!-- starred consult and consult keywords -->\n' +
    '    <div class="col-xs-2 col-md-1 v-align" style="text-align: center">\n' +
    '      <i class="fa fa-3x fa-star-o" ng-hide="consCtrl.patient.openConsult.stared"\n' +
    '         ng-click="consCtrl.patient.openConsult.stared = true"\n' +
    '         uib-tooltip="Konsultation als wichtig markieren"></i>\n' +
    '      <i class="fa fa-3x fa-star" ng-show="consCtrl.patient.openConsult.stared"\n' +
    '         style="color: #dddd33"\n' +
    '         ng-click="consCtrl.patient.openConsult.stared = false"\n' +
    '         uib-tooltip="Konsultation als unwichtig markieren"></i>\n' +
    '    </div><!-- css sometimes totally sucks, DO NOT REMOVE this comment!\n' +
    '  --><div class="col-xs-10 col-md-11 v-align">\n' +
    '      <h4 ng-show="consCtrl.patient.inconsult">Stichworte zu dieser Konsultation</h4>\n' +
    '\n' +
    '      <keywords-widget keywords="consCtrl.patient.openConsult.keywords"\n' +
    '                       text="Stichworte für diese Konsultation"></keywords-widget>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <!-- ui view for consult data entry, emax data entry, checkout stages -->\n' +
    '  <div ui-view="cur-details"></div>\n' +
    '\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('consult/consult.tpl.html',
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('consult/consult-list-directive.tpl.html',
    '<!-- new consult button -->\n' +
    '<!-- show only if there is no open consult for the patientid -->\n' +
    '<!-- <form ng-show="openConsult === \'undefined\' || openConsult == null"> -->\n' +
    '<form ng-hide="consCtrl.patient.inconsult" id="startconsult" name="startconsult">\n' +
    '  <button class="btn btn-success spacer-bottom" type="button" ng-click="consCtrl.startConsult();">\n' +
    '    Konsultation starten\n' +
    '  </button>\n' +
    '</form>\n' +
    '\n' +
    '<!--<form ng-hide="consCtrl.patient.inconsult || consCtrl.patient.waitinglist.length > 0" id="putonwl" name="putonwl">\n' +
    '  <button ng-repeat="wl in consCtrl.waitinglists"\n' +
    '          ng-click="consCtrl.wlAddPatient(wl, consCtrl.patient)"\n' +
    '          class="btn btn-info">\n' +
    '    <i class="fa fa-arrow-right"></i> WL {{wl.name}}\n' +
    '  </button>\n' +
    '</form>\n' +
    '\n' +
    '<form ng-show="consCtrl.patient.waitinglist.length > 0" id="removefromwl" name="removefromwl">\n' +
    '  <button class="btn btn-warning spacer-bottom" type="button"\n' +
    '          ng-click="consCtrl.wlRemovePatient(consCtrl.patient.waitinglist, consCtrl.patient);">\n' +
    '    Patient von Warteliste {{consCtrl.patient.waitinglist}} streichen\n' +
    '  </button>\n' +
    '</form>-->\n' +
    '\n' +
    '\n' +
    '<!--\n' +
    '  &nbsp;\n' +
    '      Auf Warteliste setzen:&nbsp;<select\n' +
    '        ng-model="patient.waitinglist"\n' +
    '        ng-change="consCtrl.changedWl()"\n' +
    '        ng-options="wl as wl.name for wl in consCtrl.waitinglists track by w._id">\n' +
    '      <option value="unset">Von Warteliste entfernen</option>\n' +
    '    </select>\n' +
    ' -->\n' +
    '\n' +
    '<form ng-show="consCtrl.patient.inconsult">\n' +
    '  <button class="btn btn-success spacer-bottom" type="button" ui-sref="patientrecord.current">\n' +
    '    Zur aktuellen Konsultation wechseln\n' +
    '  </button>\n' +
    '</form>\n' +
    '\n' +
    '<!--\n' +
    '<h4>Diagnosen</h4>\n' +
    '<keywords-widget keywords="consCtrl.patient.diagnoses" text="Neue Diagnose für Patienten"\n' +
    '                 orderbycolor="true"></keywords-widget>\n' +
    '-->\n' +
    '\n' +
    '<!-- Patientenotizen -->\n' +
    '<div class="form-group">\n' +
    '  <label for="consultnotes">\n' +
    '    <h4 uib-tooltip="Diese Patientennotizen werden nicht in Befunde oder andere Dokumente übernommen.">Permanente\n' +
    '      Notizen zum Patienten (privat)</h4></label>\n' +
    '  <textarea class="form-control" ng-model="consCtrl.patient.notes"\n' +
    '            ng-change="consCtrl.patient.$save()"\n' +
    '            ng-model-options="{ updateOn: \'default blur\', debounce: { default: 20000, blur: 0 } }"\n' +
    '            id="consultnotes"></textarea>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '<!-- Historie -->\n' +
    '<h3>Historie</h3>\n' +
    '\n' +
    '<div class="alert alert-info" ng-show="consCtrl.patConsults.length === 0">\n' +
    '  <h4><i class="fa fa-info-circle"></i>&nbsp;Es wurden noch keine Konsultationen abgeschlossen</h4>\n' +
    '</div>\n' +
    '\n' +
    '<uib-accordion close-others="oneAtATime">\n' +
    '  <uib-accordion-group heading="{{group.title}}"\n' +
    '                       ng-repeat="cons in consCtrl.patient.consults  | filter: {open: false } | orderBy: \'-created\'">\n' +
    '    <uib-accordion-heading>\n' +
    '      <!-- Stern/starred -->\n' +
    '      <div class="row" style="padding:3px">\n' +
    '        <i ng-show="cons.stared" class="fa fa-star fa-2x" style="color: #dddd33"></i><i ng-hide="cons.stared"\n' +
    '                                                                                        class="fa fa-star-o fa-2x"></i>\n' +
    '        {{cons.created | date}}\n' +
    '        <!-- Consult Keywords -->\n' +
    '        <!--<span ng-repeat="kw in cons.flatkeywords track by $index | orderBy: \'nr\'"><span class="label label-{{kw.col}}">{{kw.key}}</span>&ensp; </span>-->\n' +
    '        <span ng-repeat="kw in cons.keywords track by $index"><span class="label label-{{kw.col || primary}}">{{kw.key}}</span>&ensp; </span>\n' +
    '      </div>\n' +
    '      <div class="row" style="padding:3px">\n' +
    '        <!-- Exam Labels -->\n' +
    '        <!-- old -->\n' +
    '        <span ng-repeat="label in cons.examlabels track by $index"\n' +
    '              ng-hide="cons.examlabelsPrimary || cons.examlabelsSecondary"><span\n' +
    '            class="label label-success">{{label}}</span>&ensp; </span>\n' +
    '        <!-- new primary labels -->\n' +
    '        <span ng-repeat="plabel in cons.examlabelsPrimary track by $index">\n' +
    '        <!-- PDF vorhanden -->\n' +
    '        <a href="/routes/pdfreader/{{plabel.docid}}" uib-tooltip="Befund PDF Ansicht" ng-show="plabel.docid"\n' +
    '           target="_blank" style="text-decoration: none">\n' +
    '          <span class="label label-primary">\n' +
    '            {{plabel.type}}\n' +
    '          </span>&ensp;\n' +
    '        </a>\n' +
    '          <!-- PDF nicht vorhanden -->\n' +
    '        <span class="label label-primary" ng-hide="plabel.docid">\n' +
    '            {{plabel.type}}\n' +
    '          </span>&ensp;\n' +
    '        </span>\n' +
    '        <!-- new secondary labels -> Gesamtbefund -->\n' +
    '        <span ng-repeat="slabel in cons.examlabelsSecondary track by $index">\n' +
    '           <!-- Nice, try, but din\'t work out - no way to access gesamtbefund withour complicated query -->\n' +
    '          <!-- <a href="/routes/pdfreader/{{cons.docid}}" uib-tooltip="Gesamtbefund PDF" target="_blank"\n' +
    '              style="text-decoration: none">-->\n' +
    '            <span class="label label-info">{{slabel.type}}</span>&ensp;\n' +
    '          <!--</a>-->\n' +
    '        </span>\n' +
    '      </div>\n' +
    '    </uib-accordion-heading>\n' +
    '\n' +
    '\n' +
    '    <h4 ng-show="cons.symptoms">Symptome</h4>\n' +
    '    <p ng-show="cons.symptoms">{{cons.symptoms}}</p>\n' +
    '    <h4 ng-show="cons.therapy">Therapie</h4>\n' +
    '    <p ng-show="cons.therapy">{{cons.therapy}}</p>\n' +
    '    <h4 ng-show="cons.diagnoses">Diagnosen</h4>\n' +
    '    <p ng-show="cons.diagnoses">{{cons.diagnoses}}</p>\n' +
    '    <h4>Anmerkungen</h4>\n' +
    '    {{cons.comments}}\n' +
    '    <div>\n' +
    '      <!-- Dokumente einblenden -->\n' +
    '      <button class="btn btn-primary" ng-hide="cons.docs" ng-click="consCtrl.getDocuments(cons)">Dokumente einblenden\n' +
    '      </button>\n' +
    '      <button class="btn btn-primary" ng-show="cons.docs" ng-click="cons.docs = null">Dokumente ausblenden</button>\n' +
    '      <!-- Untersuchungen einblenden -->\n' +
    '      <span>\n' +
    '        <button class="btn btn-primary" ng-hide="cons.exams" ng-click="consCtrl.getExams(cons)">\n' +
    '          Untersuchungen einblenden\n' +
    '        </button>\n' +
    '        <button class="btn btn-primary" ng-show="cons.exams" ng-click="cons.exams = null">\n' +
    '          Untersuchungen ausblenden\n' +
    '        </button>\n' +
    '      </span>\n' +
    '      <!-- Bilder einblenden -->\n' +
    '      <span ng-show="cons.images.length > 0 ">\n' +
    '        <button class="btn btn-primary" ng-hide="cons.imgs" ng-click="cons.imgs = 1">\n' +
    '            Bilder der Konsultation einblenden\n' +
    '        </button>\n' +
    '        <button class="btn btn-primary" ng-show="cons.imgs" ng-click="cons.imgs = null">Bilder ausblenden</button>\n' +
    '      </span>\n' +
    '\n' +
    '      <!-- Dokumente Block -->\n' +
    '      <div ng-show="cons.docs">\n' +
    '        <h4>Dokumente (Detail- &amp; Gesamtbefund, Überweisungen, ...)</h4>\n' +
    '        <div class="row" ng-repeat="doc in cons.docs">\n' +
    '          <div class="col-md-10">{{doc.type}}</div>\n' +
    '          <div class="col-md-2" style="text-align: right">\n' +
    '            <a href="/routes/pdfreader/{{doc._id}}" target="_blank" class="btn btn-primary" ng-show="doc._id"><i\n' +
    '                class="fa fa-eye"></i></a>\n' +
    '            <a href="/routes/pdfdownload/{{doc._id}}" class="btn btn-primary" ng-show="doc._id"><i\n' +
    '                class="fa fa-download"></i></a>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <!-- Exam Block  -->\n' +
    '      <div ng-show="cons.exams">\n' +
    '        <h4>Untersuchungen</h4>\n' +
    '        <div class="alert alert-info" ng-show="cons.examinations.length === 0">Keine Untersuchungen gefunden</div>\n' +
    '        <div class="row examrow" ng-repeat="exam in cons.examinations">\n' +
    '          <div class="col-md-9"><strong>{{exam.type}}</strong><br>{{exam.diagnosis}}</div>\n' +
    '          <div class="col-md-3" style="text-align: right">\n' +
    '            <a ng-show="exam.files.length > 0 && !eximg" ng-click="eximg = 1" class="btn btn-primary"\n' +
    '               uib-tooltip="Bilder anzeigen">\n' +
    '              <i class="fa fa-image"></i>\n' +
    '            </a>\n' +
    '            <a ng-show="exam.files.length > 0 && eximg" ng-click="eximg = null" class="btn btn-primary"\n' +
    '               uib-tooltip="Bilder ausblenden">\n' +
    '              <i class="fa fa-image"></i>\n' +
    '            </a>\n' +
    '            <a ng-show="!exdata" ng-click="exdata = 1" class="btn btn-primary" uib-tooltip="Daten anzeigen">\n' +
    '              <i class="fa fa-table"></i>\n' +
    '            </a>\n' +
    '            <a ng-show="exdata" ng-click="exdata = null" class="btn btn-primary" uib-tooltip="Daten ausblenden">\n' +
    '              <i class="fa fa-table"></i>\n' +
    '            </a>\n' +
    '            <!-- Untersuchungs Befund PDF für includefindings false untersuchungen -->\n' +
    '            <a href="/routes/pdfreader/{{exam.docid}}" target="_blank" ng-hide="exam.template.includefindings === true"\n' +
    '               uib-tooltip="Befund PDF anzeigen" class="btn btn-primary"><i class="fa fa-eye"></i></a>\n' +
    '            <a href="/routes/pdfdownload/{{exam.docid}}" ng-hide="exam.template.includefindings === true"\n' +
    '               uib-tooltip="Befund PDF speichern" class="btn btn-primary"><i class="fa fa-download"></i></a>\n' +
    '            <!--            &lt;!&ndash; Gesamtbefund für includefindings untersuchungen &ndash;&gt;\n' +
    '                        <a href="/routes/pdfreader/{{cons.docid}}" target="_blank" ng-show="exam.template.includefindings === true"\n' +
    '                           uib-tooltip="Befund PDF anzeigen" class="btn btn-primary"><i class="fa fa-eye"></i></a>\n' +
    '                        <a href="/routes/pdfdownload/{{cons.docid}}" ng-show="exam.template.includefindings === true"\n' +
    '                           uib-tooltip="Befund PDF speichern" class="btn btn-primary"><i class="fa fa-download"></i></a>-->\n' +
    '          </div>\n' +
    '          <!-- Exam Bilder -->\n' +
    '          <div ng-show="eximg" style="padding:20px">\n' +
    '\n' +
    '            <!--<div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"-->\n' +
    '            <div class="col-xs-12"\n' +
    '                 ng-click="consCtrl.showImage(img)"\n' +
    '                 ng-repeat="img in exam.files | filter: {mimetype: \'image/jpeg\'} |  orderBy: \'_id\'">\n' +
    '\n' +
    '              <img class="img-responsive" src="/routes/files/filereader/{{img.fileid}}"\n' +
    '                   uib-tooltip="{{img.label}}">\n' +
    '            </div>\n' +
    '\n' +
    '          </div>\n' +
    '          <!-- Exam Daten -->\n' +
    '          <div ng-show="exdata" style="padding:20px">\n' +
    '\n' +
    '            <div class="row">\n' +
    '              <div class="col-xs-12">\n' +
    '                <!-- fieldgroups -->\n' +
    '                <div ng-repeat="grp in exam.template.fieldgroups">\n' +
    '                  <h5 class="dl-horizontal" ng-hide="grp.label == \'\'" ng-model="grp.label"\n' +
    '                      style="border-bottom: 1px solid black; border-top: 1px solid black;  padding-top: 5px; padding-bottom: 5px">\n' +
    '                    {{grp.label}}\n' +
    '                  </h5>\n' +
    '                  <h5 class="dl-horizontal" ng-show="grp.label == \'\'" ng-model="grp.label"\n' +
    '                      style="border-bottom: 1px solid black; padding-top: 5px; padding-bottom: 5px">\n' +
    '                  </h5>\n' +
    '                  <!-- fields -->\n' +
    '                  <dl class="dl-horizontal">\n' +
    '                    <span class="dl-horizontal" ng-repeat="field in grp.fields">\n' +
    '                      <dt>{{field.label}}</dt>\n' +
    '                      <dd><span ng-repeat="val in field.avalues">{{val.name}}{{$last ? \'\' : \', \'}}</span></dd>\n' +
    '                    </span>\n' +
    '                  </dl>\n' +
    '                </div>\n' +
    '\n' +
    '                <!-- exam images -->\n' +
    '                <div class="row spacer-top" ng-show="exam.files">\n' +
    '                  <!--<div class="col-xs-12 imgcontainer"-->\n' +
    '                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 imgcontainer"\n' +
    '                       ng-repeat="img in exam.files | filter: {mimetype: \'image/jpeg\'} |  orderBy: \'_id\'">\n' +
    '\n' +
    '                    <img class="img-responsive" src="/routes/files/filereader/{{img.fileid}}"\n' +
    '                         uib-tooltip="{{img.label}}">\n' +
    '                    <!--<img class="img-responsive" src="/routes/files/filereader/{{img.fileid}}"\n' +
    '                         uib-tooltip="{{img.label}}"\n' +
    '                         ng-click="celCtrl.editImage(img)">-->\n' +
    '                    <!--<div class="imgcheckbox"><input type="checkbox" ng-model="img.include" ng-click="exam.$save()"></div>-->\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <!-- Befundblock  -->\n' +
    '                <h5 class="dl-horizontal"\n' +
    '                    style="border-bottom: 1px solid black; border-top: 1px solid black; padding-top: 5px; padding-bottom: 5px">\n' +
    '                  Befund</h5>\n' +
    '                <dl class="dl-horizontal">\n' +
    '                  <dt>Diagnose</dt>\n' +
    '                  <dd><span\n' +
    '                      ng-repeat="diagnosis in exam.template.diagnosis.avalues">{{diagnosis.name}}{{$last ? \'\' : \', \'}}</span>\n' +
    '                  </dd>\n' +
    '                  <dt>Empfehlung</dt>\n' +
    '                  <dd><span\n' +
    '                      ng-repeat="diagnosis in exam.template.recommendation.avalues">{{diagnosis.name}}{{$last ? \'\' : \', \'}}</span>\n' +
    '                  </dd>\n' +
    '                  <dt>Anmerkung</dt>\n' +
    '                  <dd><span\n' +
    '                      ng-repeat="diagnosis in exam.template.comment.avalues">{{diagnosis.name}}{{$last ? \'\' : \', \'}}</span>\n' +
    '                  </dd>\n' +
    '                </dl>\n' +
    '\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '\n' +
    '      <!-- Bilder block -->\n' +
    '      <div ng-show="cons.imgs">\n' +
    '        <h4>Bilder aus der Konsultation</h4>\n' +
    '        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"\n' +
    '             ng-repeat="img in cons.images | filter: {mimetype: \'image/jpeg\'} |  orderBy: \'_id\'">\n' +
    '\n' +
    '          <img class="img-responsive" src="/routes/files/filereader/{{img.fileid}}"\n' +
    '               ng-click="consCtrl.showImage(img)"\n' +
    '               uib-tooltip="{{img.label}}">\n' +
    '        </div>\n' +
    '        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"\n' +
    '             ng-repeat="video in cons.images | filter: {mimetype: \'video/quicktime\'} |  orderBy: \'_id\'">\n' +
    '          <video controls class="img-responsive" ng-src="{{video.fileid | trustUrl}}"></video>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </uib-accordion-group>\n' +
    '</uib-accordion>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('consult/checkout.tpl.html',
    '<div class="docs">\n' +
    '  <div class="wizard">\n' +
    '    <div class="steps-container">\n' +
    '      <ul class="steps">\n' +
    '        <li ui-sref="patientrecord.current.consult" ui-sref-active="active">Konsultation<span\n' +
    '            class="chevron"></span></li>\n' +
    '        <!-- <li ui-sref="patientrecord.current.checkout.findings" ui-sref-active="active">Befund<span\n' +
    '             class="chevron"></span></li>\n' +
    '         <li ui-sref="patientrecord.current.checkout.aides" ui-sref-active="active">Verordnung<span\n' +
    '             class="chevron"></span></li>\n' +
    '         <li ui-sref="patientrecord.current.checkout.referrals" ui-sref-active="active">Überweisung<span\n' +
    '             class="chevron"></span></li>-->\n' +
    '        <li ui-sref="patientrecord.billing" ui-sref-active="active">Rechnungspositionen<span\n' +
    '            class="chevron"></span></li>\n' +
    '        <!-- <li ui-sref="patientrecord.current.checkout.drugs" ui-sref-active="active">Rezept<span class="chevron"></span>\n' +
    '         </li>-->\n' +
    '        <li ui-sref="patientrecord.ccdocs" ui-sref-active="active">\n' +
    '          <i class="fa fa-print" uib-tooltip="Drucken"></i> Dokumentenübersicht\n' +
    '          <span class="chevron"></span></li>\n' +
    '        <li class="notallowed" ui-sref-active="active">\n' +
    '          <i class="fa fa-eur" uib-tooltip="Bezahlen"></i> Bezahlen<span class="chevron"></span></li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div ui-view="checkout"></div>\n' +
    '</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('consult/checkout-referrals-directive.tpl.html',
    '<h3>Überweisungen</h3>\n' +
    '\n' +
    '<form class="form-inline">\n' +
    '  <h4>Überweisungsart</h4>\n' +
    '  <div class="form-group">\n' +
    '    <div class="radio" ng-init="refCtrl.newreferral.reftype = \'std\'" >\n' +
    '      <label for="stdref" class="radio-inline"><input type="radio" ng-value="std" tabindex="1"\n' +
    '                                                      ng-model="refCtrl.newreferral.reftype"\n' +
    '                                                      id="stdref"> Standard</label>\n' +
    '      <label for="labref" class="radio-inline"><input type="radio" value="lab" tabindex="2"\n' +
    '                                                      ng-model="refCtrl.newreferral.reftype"\n' +
    '                                                      ng-click="refCtrl.setlab()"\n' +
    '                                                      id="labref"> Labor</label>\n' +
    '      <!--      <label for="imgref" class="radio-inline"><input type="radio" value="img"\n' +
    '                                                           ng-model="refCtrl.newreferral.reftype"\n' +
    '                                                           ng-click="refCtrl.setimg()"\n' +
    '                                                           id="imgref"> Bildgebende Diagnostik</label>-->\n' +
    '\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</form>\n' +
    '\n' +
    '<!-- Überweisung an: -->\n' +
    '<form class="form-vertical" id="refform" name="refform">\n' +
    '  <div class="form-group">\n' +
    '    <label for="refto">\n' +
    '      <span uib-tooltip="Es muß entweder ein Arzt aus der Liste ausgewählt werden oder das Feld Freitext ausgefüllt werden">\n' +
    '        An/Zu: Arztauswahl ODER freie Texteingabe\n' +
    '      </span>\n' +
    '      <span uib-tooltip="Pflichtfeld">*</span>\n' +
    '    </label>\n' +
    '    <!-- Arztsuche -->\n' +
    '    <!--<doctor-search doctorresult="refCtrl.newreferral.doctor" ti="3"></doctor-search>-->\n' +
    '  \n' +
    '  \n' +
    '    <div class="form-group" ng-hide="refCtrl.newreferral.doctor._id">\n' +
    '      <doctor-search doctorresult="refCtrl.newreferral.doctor" ti="3"></doctor-search>\n' +
    '    </div>\n' +
    '    <div class="form-group" ng-show="refCtrl.newreferral.doctor._id">\n' +
    '      <div class="input-group">\n' +
    '        <input type="text" class="form-control"\n' +
    '               id="refdoctorname"\n' +
    '               readonly\n' +
    '               ng-model="refCtrl.newreferral.doctor.fname">\n' +
    '        <span class="input-group-btn">\n' +
    '                    <button class="btn btn-danger"\n' +
    '                            ng-click="refCtrl.clearRefDoctor()"><i class="fa fa-remove"></i>\n' +
    '                    </button>\n' +
    '        </span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    \n' +
    '    <!-- Freitext -->\n' +
    '    <input type="text" ng-model="refCtrl.newreferral.to" tabindex="4"\n' +
    '           ng-required="!(refCtrl.newreferral.to || refCtrl.newreferral.doctor)"\n' +
    '           ng-hide="refCtrl.newreferral.doctor"\n' +
    '           placeholder="Freitext für Überweisungsziel" class="form-control" id="refto">\n' +
    '  </div>\n' +
    '  <!-- diagnose/Begründung -->\n' +
    '  <div class="form-group">\n' +
    '    <label for="refdiag">Diagnose/Begründung<span uib-tooltip="Pflichtfeld">*</span></label>\n' +
    '    <input required type="text" ng-model="refCtrl.newreferral.diag"\n' +
    '           class="form-control" id="refdiag" tabindex="5">\n' +
    '  </div>\n' +
    '\n' +
    '  <!-- Selected Labvalue Output -->\n' +
    '  <div ng-show="refCtrl.newreferral.reftype == \'lab\'">\n' +
    '    <labreferral allvalues="refCtrl.newreferral.labvalues"\n' +
    '                 additionalvalues="refCtrl.newreferral.additionalvalues"\n' +
    '                 ginclude="refCtrl.newreferral.ginclude"\n' +
    '                 ti="6">\n' +
    '    </labreferral>\n' +
    '  </div>\n' +
    '  \n' +
    '  <!-- Erbeten -->\n' +
    '  <div class="form-group" ng-hide="refCtrl.newreferral.reftype == \'lab\'">\n' +
    '    <label for="refref">Erbeten<span uib-tooltip="Pflichtfeld">*</span></label>\n' +
    '    <textarea class="form-control" ng-model="refCtrl.newreferral.ref" rows="5"\n' +
    '              ng-required="!(refCtrl.newreferral.ref || refCtrl.newreferral.labvalues)"\n' +
    '              id="refref" tabindex="4"></textarea>\n' +
    '  </div>\n' +
    '\n' +
    '  <!-- gültig -->\n' +
    '  <div class="form-group">\n' +
    '    <label>Gültig </label>\n' +
    '    <div class="radio">\n' +
    '      <label class="radio-inline">\n' +
    '        <input type="radio" ng-value="true" ng-model="refCtrl.newreferral.pdate"\n' +
    '               ng-click="refCtrl.newreferral.refdate = moment().locale(\'de-at\').format(\'ll\')">\n' +
    '        1 Monat</label>\n' +
    '      <label class="radio-inline">\n' +
    '        <input type="radio" ng-value="false" ng-model="refCtrl.newreferral.pdate"\n' +
    '               ng-click="refCtrl.newreferral.refdate = \'\'">\n' +
    '        Kein Datum</label>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '<!-- Buttons -->\n' +
    '  <button class="btn btn-success" ng-disabled="refform.$invalid" type="button" ng-click="refCtrl.addAnotherReferral();" tabindex="50">\n' +
    '    Speichern\n' +
    '  </button>\n' +
    '\n' +
    '  <button class="btn btn-danger" type="button" ng-click="refCtrl.dialog.close()" ng-show="refCtrl.dialog" tabindex="51">\n' +
    '    Schließen\n' +
    '  </button>\n' +
    '</form>\n' +
    '\n' +
    '<!-- Bestehende Überweisungen -->\n' +
    '<div ng-repeat="ref in refCtrl.patient.openConsult.referrals">\n' +
    '  <div class="row">\n' +
    '    <div class="col-sm-11"><h4>Überweisung</h4></div>\n' +
    '    <div class="col-sm-1">\n' +
    '      <button class="btn btn-danger" ng-click="refCtrl.removeRef($index)" uib-tooltip="Überweisung löschen">\n' +
    '        <i class="fa fa-remove"></i>\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <dl class="dl-horizontal">\n' +
    '        <dt>An/zu</dt>\n' +
    '        <dd>{{ref.to}}{{ref.doctor.fname}}</dd>\n' +
    '        <dt>Diagnose</dt>\n' +
    '        <dd ng-bind="ref.diag"></dd>\n' +
    '        <dt ng-hide="ref.labvalues.length > 0">Erbeten</dt>\n' +
    '        <dd ng-hide="ref.labvalues.length > 0" ng-model="ref.ref">{{ref.ref}}</dd>\n' +
    '        <dt ng-show="ref.labvalues.length > 0">Laborwerte</dt>\n' +
    '        <dd ng-show="ref.labvalues.length > 0"><span\n' +
    '            ng-repeat="val in ref.labvalues">{{val}}{{$last ? \'\' : \', \'}}</span></dd>\n' +
    '        <dt ng-show="ref.biolabels.length > 0">Biopsien</dt>\n' +
    '        <dd ng-show="ref.biolabels.length > 0">\n' +
    '          <div ng-repeat="label in ref.biolabels">{{label}}</div>\n' +
    '          </dd>\n' +
    '        <dt>Datum</dt>\n' +
    '        <dd ng-bind="ref.refdate"></dd>\n' +
    '      </dl>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<!-- PDF Vorschau -->\n' +
    '<a class="btn btn-info" target="_blank" ng-show="refCtrl.patient.openConsult.referrals.length"\n' +
    '   href="/routes/pdfprinter/consultpreview?patientid={{refCtrl.patient._id}}&consultid={{refCtrl.patient.openConsult._id}}&doctype=referral">\n' +
    '  <i class="fa fa-eye"></i> PDF Vorschau\n' +
    '</a>\n' +
    '<div style="min-height:3cm">&nbsp;</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('consult/checkout-payment-directive.tpl.html',
    '<!-- This is a very important hack: DO NOT REMOVE this totally pointless invisible bindings -->\n' +
    '<!-- Removal of these bindings will cause exam and consult close detection to fail -->\n' +
    '<span style="visibility: hidden">Consult Status patient: <span ng-bind=\'payCtrl.patient.inconsult\'></span> </span>\n' +
    '<span style="visibility: hidden">Consult Status openConsult: <span\n' +
    '    ng-bind=\'payCtrl.patient.openConsult.open\'></span> </span>\n' +
    '<span style="visibility: hidden">Exam Status patient: <span ng-bind=\'payCtrl.patient.inexam\'></span> </span>\n' +
    '<span style="visibility: hidden">Exam Status openExam: <span ng-bind=\'payCtrl.patient.openExam.open\'></span> </span>\n' +
    '<span style="visibility: hidden">openExam type: <span\n' +
    '    ng-bind=\'payCtrl.patient.openExam.template.displayname\'></span> </span>\n' +
    '<span style="visibility: hidden">Consultstate: <span ng-bind=\'payCtrl.patient.consultstate\'></span> </span>\n' +
    '<span style="visibility: hidden">Checkoutstate: <span ng-bind=\'payCtrl.patient.checkoutstate\'></span> </span>\n' +
    '\n' +
    '\n' +
    '<!-- not in consult -->\n' +
    '<div ng-hide="payCtrl.patient.inconsult === true && payCtrl.patient.openConsult.open === true">\n' +
    '  <div class="alert alert-info">\n' +
    '    <h4>Die Konsultation wurde von anderer Stelle beendet</h4>\n' +
    '    <span class="btn btn-primary"\n' +
    '          ng-click="payCtrl.$state.go(\'patientrecord.history\')">Zur Patienten-Historie wechseln</span>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '<!-- In consult, not already in checkout -->\n' +
    '<div ng-show="payCtrl.patient.inconsult === true && payCtrl.patient.openConsult.open === true">\n' +
    '  \n' +
    '  <!--\n' +
    '  // TODO: replace with more intelligent version using patient todos\n' +
    '  <div ng-show="payCtrl.patient.openConsult.nextappointment != null">\n' +
    '    <h3>Termin vereinbaren</h3>\n' +
    '  \n' +
    '    ab {{payCtrl.patient.openConsult.nextappointment | addweeks: \'ll\' }} (in\n' +
    '    {{payCtrl.patient.openConsult.nextappointment}} Wochen)\n' +
    '  </div>-->\n' +
    '  \n' +
    '  <h3>Bezahlen</h3>\n' +
    '  <!-- if no serive items are found -->\n' +
    '  <!--<div ng-hide="payCtrl.patient.openConsult.service">-->\n' +
    '  <div ng-hide="payCtrl.nonzeroitems() > 0">\n' +
    '    <div class="alert alert-info">\n' +
    '      <h3>Keine Rechnungspositionen verfügbar</h3>\n' +
    '    </div>\n' +
    '    <div class="btn btn-success" ng-click="payCtrl.backToBilling()">Zurück zur Rechnungslegung</div>\n' +
    '    <div class="btn btn-danger" ng-click="payCtrl.reallyFinish()">Konsultation ohne Rechnung abschliessen</div>\n' +
    '  </div>\n' +
    '  \n' +
    '  \n' +
    '  <!-- Rechnungsvorschau service items -->\n' +
    '  <!--<div ng-show="payCtrl.patient.openConsult.service">-->\n' +
    '  <div ng-show="payCtrl.nonzeroitems() > 0">\n' +
    '    <h3>Rechnungsvorschau</h3>\n' +
    '    <table width="100%">\n' +
    '      <tr>\n' +
    '        <th>Pos.</th>\n' +
    '        <th>Bezeichnung</th>\n' +
    '        <th align="right">Preis</th>\n' +
    '        <th></th>\n' +
    '        <th align="right">St.Satz</th>\n' +
    '      </tr>\n' +
    '      <!-- items -->\n' +
    '      <tr ng-repeat="item in payCtrl.patient.openConsult.service.items | filter: {selected: true}">\n' +
    '        <td>{{$index + 1}}</td>\n' +
    '        <td ng-bind="item.name"></td>\n' +
    '        <td ng-bind="item.price | currency: \'\': 2" align="right"></td>\n' +
    '        <td>&nbsp;€</td>\n' +
    '        <td ng-bind="(item.tax | currency: \'\' : 0) + \'%\'" align="right"></td>\n' +
    '      </tr>\n' +
    '      <!-- Rabatt, discount -->\n' +
    '      <tr style="border-top: 1pt solid black">\n' +
    '        <td></td>\n' +
    '        <td>Rabatt</td>\n' +
    '        <td align="right" ng-show="payCtrl.patient.openConsult.service.discount"> -&nbsp;{{payCtrl.patient.openConsult.service.discount\n' +
    '          | currency: \'\': 2}}\n' +
    '        </td>\n' +
    '        <td align="right" ng-hide="payCtrl.patient.openConsult.service.discount"> - 0,00</td>\n' +
    '        <td>&nbsp;€</td>\n' +
    '        <td align="right"> 0%</td>\n' +
    '      </tr>\n' +
    '      <!-- Steuer, tax -->\n' +
    '      <tr>\n' +
    '        <td></td>\n' +
    '        <td>Ust. 20% gesamt</td>\n' +
    '        <td ng-bind="payCtrl.patient.openConsult.service.tax | currency: \'\': 2" align="right"></td>\n' +
    '        <td>&nbsp;€</td>\n' +
    '        <td></td>\n' +
    '      </tr>\n' +
    '      <!-- Summe, total -->\n' +
    '      <tr style="border-top: 1pt solid black; border-bottom: 1pt double black;">\n' +
    '        <td></td>\n' +
    '        <td>Gesamt</td>\n' +
    '        <td ng-bind="payCtrl.patient.openConsult.service.total | currency: \'\': 2" align="right"></td>\n' +
    '        <td>&nbsp;€</td>\n' +
    '        <td></td>\n' +
    '      </tr>\n' +
    '    </table>\n' +
    '    \n' +
    '    \n' +
    '    <!-- invoice has already been created -->\n' +
    '    <div ng-show="payCtrl.patient.openConsult.invoicecreated === true && payCtrl.invoicecreated !== true"\n' +
    '         class="alert alert-info spacer-top" role="alert">\n' +
    '      <h3>Es wurde bereits eine Rechnung erstellt!</h3>\n' +
    '      <button class="btn btn-primary" ng-click="payCtrl.retrieveInvoice()">PDFs anzeigen</button>\n' +
    '      &nbsp;\n' +
    '      <button class="btn btn-primary" ng-click="payCtrl.retrieveAndFinish()">Konsultation abschließen</button>\n' +
    '    </div>\n' +
    '    \n' +
    '    \n' +
    '    <!-- Zahlungsmittel auswahl -->\n' +
    '    <!-- only show if no invoice has been generated yet -->\n' +
    '    <div ng-hide="payCtrl.patient.openConsult.invoicecreated === true" class="spacer-top">\n' +
    '      <!-- service items total alertbox -->\n' +
    '      <div class="alert alert-info spacer-top" role="alert">\n' +
    '        <h3>Gesamt (Brutto): {{payCtrl.patient.openConsult.service.total | currency : \'€ \' : 2}} </h3>\n' +
    '      </div>\n' +
    '      <!-- Choose payment typecash, card or invoice alertbox -->\n' +
    '      <form class="form-vertical spacer-top" name="paymenttype" id="paymenttype">\n' +
    '        <div class="form-group col-md-12 spacer-top alert"\n' +
    '             ng-class="{ \'alert-danger\': paymenttype.$invalid, \'alert-success\': paymenttype.$valid }">\n' +
    '          <div class="radio">\n' +
    '            <label for="pay1" class="radio-inline"><input type="radio" value="cash"\n' +
    '                                                          ng-required="!payCtrl.patient.openConsult.service.paytype"\n' +
    '                                                          ng-model="payCtrl.patient.openConsult.service.paytype"\n' +
    '                                                          id="pay1">Bar</label>\n' +
    '            <label for="pay2" class="radio-inline"><input type="radio" value="card"\n' +
    '                                                          ng-required="!payCtrl.patient.openConsult.service.paytype"\n' +
    '                                                          ng-model="payCtrl.patient.openConsult.service.paytype"\n' +
    '                                                          id="pay2">Bankomat o. Kreditkarte</label>\n' +
    '            <label for="pay3" class="radio-inline"><input type="radio" value="bill"\n' +
    '                                                          ng-model="payCtrl.patient.openConsult.service.paytype"\n' +
    '                                                          ng-required="!payCtrl.patient.openConsult.service.paytype"\n' +
    '                                                          id="pay3">Rechnung mit Zahlschein</label>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        \n' +
    '        <!-- Wechselgeld -->\n' +
    '        <div class="row spacer-top">\n' +
    '          <div class="form-group col-md-6">\n' +
    '            <label for="paycash">Bar erhalten</label>\n' +
    '            <input type="number" step="0.1" ng-model="payCtrl.paycash"\n' +
    '                   ng-change="payCtrl.payreturn = payCtrl.paycash - payCtrl.patient.openConsult.service.total"\n' +
    '                   class="form-control" id="paycash">\n' +
    '          </div>\n' +
    '          <div class="form-group  col-md-6">\n' +
    '            <label for="payreturn">Wechselgeld</label>\n' +
    '            <input type="number" step="0.1" ng-model="payCtrl.payreturn"\n' +
    '                   ng-value="" class="form-control" id="payreturn">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        \n' +
    '        <!-- Create invoices and receipt (where necessery) -->\n' +
    '        <div class="form-group col-md-12 spacer-top">\n' +
    '          <button type="submit"\n' +
    '                  data-ng-disabled="paymenttype.$invalid"\n' +
    '                  ng-click="payCtrl.saveInvoice()"\n' +
    '                  class="btn btn-success">Rechnung &amp; Beleg ausstellen\n' +
    '          </button>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '    \n' +
    '    \n' +
    '    <!-- user check for correct invoice generation -->\n' +
    '    <div ng-show="payCtrl.patient.openConsult.invoicecreated === true && payCtrl.invoicecreated === true">\n' +
    '      <form class="form-vertical">\n' +
    '        \n' +
    '        <!-- questions depending on invoice type -->\n' +
    '        <h3 ng-show="payCtrl.patient.openConsult.service.paytype === \'bill\'">\n' +
    '          Rechnung erfolgreich ausgedruckt?\n' +
    '        </h3>\n' +
    '        <h3 ng-show="payCtrl.patient.openConsult.service.paytype === \'cash\' ||\n' +
    '                   payCtrl.patient.openConsult.service.paytype === \'card\'">\n' +
    '          Rechnung und Beleg inhaltlich richtig und erfolgreich ausgedruckt?\n' +
    '        </h3>\n' +
    '        \n' +
    '        <!-- buttons -->\n' +
    '        <div class="spacer-top">\n' +
    '          <div class="form-group col-md-4">\n' +
    '            <div class="btn btn-success" ng-click="payCtrl.finishConsult()">\n' +
    '              Ja\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div class="form-group col-md-4">\n' +
    '            <div class="btn btn-info" ng-click="payCtrl.retryPrinting()" uib-tooltip="PDFs werden angezeigt">\n' +
    '              PDF Dateien anzeigen\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <!-- ask before canceling invoice on grounds of error -->\n' +
    '          <div class="form-group col-md-4">\n' +
    '            <div class="btn btn-danger"\n' +
    '                 ng-really-message="Rechnung stornieren und neue Rechnung ausstellen?"\n' +
    '                 ng-really-click="payCtrl.redoBilling()">\n' +
    '              Nein, inhaltlich falsch\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '    \n' +
    '    <!-- invoice documents, show on user command, e.g. when print failed -->\n' +
    '    <!--<div ng-show="payCtrl.showpdfs === true || payCtrl.patient.openConsult.invoicecreated === true">-->\n' +
    '    <div ng-show="payCtrl.showpdfs === true">\n' +
    '      <h3>Rechnungen und Belege</h3>\n' +
    '      <!-- Patientenrechnung -->\n' +
    '      <div class="row">\n' +
    '        <div class="col-md-1">\n' +
    '          <i class="fa fa-file-pdf-o" uib-tooltip="PDF"></i>\n' +
    '        </div>\n' +
    '        <div class="col-md-9">\n' +
    '          <h4>Patientenrechnung</h4>\n' +
    '        </div>\n' +
    '        <div class="col-md-1">\n' +
    '          <a href="/routes/pdfreader/{{payCtrl.createdinvoice.patientinvoiceid}}" target="_blank"\n' +
    '             class="btn btn-primary"\n' +
    '             role="button">\n' +
    '            <i class="fa fa-eye" uib-tooltip="Anzeigen"></i>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '        <div class="col-md-1">\n' +
    '          <a href="/routes/pdfdownload/{{payCtrl.createdinvoice.patientinvoiceid}}" class="btn btn-primary"\n' +
    '             role="button">\n' +
    '            <i class="fa fa-download" uib-tooltip="Download"></i>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <!-- Artzrechnung -->\n' +
    '      <div class="row">\n' +
    '        <div class="col-md-1">\n' +
    '          <i class="fa fa-file-pdf-o" uib-tooltip="PDF"></i>\n' +
    '        </div>\n' +
    '        <div class="col-md-9">\n' +
    '          <h4>Arztrechnung</h4>\n' +
    '        </div>\n' +
    '        <div class="col-md-1">\n' +
    '          <a href="/routes/pdfreader/{{payCtrl.createdinvoice.doctorinvoiceid}}" target="_blank" class="btn btn-primary"\n' +
    '             role="button">\n' +
    '            <i class="fa fa-eye" uib-tooltip="Anzeigen"></i>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '        <div class="col-md-1">\n' +
    '          <a href="/routes/pdfdownload/{{payCtrl.createdinvoice.doctorinvoiceid}}" class="btn btn-primary"\n' +
    '             role="button">\n' +
    '            <i class="fa fa-download" uib-tooltip="Download"></i>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <!-- Beleg -->\n' +
    '      <div class="row">\n' +
    '        <div class="col-md-1">\n' +
    '          <i class="fa fa-file-pdf-o" uib-tooltip="PDF"></i>\n' +
    '        </div>\n' +
    '        <div class="col-md-9">\n' +
    '          <h4>Beleg</h4>\n' +
    '        </div>\n' +
    '        <div class="col-md-1">\n' +
    '          <a href="/routes/pdfreader/{{payCtrl.createdinvoice.receiptid}}" target="_blank" class="btn btn-primary"\n' +
    '             role="button">\n' +
    '            <i class="fa fa-eye" uib-tooltip="Anzeigen"></i>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '        <div class="col-md-1">\n' +
    '          <a href="/routes/pdfdownload/{{payCtrl.createdinvoice.receiptid}}" class="btn btn-primary" role="button">\n' +
    '            <i class="fa fa-download" uib-tooltip="Download"></i>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  \n' +
    '  <div style="min-height:3cm">&nbsp;</div>\n' +
    '\n' +
    '</div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('consult/checkout-drugs-directive.tpl.html',
    '<h3>Rezepte austellen</h3>\n' +
    '\n' +
    '<!-- Medikamente buttons -->\n' +
    '<form class="form-horizontal">\n' +
    '  <div class="form-group" ng-repeat="drug in patdCtrl.patient.drugs">\n' +
    '    <label ng-show="drug.active && !(drug.foreign)">\n' +
    '      <input type="checkbox"\n' +
    '             ng-model="drug.print"/>\n' +
    '      {{drug.name}} {{drug.amount}}, {{drug.op}}\n' +
    '      <button uib-popover-template="patdCtrl.drugedittpl" type="button" class="btn btn-primary btn-sm">Details</button>\n' +
    '    </label>\n' +
    '  </div>\n' +
    '\n' +
    '  <!-- Gültigkeitsdatum -->\n' +
    '  <div class="form-group">\n' +
    '    <label>Gültig </label>\n' +
    '    <div ng-init="patdCtrl.pdate=true">\n' +
    '      <input type="radio" ng-value="true" ng-model="patdCtrl.pdate"><label class="radio-inline">1&nbsp;Monat</label>\n' +
    '      <input type="radio" value="false" ng-model="patdCtrl.pdate"><label class="radio-inline">Kein Datum</label>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <!-- Rezept austellen -->\n' +
    '  <div class="form-group">\n' +
    '    <button class="btn btn-success"\n' +
    '            ng-click="patdCtrl.drugcheckout(patdCtrl.patient, patdCtrl.patient.openConsult._id);">Rezept(e) drucken\n' +
    '      &amp; Weiter\n' +
    '    </button>\n' +
    '    <button class="btn btn-danger" ng-click="patdCtrl.switchToDocuments(patdCtrl.patient)">Weiter ohne Rezept(e)\n' +
    '    </button>\n' +
    '  </div>\n' +
    '</form>\n' +
    '\n' +
    '<!-- drug info in popup -->\n' +
    '<!--\n' +
    '<script type="text/ng-template" id="drugPopoverTemplate.html">\n' +
    '  <div>\n' +
    '    <div class="input-group">\n' +
    '      <label for="drugamount">Einnahme</label>\n' +
    '      <input class="form-control" ng-model="drug.amount" id="drugamount" placeholder="1-0-1-0">\n' +
    '    </div>\n' +
    '    <div class="input-group">\n' +
    '      <strong>Spezifika</strong>\n' +
    '\n' +
    '      <div class="checkbox">\n' +
    '        <label for="drugind" class="checkbox-inline"><input type="checkbox" ng-model="drug.ind" id="drugind">IND\n' +
    '          (Indikation)</label><br>\n' +
    '      </div>\n' +
    '      <div class="radio">\n' +
    '        <label for="drugop1" class="radio-inline"><input type="radio" value="OP I" ng-model="drug.op" id="drugop1">OP I</label>\n' +
    '        <label for="drugop2" class="radio-inline"><input type="radio" value="OP II" ng-model="drug.op" id="drugop2">OP\n' +
    '          II</label>\n' +
    '        <label for="drugop3" class="radio-inline"><input type="radio" value="OP III" ng-model="drug.op" id="drugop3">OP\n' +
    '          III</label>\n' +
    '      </div>\n' +
    '      <label for="drugpkgsize">Packungsgröße</label>\n' +
    '      <input class="form-control" ng-model="drug.pkgsize" id="drugpkgsize" placeholder="30 Stk">\n' +
    '    </div>\n' +
    '    <div class="input-group">\n' +
    '      <div class="checkbox">\n' +
    '        <label for="drugperm" class="checkbox-inline"><input type="checkbox" ng-model="drug.permanent" id="drugperm">Dauermedikamentation</label><br>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="form-group">\n' +
    '      <label for="drugcomment">Rezeptvermerk</label>\n' +
    '      <textarea class="form-control" ng-model="drug.comment" id="drugcomment" placeholder=""></textarea>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</script>\n' +
    '\n' +
    '-->\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('consult/checkout-documents-directive.tpl.html',
    '<!-- This is a very important hack: DO NOT REMOVE this totally pointless invisible bindings -->\n' +
    '<!-- Removal of these bindings will cause exam and consult close detection to fail -->\n' +
    '<span style="visibility: hidden">Consult Status patient: <span ng-bind=\'docCtrl.patient.inconsult\'></span> </span>\n' +
    '<span style="visibility: hidden">Consult Status openConsult: <span\n' +
    '    ng-bind=\'docCtrl.patient.openConsult.open\'></span> </span>\n' +
    '<span style="visibility: hidden">Exam Status patient: <span ng-bind=\'docCtrl.patient.inexam\'></span> </span>\n' +
    '<span style="visibility: hidden">Exam Status openExam: <span ng-bind=\'docCtrl.patient.openExam.open\'></span> </span>\n' +
    '<span style="visibility: hidden">openExam type: <span\n' +
    '    ng-bind=\'docCtrl.patient.openExam.template.displayname\'></span> </span>\n' +
    '<span style="visibility: hidden">Consultstate: <span ng-bind=\'docCtrl.patient.consultstate\'></span> </span>\n' +
    '<span style="visibility: hidden">Checkoutstate: <span ng-bind=\'docCtrl.patient.checkoutstate\'></span> </span>\n' +
    '\n' +
    '\n' +
    '<!-- not in consult -->\n' +
    '<div ng-hide="docCtrl.patient.inconsult == true && docCtrl.patient.openConsult.open === true">\n' +
    '  <div class="alert alert-info">\n' +
    '    <h4>Die Konsultation wurde von anderer Stelle beendet</h4>\n' +
    '    <span class="btn btn-primary"\n' +
    '          ng-click="docCtrl.$state.go(\'patientrecord.history\')">Zur Patienten-Historie wechseln</span>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '<!-- In consult, not already in checkout -->\n' +
    '<div ng-show="docCtrl.patient.inconsult == true && docCtrl.patient.openConsult.open === true">\n' +
    '\n' +
    '\n' +
    '  <!--\n' +
    '  <h3>Termine</h3>\n' +
    '  <form>\n' +
    '    <div class="form-group">\n' +
    '      <div class="radio">\n' +
    '        <label for="nap1" class="radio-inline">\n' +
    '          <input type="radio" value="1"\n' +
    '                 ng-model="docCtrl.patient.openConsult.nextappointment" id="nap1">1 Woche</label>\n' +
    '        <label for="nap2" class="radio-inline">\n' +
    '          <input type="radio" value="2"\n' +
    '                 ng-model="docCtrl.patient.openConsult.nextappointment" id="nap2">2 Wochen</label>\n' +
    '        <label for="nap3" class="radio-inline">\n' +
    '          <input type="radio" value="4"\n' +
    '                 ng-model="docCtrl.patient.openConsult.nextappointment" id="nap3">1 Monat</label>\n' +
    '        <label for="nap3" class="radio-inline">\n' +
    '          <input type="radio" value="8"\n' +
    '                 ng-model="docCtrl.patient.openConsult.nextappointment" id="nap4">2 Monate</label>\n' +
    '      </div>\n' +
    '      <div class="radio">\n' +
    '        <label for="nap3" class="radio-inline">\n' +
    '          <input type="radio" value="13"\n' +
    '                 ng-model="docCtrl.patient.openConsult.nextappointment" id="nap5">3 Monate</label>\n' +
    '        <label for="nap3" class="radio-inline">\n' +
    '          <input type="radio" value="26"\n' +
    '                 ng-model="docCtrl.patient.openConsult.nextappointment" id="nap6">6 Monate</label>\n' +
    '        <label for="nap3" class="radio-inline">\n' +
    '          <input type="radio" value="52"\n' +
    '                 ng-model="docCtrl.patient.openConsult.nextappointment" id="nap7">1 Jahr</label>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </form>\n' +
    '  -->\n' +
    '\n' +
    '  <h3>Befundempänger</h3>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <div class="form-group form-vertical">\n' +
    '        <input type="radio" ng-model="docCtrl.recipient" value="patient" required="required"\n' +
    '               ng-change="docCtrl.setRecipient()">&nbsp;Patient\n' +
    '        <div data-ng-show="docCtrl.patient.practdoctor.fname">\n' +
    '        <input type="radio" ng-model="docCtrl.recipient" value="practdoctor" ng-required="!docCtrl.recipient"\n' +
    '               ng-change="docCtrl.setRecipient()"\n' +
    '               ng-show="docCtrl.patient.practdoctor">&nbsp;Hausarzt: {{docCtrl.patient.practdoctor.fname}}</div>\n' +
    '        <div ng-show="docCtrl.patient.refdoctor.fname">\n' +
    '        <input type="radio" ng-model="docCtrl.recipient" value="refdoctor" required="required"\n' +
    '               ng-change="docCtrl.setRecipient()"\n' +
    '               ng-show="docCtrl.patient.refdoctor">&nbsp;Überweisender Arzt: {{docCtrl.patient.refdoctor.fname}}\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '\n' +
    '  <h3>Dokumentenübersicht</h3>\n' +
    '\n' +
    '  <!-- Überweisungen -->\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12 col-sm-9">\n' +
    '      <input type="checkbox" ng-init="docCtrl.consultDocs.referral = docCtrl.checkReferrals()"\n' +
    '             ng-model="docCtrl.consultDocs.referral">&nbsp;\n' +
    '\n' +
    '      <!-- <input type="checkbox" ng-init="docCtrl.consultDocs.referral = true"\n' +
    '             ng-model="docCtrl.consultDocs.referral">&nbsp;-->\n' +
    '    <span\n' +
    '        ng-show="docCtrl.patient.openConsult.referrals.length > 0">{{docCtrl.patient.openConsult.referrals.length}}</span>\n' +
    '      <span ng-hide="docCtrl.patient.openConsult.referrals.length > 0">Keine</span>\n' +
    '      &nbsp;Überweisung(en)\n' +
    '    </div>\n' +
    '    <div class="col-xs-12 col-sm-3" style="text-align: right">\n' +
    '      <a ng-show="docCtrl.patient.openConsult.referrals.length > 0" class="btn btn-info" uib-tooltip="PDF Vorschau"\n' +
    '         target="_blank"\n' +
    '         href="/routes/pdfprinter/consultpreview?patientid={{docCtrl.patient._id}}&consultid={{docCtrl.patient.openConsult._id}}&doctype=referral">\n' +
    '        <i class="fa fa-eye"></i>\n' +
    '      </a>\n' +
    '      <button type="button" ng-click="docCtrl.addReferralModal(docCtrl.patient)"\n' +
    '              class="btn btn-primary" uib-tooltip="Überweisungen editieren/hinzufügen">\n' +
    '        <i class="fa fa-edit"></i>\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '\n' +
    '  <!-- Hilfsmittelverordnungen -->\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12 col-sm-9">\n' +
    '      <input type="checkbox" ng-init="docCtrl.consultDocs.aide = docCtrl.checkAides()"\n' +
    '             ng-model="docCtrl.consultDocs.aide">&nbsp;\n' +
    '      <span ng-show="docCtrl.patient.openConsult.aides.length > 0">{{docCtrl.patient.openConsult.aides.length}} </span>\n' +
    '      <span ng-hide="docCtrl.patient.openConsult.aides.length > 0">Keine </span>\n' +
    '      Hilfsmittelverordnungen(en)\n' +
    '    </div>\n' +
    '    <div class="col-xs-12 col-sm-3" style="text-align: right">\n' +
    '      <a ng-show="docCtrl.patient.openConsult.aides.length > 0" class="btn btn-info" uib-tooltip="PDF Vorschau"\n' +
    '         target="_blank"\n' +
    '         href="/routes/pdfprinter/consultpreview?patientid={{docCtrl.patient._id}}&consultid={{docCtrl.patient.openConsult._id}}&doctype=aide">\n' +
    '        <i class="fa fa-eye"></i>\n' +
    '      </a>\n' +
    '      <button type="button" ng-click="docCtrl.addAideModal(docCtrl.patient)"\n' +
    '              class="btn btn-primary" uib-tooltip="Überweisungen editieren/hinzufügen">\n' +
    '        <i class="fa fa-edit"></i>\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <!-- Rezepte -->\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12 col-sm-9">\n' +
    '      <input type="checkbox" ng-init="docCtrl.checkPrescription()"\n' +
    '             ng-model="docCtrl.consultDocs.prescription">&nbsp;{{docCtrl.patient.activedrugs}}\n' +
    '      Medikamenteverordnung(en)/Rezepte(en)\n' +
    '    </div>\n' +
    '    <div class="col-xs-12 col-sm-3" style="text-align: right">\n' +
    '      <a ng-show="docCtrl.patient.activedrugs > 0" class="btn btn-info" uib-tooltip="PDF Vorschau" target="_blank"\n' +
    '         href="/routes/pdfprinter/consultpreview?patientid={{docCtrl.patient._id}}&consultid={{docCtrl.patient.openConsult._id}}&doctype=prescription">\n' +
    '        <i class="fa fa-eye"></i>\n' +
    '      </a>\n' +
    '      <button type="button" ng-click="docCtrl.editPrescription(docCtrl.patient)"\n' +
    '              class="btn btn-primary" uib-tooltip="Medikamente editieren/hinzufügen">\n' +
    '        <i class="fa fa-edit"></i>\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <!-- Gesamtbefund -->\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12 col-sm-9">\n' +
    '      <input type="checkbox" ng-init="docCtrl.consultDocs.consultfinding = true"\n' +
    '             ng-model="docCtrl.consultDocs.consultfinding">&nbsp;Gesamtbefund/Arztbrief\n' +
    '    </div>\n' +
    '    <div class="col-xs-12 col-sm-3" style="text-align: right">\n' +
    '      <a class="btn btn-info" uib-tooltip="PDF Vorschau" target="_blank"\n' +
    '         href="/routes/pdfprinter/consultpreview?patientid={{docCtrl.patient._id}}&consultid={{docCtrl.patient.openConsult._id}}&doctype=consultfinding">\n' +
    '        <i class="fa fa-eye"></i>\n' +
    '      </a>\n' +
    '      <!--<button type="button" ng-click="docCtrl.addReferralModal"\n' +
    '              class="btn btn-primary" uib-tooltip="Überweisungen editieren/hinzufügen">\n' +
    '        <i class="fa fa-edit"></i>\n' +
    '      </button>-->\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <!-- Untersuchungen -->\n' +
    '\n' +
    '  <div class="row" ng-repeat="exam in docCtrl.patient.openConsult.examinations | filter: {active: true}">\n' +
    '    <div class="col-xs-12 col-sm-9">\n' +
    '      <input type="checkbox" ng-init="docCtrl.examDocs[$index] = true"\n' +
    '             ng-model="docCtrl.examDocs[$index]">&nbsp;Untersuchungsbefund {{exam.type}}\n' +
    '    </div>\n' +
    '    <div class="col-xs-12 col-sm-3" style="text-align: right">\n' +
    '      <a class="btn btn-info" uib-tooltip="PDF Vorschau" target="_blank"\n' +
    '         href="/routes/pdfprinter/exampreview?patientid={{docCtrl.patient._id}}&examid={{exam._id}}&doctype=examfinding">\n' +
    '        <i class="fa fa-eye"></i>\n' +
    '      </a>\n' +
    '      <button type="button" ng-click="docCtrl.editExam(exam)"\n' +
    '              class="btn btn-primary" uib-tooltip="Untersuchung editieren">\n' +
    '        <i class="fa fa-edit"></i>\n' +
    '      </button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <!-- Rechnungsvorschau -->\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12 col-sm-9">\n' +
    '      <!--<input type="checkbox" ng-init="docCtrl.consultDocs.invoice = true"\n' +
    '             ng-model="docCtrl.consultDocs.consultfinding">-->Rechnungsvorschau\n' +
    '    </div>\n' +
    '    <div class="col-xs-12 col-sm-3" style="text-align: right">\n' +
    '      <a class="btn btn-info" uib-tooltip="PDF Vorschau" target="_blank"\n' +
    '         href="/routes/pdfprinter/consultpreview?patientid={{docCtrl.patient._id}}&consultid={{docCtrl.patient.openConsult._id}}&doctype=invoicepreview">\n' +
    '        <i class="fa fa-eye"></i>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <!-- Buttons -->\n' +
    '  <button class="btn btn-success" ng-click="docCtrl.printSome()" data-ng-disabled="!docCtrl.recipient">Ausgewählte Dateien Drucken &amp; Konsultation\n' +
    '    abschließen\n' +
    '  </button>\n' +
    '  <button class="btn btn-danger" ng-click="docCtrl.saveAll()"  data-ng-disabled="!docCtrl.recipient"\n' +
    '          uib-tooltip="Dateien können nachträglich aus der Patientenakte -&gt; Dokumente Reiter gedruckt werden">\n' +
    '    Konsultation ohne Drucken abschließen\n' +
    '  </button>\n' +
    '\n' +
    '\n' +
    '</div>\n' +
    '<div style="min-height:3cm">&nbsp;</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('consult/checkout-billing-directive.tpl.html',
    '<!-- This is a very important hack: DO NOT REMOVE this totally pointless invisible bindings -->\n' +
    '<!-- Removal of these bindings will cause exam and consult close detection to fail -->\n' +
    '<span style="visibility: hidden">Consult Status patient: <span ng-bind=\'billCtrl.patient.inconsult\'></span> </span>\n' +
    '<span style="visibility: hidden">Consult Status openConsult: <span ng-bind=\'billCtrl.patient.openConsult.open\'></span> </span>\n' +
    '<span style="visibility: hidden">Exam Status patient: <span ng-bind=\'billCtrl.patient.inexam\'></span> </span>\n' +
    '<span style="visibility: hidden">Exam Status openExam: <span ng-bind=\'billCtrl.patient.openExam.open\'></span> </span>\n' +
    '<span style="visibility: hidden">openExam type: <span ng-bind=\'billCtrl.patient.openExam.template.displayname\'></span> </span>\n' +
    '<span style="visibility: hidden">Consultstate: <span ng-bind=\'billCtrl.patient.consultstate\'></span> </span>\n' +
    '<span style="visibility: hidden">Checkoutstate: <span ng-bind=\'billCtrl.patient.checkoutstate\'></span> </span>\n' +
    '\n' +
    '<!-- not in consult -->\n' +
    '<div ng-hide="billCtrl.patient.inconsult == true && billCtrl.patient.openConsult.open === true">\n' +
    '  <div class="alert alert-info">\n' +
    '    <h4>Die Konsultation wurde von anderer Stelle beendet</h4>\n' +
    '    <span class="btn btn-primary" ng-click="billCtrl.$state.go(\'patientrecord.history\')">Zur Patienten-Historie wechseln</span>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<!-- in consult, already in checkout-->\n' +
    '<!--\n' +
    '<div\n' +
    '    ng-show="billCtrl.patient.inconsult == true && billCtrl.patient.openConsult.open === true && billCtrl.patient.incheckout === true">\n' +
    '  <div class="alert alert-info">\n' +
    '    <h4>Diese Konsultation wird gerade abgeschlossen</h4>\n' +
    '    <span class="btn btn-primary" ng-click="billCtrl.switchToExit()">\n' +
    '      Zum Konsultationsabschluß wechseln\n' +
    '    </span>\n' +
    '  </div>\n' +
    '-->\n' +
    '\n' +
    '\n' +
    '</div>\n' +
    '\n' +
    '<!-- In consult -->\n' +
    '<div\n' +
    '    ng-show="billCtrl.patient.inconsult == true && billCtrl.patient.openConsult.open === true">\n' +
    '  \n' +
    '  \n' +
    '  <h3>Honorarnote</h3>\n' +
    '  \n' +
    '  \n' +
    '  <h4>Diagnosen für Rechnung</h4>\n' +
    '<!--<textarea class="form-control" rows="2" title="invoicediag"\n' +
    '          ng-model="billCtrl.patient.openConsult.service.invoicediagnoses"\n' +
    '          ng-change="billCtrl.patient.openConsult.$save()">{{billCtrl.patient.openConsult.invoicediagnoses}}</textarea>-->\n' +
    ' \n' +
    '  <textarea class="form-control" rows="2" title="invoicediag"\n' +
    '          ng-init="billCtrl.patient.openConsult.invoicediagnoses = billCtrl.joinDiagnoses()"\n' +
    '          ng-model="billCtrl.patient.openConsult.invoicediagnoses"\n' +
    '          ng-blur="billCtrl.patient.openConsult.$save()">{{billCtrl.patient.openConsult.invoicediagnoses}}</textarea>\n' +
    ' \n' +
    '  <h4>Gewählte Positionen</h4>\n' +
    '  <div ng-hide="billCtrl.patient.openConsult.service" class="alert alert-info">\n' +
    '    <h4>Dienstleistungskatalog wird geladen</h4>\n' +
    '  </div>\n' +
    '  \n' +
    '<!--  <div ng-hide="billCtrl.patient.openConsult.service.items.length" class="alert alert-info">\n' +
    '    <h4>Es wurden noch keine Rechnungspositionen ausgewählt</h4>\n' +
    '  </div>-->\n' +
    '  \n' +
    '  <table width="100%" ng-show="billCtrl.patient.openConsult.service.items.length">\n' +
    '    <tr style="border-bottom: #333333 solid 2pt">\n' +
    '      <th width="5%">Pos</th>\n' +
    '      <th width="55%">Name</th>\n' +
    '      <th width="15%" style="text-align: right">Preis EUR</th>\n' +
    '      <th width="15%" style="text-align: right">Steuer %</th>\n' +
    '      <th width="10%" style="text-align: center">Aktion</th>\n' +
    '    </tr>\n' +
    '    <!-- Gewählte Positionen -->\n' +
    '    <tr ng-repeat="s in billCtrl.patient.openConsult.service.items | filter: { selected: true }">\n' +
    '      <td>{{$index + 1}}</td>\n' +
    '      <td><span>{{s.name}}</span> <span ng-show="s.hint">&nbsp;({{s.hint}})</span></td>\n' +
    '      <td style="text-align: right">{{s.price | currency: \'\' : 2}} EUR</td>\n' +
    '      <td style="text-align: right">{{s.tax | currency: \'\' : 0}} %</td>\n' +
    '      <td style="text-align: center">\n' +
    '      <span class="btn btn-danger" uib-tooltip="Service aus Liste entfernen"\n' +
    '            ng-click="s.selected = false; billCtrl.sumUpItems()"><i class="fa fa-remove"></i>\n' +
    '      </span>\n' +
    '      </td>\n' +
    '    </tr>\n' +
    '    <!-- Zwischensumme netto -->\n' +
    '    <tr style="border-top: #333333 solid 2pt;">\n' +
    '      <td></td>\n' +
    '      <td style="text-align: right">Betrag (netto)&nbsp;</td>\n' +
    '      <td style="text-align: right">{{billCtrl.patient.openConsult.service.netto | currency: \'\' : 2}} EUR</td>\n' +
    '      <td style="text-align: right"></td>\n' +
    '      <td style="text-align: center">\n' +
    '      </td>\n' +
    '    </tr>\n' +
    '    <!-- Gesamtsumme brutto -->\n' +
    '    <tr style="border-top: #333333 solid 2pt;border-bottom: #333333 double 2pt;">\n' +
    '      <td></td>\n' +
    '      <td style="text-align: right">Zwischensumme (brutto)&nbsp;</td>\n' +
    '      <td style="text-align: right">{{billCtrl.patient.openConsult.service.totalbefore | currency: \'\' : 2}} EUR</td>\n' +
    '      <td style="text-align: right"></td>\n' +
    '      <td style="text-align: center">\n' +
    '      </td>\n' +
    '    </tr>\n' +
    '    <!-- Rabatt -->\n' +
    '    <tr class="alert alert-warning">\n' +
    '      <td></td>\n' +
    '      <td style="text-align: right">Rabatt (EUR)&nbsp;</td>\n' +
    '      <td style="text-align: right; padding-top:5pt; padding-bottom:5pt;">\n' +
    '        <input type="number"\n' +
    '               class="form-control" ng-change="billCtrl.sumUpItems()"\n' +
    '               ng-model-options="{ updateOn: \'default blur\', debounce: { default: 2000, blur: 0 } }"\n' +
    '               step=\'0.01\' min\n' +
    '               style="text-align: right"\n' +
    '               \n' +
    '               ng-model="billCtrl.patient.openConsult.service.discount"></td>\n' +
    '      <td style="text-align: right"></td>\n' +
    '      <td style="text-align: center">\n' +
    '      </td>\n' +
    '    </tr>\n' +
    '    <!-- Steuer -->\n' +
    '<!--    <tr>\n' +
    '      <td></td>\n' +
    '      <td style="text-align: right">USt. 20%&nbsp;</td>\n' +
    '      <td style="text-align: right">{{billCtrl.patient.openConsult.service.tax | currency: \'\' : 2}} EUR</td>\n' +
    '      <td style="text-align: right"></td>\n' +
    '      <td style="text-align: center">\n' +
    '      </td>\n' +
    '    </tr>-->\n' +
    '    <tr ng-repeat="(tax, val) in billCtrl.patient.openConsult.service.discounttaxsum">\n' +
    '      <td></td>\n' +
    '      <td style="text-align: right">USt. {{tax}}%&nbsp;</td>\n' +
    '      <td style="text-align: right">{{val | currency: \'\' : 2}} EUR</td>\n' +
    '      <td style="text-align: right"></td>\n' +
    '      <td style="text-align: center">\n' +
    '      </td>\n' +
    '    </tr>\n' +
    '    <!-- Gesamtsumme brutto -->\n' +
    '    <tr style="border-top: #333333 solid 2pt;border-bottom: #333333 double 2pt;">\n' +
    '      <td></td>\n' +
    '      <td style="text-align: right">Gesamt (brutto)&nbsp;</td>\n' +
    '      <td style="text-align: right">{{billCtrl.patient.openConsult.service.total | currency: \'\' : 2}} EUR</td>\n' +
    '      <td style="text-align: right"></td>\n' +
    '      <td style="text-align: center">\n' +
    '      </td>\n' +
    '    </tr>\n' +
    '  </table>\n' +
    '  \n' +
    '  <!-- Buttons -->\n' +
    '  <div class="row spacer-top">\n' +
    '    <div class="col-md-12">\n' +
    '      <button type="submit" class="btn btn-success" ng-click="billCtrl.saveInvoiceItems()"\n' +
    '              ng-hide="billCtrl.patient.incheckout === true"\n' +
    '              data-ng-disabled="billCtrl.nonzeroitems() === 0">\n' +
    '        Weiter zur Dokumentenübersicht\n' +
    '      </button>\n' +
    '      <button type="submit" class="btn btn-success" ng-click="billCtrl.saveInvoiceItems()"\n' +
    '              ng-show="billCtrl.patient.incheckout === true"\n' +
    '              data-ng-disabled="billCtrl.nonzeroitems() === 0">\n' +
    '        Weiter zum Bezahlen\n' +
    '      </button>\n' +
    '\n' +
    '      <!--<button type="submit" class="btn btn-success" ng-click="billCtrl.saveInvoiceItems()"\n' +
    '              ng-show="billCtrl.$state.current.name === \'patientrecord.billing\'"\n' +
    '              data-ng-disabled="billCtrl.patient.openConsult.service.items.length === 0">\n' +
    '        Weiter zur Dokumentenübersicht\n' +
    '      </button>\n' +
    '      <button type="submit" class="btn btn-success" ng-click="billCtrl.saveInvoiceItems()"\n' +
    '              ng-show="billCtrl.$state.current.name === \'checkout.redobilling\'"\n' +
    '              data-ng-disabled="billCtrl.patient.openConsult.service.items.length === 0">\n' +
    '        Weiter zum Bezahlen\n' +
    '      </button>-->\n' +
    '      <a type="submit" class="btn btn-info" target="_blank"\n' +
    '         data-ng-disabled="billCtrl.patient.openConsult.service.items.length === 0"\n' +
    '         href="/routes/pdfprinter/consultpreview?patientid={{billCtrl.patient._id}}&consultid={{billCtrl.patient.openConsult._id}}&doctype=invoicepreview">\n' +
    '        <i class="fa fa-eye"></i> PDF Vorschau</a>\n' +
    '      <button type="submit" class="btn btn-danger" ng-click="billCtrl.noInvoice()">Weiter ohne Rechnung</button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  \n' +
    '  \n' +
    '  <!-- Service Liste -->\n' +
    '  <h4>Positionen</h4>\n' +
    '  <form>\n' +
    '    <div class="row">\n' +
    '      <div class="col-md-12" ng-if="service.price > 0"\n' +
    '           ng-repeat="service in billCtrl.patient.openConsult.service.items | filter: { active: true }">\n' +
    '        <div class="form-group col-md-12">\n' +
    '          <label>\n' +
    '            <input type="checkbox"\n' +
    '                   ng-init="false"\n' +
    '                   ng-model="service.selected"\n' +
    '                   ng-change="billCtrl.sumUpItems()"\n' +
    '                   uib-tooltip="{{service.comment}}"/> {{service.name}}<span\n' +
    '              ng-show="service.hint">({{service.hint}})</span>, EUR {{service.price}}</label>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </form>\n' +
    '  \n' +
    '  <div style="min-height:3cm">&nbsp;</div>\n' +
    '</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('consult/checkout-aides-directive.tpl.html',
    '<h3>Verordnungen von Hilfsmitteln</h3>\n' +
    '\n' +
    '  <form class="form-vertical" id="aideform" name="aideform">\n' +
    '    <div class="form-group">\n' +
    '      <label for="aideto">An/Zu<span uib-tooltip="Pflichtfeld">*</span></label>\n' +
    '      <input type="text" required ng-model="aidCtrl.newaide.to" class="form-control" id="aideto">\n' +
    '    </div>\n' +
    '    <div class="form-group">\n' +
    '      <label for="aidediag">Diagnose/Begründung<span uib-tooltip="Pflichtfeld">*</span></label>\n' +
    '      <input type="text" required ng-model="aidCtrl.newaide.diag" class="form-control" id="aidediag">\n' +
    '    </div>\n' +
    '    <div class="form-group">\n' +
    '      <label for="aideaide">Verordnung<span uib-tooltip="Pflichtfeld">*</span></label>\n' +
    '    <textarea class="form-control" required ng-model="aidCtrl.newaide.aide" rows="5"\n' +
    '              id="aideaide"></textarea>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- gültig -->\n' +
    '    <div class="form-group">\n' +
    '      <label>Gültig </label>\n' +
    '      <div class="radio">\n' +
    '        <label class="radio-inline">\n' +
    '          <input type="radio" ng-value="true" ng-model="aidCtrl.newaide.pdate"\n' +
    '                 ng-click="aidCtrl.newaide.aidedate = moment().locale(\'de-at\').format(\'ll\')">\n' +
    '          1 Monat</label>\n' +
    '        <label class="radio-inline">\n' +
    '          <input type="radio" ng-value="false" ng-model="aidCtrl.newaide.pdate"\n' +
    '                 ng-click="aidCtrl.newaide.aidedate = \'\'">\n' +
    '          Kein Datum</label>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- Buttons -->\n' +
    '    <button class="btn btn-success" type="button" ng-click="aidCtrl.addAnotherAide()"\n' +
    '            ng-disabled="aideform.$invalid">\n' +
    '      Speichern\n' +
    '    </button>\n' +
    '    <button class="btn btn-danger" type="button" ng-click="aidCtrl.dialog.close()" ng-show="aidCtrl.dialog">\n' +
    '      Schließen\n' +
    '    </button>\n' +
    '  </form>\n' +
    '\n' +
    '<!-- Liste bestehender Verordnungen -->\n' +
    '  <div ng-repeat="aide in aidCtrl.patient.openConsult.aides">\n' +
    '    <div class="row">\n' +
    '      <div class="col-sm-11"><h4>Verordnung</h4></div>\n' +
    '      <div class="col-sm-1">\n' +
    '        <button class="btn btn-danger" ng-click="aidCtrl.removeAide($index)" uib-tooltip="Verordnung löschen">\n' +
    '          <i class="fa fa-remove"></i>\n' +
    '        </button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '      <div class="col-xs-12">\n' +
    '        <dl class="dl-horizontal">\n' +
    '          <dt>An/zu</dt>\n' +
    '          <dd ng-model="aide.to">{{aide.to}}</dd>\n' +
    '          <dt>Diagnose</dt>\n' +
    '          <dd ng-model="aide.diag">{{aide.diag}}</dd>\n' +
    '          <dt>Verordnung</dt>\n' +
    '          <dd ng-model="aide.aide">{{aide.aide}}</dd>\n' +
    '          <dt>Datum</dt>\n' +
    '          <dd ng-bind="aide.aidedate"></dd>\n' +
    '        </dl>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <a class="btn btn-info" target="_blank" ng-show="aidCtrl.patient.openConsult.aides.length"\n' +
    '     href="/routes/pdfprinter/consultpreview?patientid={{aidCtrl.patient._id}}&consultid={{aidCtrl.patient.openConsult._id}}&doctype=aide">\n' +
    '    <i class="fa fa-eye"></i> PDF Vorschau\n' +
    '  </a>\n' +
    '  <div style="min-height:3cm">&nbsp;</div>\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('config/version-directive.tpl.html',
    '<h3>Version</h3>\n' +
    '\n' +
    '<div class="alert alert-info" ng-show="verCtrl.version">\n' +
    '  <h4><i class="fa fa-info-circle"></i> <span ng-bind="verCtrl.version.data"></span> </h4>\n' +
    '</div>\n' +
    '\n' +
    '<div class="alert alert-warning" ng-show="verCtrl.error">\n' +
    '  <h4><i class="fa fa-warning"></i> Versionsnummer wurde nicht gefunden: <span ng-bind="verCtrl.error"></span> </h4>\n' +
    '</div>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('config/config-editor-directive.tpl.html',
    '<h3>Konfiguration</h3>\n' +
    '\n' +
    '<form class="form-vertical">\n' +
    '  <div class="form-group col-md-12">\n' +
    '    <label>Patienten-Memo anstatt der Patienten-Heute Ansicht\n' +
    '      <div class="radio">\n' +
    '        <label for="memo1" class="radio-inline">\n' +
    '          <input type="radio" ng-value="true"\n' +
    '                 ng-model="confCtrl.conf.patientmemo"\n' +
    '                 id="memo1">ja</label>\n' +
    '        <label for="memo2" class="radio-inline">\n' +
    '          <input type="radio" ng-value="false"\n' +
    '                 ng-model="confCtrl.conf.patientmemo"\n' +
    '                 id="memo2">nein</label>\n' +
    '      </div>\n' +
    '    </label>\n' +
    '  </div>\n' +
    '<!--  <div class="form-group col-md-12">\n' +
    '    <label>Kalender\n' +
    '      <div class="radio">\n' +
    '        <label for="cal1" class="radio-inline">\n' +
    '          <input type="radio" ng-value="true"\n' +
    '                 ng-model="confCtrl.conf.calendar"\n' +
    '                 id="cal1">ja</label>\n' +
    '        <label for="cal2" class="radio-inline">\n' +
    '          <input type="radio" ng-value="false"\n' +
    '                 ng-model="confCtrl.conf.calendar"\n' +
    '                 id="cal2">nein</label>\n' +
    '      </div>\n' +
    '    </label>\n' +
    '  </div>-->\n' +
    '<!--  <div class="form-group col-md-12">\n' +
    '    <label>Benutzeraccounts aktiv\n' +
    '      <div class="radio">\n' +
    '        <label for="user1" class="radio-inline">\n' +
    '          <input type="radio" ng-value="true"\n' +
    '                 ng-model="confCtrl.conf.useraccounts"\n' +
    '                 id="user1">ja</label>\n' +
    '        <label for="user2" class="radio-inline">\n' +
    '          <input type="radio" ng-value="false"\n' +
    '                 ng-model="confCtrl.conf.useraccounts" id="user2">nein</label>\n' +
    '      </div>\n' +
    '    </label>\n' +
    '  </div>-->\n' +
    '<!--  <div class="form-group col-md-12">\n' +
    '    <label>Freiform Rechnung\n' +
    '      <div class="radio">\n' +
    '        <label for="bill1" class="radio-inline">\n' +
    '          <input type="radio" ng-value="true"\n' +
    '                 ng-model="confCtrl.conf.freeformbill" id="bill1">ja</label>\n' +
    '        <label for="bill2" class="radio-inline">\n' +
    '          <input type="radio" ng-value="false"\n' +
    '                 ng-model="confCtrl.conf.freeformbill" id="bill2">nein</label>\n' +
    '      </div>\n' +
    '    </label>\n' +
    '  </div>-->\n' +
    '\n' +
    '  <div class="form-group col-md-12">\n' +
    '    <label>Developer mode\n' +
    '      <div class="radio">\n' +
    '        <label for="devm1" class="radio-inline">\n' +
    '          <input type="radio" ng-value="true"\n' +
    '                 ng-model="confCtrl.conf.devmode" id="devm1">ja</label>\n' +
    '        <label for="devm2" class="radio-inline">\n' +
    '          <input type="radio" ng-value="false"\n' +
    '                 ng-model="confCtrl.conf.devmode" id="devm2">nein</label>\n' +
    '      </div>\n' +
    '    </label>\n' +
    '  </div>\n' +
    '<!--\n' +
    '\n' +
    '  <div class="form-group">\n' +
    '    <label for="maincolor">Hauptfarbe <span style="background-color:{{confCtrl.conf.printconf.maincolor}}">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>\n' +
    '    <input type="text" ng-model="confCtrl.conf.printconf.maincolor" class="form-control" id="maincolor">\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="form-group">\n' +
    '    <label for="highlightcolor">Highlight-Farbe <span\n' +
    '      style="background-color:{{confCtrl.conf.printconf.highlightcolor}}">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>\n' +
    '    <input type="text" ng-model="confCtrl.conf.printconf.highlightcolor" class="form-control" id="highlightcolor">\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="form-group">\n' +
    '    <label for="shadowcolor">Highlight-Farbe <span style="background-color:{{confCtrl.conf.printconf.shadowcolor}}">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>\n' +
    '    <input type="text" ng-model="confCtrl.conf.printconf.shadowcolor" class="form-control" id="shadowcolor">\n' +
    '  </div>\n' +
    '\n' +
    '-->\n' +
    '\n' +
    '  <h3>DMS</h3>\n' +
    '  <div class="form-group">\n' +
    '    <label>Kategorien für patientenbezogene Dokumente</label>\n' +
    '    <list-editor ar="confCtrl.conf.dmspatientcat"></list-editor>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="form-group">\n' +
    '    <label>Kategorien für generische Dokumente</label>\n' +
    '    <list-editor ar="confCtrl.conf.dmsgencat"></list-editor>\n' +
    '  </div>\n' +
    '  \n' +
    '\n' +
    '  \n' +
    '  <h3>Medikamenten-Signaturen</h3>\n' +
    '  <div class="form-group">\n' +
    '    <label>Häufig gebrauchte Medikamentensignaturen</label>\n' +
    '    <list-editor ar="confCtrl.conf.drugsignature"></list-editor>\n' +
    '  </div>\n' +
    '  \n' +
    '  \n' +
    '  <h3>Drucker</h3>\n' +
    '\n' +
    '  <!-- <div class="form-group">\n' +
    '    <label for="refprinter">Drucker für Verordnungen und Überweisungen</label>\n' +
    '    <span ng-show="confCtrl.conf.printers.refprinter">Derzeit gewählt: {{confCtrl.conf.printers.refprinter}} </span>\n' +
    '    <select ng-model="confCtrl.conf.printers.refprinter" class="form-control" id="refprinter">\n' +
    '      <option value="{{p.printername}}" ng-repeat="p in confCtrl.allprinters">{{p.description}}</option>\n' +
    '    </select>\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <label for="prescriptionprinter">Drucker für Rezepte</label>\n' +
    '    <span ng-show="confCtrl.conf.printers.refprinter">Derzeit gewählt: {{confCtrl.conf.printers.refprinter}} </span>\n' +
    '    <select ng-model="confCtrl.conf.printers.prescriptionprinter" class="form-control" id="prescriptionprinter">\n' +
    '      <option value="{{p.printername}}" ng-repeat="p in confCtrl.allprinters">{{p.description}}</option>\n' +
    '    </select>\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <label for="findingsprinter">Drucker für Befunde</label>\n' +
    '    <span ng-show="confCtrl.conf.printers.findingsprinter">Derzeit gewählt: {{confCtrl.conf.printers.findingsprinter}} </span>\n' +
    '    <select ng-model="confCtrl.conf.printers.findingsprinter" class="form-control" id="findingsprinter">\n' +
    '      <option value="{{p.printername}}" ng-repeat="p in confCtrl.allprinters">{{p.description}}</option>\n' +
    '    </select>\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <label for="labelprinter">Drucker für Biopsie-Labels</label>\n' +
    '    <span ng-show="confCtrl.conf.printers.labelprinter">Derzeit gewählt: {{confCtrl.conf.printers.labelprinter}} </span>\n' +
    '    <select ng-model="confCtrl.conf.printers.labelprinter" class="form-control" id="labelprinter">\n' +
    '      <option value="{{p.printername}}" ng-repeat="p in confCtrl.allprinters">{{p.description}}</option>\n' +
    '    </select>\n' +
    '  </div>-->\n' +
    '  <div class="form-group">\n' +
    '    <label for="invoiceprinter">Drucker für Rechnungen</label>\n' +
    '    <span ng-show="confCtrl.conf.printers.invoiceprinter">Derzeit gewählt: {{confCtrl.conf.printers.invoiceprinter}} </span>\n' +
    '    <select ng-model="confCtrl.conf.printers.invoiceprinter" class="form-control" id="invoiceprinter">\n' +
    '      <option value="{{p.printername}}" ng-repeat="p in confCtrl.allprinters">{{p.description}}</option>\n' +
    '    </select>\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <label for="receiptprinter">Drucker für Belege</label>\n' +
    '    <span ng-show="confCtrl.conf.printers.receiptprinter">Derzeit gewählt: {{confCtrl.conf.printers.receiptprinter}} </span>\n' +
    '    <select ng-model="confCtrl.conf.printers.receiptprinter" class="form-control" id="receiptprinter">\n' +
    '      <option value="{{p.printername}}" ng-repeat="p in confCtrl.allprinters">{{p.description}}</option>\n' +
    '    </select>\n' +
    '  </div>\n' +
    '\n' +
    '<!--  <h3>Firma</h3>\n' +
    '\n' +
    '  <div class="form-group col-md-12">\n' +
    '    <label for="compname">Firma</label>\n' +
    '    <input type="text" ng-model="confCtrl.conf.company.name" placeholder="Firmenname bzw. Arztname" id="compname"  class="form-control" >\n' +
    '  </div>\n' +
    '  <div class="form-group col-md-12">\n' +
    '    <label for="comptax">Steuernummer</label>\n' +
    '    <input type="text" ng-model="confCtrl.conf.company.taxnr" placeholder="Steuernummer" id="comptax" class="form-control" >\n' +
    '  </div>\n' +
    '  <div class="form-group col-md-12">\n' +
    '    <label for="compaddress">Adresse</label>\n' +
    '    <input type="text" ng-model="confCtrl.conf.company.address" placeholder="Straße Hausnummer" id="compaddress" class="form-control" >\n' +
    '  </div>\n' +
    '  <div class="form-group col-md-12">\n' +
    '    <label for="compcity">Ort</label>\n' +
    '    <input type="text" ng-model="confCtrl.conf.company.city" placeholder="PLZ Ort" id="compcity" class="form-control" >\n' +
    '  </div>-->\n' +
    '\n' +
    '\n' +
    '\n' +
    '  <div class="form-group col-md-12">\n' +
    '    <button type="submit" class="btn btn-success"\n' +
    '            ng-click="confCtrl.conf.$save()">Speichern\n' +
    '    </button>\n' +
    '  </div>\n' +
    '</form>\n' +
    '\n' +
    '\n' +
    '\n' +
    '');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('chat/chat.tpl.html',
    '<h2>chat</h2>\n' +
    '<p>{{chat.ctrlName}}</p>');
}]);

angular.module('docsaver').run(['$templateCache', function($templateCache) {
  $templateCache.put('chat/chat-directive.tpl.html',
    '<div class="panel panel-default">\n' +
    '  <div class="panel-heading">Chat</div>\n' +
    '  <div class="panel-body">\n' +
    '    <div scroll-glue class="chatlog">\n' +
    '      <div ng-repeat="msg in chatCtrl.chatlog track by $index">\n' +
    '        <div ng-bind="msg"></div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <form>\n' +
    '      <div class="input-group">\n' +
    '        <input class="form-control"\n' +
    '               ng-model="chatCtrl.newmessage" id="newgroup" placeholder="Nachricht eingeben" />\n' +
    '        <span class="input-group-btn">\n' +
    '          <button class="btn btn-success"\n' +
    '                  type="submit"\n' +
    '                  ng-click="chatCtrl.sendMsg()"><i class="fa fa-send"></i></button>\n' +
    '        </span>\n' +
    '      </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '');
}]);

(function () {
  'use strict';

  /* @ngdoc object
   * @name utils.arrayEditor
   * @description
   *
   */
  angular
    .module('utils.listEditor', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name utils.listEditor.directive:listEditor
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="utils.listEditor">
       <file name="index.html">
        <list-editor ar=""></list-editor>
       </file>
     </example>
   *
   */
  angular
    .module('utils.listEditor')
    .directive('listEditor', listEditor);

  listEditor.$inject = ['$log'];

  function listEditor($log) {
    return {
      restrict: 'EA',
      scope: {
        ar: '='
      },
      templateUrl: 'utils/list-editor/list-editor-directive.tpl.html',
      replace: false,
      controllerAs: 'listCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;

        vm.splice = splice;
        vm.add = add;
        vm.formid = guid;

        $log.debug('vm.ar: ', vm.ar);

        /*
        if (!angular.isArray(vm.ar)) {
          vm.ar = [];
          $log.debug('array new: ', vm.ar);
        } */
        
        function splice(i) {
          vm.ar.splice(i, 1);
          $log.debug('array: ', vm.ar);
        }

        function add() {
          if (!angular.isArray(vm.ar)) {
            vm.ar = [];
          }
          vm.ar.push(vm.newvalue);
          $log.debug('array: ', vm.ar);
          vm.newvalue = '';
        }
        
        function guid() {
          function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
              .toString(16)
              .substring(1);
          }
          return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
        }

      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name utils.stacktrace
   * @description
   *
   */
  angular
    .module('utils.stacktrace', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name utils.stacktrace.service:Stacktrace
   *
   * @description
   *
   */
  angular
    .module('utils.stacktrace')
    .service('Stacktrace', Stacktrace);

  function Stacktrace() {
    Stacktrace.prototype.print = function ($window, exception) {
      return $window.printStackTrace({
        e: exception
      });
    };
  }
  

  /* var StacktraceService = function() {}
   StacktraceService.prototype.print = function($window, exception) {
   return $window.printStackTrace({
   e: exception
   });
   };
   angular.module('plunker').service('stacktraceService', StacktraceService);*/

// a call to printStackTrace function with exception object will output an array of
// string as shown below:
// ["ReferenceError: $logs is not defined", "    at Scope.$scope.cancelForm (https://run.plnkr.co/iNUZMYs0Ss3pprmS/app.js:54:5)",
// "    at fn (eval at <anonymous> (https://code.angularjs.org/1.4.9/angular.js:1:0) ]


  // klammerfriedhof
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name utils.keywords
   * @description
   *
   */
  angular
    .module('utils.keywords', [
      'ui.router',
      'as.sortable'
    ])
    .filter('myindex', function () {
      return function (array, index) {
        var i = 0;
        if (!index) {
          index = 'myindex';
        }
        for (i = 0; i < array.length; ++i) {
          array[i][index] = i;
        }
        return array;
      };
    });
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name utils.keywords.directive:sortableKeywords
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="utils.keywords">
       <file name="index.html">
        <sortable-keywords></sortable-keywords>
       </file>
     </example>
   *
   */
  angular
    .module('utils.keywords')
    .directive('sortableKeywords', sortableKeywords);
  
  
  sortableKeywords.$inject = ['$log'];
  
  function sortableKeywords($log) {
    return {
      restrict: 'EA',
      scope: {
        keywords: '=',
        text: '@',
        orderbycolor: '='
      },
      templateUrl: 'utils/keywords/sortable-keywords-directive.tpl.html',
      replace: false,
      controllerAs: 'vmKw',
      bindToController: true,
      controller: function () {
        var vm = this;
         
        var nrResolver = {
          primary: 1,
          info: 2,
          danger: 3,
          warning: 4,
          success: 5
        };
        
        var altTextResolver = {
          primary: '(neu)',
          info: '',
          danger: '',
          warning: '',
          success: '(St.p.)'
        };
        
        vm.orderkey = 'nr';
        vm.updateColor = updateColor;
        vm.addKeyword = addKeyword;
        vm.removeKeyword = removeKeyword;
  
        function updateColor(value, color) {
          var index = vm.keywords.map(function (el) {
            return el.key;
          }).indexOf(value);
          console.log('value: ', value, ', index: ', index);
          vm.keywords[index].col = color;
          vm.keywords[index].nr = nrResolver[color];
          vm.keywords[index].alttext = altTextResolver[color];
          vm.keywords[index].updated = moment().locale('de-at').format('l');
        }
  
        function removeKeyword(value) {
          var index = vm.keywords.map(function (el) {
            return el.key;
          }).indexOf(value);
          vm.keywords.splice(index, 1);
        }
  
        function addKeyword() {
          var kw = {};
          kw.col = 'primary';
          kw.key = vm.newkeyword;
          kw.nr = nrResolver[kw.col];
          kw.alttext = altTextResolver[kw.col];
          kw.timestamp = moment().toISOString();
          kw.created = moment().locale('de-at').format('l');
          kw.updated = moment().locale('de-at').format('l');
    
          if (vm.keywords.hasOwnProperty('length') === false) {
            vm.keywords = [];
          }
          $log.debug('Keyword: ', kw.key);
          $log.debug('Keyword length: ', kw.key.length);
          if (kw.key.length > 0) {
            vm.keywords.push(kw);
          }
          vm.newkeyword = '';
        }
  
  
  
  
  
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
  
        console.log('Attr: ', attrs);
        if (!attrs.orderbycolor) {
          attrs.orderbycolor = false;
          ctrl.orderkey = '$index';
        }
        else {
          ctrl.orderkey = 'nr';
        }
        if (!attrs.text) {
          attrs.text = 'Neues Stichwort eingeben <Eingabataste>';
        }
        
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:selectedDiagnoses
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="patient">
       <file name="index.html">
        <selected-keywords></selected-keywords>
       </file>
     </example>
   *
   */
  angular
    .module('utils.keywords')
    .directive('selectedKeywords', selectedKeywords);

  function selectedKeywords() {
    return {
      restrict: 'EA',
      scope: {
        keywords: '=',
        selected: '@'
      },
      templateUrl: 'utils/keywords/selected-keywords-directive.tpl.html',
      replace: false,
      controllerAs: 'skwCtrl',
      controller: 'KeywordsCtrl',
      bindToController: true,
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name utils.keywords.directive:keywordsWidget
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="utils.keywords">
   <file name="index.html">
   <keywords-widget keywords="patient.keywords"></keywords-widget>
   <div keywords-widget keywords="patient.keywords"></div>
   </file>
   </example>
   *
   */
  angular
    .module('utils.keywords')
    .directive('keywordsWidget', keywordsWidget);

  keywordsWidget.$inject = [];

  function keywordsWidget() {
    return {
      restrict: 'EA',
      scope: {
        keywords: '=',
        text: '@',
        orderbycolor: '='
      },
      templateUrl: 'utils/keywords/keywords-widget-directive.tpl.html',
      replace: false,
      controllerAs: 'vmKw',
      bindToController: true,
      controller: 'KeywordsCtrl',
      link: function (scope, el, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */

        console.log('Attr: ', attrs);
        if (!attrs.orderbycolor) {
          attrs.orderbycolor = false;
          ctrl.orderkey = '$index';
        }
        else {
          ctrl.orderkey = 'nr';
        }
        if (!attrs.text) {
          attrs.text = 'Neues Stichwort eingeben <Eingabataste>';
        }
      }
    };
  }
}());

(function () {
  'use strict';

  angular
    .module('utils.keywords')
    .config(config);

  config.$inject = ['$stateProvider'];
  function config($stateProvider) {
    $stateProvider
      .state('keywords', {
        url: '/keywords',
        templateUrl: 'utils/keywords/keywords.tpl.html',
        controller: 'KeywordsCtrl',
        controllerAs: 'keywords'
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name utils.keywords.controller:KeywordsCtrl
   *
   * @description
   *
   */
  angular
    .module('utils.keywords')
    .controller('KeywordsCtrl', KeywordsCtrl);

  KeywordsCtrl.$inject = ['$log'];

  function KeywordsCtrl($log) {
    var vm = this;
    var nrResolver = {
      primary: 1,
      info: 2,
      danger: 3,
      warning: 4,
      success: 5
    };
    var altTextResolver = {
      primary: '(neu)',
      info: '',
      danger: '',
      warning: '',
      success: '(St.p.)'
    };
    vm.orderkey = 'nr';
    vm.updateColor = updateColor;
    vm.addKeyword = addKeyword;
    vm.removeKeyword = removeKeyword;

    function updateColor(value, color) {
      var index = vm.keywords.map(function (el) {
        return el.key;
      }).indexOf(value);
      console.log('value: ', value, ', index: ', index);
      vm.keywords[index].col = color;
      vm.keywords[index].nr = nrResolver[color];
      vm.keywords[index].alttext = altTextResolver[color];
      vm.keywords[index].updated = moment().locale('de-at').format('l');
    }

    function removeKeyword(value) {
      var index = vm.keywords.map(function (el) {
        return el.key;
      }).indexOf(value);
      vm.keywords.splice(index, 1);
    }

    function addKeyword() {
      var kw = {};
      kw.col = 'primary';
      kw.key = vm.newkeyword;
      kw.nr = nrResolver[kw.col];
      kw.alttext = altTextResolver[kw.col];
      kw.timestamp = moment().toISOString();
      kw.created = moment().locale('de-at').format('l');
      kw.updated = moment().locale('de-at').format('l');

      if (vm.keywords.hasOwnProperty('length') === false) {
        vm.keywords = [];
      }
      $log.debug('Keyword: ', kw.key);
      $log.debug('Keyword length: ', kw.key.length);
      if (kw.key.length > 0) {
        vm.keywords.push(kw);
      }
      vm.newkeyword = '';
    }

  }


}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name utils.fileuploader
   * @description
   *
   */
  angular
    .module('utils.fileuploader', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name utils.fileuploader.directive:imagecapture
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="utils.fileuploader">
       <file name="index.html">
        <imagecapture></imagecapture>
       </file>
     </example>
   *
   */
  angular
    .module('utils.fileuploader')
    .directive('imagecapture', imagecapture);

  imagecapture.$inject = ['Fileupload', '$log'];

  function imagecapture(Fileupload, $log) {
    return {
      restrict: 'EA',
      scope: {
        filehandle: '=',
        captureid: '@',
        label: '=?'
      },
      templateUrl: 'utils/fileuploader/imagecapture-directive.tpl.html',
      replace: false,
      controllerAs: 'icCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'fileupload';
        vm.upload = upload;

        $log.debug('catureid: ', vm.captureid);
        function upload() {
          // vm.icfile.type = 'Bild/Foto';
          Fileupload.uploadFileToUrl(vm.icfile, '/routes/upload')
            .success(function (res) {
              $log.debug('Fileupload successful: ', res);
              vm.filehandle = res;
              $log.debug('Filehandle: ', vm.filehandle);
            })
            .error(function (res) {
              $log.debug('Fileupload error');
              return res;
            });
        }

      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        element.bind('change', function (e) {
          // ctrl.icfile = (e.srcElement || e.target).files[0];
          console.log('element: ', e);
          ctrl.icfile = (e.srcElement || e.target).files[0];
          ctrl.upload();
        });
      }
    };
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('utils.fileuploader')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('fileuploader', {
        url: '/fileuploader',
        views: {
          main: {templateUrl: 'utils/fileuploader/fileuploader.tpl.html'}
        }
      });
  }
// klammerfriedhof
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name utils.fileuploader.factory:Fileupload
   *
   * @description
   *
   */
  angular
    .module('utils.fileuploader')
    .factory('Fileupload', Fileupload);

  Fileupload.$inject = ['$http', '$log'];

  function Fileupload($http, $log) {
    var FileuploadBase = {};
    FileuploadBase.uploadFileToUrl = function (file, uploadUrl) {
      var fd = new FormData();
      fd.append('file', file);
      return $http.post(uploadUrl, fd, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined}
      });
    };
    return FileuploadBase;
  }
}());

(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name utils.fileuploader.directive:fileupload
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="utils.fileuploader">
   <file name="index.html">
   <fileupload filehandle=""></fileupload>
   </file>
   </example>
   *
   */
  angular
    .module('utils.fileuploader')
    .directive('fileupload', fileupload);
  
  fileupload.$inject = ['Fileupload', '$log'];
  
  function fileupload(Fileupload, $log) {
    return {
      restrict: 'E',
      scope: {
        filehandle: '=',
        furl: '=?',
        label: '=?',
        color: '=?'
      },
      templateUrl: 'utils/fileuploader/fileupload-directive.tpl.html',
      replace: false,
      controllerAs: 'fuCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'fileupload';
        vm.upload = upload;
        
        // create unique identifiers for fileupload input ids
        vm.uuid = guid();
        
        
        // this doesn't work in angular 1.4
        /* vm.$onInit = $onInit;
         
         function $onInit() {
         $log.debug('$onInit() called');
         vm.furl = angular.isDefined(vm.furl) ? vm.furl : '/routes/upload';
         vm.label = angular.isDefined(vm.label) ? vm.label : 'Datei hinaufladen ...';
         vm.color = angular.isDefined(vm.color) ? vm.color : 'primary';
         $log.debug('$onInit() url: ', vm.furl);
         }*/
        
        function upload() {
          // vm.ufile.type = 'Dateiupload';
          $log.debug('upload url: ', vm.furl);
          if (angular.isUndefined(vm.furl)) {
            vm.furl = '/routes/upload';
          }
          $log.debug('upload url: ', vm.furl);
          Fileupload.uploadFileToUrl(vm.ufile, vm.furl)
            .success(function (res) {
              $log.debug('Fileupload successful: ', res);
              vm.filehandle = res;
              $log.debug('Filehandle: ', vm.filehandle);
            })
            .error(function (res) {
              $log.debug('Fileupload error');
              return res;
            });
        }
        
        
        function guid() {
          function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
              .toString(16)
              .substring(1);
          }
          
          return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
        }
        
        
      },
      /*
       // this sets the defaults, but hangs the fileupload on permanently
        function (element, attrs) {
       $log.debug('compile fnuction called');
       attrs.furl = angular.isDefined(attrs.furl) ? attrs.furl : "'/routes/upload'";
       attrs.label = angular.isDefined(attrs.label) ? attrs.label : "'Datei auswählen ...'";
       attrs.color = angular.isDefined(attrs.color) ? attrs.color : "'primary'";
       },*/
      compile: function () {
        return {
          pre: function (scope, element, attrs, ctrl) {
            // this is your average link function
            /* jshint unused:false */
            /* eslint "no-unused-vars": [2, {"args": "none"}] */
            element.bind('change', function (e) {
              $log.debug('Element: ', element);
              $log.debug('Element e: ', e);
              $log.debug('files e: ', e.srcElement.files);
              ctrl.ufile = (e.srcElement || e.target).files[0];
              ctrl.upload();
            });
          },
          post: function (scope, element, attrs, ctrl) {
            // this is non standard post link function that will set the defaults, if not already available
            /* jshint unused:false */
            /* eslint "no-unused-vars": [2, {"args": "none"}] */
            // TODO: this code does not works, neither as post-link nor as post-compile
           /* $log.debug('post link function called');
            // double and single quotes are necessary. system will otherwise search for variables instead!
            attrs.furl = angular.isDefined(attrs.furl) ? attrs.furl : "'/routes/upload'";
            attrs.label = angular.isDefined(attrs.label) ? attrs.label : "'Datei auswählen ...'";
            attrs.color = angular.isDefined(attrs.color) ? attrs.color : "'primary'";*/
          }
        };
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name utils.fileuploader.directive:fileModel
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="utils.fileuploader">
       <file name="index.html">
        <file-model></file-model>
       </file>
     </example>
   *
   */
  angular
    .module('utils.fileuploader')
    .directive('fileModel', fileModel);

  fileModel.$inject = ['$parse'];

  function fileModel($parse) {
    return {
      restrict: 'A',

      link: function (scope, element, attrs) {
        var model = $parse(attrs.fileModel);
        var modelSetter = model.assign;

        element.bind('change', function () {
          scope.$apply(function () {
            modelSetter(scope, element[0].files[0]);
            scope.$parent.upload();
          });
        });
      }
    };
  }

// klammer friedhof
}());



(function () {
  'use strict';

  /* @ngdoc object
   * @name utils.filereader
   * @description
   *
   */
  angular
    .module('utils.filereader', [
      'ui.router',
      'ui.bootstrap'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('utils.filereader')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('filereader', {
        url: '/filereader',
        templateUrl: 'utils/filereader/filereader.tpl.html',
        controller: 'FilereaderCtrl',
        controllerAs: 'filereader'
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name utils.filereader.factory:Filereader
   *
   * @description
   *
   */
  angular
    .module('utils.filereader')
    .factory('Filereader', Filereader);

  Filereader.$inject = ['$q'];

  function Filereader($q) {
    var onLoad = function (reader, deferred, scope) {
      return function () {
        scope.$apply(function () {
          deferred.resolve(reader.result);
        });
      };
    };

    var onError = function (reader, deferred, scope) {
      return function () {
        scope.$apply(function () {
          deferred.reject(reader.result);
        });
      };
    };

    var onProgress = function (reader, scope) {
      return function (event) {
        scope.$broadcast('fileProgress',
            {
              total: event.total,
              loaded: event.loaded
            }
        );
      };
    };

    var getReader = function (deferred, scope) {
      var reader = new FileReader();
      reader.onload = onLoad(reader, deferred, scope);
      reader.onerror = onError(reader, deferred, scope);
      reader.onprogress = onProgress(reader, scope);
      return reader;
    };

    var readAsDataURL = function (file, scope) {
      var deferred = $q.defer();
      var reader = getReader(deferred, scope);
      console.log('readasdaturl scope: ',scope);
      reader.readAsDataURL(file);

      return deferred.promise;
    };

    var readAsText = function (file, scope) {
      var deferred = $q.defer();
      var reader = getReader(deferred, scope);
      reader.readAsText(file);
      return deferred.promise;
    };

    var readAsArrayBuffer = function (file, scope) {
      var deferred = $q.defer();
      var reader = getReader(deferred, scope);
      reader.readAsArrayBuffer(file);
      return deferred.promise;
    };

    var readAsBinaryString = function (file, scope) {
      var deferred = $q.defer();
      var reader = getReader(deferred, scope);
      reader.readAsBinaryString(file);
      return deferred.promise;
    };

    return {
      readAsDataUrl: readAsDataURL,
      readAsText: readAsText,
      readAsBinaryString: readAsBinaryString,
      readAsArrayBuffer: readAsArrayBuffer
    };

  }


}());



(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name utils.filereader.directive:filereader
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="utils.filereader">
        <filereader file=""></filereader>
     </example>
   *
   */
  angular
    .module('utils.filereader')
    .directive('filereader', filereader);

  filereader.$inject = [];

  function filereader() {
    return {
      restrict: 'EA',
      scope: {
        file: '='
      },
      templateUrl: 'utils/filereader/filereader-directive.tpl.html',
      replace: false,
      controller: 'FilereaderCtrl',
      controllerAs: 'frCtrl',
      bindToController: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        element.bind('change', function (e) {
          ctrl.file = (e.srcElement || e.target).files[0];
          ctrl.getFile();
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name utils.filereader.controller:FilereaderCtrl
   *
   * @description
   *
   */
  angular
    .module('utils.filereader')
    .controller('FilereaderCtrl', FilereaderCtrl);

  FilereaderCtrl.$inject = ['Filereader', '$scope', '$http', '$log'];

  function FilereaderCtrl(Filereader, $scope, $http, $log) {
    var vm = this;
    vm.ctrlName = 'FilereaderCtrl';

    console.log(Filereader);

    vm.getFile = function () {
      vm.file.progress = 0;
      vm.blob = vm.file;
      switch (vm.file.type) {
        case 'image/gif':
          vm.file.img = true;
          break;
        case 'image/jpg':
          vm.file.img = true;
          break;
        case 'image/jpeg':
          vm.file.img = true;
          break;
        case 'image/png':
          vm.file.img = true;
          break;
        case 'text/plain':
          vm.file.text = true;
          break;
        case 'text/csv':
          vm.file.text = true;
          break;
        default:
          vm.file.nopreview = true;
      }

      if (vm.file.img) {
        Filereader.readAsDataUrl(vm.file, $scope)
          .then(function (result) {
            vm.file.fileSrc = result;
          });
        Filereader.readAsBinaryString(vm.file, $scope)
          .then(function (result) {
            vm.file.binString = result;
          });
      }

      if (vm.file.text) {
        Filereader.readAsText(vm.file, $scope)
          .then(function (result) {
            vm.file.fileSrc = result;
            vm.file.textpreview = fileHead();
          });
      }

      if (vm.file.nopreview) {
        Filereader.readAsDataUrl(vm.file, $scope)
          .then(function (result) {
            vm.file.fileSrc = result;
          });
        Filereader.readAsBinaryString(vm.file, $scope)
          .then(function (result) {
            vm.file.binString = result;
          });
      }

    };


    function fileHead() {
      // var head;
      var lines = [];
      console.log('head: ', vm.file.fileSrc);

      // head = vm.file.fileSrc.split(0, 500);
      // lines = vm.file.fileSrc.split('/\r?\n/');
      //lines = vm.file.fileSrc.split('\n');
      lines = vm.file.fileSrc.split('\r\n|[\n\v\f\r\x85\u2028\u2029]');
      console.log('lines: ', lines);
      return lines.slice(0, 4);
    }

    $scope.$on('fileProgress', function (e, progress) {
      vm.file.progress = progress.loaded / progress.total;
    });

  }

}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name helpers.zipcode
   * @description
   *
   */
  angular
    .module('helpers.zipcode', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name helpers.zipcode.factory:Zipcode
   *
   * @description
   *
   */

  angular
    .module('helpers.zipcode')
    .factory('Zipcode', Zipcode)
    .factory('Zipcodesearch', Zipcodesearch);

  Zipcode.$inject = ['restmod'];
  function Zipcode(restmod) {
    return restmod.model('/routes/zipcodes');
  }

  Zipcodesearch.$inject = ['restmod'];
  function Zipcodesearch(restmod) {
    return restmod.model('/routes/zipcodes/numahead');
  }



}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name helpers.zipcode.directive:zipcode
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="helpers.zipcode">
   <file name="index.html">
   <zipcode></zipcode>
   </file>
   </example>
   *
   */
  angular
    .module('helpers.zipcode')
    .directive('zipcode', zipcode);

  zipcode.$inject = ['Zipcodesearch', '$log'];

  function zipcode(Zipcodesearch, $log) {
    return {
      restrict: 'E',
      scope: {
        zip: '=',
        city: '=',
        ti: '@?',
        ti2: '@?'
      },
      templateUrl: 'helpers/zipcode/zipcode-directive.tpl.html',
      replace: false,
      controllerAs: 'zcCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'zipcode';

        // default optional ti (tabindex) to 1000 & 1001
        if (!angular.isObject(vm.ti)) {
          vm.ti = 1000;
        }

        if (!angular.isObject(vm.ti2)) {
          vm.ti2 = 1001;
        }

        vm.getzipcity = getzipcity;
        vm.foreignzip = foreignzip;
        vm.getNames = getNames;
        // vm.ngModelOptionsSelected = ngModelOptionsSelected;


        // typeahead patient search backend query
        function getNames(val) {
          $log.debug('loading city names');

          return Zipcodesearch.$search({zip: val}).$asPromise().then(function (response) {
            console.log('response: ', response);
            return response.map(function (zip) {
              zip.text = zip.zip + ' ' + zip.city;
              zip.label = zip.zip + ' ' + zip.city;
              $log.debug('response zip: ', zip);
              return zip;
            });
          });
        }

        // typeahead patient search action
        function getzipcity($item, $model, $label) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          //  $log.debug('orig zip: ', vm.zip);
          //  $log.debug('$model zip: ', $model.zip);
          //  $log.debug('orig city: ', vm.city);
          vm.zip = $model.zip;
          vm.city = $model.city;
        }

        function foreignzip() {
          $log.debug('foreignzip vm.zip:', vm.zip);
          $log.debug('foreignzip asyncSelected:', vm.asyncSelected);
          if (!vm.zip) {
            if (angular.isString(vm.asyncSelected)) {
              vm.zip = vm.asyncSelected;
              $log.debug('foreignzip string vm.zip:', vm.zip);
            }
            if (angular.isObject(vm.asyncSelected) && vm.asyncSelected.hasOwnProperty(zip)) {
              vm.zip = vm.asyncSelected.zip;
              $log.debug('foreignzip object vm.zip:', vm.zip);
            }
          }
        }


      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name helpers.sv
   * @description
   *
   */
  angular
    .module('helpers.sv', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name helpers.sv.directive:svSearch
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="helpers.sv">
   <file name="index.html">
   <sv-search svres="" svresult="" ti="?"></sv-search>
   </file>
   </example>
   *
   */
  angular
    .module('helpers.sv')
    .directive('svSearch', svSearch);


  svSearch.$inject = ['Svahead', 'Sv', '$uibModal', '$log', 'SvHelper'];

  function svSearch(Svsearch, Sv, $uibModal, $log, SvHelper) {
    return {
      restrict: 'EA',
      scope: {
        svresult: '=',
        svres: '=',
        ti: '@?'
      },
      templateUrl: 'helpers/sv/sv-search-directive.tpl.html',
      replace: false,
      controllerAs: 'svsCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;

        vm.showsv = showsv;
        vm.getSvs = getSvs;
        vm.addSv = addSv;
        vm.editSv = SvHelper.editSv;

        $log.debug('ti: ', vm.ti);

        if (angular.isString(vm.ti)) {
          vm.tabi = parseInt(vm.ti, 10);
          $log.debug('tabi: ', vm.tabi);
        }
        else {
          vm.tabi = 0;
        }

        vm.newsv = {};
        vm.newsv.foo = 'quux';

        // typeahead doctor search backend query
        function getSvs(val) {
          return Svsearch.$search({query: val}).$asPromise().then(function (response) {
            return response.map(function (sv) {
              sv.text = sv.short;
              return sv;
            });
          });
        }

        // typeahead doctor search action
        function showsv($item, $model, $label) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          // vm.doctorresult = Doctor.$find($model._id);
          Sv.$find($model._id).$asPromise().then(function (sv) {
            $log.debug('sv item: ', $item);
            vm.svresult._id = sv._id;
            vm.svresult.name = sv.name;
            vm.svresult.short = sv.short;
            vm.svres = sv.short;
            delete vm.asyncSelected;
          });
        }

        function addSv() {
          var newsv = Sv.$create();
          vm.dialog = vm.editSv(newsv);
          $log.debug('dialog: ', vm.dialog);
          vm.dialog.result.finally(function () {
            $log.debug('new sv added: ', newsv);
            $log.debug('modal closed finally: ', newsv);
            $log.debug('vm.svresult before: ', vm.svresult);
            if (!angular.isObject(vm.svresult)) {
              vm.svresult = {};
            }
            vm.svresult._id = newsv._id;
            vm.svresult.name = newsv.name;
            vm.svresult.short = newsv.short;
            vm.svres = newsv.short;
            vm.asyncSelected = newsv.short;
            $log.debug('vm.svresult after: ', vm.svresult);
            $log.debug('newly added: ', vm.svres, vm.svresult);
          });
        }


        /* function addSv() {
          var dialog = $uibModal.open({
            template: '<div class="modalspacer"><sv-edit returnsv="miCtrl.returnsv" dialog="miCtrl.$uibModalInstance"></sv-edit></div>',
            controllerAs: 'miCtrl',
            controller: ['returnsv', '$uibModalInstance', function (returnsv, $uibModalInstance) {
              var vmi = this;
              vmi.returnsv = returnsv;
              vmi.$uibModalInstance = $uibModalInstance;
              $log.debug('vmi: ', returnsv);
            }],
            resolve: {
              returnsv: function () {
                $log.debug('newsv init: ', vm.newsv);
                return vm.newsv;
              },
              dialog: function () {
                $log.debug('dialog init: ', dialog);
                return dialog;
              }
            },
            size: 'lg'
          });

          dialog.result.finally(function () {
            $log.debug('new doc added: ', vm.newsv);
            $log.debug('modal closed finally: ', vm.newsv);
            vm.svresult = {};
            vm.svresult._id = vm.newsv._id;
            vm.svresult.fname = vm.newsv.short;
            vm.asyncSelected = vm.newsv.short;
          });
        }*/


      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name helpers.sv.factory:SvHelper
   *
   * @description
   *
   */
  angular
    .module('helpers.sv')
    .factory('SvHelper', SvHelper);


  SvHelper.$inject = ['Sv', '$uibModal', '$log'];

  function SvHelper(Sv, $uibModal, $log) {
    var SvHelperBase = {};

    SvHelperBase.editSv = editSv;

    function editSv(lsv) {
      var dialog = $uibModal.open({
        template: '<div class="modalspacer"><sv-edit sv="miCtrl.sv" dialog="miCtrl.$uibModalInstance"></sv-edit></div>',
        controllerAs: 'miCtrl',
        controller: ['sv', '$uibModalInstance', function (ssv, $uibModalInstance) {
          var vmi = this;
          vmi.sv = ssv;
          vmi.$uibModalInstance = $uibModalInstance;
        }],
        resolve: {
          sv: function () {
            return lsv;
          },
          dialog: function () {
            return dialog;
          }
        },
        size: 'lg'
      });
      return dialog;
    }
    
    return SvHelperBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name helpers.sv.factory:Sv
   *
   * @description
   *
   */
  angular
    .module('helpers.sv')
    .factory('Sv', Sv)
    .factory('Svahead', Svahead);


  Sv.$inject = ['restmod'];
  function Sv(restmod) {
    return restmod.model('/routes/svs').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true}
    });
  }

  Svahead.$inject = ['restmod'];
  function Svahead(restmod) {
    return restmod.model('/routes/svs/svahead');
  }

// klammerfriedhof
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name helpers.sv.directive:svEdit
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="helpers.sv">
       <file name="index.html">
        <sv-edit></sv-edit>
       </file>
     </example>
   *
   */
  angular
    .module('helpers.sv')
    .directive('svEdit', svEdit);

  function svEdit() {
    return {
      restrict: 'E',
      scope: {
        sv: '=',
        dialog: '='
      },
      templateUrl: 'helpers/sv/sv-edit-directive.tpl.html',
      replace: false,
      controllerAs: 'sveCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'svEdit';
        vm.save = save;
        vm.close = close;

        function save() {
          vm.sv.$save();
        }

        function close() {
          vm.dialog.close();
        }

      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name helpers.sv.directive:sv
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="helpers.sv">
       <file name="index.html">
        <sv></sv>
       </file>
     </example>
   *
   */
  angular
    .module('helpers.sv')
    .directive('sv', sv);

  sv.$inject = ['Sv', '$uibModal', '$log', 'SvHelper'];

  function sv(Sv, $uibModal, $log, SvHelper) {
    return {
      restrict: 'E',
      scope: {},
      templateUrl: 'helpers/sv/sv-directive.tpl.html',
      replace: false,
      controllerAs: 'svCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'sv';
        vm.svlist = Sv.$search();

        vm.editSv = SvHelper.editSv;
        vm.addSv = addSv;
        vm.removeSv = removeSv;

        /* function editSv(lsv) {
          var dialog = $uibModal.open({
            template: '<div class="modalspacer"><sv-edit sv="miCtrl.sv" dialog="miCtrl.$uibModalInstance"></sv-edit></div>',
            controllerAs: 'miCtrl',
            controller: ['sv', '$uibModalInstance', function (ssv, $uibModalInstance) {
              var vmi = this;
              vmi.sv = ssv;
              vmi.$uibModalInstance = $uibModalInstance;
            }],
            resolve: {
              sv: function () {
                return lsv;
              },
              dialog: function () {
                return dialog;
              }
            },
            size: 'lg'
          });
        }*/

        function addSv() {
          var newsv = vm.svlist.$create();
          vm.editSv(newsv);
        }


/*
        function addSv() {
          var dialog = $uibModal.open({
            template: '<div class="modalspacer"><sv-edit doctor="miCtrl.sv" dialog="miCtrl.$uibModalInstance"></sv-edit></div>',
            controllerAs: 'miCtrl',
            controller: ['sv', '$uibModalInstance', function (lsv, $uibModalInstance) {
              var vmi = this;
              $log.debug('Lsv: ', lsv);
              vmi.sv = lsv;
              vmi.$uibModalInstance = $uibModalInstance;
            }],
            resolve: {
              sv: function () {
                return vm.svlist.$create();
              },
              dialog: function () {
                return dialog;
              }
            },
            size: 'lg'
          });


        }

      */

        function removeSv(tsv) {
          tsv.active = false;
          tsv.$save();
        }

      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name helpers.doctor
   * @description
   *
   */
  angular
    .module('helpers.doctor', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name helpers.doctor.directive:doctorSearch
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="helpers.doctor">
   <file name="index.html">
   <doctor-search doctorresult="" ti="?"></doctor-search>
   </file>
   </example>
   *
   */
  angular
    .module('helpers.doctor')
    .directive('doctorSearch', doctorSearch);

  doctorSearch.$inject = ['Doctorsearch', 'Doctor', '$uibModal', '$log'];

  function doctorSearch(Doctorsearch, Doctor, $uibModal, $log) {
    return {
      restrict: 'EA',
      scope: {
        doctorresult: '=',
        ti: '@?'
      },
      templateUrl: 'helpers/doctor/doctor-search-directive.tpl.html',
      replace: false,
      controllerAs: 'drsCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'doctorSearch';

        if (angular.isString(vm.ti)) {
          vm.tabi = parseInt(vm.ti, 10);
        }
        else {
          vm.tabi = 0;
        }
        
        vm.newdoc = {};
        vm.newdoc.foo = 'quux';

        vm.showdoctor = showdoctor;
        vm.getNames = getNames;
        vm.addDoctor = addDoctor;

        // typeahead doctor search backend query
        function getNames(val) {
          var tmp;
          return Doctorsearch.$search({query: val}).$asPromise().then(function (response) {
            // $log.debug('response: ', response);
            return response.map(function (doctor) {
              /* $log.debug('doctor:', doctor);
              $log.debug('alle: ', angular.isString(doctor.firstname) && angular.isString(doctor.lastname) && angular.isString(doctor.institute));
              $log.debug('nur doc: ', angular.isString(doctor.firstname) && angular.isString(doctor.lastname) && !angular.isString(doctor.institute));
              $log.debug('doc inst: ', !angular.isString(doctor.firstname) && angular.isString(doctor.lastname) && angular.isString(doctor.institute)
              );
              $log.debug('inst: ', !angular.isString(doctor.firstname) && !angular.isString(doctor.lastname) && angular.isString(doctor.institute)); */
              if (angular.isString(doctor.firstname) && angular.isString(doctor.lastname) && angular.isString(doctor.institute)) {
                doctor.text = doctor.firstname + ' ' + doctor.lastname + ', ' + doctor.institute + ', ' + doctor.speciality;
              }
              if (angular.isString(doctor.firstname) && angular.isString(doctor.lastname) && !angular.isString(doctor.institute)) {
                doctor.text = doctor.firstname + ' ' + doctor.lastname + ', ' + doctor.speciality;
              }
              if (!angular.isString(doctor.firstname) && angular.isString(doctor.lastname) && angular.isString(doctor.institute)) {
                doctor.text = doctor.lastname + ', ' + doctor.institute + ', ' + doctor.speciality;
              }
              if (!angular.isString(doctor.firstname) && !angular.isString(doctor.lastname) && angular.isString(doctor.institute)) {
                doctor.text = doctor.institute + ', ' + doctor.speciality;
              }

              return doctor;
            });
          });
        }


        // typeahead doctor search action
        function showdoctor($item, $model, $label) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          // vm.doctorresult = Doctor.$find($model._id);
          Doctor.$find($model._id).$asPromise().then(function (doc) {
            var property;
            // vm.doctorresult = doc;
            vm.doctorresult = {};
            vm.doctorresult._id = doc._id;
            vm.doctorresult.fname = doc.fname;
            vm.doctorresult.fulldoc = doc;
          });
          delete vm.newdoc.text;
        }


        function addDoctor() {
          var dialog = $uibModal.open({
            template: '<div class="modalspacer"><doctor-add returndoc="miCtrl.returndoc" dialog="miCtrl.$uibModalInstance"></doctor-add></div>',
            controllerAs: 'miCtrl',
            controller: ['returndoc', '$uibModalInstance', function (returndoc, $uibModalInstance) {
              var vmi = this;
              vmi.returndoc = returndoc;
              vmi.$uibModalInstance = $uibModalInstance;
              $log.debug('vmi: ', returndoc);
            }],
            resolve: {
              returndoc: function () {
                $log.debug('newdoc init: ', vm.newdoc);
                return vm.newdoc;
              },
              dialog: function () {
                $log.debug('dialog init: ', dialog);
                return dialog;
              }
            },
            size: 'lg'
          });

          dialog.result.finally(function () {
            $log.debug('new doc added: ', vm.newdoc);
            $log.debug('modal closed finally: ', vm.newdoc);
            vm.doctorresult = {};
            vm.doctorresult._id = vm.newdoc._id;
            vm.doctorresult.fname = vm.newdoc.fname;
            vm.asyncSelected = vm.newdoc.fname;
          });

        }
      }
    };
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('helpers.doctor')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('doctors', {
        url: '/doctors',
        views: {
          main: {templateUrl: 'helpers/doctor/doctor.tpl.html'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });



    // show modal for doctor new
    $stateProvider.state('doctornew', {
      url: '/doctornew',
      onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
        $uibModal.open({
          template: '<div class="modalspacer"><doctor-add></doctor-add></div>',
          size: 'lg'
        });
      }]
    });

  }

  // klammerfriedhof
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name helpers.doctor.factory:Doctor
   *
   * @description
   *
   */
  angular
    .module('helpers.doctor')
    .factory('Doctor', Doctor)
    .factory('Doctorcount', Doctorcount)
    .factory('Doctorpaged', Doctorpaged)
    .factory('Doctorsearch', Doctorsearch);

  Doctor.$inject = ['restmod'];
  function Doctor(restmod) {
    return restmod.model('/routes/doctors').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      titlefront: {init: 'Dr.'},
      active: {init: true}
    });
  }

  Doctorsearch.$inject = ['restmod'];
  function Doctorsearch(restmod) {
    return restmod.model('/routes/doctors/docahead');
  }

  Doctorcount.$inject = ['restmod'];
  function Doctorcount(restmod) {
    return restmod.model('/routes/doctors/count');
  }


  Doctorpaged.$inject = ['restmod'];
  function Doctorpaged(restmod) {
    return restmod.model('/routes/doctors/pagedlist');
  }


}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name helpers.doctor.directive:doctorEdit
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="helpers.doctor">
       <file name="index.html">
        <doctor-edit></doctor-edit>
       </file>
     </example>
   *
   */
  angular
    .module('helpers.doctor')
    .directive('doctorEdit', doctorEdit);


  function doctorEdit() {
    return {
      restrict: 'EA',
      scope: {
        doctor: '=',
        dialog: '='
      },
      templateUrl: 'helpers/doctor/doctor-edit-directive.tpl.html',
      replace: false,
      controllerAs: 'dreCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'doctorEdit';
        vm.save = save;
        vm.close = close;

        function save() {
          if (angular.isString(vm.doctor.lastname) && !angular.isString(vm.doctor.institute)) {
            vm.doctor.fname = vm.doctor.titlefront + ' ' + vm.doctor.firstname + ' ' + vm.doctor.lastname + ', ' + vm.doctor.city;
          }
          if (!angular.isString(vm.doctor.lastname) && angular.isString(vm.doctor.institute)) {
            vm.doctor.fname = vm.doctor.institute + ', ' + vm.doctor.city;
          }
          if (angular.isString(vm.doctor.lastname) && angular.isString(vm.doctor.institute)) {
            vm.doctor.fname = vm.doctor.institute + ', ' + vm.doctor.titlefront + ' ' + vm.doctor.lastname + ', ' + vm.doctor.city;
          }

          vm.doctor.$save();
        }


        function close() {
          vm.dialog.close();
        }
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */

        scope.$watch('dreCtrl.doctor.zip', function () {
          ctrl.save();
        }, true);

        scope.$watch('dreCtrl.doctor.city', function () {
          ctrl.save();
        }, true);

      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name helpers.doctor.directive:doctor
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="helpers.doctor">
       <file name="index.html">
        <doctor></doctor>
       </file>
     </example>
   *
   */
  angular
    .module('helpers.doctor')
    .directive('doctors', doctors);

  doctors.$inject = ['Doctorcount', 'Doctorpaged', 'Doctor', '$uibModal', '$log'];

  function doctors(Doctorcount, Doctorpaged, Doctor, $uibModal, $log) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'helpers/doctor/doctor-directive.tpl.html',
      replace: false,
      controllerAs: 'drCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'doctor';
        vm.editDoctor = editDoctor;
        vm.addDoctor = addDoctor;
        vm.removeDoctor = removeDoctor;
        vm.pageChanged = pageChanged;
        vm.itemspp = 10;

        vm.doctorlist = Doctorpaged.$search();
        Doctorcount.$search().$asPromise().then(function (n) {
          console.log('n: ', n);
          vm.totalItems = n[0].count;
        });

        function pageChanged() {
          vm.doctorlist.$refresh({page: vm.currentPage});
        }


        function editDoctor(doctor) {
          var dialog = $uibModal.open({
            template: '<div class="modalspacer"><doctor-edit doctor="miCtrl.doctor" dialog="miCtrl.$uibModalInstance"></doctor-edit></div>',
            controllerAs: 'miCtrl',
            controller: ['doctor', '$uibModalInstance', function (ldoctor, $uibModalInstance) {
              var vmi = this;
              vmi.doctor = ldoctor;
              vmi.$uibModalInstance = $uibModalInstance;
            }],
            resolve: {
              doctor: function () {
                return doctor;
              },
              dialog: function () {
                return dialog;
              }
            },
            size: 'lg'
          });
        }


        function addDoctor() {
          var dialog = $uibModal.open({
            template: '<div class="modalspacer"><doctor-edit doctor="miCtrl.doctor" dialog="miCtrl.$uibModalInstance"></doctor-edit></div>',
            controllerAs: 'miCtrl',
            controller: ['doctor', '$uibModalInstance', function (ldoctor, $uibModalInstance) {
              var vmi = this;
              vmi.doctor = ldoctor;
              vmi.$uibModalInstance = $uibModalInstance;
            }],
            resolve: {
              doctor: function () {
                // return vm.doctorlist.$create();  // does not work - no $create on pagedDoctors!
                return Doctor.$create();
              },
              dialog: function () {
                return dialog;
              }
            },
            size: 'lg'
          });

          dialog.result.then(function () {
            vm.doctorlist.$refresh();
          }, function () {});
        }

        function removeDoctor(doctor) {
          doctor.active = false;
          doctor.$save();
          pageChanged();
        }

      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name helpers.doctor.directive:doctorAdd
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="helpers.doctor">
       <file name="index.html">
        <doctor-add></doctor-add>
       </file>
     </example>
   *
   */
  angular
    .module('helpers.doctor')
    .directive('doctorAdd', doctorAdd);

  doctorAdd.$inject = ['Doctor', '$state', '$log'];


  function doctorAdd(Doctor, $state, $log) {
    return {
      restrict: 'EA',
      scope: {
        returndoc: '=?',
        dialog: '=?'
      },
      templateUrl: 'helpers/doctor/doctor-add-directive.tpl.html',
      replace: false,
      controllerAs: 'draCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'doctorAdd';
        vm.newdoctor = {};
        vm.addDoctor = addDoctor;

        $log.debug(vm.returndoc);
        function addDoctor() {
          if (angular.isString(vm.newdoctor.lastname) && !angular.isString(vm.newdoctor.institute)) {
            vm.newdoctor.fname = vm.newdoctor.titlefront + ' ' + vm.newdoctor.firstname + ' ' + vm.newdoctor.lastname + ', ' + vm.newdoctor.city;
          }
          if (!angular.isString(vm.newdoctor.lastname) && angular.isString(vm.newdoctor.institute)) {
            vm.newdoctor.fname = vm.newdoctor.institute + ', ' + vm.newdoctor.city;
          }
          if (angular.isString(vm.newdoctor.lastname) && angular.isString(vm.newdoctor.institute)) {
            vm.newdoctor.fname = vm.newdoctor.institute + ', ' + vm.newdoctor.titlefront + ' ' + vm.newdoctor.lastname + ', ' + vm.newdoctor.city;
          }
          // vm.newdoctor.fname = vm.newdoctor.titlefront + ' ' + vm.newdoctor.firstname + ' ' + vm.newdoctor.lastname + ', ' + vm.newdoctor.city;
          Doctor.$create(vm.newdoctor).$asPromise().then(function (doc) {
            vm.returndoc._id = doc._id;
            vm.returndoc.fname = doc.fname;
            $log.debug('freshly created doctor: ', vm.returndoc);
            if (angular.isObject(vm.dialog)) {
              vm.dialog.close();
            }
          });
          vm.newdoctor = {};
          /* $log.debug('previous state: ', $state.previousState);
          if ($state.previousState.name !== '') {
            $state.go($state.previousState);
          } */
        }
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name turnover
   * @description
   *
   */
  angular
    .module('finance.turnover', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name turnover.factory:Turnover
   *
   * @description
   *
   */
  angular
    .module('finance.turnover')
    .factory('Turnover', Turnover);

  Turnover.$inject = ['restmod'];

  function Turnover(restmod) {
    return restmod.model('/routes/sales');
  }

}());

(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name turnover.directive:turnover
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="turnover">
   <file name="index.html">
   <turnover></turnover>
   </file>
   </example>
   *
   */
  angular
    .module('finance.turnover')
    .directive('turnover', turnover);
  
  turnover.$inject = ['Turnover', '$http', '$log'];
  
  function turnover(Turnover, $http, $log) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'finance/turnover/turnover-directive.tpl.html',
      replace: false,
      controllerAs: 'toCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        var monthlyquerycash;
        var monthlyquerycheck;
        var yearlyquerycash;
        var yearlyquerycheck;
        
        var thisquarterquerycash;
        var thisquarterquerycheck;
        var thismonthquerycash;
        var thismonthquerycheck;
        var thisyearquerycash;
        var thisyearquerycheck;
        
        
        // set default view on page open
        vm.view = 'summary';
        
        vm.today = moment().locale('de-at').format('ll');
        // vm.thisday = moment().format('YYYY-MM-DD');
        vm.thisday = moment().format('YYYY-MM-DD').toString();
        vm.thismonth = moment().format('YYYY-MM').toString();
        vm.thisyear = moment().format('YYYY').toString();
        
        // NOTE TO SELF: Statistics with Stornos and Discounts is REALLY tricky!!!!!!
        // please note the following
        
        // inv.netto = sum of net prices
        // inv.totalbefore = brutto sum before discount
        // inv.tax = tax sum before discount
        // inv.taxdiscounted = discounted tax sum
        // inv.nettodiscounted = nett sum discounted, also set when discount was 0
        // inv.discounttaxsum = array with taxes per steuersatz, post discount, also set when discount was 0
        
        // some fun new queries to cashinvoices
        // [{$match: {test:false}},{$group:{_id: {year: {$substr:['$date',0,4]},month: {$substr: ['$date',5,2]} },count: {$sum:1}, monthlytotal: {$sum: '$total'}, monthlytotalstorno: {$sum: {$cond:[{$eq: ['$storno', true]},'$total', 0]}}   }}]
        // moment().startOf('quarter')
        
        // TODO: before you roll out this feature, migrate client databases to add missing nettodiscounted, etc fields
        
        
        // this month, quarter, year statss
        vm.thisyear = {};
        
        function mongomatchstartend(start, end) {
          return {
            $match: {
              $and: [
                {date: {$gt: start}},
                {date: {$lt: end}},
                {test: false}
              ]
            }
          };
        }
        
        function mongogenblock(type, id) {
          var specficquery = {};
          var groupid = {};
          if (id === 'month') {
            groupid = {
              year: {$substr: ['$date', 0, 4]},
              month: {$substr: ['$date', 5, 2]}
            };
          }
          else if (id === 'year') {
            groupid = {
              year: {$substr: ['$date', 0, 4]}
            };
          }
          else {
            groupid = 1;
          }
          
          
          if (type === 'cash') {
            specficquery = {
              $group: {
                _id: groupid, totalcount: {$sum: 1},
                invcount: {$sum: {$cond: [{$eq: ['$storno', false]}, 1, 0]}},
                stornocount: {$sum: {$cond: [{$eq: ['$storno', true]}, 1, 0]}},
                total: {$sum: {$cond: [{$eq: ['$storno', false]}, '$total', 0]}},
                netto: {$sum: {$cond: [{$eq: ['$storno', false]}, '$netto', 0]}},
                nettodiscounted: {$sum: {$cond: [{$eq: ['$storno', false]}, '$nettodiscounted', 0]}},
                discount: {$sum: {$cond: [{$eq: ['$storno', false]}, '$discount', 0]}},
                tax: {$sum: {$cond: [{$eq: ['$storno', false]}, '$tax', 0]}},
                taxdiscounted: {$sum: {$cond: [{$eq: ['$storno', false]}, '$taxdiscounted', 0]}},
                storno: {$sum: {$cond: [{$eq: ['$storno', true]}, '$total', 0]}},
                avg: {$avg: {$cond: [{$eq: ['$storno', false]}, '$total', 0]}},
                stddev: {$stdDevPop: {$cond: [{$eq: ['$storno', false]}, '$total', 0]}},
                max: {$max: {$cond: [{$eq: ['$storno', false]}, '$total', 0]}},
                min: {$min: {$cond: [{$eq: ['$storno', false]}, '$total', 0]}},
                cash: {
                  $sum: {
                    $cond: [
                      {
                        $and: [
                          {$eq: ['$storno', false]},
                          {$eq: ['$paytype', 'cash']}
                        ]
                      }, '$total', 0]
                  }
                },
                card: {
                  $sum: {
                    $cond: [
                      {
                        $and: [
                          {$eq: ['$storno', false]},
                          {$eq: ['$paytype', 'card']}
                        ]
                      }, '$total', 0]
                  }
                }
              }
            };
          }
          else if (type === 'check') {
            specficquery = {
              $group: {
                _id: groupid, totalcount: {$sum: 1},
                invcount: {$sum: {$cond: [{$eq: ['$storno', false]}, 1, 0]}},
                stornocount: {$sum: {$cond: [{$eq: ['$storno', true]}, 1, 0]}},
                total: {$sum: {$cond: [{$eq: ['$storno', false]}, '$total', 0]}},
                netto: {$sum: {$cond: [{$eq: ['$storno', false]}, '$netto', 0]}},
                nettodiscounted: {$sum: {$cond: [{$eq: ['$storno', false]}, '$nettodiscounted', 0]}},
                discount: {$sum: {$cond: [{$eq: ['$storno', false]}, '$discount', 0]}},
                tax: {$sum: {$cond: [{$eq: ['$storno', false]}, '$tax', 0]}},
                taxdiscounted: {$sum: {$cond: [{$eq: ['$storno', false]}, '$taxdiscounted', 0]}},
                storno: {$sum: {$cond: [{$eq: ['$storno', true]}, '$total', 0]}},
                avg: {$avg: {$cond: [{$eq: ['$storno', false]}, '$total', 0]}},
                stddev: {$stdDevPop: {$cond: [{$eq: ['$storno', false]}, '$total', 0]}},
                max: {$max: {$cond: [{$eq: ['$storno', false]}, '$total', 0]}},
                min: {$min: {$cond: [{$eq: ['$storno', false]}, '$total', 0]}},
                open: {
                  $sum: {
                    $cond: [
                      {
                        $and: [
                          {$eq: ['$storno', false]},
                          {$eq: ['$paid', false]}
                        ]
                      }, '$total', 0]
                  }
                },
                paid: {
                  $sum: {
                    $cond: [
                      {
                        $and: [
                          {$eq: ['$storno', false]},
                          {$eq: ['$paid', true]}
                        ]
                      }, '$total', 0]
                  }
                }
              }
            };
          }
          
          return specficquery;
        }
        
        thismonthquerycash = [mongomatchstartend(moment().startOf('month').toISOString(), moment().add(1, 'days').toISOString()),
          mongogenblock('cash', 'this')];
        thismonthquerycheck = [mongomatchstartend(moment().startOf('month').toISOString(), moment().add(1, 'days').toISOString()),
          mongogenblock('check', 'this')];
        
        thisquarterquerycash = [mongomatchstartend(moment().startOf('quarter').toISOString(), moment().add(1, 'days').toISOString()),
          mongogenblock('cash', 'this')];
        thisquarterquerycheck = [mongomatchstartend(moment().startOf('quarter').toISOString(), moment().add(1, 'days').toISOString()),
          mongogenblock('check', 'this')];
        
        thisyearquerycash = [mongomatchstartend(moment().startOf('year').toISOString(), moment().add(1, 'days').toISOString()),
          mongogenblock('cash', 'this')];
        thisyearquerycheck = [mongomatchstartend(moment().startOf('year').toISOString(), moment().add(1, 'days').toISOString()),
          mongogenblock('check', 'this')];
        
     
        
        
        
        // cashinvoices this month
        $http.post('/routes/cashinvoices/aggregate', thismonthquerycash).then(
          function success(response) {
            if (angular.isArray(response.data) && response.data.length > 0) {
              vm.thisyear.monthcashstats = response.data[0];
            }
            else {
              vm.thisyear.monthcashstats = 0;
            }
          },
          function error(response) {
            $log.debug('thismonthquerycash Aggregate failed: ', response);
          });
        
        // checkinvoices this month
        $http.post('/routes/checkinvoices/aggregate', thismonthquerycheck).then(
          function success(response) {
            if (angular.isArray(response.data) && response.data.length > 0) {
              vm.thisyear.monthcheckstats = response.data[0];
            }
            else {
              vm.thisyear.monthcheckstats = 0;
            }
          },
          function error(response) {
            $log.debug('thismonthquerycheck Aggregate failed: ', response);
          });
        
        // cashinvoices this quarter
        $http.post('/routes/cashinvoices/aggregate', thisquarterquerycash).then(
          function success(response) {
            if (angular.isArray(response.data) && response.data.length > 0) {
              vm.thisyear.quartercashstats = response.data[0];
            }
            else {
              vm.thisyear.quartercashstats = 0;
            }
          },
          function error(response) {
            $log.debug('thisquarterquerycash Aggregate failed: ', response);
          });
        
        // checkinvoices this quarter
        $http.post('/routes/checkinvoices/aggregate', thisquarterquerycheck).then(
          function success(response) {
            if (angular.isArray(response.data) && response.data.length > 0) {
              vm.thisyear.quartercheckstats = response.data[0];
            }
            else {
              vm.thisyear.quartercheckstats = 0;
            }
          },
          function error(response) {
            $log.debug('thisquarterquerycheck Aggregate failed: ', response);
          });
        
        // cashinvoices this year
        $http.post('/routes/cashinvoices/aggregate', thisyearquerycash).then(
          function success(response) {
            if (angular.isArray(response.data) && response.data.length > 0) {
              vm.thisyear.yearcashstats = response.data[0];
            }
            else {
              vm.thisyear.yearcashstats = 0;
            }
          },
          function error(response) {
            $log.debug('thisyearquerycash Aggregate failed: ', response);
          });
        
        // checkinvoices this year
        $http.post('/routes/checkinvoices/aggregate', thisyearquerycheck).then(
          function success(response) {
            if (angular.isArray(response.data) && response.data.length > 0) {
              vm.thisyear.yearcheckstats = response.data[0];
            }
            else {
              vm.thisyear.yearcheckstats = 0;
            }
          },
          function error(response) {
            $log.debug('thisyearquerycheck Aggregate failed: ', response);
          });
        
        
        // monthly stats for the whole business period
        
        monthlyquerycash = [{$match: {test: false}}, mongogenblock('cash', 'month')];
        monthlyquerycheck = [{$match: {test: false}}, mongogenblock('check', 'month')];
      
        
        // cashinvoices per month
        $http.post('/routes/cashinvoices/aggregate', monthlyquerycash).then(
          function success(response) {
            if (angular.isArray(response.data) && response.data.length > 0) {
              vm.monthlycashstats = response.data;
              $log.debug('monthlycashstats: ', vm.monthlycashstats);
            }
            else {
              vm.monthlycashstats = 0;
            }
          },
          function error(response) {
            $log.debug('monthlychash Aggregate failed: ', response);
          });
        
        // checkinvoices per month
        $http.post('/routes/checkinvoices/aggregate', monthlyquerycheck).then(
          function success(response) {
            if (angular.isArray(response.data) && response.data.length > 0) {
              vm.monthlycheckstats = response.data;
              $log.debug('monthlycheckstats: ', vm.monthlycheckstats);
            }
            else {
              vm.monthlycheckstats = 0;
            }
          },
          function error(response) {
            $log.debug('monthlyquerycheck Aggregate failed: ', response);
          });
        
        // yearly stats for the whole business period
        yearlyquerycash = [{$match: {test: false}}, mongogenblock('cash', 'year')];
        yearlyquerycheck = [{$match: {test: false}}, mongogenblock('check', 'year')];
        
        // chashinvoices per year
        $http.post('/routes/cashinvoices/aggregate', yearlyquerycash).then(
          function success(response) {
            if (angular.isArray(response.data) && response.data.length > 0) {
              vm.yearlycashstats = response.data;
              $log.debug('yearlycashstats: ', vm.yearlycashstats);
            }
            else {
              vm.yearlycashstats = 0;
            }
          },
          function error(response) {
            $log.debug('yearlycashstats Aggregate failed: ', response);
          });
        
        // Checkinvoices per year
        $http.post('/routes/checkinvoices/aggregate', yearlyquerycheck).then(
          function success(response) {
            if (angular.isArray(response.data) && response.data.length > 0) {
              vm.yearlycheckstats = response.data;
              $log.debug('yearlycheckstats: ', vm.yearlycheckstats);
            }
            else {
              vm.yearlycheckstats = 0;
            }
          },
          function error(response) {
            $log.debug('yearlycheckstats Aggregate failed: ', response);
          });
        
      } // end controller
      
      
      // how to sum up money in mongodb
      // db.checkinvoices.aggregate([{$group: {_id:null, grandtotal: {$sum: "$total"}}}])
      // paid invoices
      // db.checkinvoices.aggregate({$match:{ $and: [{storno: false}, {paid: true}]}}, {$group: { _id:null, totalpaid: { $sum: "$total"}}})
      // open invoices
      // db.checkinvoices.aggregate({$match:{ $and: [{storno: false}, {paid: false}]}}, {$group: { _id:null, totalopen: { $sum: "$total"}}})
    };
  }

// klammerfriedhof
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name bmfexport
   * @description
   *
   */
  angular
    .module('finance.rksv', [
      'ui.router',
      'ui.bootstrap'
    ]);
}());

/**
 * Created by lari on 04/03/2017.
 */

(function () {
  'use strict';
  /**
   * @ngdoc directive
   * @name config.component:todowidget
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="todo">
   <file name="index.html">
   <rksv-zero></rksv-zero>
   </file>
   </example>
   *
   */
  
  
  angular
    .module('finance.rksv')
    .component('rksvZero', {
      restrict: 'E',
      template: '<h2>Null-Beleg, Signatur über ausgefallene Signaturen</h2>' +
      '<button class="btn btn-primary" ng-click="vm.createNullBeleg()">Null-Beleg erstellen</button>' +
      '<div class="alert alert-{{vm.status || info}} spacer-top" ng-hide="vm.status === \'init\'">' +
      '<h3>{{vm.message}}</h3>' +
      '<p ng-show="vm.pdfid"><a href="/routes/pdfreader/{{vm.pdfid}}" target="_blank">PDF anzeigen</a></p>' +
      '<p ng-show="vm.hint">{{vm.hint}}</p>' +
      '</div>',
      controllerAs: 'vm',
      controller: ['$log', '$http', 'RksvHelper',
        function ($log, $http, RksvHelper) {
          var vm = this;
          vm.status = 'init';
          vm.createNullBeleg = createNullBeleg;
          
          $log.debug('RKSV zero called');
          
          function createNullBeleg() {
            var invoiceobj = RksvHelper.getNullBeleg();
            
            $http.post('/routes/rksvzero', invoiceobj).then(function (res) {
              vm.status = 'success';
              vm.message = 'Erstellung des Nullbelegs ' + res.data.nbnum + ' war erfolgreich';
              vm.pdfid = res.data.pdfid;
              vm.hint = 'QR Code: ' + res.data.qrcode;
              $log.debug('RKSV zero record sent successfully');
            }, function (res) {
              $log.error('RKSV zero record failed');
              vm.status = 'danger';
              vm.message = 'Erstellung des Nullbelegs ist fehlgeschlagen';
              vm.hint = '';
            });
            
          }
          
        }]
    });
  
  // klammerfriedhof
}());

/**
 * Created by lari on 04/03/2017.
 */

(function () {
  'use strict';
  /**
   * @ngdoc directive
   * @name config.component:todowidget
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="todo">
   <file name="index.html">
   <rksv-init></rksv-init>
   </file>
   </example>
   *
   */
  
  // var todowidget;
  
  angular
    .module('finance.rksv')
    .component('rksvInit', {
      restrict: 'E',
      // templateUrl: 'config/todowidget-directive.tpl.html',
      template: '<h2>Startbeleg</h2>' +
      '<button class="btn btn-primary" ng-click="vm.createStartBeleg()">Start-Beleg erstellen</button>' +
      '<div class="alert alert-{{vm.status || info}} spacer-top" ng-hide="vm.status === \'init\'">' +
      '<h3>{{vm.message}}</h3>' +
      '<p ng-show="vm.pdfid"><a href="/routes/pdfreader/{{vm.pdfid}}" target="_blank">PDF anzeigen</a></p>' +
      '<p ng-show="vm.hint">{{vm.hint}}</p>' +
      '</div>',
      controllerAs: 'vm',
      controller: ['$log', '$http', 'RksvHelper',
        function ($log, $http, RksvHelper) {
          var vm = this;
          vm.status = 'init';
          vm.createStartBeleg = createStartBeleg;
          
          $log.debug('RKSV Init called');
          
          function createStartBeleg() {
            var invoiceobj = RksvHelper.getNullBeleg();
  
            $http.post('/routes/rksvinit', invoiceobj).then(function (res) {
              vm.status = 'success';
              vm.message = 'Erstellung des Startbelegs ' + res.data.nbnum + ' war erfolgreich';
              vm.pdfid = res.data.pdfid;
              vm.hint = 'QR Code: ' + res.data.qrcode;
              $log.debug('RKSV init record sent successfully');
            }, function (res) {
              $log.error('RKSV init record failed');
              vm.status = 'danger';
              vm.message = 'Erstellung des Startbelegs ist fehlgeschlagen';
              vm.hint = 'Hinweis: Der Startbeleg kann nur ein mal erstellt werden!';
            });
  
          }
          
        }]
    });
  
  // klammerfriedhof
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name config.factory:RksvHelper
   *
   * @description
   *
   */
  angular
    .module('finance.rksv')
    .factory('RksvHelper', RksvHelper);

  function RksvHelper() {
    var RksvHelperBase = {};
    
    
    
    function getNullBeleg() {
      var invoice = {};
      var invoiceobj = {};
      var patient = {
        _id: '123412341234123412341234',
        firstname: 'Maximilian',
        lastname: 'Mustermann'
      };
    
      invoice.date = moment().toISOString();
      invoice.fdate = moment(invoice.date).locale('de-at').format('ll');
      invoice.ftime = moment(invoice.date).locale('de-at').format('LTS');
    
      invoice.storno = false;
      invoice.test = false;
    
      // invoice.items = vm.patient.openConsult.service.items;
      // get rid of unselected excess items that clutter up the database
      invoice.items = [
        {
          _id: '56ae72d68dd4bd59ac0423af',
          created: '2016-02-09T21:21:30.978Z',
          active: true,
          cat: '0',
          group: '0',
          num: '00',
          name: 'Nullleistung',
          opoints: 0,
          points: 0,
          tax: 0,
          comment: 'Nullbeleg für Finanzamt',
          price: 0.0,
          include: true,
          selected: true
        }
      ];
    
      invoice.netto = 0.00;
      invoice.totalbefore = 0.00;
      invoice.nettodiscounted = 0.00;
      invoice.bruttodiscounted = 0.00;
      invoice.taxdiscounted = 0.00;
      invoice.discount = 0.00;
      invoice.tax = 0.00;
      // discounttaxsum is actually an array with the accumulated tax per tax bracket
      // discounttaxsum kept for backward compatibility, new type is discounttaxarray
      invoice.discounttaxsum = {'0': 0.00};
      invoice.discounttaxarray = {'0': 0.00};
      invoice.discountnettoarray = {'0': 0.00};
      invoice.discountbruttoarray = {'0': 0.00};
      invoice.total = 0.00;
      invoice.invoicediagnoses = 'foo';
      // invoice.consultid = vm.patient.openConsult._id;
    
      invoice.paytype = 'cash';
      invoice.paid = true;
      invoice.type = 'Barrechnung';
      invoice.cash = true;
      invoice.paiddate = invoice.date;
      invoiceobj.patient = patient;
      invoiceobj.patient.openConsult = {};
      invoiceobj.patient.openConsult.invoice = invoice;
    
      invoiceobj.patientid = patient._id;
      invoiceobj.consultid = 'nullbeleg';
      invoiceobj.rootelement = 'patient';
      
      return invoiceobj;
    }
    
    
    RksvHelperBase.getNullBeleg = getNullBeleg;
    return RksvHelperBase;
  }
}());

/**
 * Created by lari on 17/04/2017.
 */
(function () {
  'use strict';
  angular.module('finance.rksv')
    .component('deplist', {
      restrict: 'E',
      templateUrl: 'finance/rksv/deplist-component.tpl.html',
      controllerAs: 'vm',
      controller: ['$log', 'Deplist',
        function ($log, Deplist) {
          var vm = this;
          vm.currentPage = 1;
          vm.pageChanged = pageChanged;
  
  
          this.$onInit = function () {
            var search = {};
  
            search.page = vm.currentpage;
            search.query = {};
  
            search.options = {};
            search.options.fields = {
              _id: 1,
              created: 1,
              receiptnr: 1,
              type: 1,
              signatureok: 1,
              pdfid: 1,
              storno: 1
            };
  
            vm.dep = Deplist.$search(search).$then(function (dep) {
              vm.totalItems = dep.$pageCount;
            });
          };
          
          function pageChanged() {
            vm.dep.$refresh({page: vm.currentPage}).$then(function (dep) {
              vm.totalItems = dep.$pageCount;
            });
          }
          
        }] // controller
    }); // component

// klammerfriedhof
}());
/**
 * Created by lari on 31/03/2017.
 */


(function () {
  'use strict';
  /**
   * @ngdoc directive
   * @name finance.rksv.component:depexport
   * @restrict E
   * @element
   *
   * @description
   *
   * @example
   <example module="finance.rksv">
   <file name="index.html">
   <depexport></depexport>
   </file>
   </example>
   *
   */
  
  angular
    .module('finance.rksv')
    .component('depexport', {
      restrict: 'E',
      templateUrl: 'finance/rksv/depexport-component.tpl.html',
      controllerAs: 'vm',
      controller: ['$log', '$http',
        function ($log, $http) {
          var vm = this;
          vm.status = 'init';
          vm.exportDEP = exportDEP;
          vm.thisyear = thisyear;
          vm.allyears = allyears;
          vm.startdate = new Date(moment().year(), 0, 1).toISOString();
          $log.debug('Startdate: ', vm.startdate);
          // vm.enddate = new Date().toISOString();
          vm.enddate = moment().endOf('day').toISOString();
          vm.dateOptions = {
            formatYear: 'yyyy',
            startingDay: 1
          };
  
          vm.altInputFormats = ['yyyy-MM-dd', 'dd.MM.yyyy'];
  
  
          function thisyear() {
            vm.startdate = moment().startOf('year').toISOString();
            vm.enddate = moment().endOf('year').toISOString();
            exportDEP();
          }
          
          function allyears() {
            vm.startdate = moment('2017-01-01 00:00:00').toISOString();
            vm.enddate = moment().toISOString();
            exportDEP();
          }
          
          
          function exportDEP() {

            var timerange = {
              $gte: vm.startdate,
              $lt: vm.enddate
            };
            
            var query = {};
            query.created = timerange;
            
            $http.post('/routes/depexport', query).then(function (res) {
              var anchor = angular.element('<a/>');
              $log.debug('res data: ', res);
              $log.debug('data string: ', res.data.toString());
              anchor.attr({
                href: 'data:attachment/json;charset=utf-8,' + encodeURI(angular.toJson(res.data, true)),
                target: '_blank',
                download: 'depexport.json'
              })[0].click();
              vm.status = 'success';
              vm.message = 'DEP Export war erfolgreich';
              
              $log.debug('RKSV zero record sent successfully');
            }, function () {
              $log.error('DEP Export failed');
              vm.status = 'danger';
              vm.message = 'DEP Export ist fehlgeschlagen';
            });
            
          }
          
        }]
    });
  
  // klammerfriedhof
}());

(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name finance.rksv.factory:finance.rksvService
   *
   * @description
   *
   */
  angular
    .module('finance.rksv')
    .factory('Deplist', Deplist)
    .config(config);

  config.$inject = ['restmodProvider'];

  function config(restmodProvider) {
    restmodProvider.rebase({
      $config: {
        primaryKey: '_id'
      }
    });
  }


  Deplist.$inject = ['restmod'];

  function Deplist(restmod) {
    return restmod.model('/routes/rksvdep', 'PagedModel');
  }

// klammerfriedhof
}());
(function () {
  'use strict';

  /* @ngdoc object
   * @name invoice
   * @description
   *
   */
  angular
    .module('finance.invoice', [
      'ui.router',
      'smart-table',
      'utils.filereader',
      'ui.bootstrap'
    ])
    .filter('bezahlt', function () {
      return function (input) {
        return input ? 'bezahlt' : 'offen';
      };
    })
    .filter('gemahnt', function () {
      return function (input) {
        return input ? 'gemahnt' : 'nicht gemahnt';
      };
    });

  // klammerfriedhof
}());

(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name invoice.directive:invoicebrowser
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="invoice">
   <file name="index.html">
   <invoicebrowser></invoicebrowser>
   </file>
   </example>
   *
   */
  angular
    .module('finance.invoice')
    .directive('invoicebrowser', invoicebrowser);
  
  
  function invoicebrowser() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'finance/invoice/invoicebrowser-directive.tpl.html',
      replace: false,
      controllerAs: 'invCtrl',
      bindToController: true,
      controller: function () {
      // this is an empty controller!
        
      } // controller
    };
  } // directive
  
  // klammerfriedhof
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name checkinvoice.factory:Checkinvoice
   *
   * @description
   *
   */
  angular
    .module('finance.invoice')
    .factory('Checkinvoice', Checkinvoice);

  Checkinvoice.$inject = ['restmod'];

  function Checkinvoice(restmod) {
    return restmod.model('/routes/checkinvoices', 'PagedModel').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      }
    });
  }
}());

/**
 * Created by lari on 07/04/2017.
 */
(function () {
  'use strict';
  angular.module('finance.invoice')
    .component('checkinvoice', {
      restrict: 'E',
      templateUrl: 'finance/invoice/checkinvoice-component.tpl.html',
      controllerAs: 'vm',
      bindings: {
        mode: '@'
      },
      controller: ['Checkinvoice', '$log', '$http', '$uibModal',
        function (Checkinvoice, $log, $http, $uibModal) {
          var vm = this;
          
          this.$onInit = function () {
            var search = {};
  
            vm.currentpage = 1;
  
            vm.invdatetpl = 'finance/invoice/invoiceDateTpl.html';
            
            vm.pageChanged = pageChanged;
            vm.storno = storno;
            vm.sendReminder = sendReminder;
            vm.enterPaidDate = enterPaidDate;
            
            search.page = vm.currentpage;
            search.query = {};
            
            switch (vm.mode) {
              case 'showstorno':
                search.query.storno = true;
                vm.title = 'Stornierte Zielrechnungen';
                break;
              case 'hidestorno':
                search.query.storno = false;
                vm.title = 'Unstornierte Zielrechnungen';
                break;
              case 'showstest':
                search.query.test = true;
                vm.title = 'Test-Zielrechnungen';
                break;
              case 'hidetest':
                search.query.test = false;
                vm.title = 'Zielrechnungen (ohne Testrechnungen)';
                break;
              case 'open':
                search.query.paid = false;
                search.query.test = false;
                search.query.storno = false;
                vm.title = 'Offene Zielrechnungen';
                break;
              case 'paid':
                search.query.paid = true;
                search.query.test = false;
                search.query.storno = false;
                vm.title = 'Bezahlte Zielrechnungen';
                break;
              case 'overdue':
                search.query.paid = false;
                search.query.test = false;
                search.query.storno = false;
                search.query.date = {$lt: moment().subtract(15, 'days').toISOString()};
                vm.title = 'Überfällige Zielrechnungen';
                break;
              default:
                vm.title = 'Zielrechnungen';
                break;
            }
            
            search.options = {};
            search.options.fields = {
              _id: 1,
              invoicenr: 1,
              date: 1,
              fdate: 1,
              total: 1,
              tax: 1,
              storno: 1,
              test: 1,
              paytype: 1,
              patientinvoiceid: 1,
              doctorinvoiceid: 1,
              receiptid: 1,
              stornoreceiptid: 1,
              patientid: 1,
              paiddate: 1,
              fpaiddate: 1,
              duedate: 1,
              paid: 1
            };
            
            vm.checkinvoices = Checkinvoice.$search(search).$then(function (inv) {
              vm.totalItems = inv.$pageCount;
            });
            
          }; // $onInit
          
          
          function pageChanged() {
            vm.checkinvoices.$refresh({page: vm.currentPage});
          }
          
          function storno(invoice) {
            $log.debug('storno invoice: ', invoice);
            invoice.$destroy().$then(function () {
              vm.checkinvoices.$refresh().$then(function (inv) {
                vm.totalItems = inv.$pageCount;
              });
            });
          }
  
          function enterPaidDate(invoice) {
            $uibModal.open({
              templateUrl: 'finance/invoice/invoiceDateTpl.html',
              controllerAs: 'miCtrl',
              controller: ['invoice', '$uibModalInstance', function (inv, $uibModalInstance) {
                var vmi = this;
                vmi.invoice = inv;
                vmi.dialog = $uibModalInstance;
        
                // TODO: options are completyl ignored, figure out how to enable maxDate
                vmi.dateoptions = {
                  maxDate: new Date(),
                  showWeeks: true
                };
                vmi.setDate = setDate;
        
                function setDate() {
                  vmi.invoice.paiddate = moment(vmi.paiddate).toISOString();
                  vmi.invoice.fpaiddate = moment(vmi.paiddate).locale('de-at').format('ll');
                  vmi.invoice.paid = true;
                  // $log.debug('Paiddate: ', vmi.paiddate);
                  // $log.debug('Invoice Paiddate: ', vmi.invoice.paiddate);
                  $log.debug('Invoice FPaiddate: ', vmi.invoice.fpaiddate);
                  vmi.invoice.$save();
                  vmi.dialog.close();
                }
              }],
              resolve: {
                invoice: function () {
                  return invoice;
                }
              },
              size: 'md'
            });
          }
          
          // TODO: this does not work yet
          function sendReminder(invoice) {
            invoice.reminded = true;
            invoice.remindeddate = moment().toISOString();
            invoice.fremindeddate = moment().locale('de-at').format('ll');
    
            $http({
              url: '/routes/pdfprinter/consultprint',
              method: 'GET',
              params: {
                patientid: invoice.patientid,
                consultid: invoice.consultid,
                doctype: 'reminder'
              }
            }).then(
              function success(r) {
                if (!angular.isArray(invoice.reminders)) {
                  invoice.reminders = [];
                }
                invoice.reminders.push({date: invoice.remindeddate, fdate: invoice.fremindeddate, doc: r.docid});
                $log.debug('Reminder docid: ', r.docid);
                // invoice.$save();
              },
              function error(r) {
                $log.debug('Printsaving Reminder PDF failed: ', r);
              }
            );
    
          }
          
        }] // controller
    }); // component

// klammerfriedhof
}());
(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name cashinvoice.factory:Cashinvoice
   *
   * @description
   *
   */
  angular
    .module('finance.invoice')
    .factory('Cashinvoice', Cashinvoice);

  Cashinvoice.$inject = ['restmod'];

  function Cashinvoice(restmod) {
    return restmod.model('/routes/cashinvoices', 'PagedModel').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      }
    });
  }
  
}());

/**
 * Created by lari on 06/04/2017.
 */
(function () {
  'use strict';
  angular.module('finance.invoice')
    .component('cashinvoice', {
      restrict: 'E',
      templateUrl: 'finance/invoice/cashinvoice-component.tpl.html',
      controllerAs: 'vm',
      bindings: {
        mode: '@'
      },
      controller: ['Cashinvoice', '$log',
        function (Cashinvoice, $log) {
          var vm = this;
  
          vm.currentPage = 1;
          vm.pageChanged = pageChanged;
          vm.storno = storno;
          
          this.$onInit = function () {
            var search = {};
            
            search.page = vm.currentpage;
            search.query = {};
            
            switch (vm.mode) {
              case 'showstorno':
                search.query.storno = true;
                vm.title = 'Stornierte Barrechnungen';
                break;
              case 'hidestorno':
                search.query.storno = false;
                vm.title = 'Unstornierte Barrechnungen';
                break;
              case 'showstest':
                search.query.test = true;
                vm.title = 'Test-Barrechnungen';
                break;
              case 'hidetest':
                search.query.test = false;
                vm.title = 'Barrechnungen (ohne Testrechnungen)';
                break;
              default:
                vm.title = 'Barrechnungen';
                break;
            }
            
            search.options = {};
            search.options.fields = {
              _id: 1,
              invoicenr: 1,
              date: 1,
              fdate: 1,
              total: 1,
              tax: 1,
              storno: 1,
              test: 1,
              paytype: 1,
              patientinvoiceid: 1,
              doctorinvoiceid: 1,
              receiptid: 1,
              stornoreceiptid: 1,
              patientid: 1
            };
            

            vm.cashinvoices = Cashinvoice.$search(search).$then(function (inv) {
              vm.totalItems = inv.$pageCount;
            });
          };
          
          
          function pageChanged() {
            vm.cashinvoices.$refresh({page: vm.currentPage}).$then(function (inv) {
              vm.totalItems = inv.$pageCount;
            });
          }
  
          function storno(invoice) {
            $log.debug('storno invoice: ', invoice);
            invoice.$destroy().$then(function () {
              vm.cashinvoices.$refresh();
            });
          }
          
        }] // controller
    }); // component

// klammerfriedhof
}());
(function () {
  'use strict';

  /* @ngdoc object
   * @name closing
   * @description
   *
   */
  angular
    .module('finance.closing', [
      'ui.router',
      'ui.bootstrap'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name closing.factory:Closing
   *
   * @description
   *
   */
  angular
    .module('finance.closing')
    .factory('Closing', Closing);

  Closing.$inject = ['restmod'];

  function Closing(restmod) {
    return restmod.model('/routes/fiscaljournal');
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name closing.directive:closing
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="closing">
   <file name="index.html">
   <closing></closing>
   </file>
   </example>
   *
   */
  angular
    .module('finance.closing')
    .directive('closing', closing);

  closing.$inject = ['Closing', '$log', '$http'];

  function closing(Closing, $log, $http) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'finance/closing/closing-directive.tpl.html',
      replace: false,
      controllerAs: 'clCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'clCtrl';
        vm.startdate = new Date(moment().year(), 0, 1).toISOString();
        // vm.enddate = new Date().toISOString();
        vm.enddate = moment().add(1, 'days').toISOString();
        vm.rangequery = rangequery;
        vm.lastmonth = lastmonth;
        vm.freeinterval = freeinterval;

        function lastmonth() {
          var lastm = moment().subtract(1, 'month');
          var start = lastm.startOf('month').toISOString();
          var end = lastm.endOf('month').toISOString();
          $log.debug('Startdate: ', start);
          $log.debug('enddate: ', end);
          var cashname = 'umsatz_bar_karte-' + lastm.format('YYYY_MM') + '.csv';
          var checkname = 'umsatz_zielrechnung-' + lastm.format('YYYY_MM') + '.csv';
          rangequery(start, end, '/routes/fiscaljournal', cashname);
          rangequery(start, end, '/routes/checkjournal', checkname);
        }

        function freeinterval() {
          var start = moment.utc(vm.startdate).toISOString();
          var end = moment.utc(vm.enddate).toISOString();
          $log.debug('Startdate: ', start);
          $log.debug('enddate: ', end);
          var cashname = 'umsatz_bar_karte.csv';
          var checkname = 'umsatz_zielrechnung.csv';
          rangequery(start, end, '/routes/fiscaljournal', cashname);
          rangequery(start, end, '/routes/checkjournal', checkname);
          vm.start = null;
          vm.end = null;
        }

        function rangequery(start, end, url, filename) {
          var query = {
            created: {
              $gte: start,
              $lt: end
            }
          };
          var qq = {
              $gte: start,
              $lt: end
            };

          $log.debug('query: ', JSON.stringify(qq));
          var tmp = url + '?created=' + encodeURIComponent(JSON.stringify(qq));
          $log.debug('tmp: ', tmp);
          // $log.debug('query: ', JSON.stringify(query));
          $http.get(tmp, {})
          .success(function (data, status, headers, config) {
            var anchor = angular.element('<a/>');
            anchor.attr({
              href: 'data:attachment/csv;charset=utf-8,' + encodeURI(data),
              target: '_blank',
              download: filename
            })[0].click();
          })
          .error(function (data, status, headers, config) {
            $log.error('Ficaljournal filegeneration and export failed');
            $log.error(status);
            $log.error(headers);
            $log.error(data);
          });
        }


      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name cashbook
   * @description
   *
   */
  angular
    .module('finance.cashbook', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name cashbook.factory:Cashbook
   *
   * @description
   *
   */
  angular
    .module('finance.cashbook')
    .factory('Cashbook', Cashbook)
    .factory('Fiscaljournal', Fiscaljournal)
  ;

  Cashbook.$inject = ['restmod', '$log'];
  Fiscaljournal.$inject = ['restmod', '$log'];
  
  // gets the current cash-amount
  function Cashbook(restmod) {
    return restmod.model('/routes/cashbook');
  }
  
  // gets cashbook history
  function Fiscaljournal(restmod) {
    return restmod.model('/routes/cashbookhistory', 'PagedModel');
  }
  

}());

/**
 * Created by lari on 09/04/2017.
 */
(function () {
  'use strict';
  angular.module('finance.cashbook')
    .component('cashbook', {
      restrict: 'E',
      templateUrl: 'finance/cashbook/cashbook-component.tpl.html',
      controllerAs: 'vm',
      controller: ['Cashbook', 'Fiscaljournal', '$log',
        function (Cashbook, Fiscaljournal, $log) {
          var vm = this;
          vm.currentPage = 1;
          vm.pageChanged = pageChanged;
          
          vm.cashbook = Cashbook.$search();
          vm.cashlog = Fiscaljournal.$search().$then(function (log) {
            vm.totalItems = log.$pageCount;
          });
          
          vm.deposit = deposit;
          vm.withdrawal = withdrawal;
  
          function deposit() {
            vm.cashbook[0].amount = vm.depositvalue;
            vm.cashbook[0].add = true;
            vm.cashbook[0].$save().$then(function () {
              vm.depositvalue = 0;
              vm.cashbook.$refresh();
              vm.cashlog.$refresh();
            });
    
          }
  
          function withdrawal() {
            vm.cashbook[0].amount = vm.withdrawalvalue;
            vm.cashbook[0].add = false;
            vm.cashbook[0].$save().$then(function () {
              vm.withdrawalvalue = 0;
              vm.cashbook.$refresh();
              vm.cashlog.$refresh();
            });
          }
  
          function pageChanged() {
            vm.cashlog.$refresh({page: vm.currentPage}).$then(function (log) {
              vm.totalItems = log.$pageCount;
            });
          }
          
        }] // controller
    }); // component

// klammerfriedhof
}());
(function () {
  'use strict';

  /* @ngdoc object
   * @name bmfexport
   * @description
   *
   */
  angular
    .module('finance.bmfexport', [
      'ui.router',
      'ui.bootstrap'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name bmfexport.factory:Bmfexport
   *
   * @description
   *
   */
  angular
    .module('finance.bmfexport')
    .factory('Bmfexport', Bmfexport);

  Bmfexport.$inject = ['restmod'];

  function Bmfexport(restmod) {
    return restmod.model('/routes/bmfexport');
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name bmfexport.directive:bmfexport
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="bmfexport">
       <file name="index.html">
        <bmfexport></bmfexport>
       </file>
     </example>
   *
   */
  angular
    .module('finance.bmfexport')
    .directive('bmfexport', bmfexport);

  bmfexport.$inject = ['Config', '$log', '$http'];

  function bmfexport(Config, $log, $http) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'finance/bmfexport/bmfexport-directive.tpl.html',
      replace: false,
      controllerAs: 'beCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        var thisyear;
        vm.name = 'bmfexport';
        vm.rangequery = rangequery;
        vm.conf = Config.$find('configuration');
        // vm.startdate = moment(moment().year() + '-01-01', 'YYYY-MM-DD').toDate();
        vm.startdate = new Date(moment().year(), 0, 1).toISOString();
        $log.debug('Startdate: ', vm.startdate);
        vm.enddate = new Date().toISOString();
        vm.dateOptions = {
          formatYear: 'yyyy',
          startingDay: 1
        };

        vm.altInputFormats = ['yyyy-MM-dd', 'dd.MM.yyyy'];


        thisyear = {
          $gte: moment().startOf('year').toISOString(),
          $lt: moment().endOf('year').toISOString()
        };

        vm.thisyearquery = '/routes/bmfexport' + '?created=' + encodeURIComponent(JSON.stringify(thisyear));


        function rangequery (start, end) {
          var qq = {
            $gte: start,
            $lt: end
          };
// '/routes/bmfexport' + '?created=' + encodeURIComponent(JSON.stringify({}));
          var tmp = '/routes/bmfexport' + '?created=' + encodeURIComponent(JSON.stringify(qq));
          $log.debug('tmp: ', tmp);
          // $log.debug('query: ', JSON.stringify(query));
          $http.get(tmp, {})
            .success(function (data, status, headers, config) {
              var anchor = angular.element('<a/>');
              anchor.attr({
                href: 'data:application/octet-stream;base64,' + encodeURI(data),
                target: '_blank',
                download: 'bmfexport.zip'
              })[0].click();
            })
            .error(function (data, status, headers, config) {
              $log.error('bmfexport filegeneration and export failed');
              $log.error(status);
              $log.error(headers);
              $log.error(data);
            });
        }


      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name dms.scan
   * @description
   *
   */
  angular
    .module('dms.scan', [
      'ui.router',
      'pdf',
      'ngTagsInput',
      'ui.bootstrap.typeahead'
    ]);
}());

(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name dms.scan.directive:scanSorter
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="dms.scan">
   <file name="index.html">
   <scan-sorter></scan-sorter>
   </file>
   </example>
   *
   */
  angular
    .module('dms.scan')
    .directive('scanSorter', scanSorter);
  
  scanSorter.$inject = ['FileGrabberScan', 'FileAhead', 'File', '$log', 'Share', 'Config',
    'pdfDelegate', 'Patientsearch', 'SignHelper', '$http'];
  
  function scanSorter(FileGrabberScan, FileAhead, File, $log, Share, Config,
                      PdfDelegate, Patientsearch, SignHelper, $http) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'dms/scan/scan-sorter-directive.tpl.html',
      replace: false,
      controllerAs: 'scanCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.conf = Config.$find('configuration');
        
        vm.pdf = 0;
        vm.ready = false;
        // vm.fileDelete = fileDelete;
        // vm.fileLabel = fileLabel;
        vm.nextPdf = nextPdf;
        vm.previousPdf = previousPdf;
        vm.nextPage = nextPage;
        vm.previousPage = previousPage;
        vm.rotatePage = rotatePage;
        vm.gotoPage = gotoPage;
        vm.zoomIn = zoomIn;
        vm.zoomOut = zoomOut;
        vm.assignpatient = assignpatient;
        vm.getNames = getNames;
        vm.getTags = getTags;
        vm.clearPatient = clearPatient;
        vm.closePdf = closePdf;
        vm.reloadPdfs = reloadPdfs;
        vm.spliceDocument = spliceDocument;
        // vm.loadTags = loadTags;
        vm.assignKeyword = assignKeyword;
        vm.saveKeyword = saveKeyword;
        vm.formDisabled = formDisabled;
        
        // get the scans
        vm.reloadPdfs();
        
        // slow, but working
        function previousPdf() {
          $log.debug('next pdf');
          vm.scans[vm.pdf].$save();
          vm.pdf = vm.pdf === 0 ? vm.scans.length - 1 : vm.pdf - 1;
          PdfDelegate
            .$getByHandle('scans-pdf-container')
            .load('/routes/files/filereader/' + vm.scans[vm.pdf].fileid)
            .then(function () {
              vm.currentpage = 1;
              vm.currentpage = PdfDelegate.$getByHandle('scans-pdf-container').getCurrentPage();
              vm.maxpc = PdfDelegate.$getByHandle('scans-pdf-container').getPageCount();
            });
        }
        
        function nextPdf() {
          vm.scans[vm.pdf].$save();
          vm.pdf = vm.pdf < vm.scans.length - 1 ? vm.pdf + 1 : 0;
          PdfDelegate
            .$getByHandle('scans-pdf-container')
            .load('/routes/files/filereader/' + vm.scans[vm.pdf].fileid)
            .then(function () {
              vm.currentpage = 1;
              vm.currentpage = PdfDelegate.$getByHandle('scans-pdf-container').getCurrentPage();
              vm.maxpc = PdfDelegate.$getByHandle('scans-pdf-container').getPageCount();
            });
        }
        
        function previousPage() {
          PdfDelegate.$getByHandle('scans-pdf-container').prev();
          vm.currentpage = PdfDelegate.$getByHandle('scans-pdf-container').getCurrentPage();
        }
        
        function nextPage() {
          PdfDelegate.$getByHandle('scans-pdf-container').next();
          vm.currentpage = PdfDelegate.$getByHandle('scans-pdf-container').getCurrentPage();
        }
        
        function zoomIn() {
          PdfDelegate.$getByHandle('scans-pdf-container').zoomIn();
        }
        
        function zoomOut() {
          PdfDelegate.$getByHandle('scans-pdf-container').zoomOut();
        }
        
        function rotatePage() {
          PdfDelegate.$getByHandle('scans-pdf-container').rotate();
        }
        
        function gotoPage() {
          if (vm.currentpage > 0 && vm.currentpage <= vm.maxpc) {
            PdfDelegate.$getByHandle('scans-pdf-container').goToPage(vm.currentpage);
          }
        }
        
        
        // typeahead patient search backend query
        function getNames(val) {
          console.log('loading names');
          
          return Patientsearch.$search({query: val}).$asPromise().then(function (response) {
            console.log('response: ', response);
            return response.map(function (patient) {
              patient.text = patient.firstname + ' ' + patient.lastname + ', ' + patient.svnr;
              return patient;
            });
          });
        }
        
        // typeahead patient search action
        function assignpatient($item, $model, $label) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          vm.scans[vm.pdf].patientid = $model._id;
          vm.scans[vm.pdf].signedoff = false;
          vm.scans[vm.pdf].patientfname = $model.firstname + ' ' + $model.lastname + ', ' + $model.svnr;
          vm.scans[vm.pdf].$save();
          SignHelper.triggerUpdate();
          vm.asyncSelected = null;
        }
        
        
        function assignKeyword($item, $model, $label) {
          if (!angular.isArray(vm.scans[vm.pdf].keywords)) {
            vm.scans[vm.pdf].keywords = [];
          }
          $log.debug('$model: ', $model);
          $log.debug('$item: ', $item);
          $log.debug('$label: ', $label);
          // vm.scans[vm.pdf].keywords.push({text: $item});
          // saveKeyword();
        }
        
        function clearPatient() {
          vm.scans[vm.pdf].patientid = null;
          vm.scans[vm.pdf].patientfname = null;
          vm.scans[vm.pdf].signoff = null;
          vm.scans[vm.pdf].jobid = 'scan';
          vm.scans[vm.pdf].$save();
          SignHelper.triggerUpdate();
        }
        
        
        function closePdf() {
          vm.scans[vm.pdf].jobid = 'processedscan';
          vm.saveKeyword();
          vm.scans[vm.pdf].$save();
          vm.spliceDocument();
          // vm.reloadPdfs();
        }
        
        function spliceDocument() {
          if (vm.scans.length > 1) {
            vm.scans.splice(vm.pdf, 1);
            vm.pdf = vm.pdf === 0 ? vm.scans.length - 1 : vm.pdf - 1;
            vm.nextPdf();
          }
          else {
            vm.scans.splice(vm.pdf, 1);
            vm.pdf = 0;
            vm.nomoredocuments = true;
          }
        }
        
        function reloadPdfs() {
          vm.ready = false;
          vm.nomoredocuments = false;
          vm.pdf = 0;
          Share.$search({name: 'scan'}).$asPromise().then(function (share) {
            var shareobj = {};
            shareobj.path = share[0].path;
            FileGrabberScan.$create(shareobj).$asPromise().then(function () {
              File.$search({jobid: 'scan'}).$asPromise().then(function (s) {
                vm.scans = s;
                $log.debug('scans: ', vm.scans);
                vm.ready = true;
                if (vm.scans.length === 0) {
                  vm.nomoredocuments = true;
                }
                else {
                  // load the first scan
                  PdfDelegate
                    .$getByHandle('scans-pdf-container')
                    .load('/routes/files/filereader/' + vm.scans[vm.pdf].fileid)
                    .then(function () {
                      vm.currentpage = PdfDelegate.$getByHandle('scans-pdf-container').getCurrentPage();
                      vm.maxpc = PdfDelegate.$getByHandle('scans-pdf-container').getPageCount();
                    });
                }
              });
            });
          });
        }
        
        /* function loadTags(query) {
         var ptags = [{text: 'Leberwerte'}, {text: 'Biopsie'}, {text: 'Entlassungsbrief'}, {text: 'Vorbefund'}];
         return ptags;
         }
         */

        // use aggregate query to obtain a list of distinct tags
        // first match all documents contain a tag that fits the regex
        // then unwind the document per tag to obtain multiple copies
        function getTags(val) {
          var myregex = '^' + val; // when using $regex, do not include '/' for regex delimiter
          var match = [
            {'$match': {'flattags': {'$regex': myregex, '$options': 'i'}}},
            {'$unwind': '$flattags'},
            {'$match': {'flattags': {'$regex': myregex, '$options': 'i'}}},
            {'$group': {'_id': '$flattags',  'total': {$sum: 1}}}];
          
          console.log('loading tags');
          
          
          return $http.post('/routes/files/aggregate', match).then(function (response) {
            var i;
            var tags = [];
            // $log.debug('tags data received: ', response);
            for (i = 0; i < response.data.length; i++) {
              tags.push({text: response.data[i]._id});
            }
            $log.debug('suggested tags array: ', tags);
            $log.debug('keywords array: ', vm.scans[vm.pdf].keywords);
            return tags;
          });
          
          
/*
            // tagahead - typeahead flatkeyword search backend query
            return FileAhead.$search({query: val}).$asPromise().then(function (response) {
            var tags = [];
            // var filteredtags = [];
            var re = new RegExp('^' + val, 'i');
            
            response.forEach(function (res) {
              $log.debug('single response entry: ', res);
              res.keywords.forEach(function (t) {
                  $log.debug('tag: ', t);
                  if (re.exec(t.text)) {
                    $log.debug('tag matched: ', t);
                    tags = tags.concat(t);
                    tags.push(t.text);
                    $log.debug('tags matched: ', tags);
                  }
                }
              );
            });
            $log.debug('filtered tags: ', tags);
            return uniq(tags);
          });*/
        }
        
        
        function formDisabled(scan) {
          var answer = true;
          if (angular.isObject(scan) && scan.hasOwnProperty('doccat') && scan.doccat === 'patient' && angular.isString(scan.patientid)) {
            answer = false;
          }
          if (angular.isObject(scan) && scan.hasOwnProperty('doccat') && scan.doccat === 'generic') {
            answer = false;
          }
          return answer;
        }
        
/*        function uniq(a) {
          return a.sort().filter(function (item, pos, ary) {
            return !pos || item !== ary[pos - 1];
          });
        }*/
        
        
        function saveKeyword() {
          var flattags = [];
          var i = 0;
          if (angular.isArray(vm.scans[vm.pdf].keywords)) {
            for (i = 0; i < vm.scans[vm.pdf].keywords.length; i++) {
              flattags.push(vm.scans[vm.pdf].keywords[i].text);
            }
            vm.scans[vm.pdf].flattags = flattags;
          }
        }
        
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint 'no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('dms.scan')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('scan', {
        url: '/scan',
        templateUrl: 'dms/scan/scan.tpl.html',
        controller: 'ScanCtrl',
        controllerAs: 'scan'
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name dms.scan.factory:Scan
   *
   * @description
   *
   */
  angular
    .module('dms.scan')
    .factory('FileGrabberScan', FileGrabberScan)
    .factory('FileAhead', FileAhead);

  FileGrabberScan.$inject = ['restmod'];

  function FileGrabberScan(restmod) {
    return restmod.model('/routes/files/filegrabber/scan');
  }

  FileAhead.$inject = ['restmod'];

  function FileAhead(restmod) {
    return restmod.model('/routes/files/tagahead');
  }
  


  // klammerfriedhof
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name dms.signoff
   * @description
   *
   */
  angular
    .module('dms.signoff', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name dms.signoff.directive:signoffAll
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="dms.signoff">
   <file name="index.html">
   <signoff-all></signoff-all>
   </file>
   </example>
   *
   */
  angular
    .module('dms.signoff')
    .directive('signoffAll', signoffAll);

  signoffAll.$inject = ['File', '$log', 'Patient', 'pdfDelegate', 'SignHelper', '$stateParams',
    'vertxEventBusService', '$state', '$uibModal', '$http'];


  function signoffAll(File, $log, Patient, PdfDelegate, SignHelper, $stateParams,
                      vertxEventBusService, $state, $uibModal, $http) {
    return {
      restrict: 'EA',
      scope: {
        patientid: '@'
      },
      templateUrl: 'dms/signoff/signoff-all-directive.tpl.html',
      replace: false,
      controllerAs: 'soCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        var query = {};
        vm.name = 'signoffAll';
        vm.nomoredocuments = true;
        vm.ready = false;
        vm.$state = $state;

        vm.pdf = 0;
        vm.ready = false;
        vm.nextPdf = nextPdf;
        vm.previousPdf = previousPdf;
        vm.nextPage = nextPage;
        vm.previousPage = previousPage;
        vm.rotatePage = rotatePage;
        vm.gotoPage = gotoPage;
        vm.zoomIn = zoomIn;
        vm.zoomOut = zoomOut;
        vm.reloadPdfs = reloadPdfs;
        vm.closePdf = closePdf;
        vm.getTags = getTags;
        vm.patientlogger = patientlogger;
        vm.patienttodo = patienttodo;

        vm.$onInit = function () {
          // $log.debug('stateParams.patientid: ', $stateParams.patientid);
          $log.debug('***** vm.patientid: ', vm.patientid);


          // get the scans
          if (angular.isDefined(vm.patientid) && angular.isString(vm.patientid) && vm.patientid.length > 0) {
            query = {signedoff: false, patientid: $stateParams.patientid};
            $log.debug('Loading scans for patient: ', $stateParams.patientid);
          }
          else {
            query = {signedoff: false};
            $log.debug('Loading all assigned scans');
          }

          File.$search(query).$then(function (s) {
            vm.unsignedScans = s;
            vm.reloadPdfs();
            $log.debug(vm.unsignedScans);
          });
        };



        // slow, but working
        function previousPdf() {
          $log.debug('next pdf');
          vm.unsignedScans[vm.pdf].$save();
          vm.pdf = vm.pdf === 0 ? vm.unsignedScans.length - 1 : vm.pdf - 1;
          vm.patient = Patient.$find(vm.unsignedScans[vm.pdf].patientid);
          PdfDelegate
            .$getByHandle('unsigned-pdf-container')
            .load('/routes/files/filereader/' + vm.unsignedScans[vm.pdf].fileid)
            .then(function () {
              vm.currentpage = 1;
              vm.currentpage = PdfDelegate.$getByHandle('unsigned-pdf-container').getCurrentPage();
              vm.maxpc = PdfDelegate.$getByHandle('unsigned-pdf-container').getPageCount();
            });
        }

        function nextPdf() {
          vm.unsignedScans[vm.pdf].$save();
          vm.pdf = vm.pdf < vm.unsignedScans.length - 1 ? vm.pdf + 1 : 0;
          vm.patient = Patient.$find(vm.unsignedScans[vm.pdf].patientid);
          PdfDelegate
            .$getByHandle('unsigned-pdf-container')
            .load('/routes/files/filereader/' + vm.unsignedScans[vm.pdf].fileid)
            .then(function () {
              vm.currentpage = 1;
              vm.currentpage = PdfDelegate.$getByHandle('unsigned-pdf-container').getCurrentPage();
              vm.maxpc = PdfDelegate.$getByHandle('unsigned-pdf-container').getPageCount();
            });

        }

        function previousPage() {
          PdfDelegate.$getByHandle('unsigned-pdf-container').prev();
          vm.currentpage = PdfDelegate.$getByHandle('unsigned-pdf-container').getCurrentPage();
        }

        function nextPage() {
          PdfDelegate.$getByHandle('unsigned-pdf-container').next();
          vm.currentpage = PdfDelegate.$getByHandle('unsigned-pdf-container').getCurrentPage();
        }

        function zoomIn() {
          PdfDelegate.$getByHandle('unsigned-pdf-container').zoomIn();
        }

        function zoomOut() {
          PdfDelegate.$getByHandle('unsigned-pdf-container').zoomOut();
        }

        function rotatePage() {
          PdfDelegate.$getByHandle('unsigned-pdf-container').rotate();
        }

        function gotoPage() {
          if (vm.currentpage > 0 && vm.currentpage <= vm.maxpc) {
            PdfDelegate.$getByHandle('unsigned-pdf-container').goToPage(vm.currentpage);
          }
        }

        function reloadPdfs() {
          vm.ready = false;
          vm.nomoredocuments = true;
          vm.pdf = 0;

          $log.debug('unsigned scans length: ', vm.unsignedScans.length);
          if (vm.unsignedScans.length > 0) {
            vm.patient = Patient.$find(vm.unsignedScans[vm.pdf].patientid);
            vm.ready = true;
            PdfDelegate
              .$getByHandle('unsigned-pdf-container')
              .load('/routes/files/filereader/' + vm.unsignedScans[vm.pdf].fileid)
              .then(function () {
                vm.currentpage = PdfDelegate.$getByHandle('unsigned-pdf-container').getCurrentPage();
                vm.maxpc = PdfDelegate.$getByHandle('unsigned-pdf-container').getPageCount();
                vm.ready = true;
                vm.nomoredocuments = false;
              });
          }
        }


        // get document classification tags
        function getTags(val) {
          var myregex = '^' + val; // when using $regex, do not include '/' for regex delimiter
          var match = [
            {'$match': {'flattags': {'$regex': myregex, '$options': 'i'}}},
            {'$unwind': '$flattags'},
            {'$match': {'flattags': {'$regex': myregex, '$options': 'i'}}},
            {'$group': {'_id': '$flattags',  'total': {$sum: 1}}}];

          return $http.post('/routes/files/aggregate', match).then(function (response) {
            var i;
            var tags = [];
            // $log.debug('tags data received: ', response);
            for (i = 0; i < response.data.length; i++) {
              tags.push({text: response.data[i]._id});
            }
            return tags;
          });
        }

        // Modal für neuen Patient-Log-Eintrg
        function patientlogger() {
          $uibModal.open({
            template: '<div class="modalspacer"><patient-log patient="miCtrl.patient"></patient-log></div>',
            size: 'modal-sm',
            controller: ['patient', function (patient) {
              var vmi = this;
              vmi.patient = patient;
            }],
            controllerAs: 'miCtrl',
            resolve: {
              patient: function () {
                return vm.patient;
              }
            }
          });
        }


        // Modal for new Patient-Todo
        function patienttodo() {
          $uibModal.open({
            template: '<div class="modalspacer"><patient-reminder patient="miCtrl.patient"></patient-reminder></div>',
            size: 'modal-sm',
            controller: ['patient', function (patient) {
              var vmi = this;
              vmi.patient = patient;
            }],
            controllerAs: 'miCtrl',
            resolve: {
              patient: function () {
                return vm.patient;
              }
            }
          });
        }

        function closePdf() {
          vm.unsignedScans[vm.pdf].signedoff = true;
          vm.unsignedScans[vm.pdf].$save();
          // signal array change
          SignHelper.triggerUpdate();
        }

        // listen for array change, reload list and redisplay
        vertxEventBusService.on('signoff.update', function () {
          vm.unsignedScans.$refresh().$asPromise().then(function () {
            $log.debug('signoff.update caught: ', vm.unsignedScans);
            vm.reloadPdfs();
          });
        });


      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name dms.signoff.factory:SignHelper
   *
   * @description
   *
   */
  angular
    .module('dms.signoff')
    .factory('SignHelper', SignHelper);

  SignHelper.$inject = ['vertxEventBusService', '$log'];

  function SignHelper(vertxEventBusService, $log) {
    var SignHelperBase = {};

    SignHelperBase.triggerUpdate = function () {
      vertxEventBusService.publish('signoff.update', {});
      $log.debug('signoff update triggered');
    };

    return SignHelperBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name dms.signoff.directive:signCounter
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="dms.signoff">
       <file name="index.html">
        <sign-counter></sign-counter>
       </file>
     </example>
   *
   */
  angular
    .module('dms.signoff')
    .directive('signCounter', signCounter);


  signCounter.$inject = ['vertxEventBusService', '$log', 'File'];
  function signCounter(vertxEventBusService, $log, File) {
    return {
      restrict: 'EA',
      scope: {},
      // templateUrl: 'dms/signoff/sign-counter-directive.tpl.html',
      template: '<span class="label label-danger">{{scCtrl.unsignedScans.length}}</span>',
      replace: false,
      controllerAs: 'scCtrl',
      controller: function () {
        var vm = this;
        vm.name = 'signCounter';
        vm.unsignedScans = File.$search({signedoff: false});


        vertxEventBusService.on('signoff.update', function () {
          vm.unsignedScans.$refresh();
          $log.debug('signoff.update caught: ', vm.unsignedScans);
        });

      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name waitinglist
   * @description
   *
   */
  angular
    .module('waitinglist', [
      'ui.router',
      'utils.listEditor',
      'restmod',
      'dndLists',
      'as.sortable'
    ]);
}());

(function () {
  'use strict';
  
  /**
   * @ngdoc service
   * @name waitinglist.factory:Wlcommons
   *
   * @description
   *
   */
  angular
    .module('waitinglist')
    .factory('Wlcommons', Wlcommons);
  
  Wlcommons.$inject = ['$log'];
  
  function Wlcommons($log) {
    var WlcommonsBase = {};
    
    
    WlcommonsBase.addPatient = function (list, patient) {
      var i;
/*      var pat = {};
      pat._id = patient._id;
      pat.firstname = patient.firstname;
      pat.lastname = patient.lastname;
      pat.titlefront = patient.titlefront;
      pat.titleback = patient.titleback;
      pat.svnr = patient.svnr;
      pat.birthdate = patient.birthdate;*/
      
      list.patients.$fetch().$asPromise().then(function () {
        // don't add patient to list if patient is alreaddy on list
        for (i = 0; i < list.length; i++) {
          if (list.patients[i]._id === patient._id) {
            list.splice(i, 1);
          }
        }
        list.patients.push(patient);
        patient.waitinglistid = list._id;
        patient.waitinglistname = list.name;
        list.$save();
        patient.$save();
      });
    };
    
    WlcommonsBase.removePatient = function (list, patient) {
      var i;
      
      $log.debug('wl.removePatient called: ', list, patient);
      
      list.patients.$fetch().$asPromise().then(function () {
        for (i = 0; i < list.length; i++) {
          if (list[i].patient._id === patient._id) {
            list.splice(i, 1);
          }
        }
        list.$save();
        delete patient.waitinglistid;
        delete patient.waitinglistname;
        $log.debug('list, patient after remove: ', list, patient);
        patient.$save();
      });
    };
    
    return WlcommonsBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name waitinglist.directive:waitinglists
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="waitinglist">
       <file name="index.html">
        <waitinglists></waitinglists>
       </file>
     </example>
   *
   */
  angular
    .module('waitinglist')
    .directive('waitinglists', waitinglists);
  
  waitinglists.$inject = ['$log', 'Waitinglist', 'Wlcommons'];
  
  function waitinglists($log, Waitinglist, Wlcommons) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'waitinglist/waitinglists-directive.tpl.html',
      replace: false,
      controllerAs: 'wlCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'waitinglists';
        // vm.waitinglists = Waitinglist.$search({active: true});
        Waitinglist.$search({active: true}).$asPromise().then(function (wl) {
          var i;
          for (i = 0; i < wl.length; i++) {
            wl[i].patients.$refresh();
          }
          vm.waitinglists = wl;
        });
        
        vm.addPatient = Wlcommons.addPatient;
        vm.removePatient = Wlcommons.removePatient;
        
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  angular
    .module('waitinglist')
    .config(config);
  
  config.$inject = ['$stateProvider'];
  
  function config($stateProvider) {
    $stateProvider
      .state('waitinglists', {
        url: '/waitinglists',
        views: {
          main: {template: '<configurewl></configurewl>'},
          // 'leftbar': {template: '<patient-today></patient-today>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });
  }
}());

(function () {
  'use strict';
  
  /**
   * @ngdoc service
   * @name waitinglist.factory:Waitinglist
   *
   * @description
   *
   */
  angular
    .module('waitinglist')
    .factory('Waitinglist', Waitinglist)
    .config(config);
  
  config.$inject = ['restmodProvider'];
  
  function config(restmodProvider) {
    restmodProvider.rebase({
      $config: {
        primaryKey: '_id'
      }
    });
  }
  
  Waitinglist.$inject = ['restmod'];
  
  function Waitinglist(restmod) {
    return restmod.model('/routes/waitinglists').mix({
      patients: {hasMany: 'Patient', inverseOf: 'waitinglistid', path: 'patients'},
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true}
    });
  }
  
  
  //      patients: {hasMany: 'Patient', inverseOf: 'waitinglist', path: 'patients'},
  /*  function Waitinglist(restmod) {
   return restmod.model('/routes/waitinglists');
   }*/
  
  
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name waitinglist.directive:configurewl
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="waitinglist">
       <file name="index.html">
        <configurewl></configurewl>
       </file>
     </example>
   *
   */
  angular
    .module('waitinglist')
    .directive('configurewl', configurewl);
  
  configurewl.$inject = ['Waitinglist', '$log'];
  
  function configurewl(Waitinglist, $log) {
    return {
      restrict: 'E',
      scope: {},
      templateUrl: 'waitinglist/configurewl-directive.tpl.html',
      replace: false,
      controllerAs: 'cwlCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'configurewl';
        vm.waitinglists = Waitinglist.$search({active: true});
        vm.add = add;
        vm.formid = guid;
        vm.splice = splice;
  
        function splice(i, wl) {
          vm.waitinglists.splice(i, 1);
          wl.active = false;
          wl.$save();
          $log.debug('array: ', vm.waitinglists);
        }
  
        function add() {
          if (vm.newlist !== '') {
            vm.waitinglists.$create({name: vm.newlist});
          }
          
          vm.newlist = '';
          /* if (!angular.isArray(vm.waitinglists)) {
            vm.waitinglists = [];
          }
          vm.waitinglists.push(vm.newvalue);
          $log.debug('array: ', vm.waitinglists);
          vm.newvalue = '';*/
        }
  
        function guid() {
          function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
              .toString(16)
              .substring(1);
          }
          return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
        }
        
        
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name utils
   * @description
   *
   */
  angular
    .module('utils', [
      'ui.router',
      'utils.keywords',
      'utils.fileuploader',
      'utils.listEditor',
      'utils.stacktrace'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('utils')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('utils', {
        url: '/utils',
        templateUrl: 'utils/utils.tpl.html',
        controller: 'UtilsCtrl',
        controllerAs: 'utils'
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name utils.controller:UtilsCtrl
   *
   * @description
   *
   */
  angular
    .module('utils')
    .controller('UtilsCtrl', UtilsCtrl);

  UtilsCtrl.$inject = [];

  function UtilsCtrl() {
    var vm = this;
    vm.ctrlName = 'UtilsCtrl';
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name user
   * @description
   *
   */
  angular
    .module('user', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('user')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('users', {
        url: '/users',
        views: {
          main: {template: '<adduser></adduser>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });
  }
}());

(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name user.factory:Userservice
   *
   * @description
   *
   */
  angular
    .module('user')
    .factory('User', User)
    .config(config);

  config.$inject = ['restmodProvider'];

  function config(restmodProvider) {
    restmodProvider.rebase({
      $config: {
        primaryKey: '_id'
      }
    });
  }

  User.$inject = ['restmod'];

  function User(restmod) {
    return restmod.model('/routes/users').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true}
    });
  }
  
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.controller:UserCtrl
   *
   * @description
   *
   */
  angular
    .module('user')
    .controller('UserCtrl', UserCtrl);

  UserCtrl.$inject = ['User'];

  function UserCtrl(User) {
    var vm = this;
    vm.ctrlName = 'UserCtrl';

    vm.users = User.$search();
    vm.add = add;


    function add() {
      vm.users.$create(vm.newuser);
    }


  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name user.directive:adduser
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="user">
   <file name="index.html">
   <adduser></adduser>
   </file>
   </example>
   *
   */
  angular
    .module('user')
    .directive('adduser', adduser);

  function adduser() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'user/adduser-directive.tpl.html',
      replace: false,
      controllerAs: 'uCtrl',
      bindToController: true,
      controller: 'UserCtrl',
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name unicatalog
   * @description
   *
   */
  angular
    .module('unicatalog', [
      'ui.router',
      'ui.tree',
      'ui.bootstrap'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name unicatalog.factory:Unicatalogtree
   *
   * @description
   *
   */
  angular
    .module('unicatalog')
    .factory('Unicatalogtree', Unicatalogtree);

  Unicatalogtree.$inject = ['restmod'];

  function Unicatalogtree(restmod) {
    return restmod.model('/routes/unicatalogtree').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      updated: {
        init: function () {
          var updates = [];
          updates.push(new Date().toISOString());
          return updates;
        }
      },
      active: {init: true}
    });
  }

}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('unicatalog')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('unicatalog', {
        url: '/unicatalog',
        views: {
          main: {template: '<catalog-editor></catalog-editor>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });




  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name unicatalog.factory:Unicatalog
   *
   * @description
   *
   */
  Unicatalogtree.$inject = ["restmod"];
  angular
    .module('unicatalog')
    .factory('Unicatalog', Unicatalog)
    .factory('Unicatalogtree', Unicatalogtree);

  Unicatalog.$inject = ['restmod'];

  function Unicatalog(restmod) {
    return restmod.model('/routes/unicatalog').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      updated: {
        init: function () {
          var updates = [];
          updates.push(new Date().toISOString());
          return updates;
        }
      },
      active: {init: true},
      displayname: {init: ''},
      cname: {init: ''},
      description: {init: ''},
      ingeneralcat: {init: false},
      inusercat: {init: false},
      inoperationcat: {init: false},
      customerdata: {
        init: function () {
          return {tax: 0, price: 0, shortcut: ''};
        }
      },
      svdata: {
        init: function () {
          var struct = {
            refundable: true,
            codes1: [],
            codes2: [],
            primarycode: '',
            points: 0,
            pointcat: '',
            price: 0,
            conflictssv: [],
            conflictsuni: [],
            conditions: [],
            repeatable: false,
            repeatlimit: 0
          };
          var svstruct = {
            wggk: struct,
            noegkk: struct,
            bgkk: struct,
            ooegkk: struct,
            sgkk: struct,
            stgkk: struct,
            kgkk: struct,
            tgkk: struct,
            bva: struct,
            vaeb: struct,
            sva: struct,
            wkfa: struct,
            skfa: struct
          };
          return svstruct;
        }
      }
    });
  }

  function Unicatalogtree(restmod) {
    return restmod.model('/routes/unicatalog').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      }
    });
  }

}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name unicatalog.directive:itemList
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="unicatalog">
       <file name="index.html">
        <item-list></item-list>
       </file>
     </example>
   *
   */
  angular
    .module('unicatalog')
    .directive('itemList', itemList);

  function itemList() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'unicatalog/item-list-directive.tpl.html',
      replace: false,
      controllerAs: 'itemList',
      controller: function () {
        var vm = this;
        vm.name = 'itemList';
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name unicatalog.directive:itemEditor
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="unicatalog">
   <file name="index.html">
   <item-editor></item-editor>
   </file>
   </example>
   *
   */
  angular
    .module('unicatalog')
    .directive('itemEditor', itemEditor);

  itemEditor.$inject = ['$log'];

  function itemEditor($log) {
    return {
      restrict: 'EA',
      scope: {
        item: '='
      },
      templateUrl: 'unicatalog/item-editor-directive.tpl.html',
      replace: false,
      controllerAs: 'ieCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'itemEditor';


      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name unicatalog.directive:catalogEditor
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="unicatalog">
       <file name="index.html">
        <catalog-editor></catalog-editor>
       </file>
     </example>
   *
   */
  angular
    .module('unicatalog')
    .directive('catalogEditor', catalogEditor);


  catalogEditor.$inject = ['Unicatalogtree', 'Unicatalog', '$log'];

  function catalogEditor(Unicatalogtree, Unicatalog, $log) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'unicatalog/catalog-editor-directive.tpl.html',
      replace: false,
      controllerAs: 'cateCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'catalogEditor';

        vm.catalog = Unicatalogtree.$find('unicatalog');
        vm.allcatitems = Unicatalog.$search();


        vm.newGroup = function () {
          if (!angular.isArray(vm.catalog.groups)){
            vm.catalog.groups = [];
          }
          vm.catalog.groups.push({
            label: 'Neue Gruppe',
            items: []
          });
        };

        vm.newSubItem = function (scope) {
          var nodeData = scope.$modelValue;
          Unicatalog.$create().$asPromise().then(function (newitem){
            nodeData.items.push(newitem);
          });
        };

        vm.remove = function (scope) {
          scope.remove();
          vm.tpl.$save();
        };


      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name unicatalog.directive:arrayEditor
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="unicatalog">
       <file name="index.html">
        <array-editor></array-editor>
       </file>
     </example>
   *
   */
  angular
    .module('unicatalog')
    .directive('arrayEditor', arrayEditor);

  function arrayEditor() {
    return {
      restrict: 'EA',
      scope: {
        array: '='
      },
      //templateUrl: 'unicatalog/array-editor-directive.tpl.html',
      template: '<input type="text" class="form-control" ng-model="aeCtrl.array" ng-blur="aeCtrl.deserialize(aeCtrl.array)">',
      replace: false,
      controllerAs: 'aeCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'arrayEditor';

        vm.serial = vm.serialize(vm.array);
        vm.serialize = serialize;
        vm.deserialize = deserialize;


        function serialize(input) {
          var output = '';
          if (angular.isArray(input)) {
            output = input.join(', ');
          }
          return output;
        }

        function deserialize(input) {
          var output = [];
          if (angular.isString(input)) {
            input.text().replace(/ /g, '');
            output = input.split(',');
          }
          return output;
        }
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name tracker
   * @description
   *
   */
  angular
    .module('tracker', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name tracker.directive:trackerlist
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="tracker">
       <file name="index.html">
        <trackerlist></trackerlist>
       </file>
     </example>
   *
   */
  angular
    .module('tracker')
    .directive('trackerlist', trackerlist);
  
  trackerlist.$inject = ['$log', 'Trackercommons', '$timeout', '$uibModal'];
  
  function trackerlist($log, Trackercommons, $timeout, $uibModal) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'tracker/trackerlist-directive.tpl.html',
      replace: false,
      controllerAs: 'tlCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'trackerlist';
        vm.newtr = '';
        vm.newdp = [];
        vm.dpsaved = false;
        
        vm.addTracker = addTracker;
        vm.removeTracker = removeTracker;
        vm.editTracker = editTracker;
        vm.addDatapoint = addDatapoint;
        vm.addDatapoints = addDatapoints;
        vm.today = today;
        
        function addTracker() {
          Trackercommons.addTracker(vm.patient, vm.newtr.name, vm.newtr.unit, vm.newtr.min, vm.newtr.max);
          vm.newtr = '';
        }
        
        function addDatapoint(trackerindex) {
          Trackercommons.addDatapoint(vm.patient, trackerindex, vm.newdp[trackerindex].date, vm.newdp[trackerindex].unit);
          vm.newdp[trackerindex] = {};
          vm.shownewdp = false;
        }
        
        function addDatapoints() {
          var i;
          var multi = true;
          // use multi: true to avoid saving on every datapoint entry. save once, after all datapoints have been added.
          for (i = 0; i < vm.newdp.length; i++) {
            if (angular.isString(vm.newdp[i].date) && angular.isNumber(vm.newdp[i].data)) {
              Trackercommons.addDatapoint(vm.patient, i, vm.newdp[i].date, vm.newdp[i].data, multi);
            }
          }
          
          vm.dpsaved = true;
          $timeout(function () {
            vm.newdp = [];
            vm.dpsaved = false;
          }, 3000);
          vm.patient.$save();
        }
        
        function removeTracker(index) {
          $log.debug('*************  remove tracker: ', index);
          vm.patient.trackers[index].active = false;
          $log.debug('*************  remove tracker: ', vm.patient.trackers[index].active);
          vm.patient.$save();
        }
        
        function today() {
          var date = moment().format('YYYY-MM-DD');
          return date;
        }
        
        function editTracker(tracker) {
          $uibModal.open({
            template: '<div class="modalspacer"><edit-tracker tracker="miCtrl.tracker" patient="miCtrl.patient"></edit-tracker></div>',
            size: 'modal-sm',
            controller: ['$uibModalInstance', 'tracker', 'patient', function ($uibModalInstance, ltracker, lpatient) {
              var vmi = this;
              vmi.tracker = ltracker;
              vmi.patient = lpatient;
              vmi.$modalInstance = $uibModalInstance;
      
              vmi.cancel = function () {
                vmi.$modalInstance.close();
              };
      
              vmi.save = function () {
                lpatient.$save();
                // vmi.$modalInstance.close();
              };
            }],
            controllerAs: 'miCtrl',
            resolve: {
              tracker: function () {
                return tracker;
              },
              patient: function () {
                return vm.patient;
              }
            }
          });
        }
        
        
        
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';
  
  /**
   * @ngdoc service
   * @name tracker.factory:Trackercommons
   *
   * @description
   *
   */
  angular
    .module('tracker')
    .factory('Trackercommons', Trackercommons);
  
  
  Trackercommons.$inject = ['$log'];
  
  function Trackercommons($log) {
    var TrackercommonsBase = {};
    
    TrackercommonsBase.addTracker = function (patient, name, unit, min, max) {
      var tmp = {};
      var dataset;
      var normmin;
      var normmax;
      
      $log.debug('Min: ', min);
      $log.debug('Max: ', max);
      
      
      tmp.graphoptions = {
        legend: true
      };
      tmp.groups = [];
      tmp.items = [];
      tmp.normmin = min;
      tmp.normmax = max;
      tmp.name = name;
      tmp.unit = unit;
      tmp.active = true;
      
      
      // actual data
      dataset = {
        id: 0,
        content: name,
        options: {
          drawPoints: true,
          interpolation: true
        }
      };
      
      tmp.groups.push(dataset);
      
      // dummy data to show norm references
      if (angular.isNumber(min) && angular.isNumber(max)) {
        $log.debug('Min and Max are number');
        normmax = {
          id: 1,
          content: 'Normbereich',
          options: {
            drawPoints: false,
            interpolation: true,
            className: 'refgreen',
            shaded: {
              orientation: 'group',
              groupId: '2'
            }
          }
        };
        
        normmin = {
          id: 2,
          content: 'Unterer Normwert',
          options: {
            excludeFromLegend: true,
            drawPoints: false,
            interpolation: true
          }
        };
  
        tmp.groups.push(normmax);
        tmp.groups.push(normmin);
        $log.debug('tmp object: ', tmp);
      }
      
      
      if (!angular.isArray(patient.trackers)) {
        patient.trackers = [];
      }
      patient.trackers.push(tmp);
      patient.$save();
    };
    
    
    TrackercommonsBase.addDatapoint = function (patient, trackerindex, date, data, multi) {
      var tmp = {};
      var min = {};
      var max = {};
      tmp.x = date;
      tmp.y = data;
      tmp.group = 0; // actual data resides in group 0, reference data in groups 1 & 2

      if (!angular.isArray(patient.trackers[trackerindex].items)) {
        patient.trackers[trackerindex].items = [];
      }
      
      // push data
      patient.trackers[trackerindex].items.push(tmp);
      
      // push reference data
      if (angular.isNumber(patient.trackers[trackerindex].normmin) && angular.isNumber(patient.trackers[trackerindex].normmax)) {
        max.x = date;
        max.y = patient.trackers[trackerindex].normmax;
        max.group = 1;
        min.x = date;
        min.y = patient.trackers[trackerindex].normmin;
        min.group = 2;
        patient.trackers[trackerindex].items.push(max);
        patient.trackers[trackerindex].items.push(min);
      }

      // saving is not clever if run in a loop
      if (multi === false) {
        patient.$save();
      }
      
    };
    
    
    return TrackercommonsBase;
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('tracker')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('tracker', {
        url: '/tracker',
        templateUrl: 'tracker/tracker.tpl.html',
        controller: 'TrackerCtrl',
        controllerAs: 'tracker'
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name tracker.factory:Tracker
   *
   * @description
   *
   */
  angular
    .module('tracker')
    .factory('Tracker', Tracker);

  function Tracker() {
    var TrackerBase = {};
    TrackerBase.someValue = 'Tracker';
    TrackerBase.someMethod = function () {
      return 'Tracker';
    };
    return TrackerBase;
  }
}());

(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name tracker.directive:editTracker
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="tracker">
   <file name="index.html">
   <edit-tracker></edit-tracker>
   </file>
   </example>
   *
   */
  angular
    .module('tracker')
    .directive('editTracker', editTracker);
  
  
  editTracker.$inject = ['$log'];
  
  function editTracker($log) {
    return {
      restrict: 'EA',
      scope: {
        tracker: '=',
        patient: '='
      },
      templateUrl: 'tracker/edit-tracker-directive.tpl.html',
      replace: false,
      controllerAs: 'etCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'editTracker';
        
        vm.recalcRef = recalcRef;
        vm.addDatapoint = addDatapoint;
        function addDatapoint() {
          var tmp = {};
          var max = {};
          var min = {};
          tmp.x = vm.newdp.x;
          tmp.y = vm.newdp.y;
          tmp.group = 0;
          $log.debug('new datapoint: ', tmp);
          // check for data array
          if (!angular.isArray(vm.patient.trackers[vm.tracker].items)) {
            vm.patient.trackers[vm.tracker].items = [];
          }
          
          // push data
          vm.patient.trackers[vm.tracker].items.push(tmp);
          
          // push reference data
          if (angular.isNumber(vm.tracker.normmin) && angular.isNumber(vm.tracker.normmax)) {
            max.x = vm.newdp.x;
            max.y = vm.tracker.normmax;
            max.group = 1;
            min.x = vm.newdp.x;
            min.y = vm.tracker.normmin;
            min.group = 2;
            vm.patient.trackers[vm.tracker].items.push(max);
            vm.patient.trackers[vm.tracker].items.push(min);
          }
          
          
          vm.patient.$save();
          vm.newpd = {};
        }
        
        function recalcRef() {
          var i;
          for (i = 0; i < vm.patient.trackers[vm.tracker].items.length; i++) {
            if (vm.patient.trackers[vm.tracker].items[i].group === 1) {
              vm.patient.trackers[vm.tracker].items[i].y = vm.patient.trackers[vm.tracker].normmax;
            }
            if (vm.patient.trackers[vm.tracker].items[i].group === 2) {
              vm.patient.trackers[vm.tracker].items[i].y = vm.patient.trackers[vm.tracker].normmin;
            }
          }
          vm.patient.$save();
        }
        
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name tracker.directive:2dgraph
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="tracker">
       <file name="index.html">
        <twodgraph></twodgraph>
       </file>
     </example>
   *
   */
  angular
    .module('tracker')
    .directive('twodgraph', twodgraph);
  
  twodgraph.$inject = ['$log'];
  
  function twodgraph($log) {
    return {
      restrict: 'E',
      scope: {
        data: '=',
        graphid: '=',
        events: '='
      },
      templateUrl: 'tracker/2dgraph-directive.tpl.html',
      replace: false,
      controllerAs: 'tdCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = '2dgraph';
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        
        // Create the chart
        var graph = null;
    
        scope.$watch('tdCtrl.data', function (data) {
          // $log.debug('graph data: ', data);
          // Sanity check
          if (angular.isObject(data) && angular.isArray(data.items) && data.items.length > 0) {
            // $log.debug('graph data found');
            // If we've actually changed the data set, then recreate the graph
            // We can always update the data by adding more data to the existing data set
            if (graph !== null) {
              graph.destroy();
            }
        
            /* var items = new vis.DataSet(scope.data.items);
             var groups = new vis.DataSet(scope.data.groups);*/
        
            // Create the graph2d object
            graph = new vis.Graph2d(element[0], data.items, data.groups, data.graphoptions);
            // $log.debug('graph: ', graph);
          }
          else {
            // $log.debug('no graph data found');
          }
        }, true);
    
      }
      /* link: function (scope, element, attrs) {
        /!* jshint unused:false *!/
        /!* eslint "no-unused-vars": [2, {"args": "none"}] *!/
 

        // Create the chart
        var graph = null;
  
        scope.$watch('data', function () {
          // Sanity check
          if (scope.data === null) {
            return;
          }
    
          // If we've actually changed the data set, then recreate the graph
          // We can always update the data by adding more data to the existing data set
          if (graph !== null) {
            graph.destroy();
          }
    
          // Create the graph2d object
          graph = new vis.Graph2d(element[0], scope.data.items, scope.data.groups, scope.data.graphoptions);
          // graph = new vis.Graph2d(, scope.data.items, scope.data.groups, scope.data.graphoptions);
    
          // Attach an event handler if defined
          angular.forEach(scope.events, function (callback, event) {
            if (graphEvents.indexOf(String(event)) >= 0) {
              graph.on(event, callback);
            }
          });
    
          // onLoad callback
          if (scope.events !== null && scope.events.onload != null &&
            angular.isFunction(scope.events.onload)) {
            scope.events.onload(graph);
          }
        });
  
        scope.$watchCollection('options', function (options) {
          if (graph === null) {
            return;
          }
          graph.setOptions(options);
        });
        
      }*/
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name todo
   * @description
   *
   */
  angular
    .module('todo', [
      'ui.router',
      'knalli.angular-vertxbus'
    ]);
}());

(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name todo.factory:Todoservice
   *
   * @description
   *
   */
  angular
    .module('todo')
    .factory('Todo', Todo)
    .config(config);

  config.$inject = ['restmodProvider'];

  function config(restmodProvider) {
    restmodProvider.rebase({
      $config: {
        primaryKey: '_id'
      }
    });
  }


  Todo.$inject = ['restmod'];

  function Todo(restmod) {
    return restmod.model('/routes/todos').mix({
      // owner: { belongsTo: 'User', key: 'userid' }, // todo add user to todowidget
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true},
      done: {init: false},
      owner: {init: ''}
    });
  }


}());

/**
 * Created by lari on 12/01/2017.
 */

(function () {
  'use strict';
  /**
   * @ngdoc directive
   * @name todo.directive:todowidget
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="todo">
   <file name="index.html">
   <todowidget></todowidget>
   </file>
   </example>
   *
   */
  
  // var todowidget;
  
  angular
    .module('todo')
    .component('todowidget', {
      restrict: 'E',
      templateUrl: 'todo/todowidget-directive.tpl.html',
      controllerAs: 'vm',
      controller: ['Todo', '$log', '$cookies', '$window', 'vertxEventBusService', '$interval', '$timeout',
        function (Todo, $log, $cookies, $window, vertxEventBusService, $interval, $timeout) {
          var vm = this;
          
          vm.todos = {};
  
          vm.save = save;
          vm.add = add;
          vm.calcdue = calcdue;

          // initalise data bindings
          vm.todos.upcoming = upcoming();
          vm.todos.overdue = dueandoverdue();
          vm.todos.lastcompleted = lastcompleted();
          
          // after a minute start repeating refreshs every 10 minutes
          $timeout(function () {
            $interval(function () {
              refreshAll();
            }, 600000); }, 60000);
          
          function add() {
            vm.newitem.done = false;
            vm.newitem.user = $cookies.get('username');
            if (!vm.newitem.due) {
              vm.newitem.due = moment().toISOString();
              vm.newitem.duestr = 'Sofort';
            }
            Todo.$create(vm.newitem).$then(function () {
              vm.todos.upcoming.$refresh();
              vm.todos.overdue.$refresh();
              vm.todos.lastcompleted.$refresh();
            });
            vm.newitem = '';
          }
          
          function save(item) {
            if (item.done === true) {
              item.completed = moment().toISOString();
            }
            else {
              delete item.completed;
            }
            item.$save().$then(function () {
              refreshAll();
            });
          }
          
          function refreshAll() {
            vm.todos.upcoming.$refresh();
            vm.todos.overdue.$refresh();
            vm.todos.lastcompleted.$refresh();
          }
          
          function calcdue(days, str) {
            vm.newitem.due = moment().add(days, 'days').toISOString();
            vm.newitem.duestr = str;
          }
          
          function lastcompleted() {
            return Todo.$search({
              completed: {$gte: moment().subtract(3, 'days').toISOString()},
              done: true
            });
          }
          
          function upcoming() {
            return Todo.$search({
              due: {$gte: moment().add('days', 1).startOf('day').toISOString()},
              done: false
            });
          }
          
          function dueandoverdue() {
            return Todo.$search({
              due: {$lt: moment().add('days', 1).startOf('day').toISOString()},
              done: false
            });
          }
          
          // listen for todos updates from other tabs
          vertxEventBusService.on('todos.update', function (message) {
            var messages = message.split(':');
            $log.debug('Todos updated: ', message);
            if (messages[1] !== $window.sessionStorage.getItem('sprtabid')) {
              refreshAll();
              $log.debug('(todo) refreshed todos');
            }
            else {
              $log.debug('(todo) ignored todo refresh');
            }
          });
        }]
    });
  
  // klammerfriedhof
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name testdata
   * @description
   *
   */
  angular
    .module('testdata', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('testdata')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('testdata', {
        url: '/testdata',
        views: {
          main: {template: '<testdata></testdata>'},
          // leftbar: {template: ''},
          rightupper: {template: '<todowidget></todowidget>'},
          // rightlower: {template: '<chat></chat>'}
          rightlower: {template: ''}
        }
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name testdata.factory:Testdata
   *
   * @description
   *
   */
  angular
    .module('testdata')
    .factory('Testdata', Testdata);

  function Testdata() {
    var TestdataBase = {};
    TestdataBase.someValue = 'Testdata';
    TestdataBase.someMethod = function () {
      return 'Testdata';
    };
    return TestdataBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name testdata.directive:testdata
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="testdata">
       <file name="index.html">
        <testdata></testdata>
       </file>
     </example>
   *
   */
  angular
    .module('testdata')
    .directive('testdata', testdata);

  testdata.$inject = ['Patient', 'Consult', 'Examination', '$log'];

  function testdata(Patient, Consult, Examination, $log) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'testdata/testdata-directive.tpl.html',
      replace: false,
      controllerAs: 'tdCtrl',
      controller: function () {
        var vm = this;
        var i = 0;
        var femnames = [];
        var malenames = [];
        var lastnames = [];
        var streets = [];
        var cities = [];
        var svs = [];
        var numbers = [];
        var workplaces = [];
        var titlefronts = [];
        var titlebacks = [];
        var keywords = [];
        var gender = [];
        vm.numberofsets = 10;
        vm.setsgenerated = 0;
        vm.incConsults = false;
        vm.incExams = false;
        vm.incFamily = true;
        vm.generatePatients = generatePatients;

        function generatePatients() {
          var newpat = {};
          var date;
          vm.setsgenerated = 0;
          vm.numberofsets = parseInt(vm.numberofsets, 10);
          for (i = 0; i < vm.numberofsets; i++) {
            newpat = {};
            newpat.titlefront = randomize(titlefronts);
            newpat.titleback = randomize(titlebacks);
            newpat.address = randomize(streets) + ' ' + randomize(numbers);
            newpat.postcode = Math.floor(Math.random() * 1000 + 1000);
            newpat.titlefront = randomize(titlefronts);
            newpat.titlefront = randomize(titlefronts);
            date = randombirthdate();
            newpat.birthdate = date.format('DD.MM.YYYY');
            newpat.svnr = '9999' + date.format('DDMMYY');
            // newpat.svs = randomize(svs);
            newpat.lastname = randomize(lastnames);
            newpat.city = randomize(cities);
            newpat.employer = randomize(workplaces);
            newpat.practitioner = 'Dr. M. Musterarzt';
            newpat.keywords = [randomize(keywords)];
            newpat.test = true;
            newpat.created = randomcreatedate();
            if (i % 2 === 0) {
              newpat.firstname = randomize(femnames);
              newpat.gender = 'weiblich';
              newpat.image = '/img/head_female.png';
            }
            else {
              newpat.firstname = randomize(malenames);
              newpat.gender = 'männlich';
              newpat.image = '/img/head_male.png';
            }


            Patient.$create(newpat).$asPromise().then(function () {
              vm.setsgenerated++;
            });
          }

        }

        function randombirthdate() {
          var day = Math.floor(Math.random() * 28 + 1);
          var month = Math.floor(Math.random() * 12 + 1);
          var year = Math.floor(Math.random() * 100 + 1914);
          return moment(new Date(year, month, day));
        }

        function randomcreatedate() {
          var day = Math.floor(Math.random() * 28 + 1);
          var month = Math.floor(Math.random() * 12 + 1);
          var year = Math.floor(Math.random() * 2 + 2013);
          return new Date(year, month, day);
        }

        function randomize(value) {
          var val = value[Math.floor(Math.random() * value.length)];
          return val;
        }

        keywords = [
          {col: 'success', key: 'Gastritis', created: '4.11.2015', nr: 1, updated: '4.3.2016'},
          {col: 'info', key: 'Erstbesuch', created: '4.11.2015', nr: 1, updated: '4.3.2016'},
          {col: 'danger', key: 'Hep C', created: '4.11.2015', nr: 1, updated: '4.3.2016'},
          {col: 'primary', key: 'Ulcus duodeni', created: '4.11.2015', nr: 1, updated: '4.3.2016'},
          {col: 'info', key: 'Erstbesuch', created: '4.11.2015', nr: 1, updated: '4.3.2016'},
          {col: 'success', key: 'Ulcus', created: '4.11.2015', nr: 1, updated: '4.3.2016'},
          {col: 'warning', key: 'Morbus Chron', created: '4.11.2015', nr: 1, updated: '4.3.2016'}
        ];

        gender = ['weiblich','männlich','unbekannt'];
        numbers = [3, 34, 11, 56, 73, 47, 62, 79, 24, 23, 67, 114, 345, '24/Loft 3', '110-116/7/34', '34/2/17', '51-57/3/24']
        streets = ['Mustergasse', 'Beispielsplatz', 'Kirschenweg', 'Landstra\u00DFe', 'Haupstra\u00DFe', 'Ring'];
        cities = ['Satellitenstadt', 'Musterort', 'Mustergemeinde', 'Entenhausen', 'Musterstadt', 'Phantasia'];
        svs = ['WGGK', 'N\u00D6GKK', 'StGKK', 'O\u00D6', 'BGKK', 'SVA', 'BVA', 'EBK'];
        workplaces = ['ACME GmbH', 'REWE AG', 'Telekom Austria AG'];
        titlefronts = ['Dr.', 'Dr. DI.', 'Dipl.Ing.', 'Mag', '', '', '', '', '', '', ''];
        titlebacks = ['BSc', 'MSc.', 'MBA', 'BA', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
        lastnames = ['Mustermensch', 'Musterpatient', 'Musterfamilie', 'Mustermax', 'Mustermann', 'Musterquux',
          'Musterfoo', 'Musterix', 'Musterax', 'Mustermini', 'Mustermaxi', 'Musterm\u00FCller', 'Mustermayr',
          'Testmensch', 'Testpatient', 'Testmayer', 'Testiwitsch', 'Testorax', 'Quuxmuster', 'Foomuster',
          'Quuxmensch', 'Quuxpatient', 'Foopatient', 'Meyerpatient', 'Maierpatient', 'Mayerpatient',
          'M\u00FCllerpatient', 'Huberpatient', 'Prohaskapatient', 'Patientmuster', 'Patienthuber'];

        femnames = ['Anna', 'Sophia', 'Emilia', 'Emma', 'Sophie', 'Marie', 'Johanna', 'Mia', 'Helena', 'Luisa', 'Laura', 'Lena',
          'Ella', 'Amelie', 'Clara', 'Lara', 'Julia', 'Lina', 'Theresa', 'Paula', 'Antonia', 'Emily', 'Elisa', 'Lea',
          'Marlene', 'Charlotte', 'Magdalena', 'Hannah', 'Greta', 'Leonie', 'Matilda', 'Mila', 'Hanna', 'Valentina',
          'Maja', 'Sarah', 'Sara', 'Franziska', 'Maria', 'Josephine', 'Mathilda', 'Isabella', 'Elena', 'Lisa', 'Sofia',
          'Victoria', 'Katharina', 'Klara', 'Lilly', 'Annika', 'Elisabeth', 'Alina', 'Ida', 'Carla', 'Leni', 'Carlotta',
          'Lucia', 'Maya', 'Nora', 'Isabel', 'Frida', 'Jasmin', 'Linda', 'Mina', 'Paulina', 'Alma', 'Luise', 'Rosalie',
          'Fiona', 'Nele', 'Viktoria', 'Pauline', 'Jana', 'Louisa', 'Miriam', 'Olivia', 'Stella', 'Zoe', 'Elif', 'Emilie',
          'Eva', 'Helene', 'Josefine', 'Nina', 'Romy', 'Julie', 'Lia', 'Lotta', 'Melina', 'Pia', 'Alexandra', 'Frieda',
          'Isabelle', 'Nisa', 'Philippa', 'Vanessa', 'Anastasia', 'Flora', 'Larissa', 'Marla', 'Martha', 'Medina',
          'Melisa', 'Milena', 'Mira', 'Natalie', 'Rosa', 'Aurelia', 'Ava', 'Ela', 'Lorena', 'Veronika', 'Aylin',
          'Cecilia', 'Elina', 'Hana', 'Karla', 'Luna', 'Mara', 'Melissa', 'Meryem', 'Rebecca', 'Ronja', 'Valerie',
          'Alisa', 'Ana', 'Chiara', 'Christina', 'Elin', 'Elise', 'Felicitas', 'Leyla', 'Livia', 'Lotte', 'Milla',
          'Noemi', 'Selin', 'Alea', 'Alicia', 'Amira', 'Annabelle', 'Eleni', 'Elsa', 'Emelie', 'Florentine', 'Giulia',
          'Leandra', 'Lilli', 'Liv', 'Marta', 'Michelle', 'Selma', 'Zara', 'Alessia', 'Alice', 'Alissa', 'Angelina',
          'Anika', 'Cosima', 'Kristina', 'Lana', 'Lejla', 'Mariam', 'Mona', 'Natalia', 'Nicole', 'Nika', 'Sina',
          'Smilla', 'Sonja', 'Tara', 'Teresa', 'Tuana', 'Vivien', 'Yara', 'Zeynep', 'Ada', 'Alba', 'Anouk', 'Carolina',
          'Filippa', 'Finja', 'Kira', 'Liliana', 'Lola', 'Madita', 'Marlena', 'Talia', 0, 'Agnes', 'Alexa', 'Amalia',
          'Amanda', 'Amelia', 'Amina', 'Anisa', 'Anja', 'Anne', 'Anni', 'Ariana', 'Ariane', 'Diana', 'Emely', 'Franka',
          'Helen', 'Ines', 'Jule', 'Leah', 'Leona', 'Lily', 'Luana', 'Lucie', 'Malina', 'Mariella', 'Marina', 'Maryam',
          'Mathilde', 'Sofija', 'Tamara', 'Alva', 'Annabell', 'Cataleya', 'Celina', 'Cleo', 'Dalia'];
        malenames = ['Maximilian', 'Felix', 'David', 'Moritz', 'Jakob', 'Paul', 'Julian', 'Alexander', 'Vincent', 'Leon',
          'Lukas', 'Noah', 'Anton', 'Emil', 'Benjamin', 'Luis', 'Valentin', 'Jonas', 'Leo', 'Daniel', 'Luca', 'Philipp',
          'Elias', 'Jonathan', 'Tim', 'Niklas', 'Samuel', 'Simon', 'Leopold', 'Louis', 'Ben', 'Leonard', 'Oskar', 'Benedikt',
          'Ludwig', 'Sebastian', 'Fabian', 'Ferdinand', 'Florian', 'Julius', 'Max', 'Adrian', 'Gabriel', 'Raphael', 'Konstantin',
          'Lucas', 'Henri', 'Johann', 'Jan', 'Finn', 'Linus', 'Nicolas', 'Rafael', 'Theo', 'Tobias', 'Xaver', 'Lorenz', 'Levi',
          'Oscar', 'Johannes', 'Korbinian', 'Michael', 'Nico', 'Quirin', 'Christian', 'Kilian', 'Bastian', 'Nils', 'Ali',
          'Leonhard', 'Liam', 'Theodor', 'Adam', 'Arthur', 'Constantin', 'Dominik', 'Erik', 'Karl', 'Luka', 'Mateo', 'Aaron',
          'Mustafa', 'Oliver', 'Timo', 'Tom', 'Emir', 'Leonardo', 'Jonah', 'Levin', 'Manuel', 'Mats', 'Matteo', 'Patrick',
          'Severin', 'Yusuf', 'Antonio', 'Diego', 'Franz', 'Frederik', 'Georg', 'Gustav', 'Henry', 'Jacob', 'Jason',
          'Martin', 'Toni', 'Viktor', 'Deniz', 'Emilio', 'Hannes', 'Lennart', 'Malik', 'Milan', 'Carl', 'Joel', 'Josef',
          'Justus', 'Lars', 'Marcel', 'Mika', 'Nick', 'Noel', 'Otto', 'Sami', 'Tristan', 0, 'Alexandros', 'Bruno',
          'Damian', 'Eren', 'Fritz', 'Joshua', 'Marc', 'Marko', 'Marlon', 'Maxim', 'Philip', 'Richard', 'Robert', 'Robin',
          'Yasin', 'Amin', 'Benno', 'Christoph', 'Clemens', 'Colin', 'Enes', 'Fabio', 'Ismail', 'Leander', 'Marco', 'Mark',
          'Markus', 'Mert', 'Mikail', 'Ole', 'Victor', 'Alex', 'Amar', 'Amir', 'Andreas', 'Bilal', 'Carlos', 'Filip', 'Hugo',
          'Armin', 'Batuhan', 'Cornelius', 'Dario', 'Diar', 'Emanuel', 'Eric', 'Frederic', 'Friedrich', 'Henrik', 'Jack', 'James',
          'Jannes', 'Jannik', 'Jannis', 'Jayden', 'Jeremy', 'John', 'Joseph', 'Kaspar', 'Kerem', 'Koray', 'Levent', 'Lian',
          'Lovis', 'Luke', 'Mohammed', 'Muhammad', 'Muhammed', 'Nathaniel', 'Neo', 'Raffael', 'Sam', 'Selim', 'Tiago', 'Timon',
          'Vinzenz', 'Vitus', 'Adriano', 'Aiden', 'Alessio', 'Andrej', 'Angelo', 'Arda', 'Augustin', 'Aurel', 'Bela', 'Can',
          'Caspar', 'Cem', 'Cinar', 'Cristiano', 'Daris', 'Ege', 'Eliah', 'Elijah', 'Elliot', 'Emin'];



      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2,  {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name share
   * @description
   *
   */
  angular
    .module('share', [
      'ui.router',
      'ui.bootstrap'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('share')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('shares', {
        url: '/shares',
        views: {
          'main': {template: '<share-editor></share-editor>'},
          //'leftbar': {template: '<patient-today></patient-today>'},
          'rightupper': {template: '<todowidget></todowidget>'},
          'rightlower': {template: '<h3>I\'m a chat window</h3>'}
        }
      });


  }


}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name share.factory:Share
   *
   * @description
   *
   */
  angular
    .module('share')
    .factory('Share', Share);

  Share.$inject = ['restmod'];

  function Share(restmod) {
    return restmod.model('/routes/shares');
  }


}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name share.directive:shareEditor
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="share">
   <file name="index.html">
   <share-editor></share-editor>
   </file>
   </example>
   *
   */
  angular
    .module('share')
    .directive('shareEditor', shareEditor);

  function shareEditor() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'share/share-editor-directive.tpl.html',
      replace: false,
      controllerAs: 'shareCtrl',
      controller: 'ShareCtrl',
      bindToController: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name share.controller:ShareCtrl
   *
   * @description
   *
   */
  angular
    .module('share')
    .controller('ShareCtrl', ShareCtrl);

  ShareCtrl.$inject = ['Share', '$uibModal'];

  function ShareCtrl(Share, $uibModal) {
    var vm = this;
    vm.ctrlName = 'ShareCtrl';
    vm.Share = Share;
    vm.addShare = addShare;
    vm.allshares = vm.Share.$search();
    vm.editShare = editShare;

    function addShare() {
      vm.newshare.active = true;
      vm.newshare.created = new Date().toISOString();
      vm.newshare.isopen = false;
      vm.allshares.$create(vm.newshare);
      vm.newshare = {};
    }

    function editShare(share) {
      $uibModal.open({
        templateUrl: 'shareedittempl',
        size: 'lg',
        controller: ['$uibModalInstance', 'share', ShareEditCtrl],
        controllerAs: 'sheCtrl',
        resolve: {
          share: function () {
            return share;
          }
        }
      });


    }
  }

  function ShareEditCtrl($uibModalInstance, share) {
    var vm = this;
    vm.share = share;
    vm.$uibModalInstance = $uibModalInstance;


  }


}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name service
   * @description
   *
   */
  angular
    .module('service', [
      'ui.router'
    ])
    .filter('aek', function () {
      return function (points) {
        if (!points) {
          return '';
        }
        return points * 0.15;
      };
    });


}());



(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name service.directive:serviceeditor
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="service">
   <file name="index.html">
   <serviceeditor></serviceeditor>
   </file>
   </example>
   *
   */
  angular
    .module('service')
    .directive('serviceeditor', serviceeditor);

  serviceeditor.$inject = ['Service'];
  
  function serviceeditor(Service) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'service/serviceeditor-directive.tpl.html',
      replace: false,
      controllerAs: 'servCtrl',
      controller: function () {
        var vm = this;
        vm.ctrlName = 'ServiceCtrl';
        vm.Service = Service;
        vm.services = vm.Service.$search();

        vm.addAnother = addAnother;

        function addAnother() {
          vm.services.$create(vm.newservice);
          vm.newservice = {};
          return vm.services;
        }
      },
      bindToController: true
    };
  }
}());

(function () {
  'use strict';

  angular
    .module('service')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('services', {
        url: '/services',
        views: {
          main: {template: '<serviceeditor></serviceeditor>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });
  }


}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name service.factory:Service
   *
   * @description
   *
   */
  angular
    .module('service')
    .factory('Service', Service);

  Service.$inject = ['restmod'];

  function Service(restmod) {
    return restmod.model('/routes/servicecatalog').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true}
    });
  }


}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name rlog
   * @description
   *
   */
  angular
    .module('rlog', [
      'ui.router',
      'knalli.angular-vertxbus'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name rlog.service:Rlog
   *
   * @description
   *
   */
  angular
    .module('rlog')
    .service('Rlog', Rlog);

  Rlog.$inject = ['vertxEventBusService'];
  
  function Rlog(vertxEventBusService) {
    this.debug = function (module, directive, text, payload) {
      var message = {};
      message.module = module;
      message.directive = directive;
      message.text = text;
      message.payload = payload;
      
      return vertxEventBusService.publish('rlog.entry', message);
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name reminder
   * @description
   *
   */
  angular
    .module('reminder', [
      'ui.router',
      'ui.bootstrap'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('reminder')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('reminder', {
        url: '/reminder',
        templateUrl: 'reminder/reminder.tpl.html',
        controller: 'ReminderCtrl',
        controllerAs: 'reminder'
      });
  }
}());

(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name reminder.factory:ReminderService
   *
   * @description
   *
   */
  angular
    .module('reminder')
    .factory('Reminder', Reminder)
    .factory('Remindersearch', Remindersearch)
    .factory('Remindercount', Remindercount)
    .factory('Reminderpaged', Reminderpaged)
    .config(config);
  
  
  config.$inject = ['restmodProvider'];
  
  function config(restmodProvider) {
    restmodProvider.rebase({
      $config: {
        primaryKey: '_id'
      }
    });
  }
  
  
  Reminder.$inject = ['restmod'];
  
  function Reminder(restmod) {
    return restmod.model('/routes/reminders').mix({
      openConsult: {hasOne: 'Patient'},
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true},
      done: {init: false}
    });
  }
  
  Remindersearch.$inject = ['restmod'];
  
  function Remindersearch(restmod) {
    return restmod.model('/routes/reminders/patahead');
  }
  
  
  Remindercount.$inject = ['restmod'];
  function Remindercount(restmod) {
    return restmod.model('/routes/reminders/count');
  }
  
  
  Reminderpaged.$inject = ['restmod'];
  function Reminderpaged(restmod) {
    return restmod.model('/routes/reminders/pagedlist');
  }
  

}());

(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name reminder.directive:patientReminder
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="reminder">
   <file name="index.html">
   <patient-reminder></patient-reminder>
   </file>
   </example>
   *
   */
  angular
    .module('reminder')
    .directive('patientReminder', patientReminder);
  
  patientReminder.$inject = ['Reminder', 'Patient', '$log'];
  
  function patientReminder(Reminder, Patient, $log) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'reminder/patient-reminder-directive.tpl.html',
      replace: false,
      controllerAs: 'premCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'patientReminder';
        vm.addTodoEntry = addTodoEntry;
        vm.calcdue = calcdue;
        vm.save = save;
        vm.newreminder = {};
        // vm.todos = [];
  
        // vm.todos = vm.patient.reminders.$refresh();
  
  
        /* vm.patient.reminders.$refresh().$then(function (reminders) {
          vm.todos = reminders;
          $log.debug('******************** patient todosCtr -  reminders loaded:', reminders);
        }, function ( ) {
          $log.debug('******************** patient todosCtr -  loading reminders failed:', reminders);
        });*/
        
/*        vm.patient.reminders.$refresh().$asPromise().then(function (reminders) {
          vm.todos = reminders;
        });*/
        
        /*        vm.patient.reminders.$refresh().$then(function (reminders) {
         vm.todos = reminders;
         });*/
        
        function addTodoEntry() {
          vm.newreminder.done = false;
          vm.newreminder.pfname = vm.patient.firstname;
          vm.newreminder.plname = vm.patient.lastname;
          vm.newreminder.psvnr = vm.patient.svnr;
          vm.newreminder.patientid = vm.patient._id;
          if (!vm.newreminder.due) {
            vm.newreminder.due = moment().toISOString();
            vm.newreminder.duestr = 'Sofort';
          }
          
          // doesn't work, needs new backend route
          // vm.todos.$create(vm.newreminder);
          
          Reminder.$create(vm.newreminder).$then(function () {
            vm.patient.reminders.$refresh();
            // vm.todos.$refresh();
          });
          vm.newreminder = {};
        }
        
        function calcdue(days, str) {
          vm.newreminder.due = moment().add(days, 'days').toISOString();
          vm.newreminder.duestr = str;
        }
        
        function save(item) {
          var logentry = {};
          logentry.created = moment().toISOString();
          if (item.done === true) {
            item.completed = moment().toISOString();
            logentry.entry = 'Todo abgeschlossen: ' + item.label;
          }
          if (item.done === false) {
            delete item.completed;
            logentry.entry = 'Todo wieder geöffnet: ' + item.label;
          }
          item.$save().$asPromise().then(function () {
            Patient.$find(item.patientid).$asPromise().then(function (pat) {
              if (!angular.isArray(pat.log)) {
                pat.log = [];
              }
              pat.log.push(logentry);
              pat.$save();
            });
          });
        }
        
        
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name reminder.directive:allReminder
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="reminder">
   <file name="index.html">
   <all-reminder></all-reminder>
   </file>
   </example>
   *
   */
  angular
    .module('reminder')
    .directive('allReminder', allReminder);

  allReminder.$inject = ['Reminder', 'Patient', '$log'];

  function allReminder(Reminder, Patient, $log) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'reminder/all-reminder-directive.tpl.html',
      replace: false,
      controllerAs: 'arCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.weeks = 2;
        // vm.upcoming = moment().add(2, 'weeks').toISOString();
        vm.name = 'allReminder';
        vm.save = save;
        vm.todos = {};


        vm.todos.upcoming = upcoming();
        vm.todos.overdue = dueandoverdue();
        vm.todos.lastcompleted = lastcompleted();

        vm.switchRange = switchRange;

        function lastcompleted() {
          return Reminder.$search({
            completed: {
              $lt: moment().toISOString(),
              $gte: moment().subtract(2, 'days').toISOString()
            }, done: true
          });
        }

        function upcoming() {
          return Reminder.$search({
            due: {
              $lt: moment().add(vm.weeks, 'weeks').toISOString(),
              $gte: moment().toISOString()
            }, done: false
          });
        }

        function dueandoverdue() {
          return Reminder.$search({
            due: {$lt: moment().toISOString()},
            done: false
          });
        }

        function switchRange(weeks) {
          vm.weeks = weeks;
          vm.todos.upcoming = upcoming();
        }

        function save(item) {
          var logentry = {};
          logentry.created = moment().toISOString();
          if (item.done === true) {
            item.completed = moment().toISOString();
            logentry.entry = 'Todo abgeschlossen: ' + item.label;
          }
          if (item.done === false) {
            delete item.completed;
            logentry.entry = 'Todo wieder geöffnet: ' + item.label;
          }
          item.$save().$asPromise().then(function () {
            vm.todos.upcoming = upcoming();
            vm.todos.overdue = dueandoverdue();
            vm.todos.lastcompleted = lastcompleted();
            Patient.$find(item.patientid).$asPromise().then(function (pat) {
              if (!angular.isArray(pat.log)) {
                pat.log = [];
              }
              pat.log.push(logentry);
              pat.$save();
            });
          });
        }


      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name printer
   * @description
   *
   */
  angular
    .module('printer', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name printer.factory:Printer
   *
   * @description
   *
   */
  angular
    .module('printer')
    .factory('Printer', Printer)
    .factory('Printerlist', Printerlist);

  Printer.$inject = ['restmod'];

  function Printer(restmod) {
    return restmod.model('/routes/printer');
  }


  Printerlist.$inject = ['restmod'];

  function Printerlist(restmod) {
    var model = restmod.model('/routes/printer');
    var printers = {};
    function getPrinters() {
      return model.$search();
    }

    printers.getPrinters = getPrinters;
    printers.model = model;

    return printers;
  }

}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name pdfprinter
   * @description
   *
   */
  angular
    .module('pdfprinter', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('pdfprinter')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('pdfprinter', {
        url: '/pdfprinter',
        templateUrl: 'pdfprinter/pdfprinter.tpl.html',
        controller: 'PdfprinterCtrl',
        controllerAs: 'pdfprinter'
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name pdfprinter.factory:Pdfprinter
   *
   * @description
   *
   */
  angular
    .module('pdfprinter')
    .factory('Pdfprinter', Pdfprinter);
    // .config(config);
/*
  config.$inject = ['restmodProvider'];

  function config(restmodProvider) {
    restmodProvider.rebase({
      $config: {
        primaryKey: '_id'
      }
    });
  }
*/
  Pdfprinter.$inject = ['restmod'];

  function Pdfprinter(restmod) {
    return restmod.model('/routes/pdfprinter');
  }


}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name pdfprinter.controller:PdfprinterCtrl
   *
   * @description
   *
   */
  angular
    .module('pdfprinter')
    .controller('PdfprinterCtrl', PdfprinterCtrl);

  function PdfprinterCtrl() {
    var vm = this;
    vm.ctrlName = 'PdfprinterCtrl';
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name patient
   * @description
   *
   */
  angular
    .module('patient', [
      'ui.router',
      'ui.bootstrap',
      'utils.keywords',
      'consult',
      'examination',
      'helpers.doctor',
      'helpers.sv',
      'drug',
      'labvalue',
      'knalli.angular-vertxbus',
      'cfp.hotkeys',
      'config',
      'reminder',
      'helpers.zipcode',
      'datetime'
    ])
    .filter('drugname', function () {
      return function (value) {
        if (!value) {
          return '';
        }
        return value.split(' ')[0];
      };
    });
}());

(function () {
  'use strict';
  /**
   * @ngdoc directive
   * @name patient.directive:patientVert
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-vert cols="4" buttons="true"></patient-vert>
   </file>
   </example>
   *
   */
  angular
    .module('patient')
    .directive('patientVert', patientVert);

  function patientVert() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'patient/patient-vert-directive.tpl.html',
      replace: false,
      controllerAs: 'patCtrl',
      bindToController: true,
      controller: 'PatientCtrl',
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        /* eslint "no-unused-vars": [2, {"vars": "none"}] */
        var col = 12;
        var buttons = true;
        col = attrs.col;
        buttons = attrs.buttons;
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:patientTodos
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-todos></patient-todos>
   </file>
   </example>
   *
   */
  angular
    .module('patient')
    .directive('patientTodos', patientTodos);

  function patientTodos() {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'patient/patient-todos-directive.tpl.html',
      replace: false,
      controllerAs: 'ptdCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.addTodoEntry = addTodoEntry;

        function addTodoEntry() {
          if (!vm.patient.hasOwnProperty('todos') || vm.patient.todos.constructor !== Array) {
            vm.patient.todos = [];
          }
          vm.patient.todos.push({created: new Date().toISOString(), label: vm.patient.newtodo, done: false});
          vm.patient.newtodo = '';
          vm.patient.$save();
        }
      }
    };
  }
}());

(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name patient.factory:PatientService
   *
   * @description
   *
   */
  angular
    .module('patient')
    .factory('Patient', Patient)
    .factory('TestPatient', TestPatient)
    .factory('Patientsearch', Patientsearch)
    .factory('Patientcount', Patientcount)
    .factory('Patientpaged', Patientpaged)
    .config(config);


  config.$inject = ['restmodProvider'];

  function config(restmodProvider) {
    restmodProvider.rebase({
      $config: {
        primaryKey: '_id'
      }
    });
  }


  Patient.$inject = ['restmod'];
  
  
  function Patient(restmod) {
    return restmod.model('/routes/patients').mix({
      consults: {hasMany: 'Consult'},
      reminders: {hasMany: 'Reminder'},
      openConsult: {hasOne: 'Consult'},
      openExam: {hasOne: 'Examination'},
      waitinglist: {belongsTo: 'Waitinglist', inverseOf: 'patients', map: 'patwl'},
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true},
      test: {init: false},
      drugs: {init: []},
      todos: {init: []},
      log: {init: []},
      keywords: {init: []},
      svref: {init: {}},
      diagnoses: {init: {}},
      image: {init: '/img/head_male.png'},
      inconsult: {inconsult: false},
      inexam: {inexam: false},
      incheckout: {incheckout: false},
      consultstate: {consultstate: ''},
      checkoutstate: {checkoutstate: ''},
      today: {init: false}
    });
  }

  Patientsearch.$inject = ['restmod'];

  function Patientsearch(restmod) {
    return restmod.model('/routes/patients/patahead');
  }


  Patientcount.$inject = ['restmod'];
  function Patientcount(restmod) {
    return restmod.model('/routes/patients/count');
  }


  Patientpaged.$inject = ['restmod'];
  function Patientpaged(restmod) {
    return restmod.model('/routes/patients/pagedlist');
  }


  TestPatient.$inject = ['restmod'];

  function TestPatient(restmod) {
    return restmod.model('/routes/patients').mix({
      consults: {hasMany: 'Consult'},
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true},
      test: {init: true},
      firstname: {init: 'Maximilian'},
      lastname: {init: 'Mustermann'},
      birthdate: {init: '01.01.2010'},
      svnr: {init: '1234010110'},
      address: {init: 'Mustergasse 24'},
      postcode: {init: '1010'},
      city: {init: 'Wien'},
      insuredwith: {init: {name: 'Maria Mustermann', svnr: '432101070'}},
      drugs: {init: []},
      todos: {init: []},
      log: {init: []},
      keywords: {init: [{key: 'Erstbesuch', col: 'info'}]},
      diagnoses: {init: {}},
      image: {init: '/img/head_male.png'},
      inconsult: {inconsult: false},
      today: {init: false}
    });
  }


}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:patientSearch
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-search></patient-search>
   </file>
   </example>
   *
   */
  angular
    .module('patient')
    .directive('patientSearch', patientSearch);

  patientSearch.$inject = ['Patientsearch', '$state', '$log'];

  function patientSearch(Patientsearch, $state, $log) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'patient/patient-search-directive.tpl.html',
      replace: false,
      controllerAs: 'psCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.showpatient = showpatient;
        vm.getNames = getNames;

        // typeahead patient search backend query
        function getNames(val) {
          console.log('loading names');

          return Patientsearch.$search({query: val}).$asPromise().then(function (response) {
            console.log('response: ', response);
            return response.map(function (patient) {
              patient.text = patient.firstname + ' ' + patient.lastname + ', ' + patient.svnr;
              return patient;
            });
          });
        }

        // typeahead patient search action
        function showpatient($item, $model, $label) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          var patid = $model._id;
          var state;
          $log.debug('$model: ', $model);
          $log.debug('consultstate bool: ', $model.inconsult);
          $log.debug('checkoutstate bool: ', $model.incheckout);
          $log.debug('consultstate: ', state);
          $state.go('patientrecord.current', {patientid: patid});
          /* if ($model.inconsult === true && $model.incheckout === false) {
            state = 'patientrecord.current.' + $model.consultstate;
            $log.debug('consultstate: ', state);
            $state.go('patientrecord.current.' + $model.consultstate, {patientid: patid});
          }
          else if ($model.inconsult === true && $model.incheckout === true) {
            $state.go('patientrecord.checkout.' + $model.checkoutstate, {patientid: patid});
          }
          else {
            $state.go('patientrecord.history', {patientid: patid});
          }*/
          vm.asyncSelected = null;
        }
      }
    };
  }
}());

(function () {
  'use strict';
  // if you plan on recycling a fixed grid, all aptient states must be sperate states not child states!
  // a state name such as patient.new denotes a child of parent. view names a re lative to the the parent then!
  // the parent must exist, or all children will fail
  // oder is not important!
  angular
    .module('patient')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {

    // Patientenliste
    $stateProvider.state('patients', {
      url: '/patients',
      views: {
        main: {
          template: '<aside ui-view="leftbar" class="hidden-sm hidden-xs hidden-md col-lg-2 col-xl-3"></aside>' +
          '<div ui-view="patientmain" class="col-sm-12 col-md-12 col-lg-10 col-xl-9"><patient-list>' +
          '</patient-list></div>'
        },
        rightupper: {template: '<todowidget></todowidget>'},
        rightlower: {template: '<chat></chat>'}
      }
    });


    // show modal for searching and entering new patients
    $stateProvider.state('newpatient', {
      url: '/newpatient',
      onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {

        console.log('onenter newpatient state: ', $state);
        $uibModal.open({
          template: '<div class="modalspacer"><patient-newsearch dialog="miCtrl.$uibModalInstance"></patient-newsearch></div>',
          controllerAs: 'miCtrl',
          controller: ['$uibModalInstance', function ($uibModalInstance) {
            var vmi = this;
            vmi.$uibModalInstance = $uibModalInstance;
          }],
          size: 'lg'
        });
      }]
    });

    // show modal for patient searching
    $stateProvider.state('patientsearch', {
      url: 'patientsearch',
      onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
        $uibModal.open({
          template: '<div class="modalspacer"><form class="form-vertical"><patient-search></patient-search></form></div>'
        });
      }]
    });


    // Patientenakte
    $stateProvider.state('patientrecord', {
      url: '/patient/:patientid',
      views: {
        main: {template: '<patient-record-view></patient-record-view>'},
        rightupper: {template: '<todowidget></todowidget>'},
        rightlower: {template: '<chat></chat>'}
      }
    });


    // Patientenakte Historie
    $stateProvider.state('patientrecord.history', {
      url: '/consult',
      views: {
        'pr-details': {template: '<consult-list patient="patrCtrl.patient"></consult-list>'}
      }
    });

    // Patientenakte Medikamente
    $stateProvider.state('patientrecord.drugs', {
      url: '/drugs',
      views: {
        'pr-details': {template: '<patient-drug-view patient="patrCtrl.patient"></patient-drug-view>'}
      }
    });

    // Patientenakte abgeschlossene Untersuchungen
    $stateProvider.state('patientrecord.examinations', {
      url: '/examinations',
      views: {
        'pr-details': {template: '<exam-list patient="patrCtrl.patient"></exam-list>'}
      }
    });

    // Patietenakte Dokumente
    $stateProvider.state('patientrecord.documents', {
      url: '/documents',
      views: {
        'pr-details': {templateUrl: 'document/document.tpl.html'}
      }
    });
    
    // Patietenakte Tracker
    $stateProvider.state('patientrecord.trackers', {
      url: '/trackers',
      views: {
        'pr-details': {template: '<trackerlist patient="patrCtrl.patient"></trackerlist>'}
      }
    });

    // Patientenakte Stammdaten
    $stateProvider.state('patientrecord.basicdata', {
      url: '/basicdata',
      views: {
        'pr-details': {template: '<patient-edit patient="patrCtrl.patient"></patient-edit>'}
      }
    });

    // Patientenakte aktuelle Konsultation Automatische Unterseiten Auswahl
    $stateProvider.state('patientrecord.current', {
      url: '/current',
      views: {
        'pr-details': {template: '<current-consult-container patient="patrCtrl.patient"></current-consult-container>'}
      },
      params: {
        patientid: null
      },
      resolve: {
        patient: ["Patient", "$stateParams", "$state", function (Patient, $stateParams, $state) {
          var route;
          console.log('entered patientrecord.current resolve, $stateParams: ', $stateParams);
          Patient.$new($stateParams.patientid).$fetch().$then(function (patient) {
            console.log('entered patientrecord.current resolve, return pat: ', patient);
            console.log('consultstate bool: ', patient.inconsult);
            console.log('checkoutstate bool: ', patient.incheckout);
            console.log('examstate bool: ', patient.inexam);
            console.log('consultstate: ', patient.consultstate);
            console.log('checkoutstate: ', patient.checkoutstate);
          
            if (patient.inconsult === false) {
              // redirect to patient consult history
              route = 'patientrecord.history';
            }
            else if (!patient.hasOwnProperty('consultstate')) {
              // force consultstate to empty
              patient.consultstate = '';
              patient.$save();
              // redirect to patient consult history
              route = 'patientrecord.history';
            }
            else if (patient.consultstate === null || patient.consultstate === '') {
              // redirect to patient consult history
              route = 'patientrecord.history';
            }
            else {
              switch (patient.consultstate) {
                case 'consult':
                  route = 'patientrecord.current.consult';
                  break;
                case 'exam':
                  route = 'patientrecord.current.exam';
                  break;
                case 'examfiles':
                  route = 'patientrecord.current.examfiles';
                  break;
                default:
                  route = 'patientrecord.current.consult';
                  break;
              }
            }
            console.log('patientrecord.current route: ', route);
            $state.go(route, {patientid: $stateParams.patientid, patient: patient});
            return patient;
          });
        }]
      }
    });

    // Patientenakte aktuelle Konsultation - Konsultation
    $stateProvider.state('patientrecord.current.consult', {
      url: '/consult',
      views: {
        'cur-details': {template: '<current-consult patient="consCtrl.patient"></current-consult>'}
      },
      onEnter: function () {
        console.log('patientrecord.current.consult entered');
      }
    });


    // Patientenakte aktuelle Konsultation - aktuelle Untersuchung
    $stateProvider
      .state('patientrecord.current.exam', {
        url: '/exam',
        views: {
          'cur-details': {template: '<exameditor patient="consCtrl.patient"></exameditor>'}
        },
        onEnter: function () {
          console.log('patientrecord.current.exam entered');
        }
      });

    // Patientenakte aktuelle Konsultation - aktuelle Untersuchung Bilderauswahl
    $stateProvider.state('patientrecord.current.examfiles', {
      url: '/files',
      views: {
        'cur-details': {template: '<file-picker patient="consCtrl.patient"></file-picker>'}
      },
      onEnter: function () {
        console.log('patientrecord.current.exam.files entered');
      }
    });


    /*
     // obsolet


     $stateProvider
     .state('patientrecord.current.checkout', {
     url: '/checkout',
     abstract: true,
     views: {
     'cur-details': {templateUrl: 'consult/checkout.tpl.html'}
     },
     onEnter: function () {
     console.log('patientrecord.current.checkout entered');
     }
     });

     $stateProvider
     .state('patientrecord.current.checkout.findings', {
     url: '/findings',
     views: {
     checkout: {template: '<checkout-findings patient="consCtrl.patient"></checkout-findings>'}
     },
     onEnter: function () {
     console.log('patientrecord.current.checkout.findings entered');
     }
     });

     $stateProvider
     .state('patientrecord.current.checkout.aides', {
     url: '/aides',
     views: {
     checkout: {template: '<checkout-aides patient="consCtrl.patient"></checkout-aides>'}
     },
     onEnter: function () {
     console.log('patientrecord.current.checkout.aides entered');
     }
     });


     $stateProvider
     .state('patientrecord.current.checkout.referrals', {
     url: '/referrals',
     views: {
     checkout: {template: '<checkout-referrals patient="consCtrl.patient"></checkout-referrals>'}
     },
     onEnter: function () {
     console.log('patientrecord.current.checkout.referrals entered');
     }
     });

     $stateProvider
     .state('patientrecord.current.checkout.drugs', {
     url: '/drugs',
     views: {
     checkout: {template: '<checkout-drugs patient="consCtrl.patient"></checkout-drugs>'}
     },
     onEnter: function () {
     console.log('patientrecord.current.checkout.drugs entered');
     }
     });


     */

    // Patientenakte - aktuelle Rechnungspositionen
    $stateProvider.state('patientrecord.billing', {
      url: '/billing',
      views: {
        'pr-details': {template: '<checkout-billing patient="patrCtrl.patient"></checkout-billing>'}
      },
      onEnter: function () {
        console.log('patientrecord.billing entered');
      }
    });

    // Patientenakte - aktuelle Rechnungspositionen
    $stateProvider.state('patientrecord.error', {
      url: '/error',
      views: {
        'pr-details': {template: '<consult-error></consult-error>'}
      },
      onEnter: function () {
        console.log('patientrecord.error entered');
      },
      params: {
        patient: {},
        patientid: ''
      }
    });



    $stateProvider.state('patientrecord.checkout', {
      url: '/checkout',
      views: {
        'pr-details': {template: '<ui-view name="checkout-details"></ui-view>'}
      },
      onEnter: ["Patient", "$stateParams", "$state", function (Patient, $stateParams, $state) {
        console.log('patientrecord.checkout switcher entered');

        // note to self: failed at avoiding to Patient.$new($stateParams.patientid).$fetch()
        Patient.$new($stateParams.patientid).$fetch().$asPromise().then(function (pat) {
          var route;
          if (!pat.hasOwnProperty('checkoutstate') || pat.checkoutstate === null) {
            pat.checkoutstate = 'ccdocs';
            route = 'patientrecord.checkout.ccdocs';
          }
          else {
            // route = 'patientrecord.checkout.' + pat.checkoutstate;
            switch (pat.checkoutstate) {
              case 'ccdocs': route = 'patientrecord.checkout.ccdocs'; break;
              case 'payment': route = 'patientrecord.checkout.payment'; break;
              case 'redobilling': route = 'patientrecord.checkout.redobilling'; break;
              default: route = 'patientrecord.checkout.ccdocs'; break;
            }

          }
          console.log('patientrecord.checkout route choosen: ', route);
          $state.go(route, {patient: pat});


          if (!pat.hasOwnProperty('consultstate') || pat.consultstate === null) {
            pat.consultstate = 'consult';
            route = 'patientrecord.current.consult';
          }
          else {

          }


        });
      }],
      controller: 'ConsultCtrl',
      controllerAs: 'consCtrl'
    });


    $stateProvider.state('patientrecord.checkout.ccdocs', {
      url: '/ccdocuments',
      views: {
        'checkout-details': {template: '<checkout-documents patient="patrCtrl.patient"></checkout-documents>'}
      },
      onEnter: function () {
        console.log('patientrecord.checkout.ccdocs entered');
      }
    });


    $stateProvider.state('patientrecord.checkout.payment', {
      url: '/payment',
      views: {
        'checkout-details': {template: '<checkout-payment patient="patrCtrl.patient"></checkout-payment>'}
      },
      onEnter: function () {
        console.log('patientrecord.checkout.payment entered');
      }
    });

    $stateProvider.state('patientrecord.checkout.redobilling', {
      url: '/redobilling',
      views: {
        'checkout-details': {template: '<checkout-billing patient="patrCtrl.patient"></checkout-billing>'}
      },
      onEnter: function () {
        console.log('patientrecord.checkout.redobilling entered');
      }
    });

  }
}());

(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name patient.directive:patientRecordView
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-record-view></patient-record-view>
   </file>
   </example>
   *
   */
  angular
    .module('patient')
    .directive('patientRecordView', patientRecordView);
  
  
  patientRecordView.$inject = ['Patient', '$stateParams', '$state', 'vertxEventBusService',
    '$log', 'Config', 'hotkeys', '$uibModal', 'File', 'Checkinvoice', '$cookies', '$window'];
  
  function patientRecordView(Patient, $stateParams, $state, vertxEventBusService,
                             $log, Config, hotkeys, $uibModal, File, Checkinvoice, $cookies, $window) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'patient/patient-record-view-directive.tpl.html',
      replace: false,
      // controller: 'PatientCtrl',
      controllerAs: 'patrCtrl',
      bindToController: true,
      controller: ["$scope", function ($scope) {
        var vm = this;
        vm.vertxEventBusService = vertxEventBusService;
        vm.patientid = $stateParams.patientid;
        // TODO: really test this unrwesolved refresh chain
        Patient.$find(vm.patientid).$then(function (pat) {
          vm.patient = pat;
          vm.patient.reminders.$refresh().$then(function () {
            $log.debug('patrCtrl patient reminders loaded');
          });
  
          if (pat.inconsult === true) {
            vm.patient.openConsult.$refresh().$then(function () {
              vm.patient.openConsult.examinations.$refresh();
            });
            if (pat.inexam === true) {
              vm.patient.openExam.$refresh();
            }
          }
        });
        
        vm.addPhoto = addPhoto;
        vm.enterPaidDate = enterPaidDate;
        
        
        /*
         if (vm.patient.inconsult) {
         vm.patient.openConsult.$refresh().$asPromise().then(function () {
         vm.patient.openConsult.examinations.$refresh();
         });
         vm.patient.openExam.$refresh();
         }
         */
        
        
        vm.openInvoices = Checkinvoice.$search({patientid: $stateParams.patientid, paid: false, storno: false});
        vm.unsignedScans = File.$search({patientid: $stateParams.patientid, signedoff: false});
        
        
        function addPhoto() {
          if (vm.newphoto && vm.newphoto.fileid) {
            File.$find(vm.newphoto.fileid).$asPromise().then(function (image) {
              image.patientid = vm.patient._id;
              image.$save();
              vm.patient.image = '/routes/files/filereader/' + image.fileid;
              vm.patient.$save();
            });
            vm.newphoto = {};
          }
        }
        
        // vm.conf = Config.getConfig();
        // vm.patient.printconf = vm.conf.printconf;
        // obsolete!
        Config.$find('configuration').$asPromise().then(function (conf) {
          vm.conf = conf;
          // vm.patient.printconf = conf.printconf;
        });
        
        
        function enterPaidDate(invoice) {
          $uibModal.open({
            templateUrl: 'finance/invoice/invoiceDateTpl.html',
            controllerAs: 'miCtrl',
            controller: ['invoice', '$uibModalInstance', function (inv, $uibModalInstance) {
              var vmi = this;
              vmi.invoice = inv;
              vmi.dialog = $uibModalInstance;
              
              // TODO: options are completyl ignored, figure out how to enable maxDate
              vmi.dateoptions = {
                maxDate: new Date(),
                showWeeks: true
              };
              vmi.setDate = setDate;
              
              function setDate() {
                vmi.invoice.paiddate = moment(vmi.paiddate).toISOString();
                vmi.invoice.fpaiddate = moment(vmi.paiddate).locale('de-at').format('ll');
                vmi.invoice.paid = true;
                // $log.debug('Paiddate: ', vmi.paiddate);
                // $log.debug('Invoice Paiddate: ', vmi.invoice.paiddate);
                $log.debug('Invoice FPaiddate: ', vmi.invoice.fpaiddate);
                vmi.invoice.$save().$asPromise().then(function () {
                  vm.openInvoices.$refresh();
                });
                vmi.dialog.close();
              }
            }],
            resolve: {
              invoice: function () {
                return invoice;
              }
            },
            size: 'sm'
          });
          
          
        }
        
        
        hotkeys.bindTo($scope)
          .add({
            combo: 'alt+m',
            description: 'Medikamente',
            callback: function () {
              $state.go('patientrecord.drugs');
            }
          })
          .add({
            combo: 'alt+a',
            description: 'Aktuelle Konsultation',
            callback: function () {
              $state.go('patientrecord.current');
            }
          })
          .add({
            combo: 'alt+d',
            description: 'Dokumente',
            callback: function () {
              $state.go('patientrecord.documents');
            }
          })
          .add({
            combo: 'alt+u',
            description: 'Untersuchungen',
            callback: function () {
              $state.go('patientrecord.examinations');
            }
          })
          .add({
            combo: 'alt+p',
            description: 'Patienten ToDo',
            callback: function () {
              
              $uibModal.open({
                template: '<div class="modalspacer"><patient-reminder patient="miCtrl.patient"></patient-reminder></div>',
                size: 'modal-sm',
                controller: ['$uibModalInstance', 'patient', ModalInstanceCtrl],
                controllerAs: 'miCtrl',
                resolve: {
                  patient: function () {
                    return vm.patient;
                  }
                }
              });
              
              function ModalInstanceCtrl($uibModalInstance, patient) {
                var vmi = this;
                vmi.patient = patient;
                vmi.$modalInstance = $uibModalInstance;
                
                vmi.cancel = function () {
                  vmi.$modalInstance.close();
                };
                
                vmi.ok = function () {
                  vmi.patient.$save();
                  vmi.$modalInstance.close();
                };
              }
              
            }
          })
          .add({
            combo: 'alt+h',
            description: 'Historie/Verlauf',
            callback: function () {
              $state.go('patientrecord.history');
            }
          });
        
      }],
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        
        // listen for patient updates
        ctrl.vertxEventBusService.on('patients.update', function (message) {
          var messages = message.split(':');
          console.log('Patient updated: ', message);
          // if (messages[1] !== $cookies.get('vertx-web.session') && ctrl.patientid === messages[0]) {
          if (messages[1] !== $window.sessionStorage.getItem('sprtabid') && ctrl.patientid === messages[0]) {
            ctrl.patient.$refresh().$asPromise().then(function () {
              if (ctrl.patient.inexam === false && angular.isObject(ctrl.patient.openConsult) && angular.isString(ctrl.patient.openConsult._id)) {
                console.log('(prv) refreshed patient openConsult: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
                ctrl.patient.openConsult.$refresh().$asPromise().then(function (c) {
                  console.log('(prv) refreshed patient openConsult->examinations: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
                  ctrl.patient.openConsult.examinations.$refresh();
                });
              }
            });
            console.log('(prv) refreshed patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
          if (ctrl.patientid !== messages[0]) {
            console.log('(prv) ignored patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
        });
        
        /*
         
         // this is not healthy!
         // listen for consult updates
         ctrl.vertxEventBusService.on('consults.update', function (message) {
         var messages = message.split(':');
         console.log('Consult updated: ', message);
         if (ctrl.patient.openConsult._id === messages[0]) {
         ctrl.patient.openConsult.$refresh();
         console.log('refreshed openConsult for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
         }
         if (ctrl.patient.openConsult._id !== messages[0]) {
         console.log('ignored openConsult update for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
         }
         });*/
        
        
        // watch for changes in patient diagnoses
        // the third argument 'true' ensures deep checking of contents, works better than watchCollection
        scope.$watch('patrCtrl.patient.diagnoses', function (patdiagnoses) {
          if (angular.isArray(patdiagnoses)) {
            ctrl.patient.flatdiagnoses = [];
            if (ctrl.patient.diagnoses && ctrl.patient.diagnoses.length > 0) {
              ctrl.patient.diagnoses.forEach(function (kw) {
                ctrl.patient.flatdiagnoses.push(kw.key);
              });
            }
            ctrl.patient.$save();
          }
        }, true);
        
        
        /*
         // watch for changes in patient trackers
         // the third argument 'true' ensures deep checking of contents, works better than watchCollection
         scope.$watch('patrCtrl.patient.trackers', function (pat) {
         ctrl.patient.$save();
         }, true);
         */
        
        
        // watch for changes in patient keywords
        /* scope.$watch('patrCtrl.patient.keywords', function (embed) {
         var kw;
         ctrl.patient.flatkeywords = [];
         ctrl.patient.keywords.forEach(function (kw){
         ctrl.patient.flatkeywords.push(kw.key);
         });
         ctrl.patient.$save();
         }, true); */
        
        // watch for patient portrait photo
        scope.$watch('patrCtrl.newphoto', function () {
          console.log('patrCtrl.newphoto watch triggered');
          ctrl.addPhoto();
        }, true);
        
        
      } // link function
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:patientNewsearch
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="patient">
       <file name="index.html">
        <patient-newsearch dialog="" prevstate=""></patient-newsearch>
       </file>
     </example>
   *
   */
  angular
    .module('patient')
    .directive('patientNewsearch', patientNewsearch);

  patientNewsearch.$inject = ['Patientsearch', 'Patient', '$state', '$log'];

  function patientNewsearch(Patientsearch, Patient, $state, $log) {
    return {
      restrict: 'EA',
      scope: {
        dialog: '='
      },
      templateUrl: 'patient/patient-newsearch-directive.tpl.html',
      replace: false,
      controllerAs: 'npCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.createPatient = createPatient;
        vm.showRecord = showRecord;
        vm.cancel = cancel;
        vm.setLastname = setLastname;
        vm.getNewNames = getNewNames;
        
        // typeahead new patient search -  set lastname action
        function setLastname($model) {
          if (!angular.isObject(vm.newpatient)) {
            vm.newpatient = {};
          }
   //       $log.debug('setLastname model 1: ', $model);
   //       $log.debug('vm.newpatient: ', vm.newpatient);
          vm.newpatient.lastname = $model.lastname;
          getNewNames($model.lastname);
//           $log.debug('vm.newpatient: ', vm.newpatient);
        }

        // typeahead new patient search backend query
        function getNewNames(val) {
          console.log('loading names');

          return Patientsearch.$search({query: val}).$asPromise().then(function (response) {
            // console.log('response: ', response);
            var resp = response.map(function (patient) {
              patient.text = patient.lastname;
              return patient;
            });
            vm.newnames = resp;
            return resp;
          });
        }

        function createPatient() {
          // this is totally ugly, but it seems to work
          if (!vm.newpatient.lastname && angular.isObject(vm.asyncSelected)) {
            vm.newpatient.lastname = vm.asyncSelected.lastname;
          }
          if (!vm.newpatient.lastname && angular.isString(vm.asyncSelected)) {
            vm.newpatient.lastname = vm.asyncSelected;
          }
          if (angular.isObject(vm.newpatient.lastname)) {
            vm.newpatient.lastname = vm.newpatient.lastname.lastname;
          }
          vm.newpatient.inconsult = false;
          vm.newpatient.inexam = false;
          vm.newpatient.incheckout = false;
          vm.newpatient.consultstate = '';
          vm.newpatient.checkoutstate = '';
          $log.debug('vm.newpatient create: ', vm.newpatient);
          Patient.$create(vm.newpatient).$then(function (pat) {
            console.log('patid: ', pat._id);
            $state.go('patientrecord.basicdata', {patientid: pat._id});
          });
        }

        function showRecord(id) {
          $state.go('patientrecord.history', {patientid: id});
          vm.dialog.close();
        }

        function cancel() {
          vm.dialog.close();
          if ($state.previousState && $state.previousParams) {
            $state.go($state.previousState.name, $state.previousParams);
          }

          if ($state.previousState && !$state.previousParams) {
            $state.go($state.previousState.name);
          }


        }

      }
    };
  }
  // klammerfriedhof
}());




(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:patientNew
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-new></patient-new>
   </file>
   </example>
   *
   */
  angular
    .module('patient')
    .directive('patientNew', patientNew);

  function patientNew() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'patient/patient-new-directive.tpl.html',
      replace: false,
      controllerAs: 'patCtrl',
      bindToController: true,
      controller: 'PatientCtrl',
      link: function (scope, element, attrs) {
        /*jshint unused:false */
        /*eslint "no-unused-vars": [2, {"args": "none"}]*/
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:patientMemo
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-memo patient=""></patient-memo>
   </file>
   </example>
   *
   */
  angular
    .module('patient')
    .directive('patientMemo', patientMemo);

  patientMemo.$inject = ['Config'];

  function patientMemo(Config) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'patient/patient-memo-directive.tpl.html',
      replace: false,
      // controller: 'PatientCtrl',
      controller: function () {
        var vm = this;
        // no idea why this is needed, but it is essential
        vm.conf = Config.$find('configuration');
      },
      controllerAs: 'pmCtrl',
      bindToController: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:patientLog
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-log></patient-log>
   </file>
   </example>
   *
   */
  patientLog.$inject = ["Patient", "$log"];
  angular
    .module('patient')
    .directive('patientLog', patientLog);



  function patientLog(Patient, $log) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'patient/patient-log-directive.tpl.html',
      replace: false,
      controllerAs: 'plogCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.addLogEntry = addLogEntry;
        function addLogEntry() {
          if (!vm.patient.hasOwnProperty('log') || vm.patient.log.constructor !== Array) {
            vm.patient.log = [];
          }
          vm.patient.log.push({created: new Date().toISOString(), entry: vm.patient.newlog});
          vm.patient.newlog = '';
          vm.patient.$save();
        }
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:patientList
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-list></patient-list>
   </file>
   </example>
   *
   */
  angular
    .module('patient')
    .directive('patientList', patientList);

  patientList.$inject = ['Patientcount', 'Patient', 'Patientpaged', '$log'];

  function patientList(Patientcount, Patient, Patientpaged, $log) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'patient/patient-list-directive.tpl.html',
      replace: false,
      controllerAs: 'plCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.pageChanged = pageChanged;

        vm.patientlist = Patientpaged.$search();
        Patientcount.$search().$asPromise().then(function (n) {
          console.log('n: ', n);
          vm.totalItems = n[0].count;
        });

        function pageChanged() {
          vm.patientlist.$refresh({page: vm.currentPage});
        }
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:patientHoriz
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-horiz></patient-horiz>
   </file>
   </example>
   *
   */
  angular
    .module('patient')
    .directive('patientHoriz', patientHoriz);

  function patientHoriz() {
    return {
      restrict: 'EA',
      scope: false,
      /* scope: {
       patient: '=',
       editablekeywords: '@'
       }, */
      templateUrl: 'patient/patient-horiz-directive.tpl.html',
      replace: false,
      controller: 'PatientCtrl',
      controllerAs: 'patCtrl',
      bindToController: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        scope.editablekeywords = attrs.editablekeywords;
      }
    };
  }
}());

(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name patient.directive:patientEdit
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-edit></patient-edit>
   </file>
   </example>
   *
   */
  angular
    .module('patient')
    .directive('patientEdit', patientEdit);
  
  function patientEdit() {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'patient/patient-edit-directive.tpl.html',
      replace: false,
      controller: 'PatientCtrl',
      controllerAs: 'patCtrl',
      bindToController: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        
        scope.$watch('patCtrl.patient.refdoctor', function () {
          ctrl.patient.$save();
        }, true);
        
        scope.$watch('patCtrl.patient.practdoctor', function () {
          ctrl.patient.$save();
        }, true);
        
        scope.$watch('patCtrl.patient.zip', function () {
          ctrl.patient.$save();
        }, true);
        
        scope.$watch('patCtrl.patient.city', function () {
          ctrl.patient.$save();
        }, true);
        
        scope.$watch('patCtrl.patient.sv', function () {
          ctrl.patient.$save();
        }, true);
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name patient.controller:PatientCtrl
   *
   * @description
   *
   */
  angular
    .module('patient')
    .controller('PatientCtrl', PatientCtrl);

  PatientCtrl.$inject = ['Patient', '$stateParams', 'Config', 'OpenConsults', 'Svahead', '$log'];

  function PatientCtrl(Patient, $stateParams, Config, OpenConsults, Svahead, $log) {
    var vm = this;
    vm.Patient = Patient;
    vm.calcBMI = calcBMI;
    vm.clearRefDoctor = clearRefDoctor;
    vm.clearPractDoctor = clearPractDoctor;
    vm.clearSv = clearSv;
    // vm.validateSVNR = validateSVNR;
    // vm.vertxEventBusService = vertxEventBusService;
    vm.patientid = $stateParams.patientid;
    // vm.svs = ['N\u00D6GKK', 'SVA', 'WGKK', 'BVA', 'BKK'];

    // vm.showsv = showsv;
    // vm.getNames = getNames;
    // vm.setsvid = setsvid;
  
    // add custom valdiator directive
    //
    
    
    // on load calculate BMI
    vm.calcBMI();

    vm.clearOpenConsult = clearOpenConsult;

    vm.conf = Config.$find('configuration');

    function calcBMI() {
      if (angular.isObject(vm.patient)) {
        if (angular.isNumber(vm.patient.weight) && angular.isNumber(vm.patient.height) && vm.patient.height !== 0) {
          vm.patient.bmi = Math.round(vm.patient.weight * 10000 / (vm.patient.height * vm.patient.height) * 10) / 10;
        }
        else {
          vm.patient.bmi = 0;
        }
      }
    }


   /* function validateSVNR(svnr) {
      var nr = svnr.split('');
      var checksum = (5 * parseInt(nr[4], 10) +
        8 * parseInt(nr[5], 10) +
        4 * parseInt(nr[6], 10) +
        2 * parseInt(nr[7], 10) +
        parseInt(nr[8], 10) +
        6 * parseInt(nr[9], 10) +
        3 * parseInt(nr[0], 10) +
        7 * parseInt(nr[1], 10) +
        9 * parseInt(nr[2], 10)) % 11;
      
      $log.debug(svnr, ' valid: ', checksum === parseInt(nr[3], 10));
      return (checksum === parseInt(nr[3], 10));
    }*/
    
    function clearRefDoctor() {
      delete vm.patient.refdoctor;
      vm.patient.$save();
    }

    function clearPractDoctor() {
      delete vm.patient.practdoctor;
      vm.patient.$save();
    }
    function clearSv() {
      delete vm.patient.sv;
      vm.patient.svref = {};
      vm.patient.$save();
    }

   /* // typeahead sv search backend query
    function getNames(val) {
      console.log('loading names');

      return Svahead.$search({query: val}).$asPromise().then(function (response) {
        console.log('response: ', response);
        return response.map(function (sv) {
          sv.text = sv.short + ' ' + sv.name;
          return sv;
        });
      });
    }
  */
  
    /* function setsvid($model) {
      /!* jshint unused:false *!/
      /!* eslint "no-unused-vars": [2, {"args": "none"}] *!/
      vm.patient.svid = $model._id;
      vm.patient.svs = $model.short;
    }
    */

    function clearOpenConsult(patient) {
      patient.openConsult = null;
      patient.openExam = null;
      patient.consultstate = 'consult';
      patient.checkoutdocs = [];
      patient.inconsult = false;
      if (patient.hasOwnProperty('invoice')) {
        delete patient.invoice;
      }
      patient.$save().$asPromise().then(function () {
        OpenConsults.triggerUpdate();
      });
    }

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name login
   * @description
   *
   */
  angular
    .module('login', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('login')
    .config(config);



  function config($stateProvider) {
    // show modal for login
    $stateProvider.state('login', {
      url: '/login',
      params: {url: {}},
      onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
        console.log('urls in onEnter: ', $stateParams.url);
        $uibModal.open({
          template: '<div class="modalspacer"><login url="miCtrl.url" dialog="miCtrl.$uibModalInstance"></login></div>',
          controller: ['url', '$uibModalInstance', function (u, $uibModalInstance) {
            console.log('$stateParams in uib controller: ', u);
            var vm = this;
            vm.url = u;
            vm.$uibModalInstance = $uibModalInstance;
          }],
          controllerAs: 'miCtrl',
          resolve: {
            url: function () {
              console.log('url in resolve: ', $stateParams.url);
              return $stateParams.url;
            }
          }
        });
      }]
    });

    $stateProvider.state('logout', {
      url: '/logout',
      onEnter: ['$state', '$http', function ($state, $http) {

        $http.get('/routes/logout')
          .then(function () {
            $state.go('login');
          });
      }]
    });

  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name login.directive:login
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="login">
       <file name="index.html">
        <login></login>
       </file>
     </example>
   *
   */
  angular
    .module('login')
    .directive('login', login);

  login.$inject = ['$http', '$stateParams', '$state', '$location', '$log', '$cookies', '$window'];

  function login($http, $stateParams, $state, $location, $log, $cookies, $window) {
    return {
      restrict: 'EA',
      scope: {
        url: '=',
        dialog: '='
      },
      templateUrl: 'login/login-directive.tpl.html',
      replace: false,
      controllerAs: 'liCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'login';
        vm.error = false;
        vm.sendcreds = sendcreds;

        function sendcreds() {
          $http.post('/routes/login',
            {username: vm.username, password: vm.password})
            .then(function () {
              $cookies.put('username', vm.username);
              $log.debug('redirect url: ', vm.url);
              $log.debug('dialog: ', vm.dialog);
              vm.dialog.close();
              if (vm.url) {
                $log.debug('$window: ', $window.location);
                $window.location.href = '#' + vm.url;
                // $location.url(vm.url);
                $window.location.reload();
                //
                $log.debug('after login redirect active');
                // $state.go($state.$current, null, { reload: true });
              }
              else {
                $state.go('home', null, {reload: true});

              }
            },
              function () {
                $log.debug('login error');
                vm.username = '';
                vm.password = '';
                vm.error = true;
              });
        }


      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name labvalue
   * @description
   *
   */
  angular
    .module('labvalue', [
      'ui.router',
      'ngTagsInput',
      'ui.bootstrap.typeahead'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('labvalue')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('labvalue', {
        url: '/labvalue',
        views: {
          main: {template: '<labvalue-editor></labvalue-editor>'},
          // leftbar: {template: '<patient-today></patient-today>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<h3>I\'m a chat window</h3>'}
        }
      });
  }



}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name labvalue.factory:Labvalue
   *
   * @description
   *
   */
  angular
    .module('labvalue')
    .factory('Labvalue', Labvalue);

  Labvalue.$inject = ['restmod', '$log'];

  function Labvalue(restmod, $log) {
    return restmod.model('/routes/appconfig');
  }



  /*
  function Labvalue(restmod, $log) {
    // var model = restmod.model('/routes/labconfig');
    var model = restmod.model('/routes/appconfig');
    var values = [
      'Blutbild u. Diff',
      'BSG', 'CRP', 'GOT', 'GPT', 'GGT', 'AP', 'Bili', 'Cholinesterase', 'Lipase', 'Amylase', 'Aldolase', 'CK', 'LDH', 'BUN', 'CREA',
      'Natrium', 'Kalium', 'Chlorid', 'Calcium', 'Magnesium', 'Harns\u00E4ure',
      'Elektrophorese', 'Gesamteiweiss', 'Albumin', 'Cholesterin gesamt', 'HDL', 'LDL', 'HDL/LDL',
      'Triglyceride', 'Glukose', 'Hba1c', 'TSH', 'Vit. B12', 'Fols\u00E4ure', 'PTZ', 'Lues', 'EBV', 'CMV',
      'Toxoplasmose', 'Borreliose', 'Brucella', 'ANA', 'DNA', 'ASMA', 'APCA', 'AMA',
      'LKM', 'Fe', 'Transferrin', 'Ferritin', 'sTFR', 'Ferritinindex', 'Transferrins\u00E4ttigung', 'Retikulozyten',
      'Hepatitisserologie (A, B, C, E)',
      'ASAT', 'ALAT', 'Anti-TSH-Rezeptor', 'Anti-TG-Antik\u00F6rper', 'Anti-TPO-Antik\u00F6rper',
      'Blutsenkung', 'Serumelektrophorese', 'ASAT', 'ALAT', 'Gamma-GT', 'AP', 'Bilirubin', 'CRP', 'Calcium',
      'Phosphat', 'Kreatinin', 'TSH', 'Parathormon', 'ALSO', 'RF', 'Fibrinogen', 'Ammoniak', 'T3', 'T4', 'fT3', 'fT4',
      'gammaGT', 'Phosphat', 'Harnstoff-N (BUN)', 'Chlamydien', 'Borrela burgdorferi', 'Mycoplasma pneumoniae',
      'Salmonella', 'Yersinia', 'Brucella', 'HEP B/C', 'HIV', 'Parvovirus B19 (Ringelr\u00F6teln)', 'ACE-Spiegel', 'ANA',
      'Totalprotein', 'Albumin', '25-Hydroxy-Vitamin D', 'Alpha-Feto-Protein', 'CDT', 'Cortisol', 'Renin/Aldosteron Ratio',
      'Trop T hs', 'D-Dimer', 'Rest-N'];

    var defaultvalues = uniq(values);


    var defaultgroups =
      [
        {
          name: 'Gesamt',
          values: uniq(['Blutbild u. Diff', 'BSG', 'CRP', 'GOT', 'GPT', 'GGT', 'AP', 'Bili', 'Cholinesterase', 'Lipase',
            'Amylase', 'CK', 'LDH', 'BUN', 'CREA',
            'Natrium', 'Kalium', 'Chlorid', 'Calcium', 'Magnesium', 'Harns\u00E4ure',
            'Elektrophorese', 'Gesamteiweiss', 'Albumin', 'Cholesterin gesamt', 'HDL', 'LDL',
            'Triglyceride', 'Glucose', 'Hba1c', 'TSH', 'Vit. B12', 'Fols\u00E4ure', 'PTZ', 'Fe', 'Ferritin', 'Transferrins\u00E4ttigung'])
        },
        {name: 'ANA + Subsets (bei pos. ANA)', values: uniq(['ANA', 'DNA', 'ASMA', 'APCA', 'AMA', 'LKM'])},
        {
          name: 'Infektionen',
          values: uniq(['Hepatitisserologie (A, B, C, E)', 'Lues', 'EBV', 'CMV', 'Toxoplasmose', 'Borreliose', 'Brucella'])
        },
        {name: 'Eisenstatus', values: uniq(['Fe', 'Ferritin', 'Transferrins\u00E4ttigung'])},
        {
          name: 'Eisenstoffwechsel',
          values: uniq(['Fe', 'Transferrin', 'Ferritin', 'sTFR', 'Ferritinindex', 'CRP', 'Retikulozyten'])
        },
        {name: 'Herz', values: ['LDH', 'CK', 'Trop T hs']},
        {name: 'Schilddr\u00FCse', values: uniq(['ASAT', 'ALAT', 'Calcium', 'TSH'])},
        {
          name: 'Schilddr\u00FCsen-Antik\u00F6rper',
          values: uniq(['Anti-TSH-Rezeptor', 'Anti-TG-Antik\u00F6rper', 'Anti-TPO-Antik\u00F6rper'])
        },
        {
          name: 'Osteoporose',
          values: uniq(['Blutsenkung', 'Serumelektrophorese', 'ASAT', 'ALAT', 'gammaGT', 'AP', 'Bilirubin', 'CRP', 'Calcium',
            'Phosphat', 'Kreatinin', 'TSH', 'Parathormon'])
        },
        {name: 'Rheuma', values: uniq(['ALSO', 'RF', 'CRP'])},
        {
          name: 'Niere',
          values: uniq(['BUN', 'Natrium', 'Kalium', 'Chlorid', 'Phosphat', 'Kreatinin', 'Calcium', 'Rest-N', 'Harns\u00E4ure'])
        },
        {
          name: 'Leber', values: uniq(['PTZ', 'D-Dimer', 'Fibrinogen', 'Ammoniak', 'CRP', 'Natrium', 'Kalium', 'Chlorid',
          'Phosphat', 'Kreatinin', 'Magnesium', 'LDH', 'BUN',
          'ASAT', 'ALAT', 'Gamma-GT', 'AP', 'Bilirubin', 'Lipase', 'Amylase', 'TSH', 'HDL/LDL', 'Hba1c', 'Harns\u00E4ure', 'Glukose', 'Triglyceride',
          'Totalprotein', 'Albumin', '25-Hydroxy-Vitamin D', 'Alpha-Feto-Protein', 'CDT', 'Serumelektrophorese', 'Vit. B12', 'Fols\u00E4ure'])
        }

      ];

    var newconf = {};
    var labconfig;
    var labhandle = {};
    var initialised = false;

    function uniq(a) {
      return a.sort().filter(function (item, pos, ary) {
        return !pos || item !== ary[pos - 1];
      });
    }

    function init() {
      if (!initialised) {
        initialised = true;

        model.$find('labconfiguration').$then(function (labconfig) {
          if (labconfig === {}) {
            newconf = arraysToTagsArray(defaultvalues, defaultgroups);

            //labconfig = labconfigs.$create(newconf).$reveal();
            labconfig.values = newconf.values;
            labconfig.groups = newconf.groups;
            labconfig.$save();

            $log.debug('new labconfig created: ', labconfig);
          }
          else {

            $log.debug('labconfig read from server: ', labconfig);
          }
        });
      }
    }


    function replaceConfig(changedconfig) {
      var i;
      var changedgroups = [];
      if (angular.isUndefined(changedconfig)) {
        changedconfig = {};
        changedconfig.values = [];
        changedconfig.groups = [{name: '', values: []}];
      }
      labconfig.values = uniq(changedconfig.values);
      for (i = 0; i < changedconfig.groups.length; i++){
        changedgroups.push({
          name: changedconfig.groups[i].name,
          values: uniq(changedconfig.groups[i].values)
        });
      }
      labconfig.groups = changedgroups;
      labconfig.$save();
    }

    function arraysToTagsArray(values, groups) {
      var config = {};
      var i;
      var j;
      var grouptagsarray = [];
      config.groups = [];
      config.values = [];
      for (i = 0; i < values.length; i++) {
        config.values.push({name: values[i]});
      }
      for (i = 0; i < groups.length; i++) {
        grouptagsarray = [];
        for (j = 0; j < groups[i].values.length; j++) {
          grouptagsarray.push({name: groups[i].values[j]});
        }
        config.groups.push({name: groups[i].name, values: grouptagsarray});
      }
      return config;
    }

    labhandle.getlabconfig = function () {
      return labconfig;
    };

    labhandle.init = init;
    labhandle.labconfig = labconfig;
    labhandle.replaceconfig = replaceConfig;

    return labhandle;
  }
*/

// klammerfriedhof
}());


/*
 CED-Block:
 Blutbild komplett (incl. Diff), PTZ, aPTT, Fibrinogen, Natrium, Kalium, Chlorid, ASAT, ALAT, Gamma-GT, AP,
 Cholinesterase, Lipase, Amylase, LDH, BUN, Kreatinin, Harnsäure, Totalprotein, Albumin, Bilirubin, Glukose,
 Triglyceride, Cholesterin (HDL, LDL), Cholesterin/HDL Ratio, Transferrin, Eisen, Ferritin, CRP, TSH, Serumelektrophorese

 Diabetesblock:
 Natrium, Kalium, Chlorid, ASAT, ALAT, Gamma-GT, AP, Cholinesterase, Lipase, Amylase, LDH, BUN, Kreatinin,
 Harnsäure, Glukose, Cholesterin (HDL, LDL), Triglyceride, CRP, Hba1c

 Onko-Aufnahme:
 Blutbild komplett (incl. Diff), Retikulozyten, PTZ, aPTT, Fibrinogen, Natrium, Kalium, Chlorid, Calcium, ASAT, ALAT,
 gamma-GT, AP, LDH, Cholinesterase, Harnstoff (BUN), Kreatinin, Harnsäure, CK, Totalprotein, Albumin, Bilirubin,
 Glukose, Triglyceride, Cholesterin, Transferrin, Eisen, Ferritin, CRP, TSH, Serumelektrophorese, Blutsenkung

 Infektiöser Arthritisblock:
 ASLO, CRP, RF, Chlamydien, Borrela burgdorferi, Mycoplasma pneumoniae, Salmonella, Yersinia, Brucella,
 hepb/c, hiv, parvovirus b19 (Ringelröteln), ACE-spiegel, ANA

 Hypertonieblock:
 Blutbild + Diff, bsg, crp, got, gpt, ggt, ap, bili, cholinesterase,
 lipase, amylase, ck, ldh, bun, crea, natrium, kalium, chlorid, calcium, magnesium, harnsäure,
 elektrophorese, gesamteiweiss, albumin, cholesterin (chol-gesamt,hdl, ldl), triglyceride,
 glucose, hba1c, tsh, Eisenstatus (fe, transferrinsättigung, Ferritin),
 vit. B12, Folsäure, PTZ,
 Cortisol, Renin/Aldosteronratio
 */

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name labvalue.directive:labvalueEditor
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="labvalue">
       <file name="index.html">
        <labvalue-editor></labvalue-editor>
       </file>
     </example>
   *
   */
  angular
    .module('labvalue')
    .directive('labvalueEditor', labvalueEditor);


  labvalueEditor.$inject = ['Labvalue', '$log'];


  function labvalueEditor(Labvalue, $log) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'labvalue/labvalue-editor-directive.tpl.html',
      replace: false,
      controllerAs: 'labECtrl',
      controller: ["Labvalue", "$log", function (Labvalue, $log) {
        var vm = this;
        vm.name = 'labvalueEditor';
        vm.saveConfig = saveConfig;
        // vm.labconfig = Labvalue.getlabconfig();
        vm.labconfig = Labvalue.$find('labconfiguration');
        vm.loadValues = loadValues;
        vm.addGroup = addGroup;
        vm.removeGroup = removeGroup;
        vm.saveConfig = saveConfig;

        function addGroup() {
          // vm.labconfig.groups.push({name: vm.newgroup, values: []});
          vm.labconfig.groups.splice(0, 0, {name: vm.newgroup, values: []});
          vm.newgroup = '';
          vm.saveConfig();
          // vm.labconfig.$save();
        }

        function removeGroup(index) {
          vm.labconfig.groups.splice(index, 1);
          vm.saveConfig();
          // vm.labconfig.$save();
        }

/*
        function saveConfig() {
          Labvalue.replaceconfig(vm.labconfig);
        }
*/

        function saveConfig() {
          var i;
          var changedgroups = [];

          vm.labconfig.values = uniq(vm.labconfig.values);
          for (i = 0; i < vm.labconfig.groups.length; i++){
            changedgroups.push({
              name: vm.labconfig.groups[i].name,
              values: uniq(vm.labconfig.groups[i].values)
            });
          }
          vm.labconfig.groups = changedgroups;
          vm.labconfig.$save();
        }

        function uniq(a) {
          return a.sort().filter(function (item, pos, ary) {
            return !pos || item !== ary[pos - 1];
          });
        }

        // load and filter autocomplete values
        function loadValues($query) {
          var promise = new Promise(function (resolve, reject) {
            // TODO this is a rather nasty piece of code to generate a promise that is needed in the next step
            if (true) {
              resolve(vm.labconfig.values);
            }
            else {
              reject([]);
            }
          });
          return promise.then(function (values) {
            $log.debug('promise values: ', values);
            var filteredValues = values.filter(function (value) {
              return value.name.toLowerCase().indexOf($query.toLowerCase()) !== -1;
            });
            console.log('filtered values: ', filteredValues);
            return filteredValues;
          });
        }



      }],
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name labvalue.directive:labreferral
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="labvalue">
       <file name="index.html">
        <labreferral allvalues=""></labreferral>
       </file>
     </example>
   *
   */
  angular
    .module('labvalue')
    .directive('labreferral', labreferral);

  labreferral.$inject = ['Labvalue', '$log'];

  function labreferral(Labvalue, $log) {
    return {
      restrict: 'EA',
      scope: {
        allvalues: '=',
        additionalvalues: '=',
        ginclude: '=',
        ti: '@?'
      },
      templateUrl: 'labvalue/labreferral-directive.tpl.html',
      replace: false,
      controllerAs: 'labRCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.allvalues = [];
        
        vm.labconfig = Labvalue.$find('labconfiguration');
        vm.loadValues = loadValues;
        vm.toggleGroup = toggleGroup;
        vm.joinValues = joinValues;
        vm.flattenArray = flattenArray;
        vm.tabi = parseInt(vm.ti, 10);
        
        function flattenArray(a) {
          var tmpa = [];
          var i;
          if (angular.isArray(a)) {
            for (i = 0; i < a.length; i++) {
              tmpa.push(a[i].name);
            }
          }
          return tmpa;
        }

        function joinValues() {
          var tmpa = [];
          var i;
          // $log.debug('Join values entered');
          // $log.debug('Join values add val', vm.additionalvalues);
          if (angular.isArray(vm.autoselectedvalues) && angular.isArray(vm.additionalvalues)) {
            // $log.debug('both');
            tmpa = vm.autoselectedvalues.concat(flattenArray(vm.additionalvalues));
          }
          else {
            if (angular.isArray(vm.autoselectedvalues)) {
              // $log.debug('auto');
              tmpa = vm.autoselectedvalues;
            }
            if (angular.isArray(vm.additionalvalues)) {
              // $log.debug('additional');
              tmpa = flattenArray(vm.additionalvalues);
            }
          }
          vm.allvalues = uniq(tmpa);
          // $log.debug('Join valuesallvalues: ', vm.allvalues);
        }

        function toggleGroup() {
          var i;
          var tmpa = [];
          // vm.autoselectedvalues = vm.autoselectedvalues || [];
          for (i = 0; i < vm.labconfig.groups.length; i++) {
            // $log.debug('ginclude ', vm.labconfig.groups[i].name, vm.ginclude[i]);
            if (vm.ginclude[i] === true) {
              tmpa = tmpa.concat(vm.labconfig.groups[i].values);
            }
          }
          vm.autoselectedvalues = uniq(flattenArray(tmpa));
          // $log.debug('vm.autoselectedvalues: ', vm.autoselectedvalues);
          joinValues();
        }



        // load and filter autocomplete values
        function loadValues($query) {
          var promise = new Promise(function (resolve, reject) {
            // TODO this is a rather nasty piece of code to generate a promise that is needed in the next step
            if (true) {
              resolve(vm.labconfig.values);
            }
            else {
              reject([]);
            }
          });
          return promise.then(function (values) {
            var filteredValues = values.filter(function (value) {
              return value.name.toLowerCase().indexOf($query.toLowerCase()) !== -1;
            });
            console.log('filtered values: ', filteredValues);
            return filteredValues;
          });
        }

        // make array values uniq
        function uniq(a) {
          return a.sort().filter(function (item, pos, ary) {
            return !pos || item !== ary[pos - 1];
          });
        }
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name journal
   * @description
   *
   */
  angular
    .module('journal', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('journal')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('journal', {
        url: '/journal',
        templateUrl: 'journal/journal.tpl.html',
        controller: 'JournalCtrl',
        controllerAs: 'journal'
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name journal.factory:Journal
   *
   * @description
   *
   */
  angular
    .module('journal')
    .factory('Journal', Journal);

  Journal.$inject = ['restmod'];

  function Journal(restmod) {
    return restmod.model('/routes/journal').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true}
    });
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name home
   * @description
   *
   */
  angular
    .module('home', [
      'ui.router',
      'reminder'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('home')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    console.log('Home templates entered');
    $stateProvider
      .state('home', {
        url: '/home',
        views: {
          main: {templateUrl: 'home/home.tpl.html'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.controller:HomeCtrl
   *
   * @description
   *
   */
  angular
    .module('home')
    .controller('HomeCtrl', HomeCtrl);

  HomeCtrl.$inject = [];

  function HomeCtrl() {
    var vm = this;
    vm.ctrlName = 'HomeCtrl';
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name helpers
   * @description
   *
   */
  angular
    .module('helpers', [
      'ui.router',
      'helpers.doctor',
      'helpers.zipcode',
      'helpers.sv'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('helpers')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('helpers', {
        url: '/helpers',
        templateUrl: 'helpers/helpers.tpl.html',
        controller: 'HelpersCtrl',
        controllerAs: 'helpers'
      });
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name heartbeat
   * @description
   *
   */
  angular
    .module('heartbeat', [
      'ui.router',
      'knalli.angular-vertxbus'
    ]);
}());

(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name heartbeat.directive:heartbeat
   * @restrict A
   * @element
   *
   * @description detects backend outages and warns user
   *
   *
   * @example
   <html name="index.html">
   <body heartbeat></body>
   </html>
   *
   */
  angular
    .module('heartbeat')
    .directive('heartbeat', heartbeat);
  
  
  heartbeat.$inject = ['$log', 'vertxEventBusService', '$state', '$rootScope', '$interval', '$window', '$timeout', '$uibModal'];
  
  function heartbeat($log, vertxEventBusService, $state, $rootScope, $interval, $window, $timeout, $uibModal) {
    return {
      restrict: 'A',
      scope: {},
      replace: false,
      controllerAs: 'heartbeat',
      controller: function () {
        var vm = this;
        vm.name = 'heartbeat';
        
        $interval(function () {
          // $log.debug('timout called');
          if (angular.isUndefined($rootScope.connfailure)) {
            $rootScope.connfailure = 0;
          }
          
          // $log.debug('heartbeat : ', $rootScope.heartbeat.isBetween(moment().subtract(5, 'second'), moment()));
          
          if (angular.isDefined($rootScope.heartbeat)) {
            if ($rootScope.heartbeat.isBetween(moment().subtract(5, 'second'), moment())) {
              // $log.debug('connection exists');
              $rootScope.connfailure = 0;
            }
            else {
              // $log.debug('connection failed');
              $rootScope.connfailure++;
            }
            
            if ($rootScope.connfailure >= 3) {
              // $window.alert('Verbindung futsch!');
              if (angular.isUndefined($rootScope.connalert)) {
                $rootScope.connalert = $uibModal.open({
                  template: '<div class="modalspacer">' +
                  '<div class="alert alert-danger" ng-hide="connfailure === 0">' +
                  '<h1 style="text-align: center"><i class="fa fa-warning"></i> Die Verbindung zum Server wurde unterbrochen!</h1>' +
                  '<h3 style="text-align: center">Dieses Hinweis verschwindet, sobald der Fehler behoben ist.</h3>' +
                  '<div class="spacer-top"><h3>Fehlerbehebung</h3>' +
                  '<ul><li>Für alle: 2 Minuten warten, Verbindungsfehler beheben sich oft von alleine.</li>' +
                  '<li>Im WLAN: WLAN am Laptop o. iPad abdrehen und wieder neu verbinden. 60 Sekunden warten.</li>' +
                  '<li>Über VPN: VPN abdrehen und wieder neu verbinden. 60 Sekunden warten.</li>' +
                  '<li>Für alle: Sprechstunde/Browser schliessen und wieder öffnen. 60 Sekunden warten.</li>' +
                  '<li>Wenn das alles nichts geholfen hat: anrufen! 0664 845 96 90</li><!-- TODO - Support Hotline Nummer! -->' +
                  '</ul></div></div>' +
                  '<div class="alert alert-success" ng-show="connfailure === 0">' +
                  '<h1 style="text-align: center"><i class="fa fa-check"></i> Die Verbindung zum Server wurde wieder hergestellt!</h1>' +
                  '<h3>Je nach dem wo Sie gerade gearbeitet haben, kann es zu Datenverlust der letzten 20 Sekunden gekommen sein.' +
                  'Bitte überprüfen und ggf. nochmal eintragen.</h3>' +
                  '<button class="btn btn-success" ng-click="connalert.close()">Weiterabeiten</button>' +
                  '</div></div>',
                  size: 'lg',
                  backdrop: 'static',
                  controllerAs: 'miCtrl',
                  bindToController: true,
                  controller: function () {
                    $log.debug('modal $scope: ', $rootScope);
                    // this an empty controller - weird
                    var vmi = this;
                    vmi.$rootScope = $rootScope;
                  },
      /*            controller: ['$rootScope', function ($rootScope) {
                    var vmi = this;
                    vmi.$rootScope = $rootScope;
                  }],*/
                  resolve: {}
                });
              }
            }
          }
          
        }, 5000);
        
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        
        vertxEventBusService.on('heartbeat', function () {
          
          $rootScope.heartbeat = moment();
          // $log.debug('heartbeat caught: ', $rootScope.heartbeat);
        });
        
        
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name forms
   * @description
   *
   */
  angular
    .module('forms', [
      'ui.router',
      'ui.tinymce',
      'ui.bootstrap'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name forms.directive:timeevidence
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="forms">
       <file name="index.html">
        <timeevidence></timeevidence>
       </file>
     </example>
   *
   */
  angular
    .module('forms')
    .directive('timeevidence', timeevidence);
  
  timeevidence.$inject = ['$log', '$http', '$timeout', '$state', 'Patientsearch'];
  
  function timeevidence($log, $http, $timeout, $state, Patientsearch) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'forms/timeevidence-directive.tpl.html',
      replace: false,
      controllerAs: 'teCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'timeevidence';
    
        vm.print = print;
        vm.clear = clear;
        vm.getNames = getNames;
        vm.showpatient = showpatient;
        vm.timeevidence = {};
        vm.timeevidence.fdate = moment().locale('de-at').format('ll');
        vm.timeevidence.starttime = moment().subtract(58, 'minutes').format('hh:mm');
        vm.timeevidence.endtime = moment().add(5, 'minutes').format('hh:mm');
  
        // typeahead patient search backend query
        function getNames(val) {
          console.log('loading names');
    
          return Patientsearch.$search({query: val}).$asPromise().then(function (response) {
            console.log('response: ', response);
            return response.map(function (patient) {
              patient.text = patient.firstname + ' ' + patient.lastname + ', ' + patient.svnr;
              return patient;
            });
          });
        }
  
        // typeahead patient search action
        function showpatient($item, $model, $label) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          vm.patientid = $model._id;
        }
  
  
        function print() {
          var request;
          var payload = {};
          payload.timeevidence = vm.timeevidence;
    
          request = $http({
            url: '/routes/pdfprinter/genpatientprint',
            method: 'POST',
            data: {
              patientid: vm.patientid,
              doctype: 'time',
              payload: payload
            }
          });
    
          request.success(function () {
            vm.printed = true;
            $timeout($state.go('home'), 5000);
          });
          request.error(function () {
            vm.printed = false;
          });
    
        }
  
        function clear() {
          vm.patient = null;
          vm.timevidence = {};
          vm.timeevidence.fdate = moment().locale('de-at').format('ll');
          vm.timeevidence.starttime = moment().subtract(58, 'minutes').format('hh:mm');
          vm.timeevidence.endtime = moment().add(5, 'minutes').format('hh:mm');
        }
  
  
  
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name forms.directive:textprocessing
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="forms">
       <file name="index.html">
        <textprocessing></textprocessing>
       </file>
     </example>
   *
   */
  angular
    .module('forms')
    .directive('textprocessing', textprocessing);
  
  textprocessing.$inject = ['$log', 'Patientsearch', '$http', '$state', '$timeout'];
  
  function textprocessing($log, Patientsearch, $http, $state, $timeout) {
    return {
      restrict: 'E',
      scope: {},
      templateUrl: 'forms/textprocessing-directive.tpl.html',
      replace: false,
      bindToController: true,
      controllerAs: 'textCtrl',
      controller: function () {
        var vm = this;
        vm.name = 'textprocessing';
        vm.doctorr = {};
        
        vm.print = print;
        vm.clear = clear;
        vm.getNames = getNames;
        vm.showpatient = showpatient;
        
        
        // start with a clean slate
        vm.clear();
        
        
        
        function clear() {
          vm.patient = null;
          vm.doctor = null;
          vm.newtext = {};
          vm.newtext.fdate = moment().locale('de-at').format('ll');
          vm.recipient = {};
          vm.newtext.getdiagnoses = false;
          vm.newtext.getdrugs = false;
          vm.newtext.getrisks = false;
          vm.newtext.getnotes = false;
          vm.newtext.gettodos = false;
          vm.newtext.greetings = true;
        }
        
        
        // vm.tinymceOptions = {selector: 'textarea', skin: 'lightgray', theme: 'modern', resize: true, width: '100%', height: 300, plugins: 'link image media searchreplace table spellchecker print preview template', menubar: 'file edit insert view format table tools', toolbar: 'undo redo | formatselect fontsizeselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent'};
        vm.tinymceOptions = {selector: 'textarea', skin: 'lightgray', theme: 'modern', resize: true, width: '100%', height: 300, entity_encoding: 'raw', plugins: 'link searchreplace table spellchecker print preview template', menubar: 'file edit insert view format table tools', toolbar: 'undo redo | formatselect fontsizeselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent'};
          
        // typeahead patient search backend query
        function getNames(val) {
          console.log('loading names');
    
          return Patientsearch.$search({query: val}).$asPromise().then(function (response) {
            console.log('response: ', response);
            return response.map(function (patient) {
              patient.text = patient.firstname + ' ' + patient.lastname + ', ' + patient.svnr;
              return patient;
            });
          });
        }
  
        // typeahead patient search action
        function showpatient($item, $model, $label) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          vm.patientid = $model._id;
        }
  
        function print() {
          var request;
          var payload = {};
  
          payload.recipient = {};
          
          if (angular.isObject(vm.doctor) && angular.isObject(vm.doctor.fulldoc) && (angular.isString(vm.doctor.fulldoc.lastname) || angular.isString(vm.doctor.fulldoc.institute) )) {
            if (vm.doctor.fulldoc.titlefront) {payload.recipient.titlefront = vm.doctor.fulldoc.titlefront;}
            if (vm.doctor.fulldoc.titleback) {payload.recipient.titleback = vm.doctor.fulldoc.titleback;}
            if (vm.doctor.fulldoc.firstname) {payload.recipient.firstname = vm.doctor.fulldoc.firstname;}
            if (vm.doctor.fulldoc.lastname) {payload.recipient.lastname = vm.doctor.fulldoc.lastname;}
            if (vm.doctor.fulldoc.institute) {payload.recipient.institute = vm.doctor.fulldoc.institute;}
            if (vm.doctor.fulldoc.address) {payload.recipient.address = vm.doctor.fulldoc.address;}
            if (vm.doctor.fulldoc.city) {payload.recipient.city = vm.doctor.fulldoc.city;}
            if (vm.doctor.fulldoc.postcode) {payload.recipient.postcode = vm.doctor.fulldoc.postcode;}
          }
          else {
            if (vm.patient.titlefront) {payload.recipient.titlefront = vm.patient.titlefront;}
            if (vm.patient.titleback) {payload.recipient.titleback = vm.patient.titleback;}
            if (vm.patient.firstname) {payload.recipient.firstname = vm.patient.firstname;}
            if (vm.patient.lastname) {payload.recipient.lastname = vm.patient.lastname;}
            if (vm.patient.address) {payload.recipient.address = vm.patient.address;}
            if (vm.patient.city) {payload.recipient.city = vm.patient.city;}
            if (vm.patient.postcode) {payload.recipient.postcode = vm.patient.postcode;}
          }
  
          vm.newtext.xhtml = '<xhtml>' + vm.newtext.xhtml + '</xhtml>';
          payload.freeform = vm.newtext;
              
          request = $http({
            url: '/routes/pdfprinter/genpatientprint',
            method: 'POST',
            data: {
              patientid: vm.patientid,
              doctype: 'freeform',
              payload: payload
            }
          });
    
          request.success(function () {
            vm.printed = true;
            $timeout($state.go('home'), 5000);
          });
          request.error(function () {
            vm.printed = false;
          });
    
        }
        
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name forms.directive:referral
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="forms">
   <file name="index.html">
   <referral></referral>
   </file>
   </example>
   *
   */
  angular
    .module('forms')
    .directive('referral', referral);
  
  referral.$inject = ['$log', 'Patientsearch', '$http', '$state', '$timeout'];
  
  function referral($log, Patientsearch, $http, $state, $timeout) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'forms/referral-directive.tpl.html',
      replace: false,
      controllerAs: 'refCtrl',
      controller: function () {
        var vm = this;
        vm.print = print;
        vm.clear = clear;
        vm.getNames = getNames;
        vm.showpatient = showpatient;
        vm.clearRefDoctor = clearRefDoctor;
        initNewRef();
        
        function initNewRef() {
          vm.newreferral = {};
          vm.newreferral.refdate = '';
          vm.newreferral.pdate = false;
          vm.newreferral.ginclude = [];
          vm.newreferral.additionalvalues = [];
          vm.newreferral.reftype = 'std';
        }
        
        function clearRefDoctor() {
          delete vm.doctor;
        }
        
        // typeahead patient search backend query
        function getNames(val) {
          console.log('loading names');
          
          return Patientsearch.$search({query: val}).$asPromise().then(function (response) {
            console.log('response: ', response);
            return response.map(function (patient) {
              patient.text = patient.firstname + ' ' + patient.lastname + ', ' + patient.svnr;
              return patient;
            });
          });
        }
        
        // typeahead patient search action
        function showpatient($item, $model, $label) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          vm.patientid = $model._id;
        }
        
        
        function print() {
          var request;
          var payload = {};
          vm.printing = true;
  
          if (vm.newreferral.pdate === true) {
            vm.newreferral.refdate = moment().locale('de-at').format('ll');
          }
          else {
            vm.newreferral.refdate = '';
          }
          
          // payload.doctor = {};
          // payload.doctor.fulldoc = {};
  
          vm.newreferral.doctor = {};
          vm.newreferral.doctor.fulldoc = {};
          
          
          if (angular.isObject(vm.doctor)) {
            if (vm.doctor.fulldoc.titlefront) {
              vm.newreferral.doctor.fulldoc.titlefront = vm.doctor.fulldoc.titlefront;
            }
            if (vm.doctor.fulldoc.titleback) {
              vm.newreferral.doctor.fulldoc.titleback = vm.doctor.fulldoc.titleback;
            }
            if (vm.doctor.fulldoc.firstname) {
              vm.newreferral.doctor.fulldoc.firstname = vm.doctor.fulldoc.firstname;
            }
            if (vm.doctor.fulldoc.lastname) {
              vm.newreferral.doctor.fulldoc.lastname = vm.doctor.fulldoc.lastname;
            }
            if (vm.doctor.fulldoc.institute) {
              vm.newreferral.doctor.fulldoc.institute = vm.doctor.fulldoc.institute;
            }
            if (vm.doctor.fulldoc.speciality) {
              vm.newreferral.doctor.fulldoc.speciality = vm.doctor.fulldoc.speciality;
            }
            if (vm.doctor.fulldoc.address) {
              vm.newreferral.doctor.fulldoc.address = vm.doctor.fulldoc.address;
            }
            if (vm.doctor.fulldoc.city) {
              vm.newreferral.doctor.fulldoc.city = vm.doctor.fulldoc.city;
            }
            if (vm.doctor.fulldoc.postcode) {
              vm.newreferral.doctor.fulldoc.postcode = vm.doctor.fulldoc.postcode;
            }
            if (vm.doctor.fulldoc.phone1) {
              vm.newreferral.doctor.fulldoc.phone1 = vm.doctor.fulldoc.phone1;
            }
          }
          
          payload.referrals = [];
          payload.referrals.push(vm.newreferral);
          
          request = $http({
            url: '/routes/pdfprinter/genpatientprint',
            method: 'POST',
            data: {
              patientid: vm.patientid,
              doctype: 'referral',
              payload: payload
            }
          });
          
          request.success(function () {
            vm.printed = true;
            delete vm.printing;
            $timeout(function () {
              $state.go('home');
            }, 3000);
          });
          request.error(function () {
            delete vm.printing;
            vm.printed = false;
          });
          
        }
        
        function clear() {
          delete vm.patient;
          delete vm.doctor;
          initNewRef();
        }
        
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('forms')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('forms', {
        url: '/forms',
        views: {
          main: {template: '<aside ui-view="leftbar" class="hidden-sm hidden-xs hidden-md col-lg-2 col-xl-3"></aside><div ui-view="formsmain" class="col-sm-12 col-md-12 col-lg-10 col-xl-9"></div>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });
  
    $stateProvider
      .state('forms.referral', {
        url: '/referral',
        views: {
          formsmain: {template: '<referral></referral>'}
        }
      });
  
    $stateProvider
      .state('forms.aide', {
        url: '/aide',
        views: {
          leftbar: {template: ''},
          formsmain: {template: '<aide></aide>'}
        }
      });
    
    $stateProvider
      .state('forms.time', {
        url: '/time',
        views: {
          formsmain: {template: '<timeevidence></timeevidence>'}
        }
      });
  
    $stateProvider
      .state('forms.transport', {
        url: '/transport',
        views: {
          leftbar: {template: ''},
          formsmain: {template: '<transport></transport>'}
        }
      });
   
    $stateProvider
      .state('forms.textprocessing', {
        url: '/text',
        views: {
          leftbar: {template: ''},
          formsmain: {template: '<textprocessing></textprocessing>'}
        }
      });
    
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name forms.directive:aide
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="forms">
       <file name="index.html">
        <aide></aide>
       </file>
     </example>
   *
   */
  angular
    .module('forms')
    .directive('aide', aide);
  
  aide.$inject = ['$log', 'Patientsearch', '$http', '$state', '$timeout'];
  
  function aide($log, Patientsearch, $http, $state, $timeout) {
    return {
      restrict: 'E',
      scope: {},
      templateUrl: 'forms/aide-directive.tpl.html',
      replace: false,
      controllerAs: 'aidCtrl',
      controller: function () {
        var vm = this;

        vm.print = print;
        vm.clear = clear;
        vm.getNames = getNames;
        vm.showpatient = showpatient;
        vm.newaide = {};
        vm.newaide.pdate = true;
        vm.newaide.aidedate = moment().locale('de-at').format('ll');
  
        // typeahead patient search backend query
        function getNames(val) {
          console.log('loading names');
    
          return Patientsearch.$search({query: val}).$asPromise().then(function (response) {
            console.log('response: ', response);
            return response.map(function (patient) {
              patient.text = patient.firstname + ' ' + patient.lastname + ', ' + patient.svnr;
              return patient;
            });
          });
        }
  
        // typeahead patient search action
        function showpatient($item, $model, $label) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          vm.patientid = $model._id;
        }
        
        
        function print() {
          var request;
          var payload = {};
          payload.aides = [];
          payload.aides.push(vm.newaide);
          
          request = $http({
            url: '/routes/pdfprinter/genpatientprint',
            method: 'POST',
            data: {
              patientid: vm.patientid,
              doctype: 'aide',
              payload: payload
            }
          });
          
          request.success(function () {
            vm.printed = true;
            $timeout($state.go('home'), 3000);
          });
          request.error(function () {
            vm.printed = false;
          });
          
        }
        
        function clear() {
          vm.patient = null;
          vm.newaide = {};
          vm.newaide.pdate = true;
          vm.newaide.aidedate = moment().locale('de-at').format('ll');
        }
      
        
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name finance
   * @description
   *
   */
  angular
    .module('finance', [
      'ui.router'
    ]);
  
  angular.module('finance')
    .filter('datetime', function () {
      return function (dateString) {
        return moment(dateString).locale('de-at').format('lll');
      };
    });
  
}());

(function () {
  'use strict';

  angular
    .module('finance')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('finance', {
        url: '/finance',
        views: {
          main: {template: '<aside ui-view="leftbar" class="hidden-sm hidden-xs hidden-md col-lg-2 col-xl-3"></aside><div ui-view="financemain" class="col-sm-12 col-md-12 col-lg-10 col-xl-9"></div>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });

    $stateProvider
      .state('finance.invoices', {
        url: '/invoices',
        views: {
          financemain: {template: '<invoicebrowser></invoicebrowser>'}
        }
      });
    
    $stateProvider
      .state('finance.invoices.cashinv', {
        url: '/cashinv',
        views: {
          invoicedetails: {template: '<cashinvoice mode=""></cashinvoice>'}
        },
        onEnter: function () {console.log('finance.invoices.cashinv entered');}
      });
    
    $stateProvider
      .state('finance.invoices.checkinv', {
        url: '/checkinv',
        views: {
          invoicedetails: {template: '<checkinvoice mode=""></checkinvoice>'}
        }
      });
    
    $stateProvider
      .state('finance.invoices.checkpaid', {
        url: '/checkpaid',
        views: {
          invoicedetails: {template: '<checkinvoice mode="paid"></checkinvoice>'}
        }
      });
    
    $stateProvider
      .state('finance.invoices.checkopen', {
        url: '/checkopen',
        views: {
          invoicedetails: {template: '<checkinvoice mode="open"></checkinvoice>'}
        }
      });
    
    $stateProvider
      .state('finance.invoices.checkoverdue', {
        url: '/checkoverdue',
        views: {
          invoicedetails: {template: '<checkinvoice mode="overdue"></checkinvoice>'}
        }
      });
    
    $stateProvider
      .state('finance.cashbook', {
        url: '/cashbook',
        views: {
          leftbar: {template: ''},
          financemain: {template: '<cashbook></cashbook>'}
        }
      });

    $stateProvider
      .state('finance.closing', {
        url: '/closing',
        views: {
          leftbar: {template: ''},
          financemain: {template: '<closing></closing>'}
        }
      });

    $stateProvider
      .state('finance.bmfexport', {
        url: '/bmfexport',
        views: {
          leftbar: {template: ''},
          financemain: {template: '<bmfexport></bmfexport>'}
        }
      });

    $stateProvider
      .state('finance.turnover', {
        url: '/turnover',
        views: {
          leftbar: {template: ''},
          financemain: {template: '<turnover></turnover>'}
        }
      });
  
    $stateProvider
      .state('finance.rksv', {
        url: '/rksv',
        views: {
          leftbar: {template: ''},
          financemain: {template: '<rksv-init></rksv-init><rksv-zero></rksv-zero><depexport></depexport><deplist></deplist>'}
        },
        onEnter: function () {
          console.log('finance.rksv state entered');
        }
      });
    
  } // config
  // klammerfriedhof
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name file
   * @description
   *
   */
  angular
    .module('file', [
      'ui.router',
      'ui.bootstrap',
      'bootstrapLightbox',
      'utils.filereader',
      'utils.fileuploader',
      'ngTagsInput',
      'ui.bootstrap.typeahead',
      'dms.scan'
    ])
    .config(config);

  config.$inject = ['LightboxProvider'];

  function config(LightboxProvider) {
    // set a custom template
    LightboxProvider.templateUrl = 'file/lightbox.html';
    LightboxProvider.getImageUrl = function (image) {
      return '/routes/files/filereader/' + image.fileid;
    };

    LightboxProvider.getImageCaption = function (image) {
      return image.label;
    };
    // all images sclaed to the largest possible dimension
    LightboxProvider.fullScreenMode = true;
  }

}());

(function () {
  'use strict';

  angular
    .module('file')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('file', {
        url: '/file',
        views: {
          //'main': {template: '<file-demo></file-demo>'},
          'main': {templateUrl: 'file/file.tpl.html'},
          //'main': {template: '<h3>file</h3>'},
          //'leftbar': {template: '<patient-today></patient-today>'},
          'rightupper': {template: '<todowidget></todowidget>'},
          'rightlower': {template: '<h3>I\'m a chat window</h3>'}
        }
      });
  }


}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name file.factory:File
   *
   * @description
   *
   */
  angular
    .module('file')
    .factory('File', File);
   
  File.$inject = ['restmod'];

  function File(restmod) {
    return restmod.model('/routes/files');
  }

// klammerfriedhof
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name file.directive:fileDemo
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="file">
   <file name="index.html">
   <file-demo></file-demo>
   </file>
   </example>
   *
   */
  angular
    .module('file')
    .directive('fileDemo', fileDemo);

  function fileDemo() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'file/file-demo-directive.tpl.html',
      replace: false,
      controller: 'FileCtrl',
      controllerAs: 'fileCtrl',
      bindToController: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name file.controller:FileCtrl
   *
   * @description
   *
   */
  angular
    .module('file')
    .controller('FileCtrl', FileCtrl);


  FileCtrl.$inject = ['File', 'Share', 'FileGrabberStart', 'FileGrabberStop', 'Lightbox', 'hotkeys', '$scope', '$log'];


  function FileCtrl(File, Share, FileGrabberStart, FileGrabberStop, Lightbox, hotkeys, $scope, $log) {
    var vm = this;
    vm.ctrlName = 'FileCtrl';
    vm.File = File;
    vm.allshares = Share.$search();

    vm.fileGrabberStart = FileGrabberStart;
    vm.fileGrabberStop = FileGrabberStop;
    vm.getFiles = getFiles;
    vm.showFiles = showFiles;

    vm.fileKeep = fileKeep;
    vm.fileInclude = fileInclude;
    vm.fileDelete = fileDelete;
    vm.fileLabel = fileLabel;
    vm.next = next;
    vm.previous = previous;


    vm.demoStop = demoStop;
    vm.demoStart = demoStart;


    function getFiles(jobid) {
      File.$search({jobid: jobid.jobid}).$asPromise().then(function (files) {
        vm.files = files;
        console.log('vm.files: ', vm.files);
        vm.showFiles();
      });
    }

    function previous(image) {
      if (!image.processed) {
        image.keep = true;
        image.processed = true;
      }
      image.$save();
      Lightbox.prevImage();
    }

    function next(image) {
      if (!image.processed) {
        image.keep = true;
        image.processed = true;
      }
      image.$save();
      Lightbox.nextImage();
    }

    function showFiles() {
      Lightbox.openModal(vm.files, 0);
    }

    function fileKeep(image) {
      console.log('File keep: ', image);
      image.keep = true;
      image.processed = true;
      image.$save();
    }

    function fileInclude(image) {
      console.log('File Include ', image);
      image.keep = true;
      image.include = true;
      image.processed = true;
      image.$save();
    }

    function fileDelete(image) {
      console.log('File delete ', image);
      image.keep = false;
      image.processed = true;
      image.include = false;
      image.$save();
      Lightbox.nextImage();
    }

    function fileUnDelete(image) {
      console.log('File delete ', image);
      image.keep = true;
      image.processed = true;
      image.include = false;
      image.$save();
    }

    function fileLabel(image) {
      console.log('File label: ', image);
      image.keep = true;
      image.include = true;
      image.processed = true;
      image.$save();
      Lightbox.nextImage();
    }


    // hotkeys for image picker
    hotkeys.bindTo($scope)
      .add({
        combo: 'b',
        description: 'in Befund',
        callback: function () {
          $log.debug('Lightbox.image: ', Lightbox.image);
          fileInclude(Lightbox.image);
        }
      })
      .add({
        combo: 's',
        description: 'Speichern',
        callback: function () {
          fileKeep(Lightbox.image);
        }
      })
      .add({
        combo: 'x',
        description: 'Löschen',
        callback: function () {
          fileDelete(Lightbox.image);
        }
      })
      .add({
        combo: 'u',
        description: 'Un-Löschen',
        callback: function () {
          fileUnDelete(Lightbox.image);
        }
      })
      .add({
        combo: 't',
        description: 'Text',
        callback: function () {
          fileLabel(Lightbox.image);
        }
      })
      .add({
        combo: 't',
        description: 'Text',
        callback: function () {
          fileLabel(Lightbox.image);
        }
      })
      .add({
        combo: 'left',
        description: 'Vorheriges Bild',
        callback: function () {
          next(Lightbox.image);
        }
      })
      .add({
        combo: 'right',
        description: 'N&auml;chste Bild',
        callback: function () {
          previous(Lightbox.image);
        }
      });


    // for demonstration purposes only
    function demoStart(share) {
      var jobid = vm.fileGrabberStart(share._id);
      if (typeof vm.openGrabbers === 'undefined') {
        vm.openGrabbers = [];
      }
      vm.openGrabbers.push(share);
      return jobid;
    }

    // for demonstration purposes only
    function demoStop(share, metadata, $index) {
      var stopobj = {};
      stopobj.shareid = share._id;
      stopobj.metadata = metadata;
      vm.fileGrabberStop(stopobj).$asPromise().then(function (jobid) {
        vm.openGrabbers.splice($index);
        vm.getFiles(jobid);
      });
    }

// klammerfriedhof
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name file.directive:fileBrowser
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="file">
   <file name="index.html">
   <file-browser></file-browser>
   </file>
   </example>
   *
   */
  angular
    .module('file')
    .directive('fileBrowser', fileBrowser);

  fileBrowser.$inject = ['File', 'FileAhead', '$log', '$http'];

  function fileBrowser(File, FileAhead, $log, $http) {
    return {
      restrict: 'E',
      scope: {
        patient: '=?'
      },
      templateUrl: 'file/file-browser-directive.tpl.html',
      replace: false,
      controllerAs: 'fbCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'fileBrowser';
        vm.addFile = addFile;
        vm.getTags = getTags;
        vm.saveDoc = saveDoc;
        vm.addFileComplex = addFileComplex;
        
        
        // if called in patient view only search for patient related documents
        if (angular.isObject(vm.patient)) {
          vm.allFiles = File.$search({patientid: vm.patient._id});
          vm.patdocs = true;
        }
        // otherwise grab all files
        else {
          vm.allFiles = File.$search({patientid: {$exists: false}, doccat: 'generic'});
          vm.gendocs = true;
        }

        // needed for st-table search sort
        vm.af = vm.allFiles;


        
        // upload without Vidierung
        function addFile() {
          if (vm.newfile) {
            File.$find(vm.newfile.fileid).$asPromise().then(function (file) {
              if (angular.isObject(vm.patient)) {
                if (angular.isString(vm.patient.firstname) && angular.isString(vm.patient.lastname) && angular.isString(vm.patient._id) ) {
                  file.patientid = vm.patient._id;
                  file.patientfname = vm.patient.firstname + ' ' + vm.patient.lastname;
                }
                file.$save();
              }
              vm.allFiles.$refresh();
              vm.af = vm.allFiles;
            });
            vm.newfile = null;
          }
        }

        // upload with Vidierung
        function addFileComplex() {
          if (vm.newfilecomplex) {
            File.$find(vm.newfilecomplex.fileid).$asPromise().then(function (file) {
              if (angular.isObject(vm.patient)) {
                if (angular.isString(vm.patient.firstname) && angular.isString(vm.patient.lastname) && angular.isString(vm.patient._id) ) {
                  file.patientid = vm.patient._id;
                  file.patientfname = vm.patient.firstname + ' ' + vm.patient.lastname;
                }
                file.$save();
              }
              vm.allFiles.$refresh();
              vm.af = vm.allFiles;
            });
            vm.newfilecomplex = null;
          }
        }

        
        function saveDoc(doc) {
          var flattags = [];
          var i = 0;
          $log.debug('doc: ', doc);
          if (angular.isArray(doc.keywords)) {
            for (i = 0; i < doc.keywords.length; i++) {
              flattags.push(doc.keywords[i].text);
            }
            doc.flattags = flattags;
            doc.$save().$then(function () {
              vm.allFiles.$refresh();
            });
          }
        }


        // tagahead search
        function getTags(val) {
          var myregex = '^' + val; // when using $regex, do not include '/' for regex delimiter
          var match = [
            {'$match': {'flattags': {'$regex': myregex, '$options': 'i'}}},
            {'$unwind': '$flattags'},
            {'$match': {'flattags': {'$regex': myregex, '$options': 'i'}}},
            {'$group': {'_id': '$flattags',  'total': {$sum: 1}}}];
    
          return $http.post('/routes/files/aggregate', match).then(function (response) {
            var i;
            var tags = [];
            // $log.debug('tags data received: ', response);
            for (i = 0; i < response.data.length; i++) {
              tags.push({text: response.data[i]._id});
            }
            return tags;
          });
        }
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */

        // watch for fileuploads and image captures
        scope.$watch('fbCtrl.newfile', function () {
          ctrl.addFile();
        }, true);
  
        scope.$watch('fbCtrl.newfilecomplex', function () {
          ctrl.addFileComplex();
        }, true);
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name examination
   * @description
   *
   */
  angular
    .module('examination', [
      'ui.router',
      'ngTagsInput',
      'ui.bootstrap.typeahead',
      'smart-table',
      'helpers.doctor',
      'consult',
      'rlog'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name examination.factory:Printfindings
   *
   * @description
   *
   */
  angular
    .module('examination')
    .factory('Printfindings', Printfindings);


  Printfindings.$inject = ['$window', '$log', 'Pdfprinter', 'Config', '$state', 'Doctor'];

  function Printfindings($window, $log, Pdfprinter, Config, $state, Doctor) {
    var PrintfindingsBase = {};
    var conf = Config.$find('configuration');
    PrintfindingsBase.printFindings = printFindings;
    


    // create printobj
    // function printFindings(patient) {
    function printFindings(patient, exam) {
      var printobj = {};
      $log.debug('Printfindings.printFindings entered');

      // clean to avoid restmod call stack size exceeded errors
      // printobj.patient.openExam = clean(patient.openExam);
      printobj.patient = clean(patient);

      $log.debug('exam vor clean: ', exam);

      // always assign exam to patient.openExam because xslt expects it to be
      printobj.patient.openExam = clean(exam);

      if (angular.isArray(printobj.patient.openExam.files)) {
        printobj.patient.openExam.files = cleanfiles(printobj.patient.openExam.files);
      }

      $log.debug('exam nach clean: ', exam);
      // if not called form consult, consultid will be undefined
      if (patient.hasOwnProperty('openConsult')) {
        printobj.consultid = patient.openConsult._id;
      }
      printobj.patientid = patient._id;
      printobj.rootelement = 'patient';
      printobj.examid = patient.openExam._id;
      printobj.type = 'Befund-' + exam.template.type;
      printobj.filename = 'befund-' + exam.template.type + '.pdf';
      printobj.foptemplate = 'exam.xslt';

      // resolve doctor references, where available
      // prefer refdoctor over practdoctor
      // do nothing if neither is found (xslt will insert patient data as recepient)
      if (angular.isObject(patient.refdoctor) && angular.isObject(patient.practdoctor)) {
        Doctor.$find(patient.refdoctor._id).$asPromise().then(function (doc) {
          $log.debug('refdoc: ', doc);
          printobj.patient.refdoctor = clean(doc);
          delete printobj.patient.practdoctor;
          buildFindings(printobj, patient);
        });
      }
      if (angular.isObject(patient.refdoctor) && !angular.isObject(patient.practdoctor)) {
        Doctor.$find(patient.refdoctor._id).$asPromise().then(function (doc) {
          $log.debug('refdoc: ', doc);
          printobj.patient.refdoctor = clean(doc);
          buildFindings(printobj, patient);
        });
      }
      if (angular.isObject(patient.practdoctor) && !angular.isObject(patient.refdoctor)) {
        Doctor.$find(patient.practdoctor._id).$asPromise().then(function (doc) {
          $log.debug('practdoc: ', doc);
          printobj.patient.practdoctor = clean(doc);
          buildFindings(printobj, patient);
        });
      }
      if (!angular.isObject(patient.refdoctor) && !angular.isObject(patient.practdoctor)) {
        $log.debug('nodoc! ');
        buildFindings(printobj, patient);
      }
    }


    // make pdf from printobj
    function buildFindings(printobj, patient) {
      var newprinter;

      $log.debug('patient/exam in build findings: ', patient);
      newprinter = Pdfprinter.$build(printobj);
      newprinter.$save().$asPromise().then(function (doc) {
        patient.openExam.docid = doc.docid;
        $log.debug('Exam Findings PDF: ', $window.location.origin + '/routes/pdfreader/' + doc.docid);

        printobj.docid = doc.docid;
        printobj.printername = conf.printers.findingsprinter;
        printobj.time = moment().toISOString();
        if (!patient.openConsult.hasOwnProperty('checkoutdocs')) {
          patient.openConsult.checkoutdocs = [];
        }
        $log.debug('patient/exam in build findings before oC save: ', patient);
        patient.openConsult.checkoutdocs.push(printobj);
        // patient.openConsult.$save();
        // cloesed earlier!
        // patient.openExam.open = false;
        patient.openConsult.$save().$asPromise().then(function () {
          patient.openConsult.examinations.$refresh();
        });


        // this doen't make sense!
        /*patient.openExam.$save().$asPromise().then(function () {
          patient.openConsult.examinations.$refresh();
        });*/
      });

    }
    
    // clean structures of restmod cruft and selfreferencing circles
    function clean(dirty) {
      var cleaned = {};
      var i;
      var reg = /^\$/;

      if (angular.isObject(dirty)) {
        for (i in dirty) {
          if (dirty.hasOwnProperty(i)) {
            switch (i) {
              case 'consults': break;
              case 'consult': break;
              case 'patient': break;
              case 'examinations': break;
              case 'openExam': break;
              case 'openConsult': break;
              default:
                if (!reg.test(i)) {
                  cleaned[i] = dirty[i];
                }
            }
          }
        }
      }
      return cleaned;
    }

    // pull in files, but get rid of restmod cruft
    // only get those files which have include === true
    // exam.xslt is not yet able to ahndle include condition on its own
    function cleanfiles(files) {
      var tmp = [];
      var i = 0;
      for (i = 0; i < files.length; i++) {
        if (files[i].include) {
          tmp.push(files[i]);
        }
      }
      return tmp;
    }

    return PrintfindingsBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name file.directive:filePicker
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="examination">
   <file name="index.html">
   <file-picker></file-picker>
   </file>
   </example>
   *
   */
  angular
    .module('file')
    .directive('filePicker', filePicker);

  filePicker.$inject = ['File', '$log', '$state', '$http', '$timeout', 'hotkeys', 'ConsultRefresh', 'vertxEventBusService', '$cookies', 'Rlog', 'lodash'];

  function filePicker(File, $log, $state, $http, $timeout, hotkeys, ConsultRefresh, vertxEventBusService, $cookies, Rlog, _) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'examination/file-picker-directive.tpl.html',
      replace: false,
      controllerAs: 'fpCtrl',
      bindToController: true,
      controller: function filePickerCtrl() {
        var vm = this;
        vm.$state = $state;
        vm.image = 0;


        vm.fileDelete = fileDelete;
        vm.fileLabel = fileLabel;
        vm.next = next;
        vm.previous = previous;
        vm.close = close;

        vm.patient.openExam.filesloaded = false;
        $timeout(function () {
          vm.errorcondition = 'Bilder konnten nicht geladen werden!';
        }, 10000);


        $log.debug('filepicker entered ');
        $log.debug('filepicker openExam: ', vm.patient.openExam);

        // files can only be loaded once! check for existing files befor loading

        function previous() {
          if (!vm.patient.openExam.files[vm.image].processed) {
            vm.patient.openExam.files[vm.image].keep = true;
            vm.patient.openExam.files[vm.image].processed = true;
          }
          vm.patient.openExam.$save();
          vm.image = vm.image === 0 ? vm.patient.openExam.files.length - 1 : vm.image - 1;
        }

        function next() {
          if (!vm.patient.openExam.files[vm.image].processed) {
            vm.patient.openExam.files[vm.image].keep = true;
            vm.patient.openExam.files[vm.image].processed = true;
          }
          vm.patient.openExam.$save();
          vm.image = vm.image < vm.patient.openExam.files.length - 1 ? vm.image + 1 : 0;
        }

        function fileDelete() {
          vm.patient.openExam.files[vm.image].keep = false;
          vm.patient.openExam.files[vm.image].processed = true;
          vm.patient.openExam.files[vm.image].include = false;
          vm.patient.openExam.files[vm.image].label = 'zum L\u00F6schen markiert';
          vm.patient.openExam.$save();
          vm.image = vm.image < vm.patient.openExam.files.length - 1 ? vm.image + 1 : 0;
        }


        function fileLabel() {
          vm.patient.openExam.files[vm.image].keep = true;
          vm.patient.openExam.files[vm.image].include = false;
          vm.patient.openExam.files[vm.image].processed = true;
          vm.patient.openExam.$save();
          vm.image = vm.image < vm.patient.openExam.files.length - 1 ? vm.image + 1 : 0;
        }


        function close() {
          if (vm.patient.openExam.$hasPendingActions()) {
            $log.debug('openExam has pending actions!');
            Rlog.debug('examination', 'file-picker-directive', 'openExam has pending actions!',
              {
                openExamid: vm.patient.openExam._id,
                oeType: vm.patient.openExam.temaplte.type,
                patientid: vm.patient._id
              });

            vm.patient.openExam.$cancel();
            reallyFinish();
          }
          else {
            $log.debug('finished instantly');
            reallyFinish();
          }
        }

        function reallyFinish() {
          vm.patient.openExam.open = false;
          vm.patient.openExam.$save();
          vm.patient.inexam = false;
          vm.patient.consultstate = 'consult';
          vm.patient.$save();
          $state.go('patientrecord.current.consult');
        }


        /*
         // hotkeys for image picker
         hotkeys.bindTo($scope)
         .add({
         combo: 'enter',
         description: 'in Befund &uuml;bernehmen',
         callback: function () {
         vm.fileLabel();
         }
         })
         .add({
         combo: 'x',
         description: 'Löschen',
         callback: function () {
         vm.fileDelete();
         }
         })
         .add({
         combo: 'right',
         description: 'Vorheriges Bild',
         callback: function () {
         vm.next();
         }
         })
         .add({
         combo: 'left',
         description: 'N&auml;chste Bild',
         callback: function () {
         vm.previous();
         }
         });
         */
      },

      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        var stopobj = {};
        var i = 0;
        var tmp = {};

        // TODO: figure out ramifcations of refreshing at this state
        ConsultRefresh.refreshAll(ctrl.patient);

        /* scope.$watch('consCtrl.patient.consultstate', function (newval, oldval) {
          $log.debug('consultstate watch');
          $log.debug('oldval: ', oldval);
          $log.debug('newval: ', newval);
          if (newval !== oldval && $state.current.name !== ('patientrecord.current.' + newval)) {
            if (newval === '') {
              $state.go('patientrecord.history');
            }
            else {
              $state.go('patientrecord.current.' + newval);
            }
          }
        });*/


        if (ctrl.patient.openExam.filesloaded !== true) {
          // stopobj.shareid = ctrl.patient.openExam.shareid;
          // stopobj.metadata = {filetype: ctrl.patient.openExam.filetype};
          // $log.debug('stopobj: ', stopobj);
          console.log('stop - oE._id: ', ctrl.patient.openExam._id);
          $http.get('/routes/files/filegrabber/stop/' + ctrl.patient.openExam._id)
            .then(function success() {
              console.log('Filegrabber stop success!');
              var fullquery = {};
              fullquery.query = {examid: ctrl.patient.openExam._id};
              fullquery.options = {};
              fullquery.options.sort = {sortorder: 1};
              // File.$search(fullquery).$asPromise().then(function (files) {

                File.$search({examid: ctrl.patient.openExam._id}).$asPromise().then(function (unsortedfiles) {
                  ctrl.patient.openExam.files = [];
// _.orderBy(users, ['user', 'age'], ['asc', 'desc']);
                  var files = _.orderBy(unsortedfiles, ['sortorder'], ['asc']);
                  // this is weird, but necessary
                  // copy file metainformation into openExam
                  // restmod will trip otherwise
                  for (i = 0; i < files.length; i++) {
                    tmp = {};
                    tmp.fileid = files[i].fileid;
                    tmp.mimetype = files[i].mimetype;
                    tmp.idinfiles = files[i]._id;
                    ctrl.patient.openExam.files.push(tmp);
                  }
                  if (files.length === 0) {
                    ctrl.errorcondition = 'Es wurden keine Bilder zu dieser Untersuchung gefunden!';
                  }
                  else {
                    ctrl.patient.openExam.filesloaded = true;
                  }

                  ctrl.patient.openExam.$save();
                  ctrl.max = files.length - 1;
                  $log.debug('vm.files: ', ctrl.patient.openExam.files);
                },
                function failure() {
                  console.log('Filegrabber stop failure!');
                  ctrl.errorcondition = 'Bilder konnten nicht geladen werden!';
                });
            });
        }
        else {

        }


        // listen for examination updates
        vertxEventBusService.on('examinations.update', function (message) {
          var messages = message.split(':');
          $log.debug('exam update mesg: ', message);
          $log.debug('cookie: ', $cookies.get('vertx-web.session'));
          $log.debug('openexam: ', ctrl.patient.openExam);
          $log.debug('openexamid: ', ctrl.patient.openExam._id);
          // the current consult container always has to react to every exam update pertaining to patient, even those caused by himself
          if (ctrl.patient.openExam._id === messages[0]) {
            ctrl.patient.openExam.$refresh();
            $log.debug('(fpd) refreshed openexam for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
          else {
            $log.debug('(fpd) ignored openexam change for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
        });


      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name examination.directive:fieldgroup
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="examination">
       <file name="index.html">
        <fieldgroup gi="?"></fieldgroup>
       </file>
     </example>
   *
   */
  angular
    .module('examination')
    .directive('fieldgroup', fieldgroup);

  function fieldgroup() {
    return {
      restrict: 'E',
      scope: {
        efieldgroup: '=',
        gi: '@?'
      },
      templateUrl: 'examination/exameditor-fieldgroup.tpl.html',
      replace: false,
      controllerAs: 'efgCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;  // yep, this is an empty controller - only holds the parameter
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name examination.directive:field
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="examination">
       <file name="index.html">
        <field></field>
       </file>
     </example>
   *
   */
  angular
    .module('examination')
    .directive('field', field);

  field.$inject = ['$log'];

  function field($log) {
    return {
      restrict: 'E',
      scope: {
        efield: '=',
        ti: '@?',
        gi: '@?'
      },
      templateUrl: 'examination/exameditor-field.tpl.html',
      replace: false,
      controllerAs: 'efCtrl',
      bindToController: true,
      controller: ["$scope", function ($scope) {
        var vm = this;
        vm.name = 'efCtrl';
        vm.loadValues = loadValues;
        vm.save = save;

        vm.groupi = parseInt(vm.gi, 10);
        vm.tabi = parseInt(vm.ti, 10);
        vm.fti = 100*vm.groupi + vm.tabi;
        
        function save() {
          $log.debug('field save called');
          $scope.$emit('tagadded', '');
        }

        function loadValues($query) {
          var promise = new Promise(function (resolve, reject) {
            // TODO this is a rather nasty piece of code to generate a promise that is needed in the next step
            if (true) {
              // console.log('efield values', vm.efield.values);
              resolve(vm.efield.values);
            }
            else {
              reject([]);
            }
          });
          return promise.then(function (values) {
            var filteredValues = values.filter(function (value) {
              return value.name.toLowerCase().indexOf($query.toLowerCase()) !== -1;
            });
            // console.log('filtered values: ', filteredValues);
            return filteredValues;
          });
        }
      }]
    };
  }
}());

(function () {
  'use strict';

  angular
    .module('examination')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('examination', {
        url: '/examination',
        templateUrl: 'examination/examination.tpl.html',
        controller: 'ExaminationCtrl',
        controllerAs: 'examination'
      });
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name examtemplate
   * @description
   *
   */
  angular
    .module('examtemplate', [
      'ui.router',
      'ui.tree',
      'ui.bootstrap',
      'ngTagsInput'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name examination.factory:Examination
   *
   * @description
   *
   */
  angular
    .module('examtemplate')
    .factory('Examination', Examination)
    .config(config);

  config.$inject = ['restmodProvider'];

  function config(restmodProvider) {
    restmodProvider.rebase({
      $config: {
        primaryKey: '_id'
      }
    });
  }

  Examination.$inject = ['restmod'];

  function Examination(restmod) {
    return restmod.model('/routes/examinations').mix({
      patient: {belongsTo: 'Patient', key: 'patientid'},
      consult: {belongsTo: 'Consult', key: 'consultid'},
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true},
      open: {init: false}
    });
  }
}());

(function () {
  'use strict';
  
  /**
   * @ngdoc object
   * @name examination.controller:ExaminationCtrl
   *
   * @description
   *
   */
  angular
    .module('examination')
    .controller('ExaminationCtrl', ExaminationCtrl);
  
  ExaminationCtrl.$inject = ['Examination', 'hotkeys', '$scope', '$http', '$state', '$log', '$timeout', '$interval', 'Rlog'];
  
  function ExaminationCtrl(Examination, hotkeys, $scope, $http, $state, $log, $timeout, $interval, Rlog) {
    var vm = this;
    vm.ctrlName = 'ExaminationCtrl';
    vm.Examination = Examination;
    vm.$state = $state;

    vm.addBiopsyField = addBiopsyField;
    vm.printBiopsyLabels = printBiopsyLabels;
    vm.finishExam = finishExam;
    vm.backToConsult = backToConsult;
    vm.backToRecord = backToRecord;


    function backToConsult() {
      $state.go('patientrecord.current.consult');
    }
    function backToRecord() {
      $state.go('patientrecord.history');
    }

    function printBiopsyLabels() {
      // cancel all pending $saves, $save again, on return send label print request
      if (vm.patient.openExam.$hasPendingActions()) {
        $log.debug('openExam has pending actions!');
        Rlog.debug('examination', 'examination-controller', 'openExam has pending actions!',
          {openExamId: vm.patient.openExam._id, openExamType: vm.patient.openExam.template.type, patientid: vm.patient._id});
        vm.patient.openExam.$cancel();
        vm.patient.openExam.$save().$then(function () {
          reallyPrint();
        });
      }
      else {
        reallyPrint();
      }
    }
    
    function reallyPrint() {
      $http({
        url: '/routes/pdfprinter/examprint',
        method: 'GET',
        params: {
          patientid: vm.patient._id,
          examid: vm.patient.openExam._id,
          doctype: 'labels'
        }
      }).success(function () {
        vm.labelsprinted = true;
      }).error(function () {
        vm.labelsprinted = false;
      });
    }
    

    function addBiopsyField() {
      var biopsy = {
        label: '',
        avalues: [],
        values: []
      };
      vm.patient.openExam.template.biopsy.biopsies.push(biopsy);
    }

    function finishExam() {
      var i;
      var btest = false;
      var biopsies = [];
      $log.debug('examination-controller.finishExam entered');


      // build diagnosis string from parts for quick reference
      if (angular.isObject(vm.patient.openExam.template.diagnosis)) {
        vm.patient.openExam.diagnosis = '';
        for (i = 0; i < vm.patient.openExam.template.diagnosis.avalues.length; i++) {
          vm.patient.openExam.diagnosis += vm.patient.openExam.template.diagnosis.avalues[i].name;
          if (i !== vm.patient.openExam.template.diagnosis.avalues.length - 1) {
            vm.patient.openExam.diagnosis += ', ';
          }
        }
      }

      // add top-level type entry for sorting in table view
      vm.patient.openExam.type = vm.patient.openExam.template.displayname;

      // add formated date vor easy identification and display ordering
      vm.patient.openExam.time = moment().format('HH:mm');

      // create biopsy referral for exams with biopsy field

      // test if biopsie entries where actually created
      if (angular.isObject(vm.patient.openExam.template.biopsy) && vm.patient.openExam.template.biopsy.biopsies.length > 0) {
        biopsies = vm.patient.openExam.template.biopsy.biopsies;
        btest = false;
        for (i = 0; i < biopsies.length; i++) {
          if (biopsies[i].avalues.length > 0) {
            btest = true;
          }
        }
        if (btest) {
          addBiopsyReferral();
        }
      }


      // get images (where applicable) or directly generate PDF
      // exam stays open!
      if (vm.patient.openExam.template.loadfiles === true && vm.patient.openExam.template.shareid !== '') {
        vm.patient.consultstate = 'examfiles';
        vm.patient.$save();
        $state.go('patientrecord.current.examfiles');
      }
      else {
        // rapid double saving can cause lock on $save() operation, check exam backlog first
        safeFinish();
      }
    } // finishExam
    
    
    function safeFinish() {
      if (vm.patient.openExam.$hasPendingActions()) {
        $log.debug('openExam has pending actions!');
        Rlog.debug('examination', 'examination-controller', 'openExam has pending actions!',
          {openExamId: vm.patient.openExam._id, openExamType: vm.patient.openExam.template.type, patientid: vm.patient._id});
        vm.patient.openExam.$cancel();
        reallyFinish();
      }
      else {
        $log.debug('finished instantly');
        reallyFinish();
      }
    }
    
    function reallyFinish() {
      vm.patient.openExam.open = false;
      vm.patient.openExam.$save();
      vm.patient.inexam = false;
      vm.patient.consultstate = 'consult';
      vm.patient.$save();
      $state.go('patientrecord.current.consult');
    }
    
    
    // add patho referral for biopsies
    function addBiopsyReferral() {
      var biolabels = [];
      var label;
      var i;
      if (!vm.patient.openConsult.hasOwnProperty('referrals') || !(vm.patient.openConsult.referrals instanceof Array)) {
        vm.patient.openConsult.referrals = [];
      }
      
      if (!vm.patient.openConsult.hasOwnProperty('newreferral')) {
        vm.patient.openConsult.newreferral = {};
      }
      
      if (angular.isObject(vm.pathology)) {
        vm.patient.openConsult.newreferral.doctor = vm.pathology;
      }
      else {
        vm.patient.openConsult.newreferral.to = 'Pathologie';
      }
      
      if (angular.isString(vm.pathdiag)) {
        vm.patient.openConsult.newreferral.diag = vm.pathdiag;
      }
      else {
        vm.patient.openConsult.newreferral.diag = 'Auffälligkeiten';
      }
      
      if (angular.isString(vm.pathcomment)) {
        vm.patient.openConsult.newreferral.ref = vm.pathcomment;
      }
      else {
        vm.patient.openConsult.newreferral.comment = 'Biopsien';
      }
      
      
      // vm.patient.openConsult.newreferral.biolabels = vm.patient.openExam.template.biopsy.biopsies.join(', ');
      for (i = 1; i <= vm.patient.openExam.template.biopsy.biopsies.length; i++) {
        label = vm.patient.openExam.template.type + ' PE ' + i + ': ';
        vm.patient.openExam.template.biopsy.biopsies[i - 1].avalues.forEach(function (v) {
          $log.debug('v: ', v);
          label += v.name;
          label += ', ';
        });
        biolabels.push(label.substring(0, label.length - 2));
      }
      
      vm.patient.openConsult.newreferral.biolabels = biolabels;
      
      $log.debug('BioReferral: ', vm.patient.openConsult.newreferral);
      if (Object.keys(vm.patient.openConsult.newreferral).length > 0) {
        vm.patient.openConsult.referrals.push(vm.patient.openConsult.newreferral);
        vm.patient.openConsult.newreferral = {};
      }
      vm.patient.openConsult.$save();
    }

    // hotkey grab backspace, which causes uncontrolled 'back' commands in chrom browser
    hotkeys.bindTo($scope)
      .add({
        combo: 'backspace',
        description: 'Unterdrückt: Verhindert Rückwärtsspringen im Browser',
        callback: function (event, hotkey) {
          // do exactly nothing :-)
          // $window.alert('backspace pressed');
          event.preventDefault();
        }
      });


  } // ExaminationCtrl
// klammerfriedhof
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name examination.directive:exameditor
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="examination">
   <file name="index.html">
   <exameditor patient=""></exameditor>
   </file>
   </example>
   *
   */
  angular
    .module('examination')
    .directive('exameditor', exameditor);
  
  exameditor.$inject = ['vertxEventBusService', '$log', '$cookies', 'ConsultRefresh', '$state', '$window', 'Rlog'];

  function exameditor(vertxEventBusService, $log, $cookies, ConsultRefresh, $state, $window, Rlog) {
    return {
      restrict: 'E',
      scope: {
        patient: '='
      },
      templateUrl: 'examination/exameditor-directive.tpl.html',
      replace: false,
      controller: 'ExaminationCtrl',
      controllerAs: 'exeCtrl',
      bindToController: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        // ctrl.patient.openExam.$refresh();
        ConsultRefresh.refreshAll(ctrl.patient);

        /* // watch for consult state changes
        scope.$watch('consCtrl.patient.consultstate', function (newval, oldval) {
          $log.debug('consultstate watch');
          $log.debug('oldval: ', oldval);
          $log.debug('newval: ', newval);
          if (newval !== oldval && $state.current.name !== ('patientrecord.current.' + newval)) {
            if (newval === '') {
              $state.go('patientrecord.history');
            }
            else {
              $state.go('patientrecord.current.' + newval);
            }
          }
        });*/


        // listen for examination updates
        vertxEventBusService.on('examinations.update', function (message) {
          var messages = message.split(':');
          // $log.debug('exam update mesg: ', message);
          // $log.debug('cookie: ', $cookies.get('vertx-web.session'));
          // $log.debug('openexam: ', ctrl.patient.openExam);
          // $log.debug('openexamid: ', ctrl.patient.openExam._id);
          
          // only refresh exam if change was NOT triggered by this browser
          // if (messages[1] !== $cookies.get('vertx-web.session') && ctrl.patient.openExam._id === messages[0]) {
          if (messages[1] !== $window.sessionStorage.getItem('sprtabid') && ctrl.patient.openExam._id === messages[0]) {
            ctrl.patient.openExam.$refresh();
            $log.debug('(ecd) refreshed openexam for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
          else {
            $log.debug(' (ecd) ignored openexam change for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
        });

        /* // listen for patient updates
        vertxEventBusService.on('patients.update', function (message) {
          var messages = message.split(':');
          $log.debug('Patient updated: ', message);
          if (ctrl.patientid === messages[0]) {
            ctrl.patient.$refresh();
            $log.debug('refreshed patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
          if (ctrl.patientid !== messages[0]) {
            $log.debug('ignored patient update for: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
        });*/

        // console.log('i am the exameditor link function');
        
        // listen for changes in template fields
        scope.$on('tagadded', function (ev, args) {
          // console.log('exameditor tagadded event found: ', ev, args);
          // ctrl.patient.openExam.$save();
          // saver saving
          if (ctrl.patient.openExam.$hasPendingActions()) {
            $log.debug('openExam save has pending actions!');
            Rlog.debug('examination', 'exameditor-directive', 'openExam has pending actions!',
              {openExamId: ctrl.patient.openExam._id, examtype: ctrl.patient.openExam.template.type, patientid: ctrl.patient._id});
            ctrl.patient.openExam.$cancel();
            ctrl.patient.openExam.$save().$then(function () {
              $log.debug('exameditor oE.$save() succeeded');
            }, function () {
              $log.error('exameditor oE.$save() failed');
            });
          }
          else {
            // $log.debug('save exam instantly');
            ctrl.patient.openExam.$save().$then(function () {
              $log.debug('exameditor oE.$save() succeeded');
            }, function () {
              $log.error('exameditor oE.$save() failed');
            });
          }
          
        });

      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name examtemplate.directive:examStarter
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="examtemplate">
   <file name="index.html">
   <exam-starter patient="" consult=""></exam-starter>
   </file>
   </example>
   *
   */
  angular
    .module('examtemplate')
    .directive('examStarter', examStarter);


  examStarter.$inject = ['Examtemplate', '$http', '$state', '$log', 'vertxEventBusService'];


  function examStarter(Examtemplate, $http, $state, $log, vertxEventBusService) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'examination/exam-starter-directive.tpl.html',
      replace: false,
      controllerAs: 'esCtrl',
      bindToController: true,
      // controller: 'ExamtemplateCtrl',
      controller: function () {
        var vm = this;
        vm.alltemplates = Examtemplate.$search();
        vm.startExam = startExam;
        // vm.createExam = createExam;
        
        function startExam(template) {
          var newexam = {};
          newexam.templateid = template._id;
          newexam.template = template.template;
          newexam.fdate = moment().locale('de-at').format('ll');

          vm.patient.openConsult.examinations.$create(newexam).$then(function (oe) {
            vm.patient.consultstate = 'exam';
            oe.open = true;

            vm.patient.openExam = oe;
            vm.patient.openExam.patient = vm.patient;
            vm.patient.openExam.$save();
            vm.patient.inexam = true;
            vm.patient.$save();

            if (template.template.loadfiles === true && template.template.shareid !== '') {
              // $http.get('/routes/files/filegrabber/start/' + vm.patient.openExam._id).then(successCallback, errorCallback);
              $http.get('/routes/files/filegrabber/start/' + vm.patient.openExam._id);
              console.log('start - oE._id: ', vm.patient.openExam._id);
            }

            $state.go('patientrecord.current.exam');
            // $state.go('patientrecord.current.modalexam', {patient: vm.patient});
          });
        }

      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        console.log('etCtrl Link function');
        ctrl.alltemplates.$refresh();
        /*
         if ((typeof(ctrl.consult.openExam) !== "undefined") && (ctrl.consult.openExam !== null)) {
         ctrl.openExam = ctrl.consult.openExam.$fetch();
         } */
        // ctrl.consultExams = ctrl.consult.examinations.$fetch();
      }
    };
  }
}());

(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name examination.directive:examList
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="examination">
   <file name="index.html">
   <exam-list></exam-list>
   </file>
   </example>
   *
   */
  angular
    .module('examination')
    .directive('examList', examList);
  
  examList.$inject = ['Examination', 'ExamHelper', '$http', '$log', 'Rlog'];
  
  function examList(Examination, ExamHelper, $http, $log, Rlog) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'examination/exam-list-directive.tpl.html',
      replace: false,
      controllerAs: 'exlCtrl',
      bindToController: true,
      controller: ["$scope", function ($scope) {
        var vm = this;
        // vm.conf = Config.$find('configuration');
        vm.ctrlName = 'ExamlistCtrl';
        vm.patExams = Examination.$search({patientid: vm.patient._id, active: true});
        
        // necessary for table sort and filter
        vm.pexs = vm.patExams;
        
        vm.editExam = editExam;
        vm.spinners = [];
        
        function editExam(exam, index) {
          var closelistener;
          
          ExamHelper.editExam(exam, vm.patient);
          /*
           // catch img edits
           // not needed, edits after consult are on click2save basis
           $scope.$on('imgeditclosed', function () {
           exam.$save();
           });
           */
          
          // Must generate a new pdf on examination edit save'n'quit
          closelistener = $scope.$on('exameditclosed', function () {
            $log.debug('exam-list $on ExamHelper.editExam call exam: ', exam);
            Rlog.debug('examination', 'exam-list-directive', 'exam-list $on exameditclosed called for exam: ' + exam._id, {exam: exam._id});
            
            // TODO: do I need to close the listener - how is it done?
            // closelistener();
            vm.spinners[index] = true;
            // force generate a new pdf
            $http({
              url: '/routes/pdfprinter/exam',
              method: 'GET',
              params: {
                patientid: vm.patient._id,
                examid: exam._id,
                doctype: 'examfinding'
              }
            }).success(function (d) {
              // obsolete, moced to backend
              // exam.docid = d.docid;
              // exam.$save();
              exam.$refresh().$asPromise().then(function (e) {
                $log.debug('exam after refresh: ', e);
                vm.spinners[index] = false;
              });
            });
            
          });
        }
      }],
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        
        
        /*          scope.$watch('exlCtrl.patient._id', function (newval, oldval) {
         if (angular.isString(newval) && newval.length > 20) {
         ctrl.patExams = Examination.$search({patientid: newval._id, active: true});
         }
         }, true);*/
        
        /*
         // listen for consult updates
         ctrl.vertxEventBusService.on('consults.update', function (message) {
         var messages = message.split(':');
         console.log('Consult updated: ', message);
         if (ctrl.patient.openConsult._id === messages[0]) {
         ctrl.patient.openConsult.$refresh();
         console.log('refreshed openConsult for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
         }
         if (ctrl.patient.openConsult._id !== messages[0]) {
         console.log('ignored openConsult update for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
         }
         });
         */
      }
    };
  }
}());

(function () {
  'use strict';
  
  /**
   * @ngdoc service
   * @name examination.factory:ExamHelper
   *
   * @description
   *
   */
  angular
    .module('examination')
    .factory('ExamHelper', ExamHelper);
  
  ExamHelper.$inject = ['$log', '$http', '$uibModal'];
  
  function ExamHelper($log, $http, $uibModal) {
    var ExamHelperBase = {};
    
    ExamHelperBase.editExam = editExam;
    ExamHelperBase.deleteExam = deleteExam;
// yep, this is weird, but editIamge is needed both inside the Helper and inside the modal
    ExamHelperBase.editImage = editImage;
    
    function deleteExam(exam, patient) {
      exam.active = false;
      exam.$save().$asPromise().then(function () {
        patient.openConsult.$refresh();
      });
      
    }
    
    function editExam(exam, patient) {
      $log.debug('editExam in ExamHelper');
      $log.debug('exam: ', exam);
      $log.debug('patient: ', patient);
      // vmo.exam = exam;
      // vmo.patient = patient;
      // vmo.modalinstance = $uibModal.open({
      var modalinstance = $uibModal.open({
        // templateUrl: 'tepledittpl',
        templateUrl: 'examination/exam-helper.tpl.html',
        size: 'lg',
        backdrop: 'static',
        controllerAs: 'eeCtrl',
        controller: ['$uibModalInstance', 'Examination', '$window', '$rootScope', 'Rlog',
          function EditExamCtrl($uibModalInstance, Examination, $window, $rootScope, Rlog) {
            var vm = this;
            
            vm.exam = exam;
            // this doesn't work -> circular structure thanks to restmod
            // vm.exambackup = JSON.parse(JSON.stringify(exam));
            
            vm.patient = patient;
            
            vm.Examination = Examination;
            
            vm.addBiopsyField = addBiopsyField;
            vm.printBiopsyLabels = printBiopsyLabels;
            vm.saveAndQuit = saveAndQuit;
            vm.cancel = cancel;
            vm.editImage = editImage;
            
            function addBiopsyField() {
              $log.debug('addBioLab exam: ', vm.exam);
              var biopsy = {
                label: '',
                avalues: [],
                values: []
              };
              vm.exam.template.biopsy.biopsies.push(biopsy);
              vm.exam.$save();
            }
            
            function printBiopsyLabels() {
              $http({
                url: '/routes/pdfprinter/examprint',
                method: 'GET',
                params: {
                  patientid: vm.patient._id,
                  examid: vm.exam._id,
                  doctype: 'labels'
                }
              }).success(function (p) {
                vm.labelsprinted = true;
              });
            }
            
            
            function saveAndQuit() {
              var i = 0;
              
              // build diagnosis string from parts for quick reference
              if (angular.isObject(vm.exam.template.diagnosis)) {
                vm.exam.diagnosis = '';
                for (i = 0; i < vm.exam.template.diagnosis.avalues.length; i++) {
                  vm.exam.diagnosis += vm.exam.template.diagnosis.avalues[i].name;
                  if (i !== vm.exam.template.diagnosis.avalues.length - 1) {
                    vm.exam.diagnosis += ', ';
                  }
                }
              }
              
              // catch exameditclosed in calling directive to handle mandatory pdf generation!
              /*              vm.exam.$save().$asPromise().then(function reallyClose() {
               $uibModalInstance.close();
               $rootScope.$broadcast('exameditclosed');
               });*/
              
              // this is the final word in save finishing!!!!!
              if (vm.patient.openExam.$hasPendingActions()) {
                $log.debug('exam has pending actions!');
                Rlog.debug('examination', 'exam-helper-factory', 'openExam has pending actions!',
                  {openExamId: vm.patient.openExam._id, openExamType: vm.patient.openExam.template.type, patientid: vm.patient._id});
                vm.exam.$cancel();
                quit();
              }
              else {
                $log.debug('finished instantly');
                quit();
              }
            }
            
            // save, quit, emit save signal after save is confirmed!
            function quit() {
              vm.exam.$save().$then(function () {
                $rootScope.$broadcast('exameditclosed');
                Rlog.debug('examination', 'exam-helper-factory', 'exam-n-quit called for: ' + exam._id, {exam: exam._id});
                $uibModalInstance.close();
              });
              
            }
            
            
            // quit without saving
            function cancel() {
              $uibModalInstance.close();
            }
            
            function editImage(img) {
              $uibModal.open({
                template: '<imglabeler dialog="miCtrl.dialog" img="miCtrl.img"/>',
                size: 'lg',
                controller: ['$uibModalInstance', 'image', function ($uibModalInstance, image) {
                  var vmi = this;
                  vmi.dialog = $uibModalInstance;
                  vmi.img = image;
                  $log.debug('editImg modal controller function');
                }],
                controllerAs: 'miCtrl',
                resolve: {
                  image: function () {
                    $log.debug('editImg resolve function');
                    return img;
                  }
                }
              });
            } // editImage
            
            
          }], // editExam modal controller
        resolve: {
          exam: function () {
            return exam;
          },
          patient: function () {
            return patient;
          }
        }
      }); // editExam modal open
    } // editExam
    
    
    // yep, this is weird, but editIamge is needed both inside the Helper and inside the modal
    // TODO: refactor in a meaningful way
    function editImage(img) {
      $uibModal.open({
        template: '<imglabeler dialog="miCtrl.dialog" img="miCtrl.img"/>',
        size: 'lg',
        controller: ['$uibModalInstance', 'image', function ($uibModalInstance, image) {
          var vmi = this;
          vmi.dialog = $uibModalInstance;
          vmi.img = image;
          $log.debug('editImg modal controller function');
        }],
        controllerAs: 'miCtrl',
        resolve: {
          image: function () {
            $log.debug('editImg resolve function');
            return img;
          }
        }
      });
    } // editImage
    
    return ExamHelperBase;
  }
  
  // klammerfriedhof
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name examination.directive:currentExamsList
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="examination">
   <file name="index.html">
   <current-exams-list></current-exams-list>
   </file>
   </example>
   *
   */
  angular
    .module('examination')
    .directive('currentExamsList', currentExamsList);


  currentExamsList.$inject = ['ExamHelper', '$log'];

  function currentExamsList(ExamHelper, $log) {
    return {
      restrict: 'EA',
      templateUrl: 'examination/current-exams-list-directive.tpl.html',
      replace: false,
      scope: {
        exams: '=',
        patient: '='
      },
      controllerAs: 'celCtrl',
      bindToController: true,
      controller: ["$scope", function celCtrl($scope) {
        var vm = this;
        vm.name = 'currentExamsList';
        vm.editExam = editExam;

        vm.deleteExam = ExamHelper.deleteExam;
        vm.editImage = ExamHelper.editImage;
  
  
        function editExam(exam) {
          ExamHelper.editExam(exam, vm.patient);

          // catch img edits
          $scope.$on('imgeditclosed', function () {
            exam.$save().$asPromise().then(function () {
              vm.patient.openConsult.examinations.$refresh();
            });
          });

          // catch exam saves
          $scope.$on('exameditclosed', function () {
            $log.debug('cel: scope on ExamHelper.editExam call exam: ', exam);
            vm.patient.openConsult.examinations.$refresh();
          });

        }
        
      }]
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name examination.directive:bfield
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="examination">
       <file name="index.html">
        <bfield></bfield>
       </file>
     </example>
   *
   */
  angular
    .module('examination')
    .directive('bfield', bfield);

  bfield.$inject = ['$log', '$rootScope'];

  function bfield($log, $rootScope) {
    return {
      restrict: 'E',
      scope: {
        efield: '=',
        bvalues: '=',
        ti: '@?'
      },
      templateUrl: 'examination/exameditor-bfield.tpl.html',
      replace: false,
      controllerAs: 'bfCtrl',
      bindToController: true,
      controller: ["$scope", function ($scope) {
        var vm = this;
        vm.loadBValues = loadBValues;
        vm.save = save;


        vm.tabi = parseInt(vm.ti, 10);
        vm.fti = 1000 + vm.tabi;

        function save() {
          $log.debug('bfield save called');
          $scope.$emit('tagadded', '');
          // $rootScope.$broadcast('tagadded', '');

        }
        
        function loadBValues($query) {
          var promise = new Promise(function (resolve, reject) {
            // TODO this is a rather nasty piece of code to generate a promise that is needed in the next step
            if (true) {
              // console.log('bfield values', vm.bvalues);
              resolve(vm.bvalues);
            }
            else {
              reject([]);
            }
          });
          return promise.then(function (values) {
            var filteredValues = values.filter(function (value) {
              return value.name.toLowerCase().indexOf($query.toLowerCase()) !== -1;
            });
            // console.log('filtered values: ', filteredValues);
            return filteredValues;
          });
        }
      }]
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name examtemplate.directive:templategenerator
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="examtemplate">
   <file name="index.html">
   <templategenerator></templategenerator>
   </file>
   </example>
   *
   */
  angular
    .module('examtemplate')
    .directive('templategenerator', templategenerator);

  function templategenerator() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'examtemplate/templategenerator-directive.tpl.html',
      replace: false,
      controller: 'ExamtemplateCtrl',
      controllerAs: 'tmCtrl',
      bondToController: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  angular
    .module('examtemplate')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('examtemplate', {
        url: '/examtemplate',
        views: {
          main: {template: '<templategenerator></templategenerator>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<h3>I\'m a chat window</h3>'}
        }
      }
    );
  }


}());

(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name examtemplate.factory:Examtemplate
   *
   * @description
   *
   */

  var obauchsono = {
    type: 'obauchsono',
    displayname: 'Oberbauchsonografie',
    device: {label: 'Ger\u00E4t', name: 'Alpinion E-Cube-9'},
    loadfiles: true,
    shareid: '',
    fieldgroups: [
      {
        label: 'Metainformationen',
        fields: [
          {
            label: 'Untersuchender Arzt',
            avalues: [{name: 'OA. Dr. Rudolf Kantner'}],
            values: [{name: 'OA. Dr. Rudolf Kantner'}, {name: 'OA. Dr. Manfred Fleischer'}]
          },
          {
            label: 'Assistenz',
            avalues: [{name: 'DGKS Alexandra Kantner'}],
            values: [{name: 'DGKS Alexandra Kantner'}, {name: 'Keine Assistenz'}]
          }
        ]
      },
      {
        label: '',
        fields: [
          {
            label: 'Schallbedingungen',
            avalues: [[{name: 'gut'}]],
            values: [[{name: 'gut'}, {name: 'eingeschr\u00E4nkt'}, {name: 'darmgas\u00FCberlagert'}]]
          },
          {
            label: 'Leber',
            avalues: [{name: 'normal gross'}, {name: 'Lebervenen normal kalabriert'}],
            values: [{name: 'normal gross'}, {name: 'homogen'}, {name: 'glattrandig begrenzt'}, {name: 'Lebervenen normal kalabriert'}, {name: 'keine fokalen Herde'}, {name: 'vergr\u00F6ssert'}, {name: 'plumprandig'}, {name: 'Echodichte erh\u00F6ht'}, {name: 'Lebervenen verschm\u00E4lert'}, {name: 'keine fokalen L\u00E4sionen'}, {name: 'verkleinert'}, {name: 'Oberfl\u00E4che h\u00F6ckrig'}, {name: 'Bild einer Cirrhosis hepatis'}]
          },
          {
            label: 'Gallengang',
            avalues: [{name: 'normal weit'}],
            values: [{name: 'normal weit'}, {name: 'erweitert'}, {name: 'nicht darstellbar'}]
          },
          {
            label: 'Gallenblase',
            avalues: [{name: 'zartwandig'}],
            values: [{name: 'zartwandig'}, {name: 'steinfrei'}, {name: 'Konkremente'}, {name: 'entfernt'}]
          },
          {
            label: 'Pankreas',
            avalues: [{name: 'unauff\u00E4llig'}],
            values: [{name: 'unauff\u00E4llig'}, {name: 'darmgas\u00FCberlagert'}, {name: 'caput'}, {name: 'corpus unauff\u00E4llig'}, {name: 'cauda \u00FCberlagert'}]
          },
          {
            label: 'Milz',
            avalues: [{name: 'normal gross'}],
            values: [{name: 'normal gross'}, {name: 'glattrandig'}, {name: 'vergr\u00F6ssert'}, {name: 'entfernt'}]
          }
        ]
      },
      {
        label: 'Nieren',
        'marginleft': '3em',
        fields: [
          {
            label: 'rechts',
            avalues: [{name: 'normal gross'}, {name: 'normales Parenchym-Pyelonverh\u00E4ltnis'}],
            values: [{name: 'normal gross'}, {name: 'normales Parenchym-Pyelonverh\u00E4ltnis'}, {name: 'keine Stauungszeichen'}, {name: 'kein Steinnachweis'}, {name: 'erstgradige Stauungszeichen'}, {name: 'zweitgradige Stauuungszeichen'}, {name: 'drittgradige Stauungszeichen'}]
          },
          {
            label: 'links',
            avalues: [{name: 'normal gross'}, {name: 'normales Parenchym-Pyelonverh\u00E4ltnis'}],
            values: [{name: 'normal gross'}, {name: 'normales Parenchym-Pyelonverh\u00E4ltnis'}, {name: 'keine Stauungszeichen'}, {name: 'kein Steinnachweis'}, {name: 'erstgradige Stauungszeichen'}, {name: 'zweitgradige Stauuungszeichen'}, {name: 'drittgradige Stauungszeichen'}]
          }
        ]
      }
    ],
    comment: {label: 'Anmerkungen', avalues: [], values: []},
    diagnosis: {label: 'Diagnose', avalues: [], values: []},
    recommendation: {label: 'Empfehlung', avalues: [], values: []}
  };

  var emptytemplate = {
    type: 'newexam',
    displayname: 'Neue Untersuchung',
    device: {label: 'Ger\u00E4t', name: ''},
    loadfiles: false,
    shareid: '',
    fieldgroups: [
      {
        label: 'Metainformationen',
        fields: [
          {label: 'Untersuchender Arzt', avalues: [], values: []},
          {label: 'Assistenz', avalues: [], values: []},
          {label: 'Sedierung', avalues: [], values: []},
          {label: 'Narkose', avalues: [], values: []}
        ]
      },
      {
        label: 'Feldgruppe',
        fields: [
          {label: 'Neues Feld', avalues: [], values: []},
          {label: 'Neues Feld', avalues: [], values: []}
        ]
      },
      {
        label: 'Feldgruppe',
        fields: [
          {label: 'Neues Feld', avalues: [], values: []},
          {label: 'Neues Feld', avalues: [], values: []}
        ]
      }
    ],
    biopsy: {
      label: 'Biopsien',
      values: [],
      biopsies: [{label: '', avalues: [], values: []}]
    },
    comment: {label: 'Anmerkungen', avalues: [], values: []},
    diagnosis: {label: 'Diagnose', avalues: [], values: []},
    recommendation: {label: 'Empfehlung', avalues: [], values: []}
  };

  angular
    .module('examtemplate')
    .factory('Examtemplate', Examtemplate)
    .factory('Sampletemplate', Sampletemplate)
    .config(config);

  config.$inject = ['restmodProvider'];

  function config(restmodProvider) {
    restmodProvider.rebase({
      $config: {
        primaryKey: '_id'
      }
    });
  }


  Examtemplate.$inject = ['restmod'];

  function Examtemplate(restmod) {
    return restmod.model('/routes/examtemplates').mix({

      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      description: {init: ''},
      active: {init: true},
      template: {init: emptytemplate}
    });
  }


  /* todo: why dosn't this work?
   LoadSampletemplate.$inject = ['Examtemplate'];

   function LoadSampletemplate() {
   var samples = Examtemplate.$search();
   return samples.$create(obauchsono);
   }*/


  Sampletemplate.$inject = ['restmod'];

  function Sampletemplate(restmod) {
    return restmod.model('/routes/examtemplates').mix({

      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      description: {init: ''},
      active: {init: true},
      template: {init: obauchsono}
    });
  }


// klammerfriedhof
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name examtemplate.controller:ExamtemplateCtrl
   *
   * @description
   *
   */
  TemplateEditCtrl.$inject = ["$uibModalInstance", "tpl", "Share"];
  TemplateTestCtrl.$inject = ["$uibModalInstance", "tpl", "patient"];
  angular
    .module('examtemplate')
    .controller('ExamtemplateCtrl', ExamtemplateCtrl)
    .controller('TemplateEditCtrl', TemplateEditCtrl)
    .controller('TemplateTestCtrl', TemplateTestCtrl);

  ExamtemplateCtrl.$inject = ['Examtemplate', 'Examination', 'TestPatient', '$uibModal', 'Sampletemplate', '$log', 'Share'];

  function ExamtemplateCtrl(Examtemplate, Examination, TestPatient, $uibModal, Sampletemplate, $log, Share) {
    var vm = this;
    vm.ctrlName = 'ExamtemplateCtrl';
    vm.loadSampletemplate = loadSampletemplate;
    vm.Exam = Examination;
    vm.Examtemplate = Examtemplate;
    vm.alltemplates = vm.Examtemplate.$search();


    vm.createTemplate = createTemplate;
    vm.deleteTemplate = deleteTemplate;
    vm.editTemplate = editTemplate;
    vm.testTemplate = testTemplate;
    
    

    function loadSampletemplate() {
      var samples = Sampletemplate.$search();
      samples.$create();
      vm.alltemplates.$refresh();
    }


    function createTemplate() {
      if (vm.alltemplates.length === 0) {
        vm.newtemplate = vm.Examtemplate.$create();
        vm.alltemplates.$refresh();
      }
      else {
        vm.newtemplate = vm.alltemplates.$create();
      }
      console.log('newtemplate: ', vm.newtemplate);
      vm.editTemplate(vm.newtemplate);
    }

    function editTemplate(tpl) {
      $uibModal.open({
        templateUrl: 'tepledittpl',
        size: 'lg',
        controller: ['$uibModalInstance', 'tpl', 'Share', TemplateEditCtrl],
        controllerAs: 'tpeCtrl',
        resolve: {
          tpl: function () {
            return tpl;
          }
        }
      });
    }

    function testTemplate(tpl) {
      var newexam = {};
      var patient = TestPatient.$create();

      newexam.created = new Date().toISOString();
      newexam.test = true;
      newexam.templateid = tpl._id;
      newexam.template = tpl.template;
      newexam.patientid = patient._id;

      vm.Exam.$create(newexam).$asPromise().then(function (oe) {
        patient.openExam = oe;
        patient.$save();
        // $state.go('patientrecord.current.exam');
        $uibModal.open({
          // templateUrl: 'tepledittpl',
          template: '<exameditor patient="tptCtrl.patient"></exameditor>',
          size: 'lg',
          controller: ['$uibModalInstance', 'tpl', 'patient', TemplateTestCtrl],
          controllerAs: 'tptCtrl',
          resolve: {
            tpl: function () {
              return tpl;
            },
            patient: function () {
              return patient;
            }
          }
        });

      });
    }

    function deleteTemplate(tpl) {
      tpl.active = false;
      tpl.$save();
    }
  } // ExamtemplateCtrl


  function TemplateTestCtrl($uibModalInstance, tpl, patient) {
    var vm = this;
    vm.tpl = tpl;
    vm.patient = patient;
    vm.$uibModalInstance = $uibModalInstance;
  }


  function TemplateEditCtrl($uibModalInstance, tpl, Share) {
    var vm = this;
    vm.tpl = tpl;
    vm.allshares = Share.$search({active: true});
    vm.$uibModalInstance = $uibModalInstance;

    vm.newFieldGroup = function () {
      vm.tpl.template.fieldgroups.push({
        label: 'Neue Feldgruppe',
        fields: [{
          label: 'Neues Feld',
          avalues: [],
          values: []
        }]
      });
    };

    vm.newSubItem = function (scope) {
      var nodeData = scope.$modelValue;
      nodeData.fields.push({
        label: 'Neues Feld',
        avalues: [],
        values: []
      });
    };

    vm.remove = function (scope) {
      scope.remove();
      vm.tpl.$save();
    };

    vm.addNamedField = function (type) {
      var labels = {
        comment: 'Kommentar',
        biopsy: 'Biopsie',
        recommendation: 'Empfehlung',
        diagnosis: 'Diagnose'
      };
      var biopsy = {
        label: '',
        avalues: [],
        values: []
      };
      vm.tpl.template[type] = {
        label: labels[type],
        avalues: [],
        values: []
      };
      if (type === 'biopsy') {
        vm.tpl.template[type].biopsies = [];
        vm.tpl.template[type].biopsies.push(biopsy);
      }
      vm.tpl.$save();
    };

    vm.removeNamedField = function (type) {
      delete vm.tpl.template[type];
      vm.tpl.$save();
    };

    vm.toggle = function (scope) {
      scope.toggle();
    };

    vm.closeModal = function () {
      vm.$uibModalInstance.close();
    };

    vm.collapseAll = function () {
      vm.$broadcast('collapseAll');
    };

    vm.expandAll = function () {
      vm.$broadcast('expandAll');
    };
  }


// klammerfriedhof
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name drug
   * @description
   *
   */
  angular
    .module('drug', [
      'ui.router',
      'ui.bootstrap'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name drug.directive:sprtimeline
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="drug">
       <file name="index.html">
        <sprtimeline></sprtimeline>
       </file>
     </example>
   *
   */
  angular
    .module('drug')
    .directive('sprtimeline', sprtimeline);
  
  
  sprtimeline.$inject = ['$log']
  
  function sprtimeline($log) {
    return {
      restrict: 'E',
      scope: {
        data: '=',
        options: '=',
        groups: '=',
        events: '='
      },
      templateUrl: 'drug/sprtimeline-directive.tpl.html',
      replace: false,
      controllerAs: 'sprtimeline',
      bindToController: false,
      controller: function () {
        var vm = this;
        vm.name = 'sprtimeline';
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
  
        var timelineEvents = [
          'rangechange',
          'rangechanged',
          'timechange',
          'timechanged',
          'select',
          'doubleClick',
          'click',
          'contextmenu'
        ];
  
        // Declare the timeline
        var timeline = null;
  
        scope.$watch('data', function (data) {
          
          console.log('watched data: ', data);
          console.log('watched scope: ', scope);
          
          // Sanity check
          if (data === null) {
            return;
          }
          
          if (typeof data === 'undefined') {
            return;
          }
          
          console.log('vis scope: ', scope);
          console.log('data : ', data);
          
          // If we've actually changed the data set, then recreate the graph
          // We can always update the data by adding more data to the existing data set
          if (timeline !== null) {
            timeline.destroy();
          }
    
          // Create the timeline object
          timeline = new vis.Timeline(element[0], data.items, scope.groups, scope.options);
    
          // Attach an event handler if defined
          angular.forEach(scope.events, function (callback, event) {
            if (timelineEvents.indexOf(String(event)) >= 0) {
              timeline.on(event, callback);
            }
          });
    
          // onLoad callback
          $log.debug('scope.events.onload: ', scope.events.onload);
          
          if (scope.events !== null && scope.events.onload !== null && angular.isFunction(scope.events.onload)) {
            scope.events.onload(timeline);
          }
        });
  
        scope.$watchCollection('options', function (options) {
          if (timeline === null) {
            return;
          }
          timeline.setOptions(options);
        });
        
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name drug.factory:PrescriptionHelper
   *
   * @description
   *
   */
  angular
    .module('drug')
    .factory('PrescriptionHelper', PrescriptionHelper);

  PrescriptionHelper.$inject = ['$log', '$uibModal'];

  function PrescriptionHelper($log, $uibModal) {
    var PrescriptionHelperBase = {};

    PrescriptionHelperBase.editPrescriptions = editPrescriptions;

    function editPrescriptions(patient) {
      $log.debug('editPrescription called');
      var modalinstance = $uibModal.open({
        template: '<div class="modalspacer"><prescription patient="miCtrl.patient" dialog="miCtrl.dialog"></prescription></div>',
        size: 'lg',
        backdrop: 'static',
        controllerAs: 'miCtrl',
        controller: ['$uibModalInstance',
          function EditPrescriptionCtrl($uibModalInstance) {
            var vm = this;
            $log.debug('EditPrescriptionCtrl patient: ', patient);
            vm.patient = patient;
            vm.dialog = $uibModalInstance;
          }], // editPrescription modal controller
        resolve: {
          patient: function () {
            $log.debug('EditPrescriptionCtrl resolve function patient: ', patient);
            return patient;
          }
        }
      }); // editPrescription modal open
    }

    return PrescriptionHelperBase;
  }
}());

(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name drug.directive:prescription
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="drug">
   <file name="index.html">
   <prescription patient=""></prescription>
   </file>
   </example>
   *
   */
  angular
    .module('drug')
    .directive('prescription', prescription);
  
  
  prescription.$inject = ['$log', '$http', 'Config', '$window'];
  
  
  function prescription($log, $http, Config, $window) {
    return {
      restrict: 'E',
      scope: {
        patient: '=',
        dialog: '=?'
      },
      templateUrl: 'drug/prescription-directive.tpl.html',
      replace: false,
      controllerAs: 'dpCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        
        vm.conf = Config.$find('configuration');
        
        vm.drugedittpl = 'drug/drugPopoverTemplate.html';
        
        vm.calculateActivedrugs = calculateActivedrugs;
        vm.printPrescription = printPrescription;
        vm.previewPrescription = previewPrescription;
        vm.checkSignatureUpdate = checkSignatureUpdate;
        
        function calculateActivedrugs() {
          var i;
          $log.debug('calcualte active drugs called');
          vm.patient.activedrugs = 0;
          for (i = 0; i < vm.patient.drugs.length; i++) {
            // retire once-off drugs if enddate has been reached
            if (vm.patient.drugs[i].active === true && vm.patient.drugs[i].endDate < moment().toISOString()) {
              vm.patient.drugs[i].active = false;
              vm.patient.drugs[i].print = false;
            }
            // count active, non-foreign drugs that should be printed
            if (vm.patient.drugs[i].active === true && vm.patient.drugs[i].foreign === false && vm.patient.drugs[i].print === true) {
              vm.patient.activedrugs += 1;
            }
          }
          vm.patient.$save();
        }
        
        function printPrescription() {
          if (vm.pdate === true) {
            vm.patient.prescriptiondate = moment().locale('de-at').format('ll');
          }
          else {
            vm.patient.prescriptiondate = '';
          }
          // make sure the prescription date is saved to backend before printing
          vm.patient.$then(function () {
            $log.debug('prescription date saved successfully');
            $http({
              url: '/routes/pdfprinter/patientprint',
              method: 'GET',
              params: {
                patientid: vm.patient._id,
                doctype: 'prescription'
              }
            });
          });
        }
        
        function previewPrescription() {
          var newtab = $window.open('', '_blank');
          if (vm.pdate === true) {
            vm.patient.prescriptiondate = moment().locale('de-at').format('ll');
          }
          else {
            vm.patient.prescriptiondate = '';
          }
          // make sure the prescription date is saved to backend before printing
          vm.patient.$save().$then(function () {
            var link = '/routes/pdfprinter/patientpreview?patientid=' + vm.patient._id + '&doctype=prescription';
            $log.debug('prescription date saved successfully: ', link);
            newtab.location = link;
          });
        }
        
        function checkSignatureUpdate(drug) {
          var tmpdrug = {};
          var item;
          $log.debug('**************** checkSignatureUpdate called for: ', drug);
          if (angular.isString(drug.newamount) && drug.newamount.length > 0) {
            if (drug.newamount !== drug.amount) {
              // copy drug
              for (item in drug) {
                if (drug.hasOwnProperty(item)) {
                  tmpdrug[item] = drug[item];
                }
              }
              // deactive old drug
              drug.active = false;
              drug.endDate = moment().toISOString();
              // create new drug
              tmpdrug.startDate = moment().toISOString();
              tmpdrug.amount = tmpdrug.newamount;
              delete tmpdrug.newamount;
              $log.debug('drug: ', drug);
              $log.debug('tmpdrug: ', tmpdrug);
              $log.debug('patient.drugs before: ', vm.patient.drugs);
              vm.patient.drugs.push(tmpdrug);
              $log.debug('patient.drugs after: ', vm.patient.drugs);
              calculateActivedrugs();
              // no need to save patient, calculateA... already does that!
            }
            else {
              drug.newamount = '';
            }
          }
        }
        
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name patient.directive:patientDrugView
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-drug-view drugs="patient.drugs"></patient-drug-view>
   </file>
   </example>
   *
   */
  angular
    .module('drug')
    .directive('patientDrugView', patientDrugView);
  
  // patientDrugView.$inject = ['$scope', 'Pdfprinter', '$state', 'Drug', 'Drugsearch', 'Printer', '$log', 'Config'];
  patientDrugView.$inject = ['Drugsearch', 'Drug', '$log', '$sce', 'Config'];
  
  // TODO figure out if switichuing this control to  bindToController Syntax is possible
  function patientDrugView(Drugsearch, Drug, $log, $sce, Config) {
    return {
      restrict: 'E',
      scope: {
        patient: '='
      },
      templateUrl: 'drug/patient-drug-view-directive.tpl.html',
      replace: false,
      controllerAs: 'pdvCtrl',
      bindToController: true,
      // controller: 'PatientDrugsCtrl'
      controller: ["$uibModal", function PatientDrugViewCtrl($uibModal) {
        var vm = this;
        vm.conf = Config.$find('configuration');
        vm.data = null;
        vm.getDrugs = getDrugs;
        vm.showdruginfo = showdruginfo;
        vm.showfdruginfo = showfdruginfo;
        vm.addDrug = addDrug;
        vm.addForeignDrug = addForeignDrug;
        vm.replaceDrug = replaceDrug;
        vm.showHistory = showHistory;
        vm.hideHistory = hideHistory;
  
        // vm.clearDrug = clearDrug;
        vm.drugalternativestpl = 'drug/drugalternativestpl.html';
  
        // popover templates
        vm.repopovertpl = 'drug/repopovertpl.html';
        vm.indpopovertpl = 'drug/indpopovertpl.html';
        vm.hintpopovertpl = 'drug/hintpopovertpl.html';
        vm.kzpopover = 'drug/kzpopover.html';
        vm.atcalternativestpl = 'drug/atcalternativestpl.html';
        
        
        // calculate activedrugs helper variable
        function calculateActivedrugs() {
          var i;
          vm.patient.activedrugs = 0;
          for (i = 0; i < vm.patient.drugs.length; i++) {
            if (vm.patient.drugs[i].active === true && vm.patient.drugs[i].foreign === false && vm.patient.drugs[i].print === true) {
              vm.patient.activedrugs += 1;
            }
          }
        }
  
  
        // drugahead drug name completion
        function getDrugs(val) {
          console.log('loading drug names');
          vm.newdrug = {};
          return Drugsearch.$search({query: val}).$asPromise().then(function (response) {
            // console.log('drug search response: ', response);
            return response.map(function (drug) {
              if (angular.isString(drug.amount) && angular.isString(drug.amounttype)) {
                drug.text = drug.name + ' (' + drug.amount + drug.amounttype + ')';
              }
              else {
                drug.text = drug.ZBEZ + ' (' + drug.ZLNUMM + ')';
              }
              return drug;
            });
          });
        }
  
        // typeahead drug search information
        function showdruginfo($item) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          var i = 0;
          var atcquery;
          var atcar = [];
    
          vm.newdrug = {};
    
          $log.debug('showdruginfo called: ', $item);
          vm.selectedDrug = $item;
    
          if (angular.isString(vm.selectedDrug.name)) {
            vm.newdrug.name = vm.selectedDrug.name;
          }
          else {
            vm.newdrug.name = vm.selectedDrug.ZBEZ;
          }
          vm.newdrug.op = 'OP I';
          vm.newdrug.permanent = true;
    
    
          // Suchtgiftvignette
          if (vm.selectedDrug.ZSUCHT === 'SG') {
            vm.newdrug.vignette = true;
            vm.newdrug.comment = 'Suchtgiftvignette!';
          }
    
          // SV Box system
          if (angular.isString(vm.selectedDrug.box)) {
            vm.newdrug.box = vm.selectedDrug.box;
          }
          else {
      
            if (vm.selectedDrug.atcarray.length === 1) {
              atcquery = {atcarray: vm.selectedDrug.atcarray[0]};
            }
            else {
              vm.selectedDrug.atcarray.forEach(function (a) {
                atcar.push({atcarray: a});
              });
              atcquery = {$or: atcar};
              // var foo = {$or: [{columnA: 3}, {columnB: 'string'}]};
            }
      
            vm.newdrug.atcalternatives = Drug.$search(atcquery);
          }
    
    
          // Pharmanr
          if (angular.isString(vm.selectedDrug.pharmanr)) {
            vm.newdrug.pharmanr = vm.selectedDrug.pharmanr;
          }
    
          // Zulassungsnummer
          if (angular.isString(vm.selectedDrug.ZLNUMM)) {
            vm.newdrug.ZLNUMM = vm.selectedDrug.ZLNUMM;
          }
    
          // Array aller ATC codes
          if (angular.isArray(vm.selectedDrug.atcarray)) {
            vm.newdrug.atcarray = vm.selectedDrug.atcarray;
          }
    
          // Fachinformationen
          if (angular.isString(vm.selectedDrug.fi)) {
            // "fi" : "/fi_pdf/1-21520.pdf"
            // "fi" : "/eu_pdf/1-21520.pdf"
            vm.newdrug.fi = vm.selectedDrug.fi.replace(/_/g, '');
            vm.newdrug.fi = vm.newdrug.fi.replace(/\.pdf/g, '');
          }
    
          // Postion im SV ekotool preivergleich
          if (angular.isString(vm.selectedDrug.position)) {
            vm.newdrug.svpos = parseInt(vm.selectedDrug.position, 10);
          }
    
          // packungsgröße/art
          if (angular.isString(vm.selectedDrug.amount) && angular.isString(vm.selectedDrug.amounttype)) {
            vm.newdrug.pkgsize = vm.selectedDrug.amount + ' ' + vm.selectedDrug.amounttype;
          }
    
          if (angular.isString(vm.selectedDrug.amount) && !angular.isString(vm.selectedDrug.amounttype)) {
            vm.newdrug.pkgsize = vm.selectedDrug.amount;
          }
    
          // if (angular.isString()) {}
    
          // Hinweis zum Medikament
          if (vm.selectedDrug.hint) {
            vm.newdrug.hint = $sce.trustAsHtml('<div>' + vm.selectedDrug.hint + '</div>');  // overlay html must be contained in single tag
          }
    
          // kassenzeichen
          // KZ RE1 RE2
          if (vm.selectedDrug.kz === 'RE1' || vm.selectedDrug.kz == 'RE2') {
            vm.newdrug.re = vm.selectedDrug.kz;
          }
          if (vm.selectedDrug.recriteria) {
            $log.debug('recriteria: ', vm.selectedDrug.recriteria);
            vm.newdrug.recriteria = $sce.trustAsHtml('<div>' + vm.selectedDrug.recriteria + '</div>');  // overlay html must be contained in single tag
          }
    
          // KZ IND
          if (vm.selectedDrug.kz && vm.selectedDrug.kz === 'IND') {
            vm.newdrug.ind = true;
          }
    
          if (vm.selectedDrug.indcriteria) {
            vm.newdrug.indcriteria = $sce.trustAsHtml('<div>' + vm.selectedDrug.indcriteria + '</div>');
          }
    
    
          // All other KZ entries, use popover lookup table
          if (vm.selectedDrug.kz !== '' && vm.selectedDrug.kz !== 'RE1' &&
            vm.selectedDrug.kz !== 'RE2' && vm.selectedDrug.kz !== 'IND') {
            vm.newdrug.kz = vm.selectedDrug.kz;
          }
    
    
          // alternatives
          vm.newdrug.alternatives = [];
          if (angular.isArray(vm.selectedDrug.alternatives)) {
            vm.newdrug.alternatives = [];
            // reorder by price/posnr
            // sv data format is crap - alternatives are not sorted by proce/posnr
            // more than one drug can occupy the same posnr
            // at least they had the decency to leave space for ex-aequo drugs
            // if more than 3 products tie, I simply don't care, third place gets overridden
            for (i = 0; i < vm.selectedDrug.alternatives.length; i++) {
              if (vm.selectedDrug.alternatives[i].posnr < vm.newdrug.svpos) {
                switch (vm.selectedDrug.alternatives[i].comparetype) {
                  case '1':
                    vm.selectedDrug.alternatives[i].color = 'success';
                    vm.selectedDrug.alternatives[i].tooltip = 'Alternative mit gleichem Wirkstoff und gleicher Stärke';
                    break;
                  case '2':
                    vm.selectedDrug.alternatives[i].color = 'warning';
                    vm.selectedDrug.alternatives[i].tooltip = 'Biosimilar, Alternative mit gleichem Wirkstoff und ähnlicher Stärke';
                    break;
                  case '3':
                    vm.selectedDrug.alternatives[i].color = 'error';
                    vm.selectedDrug.alternatives[i].tooltip = 'Alternative mit anderem Wirkstoff';
                    break;
                  default:
                    break;
                }
              }
              else {
                vm.selectedDrug.alternatives[i].color = 'default';
                vm.selectedDrug.alternatives[i].tooltip = 'Alternative ist nicht günstiger als das gewählte Produkt';
              }
              if (!vm.newdrug.alternatives[vm.selectedDrug.alternatives[i].posnr - 1]) {
                vm.newdrug.alternatives[vm.selectedDrug.alternatives[i].posnr - 1] = vm.selectedDrug.alternatives[i];
              }
              else {
                if (!vm.newdrug.alternatives[vm.selectedDrug.alternatives[i].posnr]) {
                  vm.newdrug.alternatives[vm.selectedDrug.alternatives[i].posnr] = vm.selectedDrug.alternatives[i];
                }
                else {
                  if (!vm.newdrug.alternatives[vm.selectedDrug.alternatives[i].posnr + 1]) {
                    vm.newdrug.alternatives[vm.selectedDrug.alternatives[i].posnr + 1] = vm.selectedDrug.alternatives[i];
                  }
                  else {
                    vm.newdrug.alternatives[vm.selectedDrug.alternatives[i].posnr + 2] = vm.selectedDrug.alternatives[i];
                  }
                }
              }
            }
      
            // type 3: Anderer Wirkstoff
            for (i = 0; i < vm.newdrug.svpos; i++) {
              if (vm.newdrug.alternatives[i].comparetype === '3') {
                vm.newdrug.alttype = 'danger';
                vm.newdrug.alttypetext = 'Alternativen mit anderen Wirkstoffen sind günstiger';
              }
            }
      
            // type 2: Gleicher Wirkstoff, andere Dosis, Biosimilars
            for (i = 0; i < vm.newdrug.svpos; i++) {
              if (vm.newdrug.alternatives[i].comparetype === '2') {
                vm.newdrug.alttype = 'warning';
                vm.newdrug.alttypetext = 'Alternativen mit Biosimilars (gleicher Wirkstoff, ähnliche Stärke) sind günstiger';
              }
            }
      
            // type 1: Gleicher Wirkstoff, gleiche Stärke
            for (i = 0; i < vm.newdrug.svpos; i++) {
              if (vm.newdrug.alternatives[i].comparetype === '1') {
                vm.newdrug.alttype = 'success';
                vm.newdrug.alttypetext = 'Alternativen mit gleichem Wirkstoff und gleicher Stärke sind günstiger';
              }
            }
      
            // if med was already in first position, greyout alternatives option
            if (vm.newdrug.svpos === 1) {
              vm.newdrug.alttype = 'default';
              vm.newdrug.alttypetext = 'Dieses Medikament ist das günstigste mit diesem Wirkstoff und dieser Stärke';
            }
      
            $log.debug('selected drug: ', vm.selectedDrug);
            $log.debug('new drug: ', vm.newdrug);
          } // rebuild alternatives
        }
        
        // show foreign drug info
        function showfdruginfo($item) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          vm.newfdrug = {};
    
          $log.debug('showfdruginfo called: ', $item);
          vm.selectedfDrug = $item;
    
          if (angular.isString(vm.selectedfDrug.name)) {
            vm.newfdrug.name = vm.selectedfDrug.name;
          }
          else {
            vm.newfdrug.name = vm.selectedfDrug.ZBEZ;
          }

          vm.newfdrug.permanent = true;
          
    
          // Pharmanr
          if (angular.isString(vm.selectedfDrug.pharmanr)) {
            vm.newfdrug.pharmanr = vm.selectedfDrug.pharmanr;
          }
    
          // Zulassungsnummer
          if (angular.isString(vm.selectedfDrug.ZLNUMM)) {
            vm.newfdrug.ZLNUMM = vm.selectedfDrug.ZLNUMM;
          }
    
          // Array aller ATC codes
          if (angular.isArray(vm.selectedfDrug.atcarray)) {
            vm.newfdrug.atcarray = vm.selectedfDrug.atcarray;
          }
    
          // Fachinformationen
          if (angular.isString(vm.selectedfDrug.fi)) {
            // "fi" : "/fi_pdf/1-21520.pdf"
            // "fi" : "/eu_pdf/1-21520.pdf"
            vm.newfdrug.fi = vm.selectedfDrug.fi.replace(/_/g, '');
            vm.newfdrug.fi = vm.newfdrug.fi.replace(/\.pdf/g, '');
          }
    
         
          if (angular.isString(vm.selectedfDrug.amount) && !angular.isString(vm.selectedfDrug.amounttype)) {
            vm.newfdrug.pkgsize = vm.selectedfDrug.amount;
          }
    
          // if (angular.isString()) {}
    
          // Hinweis zum Medikament
          if (vm.selectedfDrug.hint) {
            vm.newfdrug.hint = $sce.trustAsHtml('<div>' + vm.selectedfDrug.hint + '</div>');  // overlay html must be contained in single tag
          }
    
          // kassenzeichen
          // KZ RE1 RE2
          if (vm.selectedfDrug.kz === 'RE1' || vm.selectedfDrug.kz === 'RE2') {
            vm.newfdrug.re = vm.selectedfDrug.kz;
          }
          if (vm.selectedfDrug.recriteria) {
            $log.debug('recriteria: ', vm.selectedfDrug.recriteria);
            vm.newfdrug.recriteria = $sce.trustAsHtml('<div>' + vm.selectedfDrug.recriteria + '</div>');  // overlay html must be contained in single tag
          }
    
          // KZ IND
          if (vm.selectedfDrug.kz && vm.selectedfDrug.kz === 'IND') {
            vm.newfdrug.ind = true;
          }
    
          if (vm.selectedfDrug.indcriteria) {
            vm.newfdrug.indcriteria = $sce.trustAsHtml('<div>' + vm.selectedfDrug.indcriteria + '</div>');
          }
    
    
          // All other KZ entries, use popover lookup table
          if (vm.selectedfDrug.kz !== '' && vm.selectedfDrug.kz !== 'RE1' &&
            vm.selectedfDrug.kz !== 'RE2' && vm.selectedfDrug.kz !== 'IND') {
            vm.newfdrug.kz = vm.selectedfDrug.kz;
          }
        }
        
  
        function replaceDrug(replacement) {
//          $log.debug('replacement: ', replacement.pharmanr);
          Drugsearch.$search({query: replacement.pharmanr}).$asPromise().then(function (rdrug) {
            vm.newdrug = {};
            vm.showdruginfo(rdrug[0]);
            if (rdrug.length > 1) {
              $log.debug('Pharmanr search returned more than one result');
            }
          });
        }
  
        function addDrug() {
          delete vm.noResults;
          vm.newdrug.color = 'primary';
          vm.newdrug.foreign = false;
          vm.newdrug.active = true;
          vm.newdrug.startDate = moment().toISOString();
          vm.newdrug.print = true;
          if (!vm.newdrug.permanent) {
            vm.newdrug.endDate = moment().add(14, 'days').toISOString();
          }
          vm.patient.drugs.push(vm.newdrug);
          calculateActivedrugs();
          vm.patient.$save();
          vm.selectedDrug = {};
          vm.newdrug = {};
          vm.newdrug.op = 'OP I';
          vm.asyncSelected = '';
        }
  
        function addForeignDrug() {
          delete vm.noFDResults;
          vm.newfdrug.color = 'info';
          vm.newfdrug.foreign = true;
          vm.newfdrug.permanent = true;
          vm.newfdrug.active = true;
          vm.newfdrug.startDate = moment().toISOString();
          vm.newfdrug.print = false;
          vm.patient.drugs.push(vm.newfdrug);
          calculateActivedrugs();
          vm.newfdrug = null;
          vm.patient.$save();
        }
  
  
        function showHistory() {
          var i = 0;
  
          // vis-timeline expects a data object containing items[]
          vm.data = {};
          vm.data.items = [];
          for (i = 0; i < vm.patient.drugs.length; i++) {
                       
            vm.data.items[i] = {};
            $log.debug('vm.patient.drugs[i].name: ', vm.patient.drugs[i].name, vm.patient.drugs[i]);
            if (angular.isString(vm.patient.drugs[i].name)) {
              vm.data.items[i].content = vm.patient.drugs[i].name.split(' ')[0] + ', ' + vm.patient.drugs[i].amount;
            }
            else {
              vm.data.items[i].content = vm.patient.drugs[i].amount;
            }
            vm.data.items[i].title = vm.patient.drugs[i].name + ', ' + vm.patient.drugs[i].amount;
            if (angular.isString(vm.patient.drugs[i].comment)) {
              $log.debug('Komment: ', vm.patient.drugs[i].comment, vm.patient.drugs[i].name);
              vm.data.items[i].title += ', ';
              vm.data.items[i].title += vm.patient.drugs[i].comment;
              $log.debug('Komment: ', vm.data.items[i].title);
            }
            if (angular.isString(vm.patient.drugs[i].privcomment)) {
              $log.debug('Komment: ', vm.patient.drugs[i].privcomment, vm.patient.drugs[i].name);
              vm.data.items[i].title += ', ';
              vm.data.items[i].title += vm.patient.drugs[i].privcomment;
              $log.debug('PrivKomment: ', vm.data.items[i].title);
            }
            vm.data.items[i].start = vm.patient.drugs[i].startDate;
            if (angular.isString(vm.patient.drugs[i].endDate)) {
              vm.data.items[i].end = vm.patient.drugs[i].endDate;
            }
            else {
              vm.data.items[i].end = new Date();
            }
            vm.data.items[i].className = vm.patient.drugs[i].color;
  
          }
          $log.debug('vm.data: ', vm.data);

          vm.options = {};
          vm.groups = {};
          // vm.events = {};
          vm.events = ['onload'];
          
          var modalinstance = $uibModal.open({
            // templateUrl: 'tepledittpl',
            template: '<div class="modalspacer"><sprtimeline data="shCtrl.data" options="shCtrl.options" events="shCtrl.events"></sprtimeline></div>',
            size: 'lg',
            backdrop: true,
            controllerAs: 'shCtrl',
            controller: ['data', 'groups', 'options', 'events', '$uibModalInstance', function ShowHistoryCtrl(data, groups, options, events, $uibModalInstance) {
                var vm = this;
                vm.quit = quit;
              
                vm.data = data;
                vm.options = options;
                vm.groups = groups;
                vm.events = events;
              
                $log.debug('shCtrl.data: ', vm.data);
              
                function quit() {
                  $uibModalInstance.close();
                }
                
              }], // showHistory modal controller
            resolve: {
              data: function () {
                return vm.data;
              },
              groups: function () {
                return vm.groups;
              },
              options: function () {
                return vm.options;
              },
              events: function () {
                return vm.events;
              }
            }
          }); // showHistory modal open
        }
        
        function hideHistory() {
          vm.showhist = false;
        }
  
      }]
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:patientCurrentDrugs
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-current-drugs></patient-current-drugs>
   </file>
   </example>
   *
   */
  angular
    .module('drug')
    .directive('patientCurrentDrugs', patientCurrentDrugs);

  function patientCurrentDrugs() {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'drug/patient-current-drugs-directive.tpl.html',
      replace: false,
      // controller: 'PatientDrugsCtrl',
      controllerAs: 'pcdCtrl',
      bindToController: true,
      controller: function CurrentDrugsController() {
        var vm = this;
        vm.deactivateDrug = deactivateDrug;
        vm.updateDrugColor = updateDrugColor;
        vm.datecheck = datecheck;


        function updateDrugColor(drug, color) {
          var index = vm.patient.drugs.indexOf(drug);
          console.log('drug: ', drug);
          if (index > -1) {
            vm.patient.drugs[index].color = color;
            calculateActivedrugs();
            vm.patient.$save();
          }
        }

        function datecheck(drug) {
          return !moment(drug.endDate).isBefore(moment());
        }

        function deactivateDrug(drug) {
          var index = vm.patient.drugs.indexOf(drug);
          if (index > -1) {
            console.log('Drug to be deactivated: ', vm.patient.drugs[index]);
            vm.patient.drugs[index].active = false;
            vm.patient.drugs[index].print = false;
            vm.patient.drugs[index].endDate = moment().toISOString();
          }
          calculateActivedrugs();
          vm.patient.$save();
        }

        // calculate activedrugs helper variable
        function calculateActivedrugs() {
          var i;
          vm.patient.activedrugs = 0;
          for (i = 0; i < vm.patient.drugs.length; i++) {
            if (vm.patient.drugs[i].active === true && vm.patient.drugs[i].foreign === false) {
              vm.patient.activedrugs += 1;
            }
          }
        }



      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name drug.directive:drughistory
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="drug">
       <file name="index.html">
        <drughistory></drughistory>
       </file>
     </example>
   *
   */
  angular
    .module('drug')
    .directive('drughistory', drughistory);

  function drughistory() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'drug/drughistory-directive.tpl.html',
      replace: false,
      controllerAs: 'drughistory',
      controller: function () {
        var vm = this;
        vm.name = 'drughistory';
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  angular
    .module('drug')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('drug', {
        url: '/drug',
        templateUrl: 'drug/drug.tpl.html',
        controller: 'DrugCtrl',
        controllerAs: 'drug'
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name drug.factory:Drug
   *
   * @description
   *
   */
  angular
    .module('drug')
    .factory('Drug', Drug)
    .factory('Drugsearch', Drugsearch);

  Drug.$inject = ['restmod'];

  function Drug(restmod) {
    return restmod.model('/routes/meds');
  }

  Drugsearch.$inject = ['restmod'];

  function Drugsearch(restmod) {
    return restmod.model('/routes/meds/medahead');
  }


}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name drug.controller:DrugCtrl
   *
   * @description
   *
   */
  angular
    .module('drug')
    .controller('DrugCtrl', DrugCtrl);

  DrugCtrl.$inject = ['Drug', 'Drugsearch', 'Config'];

  function DrugCtrl(Drug, Drugsearch, Config) {
    var vm = this;
    vm.ctrlName = 'DrugCtrl';
    vm.Drug = Drug;
    vm.Drugsearch = Drugsearch;

    vm.getDrugs = function (val) {
      console.log('loading drug names');

      return vm.Drugsearch.$search({query: val}).$asPromise().then(function (response) {
        console.log('drug search response: ', response);
        return response.map(function (drug) {
          drug.text = drug.ZBEZ;
          return drug;
        });
      });
    };
  }
  
  // Klammerfriedhof
}());

 (function () {
  'use strict';

  /* @ngdoc object
   * @name document
   * @description
   *
   */
  angular
    .module('document', [
      'ui.router',
      'smart-table'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('document')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('document', {
        url: '/document',
        templateUrl: 'document/document.tpl.html',
        controller: 'DocumentCtrl',
        controllerAs: 'document'
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name document.directive:documentList
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="document">
   <file name="index.html">
   <document-list patient=""></document-list>
   </file>
   </example>
   *
   */
  angular
    .module('document')
    .directive('documentList', documentList);

  documentList.$inject = ['Document'];

  function documentList(Document) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'document/document-list-directive.tpl.html',
      replace: false,
      controllerAs: 'docCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.ctrlName = 'DocumentCtrl';
        vm.patDocuments = Document.$search({patientid: vm.patient._id});
        // this necessery for filter/sort table directive!
        vm.pdocs = vm.patDocuments;
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */

      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name document.factory:Document
   *
   * @description
   *
   */
  angular
    .module('document')
    .factory('Document', Document);

  Document.$inject = ['restmod'];

  function Document(restmod) {
    return restmod.model('/routes/binarypdf').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true}
    });
  }


}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name consult
   * @description
   *
   */
  angular
    .module('consult', [
      'ui.router',
      'ui.bootstrap',
      'patient',
      'drug',
      'document',
      'examination',
      'examtemplate',
      'knalli.angular-vertxbus',
      'cfp.hotkeys',
      'helpers.doctor',
      'rlog'
    ]);

}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name consult.factory:OpenConsults
   *
   * @description
   *
   */
  angular
    .module('consult')
    .factory('OpenConsults', OpenConsults);

  OpenConsults.$inject = ['restmod', '$log', 'vertxEventBusService'];

  function OpenConsults(restmod, $log, vertxEventBusService) {
    var OpenConsultsBase = {};
    var model = restmod.model('/routes/patients');
    OpenConsultsBase.allOpenConsults = model.$search({inconsult: true});
    OpenConsultsBase.triggerUpdate = function () {
      vertxEventBusService.publish('oc.update', {});
      $log.debug('openConsult factory refresh triggered');
    };

    return OpenConsultsBase;
  }



}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name consult.directive:openConsults
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="consult">
   <file name="index.html">
   <open-consults></open-consults>
   </file>
   </example>
   *
   */
  angular
    .module('consult')
    .directive('openConsults', openConsults);

  openConsults.$inject = ['OpenConsults', 'vertxEventBusService', '$state'];

  function openConsults(OpenConsults, vertxEventBusService, $state) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'consult/open-consults-directive.tpl.html',
      replace: false,
      controllerAs: 'ocCtrl',
      bindToController: true,
      controller: function ocCtrl() {
        var vm = this;
        vm.allOpenConsults = OpenConsults.allOpenConsults;

        vm.switchToPatient = switchToPatient;
        function switchToPatient(p) {
          if (p.inconsult === true && p.incheckout === true) {
            $state.go('patientrecord.checkout.' + p.checkoutstate, {patientid: p._id});
          }
          else {
            $state.go('patientrecord.current.' + p.consultstate, {patientid: p._id});
          }
        }
      },

      link: function (scope, element, attrs, ctrl) {
        // jshint unused:false
        // eslint "no-unused-vars": [2, {"args": "none"}]
        
        vertxEventBusService.on('oc.update', function () {
          ctrl.allOpenConsults.$refresh();
          console.log('oc.update caught: ', ctrl.allOpenConsults);
        });

        vertxEventBusService.on('patients.update', function () {
          ctrl.allOpenConsults.$refresh();
          console.log('oc.update caught: ', ctrl.allOpenConsults);
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name consult.directive:imglabeler
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="consult">
       <file name="index.html">
        <imgviewer></imgviewer>
       </file>
     </example>
   *
   */
  angular
    .module('consult')
    .directive('imgviewer', imgviewer);

  imgviewer.$inject = ['$log'];

  function imgviewer($log) {
    return {
      restrict: 'EA',
      scope: {
        img: '=',
        dialog: '='
      },
      templateUrl: 'consult/imgviewer-directive.tpl.html',
      replace: false,
      controllerAs: 'ilCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        var backup = angular.copy(vm.img);
        if (!backup.hasOwnProperty('include')) {
          backup.include = false;
        }
        vm.name = 'imglabeler';
        vm.save = save;
        vm.cancel = cancel;


        function save() {
          $log.debug('img before: ', vm.img);
          vm.dialog.close();
        }

        function cancel() {
          $log.debug('img before: ', vm.img);
          vm.img.label = backup.label;
          vm.img.include = backup.include;
          $log.debug('img after: ', vm.img);
          vm.dialog.close();
        }
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name consult.directive:imglabeler
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="consult">
       <file name="index.html">
        <imglabeler></imglabeler>
       </file>
     </example>
   *
   */
  angular
    .module('consult')
    .directive('imglabeler', imglabeler);

  imglabeler.$inject = ['$log', '$rootScope'];

  function imglabeler($log, $rootScope) {
    return {
      restrict: 'EA',
      scope: {
        img: '=',
        dialog: '='
      },
      templateUrl: 'consult/imglabeler-directive.tpl.html',
      replace: false,
      controllerAs: 'ilCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        var backup = angular.copy(vm.img);
        if (!backup.hasOwnProperty('include')) {
          backup.include = false;
        }
        vm.name = 'imglabeler';
        vm.save = save;
        vm.cancel = cancel;

        $log.debug('imglabel directive controller function');

        function save() {
          $log.debug('img before: ', vm.img);
          $rootScope.$broadcast('imgeditclosed');
          vm.dialog.close();
        }

        function cancel() {
          $log.debug('img before: ', vm.img);
          vm.img.label = backup.label;
          vm.img.include = backup.include;
          $log.debug('img after: ', vm.img);
          vm.dialog.close();
        }
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name consult.directive:examtherapies
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="consult">
       <file name="index.html">
        <examtherapies></examtherapies>
       </file>
     </example>
   *
   */
  angular
    .module('consult')
    .directive('examtherapies', examtherapies);

  function examtherapies() {
    return {
      restrict: 'E',
      scope: {
        consult: '='
      },
      templateUrl: 'consult/examtherapies-directive.tpl.html',
      replace: false,
      controllerAs: 'etCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'examtherapies';

        vm.popover = 'examtherapies_popver.tpl.html';
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name consult.directive:examsummaries
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="consult">
       <file name="index.html">
        <examsummaries consult=""></examsummaries>
       </file>
     </example>
   *
   */
  angular
    .module('consult')
    .directive('examsummaries', examsummaries);

  function examsummaries() {
    return {
      restrict: 'EA',
      scope: {
        consult: '='
      },
      templateUrl: 'consult/examsummaries-directive.tpl.html',
      replace: false,
      controllerAs: 'esCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'examsummaries';

        vm.popover = 'examsummaries_popver.tpl.html';
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name consult.directive:examcomments
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="consult">
       <file name="index.html">
        <examcomments consult=""></examcomments>
       </file>
     </example>
   *
   */
  angular
    .module('consult')
    .directive('examcomments', examcomments);

  function examcomments() {
    return {
      restrict: 'EA',
      scope: {
        consult: '='
      },
      templateUrl: 'consult/examcomments-directive.tpl.html',
      replace: false,
      controllerAs: 'ecCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'examcomments';
        vm.popover = 'examcomments_popver.tpl.html';
        
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name consult.factory:DocumentHelper
   *
   * @description
   *
   */
  angular
    .module('consult')
    .factory('DocumentHelper', DocumentHelper);
  
  DocumentHelper.$inject = ['$log', '$http'];
  
  function DocumentHelper($log, $http) {
    var DocumentHelperBase = {};
    // patient.copenConsult.consultDocs = {};
    // patient.copenConsult.examDocs = [];
    
    
    
    DocumentHelperBase.printSome = function printSome(patient, consultDocs, examDocs) {
      var key;
      var i;

      if (!angular.isObject(patient.openConsult.consultDocs)) {
        patient.openConsult.consultDocs = consultDocs;
      }

      if (!angular.isArray(patient.openConsult.examDocs)) {
        patient.openConsult.consultDocs = examDocs;
      }


      for (key in patient.copenConsult.consultDocs) {
        if (patient.copenConsult.consultDocs.hasOwnProperty(key)) {
          if (patient.copenConsult.consultDocs[key]) {
            $http({
              url: '/routes/pdfprinter/consultprint',
              method: 'GET',
              params: {
                patientid: patient._id,
                consultid: patient.openConsult._id,
                doctype: key
              }
            });
          }
          else {
            $http({
              url: '/routes/pdfprinter/consult',
              method: 'GET',
              params: {
                patientid: patient._id,
                consultid: patient.openConsult._id,
                doctype: key
              }
            });
          }
        }
      }
    
      if (patient.openConsult.examinations.length) {
        for (i = 0; i < patient.copenConsult.examDocs.length; i++) {
          if (patient.copenConsult.examDocs[i]) {
            $http({
              url: '/routes/pdfprinter/examprint',
              method: 'GET',
              params: {
                patientid: patient._id,
                examid: patient.openConsult.examinations[i]._id,
                doctype: 'examfinding'
              }
            });
          }
          else {
            $http({
              url: '/routes/pdfprinter/exam',
              method: 'GET',
              params: {
                patientid: patient._id,
                examid: patient.openConsult.examinations[i]._id,
                doctype: 'examfinding'
              }
            });
          }
        }
      }
    };


    // saveAll()
    DocumentHelperBase.saveAll = function saveAll(patient, consultDocs, examDocs) {
      var key;
      var i;

      if (!angular.isObject(patient.openConsult.consultDocs)) {
        patient.openConsult.consultDocs = consultDocs;
      }

      if (!angular.isArray(patient.openConsult.examDocs)) {
        patient.openConsult.consultDocs = examDocs;
      }


      for (key in patient.copenConsult.consultDocs) {
        if (patient.copenConsult.consultDocs.hasOwnProperty(key)) {
      
          $http({
            url: '/routes/pdfprinter/consult',
            method: 'GET',
            params: {
              patientid: patient._id,
              consultid: patient.openConsult._id,
              doctype: key
            }
          });
      
        }
      }
  
      if (patient.openConsult.examinations.length) {
        for (i = 0; i < patient.copenConsult.examDocs.length; i++) {
          $http({
            url: '/routes/pdfprinter/exam',
            method: 'GET',
            params: {
              patientid: patient._id,
              examid: patient.openConsult.examinations[i]._id,
              doctype: 'examfinding'
            }
          });
      
        }
      }
    };

    // return Helper Object
    return DocumentHelperBase;
  }
}());

(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name consult.directive:openConsults
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="consult">
   <file name="index.html">
   <current-consult patient="patCtrl.patient"></current-consult>
   </file>
   </example>
   *
   */
  angular
    .module('consult')
    .directive('currentConsult', currentConsult);
  
  currentConsult.$inject = ['$log', 'vertxEventBusService', '$timeout', '$cookies', 'ConsultRefresh', '$state', '$window', '$interval'];
  
  function currentConsult($log, vertxEventBusService, $timeout, $cookies, ConsultRefresh, $state, $window, $interval) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'consult/current-consult-directive.tpl.html',
      replace: false,
      controller: 'ConsultCtrl',
      controllerAs: 'consCtrl',
      bindToController: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        
        ConsultRefresh.refreshAll(ctrl.patient);
        
        /*        var consultInterval;
         consultInterval = $interval(function () {
         $log.debug('Consult interval function called');
         if (angular.isObject(ctrl.patient) && angular.isObject(ctrl.patient.openConsult)) {
         // ConsultRefresh.refreshConsult(ctrl.patient);
         ctrl.patient.openConsult.$refresh();
         $interval.cancel(consultInterval);
         }
         }, 500);*/
        
        
        // watch for fileuploads and image captures
        scope.$watch('consCtrl.newfile', function () {
          ctrl.addFile();
        }, true);
        
        scope.$watch('consCtrl.newimage', function () {
          if (angular.isObject(ctrl.newimage)) {
            $log.debug('consCtrl.newimage watch triggered: ', ctrl.newimage);
            ctrl.addImage();
          }
          else {
            $log.debug('empty consCtrl.newimage');
          }
          // console.log('consCtrl.newimage watch triggered');
        }, true);
        
        
        // watch image label and include updates, debounce saving for 5 seconds
        scope.$watch('consCtrl.patient.openConsult.images', function (newval, oldval) {
          console.log('newval: ', newval);
          if (angular.isFunction(ctrl.imgtimeout)) {
            $log.debug('imgtimeout was allready running');
            $timeout.cancel(ctrl.imgtimeout);
          }
          ctrl.imgtimeout = $timeout(function () {
            $log.debug('setting imgtimeout to 5 sec');
            ctrl.patient.openConsult.$save();
          }, 5000);
        }, true);
        
        
        // moved to CurrentConsultContainer
        /* // watch patient new diagnoses
         scope.$watch('consCtrl.patient.diagnoses', function (newd, oldd) {
         if (newd.length > oldd.length) {
         ctrl.patient.openConsult.keywords.push(newd[newd.length - 1]);
         ctrl.patient.$save();
         ctrl.patient.openConsult.$save();
         }
         }, true);*/
        
        
        // watch for consultstatechanges
        // didnt wor as exptected
        /* scope.$watch('consCtrl.patient.inexam', function (newval, oldval) {
         $log.debug('inexam watch');
         $log.debug('oldval: ', oldval);
         $log.debug('newval: ', newval);
         if (newval !== oldval && newval === false) {
         ctrl.patient.openExam.$refresh();
         ctrl.patient.openConsult.$refresh().$asPromise().then(function (c) {
         ctrl.patient.openConsult.examinations.$refresh();
         });
         }
         });
         */
        // watch for consultstatechanges
        scope.$watch('consCtrl.patient.consultstate', function (newval, oldval) {
          $log.debug('consultstate watch');
          $log.debug('oldval: ', oldval);
          $log.debug('newval: ', newval);
          if (newval !== oldval && $state.current.name !== ('patientrecord.current.' + newval)) {
            switch (newval) {
              case 'exam':
                ctrl.patient.openExam.$refresh();
                break;
              case 'examfiles':
                ctrl.patient.openExam.$refresh();
                ctrl.patient.openConsult.$refresh().$asPromise().then(function (c) {
                  ctrl.patient.openConsult.examinations.$refresh();
                });
                break;
              case 'consult':
                ctrl.patient.openExam.$refresh();
                ctrl.patient.openConsult.$refresh().$asPromise().then(function (c) {
                  ctrl.patient.openConsult.examinations.$refresh();
                });
                break;
              default:
                ctrl.patient.$refresh();
                ctrl.patient.openExam.$refresh();
                ctrl.patient.openConsult.$refresh().$asPromise().then(function (c) {
                  ctrl.patient.openConsult.examinations.$refresh();
                });
                break;
            }
          }
        });
        
        // listen for openconsult updates
        vertxEventBusService.on('consults.update', function (message) {
          var messages = message.split(':');
          console.log('Consult updated: ', messages[0]);
          // if (messages[1] !== $cookies.get('vertx-web.session') && ctrl.patient.openConsult._id === messages[0]) {
          if (messages[1] !== $window.sessionStorage.getItem('sprtabid') && ctrl.patient.openConsult._id === messages[0]) {
            ctrl.patient.openConsult.$refresh().$asPromise().then(function () {
              ctrl.patient.openConsult.examinations.$refresh();
            });
            $log.debug(' (ccd) refreshed because tabid openconsult for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
          else {
            $log.debug(' (ccd) ignored openconsult change for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
            $log.debug(' (ccd) ignored because tabid openconsult change  for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
        });
        
        
        // listen for examination updates in openConsult.examinations array
        // this seems to be pure nonsense
        /*        vertxEventBusService.on('examinations.update', function (message) {
         var i;
         var messages = message.split(':');
         $log.debug('exam update mesg: ', message);
         $log.debug('cookie: ', $cookies.get('vertx-web.session'));
         $log.debug('openexam: ', ctrl.patient.openExam);
         $log.debug('openexamid: ', ctrl.patient.openExam._id);
         // the current consult container always has to react to every exam update pertaining to patient, even those caused by himself
         if (messages[1] !== $cookies.get('vertx-web.session')) {
         for (i = 0; i < ctrl.patient.openConsult.examinations.length; i++) {
         if (ctrl.patient.openConsult.examinations[i]._id === messages[0]) {
         ctrl.patient.openConsult.examinations[i].$refresh();
         $log.debug('(ccd) refreshed closed exam for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
         }
         }
         }
         else {
         $log.debug(' (ccd) ignored closed exam change for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
         }*/
  
        // listen for examination updates in openConsult.examinations array
        // only refresh examinations array if exam in question belongs to current consult, is closed and did not originate from this tab
        vertxEventBusService.on('examinations.update', function (message) {
  
          var i;
          var messages = message.split(':');
          // only react on foreign change!
          if (messages[1] !== $window.sessionStorage.getItem('sprtabid')) {
            $log.debug('exam update mesg: ', message);
            $log.debug('cookie: ', $cookies.get('vertx-web.session'));
            $log.debug('openexam _id, consultid, state: ', ctrl.patient.openExam._id, ctrl.patient.openExam.consultid, ctrl.patient.openExam.open, ctrl.patient.openExam);
            $log.debug('openconsultid: ', ctrl.patient.openConsult._id);
            // only changes of exams belonging to this consult
            // Oups, can't check for that! TODO: backend improve exam update message
            for (i = 0; i < ctrl.patient.openConsult.examinations.length; i++) {
              if (ctrl.patient.openConsult.examinations[i]._id === messages[0]) {
                ctrl.patient.openConsult.examinations[i].$refresh();
                $log.debug('(ccd) refreshed consult exams list for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
              }
            }
          }
          else {
            $log.debug(' (ccd) ignored closed exam change for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
          
          /* if (ctrl.patient.openExam._id === messages[0]) {
           ctrl.patient.openExam.$refresh().$asPromise().then(function () {
           if (ctrl.patient.openExam.open === false) {
           ctrl.patient.openConsult.$refresh().$asPromise().then(function () {
           ctrl.patient.openConsult.examinations.$refresh();
           $log.debug('refreshed examinations  on open exam close');
           });
           }
           });
           $log.debug('(ccd) refreshed openexam for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
           }
           else {
           $log.debug('(ccd) ignored openexam change for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
           } */
        });
        
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name consult.directive:currentConsultContainer
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="consult">
   <file name="index.html">
   <current-consult-container></current-consult-container>
   </file>
   </example>
   *
   */
  
  angular
    .module('consult')
    .directive('currentConsultContainer', currentConsultContainer);
  
  currentConsultContainer.$inject = ['ConsultRefresh', '$log'];
  
  function currentConsultContainer(ConsultRefresh, $log) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'consult/current-consult-container-directive.tpl.html',
      replace: false,
      controllerAs: 'consCtrl',
      controller: 'ConsultCtrl',
      bindToController: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */

        // this ist NOT healthy!!!!‚ refresh all must be called by each child state!
        // ConsultRefresh.refreshAll(ctrl.patient);

        // if (typeof ctrl.patient.diagnoses == "undefined" || ctrl.patient.diagnoses == null) {
        if (!angular.isObject(ctrl.patient.diagnoses)) {
          ctrl.patient.diagnoses = {};
        }

        /*
        // watch patient new diagnoses
        scope.$watch('consCtrl.patient.diagnoses', function (newd, oldd) {
          /!*
          // TODO: this does not work reliably, consult keywords are attached to wrong consult
          if (newd.length > oldd.length && ctrl.patient.inconsult) {
            ctrl.patient.openConsult.keywords.push(newd[newd.length - 1]);
            // ctrl.patient.openConsult.$save(); // not necessary, consult keywords watched autonomously
          }*!/
          // ctrl.patient.$save(); // do not save patient, patient diagnoses watched from patient-record-view
        }, true);*/
        
        // the third argument 'true' ensures deep checking of contents, works better than watchCollection
        scope.$watch('consCtrl.patient.openConsult.keywords', function () {
          if (angular.isObject(ctrl.patient) && angular.isObject(ctrl.patient.openConsult)) {
            ctrl.patient.openConsult.flatkeywords = [];
            ctrl.patient.openConsult.keywords.forEach(function (kw) {
              ctrl.patient.openConsult.flatkeywords.push(kw.key);
            });
            // $log.debug('new consult flatkeywords: ', ctrl.patient.openConsult.flatkeywords);
            // $log.debug('consult keywords on change: ', ctrl.patient.openConsult.keywords);
            ctrl.patient.openConsult.$save();
          }
          // ctrl.patient.$save(); // unnecessary at this point!!
        }, true);

        // the third argument 'true' ensures deep checking of contents, works better than watchCollection
        scope.$watch('consCtrl.patient.diagnoses', function (patdiagnoses) {
          if (angular.isObject(ctrl.patient) && angular.isArray(ctrl.patient.diagnoses)) {
            ctrl.patient.$save();
          }
        }, true);

      }
    };
  }
}());

/**
 * Created by lari on 03/09/15.
 */
(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name consult.factory:ConsultService
   *
   * @description
   *
   */
  angular
    .module('consult')
    .factory('Consult', Consult)
    .config(config);

  config.$inject = ['restmodProvider'];

  function config(restmodProvider) {
    restmodProvider.rebase({
      $config: {
        primaryKey: '_id'
      }
    });
  }

  Consult.$inject = ['restmod'];

  function Consult(restmod) {
    return restmod.model('/routes/consults').mix({
      patient: {belongsTo: 'Patient', key: 'patientid'},
      examinations: {hasMany: 'Examination'},
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true},
      open: {init: false},
      symptoms: {init: ''},
      notes: {init: ''},
      keywords: {init: []}
    });
  }

}());



(function () {
  'use strict';
  
  /**
   * @ngdoc service
   * @name consult.factory:ConsultRefresh
   *
   * @description
   *
   */
  angular
    .module('consult')
    .factory('ConsultRefresh', ConsultRefresh);
  
  
  ConsultRefresh.$inject = ['$log', 'Consult', 'Examination', '$state', '$interval'];
  
  function ConsultRefresh($log, Consult, Examination, $state, $interval) {
    var ConsultRefreshBase = {};
    
    ConsultRefreshBase.refreshAll = refreshAll;
    ConsultRefreshBase.refreshExam = refreshExam;
    ConsultRefreshBase.refreshConsult = refreshConsult;
    
    
    function refreshExam(patient) {
      $log.debug('refreshAll->refreshExam called');
      if (angular.isFunction(patient.openExam.$refresh)) {
        patient.openExam.$refresh();
      }
      else {
        if (patient.consultstate === 'exam' || patient.consultstate === 'examfiles') {
          $log.error('Patient consultstate exam or examfiles without openExam attached: ', patient.firstname, patient.lastname, patient._id);
        }
      }
    }
    
    function refreshConsult(patient) {
      $log.debug('refreshAll->refreshConsult called');
      if (angular.isFunction(patient.openConsult.$refresh)) {
        patient.openConsult.$refresh().$asPromise().then(function (oc) {
          if (patient.openConsult.open === true) {
            if (angular.isArray(patient.openConsult.examinations) && angular.isFunction(oc.examinations.$refresh)) {
              $log.debug('refreshing examinations ');
              patient.openConsult.examinations.$refresh().$asPromise().then(function (e) {
                var i;
                for (i = 0; i < e.length; i++) {
                  $log.debug('refreshing examinations.i: ', i, e[i].type);
                  e[i].$refresh();
                }
              });
            }
          }
          else {
            $log.error('corresponding patient consult was already closed: ', patient.firstname, patient.lastname, patient._id);
            patient.inconsult = false;
            patient.incheckout = false;
            patient.consultstate = '';
            patient.checkoutstate = '';
            patient.openExam = null;
            patient.openConsult = null;
            delete patient.openConsult;
            delete patient.openExam;
            patient.$save().$asPromise().then(function (pat) {
              $log.debug(pat);
              $state.go('patientrecord.history', {patient: patient, patientid: patient._id});
            });
          }
        });
      }
      else {
        // look for open consults for this patient and attach to patient if exactly one is found
        Consult.$search({patientid: patient._id, open: true}).$asPromise().then(function (c) {
          if (angular.isArray(c) && c.length === 1 && angular.isFunction(c.$refresh)) {
            c.$refresh().$asPromise().then(function () {
              patient.openConsult = c;
              patient.$save();
            });
            
          }
          else {
            // TODO mark alle open consults as closed, as error, log error and send notification.
            $log.error('Patient inconsult without responding consult: ', patient.firstname, patient.lastname, patient._id);
            patient.inconsult = false;
            patient.incheckout = false;
            patient.consultstate = '';
            patient.checkoutstate = '';
            patient.openExam = null;
            patient.openConsult = null;
            delete patient.openConsult;
            delete patient.openExam;
            patient.$save().$asPromise().then(function (pat) {
              $log.debug(pat);
              $state.go('patientrecord.history', {patient: patient, patientid: patient._id});
            });
          }
        });
        
      }
    }
    
    function refreshAll(patient) {
      $log.debug('refreshAll called: ', patient);
      if (patient.inconsult && patient.inconsult === true) {
        $log.debug('refreshhAll: consult');
        refreshConsult(patient);
      }
      if (patient.inexam && patient.inexam === true) {
        $log.debug('refreshAll: exam');
        refreshExam(patient);
      }
    }
    
    /*
     // this is total madness, quargel!!!
     function refreshAll(patient) {
     // var success = true;
     if (angular.isFunction(patient.openExam.$refresh)) {
     patient.openExam.$refresh().$asPromise().then(function () {
     refreshConsult(patient);
     });
     }
     else {
     if (patient.consultstate === 'exam' || patient.consultstate === 'examfiles') {
     $log.error('Patient consultstate exam or examfiles without openExam attached: ', patient.firstname, patient.lastname, patient._id);
     }
     }
     }
     */
    
    return ConsultRefreshBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name consult.factory:ConsultModalsHelper
   *
   * @description
   *
   */
  angular
    .module('consult')
    .factory('ConsultModalsHelper', ConsultModalsHelper);
  
  ConsultModalsHelper.$inject = ['$uibModal', '$log'];
  
  
  function ConsultModalsHelper($uibModal, $log) {
    var ConsultModalsHelperBase = {};

    ConsultModalsHelperBase.addAideModal = addAideModal;
    ConsultModalsHelperBase.addReferralModal = addReferralModal;


    function addAideModal(patient) {
      $uibModal.open({
        template: '<div class="modalspacer"><checkout-aides patient="miCtrl.patient" dialog="miCtrl.$modalInstance"></checkout-aides></div>',
        size: 'modal-sm',
        controller: ['$uibModalInstance', 'patient', function ($uibModalInstance, lpatient) {
          var vmi = this;
          vmi.patient = lpatient;
          vmi.$modalInstance = $uibModalInstance;

          vmi.cancel = function () {
            vmi.$modalInstance.close();
          };

          vmi.ok = function () {
            vmi.patient.$save();
            vmi.$modalInstance.close();
          };
        }],
        controllerAs: 'miCtrl',
        resolve: {
          patient: function () {
            return patient;
          }
        }
      });
    }

    function addReferralModal(patient) {
      $uibModal.open({
        template: '<div class="modalspacer"><checkout-referrals patient="miCtrl.patient" dialog="miCtrl.$modalInstance"></checkout-referrals></div>',
        size: 'modal-sm',
        controller: ['$uibModalInstance', 'patient', function ($uibModalInstance, lpatient) {
          var vmi = this;
          vmi.patient = lpatient;
          vmi.$modalInstance = $uibModalInstance;

          vmi.cancel = function () {
            vmi.$modalInstance.close();
          };

          vmi.ok = function () {
            vmi.patient.$save();
            vmi.$modalInstance.close();
          };
        }],
        controllerAs: 'miCtrl',
        resolve: {
          patient: function () {
            return patient;
          }
        }
      });
    }

    return ConsultModalsHelperBase;
  }
}());

/**
 * Created by lari on 07/01/2017.
 */
(function () {
  'use strict';
  
  /**
   * @ngdoc component
   * @name consult.component:consultList
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="consult">
   <file name="index.html">
   <consult-list></consult-list>
   </file>
   </example>
   *
   */
  angular
    .module('consult')
    .component('consultList', {
      bindings: {
        patient: '<'
      },
      templateUrl: 'consult/consult-list-directive.tpl.html',
      controllerAs: 'consCtrl',
      controller: ['Consult', 'OpenConsults', 'Patient', 'Service', '$stateParams', '$state', '$log', 'Config',
        '$scope', '$window', 'hotkeys', '$uibModal', 'Document', 'File', 'ConsultModalsHelper', '$timeout', 'Wlcommons', 'Waitinglist',
        function (Consult, OpenConsults, Patient, Service, $stateParams, $state, $log, Config,
                  $scope, $window, hotkeys, $uibModal, Document, File, ConsultModalsHelper, $timeout, Wlcommons, Waitinglist) {
          var vm = this;
          vm.startConsult = startConsult;
          vm.switchToOpenExam = switchToOpenExam;
          vm.switchToExit = switchToExit;
          vm.getDocuments = getDocuments;
          vm.getExams = getExams;

          vm.$onChanges = function (changesObj) {
            // $log.debug('Consult List component $onChanges called: ', changesObj.patient.currentValue);
            if (vm.patient) {
              if (angular.isObject(vm.patient) && angular.isArray(vm.patient.consults)) {
                // $log.debug('patconsult refresh called');
                vm.patient.consults.$refresh();
              }
              else {
                // $log.debug('patconsult refresh NOT called: ', angular.isObject(vm.patient));
              }
            }
          };
          
          function startConsult() {
            vm.patient.consults.$create().$asPromise().then(function (oc) {
              vm.patient.consultstate = 'consult';
              vm.patient.inconsult = true;
              vm.patient.incheckout = false;
              calculateActivedrugs(); // recalculate drug counter (might not be set on older patientrecords)
              vm.patient.prescriptiondate = moment().locale('de-at').format('ll'); // set formatted prescription dat for prescription, referrals and aides
              oc.examinations.$fetch();
              oc.open = true;
              oc.fdate = moment().locale('de-at').format('ll');
              vm.patient.openConsult = oc;
              vm.patient.openConsult.$save();
              $log.debug('openConsult: ', vm.patient.openConsult);
              
              vm.patient.$save().$asPromise().then(function () {
                $log.debug('consult started, should trigger update');
                OpenConsults.triggerUpdate();
                $log.debug('oc started, patient saved');
              });
              // TODO: nasty snow flake bug - figure out why above code won't work for rudi an needs below snowflake patch
              $timeout(OpenConsults.triggerUpdate(), 5000);
              $state.go('patientrecord.current.consult');
            });
          }
          
          function switchToOpenExam() {
            vm.swtespin = true;
            vm.patient.openExam.$refresh().$asPromise().then(function (e) {
              vm.swtespin = false;
              switch (vm.patient.consultstate) {
                case 'exam':
                  $state.go('patientrecord.current.exam');
                  break;
                case 'examfiles':
                  $state.go('patientrecord.current.examfiles');
                  break;
                default:
                  vm.swtespin = true;
                  vm.swteerror = true;
                  vm.patient.$refresh().$asPromise().then(function () {
                    vm.patient.openExam.$refresh().$asPromise().then(function () {
                      vm.patient.openConsult.$refresh().$asPromise().then(function () {
                        vm.patientid.openConsult.examinations.$refresh();
                        vm.swtespin = false;
                        vm.swteerror = false;
                      });
                    });
                  });
                  
                  break;
              }
            }); // switch
          }
          
          function calculateActivedrugs() {
            var i;
            vm.patient.activedrugs = 0;
            if (vm.patient.drugs.length && vm.patient.drugs.length > 0) {
              for (i = 0; i < vm.patient.drugs.length; i++) {
                if (vm.patient.drugs[i].active === true && vm.patient.drugs[i].foreign === false && vm.patient.drugs[i].print === true) {
                  vm.patient.activedrugs += 1;
                }
              }
            }
          }
          
          function switchToExit() {
            $state.go('patientrecord.checkout.' + vm.patient.checkoutstate);
          }
  
          function getDocuments(cons) {
            cons.docs = Document.$search({consultid: cons._id});
          }
  
          function getExams(cons) {
            cons.examinations.$fetch();
            cons.exams = 1;
          }
        }
      ]
    });
  
  // consultList.$inject = [];
  /*  consultList.$inject = ['Consult', 'OpenConsults', 'Patient', 'Service', '$stateParams', '$state', '$log', 'Config',
   '$scope', '$window', 'hotkeys', '$uibModal', 'Document', 'File', 'ConsultModalsHelper', '$timeout', 'Wlcommons', 'Waitinglist'];*/


// Klammerfriedhof
}());

(function () {
  'use strict';
  /**
   * @ngdoc object
   * @name consult.controller:ConsultCtrl
   *
   * @description
   *
   */
  angular
    .module('consult')
    .controller('ConsultCtrl', ConsultCtrl);
  
  // check/edit this
  
  ConsultCtrl.$inject = ['Consult', 'OpenConsults', 'Patient', 'Service', '$stateParams', '$state', '$log', 'Config', 'Rlog',
    '$scope', '$window', 'hotkeys', '$uibModal', 'Document', 'File', 'ConsultModalsHelper', '$timeout', 'Wlcommons', 'Waitinglist'];
  
  function ConsultCtrl(Consult, OpenConsults, Patient, Service, $stateParams, $state, $log, Config, Rlog,
                       $scope, $window, hotkeys, $uibModal, Document, File, ConsultModalsHelper, $timeout, Wlcommons, Waitinglist) {
    var vm = this;
    vm.ctrlName = 'ConsultCtrl';
    vm.Consult = Consult;
    vm.Service = Service;
    vm.conf = Config.$find('configuration');
    vm.patientid = $stateParams.patientid;
    vm.$state = $state;
    
    vm.patsave = patsave;
    
    vm.wlAddPatient = Wlcommons.addPatient;
    vm.wlRemovePatient = Wlcommons.removePatient;
    vm.startConsult = startConsult;
    
    
    vm.waitinglists = Waitinglist.$search();
    
    // vm.startCheckout = startCheckout;
    vm.getDocuments = getDocuments;
    vm.getExams = getExams;
    vm.addFile = addFile;
    vm.addImage = addImage;
    vm.editImage = editImage;
    vm.showImage = showImage;
    vm.addAideModal = ConsultModalsHelper.addAideModal;
    vm.addReferralModal = ConsultModalsHelper.addReferralModal;
    vm.switchToOpenExam = switchToOpenExam;
    // vm.switchToRecord = switchToRecord;
    vm.switchToExit = switchToExit;
  
    
    
    function patsave() {
      $log.debug('patsave called');
      // vm.patient.openConsult.$saveNow();
      if (vm.patient.openConsult.$hasPendingActions()) {
        $log.debug('openConsult has pending actions!');
        Rlog.debug('consult', 'consult-controller', 'openConsult has pending actions!',
          {openConsultid: vm.patient.openConsult._id, patientid: vm.patient._id});
        vm.patient.openConsult.$cancel();
        patsave();
      }
      else {
        vm.patient.openConsult.$save();
      }
      
    }
    
    function startConsult() {
      vm.patient.consults.$create().$asPromise().then(function (oc) {
        vm.patient.consultstate = 'consult';
        vm.patient.inconsult = true;
        vm.patient.incheckout = false;
        calculateActivedrugs(); // recalculate drug counter (might not be set on older patientrecords)
        vm.patient.prescriptiondate = moment().locale('de-at').format('ll'); // set formatted prescription dat for prescription, referrals and aides
        oc.examinations.$fetch();
        oc.open = true;
        oc.fdate = moment().locale('de-at').format('ll');
        vm.patient.openConsult = oc;
        vm.patient.openConsult.$save();
        $log.debug('openConsult: ', vm.patient.openConsult);
        
        vm.patient.$save().$asPromise().then(function () {
          $log.debug('consult started, should trigger update');
          OpenConsults.triggerUpdate();
          $log.debug('oc started, patient saved');
        });
        // TODO: nasty snow flake bug - figure out why above code won't work for rudi an needs below snowflake patch
        $timeout(OpenConsults.triggerUpdate(), 5000);
        $state.go('patientrecord.current.consult');
      });
    }

    function switchToOpenExam() {
      vm.swtespin = true;
      vm.patient.openExam.$refresh().$asPromise().then(function (e) {
        vm.swtespin = false;
        switch (vm.patient.consultstate) {
          case 'exam':
            $state.go('patientrecord.current.exam');
            break;
          case 'examfiles':
            $state.go('patientrecord.current.examfiles');
            break;
          default:
            vm.swtespin = true;
            vm.swteerror = true;
            vm.patient.$refresh().$asPromise().then(function () {
              vm.patient.openExam.$refresh().$asPromise().then(function () {
                vm.patient.openConsult.$refresh().$asPromise().then(function () {
                  vm.patientid.openConsult.examinations.$refresh();
                  vm.swtespin = false;
                  vm.swteerror = false;
                });
              });
            });
            
            break;
        }
      }); // switch
    }
    
    function calculateActivedrugs() {
      var i;
      vm.patient.activedrugs = 0;
      if (vm.patient.drugs.length && vm.patient.drugs.length > 0) {
        for (i = 0; i < vm.patient.drugs.length; i++) {
          if (vm.patient.drugs[i].active === true && vm.patient.drugs[i].foreign === false && vm.patient.drugs[i].print === true) {
            vm.patient.activedrugs += 1;
          }
        }
      }
    }

    function switchToExit() {
      $state.go('patientrecord.checkout.' + vm.patient.checkoutstate);
    }
    
    function getDocuments(cons) {
      cons.docs = Document.$search({consultid: cons._id});
    }
    
    function getExams(cons) {
      cons.examinations.$fetch();
      cons.exams = 1;
    }
    
    function addFile() {
      if (vm.newfile) {
        File.$find(vm.newfile.fileid).$asPromise().then(function (file) {
          file.patientid = vm.patient._id;
          file.consultid = vm.patient.openConsult._id;
          file.$save();
          if (!angular.isArray(vm.patient.openConsult.files)) {
            vm.patient.openConsult.files = [];
          }
          vm.patient.openConsult.files.push(file);
          vm.patient.openConsult.$save();
        });
        vm.newfile = null;
      }
    }
    
    function addImage() {
      $log.debug('addImage called');
      $log.debug('vm.newimage: ', vm.newimage);
      $log.debug('vm.newimage.fileid', vm.newimage.fileid);
      if (vm.newimage && vm.newimage.fileid) {
        File.$find(vm.newimage.fileid).$asPromise().then(function (image) {
          $log.debug('image returned: ', image);
          image.patientid = vm.patient._id;
          image.consultid = vm.patient.openConsult._id;
          image.$save();
          if (!angular.isArray(vm.patient.openConsult.images)) {
            vm.patient.openConsult.images = [];
          }
          vm.patient.openConsult.images.push(image);
          vm.patient.openConsult.$save();
        });
        vm.newimage = null;
      }
    }
    
    
    function editImage(img) {
      $uibModal.open({
        template: '<imglabeler dialog="miCtrl.dialog" img="miCtrl.img"/>',
        size: 'lg',
        controller: ['$uibModalInstance', 'image', function ($uibModalInstance, image) {
          var vmi = this;
          vmi.dialog = $uibModalInstance;
          vmi.img = image;
        }],
        controllerAs: 'miCtrl',
        resolve: {
          image: function () {
            return img;
          }
        }
      });
    }
    
    function showImage(img) {
      $uibModal.open({
        template: '<imgviewer dialog="miCtrl.dialog" img="miCtrl.img"/>',
        size: 'lg',
        controller: ['$uibModalInstance', 'image', function ($uibModalInstance, image) {
          var vmi = this;
          vmi.dialog = $uibModalInstance;
          vmi.img = image;
        }],
        controllerAs: 'miCtrl',
        resolve: {
          image: function () {
            return img;
          }
        }
      });
    }
    
    // when you bind it to the controller's scope, it will automatically unbind
    // the hotkey when the scope is destroyed (due to ng-if or something that changes the DOM)
    hotkeys.bindTo($scope)
      .add({
        combo: 'a',
        description: 'test alert',
        callback: function () {
          $window.alert('a pressed');
        }
      })
      .add({
        combo: 'alt+v',
        description: 'Hilfsmittel Verordnung',
        callback: function () {
          ConsultModalsHelper.addAideModal(vm.patient);
        }
      })
      .add({
        combo: 'alt+ü',
        description: 'Überweisung',
        callback: function () {
          ConsultModalsHelper.addReferralModal(vm.patient);
        }
      })
      .add({
        combo: 'backspace',
        description: 'Unterdrückt: Verhindert Rückwärtsspringen im Browser',
        callback: function (event, hotkey) {
          // do exactly nothing :-)
          // $window.alert('backspace pressed');
          event.preventDefault();
        }
      });
    
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:checkoutReferral
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <checkout-referrals></checkout-referrals>
   </file>
   </example>
   *
   */
  angular
    .module('consult')
    .directive('checkoutReferrals', checkoutReferrals);

  checkoutReferrals.$inject = ['$log'];

  function checkoutReferrals($log) {
    return {
      restrict: 'EA',
      scope: {
        patient: '=',
        dialog: '=?'
      },
      templateUrl: 'consult/checkout-referrals-directive.tpl.html',
      replace: false,
      controllerAs: 'refCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        
        vm.removeRef = removeRef;
        vm.clearRefDoctor = clearRefDoctor;
        vm.addAnotherReferral = addAnotherReferral;
        vm.setlab = setlab;

        initNewRef();
        
        function initNewRef() {
          vm.newreferral = {};
          vm.newreferral.refdate = '';
          vm.newreferral.pdate = false;
          vm.newreferral.ginclude = [];
          vm.newreferral.additionalvalues = [];
        }
        
        function setlab() {
          vm.newreferral.to = 'Labor';
        }

        function removeRef(i) {
          vm.patient.openConsult.referrals.splice(i, 1);
          vm.patient.openConsult.$save();
        }
  
        function clearRefDoctor() {
          delete vm.newreferral.doctor;
        }
        
        
        function addAnotherReferral() {
          if (vm.newreferral.pdate === true) {
            vm.newreferral.refdate = moment().locale('de-at').format('ll');
          }
          else {
            vm.newreferral.refdate = '';
          }
          
          // create referrals array if not present
          if (!angular.isArray(vm.patient.openConsult.referrals)) {
            vm.patient.openConsult.referrals = [];
          }
          
          vm.patient.openConsult.referrals.push(vm.newreferral);
          vm.patient.openConsult.$save();

          initNewRef();
        }


      }
    };
  }
}());

(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name patient.directive:checkoutPayment
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <checkout-payment></checkout-payment>
   </file>
   </example>
   *
   */
  angular
    .module('consult')
    .directive('checkoutPayment', checkoutPayment);
  
  checkoutPayment.$inject = ['Consult', 'OpenConsults', 'Cashinvoice', 'Checkinvoice', '$log', '$state',
    '$timeout', 'ConsultRefresh', 'vertxEventBusService', '$cookies', '$window'];
  
  function checkoutPayment(Consult, OpenConsults, Cashinvoice, Checkinvoice, $log, $state,
                           $timeout, ConsultRefresh, vertxEventBusService, $cookies, $window) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'consult/checkout-payment-directive.tpl.html',
      replace: false,
      controllerAs: 'payCtrl',
      bindToController: true,
      controller: ["$scope", function ($scope) {
        var i;
        var vm = this;
        vm.Consult = Consult;
        vm.$state = $state;
        vm.saveInvoice = saveInvoice;
        vm.retryPrinting = retryPrinting;
        vm.redoBilling = redoBilling; // storno invoice and switch to redobilling state
        vm.backToBilling = backToBilling; // if no service items for invoice have been found
        vm.retrieveInvoice = retrieveInvoice;
        vm.retrieveAndFinish = retrieveAndFinish;
        vm.finishConsult = finishConsult;
        vm.reallyFinish = reallyFinish;
        vm.nonzeroitems = nonzeroitems;
        $log.debug('controller function');
  
  
        function nonzeroitems() {
          var i;
          var n = 0;
          if (angular.isObject(vm.patient.openConsult.service) && angular.isArray(vm.patient.openConsult.service.items)) {
            for (i = 0; i < vm.patient.openConsult.service.items.length; i++) {
              if (vm.patient.openConsult.service.items[i].selected === true) {
                n++;
              }
            }
          }
          // $log.debug('Nr of invoice items: ', n);
          return n;
        }
        
        // saveInvoice
        function saveInvoice() {
          var invoice = {};
          var invoiceobj = {};
       
          
          invoice.date = moment().toISOString();
          invoice.fdate = moment(invoice.date).locale('de-at').format('ll');
          invoice.ftime = moment(invoice.date).locale('de-at').format('LTS');
          
          invoice.storno = false;
          invoice.test = false;
          
          if (vm.patient.test === true) {
            invoice.test = true;
          }
          
          // invoice.items = vm.patient.openConsult.service.items;
          // get rid of unselected excess items that clutter up the database
          invoice.items = [];
          for (i = 0; i < vm.patient.openConsult.service.items.length; i++) {
            // $log.debug('item: ', vm.patient.openConsult.service.items[i]);
            if (vm.patient.openConsult.service.items[i].selected === true) {
              $log.debug('selected item: ', vm.patient.openConsult.service.items[i]);
              invoice.items.push(vm.patient.openConsult.service.items[i]);
            }
          }
          
          invoice.netto = vm.patient.openConsult.service.netto;
          invoice.totalbefore = vm.patient.openConsult.service.totalbefore;
          invoice.nettodiscounted = vm.patient.openConsult.service.nettodiscounted;
          invoice.bruttodiscounted = vm.patient.openConsult.service.bruttodiscounted;
          invoice.taxdiscounted = vm.patient.openConsult.service.taxdiscounted;
          invoice.discount = vm.patient.openConsult.service.discount;
          invoice.tax = vm.patient.openConsult.service.tax;
          // discounttaxsum is actually an array with the accumulated tax per tax bracket
          // discounttaxsum kept for backward compatibility, new type is discounttaxarray
          invoice.discounttaxsum = vm.patient.openConsult.service.discounttaxsum;
          invoice.discounttaxarray = vm.patient.openConsult.service.discounttaxsum;
          invoice.discountnettoarray = vm.patient.openConsult.service.discountnettoarray;
          invoice.discountbruttoarray = vm.patient.openConsult.service.discountbruttoarray;
          invoice.total = vm.patient.openConsult.service.total;
          invoice.invoicediagnoses = vm.patient.openConsult.invoicediagnoses;
          // invoice.consultid = vm.patient.openConsult._id;
          
          invoice.paytype = vm.patient.openConsult.service.paytype;
          
          invoiceobj.patient = clean(vm.patient);
          invoiceobj.patient.openConsult = clean(vm.patient.openConsult);
          invoiceobj.patientid = vm.patient._id;
          invoiceobj.consultid = vm.patient.openConsult._id;
          invoiceobj.rootelement = 'patient';
          
          if (vm.patient.openConsult.service.paytype === 'cash' || vm.patient.openConsult.service.paytype === 'card') {
            invoice.paid = true;
            invoice.type = 'Barrechnung';
            invoice.cash = true;
            invoice.paiddate = invoice.date;
            invoiceobj.patient.openConsult.invoice = invoice;
            Cashinvoice.$create(invoiceobj).$asPromise().then(function (inv) {
              $log.debug('Cashinvoice generated: ', inv);
              vm.patient.openConsult.cashinvoiceid = inv._id;
              saveInvoiceState(inv);
            });
          }
          
          if (vm.patient.openConsult.service.paytype === 'bill') {
            invoice.paydue = moment(invoice.date).add(14, 'days');
            invoice.fpaydue = moment(invoice.date).locale('de-at').add(14, 'days').format('ll');
            invoice.paid = false;
            invoice.cash = false;
            invoice.type = 'Zielrechnung';
            invoiceobj.patient.openConsult.invoice = invoice;
            Checkinvoice.$create(invoiceobj).$asPromise().then(function (inv) {
              $log.debug('Checkinvoice generated: ', inv);
              vm.patient.openConsult.checkinvoiceid = inv._id;
              saveInvoiceState(inv);
            });
          }
          
        } // saveInvoice()
        
        
        function retrieveInvoice() {
          retrieve(function () {
            vm.showpdfs = true;
            $log.debug('I am the the show pdf callback');
          });
        }
        
        
        function retrieveAndFinish() {
          retrieve(finishConsult);
        }
        
        
        function retrieve(callback) {
          vm.invoicecreated = true;
          $log.debug('I am the retrieve function');
          $log.debug('chashinvoiceid: ', angular.isString(vm.patient.openConsult.cashinvoiceid));
          if (angular.isString(vm.patient.openConsult.cashinvoiceid)) {
            Cashinvoice.$find(vm.patient.openConsult.cashinvoiceid).$asPromise().then(function (inv) {
              vm.createdinvoice = inv;
              $log.debug('the callback: ', callback);
              $log.debug('the invoice: ', inv);
              callback();
            });
          }
          if (angular.isString(vm.patient.openConsult.checkinvoiceid)) {
            Checkinvoice.$find(vm.patient.openConsult.checkinvoiceid).$asPromise().then(function (inv) {
              vm.createdinvoice = inv;
              callback();
            });
          }
        }
        
        function saveInvoiceState(inv) {
          vm.createdinvoice = inv;
          vm.invoicecreated = true;
          vm.patient.openConsult.invoicecreated = true;
          // $log.debug('oC before statesave: ', vm.patient.openConsult);
          vm.patient.openConsult.$save();
          vm.justcreated = true;
        }
        
        
        function retryPrinting() {
          vm.showpdfs = true;
        }
        
        // storno invoice and return to billing page
        function redoBilling() {
          $log.debug('payCtrl redoBilling entered');
          vm.createdinvoice.storno = true;
          vm.createdinvoice.stornoreason = 'Storno, inhaltlicher Fehler';
          vm.createdinvoice.$save();
          vm.createdinvoice.$destroy();
          vm.invoicecreated = false;
          vm.patient.openConsult.invoicecreated = false;
          if (vm.patient.openConsult.cashinvoiceid) {
            delete vm.patient.openConsult.cashinvoiceid;
          }
          if (vm.patient.openConsult.checkinvoiceid) {
            delete vm.patient.openConsult.checkinvoiceid;
          }
          vm.patient.openConsult.$save();
          vm.patient.checkoutstate = 'redobilling';
          vm.patient.$save();
          $state.go('patientrecord.checkout.redobilling');
        }
        
        // if no service items for invoice have been found
        // switch to redobilling anyway
        function backToBilling() {
          vm.patient.checkoutstate = 'redobilling';
          vm.patient.$save();
          $state.go('patientrecord.checkout.redobilling');
        }
        
        function reallyFinish() {
          
          // flatten exam labels for search
          var examlabels = [];
          var examlabelsPrimary = [];
          var examlabelsSecondary = [];
          var pexams = [];
          var sexams = [];
          var t = 0;
          var s = 0;
          var i = 0;
          
          $log.debug('payCtrl reallyFinish() entered');
          
          if (angular.isArray(vm.patient.openConsult.examinations) && vm.patient.openConsult.examinations.length > 0) {
            for (i = 0; i < vm.patient.openConsult.examinations.length; i++) {
              if (vm.patient.openConsult.examinations[i].active === true) {
                if (vm.patient.openConsult.examinations[i].template.includefindings === false) {
                  t = pexams.length;
                  pexams[t] = {};
                  pexams[t].type = vm.patient.openConsult.examinations[i].type;
                  pexams[t].examid = vm.patient.openConsult.examinations[i]._id;
                  if (angular.isString(vm.patient.openConsult.examinations[i].docid)) {
                    pexams[t].docid = vm.patient.openConsult.examinations[i].docid;
                  }
                  examlabelsPrimary.push(pexams[t]);
                }
                else {
                  s = sexams.length;
                  sexams[s] = {};
                  sexams[s].type = vm.patient.openConsult.examinations[i].type;
                  sexams[s].examid = vm.patient.openConsult.examinations[i]._id;
                  examlabelsSecondary.push(sexams[s]);
                }
                // always push to examlabels for search by exam type
                examlabels.push(vm.patient.openConsult.examinations[i].type);
                
              }
            }
            vm.patient.openConsult.examlabels = examlabels;
            vm.patient.openConsult.examlabelsPrimary = examlabelsPrimary;
            vm.patient.openConsult.examlabelsSecondary = examlabelsSecondary;
            // $log.debug('All Examlabels: ', vm.patient.openConsult.examlabels);
            // $log.debug('Primary: ', vm.patient.openConsult.examlabelsPrimary);
            // $log.debug('Secondary: ', vm.patient.openConsult.examlabelsSecondary);
          }
          
          
          // get rid of state variables
          vm.patient.openConsult.open = false;
          vm.patient.openConsult.service = null;
          vm.patient.openConsult.$save();
          
          vm.patient.consultstate = '';
          vm.patient.checkoutstate = '';
          vm.patient.inconsult = false;
          vm.patient.incheckout = false;
          if (vm.patient.inexam === true) {
            vm.patient.inexam = false;
          }
          vm.patient.$save().$asPromise().then(function () {
            OpenConsults.triggerUpdate();
            $log.debug('oc close, patient saved');
          });
          // TODO: nasty snow flake bug - figure pout why above code won't wok for rudi an needs below snowflake patch
          $timeout(OpenConsults.triggerUpdate(), 5000);
          $state.go('patients');
        }
        
        
        // after all checkout steps have run, finish consult
        // switch to patientslist
        function finishConsult() {
          $log.debug('payCtrl finishConsult() entered');
          $log.debug('created invoice in finish: ', vm.createdinvoice);
          if (angular.isObject(vm.createdinvoice)) {
            vm.createdinvoice.finished = true;
            vm.createdinvoice.$save();
          }
          vm.reallyFinish();
        } // finishconsult
        
        
        function clean(dirty) {
          var cleaned = {};
          var i;
          var reg = /^\$/;
          
          if (angular.isObject(dirty)) {
            for (i in dirty) {
              if (dirty.hasOwnProperty(i)) {
                // $log.debug('dirty i: ', i, dirty[i]);
                switch (i) {
                  case 'consults':
                    break;
                  case 'consult':
                    break;
                  case 'patient':
                    break;
                  case 'examinations':
                    break;
                  case 'openExam':
                    break;
                  case 'openConsult':
                    break;
                  default:
                    if (!reg.test(i)) {
                      cleaned[i] = dirty[i];
                    }
                }
              }
            }
          }
          return cleaned;
        }
      }], // controller
      link: function (scope, element, attrs, ctrl) {
        
        ConsultRefresh.refreshAll(ctrl.patient);
        
        /* ctrl.patient.openExam.$refresh();
         ctrl.patient.openConsult.$refresh().$asPromise().then(function (oc) {
         oc.examinations.$refresh();
         });
         */
        
        // listen for openconsult updates
        vertxEventBusService.on('consults.update', function (message) {
          var messages = message.split(':');
          console.log('Consult updated: ', messages[0]);
          // if (messages[1] !== $cookies.get('vertx-web.session') && ctrl.patient.openConsult._id === messages[0]) {
          if (messages[1] !== $window.sessionStorage.getItem('sprtabid') && ctrl.patient.openConsult._id === messages[0]) {
            ctrl.patient.openConsult.$refresh().$asPromise().then(function () {
              ctrl.patient.openConsult.examinations.$refresh();
            });
            $log.debug(' (cbd) refreshed openconsult for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
          else {
            $log.debug(' (cbd) ignored openconsult change for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
        });
        
      }
      
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:checkoutDrugs
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <checkout-drugs></checkout-drugs>
   </file>
   </example>
   *
   */
  angular
    .module('patient')
    .directive('checkoutDrugs', checkoutDrugs);

  checkoutDrugs.$inject = ['Pdfprinter', 'Config', '$state', '$log'];

  function checkoutDrugs(Pdfprinter, Config, $state, $log) {
    return {
      restrict: 'E',
      scope: {
        patient: '='
      },
      templateUrl: 'consult/checkout-drugs-directive.tpl.html',
      replace: false,
      controllerAs: 'patdCtrl',
      bindToController: true,
      controller: function CheckoutDrugsCtrl() {
        var vm = this;
        vm.checkoutdrugs = [];
        vm.conf = Config.$find('configuration');
        vm.drugcheckout = drugcheckout;
        vm.switchToDocuments = switchToDocuments;
        vm.drugedittpl = 'drug/drugPopoverTemplate.html';
      

        function drugcheckout(patient, consultid) {
          var newprinter;
          var printobj = {};
          var printdocument = {};
          var drugprint = [];
          if (vm.pdate) {
            moment.locale('de-at');
            patient.prescriptiondate = moment().format('ll');
          }
          else {
            patient.prescriptiondate = '';
          }
          printobj.foptemplate = 'prescription.xslt';
          printobj.rootelement = 'patient';
          printobj.patientid = patient._id;
          printobj.consultid = consultid;
          printobj.examid = '';
          printobj.type = 'Rezept';
          printobj.metadata = {};
          printobj.metadata.filename = 'rezept.pdf';
          printobj.patient = clean(patient);
          patient.drugs.forEach(function (drug) {
            if (drug.print && drug.active) {
              drugprint.push(drug);
            }
          });
          // print if there actually is something to print
          if (drugprint.length > 0) {
            printobj.patient.drugs = drugprint;
            newprinter = Pdfprinter.$build(printobj);
            newprinter.$save().$asPromise().then(function (doc) {
              printdocument.docid = doc.docid;
              printdocument.type = 'Rezept';
              printdocument.filetype = 'pdf';
              printdocument.mimetype = 'application/pdf';
              printdocument.filename = 'rezept.pdf';
              printdocument.printername = vm.conf.printers.prescriptionprinter;
              printdocument.time = moment().toISOString();
              if (!patient.openConsult.hasOwnProperty('checkoutdocs')) {
                patient.openConsult.checkoutdocs = [];
              }
              patient.openConsult.checkoutdocs.push(printdocument);
              switchToDocuments(patient);
            });
          }
          else {
            // abort prescription printing when no drugs were selected
            switchToDocuments(patient);
          }
        }

        function clean(dirty) {
          var cleaned = {};
          var i;
          var reg = /^\$/;

          if (angular.isObject(dirty)) {
            for (i in dirty) {
              if (dirty.hasOwnProperty(i)) {
                $log.debug('dirty i: ', i, dirty[i]);
                switch (i) {
                  case 'consults': break;
                  case 'consult': break;
                  case 'patient': break;
                  case 'examinations': break;
                  case 'openExam': break;
                  case 'openConsult': break;
                  default:
                    if (!reg.test(i)) {
                      cleaned[i] = dirty[i];
                    }
                }
              }
            }
          }
          return cleaned;
        }

        function switchToDocuments(patient) {
          patient.consultstate = 'checkout.documents';
          patient.$save();
          $state.go('patientrecord.ccdocs');
        }

        // TODO figure out how to use ui.popover with controllerAs syntax
        /* $scope.dynamicPopover = {};
        $scope.dynamicPopover.templateUrl = 'myPopoverTemplate.html';
        $scope.dynamicPopover.title = 'Detailinformation'; */

      }
    };
  }
}());

(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name patient.directive:checkoutDocuments
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <checkout-documents patient="consCtrl.patient"></checkout-documents>
   </file>
   </example>
   *
   */
  angular
    .module('consult')
    .directive('checkoutDocuments', checkoutDocuments);
  
  checkoutDocuments.$inject = ['$log', '$state', '$http', 'ExamHelper', 'ConsultModalsHelper',
    'PrescriptionHelper', 'Doctor', 'ConsultRefresh', 'vertxEventBusService', '$cookies', '$window'];
  
  function checkoutDocuments($log, $state, $http, ExamHelper, ConsultModalsHelper,
                             PrescriptionHelper, Doctor, ConsultRefresh, vertxEventBusService, $cookies, $window) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'consult/checkout-documents-directive.tpl.html',
      replace: false,
      controllerAs: 'docCtrl',
      bindToController: true,
      controller: ["$scope", function ($scope) {
        var vm = this;
        vm.$state = $state;
        vm.consultDocs = {};
        vm.examDocs = [];
        
        vm.editExam = editExam;
        vm.deleteExam = ExamHelper.deleteExam;
        vm.editPrescription = PrescriptionHelper.editPrescriptions;
        
        vm.addAideModal = ConsultModalsHelper.addAideModal;
        vm.addReferralModal = ConsultModalsHelper.addReferralModal;
        
        vm.checkReferrals = checkReferrals;
        vm.checkAides = checkAides;
        vm.checkPrescription = checkPrescription;
        vm.printSome = printSome;
        vm.saveAll = saveAll;
        vm.switchToPayment = switchToPayment;
        vm.setRecipient = setRecipient;


        vm.patient.checkoutstate = 'ccdocs';
        vm.patient.$save();
        
        function editExam(exam) {
          var closelistener;
          ExamHelper.editExam(exam, vm.patient);
          closelistener = $scope.$on('exameditclosed', function () {
            // $log.debug('cel: scope on ExamHelper.editExam call exam: ', exam);
            closelistener();
            vm.patient.openConsult.examinations.$refresh();
          });
        }
        
        function checkReferrals() {
          if (angular.isArray(vm.patient.openConsult.referrals) && vm.patient.openConsult.referrals.length > 0) {
            vm.consultDocs['referral'] = true;
          }
          else {
            vm.consultDocs['referral'] = false;
          }
          return vm.consultDocs['referral'];
        }

        function checkAides() {
          if (angular.isArray(vm.patient.openConsult.aides) && vm.patient.openConsult.aides.length > 0) {
            vm.consultDocs['aide'] = true;
          }
          else {
            vm.consultDocs['aide'] = false;
          }
          return vm.consultDocs['aide'];
        }

        function checkPrescription() {
          calculateActivedrugs();
          if (vm.patient.activedrugs > 0) {
            vm.consultDocs.prescription = true;
          }
          else {
            vm.consultDocs.prescription = false;
          }
        }
        
        function calculateActivedrugs() {
          var i;
          vm.patient.activedrugs = 0;
          for (i = 0; i < vm.patient.drugs.length; i++) {
            if (vm.patient.drugs[i].active === true && vm.patient.drugs[i].foreign === false && vm.patient.drugs[i].print === true) {
              vm.patient.activedrugs += 1;
            }
          }
        }
        
        function saveAll() {
          var key;
          var i;

          $log.debug('vm.ConsultDocs in saveAll: ', vm.ConsultDocs);


          // not so smart - results in automatic consult.$save() chain
          // set to forced manual selection instead
          // setRecipient();

          for (key in vm.consultDocs) {
            if (vm.consultDocs.hasOwnProperty(key)) {
              
              $http({
                url: '/routes/pdfprinter/consult',
                method: 'GET',
                params: {
                  patientid: vm.patient._id,
                  consultid: vm.patient.openConsult._id,
                  doctype: key
                }
              });
              
            }
          }
          
          if (vm.patient.openConsult.examinations.length) {
            for (i = 0; i < vm.examDocs.length; i++) {
              if (vm.patient.openConsult.examinations[i].active === true && vm.patient.openConsult.examinations[i].template.includefindings === false) {
                $http({
                  url: '/routes/pdfprinter/exam',
                  method: 'GET',
                  params: {
                    patientid: vm.patient._id,
                    examid: vm.patient.openConsult.examinations[i]._id,
                    doctype: 'examfinding'
                  }
                });
              }
            }
          }

          // vm.patient.openConsult.$save();
          switchToPayment();


          // TODO: this hangs the system. Why?
          /* vm.patient.openConsult.$save().$asPromise().then(function () {
           switchToPayment();
           }); */
        }
        
        function printSome() {
          var key;
          var i;
          $log.debug('vm.ConsultDocs in printSome(): ', vm.ConsultDocs);

          // not so smart - results in automatic consult.$save() chain
          // set to forced manual selection instead
          // setRecipient();
          
          for (key in vm.consultDocs) {
            if (vm.consultDocs.hasOwnProperty(key)) {
              if (vm.consultDocs[key]) {
                $http({
                  url: '/routes/pdfprinter/consultprint',
                  method: 'GET',
                  params: {
                    patientid: vm.patient._id,
                    consultid: vm.patient.openConsult._id,
                    doctype: key
                  }
                });
              }
              else {
                $http({
                  url: '/routes/pdfprinter/consult',
                  method: 'GET',
                  params: {
                    patientid: vm.patient._id,
                    consultid: vm.patient.openConsult._id,
                    doctype: key
                  }
                });
              }
            }
          }
          
          if (vm.patient.openConsult.examinations.length) {
            for (i = 0; i < vm.examDocs.length; i++) {
              if (vm.examDocs[i]) {
                $http({
                  url: '/routes/pdfprinter/examprint',
                  method: 'GET',
                  params: {
                    patientid: vm.patient._id,
                    examid: vm.patient.openConsult.examinations[i]._id,
                    doctype: 'examfinding'
                  }
                });
              }
              else {
                if (vm.patient.openConsult.examinations[i].active === true && vm.patient.openConsult.examinations[i].template.includefindings === false) {
                  $http({
                    url: '/routes/pdfprinter/exam',
                    method: 'GET',
                    params: {
                      patientid: vm.patient._id,
                      examid: vm.patient.openConsult.examinations[i]._id,
                      doctype: 'examfinding'
                    }
                  });
                }
              }
            }
          }
          // vm.patient.openConsult.$save();
          switchToPayment();
        }
        
        function switchToPayment() {
          // TODO: not sure if this would really work, would need to unset the variable if additional changes are made
          // leave it in for now, won't use in checkout-payment.reallyExit()
          vm.patient.openConsult.docssaved = true;
          vm.patient.checkoutstate = 'payment';
          vm.patient.incheckout = true;

          vm.patient.$save();
          $state.go('patientrecord.checkout.payment');
          /* vm.patient.$save().$asPromise().then(function () {
            $state.go('patientrecord.checkout.payment');
          }); */
        }

        function setRecipient() {
          var docid;
          vm.fr = {};
          $log.debug('setRecipient entered');
          $log.debug('is practdoctor: ', vm.recipient === 'practdoctor');
          if (angular.isString(vm.recipient) && (vm.recipient === 'practdoctor' || vm.recipient === 'refdoctor')) {
            $log.debug('yes, doctor selected');
            $log.debug('docid: ', vm.patient[vm.recipient]._id);
            docid = vm.patient[vm.recipient]._id;
            Doctor.$find(docid).$asPromise().then(function (d) {
              $log.debug('Doctor retrieved: ', d);
              setInfo(d);
            });
          }
          else {
            setInfo(vm.patient);
          }
        }

        // set recipient contact information field by field
        function setInfo(d) {
          var i = 0;
          if (d.titlefront) {
            vm.fr.titlefront = d.titlefront;
          }
          if (d.titleback) {
            vm.fr.titleback = d.titleback;
          }
          if (d.firstname) {
            vm.fr.firstname = d.firstname;
          }
          if (d.lastname) {
            vm.fr.lastname = d.lastname;
          }
          if (d.institute) {
            vm.fr.institute = d.institute;
          }
          if (d.address) {
            vm.fr.address = d.address;
          }
          if (d.postcode) {
            vm.fr.postcode = d.postcode;
          }
          if (d.city) {
            vm.fr.city = d.city;
          }
          if (d.phone1) {
            vm.fr.phone1 = d.phone1;
          }

          vm.patient.openConsult.recipient = vm.fr;
          $log.debug('Recipient created: ', vm.patient.openConsult.recipient);
          
          // save to consult for printing recipient on consultfindings
          vm.patient.openConsult.$save();
          
          // save to each exam to print recipient on examfindings
          if (angular.isArray(vm.patient.openConsult.examinations) && vm.patient.openConsult.examinations.length > 0) {
            for (i = 0; i < vm.patient.openConsult.examinations.length; i++) {
              vm.patient.openConsult.examinations[i].recipient = vm.fr;
              vm.patient.openConsult.examinations[i].$save();
            }
          }
          
          // vm.patient.recipient = vm.fr;
          // vm.patient.$save();

        }


      }],
      link: function (scope, element, attrs, ctrl) {

        ConsultRefresh.refreshAll(ctrl.patient);

        /* ctrl.patient.openExam.$refresh();
         ctrl.patient.openConsult.$refresh().$asPromise().then(function (oc) {
         oc.examinations.$refresh();
         });
         */

        // listen for openconsult updates
        vertxEventBusService.on('consults.update', function (message) {
          var messages = message.split(':');
          console.log('Consult updated: ', messages[0]);
          if (messages[1] !== $window.sessionStorage.getItem('sprtabid') && ctrl.patient.openConsult._id === messages[0]) {
          // if (messages[1] !== $cookies.get('vertx-web.session') && ctrl.patient.openConsult._id === messages[0]) {
            ctrl.patient.openConsult.$refresh().$asPromise().then(function () {
              ctrl.patient.openConsult.examinations.$refresh();
            });
            $log.debug(' (cbd) refreshed openconsult for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
          else {
            $log.debug(' (cbd) ignored openconsult change for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
        });

      }
    };
  }
}());

(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name patient.directive:checkoutBilling
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <checkout-billing></checkout-billing>
   </file>
   </example>
   *
   */
  angular
    .module('consult')
    .directive('checkoutBilling', checkoutBilling);
  
  checkoutBilling.$inject = ['Service', '$log', '$state', 'ConsultRefresh', 'vertxEventBusService', '$cookies', '$window'];
  
  function checkoutBilling(Service, $log, $state, ConsultRefresh, vertxEventBusService, $cookies, $window) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'consult/checkout-billing-directive.tpl.html',
      replace: false,
      controllerAs: 'billCtrl',
      bindToController: true,
      controller: ["$filter", function ($filter) {
        var vm = this;
        
        vm.Service = Service;
        // vm.allservices = Service.$search();
        vm.$state = $state;
        
        vm.sumUpItems = sumUpItems;
        vm.saveInvoiceItems = saveInvoiceItems;
        vm.noInvoice = noInvoice;
        
        vm.nonzeroitems = nonzeroitems;
        /* vm.checkedService = checkedService;
         vm.toggleService = toggleService;
         vm.removeService = removeService;*/
        
        vm.joinDiagnoses = joinDiagnoses;
        
        vm.selectedservices = {};
        
        function nonzeroitems() {
          var i;
          var n = 0;
          if (angular.isObject(vm.patient.openConsult.service) && angular.isArray(vm.patient.openConsult.service.items)) {
            for (i = 0; i < vm.patient.openConsult.service.items.length; i++) {
              if (vm.patient.openConsult.service.items[i].selected === true) {
                n++;
              }
            }
          }
          // $log.debug('Nr of invoice items: ', n);
          return n;
        }
        
        function joinDiagnoses() {
          var newdiag;
          var tmp1 = [];
          var tmp2 = [];
          var i;
          
          $log.debug('diagnoses: ', vm.patient.diagnoses);
          
          for (i = 0; i < vm.patient.diagnoses.length; i++) {
            $log.debug(i, ': ', vm.patient.diagnoses[i]);
            switch (vm.patient.diagnoses[i].col) {
              case 'primary':
                tmp1.unshift(vm.patient.diagnoses[i].key);
                break;
              case 'info':
                tmp1.push(vm.patient.diagnoses[i].key);
                break;
              case 'success':
                tmp2.unshift(vm.patient.diagnoses[i].key);
                break;
              default:
                tmp2.push(vm.patient.diagnoses[i].key);
                break;
            }
          }
          newdiag = tmp1.concat(tmp2);
          return newdiag.join(', ');
        }
        
        
        function arraysum(array) {
          var total = 0;
          var i;
          var len;
          for (i = 0, len = array.length; i < len; i++) {
            total += array[i];
          }
          $log.debug('arraysum total', total, array);
          return total;
        }
        
        function objectarraysum(array) {
          var total = 0;
          var entry;
          for (entry in array) {
            total += array[entry];
          }
          return total;
        }
        
        
        // this calculates brutto, netto, tax sum per taxbraket
        function sumUpItems() {
          var selserv = {};
          // var discounted = {};
          var i;
          var tax;
          var taxstr;
          var taxindex;
          var price;
          var nettoarray = {};
          var taxarray = [];
          var num = 0;
          var nettosum;
          var taxsum;
          var taxnum;
          var arrsum;
          var taxvalue;
          
          // $log.debug('sumUpItems entered');
          
          // Iterate of services
          // if service is selected, check tax bracket
          // add net price to array indexed by tax bracket
          // create array if not already exists
          
          for (i = 0; i < vm.patient.openConsult.service.items.length; i++) {
            if (vm.patient.openConsult.service.items[i].selected) {
              num++;
              tax = vm.patient.openConsult.service.items[i].tax;
              price = vm.patient.openConsult.service.items[i].price;
              taxstr = tax.toString();
              
              if (angular.isArray(selserv[taxstr])) {
                selserv[taxstr].push(price);
              }
              else {
                selserv[taxstr] = [];
                selserv[taxstr].push(price);
              }
            }
          }
          
          // $log.debug('********* selected items: ', selserv);
          
                    
          // calc netto sum per tax bracket
          // calc tax sum per tax bracket
          for (tax in selserv) {
            if (selserv.hasOwnProperty(tax)) {
              taxstr = tax.toString();
              // $log.debug('tax: ', i, selserv[taxstr]);
              if (angular.isNumber(parseInt(tax, 10)) && angular.isArray(selserv[taxstr])) {
                // $log.debug('tax isnumber: ', tax, selserv[taxstr]);
                nettoarray[taxstr] = arraysum(selserv[taxstr]);
                taxarray[taxstr] = Math.round(100 * (nettoarray[taxstr] * (parseInt(tax, 10) / 100))) / 100;
                // $log.debug('netto[taxstr]: ', tax, netto[taxstr]);
              }
            }
          }
          
          // $log.debug('********* taxarray items: ', taxarray);
                    
          // calc total
          // sum up arrays for netto prices and tax indexed by tax bracket
          nettosum = objectarraysum(nettoarray);
          taxsum = objectarraysum(taxarray);
          // $log.debug('taxsum: ', taxsum);
          
          // save to service record
          vm.patient.openConsult.service.netto = nettosum;
          // vm.patient.openConsult.service.nettoarray = nettoarray;
          vm.patient.openConsult.service.tax = taxsum;
          // vm.patient.openConsult.service.taxarray = taxarray;
          vm.patient.openConsult.service.totalbefore = nettosum + taxsum;
          vm.patient.openConsult.service.discounttaxsum = {};
          vm.patient.openConsult.service.discountnettoarray = {};
          vm.patient.openConsult.service.discountbruttoarray = {};
          // $log.debug('gesamt total pre discount:', vm.patient.openConsult.service.totalbefore);
          
          // check for discount
          // distribute discount across net proces and taxes
          if (vm.patient.openConsult.service.discount > 0 && vm.patient.openConsult.service.totalbefore > 0) {
            // $log.debug('calculating discount');
            
            for (taxindex in selserv) {
              if (selserv.hasOwnProperty(taxindex)) {

                taxnum = parseInt(taxindex, 10);
                // $log.debug('taxnum: ', taxnum);
                arrsum = arraysum(selserv[taxindex]);
                // $log.debug('arrsum: ', arrsum);
                taxvalue = arrsum * taxnum / 100;
                //  $log.debug('taxval: ', taxvalue);
                // calculate discounted tax in taxarray!
                vm.patient.openConsult.service.discounttaxsum[taxindex] = taxvalue * (1 - vm.patient.openConsult.service.discount / vm.patient.openConsult.service.totalbefore);
                vm.patient.openConsult.service.discountnettoarray[taxindex] = arrsum * (1 - vm.patient.openConsult.service.discount / vm.patient.openConsult.service.totalbefore);
                vm.patient.openConsult.service.discountbruttoarray[taxindex] = (arrsum + taxvalue) * (1 - vm.patient.openConsult.service.discount / vm.patient.openConsult.service.totalbefore);
                $log.debug('calculated discount: ', vm.patient.openConsult.service.discounttaxsum);
              }
            }
            // discounted total including tax
            vm.patient.openConsult.service.total = nettosum + taxsum - vm.patient.openConsult.service.discount;
            // discounted netto sum
            vm.patient.openConsult.service.nettodiscounted = nettosum * (1 - vm.patient.openConsult.service.discount / vm.patient.openConsult.service.totalbefore);
            // discounted tax sum
            vm.patient.openConsult.service.taxdiscounted = tax * (1 - vm.patient.openConsult.service.discount / vm.patient.openConsult.service.totalbefore);
          }
          else {
            vm.patient.openConsult.service.total = nettosum + taxsum;
            vm.patient.openConsult.service.nettodiscounted = nettosum;
            vm.patient.openConsult.service.taxdiscounted = taxsum;
            // fop templates only handle discounttaxarray, thus
            // this doesn't work
            // vm.patient.openConsult.service.discounttaxsum = taxarray;
            // vm.patient.openConsult.service.discountnettoarray = nettoarray;
            for (taxindex in selserv) {
              if (selserv.hasOwnProperty(taxindex)) {
                taxnum = parseInt(taxindex, 10);
                arrsum = arraysum(selserv[taxindex]);
                taxvalue = arrsum * taxnum / 100;
                // calculate non-discounted tax in taxarray!
                vm.patient.openConsult.service.discounttaxsum[taxindex] = taxvalue;
                vm.patient.openConsult.service.discountnettoarray[taxindex] = arrsum;
                vm.patient.openConsult.service.discountbruttoarray[taxindex] = arrsum + taxvalue;
              }
            }
          }
          
          
          // vm.patient.openConsult.service = service;
          vm.patient.openConsult.$save();
        }
        
        function saveInvoiceItems() {
          sumUpItems();
          vm.patient.openConsult.$save();
          if (vm.$state.current.name === 'patientrecord.billing') {
            $state.go('patientrecord.checkout.ccdocs');
          }
          else {
            $state.go('patientrecord.checkout.payment');
          }
        }
        
        function noInvoice() {
          vm.patient.openConsult.service = null;
          vm.patient.openConsult.$save();
          if (vm.$state.current.name === 'patientrecord.billing') {
            $state.go('patientrecord.checkout.ccdocs');
          }
          else {
            $state.go('patientrecord.checkout.payment');
          }
        }
        
        
      }],
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        
        // watch for patient.openConsult load, then check for services
        scope.$watch('billCtrl.patient.openConsult', function (newval, oldval, lscope) {
          // $log.debug('newval: ', newval);
          if (angular.isString(newval._id) && newval._id.length > 1) {
            // $log.debug('newval._id: ', newval._id);
            if (!angular.isObject(newval.service) || newval.service === null) {
              newval.service = {};
              newval.service.items = Service.$search({active: true});
            }
          }
        }, true);
        
        
        ConsultRefresh.refreshAll(ctrl.patient);
        
        // listen for openconsult updates
        vertxEventBusService.on('consults.update', function (message) {
          var messages = message.split(':');
          console.log('Consult updated: ', messages[0]);
          // if (messages[1] !== $cookies.get('vertx-web.session') && ctrl.patient.openConsult._id === messages[0]) {
          if (messages[1] !== $window.sessionStorage.getItem('sprtabid') && ctrl.patient.openConsult._id === messages[0]) {
            ctrl.patient.openConsult.$refresh().$asPromise().then(function () {
              ctrl.patient.openConsult.examinations.$refresh();
            });
            $log.debug(' (cbd) refreshed openconsult for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
          else {
            $log.debug(' (cbd) ignored openconsult change for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:checkoutAides
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <checkout-aides patient="consCtrl.patient"></checkout-aides>
   </file>
   </example>
   *
   */
  angular
    .module('consult')
    .directive('checkoutAides', checkoutAides);

  checkoutAides.$inject = [];

  function checkoutAides() {
    return {
      restrict: 'EA',
      scope: {
        patient: '=',
        dialog: '=?'
      },
      templateUrl: 'consult/checkout-aides-directive.tpl.html',
      replace: false,
      controllerAs: 'aidCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.removeAide = removeAide;
        vm.addAnotherAide = addAnotherAide;
        vm.newaide = {};
        vm.newaide.pdate = true;
        vm.newaide.aidedate = moment().locale('de-at').format('ll');

        function removeAide(i) {
          vm.patient.openConsult.aides.splice(i, 1);
          vm.patient.openConsult.$save();
        }

        function addAnotherAide() {
          if (!angular.isArray(vm.patient.openConsult.aides)) {
            vm.patient.openConsult.aides = [];
          }
          vm.patient.openConsult.aides.push(vm.newaide);
          vm.patient.openConsult.$save();

          vm.newaide = {};
          vm.newaide.pdate = true;
          vm.newaide.aidedate = moment().locale('de-at').format('ll');
        }

      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name doctype
   * @description
   *
   */
  angular
    .module('doctype', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('doctype')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('doctype', {
        url: '/doctype',
        templateUrl: 'doctype/doctype.tpl.html',
        controller: 'DoctypeCtrl',
        controllerAs: 'doctype'
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name doctype.directive:doctypeList
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="doctype">
       <file name="index.html">
        <doctype-list></doctype-list>
       </file>
     </example>
   *
   */
  angular
    .module('doctype')
    .directive('doctypeList', doctypeList);


  doctypeList.$inject = ['Doctype', '$uibModal', '$log', 'DoctypeHelper'];

  function doctypeList(Doctype, $uibModal, $log, DoctypeHelper) {
    return {
      restrict: 'E',
      scope: {},
      templateUrl: 'doctype/doctype-list-directive.tpl.html',
      replace: false,
      controllerAs: 'dtlCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'doctypeList';
        
        vm.editDoctype = DoctypeHelper.editDoctype;
        vm.addDoctype = addDoctype;
        vm.removeDoctype = removeDoctype;
  
        vm.doctypelist = Doctype.$search();
        
        function addDoctype() {
          var newdoctype = vm.doctypelist.$create();
          vm.editDoctype(newdoctype);
        }
        
        
        function removeDoctype(dt) {
          dt.active = false;
          dt.$save();
        }
        
        
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name doctype.factory:DoctypeHelper
   *
   * @description
   *
   */
  angular
    .module('doctype')
    .factory('DoctypeHelper', DoctypeHelper);

  DoctypeHelper.$inject = ['Doctype', '$uibModal', '$log'];

  function DoctypeHelper(Doctype, $uibModal, $log) {
    var DoctypeHelperBase = {};

    DoctypeHelperBase.editDoctype = editDoctype;


    function editDoctype(ldt) {
      var dialog = $uibModal.open({
        template: '<div class="modalspacer"><doctype-edit doctype="miCtrl.doctype" dialog="miCtrl.$uibModalInstance"></doctype-edit></div>',
        controllerAs: 'miCtrl',
        controller: ['doctype', '$uibModalInstance', function (sdoctype, $uibModalInstance) {
          var vmi = this;
          vmi.doctype = sdoctype;
          vmi.$uibModalInstance = $uibModalInstance;
        }],
        resolve: {
          doctype: function () {
            return ldt;
          },
          dialog: function () {
            return dialog;
          }
        },
        size: 'lg'
      });
      return dialog;

    }


    return DoctypeHelperBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name doctype.factory:Doctype
   *
   * @description
   *
   */
  angular
    .module('doctype')
    .factory('Doctype', Doctype);

 
  
  Doctype.$inject = ['restmod'];
  function Doctype(restmod) {
    return restmod.model('/routes/doctypes').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true}
    });
  }
  
  
  // Klammerfriedhof
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name doctype.directive:doctypeEdit
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="doctype">
       <file name="index.html">
        <doctype-edit></doctype-edit>
       </file>
     </example>
   *
   */
  angular
    .module('doctype')
    .directive('doctypeEdit', doctypeEdit);


  doctypeEdit.$inject = ['Doctype', 'Printerlist', '$log'];

  function doctypeEdit(Doctype, Printerlist, $log) {
    return {
      restrict: 'E',
      scope: {
        doctype: '=',
        dialog: '='
      },
      templateUrl: 'doctype/doctype-edit-directive.tpl.html',
      replace: false,
      controllerAs: 'dteCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'doctypeEdit';
        vm.save = save;
        vm.close = close;

        vm.allprinters = Printerlist.getPrinters();

        function save() {
          vm.doctype.$save();
        }

        function close() {
          vm.dialog.close();
        }

      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name dms
   * @description
   *
   */
  angular
    .module('dms', [
      'ui.router',
      'dms.scan',
      'dms.signoff'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('dms')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dms', {
        url: '/dms',
        views: {
          main: {templateUrl: 'dms/dms.tpl.html'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });
    $stateProvider
      .state('dms.scansorter', {
        url: '/scansorter',
        views: {
          dmsmain: {template: '<scan-sorter></scan-sorter>'}
        }
      });
    /*    $stateProvider
          .state('dms.signoff', {
            url: '/signoff',
            views: {
              dmsmain: {template: '<signoff-all></signoff-all>'}
            }
          });*/
    $stateProvider
      .state('dms.signoff', {
        url: '/signoff',
        params: {
          patientid: null
        },
        views: {
          dmsmain: {
            template: '<signoff-all patientid="{{vm.patientid}}"></signoff-all>',
            controller: ["$stateParams", "$state", function ($stateParams, $state) {
              var vm = this;
              console.log('stateparams in dummy controller: ', $stateParams, $state);
              vm.patientid = $stateParams.patientid;
            }],
            controllerAs: 'vm'
          }
        }
        /*resolve: {
          patientid: function ($stateParams, $state) {
            // var patientid = $stateParams.patientid;
            var patientid = $state.previousParams.patientid;
            console.log('**** dms router resolve $state: ', $state);
            console.log('**** dms router resolve previous: ', $state.previousParams.patientid);
            console.log('**** dms router resolve patientid: ', patientid);
            return patientid;
          }
        },
        onEnter: function ($stateParams, $state) {
          console.log('**** dms router entered: ', $stateParams, $state);
        }*/
      });

    $stateProvider
      .state('dms.patsignoff', {
        url: '/patsignoff',
        params: {
          patientid: null
        },
        views: {
          dmsmain: {
            template: '<signoff-all patientid="{{vm.patientid}}"></signoff-all>',
            controller: ["$stateParams", "$state", function ($stateParams, $state) {
              var vm = this;
              console.log('stateparams in dummy controller: ', $stateParams, $state);
              vm.patientid = $stateParams.patientid;
            }],
            controllerAs: 'vm'
          }
        }
        /*resolve: {
          patientid: function ($stateParams, $state) {
            // var patientid = $stateParams.patientid;
            var patientid = $state.previousParams.patientid;
            console.log('**** dms router resolve $state: ', $state);
            console.log('**** dms router resolve previous: ', $state.previousParams.patientid);
            console.log('**** dms router resolve patientid: ', patientid);
            return patientid;
          }
        },
        onEnter: function ($stateParams, $state) {
          console.log('**** dms router entered: ', $stateParams, $state);
        }*/
      });


    $stateProvider
      .state('dms.filebrowser', {
        url: '/filebrowser',
        views: {
          dmsmain: {template: '<file-browser></file-browser>'}
        }
      });
  }

  // klammerfriedhof
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name config
   * @description
   *
   */
  angular
    .module('config', [
      'ui.router',
      'utils.listEditor'
    ]);
}());

(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name config.directive:version
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="config">
   <file name="index.html">
   <version></version>
   </file>
   </example>
   *
   */
  angular
    .module('config')
    .directive('version', version);
  
  version.$inject = ['$http', '$log']
  
  function version($http, $log) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'config/version-directive.tpl.html',
      replace: false,
      controllerAs: 'verCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'version';
        
        $http.get('/routes/version')
          .then(function success(v) {
              vm.version = v;
            },
            function failure(e) {
              vm.error = e.statusText + ' (Error: ' + e.status + ')';
            });
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  angular
    .module('config')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('config', {
        url: '/config',
        views: {
          main: {template: '<config-editor></config-editor>'},
          // 'leftbar': {template: '<patient-today></patient-today>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });
    
    $stateProvider
      .state('version', {
        url: '/version',
        views: {
          main: {template: '<version></version>'},
          // 'leftbar': {template: '<patient-today></patient-today>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });

    $stateProvider
      .state('svs', {
        url: '/svs',
        views: {
          main: {template: '<sv></sv>'},
          // 'leftbar': {template: '<patient-today></patient-today>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });

    $stateProvider
      .state('dts', {
        url: '/dts',
        views: {
          main: {template: '<doctype-list></doctype-list>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });

  }


}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name config.factory:Config
   *
   * @description
   *
   */
  angular
    .module('config')
    .factory('Config', Config);


  Config.$inject = ['restmod', '$log'];

  function Config(restmod) {
    return restmod.model('/routes/appconfig');
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name config.directive:configEditor
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="config">
   <file name="index.html">
   <config-editor></config-editor>
   </file>
   </example>
   *
   */
  angular
    .module('config')
    .directive('configEditor', configEditor);

  configEditor.$inject = ['Config', 'Printerlist', '$log'];

  function configEditor(Config, Printerlist, $log) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'config/config-editor-directive.tpl.html',
      replace: false,
      // controller: 'ConfigCtrl',
      controllerAs: 'confCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.ctrlName = 'ConfigCtrl';
        vm.Printerlist = Printerlist;
        vm.conf = Config.$find('configuration');
        vm.allprinters = vm.Printerlist.getPrinters();
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */

        // the third argument 'true' ensures deep checking of contents, works better than watchCollection
        scope.$watch('confCtrl.conf.dmspatientcat', function () {
          if (angular.isFunction(ctrl.conf.$save)) {
            ctrl.conf.$save();
            console.log('dmspatientcat saved: ', ctrl.conf.dmspatientcat);
          }

        }, true);

        scope.$watch('confCtrl.conf.dmsgencat', function () {
          if (angular.isFunction(ctrl.conf.$save)) {
            ctrl.conf.$save();
            console.log('dmsgencat saved: ', ctrl.conf.dmsgencat);
          }
        }, true);

      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name chat
   * @description
   *
   */
  angular
    .module('chat', [
      'ui.router',
      'knalli.angular-vertxbus',
      'luegg.directives'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('chat')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('chat', {
        url: '/chat',
        templateUrl: 'chat/chat.tpl.html',
        controller: 'ChatCtrl',
        controllerAs: 'chat'
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name chat.factory:Chat
   *
   * @description
   *
   */
  angular
    .module('chat')
    .factory('Chat', Chat);


  Chat.$inject = ['vertxEventBusService', '$log'];

  function Chat(vertxEventBusService, $log) {
    var ChatBase = {};
    ChatBase.chatlog = [];
    ChatBase.sendMsg = sendMsg;
    ChatBase.getChatlog = getChatlog;

    function getChatlog() {
      return ChatBase.chatlog;
    }

    function sendMsg(msg) {
      vertxEventBusService.send('chat.to.server', msg);
    }

    vertxEventBusService.on('chat.to.client', function (message) {
      var audio = new Audio('img/submarine.wav');
      audio.play();
      $log.debug('Received a message: ', message);
      
      ChatBase.chatlog.push(message);
    });


    return ChatBase;
  }



}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name chat.directive:chat
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="chat">
       <file name="index.html">
        <chat></chat>
       </file>
     </example>
   *
   */
  angular
    .module('chat')
    .directive('chat', chat);

  chat.$inject = ['$log', 'Chat', '$cookies'];

  function chat($log, Chat, $cookies) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'chat/chat-directive.tpl.html',
      replace: false,
      controllerAs: 'chatCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'chat';
        vm.Chat = Chat;
        vm.chatlog = vm.Chat.getChatlog();
        vm.sendMsg = sendMsg;

        function sendMsg() {
          vm.Chat.sendMsg($cookies.get('username') + ': ' + vm.newmessage);
          vm.newmessage = '';
        }



      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
    'use strict';

    angular
      .module('docsaver')
      .config(config);

    config.$inject = ['$urlRouterProvider'];

    // when using states you only need the default route
    function config($urlRouterProvider) {
      $urlRouterProvider.when('/', '');
      $urlRouterProvider.otherwise('/home');
    }
  }()
);

/**
 * Created by lari on 27/08/15.
 */

