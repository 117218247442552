(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name waitinglist.directive:waitinglists
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="waitinglist">
       <file name="index.html">
        <waitinglists></waitinglists>
       </file>
     </example>
   *
   */
  angular
    .module('waitinglist')
    .directive('waitinglists', waitinglists);
  
  waitinglists.$inject = ['$log', 'Waitinglist', 'Wlcommons'];
  
  function waitinglists($log, Waitinglist, Wlcommons) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'waitinglist/waitinglists-directive.tpl.html',
      replace: false,
      controllerAs: 'wlCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'waitinglists';
        // vm.waitinglists = Waitinglist.$search({active: true});
        Waitinglist.$search({active: true}).$asPromise().then(function (wl) {
          var i;
          for (i = 0; i < wl.length; i++) {
            wl[i].patients.$refresh();
          }
          vm.waitinglists = wl;
        });
        
        vm.addPatient = Wlcommons.addPatient;
        vm.removePatient = Wlcommons.removePatient;
        
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
