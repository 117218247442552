(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name tracker.directive:trackerlist
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="tracker">
       <file name="index.html">
        <trackerlist></trackerlist>
       </file>
     </example>
   *
   */
  angular
    .module('tracker')
    .directive('trackerlist', trackerlist);
  
  trackerlist.$inject = ['$log', 'Trackercommons', '$timeout', '$uibModal'];
  
  function trackerlist($log, Trackercommons, $timeout, $uibModal) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'tracker/trackerlist-directive.tpl.html',
      replace: false,
      controllerAs: 'tlCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'trackerlist';
        vm.newtr = '';
        vm.newdp = [];
        vm.dpsaved = false;
        
        vm.addTracker = addTracker;
        vm.removeTracker = removeTracker;
        vm.editTracker = editTracker;
        vm.addDatapoint = addDatapoint;
        vm.addDatapoints = addDatapoints;
        vm.today = today;
        
        function addTracker() {
          Trackercommons.addTracker(vm.patient, vm.newtr.name, vm.newtr.unit, vm.newtr.min, vm.newtr.max);
          vm.newtr = '';
        }
        
        function addDatapoint(trackerindex) {
          Trackercommons.addDatapoint(vm.patient, trackerindex, vm.newdp[trackerindex].date, vm.newdp[trackerindex].unit);
          vm.newdp[trackerindex] = {};
          vm.shownewdp = false;
        }
        
        function addDatapoints() {
          var i;
          var multi = true;
          // use multi: true to avoid saving on every datapoint entry. save once, after all datapoints have been added.
          for (i = 0; i < vm.newdp.length; i++) {
            if (angular.isString(vm.newdp[i].date) && angular.isNumber(vm.newdp[i].data)) {
              Trackercommons.addDatapoint(vm.patient, i, vm.newdp[i].date, vm.newdp[i].data, multi);
            }
          }
          
          vm.dpsaved = true;
          $timeout(function () {
            vm.newdp = [];
            vm.dpsaved = false;
          }, 3000);
          vm.patient.$save();
        }
        
        function removeTracker(index) {
          $log.debug('*************  remove tracker: ', index);
          vm.patient.trackers[index].active = false;
          $log.debug('*************  remove tracker: ', vm.patient.trackers[index].active);
          vm.patient.$save();
        }
        
        function today() {
          var date = moment().format('YYYY-MM-DD');
          return date;
        }
        
        function editTracker(tracker) {
          $uibModal.open({
            template: '<div class="modalspacer"><edit-tracker tracker="miCtrl.tracker" patient="miCtrl.patient"></edit-tracker></div>',
            size: 'modal-sm',
            controller: ['$uibModalInstance', 'tracker', 'patient', function ($uibModalInstance, ltracker, lpatient) {
              var vmi = this;
              vmi.tracker = ltracker;
              vmi.patient = lpatient;
              vmi.$modalInstance = $uibModalInstance;
      
              vmi.cancel = function () {
                vmi.$modalInstance.close();
              };
      
              vmi.save = function () {
                lpatient.$save();
                // vmi.$modalInstance.close();
              };
            }],
            controllerAs: 'miCtrl',
            resolve: {
              tracker: function () {
                return tracker;
              },
              patient: function () {
                return vm.patient;
              }
            }
          });
        }
        
        
        
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
