(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name pdfprinter.factory:Pdfprinter
   *
   * @description
   *
   */
  angular
    .module('pdfprinter')
    .factory('Pdfprinter', Pdfprinter);
    // .config(config);
/*
  config.$inject = ['restmodProvider'];

  function config(restmodProvider) {
    restmodProvider.rebase({
      $config: {
        primaryKey: '_id'
      }
    });
  }
*/
  Pdfprinter.$inject = ['restmod'];

  function Pdfprinter(restmod) {
    return restmod.model('/routes/pdfprinter');
  }


}());
