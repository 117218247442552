(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name consult.factory:DocumentHelper
   *
   * @description
   *
   */
  angular
    .module('consult')
    .factory('DocumentHelper', DocumentHelper);
  
  DocumentHelper.$inject = ['$log', '$http'];
  
  function DocumentHelper($log, $http) {
    var DocumentHelperBase = {};
    // patient.copenConsult.consultDocs = {};
    // patient.copenConsult.examDocs = [];
    
    
    
    DocumentHelperBase.printSome = function printSome(patient, consultDocs, examDocs) {
      var key;
      var i;

      if (!angular.isObject(patient.openConsult.consultDocs)) {
        patient.openConsult.consultDocs = consultDocs;
      }

      if (!angular.isArray(patient.openConsult.examDocs)) {
        patient.openConsult.consultDocs = examDocs;
      }


      for (key in patient.copenConsult.consultDocs) {
        if (patient.copenConsult.consultDocs.hasOwnProperty(key)) {
          if (patient.copenConsult.consultDocs[key]) {
            $http({
              url: '/routes/pdfprinter/consultprint',
              method: 'GET',
              params: {
                patientid: patient._id,
                consultid: patient.openConsult._id,
                doctype: key
              }
            });
          }
          else {
            $http({
              url: '/routes/pdfprinter/consult',
              method: 'GET',
              params: {
                patientid: patient._id,
                consultid: patient.openConsult._id,
                doctype: key
              }
            });
          }
        }
      }
    
      if (patient.openConsult.examinations.length) {
        for (i = 0; i < patient.copenConsult.examDocs.length; i++) {
          if (patient.copenConsult.examDocs[i]) {
            $http({
              url: '/routes/pdfprinter/examprint',
              method: 'GET',
              params: {
                patientid: patient._id,
                examid: patient.openConsult.examinations[i]._id,
                doctype: 'examfinding'
              }
            });
          }
          else {
            $http({
              url: '/routes/pdfprinter/exam',
              method: 'GET',
              params: {
                patientid: patient._id,
                examid: patient.openConsult.examinations[i]._id,
                doctype: 'examfinding'
              }
            });
          }
        }
      }
    };


    // saveAll()
    DocumentHelperBase.saveAll = function saveAll(patient, consultDocs, examDocs) {
      var key;
      var i;

      if (!angular.isObject(patient.openConsult.consultDocs)) {
        patient.openConsult.consultDocs = consultDocs;
      }

      if (!angular.isArray(patient.openConsult.examDocs)) {
        patient.openConsult.consultDocs = examDocs;
      }


      for (key in patient.copenConsult.consultDocs) {
        if (patient.copenConsult.consultDocs.hasOwnProperty(key)) {
      
          $http({
            url: '/routes/pdfprinter/consult',
            method: 'GET',
            params: {
              patientid: patient._id,
              consultid: patient.openConsult._id,
              doctype: key
            }
          });
      
        }
      }
  
      if (patient.openConsult.examinations.length) {
        for (i = 0; i < patient.copenConsult.examDocs.length; i++) {
          $http({
            url: '/routes/pdfprinter/exam',
            method: 'GET',
            params: {
              patientid: patient._id,
              examid: patient.openConsult.examinations[i]._id,
              doctype: 'examfinding'
            }
          });
      
        }
      }
    };

    // return Helper Object
    return DocumentHelperBase;
  }
}());
