<div class="docs">
  <div class="wizard">
    <div class="steps-container">
      <ul class="steps">
        <li ui-sref="patientrecord.current.consult" ui-sref-active="active">Konsultation<span
            class="chevron"></span></li>
        <!-- <li ui-sref="patientrecord.current.checkout.findings" ui-sref-active="active">Befund<span
             class="chevron"></span></li>
         <li ui-sref="patientrecord.current.checkout.aides" ui-sref-active="active">Verordnung<span
             class="chevron"></span></li>
         <li ui-sref="patientrecord.current.checkout.referrals" ui-sref-active="active">Überweisung<span
             class="chevron"></span></li>-->
        <li ui-sref="patientrecord.billing" ui-sref-active="active">Rechnungspositionen<span
            class="chevron"></span></li>
        <!-- <li ui-sref="patientrecord.current.checkout.drugs" ui-sref-active="active">Rezept<span class="chevron"></span>
         </li>-->
        <li ui-sref="patientrecord.ccdocs" ui-sref-active="active">
          <i class="fa fa-print" uib-tooltip="Drucken"></i> Dokumentenübersicht
          <span class="chevron"></span></li>
        <li class="notallowed" ui-sref-active="active">
          <i class="fa fa-eur" uib-tooltip="Bezahlen"></i> Bezahlen<span class="chevron"></span></li>
      </ul>
    </div>
  </div>
  <div ui-view="checkout"></div>
</div>