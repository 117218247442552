<div style="padding: 20px"><!-- template must be enclosed by single tag -->
  <div style="padding: 20px"><!-- template must be enclosed by single tag -->


    <div class="row" style="margin-bottom: 2px" ng-repeat="alt in pdvCtrl.newdrug.alternatives">
      <div class="btn btn-{{alt.color}} col-xs-12"
           uib-tooltip="{{alt.tooltip}}"
           ng-click="pdvCtrl.replaceDrug(alt)"
           ng-bind="alt.name">
      </div>
    </div>

    <div class="alert alert-danger"><h3><i class="fa fa-warning"></i> Wichtiger Hinweis!</h3>
      <p>
        Die hier gelisteten Medikamente wurden auf Basis des EKO Tools der SV ausgewählt. Sie sind nach Preis sortiert -
        das günstigste zuerst. Es können echte Generika (gleicher Wirkstoff, gleiche Stärke), Alternativen mit
        geringfügig anderer Wirkstoffkonzentration, oder Biosimilars (änhlicher Wirksstoff) aufgelistet sein.
        Farbcodierung/Hinweise der jeweiligen Alternative beachten!</p>

      <p>Prüfung der Alternativen auf ihre Einsatzmöglichkeit und ggf. Auswahl eines Ersatzpräparats obliegt
        AUSSCHLIESSLICH der berhandelnden Ärztin / dem behandelndem Arzt!</p>
    </div>
  </div>