(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name labvalue.directive:labreferral
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="labvalue">
       <file name="index.html">
        <labreferral allvalues=""></labreferral>
       </file>
     </example>
   *
   */
  angular
    .module('labvalue')
    .directive('labreferral', labreferral);

  labreferral.$inject = ['Labvalue', '$log'];

  function labreferral(Labvalue, $log) {
    return {
      restrict: 'EA',
      scope: {
        allvalues: '=',
        additionalvalues: '=',
        ginclude: '=',
        ti: '@?'
      },
      templateUrl: 'labvalue/labreferral-directive.tpl.html',
      replace: false,
      controllerAs: 'labRCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.allvalues = [];
        
        vm.labconfig = Labvalue.$find('labconfiguration');
        vm.loadValues = loadValues;
        vm.toggleGroup = toggleGroup;
        vm.joinValues = joinValues;
        vm.flattenArray = flattenArray;
        vm.tabi = parseInt(vm.ti, 10);
        
        function flattenArray(a) {
          var tmpa = [];
          var i;
          if (angular.isArray(a)) {
            for (i = 0; i < a.length; i++) {
              tmpa.push(a[i].name);
            }
          }
          return tmpa;
        }

        function joinValues() {
          var tmpa = [];
          var i;
          // $log.debug('Join values entered');
          // $log.debug('Join values add val', vm.additionalvalues);
          if (angular.isArray(vm.autoselectedvalues) && angular.isArray(vm.additionalvalues)) {
            // $log.debug('both');
            tmpa = vm.autoselectedvalues.concat(flattenArray(vm.additionalvalues));
          }
          else {
            if (angular.isArray(vm.autoselectedvalues)) {
              // $log.debug('auto');
              tmpa = vm.autoselectedvalues;
            }
            if (angular.isArray(vm.additionalvalues)) {
              // $log.debug('additional');
              tmpa = flattenArray(vm.additionalvalues);
            }
          }
          vm.allvalues = uniq(tmpa);
          // $log.debug('Join valuesallvalues: ', vm.allvalues);
        }

        function toggleGroup() {
          var i;
          var tmpa = [];
          // vm.autoselectedvalues = vm.autoselectedvalues || [];
          for (i = 0; i < vm.labconfig.groups.length; i++) {
            // $log.debug('ginclude ', vm.labconfig.groups[i].name, vm.ginclude[i]);
            if (vm.ginclude[i] === true) {
              tmpa = tmpa.concat(vm.labconfig.groups[i].values);
            }
          }
          vm.autoselectedvalues = uniq(flattenArray(tmpa));
          // $log.debug('vm.autoselectedvalues: ', vm.autoselectedvalues);
          joinValues();
        }



        // load and filter autocomplete values
        function loadValues($query) {
          var promise = new Promise(function (resolve, reject) {
            // TODO this is a rather nasty piece of code to generate a promise that is needed in the next step
            if (true) {
              resolve(vm.labconfig.values);
            }
            else {
              reject([]);
            }
          });
          return promise.then(function (values) {
            var filteredValues = values.filter(function (value) {
              return value.name.toLowerCase().indexOf($query.toLowerCase()) !== -1;
            });
            console.log('filtered values: ', filteredValues);
            return filteredValues;
          });
        }

        // make array values uniq
        function uniq(a) {
          return a.sort().filter(function (item, pos, ary) {
            return !pos || item !== ary[pos - 1];
          });
        }
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
