(function () {
  'use strict';

  /* @ngdoc object
   * @name docsaver
   * @description
   *
   */

  angular
    .module('docsaver', [
      'ngAria',
      'ui.router',
      'ui.bootstrap',
      'ngCookies',
      'ngTagsInput',
      'ui.bootstrap.typeahead',
      'restmod',
      'bootstrapLightbox',
      'cfp.hotkeys',
      'ngLodash',
      'todo',
      'user',
      'patient',
      'consult',
      'examtemplate',
      'examination',
      'pdfprinter',
      'service',
      'config',
      'document',
      'drug',
      'file',
      'share',
      'home',
      'printer',
      'labvalue',
      'chat',
      'testdata',
      'finance',
      'finance.invoice',
      'finance.cashbook',
      'finance.turnover',
      'finance.bmfexport',
      'finance.rksv',
      'finance.closing',
      'journal',
      'utils',
      'utils.fileuploader',
      'utils.filereader',
      'utils.listEditor',
      'login',
      'dms',
      'helpers',
      'reminder',
      'unicatalog',
      'utils.stacktrace',
      'doctype',
      'forms',
      'waitinglist',
      'tracker',
      'heartbeat',
      'rlog'
    ]);

  angular.module('docsaver')
    .filter('moment', function () {
      return function (dateString, format) {
        return moment(dateString).locale('de-at').format(format);
      };
    })
    .filter('addweeks', function () {
      return function (weeks, format) {
        return moment().add(weeks * 7, 'days').locale('de-at').format(format);
      };
    })
    .filter('javadate', function () {
      return function (javadate, format) {
        return moment(javadate, 'YYYYMMDD-HHmmss').locale('de-at').format(format);
      };
    })
    .filter('trustUrl', ['$sce', function ($sce) {
      return function (recordingUrl) {
        return $sce.trustAsResourceUrl('/routes/files/filereader/' + recordingUrl);
      };
    }])
    // this interceptor will ad a custom header containing the tabid to every request
    .factory('tabidInterceptor', ['$log', '$window', function ($log, $window) {
      return {
        request: function (config) {
          var uuid;
          // helper function to create uuid
          function guid() {
            function s4() {
              return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
            }

            return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
              s4() + '-' + s4() + s4() + s4();
          }

          if ($window.sessionStorage.getItem('sprtabid')) {
            uuid = $window.sessionStorage.getItem('sprtabid');
          }
          else {
            uuid = guid();
            $log.debug('Tab UUID created: ', uuid);
            $window.sessionStorage.setItem('sprtabid', uuid);
          }
          config.headers['X-tabid'] = uuid;
          return config;
        }
      };
    }])
    .config(function ($httpProvider) {
      $httpProvider.interceptors.push('tabidInterceptor');
    })
    /*
     .run(['Config', '$rootScope', '$log', function (Config, $rootScope, $log) {
     $log.debug('Config initialised');
     Config.init();
     }])*/
    /*
     .run(['Labvalue', '$rootScope', '$log', function (Labvalue, $rootScope, $log) {
     $log.debug('Labvalues initialised');
     Labvalue.init();
     }]) */
    /*
     // this ould be nice, but it inferes with hotkey triggered state changes
     .run(['$rootScope', '$uibModalStack', '$log',
     function ($rootScope, $uibModalStack, $log) {
     // close the opened modal on location change.
     var lcshandle = $rootScope.$on('$locationChangeSuccess', function ($event) {
     var openedModal = $uibModalStack.getTop();
     $log.debug('openedModal: ', openedModal);
     $log.debug('Close modal entered');
     if (openedModal) {
     if (!$event.preventDefault) {
     $event.preventDefault();
     }
     $uibModalStack.dismiss(openedModal.key);
     }
     });
     }]) */
    // restmod cookie handling? todo
    .run(['$cookies', '$rootScope', '$log', function ($cookies, $rootScope, $log) {
      $rootScope.username = $cookies.get('username');
      $log.debug('cookies initialised: ', $cookies.get('username'));
    }])
    .config(['restmodProvider', '$httpProvider', function (restmodProvider, $httpProvider) {
      restmodProvider.rebase({
        /* $config: {

         },*/
        $hooks: {
          'before-request': function () {
            $httpProvider.defaults.withCredentials = true;
          }
        }
      });
    }])
    // login: HTTP 401 interceptor
    .factory('AuthInterceptor', ['$injector', '$location', '$q', '$log', function ($injector, $location, $q, $log) {
      return {
        responseError: function (rejection) {
          var $state;
          console.log('rejection: ', rejection);
          if (rejection.status === 401 && rejection.config.url !== '/login') {
            $state = $injector.get('$state');
            // This is the interesting bit: (redirect to initially entered url)
            $log.debug('app-module location url: ', $location.url());
            if ($location.url() !== '/login') {
              $state.go('login', {url: $location.url()});
            }

          }
          return $q.reject(rejection);
        }
      };
    }])
    .config(['$httpProvider', function ($httpProvider) {
      $httpProvider.interceptors.push('AuthInterceptor');
    }])
    // make sure that hotkeys ngroute support does not interfere with ui.router
    .config(['hotkeysProvider', function (hotkeysProvider) {
      hotkeysProvider.useNgRoute = false;
    }])
    .run(['hotkeys', '$state', '$log', function (hotkeys, $state, $log) {
      $log.debug('app hotkeys entered');
      hotkeys
        .add({
          combo: 'alt+n',
          description: 'Neuer Patient',
          callback: function () {
            $state.go('newpatient');
          }
        });
      hotkeys
        .add({
          combo: 'alt+f',
          description: 'Patient Finden',
          callback: function () {
            $state.go('patientsearch');
          }
        })
      ;
    }])
    /* .config(['$provide', function ($provide) {
     // $provide provider is used to register the components in angular internally.

     // use decorator to customise the behaviour of a service.
     $provide.decorator('$exceptionHandler', ['$delegate', '$window', 'Stacktrace',
     function ($delegate, $window, Stacktrace) {
     var stacktrace;
     var clientSideErrorInfo;

     // exception: exception associated with the error
     // cause: optional information about the context in which the error was thrown.

     return function (exception, cause) {

     // $delegate: provides the original service to the method which is used to call the base implementation
     // of $exceptionHandler service which internally delegates to $log.error.
     $delegate(exception, cause);

     stacktrace = Stacktrace.print($window, exception);

     clientSideErrorInfo = {
     cause: cause || '', // the cause of the issue
     message: exception.message, // the message in the exception
     url: $window.location.href, // the location in the browser's address bar when error occurred
     stacktrace: stacktrace.join('\n') // join array items to populate a string
     };

     console.log(clientSideErrorInfo.stacktrace);

     // the angular $http service cannot be used in the $log
     // decorator because it will cause a circular dependecy.
     // to overcome this  a direct ajax call should be made.

     $.ajax({
     type: 'POST',
     url: '/routes/logger', // this is the server end-point where you can log this error
     contentType: 'application/json; charset=UTF-8',
     data: JSON.stringify(clientSideErrorInfo)
     });
     };
     }]);
     }]) */
    // get previous state tagged onto new state
    .run(['$rootScope', '$state', function ($rootScope, $state) {
      $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        $state.previousState = fromState;
        $state.previousParams = fromParams;
      });
    }])
    .directive('ngReallyClick', [function () {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          element.bind('click', function () {
            var message = attrs.ngReallyMessage;
            if (message && confirm(message)) {
              scope.$apply(attrs.ngReallyClick);
            }
          });
        }
      };
    }])
    .directive('autofocus', function () {
      return {
        restrict: 'A',
        link: function (scope, element) {
          element[0].focus();
        }
      };
    })
    .directive('showfocus', function ($timeout) {
      return function (scope, element, attrs) {
        scope.$watch(attrs.showFocus,
          function (newValue) {
            $timeout(function () {
              newValue && element[0].focus();
            });
          }, true);
      };
    })
    .directive('validateSVNR', function () {
      return {
        require: 'ngModel',
        link: function (scope, element, attr, mCtrl) {
          function validateSVNR(svnr, element) {
            var nr = svnr.split('');
            var checksum = (5 * parseInt(nr[4], 10) +
              8 * parseInt(nr[5], 10) +
              4 * parseInt(nr[6], 10) +
              2 * parseInt(nr[7], 10) +
              parseInt(nr[8], 10) +
              6 * parseInt(nr[9], 10) +
              3 * parseInt(nr[0], 10) +
              7 * parseInt(nr[1], 10) +
              9 * parseInt(nr[2], 10)) % 11;

            var result = (checksum === parseInt(nr[3], 10));

            console.log(svnr, ' valid: ', result);

            if (result) {
              mCtrl.$setValidity('', true);
            } else {
              mCtrl.$setValidity('', false);
            }

            return svnr;
          }

          mCtrl.$parsers.push(validateSVNR);
        }
      };
    })
  ;

// klammerfriedhof
}());



