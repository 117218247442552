(function () {
  'use strict';

  /* @ngdoc object
   * @name config
   * @description
   *
   */
  angular
    .module('config', [
      'ui.router',
      'utils.listEditor'
    ]);
}());
