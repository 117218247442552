(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name cashinvoice.factory:Cashinvoice
   *
   * @description
   *
   */
  angular
    .module('finance.invoice')
    .factory('Cashinvoice', Cashinvoice);

  Cashinvoice.$inject = ['restmod'];

  function Cashinvoice(restmod) {
    return restmod.model('/routes/cashinvoices', 'PagedModel').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      }
    });
  }
  
}());
