(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name journal.factory:Journal
   *
   * @description
   *
   */
  angular
    .module('journal')
    .factory('Journal', Journal);

  Journal.$inject = ['restmod'];

  function Journal(restmod) {
    return restmod.model('/routes/journal').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true}
    });
  }
}());
