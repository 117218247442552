/**
 * Created by lari on 06/04/2017.
 */
(function () {
  'use strict';
  angular.module('finance.invoice')
    .component('cashinvoice', {
      restrict: 'E',
      templateUrl: 'finance/invoice/cashinvoice-component.tpl.html',
      controllerAs: 'vm',
      bindings: {
        mode: '@'
      },
      controller: ['Cashinvoice', '$log',
        function (Cashinvoice, $log) {
          var vm = this;
  
          vm.currentPage = 1;
          vm.pageChanged = pageChanged;
          vm.storno = storno;
          
          this.$onInit = function () {
            var search = {};
            
            search.page = vm.currentpage;
            search.query = {};
            
            switch (vm.mode) {
              case 'showstorno':
                search.query.storno = true;
                vm.title = 'Stornierte Barrechnungen';
                break;
              case 'hidestorno':
                search.query.storno = false;
                vm.title = 'Unstornierte Barrechnungen';
                break;
              case 'showstest':
                search.query.test = true;
                vm.title = 'Test-Barrechnungen';
                break;
              case 'hidetest':
                search.query.test = false;
                vm.title = 'Barrechnungen (ohne Testrechnungen)';
                break;
              default:
                vm.title = 'Barrechnungen';
                break;
            }
            
            search.options = {};
            search.options.fields = {
              _id: 1,
              invoicenr: 1,
              date: 1,
              fdate: 1,
              total: 1,
              tax: 1,
              storno: 1,
              test: 1,
              paytype: 1,
              patientinvoiceid: 1,
              doctorinvoiceid: 1,
              receiptid: 1,
              stornoreceiptid: 1,
              patientid: 1
            };
            

            vm.cashinvoices = Cashinvoice.$search(search).$then(function (inv) {
              vm.totalItems = inv.$pageCount;
            });
          };
          
          
          function pageChanged() {
            vm.cashinvoices.$refresh({page: vm.currentPage}).$then(function (inv) {
              vm.totalItems = inv.$pageCount;
            });
          }
  
          function storno(invoice) {
            $log.debug('storno invoice: ', invoice);
            invoice.$destroy().$then(function () {
              vm.cashinvoices.$refresh();
            });
          }
          
        }] // controller
    }); // component

// klammerfriedhof
}());