(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name examination.directive:exameditor
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="examination">
   <file name="index.html">
   <exameditor patient=""></exameditor>
   </file>
   </example>
   *
   */
  angular
    .module('examination')
    .directive('exameditor', exameditor);
  
  exameditor.$inject = ['vertxEventBusService', '$log', '$cookies', 'ConsultRefresh', '$state', '$window', 'Rlog'];

  function exameditor(vertxEventBusService, $log, $cookies, ConsultRefresh, $state, $window, Rlog) {
    return {
      restrict: 'E',
      scope: {
        patient: '='
      },
      templateUrl: 'examination/exameditor-directive.tpl.html',
      replace: false,
      controller: 'ExaminationCtrl',
      controllerAs: 'exeCtrl',
      bindToController: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        // ctrl.patient.openExam.$refresh();
        ConsultRefresh.refreshAll(ctrl.patient);

        /* // watch for consult state changes
        scope.$watch('consCtrl.patient.consultstate', function (newval, oldval) {
          $log.debug('consultstate watch');
          $log.debug('oldval: ', oldval);
          $log.debug('newval: ', newval);
          if (newval !== oldval && $state.current.name !== ('patientrecord.current.' + newval)) {
            if (newval === '') {
              $state.go('patientrecord.history');
            }
            else {
              $state.go('patientrecord.current.' + newval);
            }
          }
        });*/


        // listen for examination updates
        vertxEventBusService.on('examinations.update', function (message) {
          var messages = message.split(':');
          // $log.debug('exam update mesg: ', message);
          // $log.debug('cookie: ', $cookies.get('vertx-web.session'));
          // $log.debug('openexam: ', ctrl.patient.openExam);
          // $log.debug('openexamid: ', ctrl.patient.openExam._id);
          
          // only refresh exam if change was NOT triggered by this browser
          // if (messages[1] !== $cookies.get('vertx-web.session') && ctrl.patient.openExam._id === messages[0]) {
          if (messages[1] !== $window.sessionStorage.getItem('sprtabid') && ctrl.patient.openExam._id === messages[0]) {
            ctrl.patient.openExam.$refresh();
            $log.debug('(ecd) refreshed openexam for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
          else {
            $log.debug(' (ecd) ignored openexam change for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
        });

        /* // listen for patient updates
        vertxEventBusService.on('patients.update', function (message) {
          var messages = message.split(':');
          $log.debug('Patient updated: ', message);
          if (ctrl.patientid === messages[0]) {
            ctrl.patient.$refresh();
            $log.debug('refreshed patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
          if (ctrl.patientid !== messages[0]) {
            $log.debug('ignored patient update for: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
        });*/

        // console.log('i am the exameditor link function');
        
        // listen for changes in template fields
        scope.$on('tagadded', function (ev, args) {
          // console.log('exameditor tagadded event found: ', ev, args);
          // ctrl.patient.openExam.$save();
          // saver saving
          if (ctrl.patient.openExam.$hasPendingActions()) {
            $log.debug('openExam save has pending actions!');
            Rlog.debug('examination', 'exameditor-directive', 'openExam has pending actions!',
              {openExamId: ctrl.patient.openExam._id, examtype: ctrl.patient.openExam.template.type, patientid: ctrl.patient._id});
            ctrl.patient.openExam.$cancel();
            ctrl.patient.openExam.$save().$then(function () {
              $log.debug('exameditor oE.$save() succeeded');
            }, function () {
              $log.error('exameditor oE.$save() failed');
            });
          }
          else {
            // $log.debug('save exam instantly');
            ctrl.patient.openExam.$save().$then(function () {
              $log.debug('exameditor oE.$save() succeeded');
            }, function () {
              $log.error('exameditor oE.$save() failed');
            });
          }
          
        });

      }
    };
  }
}());
