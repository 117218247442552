(function () {
  'use strict';

  angular
    .module('doctype')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('doctype', {
        url: '/doctype',
        templateUrl: 'doctype/doctype.tpl.html',
        controller: 'DoctypeCtrl',
        controllerAs: 'doctype'
      });
  }
}());
