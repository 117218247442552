(function () {
  'use strict';
  
  /**
   * @ngdoc service
   * @name consult.factory:ConsultRefresh
   *
   * @description
   *
   */
  angular
    .module('consult')
    .factory('ConsultRefresh', ConsultRefresh);
  
  
  ConsultRefresh.$inject = ['$log', 'Consult', 'Examination', '$state', '$interval'];
  
  function ConsultRefresh($log, Consult, Examination, $state, $interval) {
    var ConsultRefreshBase = {};
    
    ConsultRefreshBase.refreshAll = refreshAll;
    ConsultRefreshBase.refreshExam = refreshExam;
    ConsultRefreshBase.refreshConsult = refreshConsult;
    
    
    function refreshExam(patient) {
      $log.debug('refreshAll->refreshExam called');
      if (angular.isFunction(patient.openExam.$refresh)) {
        patient.openExam.$refresh();
      }
      else {
        if (patient.consultstate === 'exam' || patient.consultstate === 'examfiles') {
          $log.error('Patient consultstate exam or examfiles without openExam attached: ', patient.firstname, patient.lastname, patient._id);
        }
      }
    }
    
    function refreshConsult(patient) {
      $log.debug('refreshAll->refreshConsult called');
      if (angular.isFunction(patient.openConsult.$refresh)) {
        patient.openConsult.$refresh().$asPromise().then(function (oc) {
          if (patient.openConsult.open === true) {
            if (angular.isArray(patient.openConsult.examinations) && angular.isFunction(oc.examinations.$refresh)) {
              $log.debug('refreshing examinations ');
              patient.openConsult.examinations.$refresh().$asPromise().then(function (e) {
                var i;
                for (i = 0; i < e.length; i++) {
                  $log.debug('refreshing examinations.i: ', i, e[i].type);
                  e[i].$refresh();
                }
              });
            }
          }
          else {
            $log.error('corresponding patient consult was already closed: ', patient.firstname, patient.lastname, patient._id);
            patient.inconsult = false;
            patient.incheckout = false;
            patient.consultstate = '';
            patient.checkoutstate = '';
            patient.openExam = null;
            patient.openConsult = null;
            delete patient.openConsult;
            delete patient.openExam;
            patient.$save().$asPromise().then(function (pat) {
              $log.debug(pat);
              $state.go('patientrecord.history', {patient: patient, patientid: patient._id});
            });
          }
        });
      }
      else {
        // look for open consults for this patient and attach to patient if exactly one is found
        Consult.$search({patientid: patient._id, open: true}).$asPromise().then(function (c) {
          if (angular.isArray(c) && c.length === 1 && angular.isFunction(c.$refresh)) {
            c.$refresh().$asPromise().then(function () {
              patient.openConsult = c;
              patient.$save();
            });
            
          }
          else {
            // TODO mark alle open consults as closed, as error, log error and send notification.
            $log.error('Patient inconsult without responding consult: ', patient.firstname, patient.lastname, patient._id);
            patient.inconsult = false;
            patient.incheckout = false;
            patient.consultstate = '';
            patient.checkoutstate = '';
            patient.openExam = null;
            patient.openConsult = null;
            delete patient.openConsult;
            delete patient.openExam;
            patient.$save().$asPromise().then(function (pat) {
              $log.debug(pat);
              $state.go('patientrecord.history', {patient: patient, patientid: patient._id});
            });
          }
        });
        
      }
    }
    
    function refreshAll(patient) {
      $log.debug('refreshAll called: ', patient);
      if (patient.inconsult && patient.inconsult === true) {
        $log.debug('refreshhAll: consult');
        refreshConsult(patient);
      }
      if (patient.inexam && patient.inexam === true) {
        $log.debug('refreshAll: exam');
        refreshExam(patient);
      }
    }
    
    /*
     // this is total madness, quargel!!!
     function refreshAll(patient) {
     // var success = true;
     if (angular.isFunction(patient.openExam.$refresh)) {
     patient.openExam.$refresh().$asPromise().then(function () {
     refreshConsult(patient);
     });
     }
     else {
     if (patient.consultstate === 'exam' || patient.consultstate === 'examfiles') {
     $log.error('Patient consultstate exam or examfiles without openExam attached: ', patient.firstname, patient.lastname, patient._id);
     }
     }
     }
     */
    
    return ConsultRefreshBase;
  }
}());
