<h4>Patienten-Todos</h4>
<div ng-show="ptdCtrl.patient.todos == null">Keine Todos</div>
<form class="form-vertical">
  <div class="input-group">
    <input type="text" ng-model="ptdCtrl.patient.newtodo" class="form-control" id="newlog" placeholder="Neues Todo">
    <span class="input-group-btn">
      <button class="btn btn-success" type="submit" ng-click="ptdCtrl.addTodoEntry()">
        <i class="fa fa-plus"></i>
      </button>
    </span>
  </div>
</form>
<form>
  <div class="form-group" ng-repeat="item in ptdCtrl.patient.todos | filter: {done: false} | orderBy: 'created' ">
    <label>
      <input type="checkbox"
             ng-model="item.done"
             ng-click="item.done != item.done; ptdCtrl.patient.$save()"/> {{item.label}}</label>
  </div>
</form>
