<div class="form-group">
  <div class="input-group">
        <input type="text" class="form-control" tabindex="{{svsCtrl.tabi}}"
           ng-class="{'dirtyfield': svsCtrl.asyncSelected.length > 0}"
           autofocus
           placeholder="Kürzel"
           ng-model="svsCtrl.asyncSelected"
           typeahead-on-select="$parent.$dismiss();svsCtrl.showsv($item, $model, $label)"
           uib-typeahead="sv as sv.text for sv in svsCtrl.getSvs($viewValue)"
           typeahead-loading="loadingNames"
           typeahead-no-results="noResults">
    <span class="input-group-btn">
      <button type="submit"
              ng-class="{'btn-warning': noResults, 'btn-info': !noResults}"
              class="btn" tabindex="-1"><i class="fa fa-search"></i></button>
    </span>
  </div>
</div>


<i ng-show="loadingNames" class="glyphicon glyphicon-refresh"></i>
<div ng-show="noResults">
  <i class="glyphicon glyphicon-remove"></i> Keine SV gefunden
  <span class="btn btn-success" ng-click="svsCtrl.addSv()">Neue Sozialversicherung eingeben</span>
</div>

