<form class="form-vertical" id="newdoctorform">
    <div class="row">
        <div class="form-group col-md-2"  ng-init="draCtrl.newdoctor.titlefront='Dr.'">
            <label for="titlefront">Titel</label>
            <input type="text" ng-model="draCtrl.newdoctor.titlefront" class="form-control" id="titlefront"
                   placeholder="Dr." ng-init="draCtrl.newdoctor.titlefront='Dr.'">
        </div>
        <div class="form-group col-md-4">
            <label for="firstname">Vorname</label>
            <input type="text" ng-model="draCtrl.newdoctor.firstname" class="form-control" id="firstname"
                   placeholder="Vorname">
        </div>
        <div class="form-group col-md-4">
            <label for="lastname">Nachname</label>
            <input type="text" ng-model="draCtrl.newdoctor.lastname" class="form-control" id="lastname"
                   placeholder="Nachname">
        </div>
        <div class="form-group col-md-2">
            <label for="titleback">Titel</label>
            <input type="text" ng-model="draCtrl.newdoctor.titleback" class="form-control" id="titleback"
                   placeholder="MSc">
        </div>
    </div>
    <div class="row">
        <div class="form-group col-md-12">
            <label for="institute">Institut</label>
            <input type="text" ng-model="dreCtrl.newdoctor.institute" class="form-control" id="institute"
                   placeholder="Nachname" ng-blur="dreCtrl.save()">
        </div>
    </div>
    <div class="row">
        <div class="form-group col-md-12">
            <label for="speciality">Fachgebiet</label>
            <select id="speciality" ng-model="draCtrl.newdoctor.speciality">
                <option value="Allgemeinmedizin">Allgemeinmedizin</option>
                <option value="Anästhesiologie und Intensivmedizin">Anästhesiologie und Intensivmedizin</option>
                <option value="Anatomie">Anatomie</option>
                <option value="Arbeitsmedizin">Arbeitsmedizin</option>
                <option value="Augenheilkunde und Optometrie">Augenheilkunde und Optometrie</option>
                <option value="Blutgruppenserologie und Transfusionsmedizin">Blutgruppenserologie und Transfusionsmedizin</option>
                <option value="Chirurgie">Chirurgie</option>
                <option value="Frauenheilkunde und Geburtshilfe">Frauenheilkunde und Geburtshilfe</option>
                <option value="Gerichtsmedizin">Gerichtsmedizin</option>
                <option value="Hals-, Nasen- und Ohrenkrankheiten">Hals-, Nasen- und Ohrenkrankheiten</option>
                <option value="Haut- und Geschlechtskrankheiten">Haut- und Geschlechtskrankheiten</option>
                <option value="Herzchirurgie">Herzchirurgie</option>
                <option value="Histologie und Embryologie">Histologie und Embryologie</option>
                <option value="Hygiene und Mikrobiologie">Hygiene und Mikrobiologie</option>
                <option value="Immunologie">Immunologie</option>
                <option value="Innere Medizin">Innere Medizin</option>
                <option value="Kinder- und Jugendchirurgie">Kinder- und Jugendchirurgie</option>
                <option value="Kinder- und Jugendheilkunde">Kinder- und Jugendheilkunde</option>
                <option value="Kinder- und Jugendpsychiatrie">Kinder- und Jugendpsychiatrie</option>
                <option value="Lungenkrankheiten">Lungenkrankheiten</option>
                <option value="Medizinische Biologie">Medizinische Biologie</option>
                <option value="Medizinische Biophysik">Medizinische Biophysik</option>
                <option value="Medizinische Genetik">Medizinische Genetik</option>
                <option value="Medizinische und Chemische Labordiagnostik">Medizinische und Chemische Labordiagnostik</option>
                <option value="Medizinische Leistungsphysiologie">Medizinische Leistungsphysiologie</option>
                <option value="Mikrobiologisch-Serologische Labordiagnostik">Mikrobiologisch-Serologische Labordiagnostik</option>
                <option value="Mund-, Kiefer- und Gesichtschirurgie">Mund-, Kiefer- und Gesichtschirurgie</option>
                <option value="Neurobiologie">Neurobiologie</option>
                <option value="Neurochirurgie">Neurochirurgie</option>
                <option value="Neurologie">Neurologie</option>
                <option value="Neurologie und Psychiatrie">Neurologie und Psychiatrie</option>
                <option value="Neuropathologie">Neuropathologie</option>
                <option value="Nuklearmedizin">Nuklearmedizin</option>
                <option value="Orthopädie und Orthopädische Chirurgie">Orthopädie und Orthopädische Chirurgie</option>
                <option value="Pathologie">Pathologie</option>
                <option value="Pathophysiologie">Pathophysiologie</option>
                <option value="Pharmakologie und Toxikologie">Pharmakologie und Toxikologie</option>
                <option value="Physikalische Medizin und Allgemeine Rehabilitation">Physikalische Medizin und Allgemeine Rehabilitation</option>
                <option value="Physiologie">Physiologie</option>
                <option value="Plastische, Ästhetische und Rekonstruktive Chirurgie">Plastische, Ästhetische und Rekonstruktive Chirurgie</option>
                <option value="Psychiatrie">Psychiatrie</option>
                <option value="Psychiatrie und Neurologie">Psychiatrie und Neurologie</option>
                <option value="Psychiatrie und Psychotherapeutische Medizin">Psychiatrie und Psychotherapeutische Medizin</option>
                <option value="Radiologie">Radiologie</option>
                <option value="Sozialmedizin">Sozialmedizin</option>
                <option value="Spezifische Prophylaxe und Tropenmedizin">Spezifische Prophylaxe und Tropenmedizin</option>
                <option value="Strahlentherapie-Radioonkologie">Strahlentherapie-Radioonkologie</option>
                <option value="Theoretische Sonderfächer">Theoretische Sonderfächer</option>
                <option value="Thoraxchirurgie">Thoraxchirurgie</option>
                <option value="Tumorbiologie">Tumorbiologie</option>
                <option value="Unfallchirurgie">Unfallchirurgie</option>
                <option value="Urologie">Urologie</option>
                <option value="Virologie">Virologie</option>
                <option value="Zahn-, Mund- und Kieferheilkunde">Zahn-, Mund- und Kieferheilkunde</option>
            </select>
        </div>
    </div>

    <div class="row">
        <div class="form-group col-md-3">
            <label for="phone1">Telefon 1</label>
            <input type="text" ng-model="draCtrl.newdoctor.phone1" class="form-control" id="phone1" placeholder="Telefon">
        </div>
        <div class="form-group col-md-3">
            <label for="phone2">Telefon 2</label>
            <input type="text" ng-model="draCtrl.newdoctor.phone2" class="form-control" id="phone2" placeholder="Telefon">
        </div>
        <div class="form-group col-md-3">
            <label for="fax">Fax</label>
            <input type="text" ng-model="draCtrl.newdoctor.fax" class="form-control" id="fax" placeholder="Telefon">
        </div>
        <div class="form-group col-md-3">
            <label for="email">E-Mail</label>
            <input type="text" ng-model="draCtrl.newdoctor.email" class="form-control" id="email" placeholder="E-Mail">
        </div>

    </div>

    <div class=" row">
        <div class="form-group col-md-6">
            <label for="address">Adresse</label>
            <input type="text" ng-model="draCtrl.newdoctor.address" class="form-control" id="address"
                   placeholder="Adresse">
        </div>

        <div class="form-group col-md-6">
            <zipcode zip="draCtrl.newdoctor.postcode" city="draCtrl.newdoctor.city"></zipcode>
        </div>

    </div>
    <div class=" row">
        <div class="form-group col-md-12">
            <button class="btn btn-success" type="button" ng-click="draCtrl.addDoctor()">Arzt anlegen</button>
            <button class="btn btn-danger" type="button" ng-click="$parent.$dismiss();">Abbrechen</button>
        </div>
    </div>
</form>