<div style="padding: 20px"><!-- template must be enclosed by single tag -->
  <div class="alert alert-danger"><h3><i class="fa fa-warning"></i> Wichtiger Hinweis!</h3>
    <p>
      Die hier gelisteten Medikamente wurden auf Basis des ATC Codes ausgewählt. Wenn das ursprüngliche - nicht mehr
      erstattungsfähige - Präparat mehrere Wirkstoffe beinhaltet hat, werden auch Arzneien die nur einen der Wirkstoffe
      beinhalten angezeigt. Die Alternativen varieren in der Stärke des Wirkstoffes. </p>

    <p>Prüfung der Alternativen auf ihre Einsatzmöglichkeit und ggf. Auswahl eines Ersatzpräparats obliegt AUSSCHLIESSLICH der berhandelnden Ärztin
      / dem behandelndem Arzt!</p>

   <!-- <p>
    <div class="btn btn-danger" ng-click="atcaltshow = true" ng-hide="atcaltshow">Habe ich verstanden, Alternativen jetzt anzeigen</div>
    </p>-->
</div>

    <!--<div ng-show="atcaltshow">-->
    <div>
      <div class="row" style="margin-bottom: 2px" ng-repeat="alt in pdvCtrl.newdrug.atcalternatives">
        <div ng-show="alt.box === 'G'" class="btn btn-success col-xs-12"
             uib-tooltip="SV Green Box Alternative"
             ng-click="pdvCtrl.replaceDrug(alt)"
             ng-bind="alt.name">
        </div>
        <div ng-show="alt.box === 'Y'" class="btn btn-warning col-xs-12"
             uib-tooltip="SV Yellow Box Alternative"
             ng-click="pdvCtrl.replaceDrug(alt)"
             ng-bind="alt.name">
        </div>
        <div ng-hide="alt.box" class="btn btn-danger col-xs-12"
             uib-tooltip="Nicht erstattungsfähige Alternative"
             ng-click="pdvCtrl.replaceDrug(alt)"
             ng-bind="alt.ZBEZ">
        </div>
      </div>


    </div>

  </div>


  <div class="row" style="margin-bottom: 2px" ng-repeat="alt in pdvCtrl.newdrug.alternatives">
    <div class="btn btn-{{alt.color}} col-xs-12"
         uib-tooltip="{{alt.tooltip}}"
         ng-click="pdvCtrl.replaceDrug(alt)"
         ng-bind="alt.name">
    </div>
    <!--<div class="col-xs-9" ng-bind="alt.name"></div>
    <div class="col-xs-3">
      <button class="btn btn-{{alt.color}}" uib-tooltip="{{alt.tooltip}}" ng-click="replaceDrug(alt)">
        Auswählen
      </button>
    </div>-->
  </div>
</div>