(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name dms.scan.factory:Scan
   *
   * @description
   *
   */
  angular
    .module('dms.scan')
    .factory('FileGrabberScan', FileGrabberScan)
    .factory('FileAhead', FileAhead);

  FileGrabberScan.$inject = ['restmod'];

  function FileGrabberScan(restmod) {
    return restmod.model('/routes/files/filegrabber/scan');
  }

  FileAhead.$inject = ['restmod'];

  function FileAhead(restmod) {
    return restmod.model('/routes/files/tagahead');
  }
  


  // klammerfriedhof
}());
