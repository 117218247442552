(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name closing.factory:Closing
   *
   * @description
   *
   */
  angular
    .module('finance.closing')
    .factory('Closing', Closing);

  Closing.$inject = ['restmod'];

  function Closing(restmod) {
    return restmod.model('/routes/fiscaljournal');
  }
}());
