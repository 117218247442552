(function () {
  'use strict';
  /**
   * @ngdoc object
   * @name consult.controller:ConsultCtrl
   *
   * @description
   *
   */
  angular
    .module('consult')
    .controller('ConsultCtrl', ConsultCtrl);
  
  // check/edit this
  
  ConsultCtrl.$inject = ['Consult', 'OpenConsults', 'Patient', 'Service', '$stateParams', '$state', '$log', 'Config', 'Rlog',
    '$scope', '$window', 'hotkeys', '$uibModal', 'Document', 'File', 'ConsultModalsHelper', '$timeout', 'Wlcommons', 'Waitinglist'];
  
  function ConsultCtrl(Consult, OpenConsults, Patient, Service, $stateParams, $state, $log, Config, Rlog,
                       $scope, $window, hotkeys, $uibModal, Document, File, ConsultModalsHelper, $timeout, Wlcommons, Waitinglist) {
    var vm = this;
    vm.ctrlName = 'ConsultCtrl';
    vm.Consult = Consult;
    vm.Service = Service;
    vm.conf = Config.$find('configuration');
    vm.patientid = $stateParams.patientid;
    vm.$state = $state;
    
    vm.patsave = patsave;
    
    vm.wlAddPatient = Wlcommons.addPatient;
    vm.wlRemovePatient = Wlcommons.removePatient;
    vm.startConsult = startConsult;
    
    
    vm.waitinglists = Waitinglist.$search();
    
    // vm.startCheckout = startCheckout;
    vm.getDocuments = getDocuments;
    vm.getExams = getExams;
    vm.addFile = addFile;
    vm.addImage = addImage;
    vm.editImage = editImage;
    vm.showImage = showImage;
    vm.addAideModal = ConsultModalsHelper.addAideModal;
    vm.addReferralModal = ConsultModalsHelper.addReferralModal;
    vm.switchToOpenExam = switchToOpenExam;
    // vm.switchToRecord = switchToRecord;
    vm.switchToExit = switchToExit;
  
    
    
    function patsave() {
      $log.debug('patsave called');
      // vm.patient.openConsult.$saveNow();
      if (vm.patient.openConsult.$hasPendingActions()) {
        $log.debug('openConsult has pending actions!');
        Rlog.debug('consult', 'consult-controller', 'openConsult has pending actions!',
          {openConsultid: vm.patient.openConsult._id, patientid: vm.patient._id});
        vm.patient.openConsult.$cancel();
        patsave();
      }
      else {
        vm.patient.openConsult.$save();
      }
      
    }
    
    function startConsult() {
      vm.patient.consults.$create().$asPromise().then(function (oc) {
        vm.patient.consultstate = 'consult';
        vm.patient.inconsult = true;
        vm.patient.incheckout = false;
        calculateActivedrugs(); // recalculate drug counter (might not be set on older patientrecords)
        vm.patient.prescriptiondate = moment().locale('de-at').format('ll'); // set formatted prescription dat for prescription, referrals and aides
        oc.examinations.$fetch();
        oc.open = true;
        oc.fdate = moment().locale('de-at').format('ll');
        vm.patient.openConsult = oc;
        vm.patient.openConsult.$save();
        $log.debug('openConsult: ', vm.patient.openConsult);
        
        vm.patient.$save().$asPromise().then(function () {
          $log.debug('consult started, should trigger update');
          OpenConsults.triggerUpdate();
          $log.debug('oc started, patient saved');
        });
        // TODO: nasty snow flake bug - figure out why above code won't work for rudi an needs below snowflake patch
        $timeout(OpenConsults.triggerUpdate(), 5000);
        $state.go('patientrecord.current.consult');
      });
    }

    function switchToOpenExam() {
      vm.swtespin = true;
      vm.patient.openExam.$refresh().$asPromise().then(function (e) {
        vm.swtespin = false;
        switch (vm.patient.consultstate) {
          case 'exam':
            $state.go('patientrecord.current.exam');
            break;
          case 'examfiles':
            $state.go('patientrecord.current.examfiles');
            break;
          default:
            vm.swtespin = true;
            vm.swteerror = true;
            vm.patient.$refresh().$asPromise().then(function () {
              vm.patient.openExam.$refresh().$asPromise().then(function () {
                vm.patient.openConsult.$refresh().$asPromise().then(function () {
                  vm.patientid.openConsult.examinations.$refresh();
                  vm.swtespin = false;
                  vm.swteerror = false;
                });
              });
            });
            
            break;
        }
      }); // switch
    }
    
    function calculateActivedrugs() {
      var i;
      vm.patient.activedrugs = 0;
      if (vm.patient.drugs.length && vm.patient.drugs.length > 0) {
        for (i = 0; i < vm.patient.drugs.length; i++) {
          if (vm.patient.drugs[i].active === true && vm.patient.drugs[i].foreign === false && vm.patient.drugs[i].print === true) {
            vm.patient.activedrugs += 1;
          }
        }
      }
    }

    function switchToExit() {
      $state.go('patientrecord.checkout.' + vm.patient.checkoutstate);
    }
    
    function getDocuments(cons) {
      cons.docs = Document.$search({consultid: cons._id});
    }
    
    function getExams(cons) {
      cons.examinations.$fetch();
      cons.exams = 1;
    }
    
    function addFile() {
      if (vm.newfile) {
        File.$find(vm.newfile.fileid).$asPromise().then(function (file) {
          file.patientid = vm.patient._id;
          file.consultid = vm.patient.openConsult._id;
          file.$save();
          if (!angular.isArray(vm.patient.openConsult.files)) {
            vm.patient.openConsult.files = [];
          }
          vm.patient.openConsult.files.push(file);
          vm.patient.openConsult.$save();
        });
        vm.newfile = null;
      }
    }
    
    function addImage() {
      $log.debug('addImage called');
      $log.debug('vm.newimage: ', vm.newimage);
      $log.debug('vm.newimage.fileid', vm.newimage.fileid);
      if (vm.newimage && vm.newimage.fileid) {
        File.$find(vm.newimage.fileid).$asPromise().then(function (image) {
          $log.debug('image returned: ', image);
          image.patientid = vm.patient._id;
          image.consultid = vm.patient.openConsult._id;
          image.$save();
          if (!angular.isArray(vm.patient.openConsult.images)) {
            vm.patient.openConsult.images = [];
          }
          vm.patient.openConsult.images.push(image);
          vm.patient.openConsult.$save();
        });
        vm.newimage = null;
      }
    }
    
    
    function editImage(img) {
      $uibModal.open({
        template: '<imglabeler dialog="miCtrl.dialog" img="miCtrl.img"/>',
        size: 'lg',
        controller: ['$uibModalInstance', 'image', function ($uibModalInstance, image) {
          var vmi = this;
          vmi.dialog = $uibModalInstance;
          vmi.img = image;
        }],
        controllerAs: 'miCtrl',
        resolve: {
          image: function () {
            return img;
          }
        }
      });
    }
    
    function showImage(img) {
      $uibModal.open({
        template: '<imgviewer dialog="miCtrl.dialog" img="miCtrl.img"/>',
        size: 'lg',
        controller: ['$uibModalInstance', 'image', function ($uibModalInstance, image) {
          var vmi = this;
          vmi.dialog = $uibModalInstance;
          vmi.img = image;
        }],
        controllerAs: 'miCtrl',
        resolve: {
          image: function () {
            return img;
          }
        }
      });
    }
    
    // when you bind it to the controller's scope, it will automatically unbind
    // the hotkey when the scope is destroyed (due to ng-if or something that changes the DOM)
    hotkeys.bindTo($scope)
      .add({
        combo: 'a',
        description: 'test alert',
        callback: function () {
          $window.alert('a pressed');
        }
      })
      .add({
        combo: 'alt+v',
        description: 'Hilfsmittel Verordnung',
        callback: function () {
          ConsultModalsHelper.addAideModal(vm.patient);
        }
      })
      .add({
        combo: 'alt+ü',
        description: 'Überweisung',
        callback: function () {
          ConsultModalsHelper.addReferralModal(vm.patient);
        }
      })
      .add({
        combo: 'backspace',
        description: 'Unterdrückt: Verhindert Rückwärtsspringen im Browser',
        callback: function (event, hotkey) {
          // do exactly nothing :-)
          // $window.alert('backspace pressed');
          event.preventDefault();
        }
      });
    
  }
}());
