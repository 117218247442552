(function () {
  'use strict';

  angular
    .module('service')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('services', {
        url: '/services',
        views: {
          main: {template: '<serviceeditor></serviceeditor>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });
  }


}());
