(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name reminder.factory:ReminderService
   *
   * @description
   *
   */
  angular
    .module('reminder')
    .factory('Reminder', Reminder)
    .factory('Remindersearch', Remindersearch)
    .factory('Remindercount', Remindercount)
    .factory('Reminderpaged', Reminderpaged)
    .config(config);
  
  
  config.$inject = ['restmodProvider'];
  
  function config(restmodProvider) {
    restmodProvider.rebase({
      $config: {
        primaryKey: '_id'
      }
    });
  }
  
  
  Reminder.$inject = ['restmod'];
  
  function Reminder(restmod) {
    return restmod.model('/routes/reminders').mix({
      openConsult: {hasOne: 'Patient'},
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true},
      done: {init: false}
    });
  }
  
  Remindersearch.$inject = ['restmod'];
  
  function Remindersearch(restmod) {
    return restmod.model('/routes/reminders/patahead');
  }
  
  
  Remindercount.$inject = ['restmod'];
  function Remindercount(restmod) {
    return restmod.model('/routes/reminders/count');
  }
  
  
  Reminderpaged.$inject = ['restmod'];
  function Reminderpaged(restmod) {
    return restmod.model('/routes/reminders/pagedlist');
  }
  

}());
