(function () {
  'use strict';

  angular
    .module('utils.fileuploader')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('fileuploader', {
        url: '/fileuploader',
        views: {
          main: {templateUrl: 'utils/fileuploader/fileuploader.tpl.html'}
        }
      });
  }
// klammerfriedhof
}());
