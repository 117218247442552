<h2>Umsätze</h2>

<!-- Menü / Nav pills -->
<div class="btn-group spacer-top spacer-bottom" style="align-content: center;">
  <label class="btn btn-primary" ng-model="toCtrl.view" uib-btn-radio="'summary'">Überblick</label>
  <label class="btn btn-primary" ng-model="toCtrl.view" uib-btn-radio="'cash'">Bar-Umsätze</label>
  <label class="btn btn-primary" ng-model="toCtrl.view" uib-btn-radio="'check'">Umsatz auf Rechnung</label>
</div>

<!-- --------------------------------------------------------------------------------------------------------------- -->
<!-- Summary view -->
<!-- --------------------------------------------------------------------------------------------------------------- -->

<div ng-show="toCtrl.view === 'summary'">
  
  <h3>Gesamtumsatz</h3>
  
  <table class="table table-striped">
    <tr>
      <th>Zeitraum</th>
      <th class="nrr"><span uib-tooltip="abzgl. Stornos, inkl. offene Beträge">Gesamt Netto</span></th>
      <th class="nrr"><span uib-tooltip="abzgl. Stornos, exkl. offene Beträge">Netto verfügbar</span></th>
      <th class="nrr"><span uib-tooltip="abzgl. Stornos">Gesamt Brutto</span></th>
      <th class="nrr"><span uib-tooltip="Noch nicht beglichene Rechnungen">Offen</span></th>
      <th class="nrr"><span uib-tooltip="Summiert über alle Umsatzsteuersätze">USt.</span></th>
      <th class="nrr"><span uib-tooltip="Summe stornierter Einzelrechnungen">Storno</span></th>
    
    </tr>
    <tr>
      <td>Dieses Monat</td>
      <td class="nrr">{{toCtrl.thisyear.monthcashstats.total + toCtrl.thisyear.monthcheckstats.total
        - toCtrl.thisyear.monthcashstats.tax - toCtrl.thisyear.monthcheckstats.tax | currency : '' : 2}} €
      </td>
      <td class="nrr">{{toCtrl.thisyear.monthcashstats.total + toCtrl.thisyear.monthcheckstats.total
        - toCtrl.thisyear.monthcashstats.tax - toCtrl.thisyear.monthcheckstats.tax
        - toCtrl.thisyear.monthcheckstats.open
        | currency : '' : 2}} €
      </td>
      <td class="nrr">{{toCtrl.thisyear.monthcashstats.total + toCtrl.thisyear.monthcheckstats.total | currency : '' :
        2}} €
      </td>
      <td class="nrr">{{toCtrl.thisyear.monthcheckstats.open | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.monthcashstats.tax + toCtrl.thisyear.monthcheckstats.tax | currency : '' : 2}}
        €
      </td>
      <td class="nrr">{{toCtrl.thisyear.monthcashstats.storno + toCtrl.thisyear.monthcheckstats.storno | currency : '' :
        2}} €
      </td>
    </tr>
    <tr>
      <td>Dieses Quartal</td>
      <td class="nrr">{{toCtrl.thisyear.quartercashstats.total + toCtrl.thisyear.quartercheckstats.total
        - toCtrl.thisyear.quartercashstats.tax - toCtrl.thisyear.quartercheckstats.tax | currency : '' : 2}} €
      </td>
      <td class="nrr">{{toCtrl.thisyear.quartercashstats.total + toCtrl.thisyear.quartercheckstats.total
        - toCtrl.thisyear.quartercashstats.tax - toCtrl.thisyear.quartercheckstats.tax
        - toCtrl.thisyear.quartercheckstats.open
        | currency : '' : 2}} €
      </td>
      <td class="nrr">{{toCtrl.thisyear.quartercashstats.total + toCtrl.thisyear.quartercheckstats.total | currency : ''
        : 2}} €
      </td>
      <td class="nrr">{{toCtrl.thisyear.quartercheckstats.open | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.quartercashstats.tax + toCtrl.thisyear.quartercheckstats.tax | currency : '' :
        2}} €
      </td>
      <td class="nrr">{{toCtrl.thisyear.quartercashstats.storno + toCtrl.thisyear.quartercheckstats.storno | currency :
        '' : 2}} €
      </td>
    </tr>
    <tr>
      <td>Dieses Jahr</td>
      <td class="nrr">{{toCtrl.thisyear.yearcashstats.total + toCtrl.thisyear.yearcheckstats.total
        - toCtrl.thisyear.yearcashstats.tax - toCtrl.thisyear.yearcheckstats.tax | currency : '' : 2}} €
      </td>
      <td class="nrr">{{toCtrl.thisyear.yearcashstats.total + toCtrl.thisyear.yearcheckstats.total
        - toCtrl.thisyear.yearcashstats.tax - toCtrl.thisyear.yearcheckstats.tax
        - toCtrl.thisyear.yearcheckstats.open
        | currency : '' : 2}} €
      </td>
      <td class="nrr">{{toCtrl.thisyear.yearcashstats.total + toCtrl.thisyear.yearcheckstats.total | currency : '' : 2}}
        €
      </td>
      <td class="nrr">{{toCtrl.thisyear.yearcheckstats.open | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.yearcashstats.tax + toCtrl.thisyear.yearcheckstats.tax | currency : '' : 2}} €
      </td>
      <td class="nrr">{{toCtrl.thisyear.yearcashstats.storno + toCtrl.thisyear.yearcheckstats.storno | currency : '' :
        2}} €
      </td>
    </tr>
  </table>
  
  
  <h3>Barumsätze</h3>
  
  <table class="table table-striped">
    <tr>
      <th>Zeitraum</th>
      <th class="nrr"><span uib-tooltip="abzgl. Stornos, inkl. offene Beträge">Gesamt Netto</span></th>
      <th class="nrr"><span uib-tooltip="abzgl. Stornos">Gesamt Brutto</span></th>
      <th class="nrr"><span uib-tooltip="Umsätze durch Bargeld">Bargeld</span></th>
      <th class="nrr"><span uib-tooltip="Umsätze durch Bankomat oder Kreditkarten">Karte</span></th>
      <th class="nrr"><span uib-tooltip="Summiert über alle Umsatzsteuersätze">USt.</span></th>
      <th class="nrr"><span uib-tooltip="Summe stornierter Einzelrechnungen">Storno</span></th>
      <th class="nrr" uib-tooltip="Rechnung/Storno, Durchschnitt (+- Standardabweichung)">Statistik</th>
    </tr>
    <tr>
      <td>Dieses Monat</td>
      <td class="nrr">{{toCtrl.thisyear.monthcashstats.total - toCtrl.thisyear.monthcashstats.tax | currency : '' : 2}}
        €
      </td>
      <td class="nrr">{{toCtrl.thisyear.monthcashstats.total | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.monthcashstats.cash | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.monthcashstats.card | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.monthcashstats.tax | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.monthcashstats.storno | currency : '' : 2}} €</td>
      <td class="nrr" uib-tooltip="Rechnung/Storno, Durchschnitt (+- Standardabweichung)">
        <span class="text-success">{{toCtrl.thisyear.monthcashstats.totalcount - toCtrl.thisyear.monthcashstats.stornocount}}</span>/
        <span class="text-danger">{{toCtrl.thisyear.monthcashstats.stornocount}}</span>,
        {{toCtrl.thisyear.monthcashstats.avg | currency : '' : 2}} € ({{toCtrl.thisyear.monthcashstats.stddev | currency
        : '' : 2}} €) </span>,
      </td>
    </tr>
    <tr>
      <td>Dieses Quartal</td>
      <td class="nrr">{{toCtrl.thisyear.quartercashstats.total - toCtrl.thisyear.quartercashstats.tax | currency : '' :
        2}} €
      </td>
      <td class="nrr">{{toCtrl.thisyear.quartercashstats.total | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.quartercashstats.cash | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.quartercashstats.card | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.quartercashstats.tax | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.quartercashstats.storno | currency : '' : 2}} €</td>
      <td class="nrr" uib-tooltip="Rechnung/Storno, Durchschnitt (+- Standardabweichung)">
        <span class="text-success">{{toCtrl.thisyear.quartercashstats.totalcount - toCtrl.thisyear.quartercashstats.stornocount}}</span>/
        <span class="text-danger">{{toCtrl.thisyear.quartercashstats.stornocount}}</span>,
        {{toCtrl.thisyear.quartercashstats.avg | currency : '' : 2}} € ({{toCtrl.thisyear.quartercashstats.stddev |
        currency : '' : 2}} €) </span>,
      </td>
    </tr>
    <tr>
      <td>Dieses Jahr</td>
      <td class="nrr">{{toCtrl.thisyear.yearcashstats.total - toCtrl.thisyear.yearcashstats.tax | currency : '' : 2}}
        €
      </td>
      <td class="nrr">{{toCtrl.thisyear.yearcashstats.total | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.yearcashstats.cash | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.yearcashstats.card | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.yearcashstats.tax | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.yearcashstats.storno | currency : '' : 2}} €</td>
      <td class="nrr" uib-tooltip="Rechnung/Storno, Durchschnitt (+- Standardabweichung)">
        <span class="text-success">{{toCtrl.thisyear.yearcashstats.totalcount - toCtrl.thisyear.yearcashstats.stornocount}}</span>/
        <span class="text-danger">{{toCtrl.thisyear.yearcashstats.stornocount}}</span>,
        {{toCtrl.thisyear.yearcashstats.avg | currency : '' : 2}} € ({{toCtrl.thisyear.yearcashstats.stddev | currency :
        '' : 2}} €) </span>,
      </td>
    </tr>
  </table>
  
  
  <h3>Umsatz auf Rechnung</h3>
  
  <table class="table table-striped">
    <tr>
      <th>Zeitraum</th>
      <th class="nrr"><span uib-tooltip="abzgl. Stornos, inkl. offene Beträge">Gesamt Netto</span></th>
      <th class="nrr"><span uib-tooltip="abzgl. Stornos, exkl. offene Beträge">Netto verfügbar</span></th>
      <th class="nrr"><span uib-tooltip="abzgl. Stornos">Gesamt Brutto</span></th>
      <th class="nrr"><span uib-tooltip="Bereits beglichene Rechnungen">Bezahlt</span></th>
      <th class="nrr"><span uib-tooltip="Noch nicht beglichene Rechnungen">Offen</span></th>
      <th class="nrr"><span uib-tooltip="Summiert über alle Umsatzsteuersätze">USt.</span></th>
      <th class="nrr"><span uib-tooltip="Summe stornierter Einzelrechnungen">Storno</span></th>
      <th class="nrr" uib-tooltip="Rechnung/Storno, Durchschnitt (+- Standardabweichung)">Statistik</th>
    </tr>
    <tr>
      <td>Dieses Monat</td>
      <td class="nrr">{{toCtrl.thisyear.monthcheckstats.total - toCtrl.thisyear.monthcheckstats.tax -
        toCtrl.thisyear.monthcheckstats.storno| currency : '' : 2}} €
      </td>
      <td class="nrr">{{toCtrl.thisyear.monthcheckstats.total - toCtrl.thisyear.monthcheckstats.tax -
        toCtrl.thisyear.monthcheckstats.open | currency : '' : 2}} €
      </td>
      <td class="nrr">{{toCtrl.thisyear.monthcheckstats.total| currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.monthcheckstats.paid | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.monthcheckstats.open | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.monthcheckstats.tax | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.monthcheckstats.storno | currency : '' : 2}} €</td>
      <td class="nrr">
        <span class="text-success">{{toCtrl.thisyear.monthcheckstats.totalcount - toCtrl.thisyear.monthcheckstats.stornocount}}</span>/<span
          class="text-danger">{{toCtrl.thisyear.monthcheckstats.stornocount}}</span>,
        {{toCtrl.thisyear.monthcheckstats.avg | currency : '' : 2}} € ({{toCtrl.thisyear.monthcheckstats.stddev |
        currency : '' : 2}} €)</span>,
      </td>
    </tr>
    <tr>
      <td>Dieses Quartal</td>
      <td class="nrr">{{toCtrl.thisyear.quartercheckstats.total - toCtrl.thisyear.quartercheckstats.tax | currency : ''
        : 2}} €
      </td>
      <td class="nrr">{{toCtrl.thisyear.quartercheckstats.total - toCtrl.thisyear.quartercheckstats.tax -
        toCtrl.thisyear.quartercheckstats.open | currency : '' : 2}} €
      </td>
      <td class="nrr">{{toCtrl.thisyear.quartercheckstats.total| currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.quartercheckstats.paid | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.quartercheckstats.open | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.quartercheckstats.tax | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.quartercheckstats.storno | currency : '' : 2}} €</td>
      <td class="nrr">
        <span class="text-success">{{toCtrl.thisyear.quartercheckstats.totalcount - toCtrl.thisyear.quartercheckstats.stornocount}}</span>/<span
          class="text-danger">{{toCtrl.thisyear.quartercheckstats.stornocount}}</span>,
        {{toCtrl.thisyear.quartercheckstats.avg | currency : '' : 2}} € ({{toCtrl.thisyear.quartercheckstats.stddev |
        currency : '' : 2}} €)</span>,
      </td>
    </tr>
    <tr>
      <td>Dieses Jahr</td>
      <td class="nrr">{{toCtrl.thisyear.yearcheckstats.total - toCtrl.thisyear.yearcheckstats.tax | currency : '' : 2}}
        €
      </td>
      <td class="nrr">{{toCtrl.thisyear.yearcheckstats.total - toCtrl.thisyear.yearcheckstats.tax -
        toCtrl.thisyear.yearcheckstats.open | currency : '' : 2}} €
      </td>
      <td class="nrr">{{toCtrl.thisyear.yearcheckstats.total| currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.yearcheckstats.paid | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.yearcheckstats.open | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.yearcheckstats.tax | currency : '' : 2}} €</td>
      <td class="nrr">{{toCtrl.thisyear.yearcheckstats.storno | currency : '' : 2}} €</td>
      <td class="nrr">
        <span class="text-success">{{toCtrl.thisyear.yearcheckstats.totalcount - toCtrl.thisyear.yearcheckstats.stornocount}}</span>/<span
          class="text-danger">{{toCtrl.thisyear.yearcheckstats.stornocount}}</span>,
        {{toCtrl.thisyear.yearcheckstats.avg | currency : '' : 2}} € ({{toCtrl.thisyear.yearcheckstats.stddev | currency
        : '' : 2}} €)</span>,
      </td>
    </tr>
  </table>


</div>


<!-- --------------------------------------------------------------------------------------------------------------- -->
<!-- Cash View -->
<!-- --------------------------------------------------------------------------------------------------------------- -->
<div ng-show="toCtrl.view === 'cash'">
  <h3>Barumsätze</h3>
  
  <!-- per year -->
  <h4>Jahresübersicht</h4>
  <table class="table table-striped">
    <tr>
      <th class="nrr">Jahr</th>
      <th class="nrr">Gesamt</th>
      <th class="nrr">Bar</th>
      <th class="nrr">Karte</th>
      <th class="nrr">Ust.</th>
      <th class="nrr" uib-tooltip="Rechnung/Storno, Durchschnitt (+- Standardabweichung)">Statistik</th>
    </tr>
    <tr ng-repeat="line in toCtrl.yearlycashstats">
      <td class="nrr">{{ line._id.year}}</td>
      <td class="nrr">{{ line.total | currency : '' : 2}} €</td>
      <td class="nrr">{{ line.cash | currency : '' : 2}} €</td>
      <td class="nrr">{{ line.card | currency : '' : 2}} €</td>
      <td class="nrr">{{ line.tax | currency : '' : 2}} €</td>
      <td class="nrr" uib-tooltip="Gesamtzahl/Stornozahl, Durchschnittl. Rechnungshöhe">
        <span class="text-success" ng-bind="line.invcount"></span>/
        <span class="text-danger" ng-bind="line.stornocount"></span>&nbsp;
        <span class="text-primary">{{ line.avg | currency : '' : 2}} €</span>&nbsp;
      </td>
    </tr>
  </table>
  <!-- per month -->
  <h4>Monatsübersicht</h4>
  <table class="table table-striped">
    <tr>
      <th class="nrr">Monat</th>
      <th class="nrr">Gesamt</th>
      <th class="nrr">Bar</th>
      <th class="nrr">Karte</th>
      <th class="nrr">Ust.</th>
      <th class="nrr" uib-tooltip="Rechnung/Storno, Durchschnitt (+- Standardabweichung)">Statistik</th>
    </tr>
    <tr ng-repeat="line in toCtrl.monthlycashstats">
      <td class="nrr">{{ line._id.month}}/{{ line._id.year}}</td>
      <td class="nrr">{{ line.total | currency : '' : 2}} €</td>
      <td class="nrr">{{ line.cash | currency : '' : 2}} €</td>
      <td class="nrr">{{ line.card | currency : '' : 2}} €</td>
      <td class="nrr">{{ line.tax | currency : '' : 2}} €</td>
      <td class="nrr" uib-tooltip="Gesamtzahl/Stornozahl, Durchschnittl. Rechnungshöhe">
        <span class="text-success" ng-bind="line.invcount"></span>/
        <span class="text-danger" ng-bind="line.stornocount"></span>&nbsp;
        <span class="text-primary">{{ line.avg | currency : '' : 2}} €</span>&nbsp;
      </td>
    </tr>
  </table>
  
</div>

<!-- --------------------------------------------------------------------------------------------------------------- -->
<!-- Check View -->
<!-- --------------------------------------------------------------------------------------------------------------- -->
<div ng-show="toCtrl.view === 'check'">
  <h3>Umsatz auf Rechnung</h3>
  
  
  <!-- per year -->
  <h4>Jahresübersicht</h4>
  <table class="table table-striped">
    <tr>
      <th class="nrr">Jahr</th>
      <th class="nrr">Gesamt</th>
      <th class="nrr">Ust.</th>
      <th class="nrr">Bezahlt</th>
      <th class="nrr">Offen</th>
      <th class="nrr">Stats</th>
    </tr>
    <tr ng-repeat="line in toCtrl.yearlycheckstats">
      <td class="nrr">{{ line._id.year}}</td>
      <td class="nrr">{{ line.total | currency : '' : 2}} €</td>
      <td class="nrr">{{ line.tax | currency : '' : 2}} €</td>
      <td class="nrr">{{ line.paid | currency : '' : 2}} €</td>
      <td class="nrr">{{ line.open | currency : '' : 2}} €</td>
      <td class="nrr" uib-tooltip="Gesamtzahl/Stornozahl, Durchschnittl. Rechnungshöhe">
        <span class="text-success" ng-bind="line.invcount"></span>/
        <span class="text-danger" ng-bind="line.stornocount"></span>&nbsp;
        <span class="text-primary">{{ line.avg | currency : '' : 2}} €</span>&nbsp;
      </td>
    </tr>
  </table>
  
  <!-- per month -->
  <h4>Monatsübersicht</h4>
  <table class="table table-striped">
    <tr>
      <th class="nrr">Monat</th>
      <th class="nrr">Gesamt</th>
      <th class="nrr">Ust.</th>
      <th class="nrr">Bezahlt</th>
      <th class="nrr">Offen</th>
      <th class="nrr">Stats</th>
    </tr>
    <tr ng-repeat="line in toCtrl.monthlycheckstats">
      <td class="nrr">{{ line._id.month}}/{{ line._id.year}}</td>
      <td class="nrr">{{ line.total | currency : '' : 2}} €</td>
      <td class="nrr">{{ line.tax | currency : '' : 2}} €</td>
      <td class="nrr">{{ line.paid | currency : '' : 2}} €</td>
      <td class="nrr">{{ line.open | currency : '' : 2}} €</td>
      <td class="nrr" uib-tooltip="Gesamtzahl/Stornozahl, Durchschnittl. Rechnungshöhe">
        <span class="text-success" ng-bind="line.invcount"></span>/
        <span class="text-danger" ng-bind="line.stornocount"></span>&nbsp;
        <span class="text-primary">{{ line.avg | currency : '' : 2}} €</span>&nbsp;
      </td>
    </tr>
  </table>
</div>