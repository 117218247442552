(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name drug.directive:sprtimeline
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="drug">
       <file name="index.html">
        <sprtimeline></sprtimeline>
       </file>
     </example>
   *
   */
  angular
    .module('drug')
    .directive('sprtimeline', sprtimeline);
  
  
  sprtimeline.$inject = ['$log']
  
  function sprtimeline($log) {
    return {
      restrict: 'E',
      scope: {
        data: '=',
        options: '=',
        groups: '=',
        events: '='
      },
      templateUrl: 'drug/sprtimeline-directive.tpl.html',
      replace: false,
      controllerAs: 'sprtimeline',
      bindToController: false,
      controller: function () {
        var vm = this;
        vm.name = 'sprtimeline';
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
  
        var timelineEvents = [
          'rangechange',
          'rangechanged',
          'timechange',
          'timechanged',
          'select',
          'doubleClick',
          'click',
          'contextmenu'
        ];
  
        // Declare the timeline
        var timeline = null;
  
        scope.$watch('data', function (data) {
          
          console.log('watched data: ', data);
          console.log('watched scope: ', scope);
          
          // Sanity check
          if (data === null) {
            return;
          }
          
          if (typeof data === 'undefined') {
            return;
          }
          
          console.log('vis scope: ', scope);
          console.log('data : ', data);
          
          // If we've actually changed the data set, then recreate the graph
          // We can always update the data by adding more data to the existing data set
          if (timeline !== null) {
            timeline.destroy();
          }
    
          // Create the timeline object
          timeline = new vis.Timeline(element[0], data.items, scope.groups, scope.options);
    
          // Attach an event handler if defined
          angular.forEach(scope.events, function (callback, event) {
            if (timelineEvents.indexOf(String(event)) >= 0) {
              timeline.on(event, callback);
            }
          });
    
          // onLoad callback
          $log.debug('scope.events.onload: ', scope.events.onload);
          
          if (scope.events !== null && scope.events.onload !== null && angular.isFunction(scope.events.onload)) {
            scope.events.onload(timeline);
          }
        });
  
        scope.$watchCollection('options', function (options) {
          if (timeline === null) {
            return;
          }
          timeline.setOptions(options);
        });
        
      }
    };
  }
}());
