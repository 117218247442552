(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name consult.directive:openConsults
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="consult">
   <file name="index.html">
   <current-consult patient="patCtrl.patient"></current-consult>
   </file>
   </example>
   *
   */
  angular
    .module('consult')
    .directive('currentConsult', currentConsult);
  
  currentConsult.$inject = ['$log', 'vertxEventBusService', '$timeout', '$cookies', 'ConsultRefresh', '$state', '$window', '$interval'];
  
  function currentConsult($log, vertxEventBusService, $timeout, $cookies, ConsultRefresh, $state, $window, $interval) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'consult/current-consult-directive.tpl.html',
      replace: false,
      controller: 'ConsultCtrl',
      controllerAs: 'consCtrl',
      bindToController: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        
        ConsultRefresh.refreshAll(ctrl.patient);
        
        /*        var consultInterval;
         consultInterval = $interval(function () {
         $log.debug('Consult interval function called');
         if (angular.isObject(ctrl.patient) && angular.isObject(ctrl.patient.openConsult)) {
         // ConsultRefresh.refreshConsult(ctrl.patient);
         ctrl.patient.openConsult.$refresh();
         $interval.cancel(consultInterval);
         }
         }, 500);*/
        
        
        // watch for fileuploads and image captures
        scope.$watch('consCtrl.newfile', function () {
          ctrl.addFile();
        }, true);
        
        scope.$watch('consCtrl.newimage', function () {
          if (angular.isObject(ctrl.newimage)) {
            $log.debug('consCtrl.newimage watch triggered: ', ctrl.newimage);
            ctrl.addImage();
          }
          else {
            $log.debug('empty consCtrl.newimage');
          }
          // console.log('consCtrl.newimage watch triggered');
        }, true);
        
        
        // watch image label and include updates, debounce saving for 5 seconds
        scope.$watch('consCtrl.patient.openConsult.images', function (newval, oldval) {
          console.log('newval: ', newval);
          if (angular.isFunction(ctrl.imgtimeout)) {
            $log.debug('imgtimeout was allready running');
            $timeout.cancel(ctrl.imgtimeout);
          }
          ctrl.imgtimeout = $timeout(function () {
            $log.debug('setting imgtimeout to 5 sec');
            ctrl.patient.openConsult.$save();
          }, 5000);
        }, true);
        
        
        // moved to CurrentConsultContainer
        /* // watch patient new diagnoses
         scope.$watch('consCtrl.patient.diagnoses', function (newd, oldd) {
         if (newd.length > oldd.length) {
         ctrl.patient.openConsult.keywords.push(newd[newd.length - 1]);
         ctrl.patient.$save();
         ctrl.patient.openConsult.$save();
         }
         }, true);*/
        
        
        // watch for consultstatechanges
        // didnt wor as exptected
        /* scope.$watch('consCtrl.patient.inexam', function (newval, oldval) {
         $log.debug('inexam watch');
         $log.debug('oldval: ', oldval);
         $log.debug('newval: ', newval);
         if (newval !== oldval && newval === false) {
         ctrl.patient.openExam.$refresh();
         ctrl.patient.openConsult.$refresh().$asPromise().then(function (c) {
         ctrl.patient.openConsult.examinations.$refresh();
         });
         }
         });
         */
        // watch for consultstatechanges
        scope.$watch('consCtrl.patient.consultstate', function (newval, oldval) {
          $log.debug('consultstate watch');
          $log.debug('oldval: ', oldval);
          $log.debug('newval: ', newval);
          if (newval !== oldval && $state.current.name !== ('patientrecord.current.' + newval)) {
            switch (newval) {
              case 'exam':
                ctrl.patient.openExam.$refresh();
                break;
              case 'examfiles':
                ctrl.patient.openExam.$refresh();
                ctrl.patient.openConsult.$refresh().$asPromise().then(function (c) {
                  ctrl.patient.openConsult.examinations.$refresh();
                });
                break;
              case 'consult':
                ctrl.patient.openExam.$refresh();
                ctrl.patient.openConsult.$refresh().$asPromise().then(function (c) {
                  ctrl.patient.openConsult.examinations.$refresh();
                });
                break;
              default:
                ctrl.patient.$refresh();
                ctrl.patient.openExam.$refresh();
                ctrl.patient.openConsult.$refresh().$asPromise().then(function (c) {
                  ctrl.patient.openConsult.examinations.$refresh();
                });
                break;
            }
          }
        });
        
        // listen for openconsult updates
        vertxEventBusService.on('consults.update', function (message) {
          var messages = message.split(':');
          console.log('Consult updated: ', messages[0]);
          // if (messages[1] !== $cookies.get('vertx-web.session') && ctrl.patient.openConsult._id === messages[0]) {
          if (messages[1] !== $window.sessionStorage.getItem('sprtabid') && ctrl.patient.openConsult._id === messages[0]) {
            ctrl.patient.openConsult.$refresh().$asPromise().then(function () {
              ctrl.patient.openConsult.examinations.$refresh();
            });
            $log.debug(' (ccd) refreshed because tabid openconsult for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
          else {
            $log.debug(' (ccd) ignored openconsult change for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
            $log.debug(' (ccd) ignored because tabid openconsult change  for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
        });
        
        
        // listen for examination updates in openConsult.examinations array
        // this seems to be pure nonsense
        /*        vertxEventBusService.on('examinations.update', function (message) {
         var i;
         var messages = message.split(':');
         $log.debug('exam update mesg: ', message);
         $log.debug('cookie: ', $cookies.get('vertx-web.session'));
         $log.debug('openexam: ', ctrl.patient.openExam);
         $log.debug('openexamid: ', ctrl.patient.openExam._id);
         // the current consult container always has to react to every exam update pertaining to patient, even those caused by himself
         if (messages[1] !== $cookies.get('vertx-web.session')) {
         for (i = 0; i < ctrl.patient.openConsult.examinations.length; i++) {
         if (ctrl.patient.openConsult.examinations[i]._id === messages[0]) {
         ctrl.patient.openConsult.examinations[i].$refresh();
         $log.debug('(ccd) refreshed closed exam for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
         }
         }
         }
         else {
         $log.debug(' (ccd) ignored closed exam change for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
         }*/
  
        // listen for examination updates in openConsult.examinations array
        // only refresh examinations array if exam in question belongs to current consult, is closed and did not originate from this tab
        vertxEventBusService.on('examinations.update', function (message) {
  
          var i;
          var messages = message.split(':');
          // only react on foreign change!
          if (messages[1] !== $window.sessionStorage.getItem('sprtabid')) {
            $log.debug('exam update mesg: ', message);
            $log.debug('cookie: ', $cookies.get('vertx-web.session'));
            $log.debug('openexam _id, consultid, state: ', ctrl.patient.openExam._id, ctrl.patient.openExam.consultid, ctrl.patient.openExam.open, ctrl.patient.openExam);
            $log.debug('openconsultid: ', ctrl.patient.openConsult._id);
            // only changes of exams belonging to this consult
            // Oups, can't check for that! TODO: backend improve exam update message
            for (i = 0; i < ctrl.patient.openConsult.examinations.length; i++) {
              if (ctrl.patient.openConsult.examinations[i]._id === messages[0]) {
                ctrl.patient.openConsult.examinations[i].$refresh();
                $log.debug('(ccd) refreshed consult exams list for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
              }
            }
          }
          else {
            $log.debug(' (ccd) ignored closed exam change for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
          
          /* if (ctrl.patient.openExam._id === messages[0]) {
           ctrl.patient.openExam.$refresh().$asPromise().then(function () {
           if (ctrl.patient.openExam.open === false) {
           ctrl.patient.openConsult.$refresh().$asPromise().then(function () {
           ctrl.patient.openConsult.examinations.$refresh();
           $log.debug('refreshed examinations  on open exam close');
           });
           }
           });
           $log.debug('(ccd) refreshed openexam for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
           }
           else {
           $log.debug('(ccd) ignored openexam change for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
           } */
        });
        
      }
    };
  }
}());
