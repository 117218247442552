<!-- This is a very important hack: DO NOT REMOVE this totally pointless invisible bindings -->
<!-- Removal of these bindings will cause exam and consult close detection to fail -->
<span style="visibility: hidden">Consult Status patient: <span ng-bind='exeCtrl.patient.inconsult'></span> </span>
<span style="visibility: hidden">Consult Status openConsult: <span
    ng-bind='exeCtrl.patient.openConsult.open'></span> </span>
<span style="visibility: hidden">Exam Status patient: <span ng-bind='exeCtrl.patient.inexam'></span> </span>
<span style="visibility: hidden">Exam Status openExam: <span ng-bind='exeCtrl.patient.openExam.open'></span> </span>
<span style="visibility: hidden">openExam type: <span
    ng-bind='exeCtrl.patient.openExam.template.displayname'></span> </span>
<span style="visibility: hidden">Consultstate: <span ng-bind='exeCtrl.patient.consultstate'></span> </span>
<span style="visibility: hidden">Checkoutstate: <span ng-bind='exeCtrl.patient.checkoutstate'></span> </span>


<!-- not in consult -->
<div ng-hide="exeCtrl.patient.inconsult === true && exeCtrl.patient.openConsult.open === true">
  <div class="alert alert-info">
    <h4>Die Konsultation wurde von anderer Stelle beendet</h4>
    <span class="btn btn-primary" ng-click="exeCtrl.$state.go('patientrecord.history')">Zur Patienten-Historie wechseln</span>
  </div>
</div>

<!-- not in exam -->
<div ng-hide="exeCtrl.patient.inexam === true && exeCtrl.patient.openExam.open === true">
  <div class="alert alert-info">
    <h4>Die Untersuchung wurde von anderer Stelle beendet</h4>
    <span class="btn btn-primary" ng-click="exeCtrl.$state.go('patientrecord.current.consult')">Zur Konsultation wechseln</span>
  </div>
</div>

<!-- in consult, already in checkout-->
<div
    ng-show="exeCtrl.patient.inconsult === true && exeCtrl.patient.openConsult.open === true && exeCtrl.patient.incheckout === true">
  <div class="alert alert-info">
    <h4>Diese Konsultation wird gerade abgeschlossen</h4>
    <span class="btn btn-primary" ng-click="exeCtrl.$state.go('patientrecord.checkout.' + exeCtrl.patient.checkoutstate)">
      Zum Konsultationsabschluß wechseln
    </span>
  </div>
</div>

<!-- Daten/bilderimport  -->
<div ng-show="exeCtrl.patient.inconsult === true && exeCtrl.patient.openConsult.open === true && exeCtrl.patient.incheckout !== true &&
     exeCtrl.patient.inexam === true && exeCtrl.patient.openExam.open === true &&
     exeCtrl.$state.current.name !== 'patientrecord.current.' + exeCtrl.patient.consultstate">
  <div class="alert alert-info">
    <h4>Diese Untersuchung lädt gerade Bilder/Daten</h4>
    <h4>Beenden Sie den Datenimport dort wo Sie ihn begonnen haben</h4>
   <!-- Does not work -> images are duplicated, other browser is not resresehd -->
    <!-- <span class="btn btn-primary" ng-click="exeCtrl.$state.go('patientrecord.current.' + exeCtrl.patient.consultstate)">
      Zum Daten/Bilderimport wechseln
    </span>-->
    <span class="btn btn-primary" ng-click="exeCtrl.$state.go('patientrecord.current.consult')">
      Zur Konsultation wechseln
    </span>
  </div>
</div>


<!-- In consult, inexam, not already in checkout, -> correct exam-consultstate -->
<div ng-show="exeCtrl.patient.inconsult === true && exeCtrl.patient.openConsult.open === true && exeCtrl.patient.incheckout !== true &&
exeCtrl.patient.inexam === true && exeCtrl.patient.openExam.open === true &&
exeCtrl.$state.current.name === 'patientrecord.current.' + exeCtrl.patient.consultstate">

  <form name="exameditorform">
    <h3 ng-bind="exeCtrl.patient.openExam.template.displayname"></h3>
    
    <div ng-hide="exeCtrl.patient.openExam.open === true">
      <div class="alert alert-info"><h4>Die Untersuchung wurde mittlerweile von anderer Stelle geschlossen</h4>
        <span ng-show="exeCtrl.patient.inconsult === true" ng-click="exeCtrl.backToConsult()" class="btn btn-primary">Zur Konsultation wechseln</span>
        <span ng-show="exeCtrl.patient.inconsult === false" ng-click="exeCtrl.backToRecord()" class="btn btn-primary">Zur Patientenakte wechseln</span>
      </div>
    </div>
    
    <!-- This is a very important hack: DO NOT REMOVE this totally pointless invisible heading -->
    <!-- Removal of this heading will cause exam close detection to fail -->
    <h4 style="visibility: hidden">Status: <span ng-bind='exeCtrl.patient.openExam.open'></span></h4>
    
    <div ng-show="exeCtrl.patient.openExam.open === true">
      <!-- fieldgroups -->
      <div ng-repeat="fieldgroup in exeCtrl.patient.openExam.template.fieldgroups">
        <fieldgroup efieldgroup="fieldgroup" gi="{{$index}}"></fieldgroup>
      </div>
      
      
      <!-- Biopsy fields -->
      <!-- biopsy format in db
          "biopsy" : {
            "label" : "Biopsien",
            "values" : [
              {
                "name" : "Magenpförtner"
              },
              {
                "name" : "Magenrückwand"
              }
            ],
            "biopsies" : [
              {
                "label" : "",
                "avalues" : "",
                "values" : ""
              }
            ]
          }
      -->
      
      
      <!-- Biopsy fields -->
      <div ng-show="exeCtrl.patient.openExam.template.biopsy" class="panel panel-default">
        <div class="panel-heading">Biopsien</div>
        <div class="panel-body">
          
          <label for="patho">Labor/Pathologie</label>
          <!--<doctor-search doctorresult="exeCtrl.pathology" ti="900" id="patho"></doctor-search>-->
  
          <div class="form-group" ng-hide="exeCtrl.pathology._id">
            <doctor-search doctorresult="exeCtrl.pathology" ti="900" id="patho"></doctor-search>
          </div>
          <div class="form-group" ng-show="exeCtrl.pathology._id">
            <div class="input-group">
              <input type="text" class="form-control"
                     readonly
                     ng-model="exeCtrl.pathology.fname">
              <span class="input-group-btn">
                    <button class="btn btn-danger"
                            ng-click="exeCtrl.clearRefDoctor()"><i class="fa fa-remove"></i>
                    </button>
        </span>
            </div>
          </div>
  
  
  
  
          <label for="pathdiag">Diagnose/Begründung</label>
          <input type="text" tabindex="901" id="pathdiag" class="form-control" ng-model="exeCtrl.pathdiag">
          
          <label for="pathcomment">Kommentar/Anweisung</label>
          <textarea tabindex="901" id="pathcomment" class="form-control" ng-model="exeCtrl.pathcomment"></textarea>
          
          <bfield efield="bf" bvalues="exeCtrl.patient.openExam.template.biopsy.values"
                  ng-init="bf.label  = 'PE ' + ($index + 1) + ' ' + exeCtrl.patient.openExam.template.type"
                  ng-repeat="bf in exeCtrl.patient.openExam.template.biopsy.biopsies">
          </bfield>
          <div class="form-group">
            <button type="submit" class="btn btn-success"
                    ng-click="exeCtrl.addBiopsyField()">
              <i class="fa fa-plus"></i> Biopsiefeld hinzufügen
            </button>
            <button type="submit" class="btn btn-primary" ng-class="{'btn-success': exeCtrl.labelsprinted}"
                    ng-click="exeCtrl.printBiopsyLabels()">
              <span ng-show="!exeCtrl.labelsprinted"><i class="fa fa-print"></i>&nbsp;Etiketten drucken</span>
              <span ng-show="exeCtrl.labelsprinted"><i class="fa fa-check"></i>&nbsp;Etiketten gedruckt</span>
            </button>
            <!-- <a class="btn btn-success"
                href="/routes/pdfprinter/examprint?patientid={{exeCtrl.patient._id}}&examid={{exeCtrl.patient.openExam._id}}&doctype=labels">
               <i class="fa fa-print"></i> Etiketten drucken</a>-->
            <a class="btn btn-info" target="_blank"
               href="/routes/pdfprinter/exampreview?patientid={{exeCtrl.patient._id}}&examid={{exeCtrl.patient.openExam._id}}&doctype=labels">
              <i class="fa fa-eye"></i> Etiketten als PDF</a>
            <!-- <div ng-show="exeCtrl.patient.openExam.template.biopsy.labelspdf" class="btn btn-success">
               <i class="fa fa-file-pdf-o"></i> Etiketten als PDF
             </div>-->
          </div>
        </div>
      
      </div>
      
      
      <!-- special fields -->
      <field efield="exeCtrl.patient.openExam.template.diagnosis"
             ng-show="exeCtrl.patient.openExam.template.diagnosis"></field>
      <field efield="exeCtrl.patient.openExam.template.recommendation"
             ng-show="exeCtrl.patient.openExam.template.recommendation"></field>
      <field efield="exeCtrl.patient.openExam.template.comment"
             ng-show="exeCtrl.patient.openExam.template.comment"></field>
      
      
      <div class="form-group">
        <!--<button type="submit" class="btn btn-success" ng-click="exeCtrl.patient.openExam.$save()">Speichern</button>-->
        <button type="submit" class="btn btn-primary" ng-click="exeCtrl.finishExam()">Beenden</button>
      </div>
      
      <div class="alert alert-info" ng-show="exeCtrl.waitingforsave === true">
        <h3><i class="fa fa-spin fa-spinner"></i> Untersuchung wird abgeschlossen ...</h3>
      </div>
      
      <div class="alert alert-success" ng-show="exeCtrl.waitingforsave === false">
        <h3><i class="fa fa-check"></i> Untersuchung abgeschlossen!</h3>
      </div>
           
      <!-- comfort white space to stop exameditor from bouncing up an down on page refresh of longer forms -->
      <!-- only remove if found an alternative way of stopping this ery annoying behaviour! -->
      <div style="min-height: 2000px">&nbsp;</div>
    </div>
  
  
  </form>
</div>