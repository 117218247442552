(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:patientTodos
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-todos></patient-todos>
   </file>
   </example>
   *
   */
  angular
    .module('patient')
    .directive('patientTodos', patientTodos);

  function patientTodos() {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'patient/patient-todos-directive.tpl.html',
      replace: false,
      controllerAs: 'ptdCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.addTodoEntry = addTodoEntry;

        function addTodoEntry() {
          if (!vm.patient.hasOwnProperty('todos') || vm.patient.todos.constructor !== Array) {
            vm.patient.todos = [];
          }
          vm.patient.todos.push({created: new Date().toISOString(), label: vm.patient.newtodo, done: false});
          vm.patient.newtodo = '';
          vm.patient.$save();
        }
      }
    };
  }
}());
