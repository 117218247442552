<h3>Verordnungen von Hilfsmitteln</h3>

  <form class="form-vertical" id="aideform" name="aideform">
    <div class="form-group">
      <label for="aideto">An/Zu<span uib-tooltip="Pflichtfeld">*</span></label>
      <input type="text" required ng-model="aidCtrl.newaide.to" class="form-control" id="aideto">
    </div>
    <div class="form-group">
      <label for="aidediag">Diagnose/Begründung<span uib-tooltip="Pflichtfeld">*</span></label>
      <input type="text" required ng-model="aidCtrl.newaide.diag" class="form-control" id="aidediag">
    </div>
    <div class="form-group">
      <label for="aideaide">Verordnung<span uib-tooltip="Pflichtfeld">*</span></label>
    <textarea class="form-control" required ng-model="aidCtrl.newaide.aide" rows="5"
              id="aideaide"></textarea>
    </div>

    <!-- gültig -->
    <div class="form-group">
      <label>Gültig </label>
      <div class="radio">
        <label class="radio-inline">
          <input type="radio" ng-value="true" ng-model="aidCtrl.newaide.pdate"
                 ng-click="aidCtrl.newaide.aidedate = moment().locale('de-at').format('ll')">
          1 Monat</label>
        <label class="radio-inline">
          <input type="radio" ng-value="false" ng-model="aidCtrl.newaide.pdate"
                 ng-click="aidCtrl.newaide.aidedate = ''">
          Kein Datum</label>
      </div>
    </div>

    <!-- Buttons -->
    <button class="btn btn-success" type="button" ng-click="aidCtrl.addAnotherAide()"
            ng-disabled="aideform.$invalid">
      Speichern
    </button>
    <button class="btn btn-danger" type="button" ng-click="aidCtrl.dialog.close()" ng-show="aidCtrl.dialog">
      Schließen
    </button>
  </form>

<!-- Liste bestehender Verordnungen -->
  <div ng-repeat="aide in aidCtrl.patient.openConsult.aides">
    <div class="row">
      <div class="col-sm-11"><h4>Verordnung</h4></div>
      <div class="col-sm-1">
        <button class="btn btn-danger" ng-click="aidCtrl.removeAide($index)" uib-tooltip="Verordnung löschen">
          <i class="fa fa-remove"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <dl class="dl-horizontal">
          <dt>An/zu</dt>
          <dd ng-model="aide.to">{{aide.to}}</dd>
          <dt>Diagnose</dt>
          <dd ng-model="aide.diag">{{aide.diag}}</dd>
          <dt>Verordnung</dt>
          <dd ng-model="aide.aide">{{aide.aide}}</dd>
          <dt>Datum</dt>
          <dd ng-bind="aide.aidedate"></dd>
        </dl>
      </div>
    </div>
  </div>

  <a class="btn btn-info" target="_blank" ng-show="aidCtrl.patient.openConsult.aides.length"
     href="/routes/pdfprinter/consultpreview?patientid={{aidCtrl.patient._id}}&consultid={{aidCtrl.patient.openConsult._id}}&doctype=aide">
    <i class="fa fa-eye"></i> PDF Vorschau
  </a>
  <div style="min-height:3cm">&nbsp;</div>
