<div class="form-group">
  <label ng-model="efCtrl.efield.label" tabindex="-1">{{efCtrl.efield.label}}</label>
  <tags-input ng-model="efCtrl.efield.avalues"
              display-property="name"
              placeholder="Tippen zum Vervollständigen"
              add-on-comma="false"
              min-length="1"
              id="{{$id}}"
              tabindex="{{efCtrl.fti}}"
              enable-editing-last-tag="true"
              ng-blur="efCtrl.save()"
              replace-spaces-with-dashes="false"
              template="tag-template">
    <auto-complete source="efCtrl.loadValues($query)"
                   min-length="0"
                   load-on-focus="true"
                   load-on-empty="true"
                   load-on-down-arrow="true"
                   max-results-to-show="32"
                   select-first-match="false"
                   template="autocomplete-template"></auto-complete>
  </tags-input>
</div>


  <script type="text/ng-template" id="tag-template">
    <div class="tag-template">
      <div class="right-panel">
        <span>{{$getDisplayText()}}</span>
        <a class="remove-button" ng-click="$removeTag()">&#10006;</a>
      </div>
    </div>
  </script>


  <script type="text/ng-template" id="autocomplete-template">
    <div class="autocomplete-template">
      <div>
        <span ng-bind-html="$highlight($getDisplayText())">{{name}}</span>
      </div>
    </div>
  </script>