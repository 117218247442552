<h4>Aktuelle Medikamente</h4>
<div class="input-group">
  <span ng-repeat="drug in pcdCtrl.patient.drugs" id="quuxbar">
    <span ng-show="drug.active && pcdCtrl.datecheck(drug)" uib-dropdown>
      <button type="button" class="btn btn-{{drug.color}}" uib-dropdown-toggle
              ng-class="btn-{{drug.color || 'primary'}}"
              data-toggle="uib-dropdown" aria-haspopup="true" aria-expanded="false"
              uib-tooltip="{{drug.name}} / {{drug.amount}} / {{drug.comment}}">
        {{drug.name | drugname }} {{drug.amount}} <span class="caret"></span>
      </button>
      <ul uib-dropdown-menu>
        <li>
          <a ng-click="pcdCtrl.updateDrugColor(drug, 'success')">
            <span class="label label-success">Grün (Erfolg)</span>
          </a>
        </li>
        <li>
          <a ng-click="pcdCtrl.updateDrugColor(drug, 'warning')">
            <span class="label label-warning">Gelb (Warnung)</span>
          </a>
        </li>
        <li>
          <a ng-click="pcdCtrl.updateDrugColor(drug, 'danger')">
            <span class="label label-danger">Rot (Gefahr)</span>
          </a>
        </li>
        <li>
          <a ng-click="pcdCtrl.updateDrugColor(drug, 'info')">
            <span class="label label-info">Türkis (Fremdverordnung)</span>
          </a>
        </li>
        <li>
          <a ng-click="pcdCtrl.updateDrugColor(drug, 'primary')">
            <span class="label label-primary">Blau (Aktuell)</span>
          </a>
        </li>

        <li>
          <a ng-really-message="Dieses Medikament wirklich absetzen?"
             ng-really-click="pcdCtrl.deactivateDrug(drug, patient)">
            <span class="label label-danger">X</span> Medikament absetzen
          </a>
        </li>
        <li role="separator" class="divider"></li>
          <form id="commentform">
          <label for="dcomment">Kommentar</label>
          <textarea class="form-control dropdown" ng-model="drug.privcomment" id="dcomment"
                 ng-click="$event.stopPropagation()"
                 ng-blur="pcdCtrl.patient.$save()"></textarea>
          </form>
        </li>
        <li role="separator"   ng-show="drug.fi" class="divider"></li>
        <li ng-show="drug.fi"><a
               href="/routes{{drug.fi}}" target="_blank"
               uib-tooltip="Fachinformationen"><span class="fa fa-file-pdf-o btn btn-info btn-sm"></span> Fachinformationen</a></li>
      </ul>
    </span>
  </span><!-- ng-repeat -->
</div>
