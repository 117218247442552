(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name unicatalog.directive:itemList
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="unicatalog">
       <file name="index.html">
        <item-list></item-list>
       </file>
     </example>
   *
   */
  angular
    .module('unicatalog')
    .directive('itemList', itemList);

  function itemList() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'unicatalog/item-list-directive.tpl.html',
      replace: false,
      controllerAs: 'itemList',
      controller: function () {
        var vm = this;
        vm.name = 'itemList';
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
