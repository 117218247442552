<div>
  <h3>Allgmeine Kassenzeichen</h3>
  <div class="row">
    <div class="col-xs-1">F2J</div>
    <div class="col-xs-11">Die Arzneispezialität ist nur für die Behandlung von Kindern bis zum vollendeten 2.
      Lebensjahr in den Grünen Bereich des Erstattungskodex aufgenommen. Für Verordnungen außerhalb der angegebenen
      Altersbeschränkung ist die Arzneispezialität wie eine Arzneispezialität des Gelben Bereichs des Erstattungskodex
      zu behandeln, wobei, wenn die Notwendigkeit für die Verordnung einer kleinkindergerechten Therapie oder Dosierung
      entsprechend den Bestimmungen der Heilmittel-Bewilligungs- und Kontroll-Verordnung und den Richtlinien über die
      ökonomische Verschreibweise dokumentiert wird, die vorherige ärztliche Bewilligung des chef- und
      kontrollärztlichen Dienstes durch eine nachfolgende Kontrolle der Einhaltung der bestimmten Verwendung ersetzt
      werden kann.
    </div>
  </div>
  <div class="row">
    <div class="col-xs-1">F6J</div>
    <div class="col-xs-11">Die Arzneispezialität ist nur für die Behandlung von Kindern bis zum vollendeten 6.
      Lebensjahr in den Grünen Bereich des Erstattungskodex aufgenommen. Für Verordnungen außerhalb der angegebenen
      Altersbeschränkung ist die Arzneispezialität wie eine Arzneispezialität des Gelben Bereichs des Erstattungskodex
      zu behandeln, wobei, wenn die Notwendigkeit für die Verordnung einer kindergerechten Therapie oder Dosierung
      entsprechend den Bestimmungen der Heilmittel-Bewilligungs- und Kontroll-Verordnung und den Richtlinien über die
      ökonomische Verschreibweise dokumentiert wird, die vorherige ärztliche Bewilligung des chef- und
      kontrollärztlichen Dienstes durch eine nachfolgende Kontrolle der Einhaltung der bestimmten Verwendung ersetzt
      werden kann.
    </div>
  </div>
  <div class="row">
    <div class="col-xs-1">F14</div>
    <div class="col-xs-11">Die Arzneispezialität ist nur für die Behandlung von Kindern und Jugendlichen bis zum
      vollendeten 14. Lebensjahr in den Grünen Bereich des Erstattungskodex aufgenommen. Für Verordnungen außerhalb der
      angegebenen Altersbeschränkung ist die Arzneispezialität wie eine Arzneispezialität des Gelben Bereichs des
      Erstattungskodex zu behandeln, wobei, wenn die Notwendigkeit für die Verordnung einer kindergerechten Zubereitung
      (z.B.: Saft) oder Dosierung entsprechend den Bestimmungen der Heilmittel-Bewilligungs- und Kontroll-Verordnung und
      den Richtlinien über die ökonomische Verschreibweise dokumentiert wird, die vorherige ärztliche Bewilligung des
      chef- und kontrollärztlichen Dienstes durch eine nachfolgende Kontrolle der Einhaltung der bestimmten Verwendung
      ersetzt werden kann.
    </div>
  </div>
  <div class="row">
    <div class="col-xs-1">DSX</div>
    <div class="col-xs-11">(steht für die Facharztkennzeichen A, D, K, L): Desensibilisierungsprodukt; frei
      verschreibbar durch Facharzt/Fachärztin für Lungenkrankheiten, Haut- und Geschlechtskrankheiten, Kinderheilkunde
      und Hals-, Nasen- und Ohrenkrankheiten.
    </div>
  </div>
  <h3>Kassenzeichen Facharztkennung</h3>
  <div class="row">
    <div class="col-xs-1"></div>
    <div class="col-xs-11"></div>
  </div>
  <div class="row">
    <div class="col-xs-1">A</div>
    <div class="col-xs-11">Facharzt/Fachärztin für Lungenkrankheiten</div>
  </div>
  <div class="row">
    <div class="col-xs-1">Au</div>
    <div class="col-xs-11">Facharzt/Fachärztin für Augenkrankheiten</div>
  </div>
  <div class="row">
    <div class="col-xs-1">D</div>
    <div class="col-xs-11">Facharzt/Fachärztin für Haut- und Geschlechtskrankheiten</div>
  </div>
  <div class="row">
    <div class="col-xs-1">F</div>
    <div class="col-xs-11">Facharzt/Fachärztin für Frauenheilkunde und Geburtshilfe</div>
  </div>
  <div class="row">
    <div class="col-xs-1">K</div>
    <div class="col-xs-11">Facharzt/Fachärztin für Kinderheilkunde</div>
  </div>
  <div class="row">
    <div class="col-xs-1">L</div>
    <div class="col-xs-11">Facharzt/Fachärztin für Hals-, Nasen- und Ohrenkrankheiten</div>
  </div>
  <div class="row">
    <div class="col-xs-1">P</div>
    <div class="col-xs-11">Facharzt/Fachärztin für Psychiatrie oder Psychiatrie und Neurologie oder Neurologie und
      Psychiatrie oder Kinder- und Jugendpsychiatrie
    </div>
  </div>

  <div class="row">
    <div class="col-xs-1">P</div>
    <div class="col-xs-11">Facharzt/Fachärztin für Psychiatrie oder Psychiatrie und Neurologie oder Neurologie und
      Psychiatrie oder Kinder- und Jugendpsychiatrie
    </div>
  </div>

  <div class="row">
    <div class="col-xs-1">U</div>
    <div class="col-xs-11">Facharzt/Fachärztin für Urologie</div>
  </div>


</div>