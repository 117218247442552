(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:patientNewsearch
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="patient">
       <file name="index.html">
        <patient-newsearch dialog="" prevstate=""></patient-newsearch>
       </file>
     </example>
   *
   */
  angular
    .module('patient')
    .directive('patientNewsearch', patientNewsearch);

  patientNewsearch.$inject = ['Patientsearch', 'Patient', '$state', '$log'];

  function patientNewsearch(Patientsearch, Patient, $state, $log) {
    return {
      restrict: 'EA',
      scope: {
        dialog: '='
      },
      templateUrl: 'patient/patient-newsearch-directive.tpl.html',
      replace: false,
      controllerAs: 'npCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.createPatient = createPatient;
        vm.showRecord = showRecord;
        vm.cancel = cancel;
        vm.setLastname = setLastname;
        vm.getNewNames = getNewNames;
        
        // typeahead new patient search -  set lastname action
        function setLastname($model) {
          if (!angular.isObject(vm.newpatient)) {
            vm.newpatient = {};
          }
   //       $log.debug('setLastname model 1: ', $model);
   //       $log.debug('vm.newpatient: ', vm.newpatient);
          vm.newpatient.lastname = $model.lastname;
          getNewNames($model.lastname);
//           $log.debug('vm.newpatient: ', vm.newpatient);
        }

        // typeahead new patient search backend query
        function getNewNames(val) {
          console.log('loading names');

          return Patientsearch.$search({query: val}).$asPromise().then(function (response) {
            // console.log('response: ', response);
            var resp = response.map(function (patient) {
              patient.text = patient.lastname;
              return patient;
            });
            vm.newnames = resp;
            return resp;
          });
        }

        function createPatient() {
          // this is totally ugly, but it seems to work
          if (!vm.newpatient.lastname && angular.isObject(vm.asyncSelected)) {
            vm.newpatient.lastname = vm.asyncSelected.lastname;
          }
          if (!vm.newpatient.lastname && angular.isString(vm.asyncSelected)) {
            vm.newpatient.lastname = vm.asyncSelected;
          }
          if (angular.isObject(vm.newpatient.lastname)) {
            vm.newpatient.lastname = vm.newpatient.lastname.lastname;
          }
          vm.newpatient.inconsult = false;
          vm.newpatient.inexam = false;
          vm.newpatient.incheckout = false;
          vm.newpatient.consultstate = '';
          vm.newpatient.checkoutstate = '';
          $log.debug('vm.newpatient create: ', vm.newpatient);
          Patient.$create(vm.newpatient).$then(function (pat) {
            console.log('patid: ', pat._id);
            $state.go('patientrecord.basicdata', {patientid: pat._id});
          });
        }

        function showRecord(id) {
          $state.go('patientrecord.history', {patientid: id});
          vm.dialog.close();
        }

        function cancel() {
          vm.dialog.close();
          if ($state.previousState && $state.previousParams) {
            $state.go($state.previousState.name, $state.previousParams);
          }

          if ($state.previousState && !$state.previousParams) {
            $state.go($state.previousState.name);
          }


        }

      }
    };
  }
  // klammerfriedhof
}());



