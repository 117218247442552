(function () {
  'use strict';

  angular
    .module('dms')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dms', {
        url: '/dms',
        views: {
          main: {templateUrl: 'dms/dms.tpl.html'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });
    $stateProvider
      .state('dms.scansorter', {
        url: '/scansorter',
        views: {
          dmsmain: {template: '<scan-sorter></scan-sorter>'}
        }
      });
    /*    $stateProvider
          .state('dms.signoff', {
            url: '/signoff',
            views: {
              dmsmain: {template: '<signoff-all></signoff-all>'}
            }
          });*/
    $stateProvider
      .state('dms.signoff', {
        url: '/signoff',
        params: {
          patientid: null
        },
        views: {
          dmsmain: {
            template: '<signoff-all patientid="{{vm.patientid}}"></signoff-all>',
            controller: function ($stateParams, $state) {
              var vm = this;
              console.log('stateparams in dummy controller: ', $stateParams, $state);
              vm.patientid = $stateParams.patientid;
            },
            controllerAs: 'vm'
          }
        }
        /*resolve: {
          patientid: function ($stateParams, $state) {
            // var patientid = $stateParams.patientid;
            var patientid = $state.previousParams.patientid;
            console.log('**** dms router resolve $state: ', $state);
            console.log('**** dms router resolve previous: ', $state.previousParams.patientid);
            console.log('**** dms router resolve patientid: ', patientid);
            return patientid;
          }
        },
        onEnter: function ($stateParams, $state) {
          console.log('**** dms router entered: ', $stateParams, $state);
        }*/
      });

    $stateProvider
      .state('dms.patsignoff', {
        url: '/patsignoff',
        params: {
          patientid: null
        },
        views: {
          dmsmain: {
            template: '<signoff-all patientid="{{vm.patientid}}"></signoff-all>',
            controller: function ($stateParams, $state) {
              var vm = this;
              console.log('stateparams in dummy controller: ', $stateParams, $state);
              vm.patientid = $stateParams.patientid;
            },
            controllerAs: 'vm'
          }
        }
        /*resolve: {
          patientid: function ($stateParams, $state) {
            // var patientid = $stateParams.patientid;
            var patientid = $state.previousParams.patientid;
            console.log('**** dms router resolve $state: ', $state);
            console.log('**** dms router resolve previous: ', $state.previousParams.patientid);
            console.log('**** dms router resolve patientid: ', patientid);
            return patientid;
          }
        },
        onEnter: function ($stateParams, $state) {
          console.log('**** dms router entered: ', $stateParams, $state);
        }*/
      });


    $stateProvider
      .state('dms.filebrowser', {
        url: '/filebrowser',
        views: {
          dmsmain: {template: '<file-browser></file-browser>'}
        }
      });
  }

  // klammerfriedhof
}());
