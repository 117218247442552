(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name examtemplate.directive:examStarter
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="examtemplate">
   <file name="index.html">
   <exam-starter patient="" consult=""></exam-starter>
   </file>
   </example>
   *
   */
  angular
    .module('examtemplate')
    .directive('examStarter', examStarter);


  examStarter.$inject = ['Examtemplate', '$http', '$state', '$log', 'vertxEventBusService'];


  function examStarter(Examtemplate, $http, $state, $log, vertxEventBusService) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'examination/exam-starter-directive.tpl.html',
      replace: false,
      controllerAs: 'esCtrl',
      bindToController: true,
      // controller: 'ExamtemplateCtrl',
      controller: function () {
        var vm = this;
        vm.alltemplates = Examtemplate.$search();
        vm.startExam = startExam;
        // vm.createExam = createExam;
        
        function startExam(template) {
          var newexam = {};
          newexam.templateid = template._id;
          newexam.template = template.template;
          newexam.fdate = moment().locale('de-at').format('ll');

          vm.patient.openConsult.examinations.$create(newexam).$then(function (oe) {
            vm.patient.consultstate = 'exam';
            oe.open = true;

            vm.patient.openExam = oe;
            vm.patient.openExam.patient = vm.patient;
            vm.patient.openExam.$save();
            vm.patient.inexam = true;
            vm.patient.$save();

            if (template.template.loadfiles === true && template.template.shareid !== '') {
              // $http.get('/routes/files/filegrabber/start/' + vm.patient.openExam._id).then(successCallback, errorCallback);
              $http.get('/routes/files/filegrabber/start/' + vm.patient.openExam._id);
              console.log('start - oE._id: ', vm.patient.openExam._id);
            }

            $state.go('patientrecord.current.exam');
            // $state.go('patientrecord.current.modalexam', {patient: vm.patient});
          });
        }

      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        console.log('etCtrl Link function');
        ctrl.alltemplates.$refresh();
        /*
         if ((typeof(ctrl.consult.openExam) !== "undefined") && (ctrl.consult.openExam !== null)) {
         ctrl.openExam = ctrl.consult.openExam.$fetch();
         } */
        // ctrl.consultExams = ctrl.consult.examinations.$fetch();
      }
    };
  }
}());
