(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name patient.directive:checkoutBilling
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <checkout-billing></checkout-billing>
   </file>
   </example>
   *
   */
  angular
    .module('consult')
    .directive('checkoutBilling', checkoutBilling);
  
  checkoutBilling.$inject = ['Service', '$log', '$state', 'ConsultRefresh', 'vertxEventBusService', '$cookies', '$window'];
  
  function checkoutBilling(Service, $log, $state, ConsultRefresh, vertxEventBusService, $cookies, $window) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'consult/checkout-billing-directive.tpl.html',
      replace: false,
      controllerAs: 'billCtrl',
      bindToController: true,
      controller: function ($filter) {
        var vm = this;
        
        vm.Service = Service;
        // vm.allservices = Service.$search();
        vm.$state = $state;
        
        vm.sumUpItems = sumUpItems;
        vm.saveInvoiceItems = saveInvoiceItems;
        vm.noInvoice = noInvoice;
        
        vm.nonzeroitems = nonzeroitems;
        /* vm.checkedService = checkedService;
         vm.toggleService = toggleService;
         vm.removeService = removeService;*/
        
        vm.joinDiagnoses = joinDiagnoses;
        
        vm.selectedservices = {};
        
        function nonzeroitems() {
          var i;
          var n = 0;
          if (angular.isObject(vm.patient.openConsult.service) && angular.isArray(vm.patient.openConsult.service.items)) {
            for (i = 0; i < vm.patient.openConsult.service.items.length; i++) {
              if (vm.patient.openConsult.service.items[i].selected === true) {
                n++;
              }
            }
          }
          // $log.debug('Nr of invoice items: ', n);
          return n;
        }
        
        function joinDiagnoses() {
          var newdiag;
          var tmp1 = [];
          var tmp2 = [];
          var i;
          
          $log.debug('diagnoses: ', vm.patient.diagnoses);
          
          for (i = 0; i < vm.patient.diagnoses.length; i++) {
            $log.debug(i, ': ', vm.patient.diagnoses[i]);
            switch (vm.patient.diagnoses[i].col) {
              case 'primary':
                tmp1.unshift(vm.patient.diagnoses[i].key);
                break;
              case 'info':
                tmp1.push(vm.patient.diagnoses[i].key);
                break;
              case 'success':
                tmp2.unshift(vm.patient.diagnoses[i].key);
                break;
              default:
                tmp2.push(vm.patient.diagnoses[i].key);
                break;
            }
          }
          newdiag = tmp1.concat(tmp2);
          return newdiag.join(', ');
        }
        
        
        function arraysum(array) {
          var total = 0;
          var i;
          var len;
          for (i = 0, len = array.length; i < len; i++) {
            total += array[i];
          }
          $log.debug('arraysum total', total, array);
          return total;
        }
        
        function objectarraysum(array) {
          var total = 0;
          var entry;
          for (entry in array) {
            total += array[entry];
          }
          return total;
        }
        
        
        // this calculates brutto, netto, tax sum per taxbraket
        function sumUpItems() {
          var selserv = {};
          // var discounted = {};
          var i;
          var tax;
          var taxstr;
          var taxindex;
          var price;
          var nettoarray = {};
          var taxarray = [];
          var num = 0;
          var nettosum;
          var taxsum;
          var taxnum;
          var arrsum;
          var taxvalue;
          
          // $log.debug('sumUpItems entered');
          
          // Iterate of services
          // if service is selected, check tax bracket
          // add net price to array indexed by tax bracket
          // create array if not already exists
          
          for (i = 0; i < vm.patient.openConsult.service.items.length; i++) {
            if (vm.patient.openConsult.service.items[i].selected) {
              num++;
              tax = vm.patient.openConsult.service.items[i].tax;
              price = vm.patient.openConsult.service.items[i].price;
              taxstr = tax.toString();
              
              if (angular.isArray(selserv[taxstr])) {
                selserv[taxstr].push(price);
              }
              else {
                selserv[taxstr] = [];
                selserv[taxstr].push(price);
              }
            }
          }
          
          // $log.debug('********* selected items: ', selserv);
          
                    
          // calc netto sum per tax bracket
          // calc tax sum per tax bracket
          for (tax in selserv) {
            if (selserv.hasOwnProperty(tax)) {
              taxstr = tax.toString();
              // $log.debug('tax: ', i, selserv[taxstr]);
              if (angular.isNumber(parseInt(tax, 10)) && angular.isArray(selserv[taxstr])) {
                // $log.debug('tax isnumber: ', tax, selserv[taxstr]);
                nettoarray[taxstr] = arraysum(selserv[taxstr]);
                taxarray[taxstr] = Math.round(100 * (nettoarray[taxstr] * (parseInt(tax, 10) / 100))) / 100;
                // $log.debug('netto[taxstr]: ', tax, netto[taxstr]);
              }
            }
          }
          
          // $log.debug('********* taxarray items: ', taxarray);
                    
          // calc total
          // sum up arrays for netto prices and tax indexed by tax bracket
          nettosum = objectarraysum(nettoarray);
          taxsum = objectarraysum(taxarray);
          // $log.debug('taxsum: ', taxsum);
          
          // save to service record
          vm.patient.openConsult.service.netto = nettosum;
          // vm.patient.openConsult.service.nettoarray = nettoarray;
          vm.patient.openConsult.service.tax = taxsum;
          // vm.patient.openConsult.service.taxarray = taxarray;
          vm.patient.openConsult.service.totalbefore = nettosum + taxsum;
          vm.patient.openConsult.service.discounttaxsum = {};
          vm.patient.openConsult.service.discountnettoarray = {};
          vm.patient.openConsult.service.discountbruttoarray = {};
          // $log.debug('gesamt total pre discount:', vm.patient.openConsult.service.totalbefore);
          
          // check for discount
          // distribute discount across net proces and taxes
          if (vm.patient.openConsult.service.discount > 0 && vm.patient.openConsult.service.totalbefore > 0) {
            // $log.debug('calculating discount');
            
            for (taxindex in selserv) {
              if (selserv.hasOwnProperty(taxindex)) {

                taxnum = parseInt(taxindex, 10);
                // $log.debug('taxnum: ', taxnum);
                arrsum = arraysum(selserv[taxindex]);
                // $log.debug('arrsum: ', arrsum);
                taxvalue = arrsum * taxnum / 100;
                //  $log.debug('taxval: ', taxvalue);
                // calculate discounted tax in taxarray!
                vm.patient.openConsult.service.discounttaxsum[taxindex] = taxvalue * (1 - vm.patient.openConsult.service.discount / vm.patient.openConsult.service.totalbefore);
                vm.patient.openConsult.service.discountnettoarray[taxindex] = arrsum * (1 - vm.patient.openConsult.service.discount / vm.patient.openConsult.service.totalbefore);
                vm.patient.openConsult.service.discountbruttoarray[taxindex] = (arrsum + taxvalue) * (1 - vm.patient.openConsult.service.discount / vm.patient.openConsult.service.totalbefore);
                $log.debug('calculated discount: ', vm.patient.openConsult.service.discounttaxsum);
              }
            }
            // discounted total including tax
            vm.patient.openConsult.service.total = nettosum + taxsum - vm.patient.openConsult.service.discount;
            // discounted netto sum
            vm.patient.openConsult.service.nettodiscounted = nettosum * (1 - vm.patient.openConsult.service.discount / vm.patient.openConsult.service.totalbefore);
            // discounted tax sum
            vm.patient.openConsult.service.taxdiscounted = tax * (1 - vm.patient.openConsult.service.discount / vm.patient.openConsult.service.totalbefore);
          }
          else {
            vm.patient.openConsult.service.total = nettosum + taxsum;
            vm.patient.openConsult.service.nettodiscounted = nettosum;
            vm.patient.openConsult.service.taxdiscounted = taxsum;
            // fop templates only handle discounttaxarray, thus
            // this doesn't work
            // vm.patient.openConsult.service.discounttaxsum = taxarray;
            // vm.patient.openConsult.service.discountnettoarray = nettoarray;
            for (taxindex in selserv) {
              if (selserv.hasOwnProperty(taxindex)) {
                taxnum = parseInt(taxindex, 10);
                arrsum = arraysum(selserv[taxindex]);
                taxvalue = arrsum * taxnum / 100;
                // calculate non-discounted tax in taxarray!
                vm.patient.openConsult.service.discounttaxsum[taxindex] = taxvalue;
                vm.patient.openConsult.service.discountnettoarray[taxindex] = arrsum;
                vm.patient.openConsult.service.discountbruttoarray[taxindex] = arrsum + taxvalue;
              }
            }
          }
          
          
          // vm.patient.openConsult.service = service;
          vm.patient.openConsult.$save();
        }
        
        function saveInvoiceItems() {
          sumUpItems();
          vm.patient.openConsult.$save();
          if (vm.$state.current.name === 'patientrecord.billing') {
            $state.go('patientrecord.checkout.ccdocs');
          }
          else {
            $state.go('patientrecord.checkout.payment');
          }
        }
        
        function noInvoice() {
          vm.patient.openConsult.service = null;
          vm.patient.openConsult.$save();
          if (vm.$state.current.name === 'patientrecord.billing') {
            $state.go('patientrecord.checkout.ccdocs');
          }
          else {
            $state.go('patientrecord.checkout.payment');
          }
        }
        
        
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        
        // watch for patient.openConsult load, then check for services
        scope.$watch('billCtrl.patient.openConsult', function (newval, oldval, lscope) {
          // $log.debug('newval: ', newval);
          if (angular.isString(newval._id) && newval._id.length > 1) {
            // $log.debug('newval._id: ', newval._id);
            if (!angular.isObject(newval.service) || newval.service === null) {
              newval.service = {};
              newval.service.items = Service.$search({active: true});
            }
          }
        }, true);
        
        
        ConsultRefresh.refreshAll(ctrl.patient);
        
        // listen for openconsult updates
        vertxEventBusService.on('consults.update', function (message) {
          var messages = message.split(':');
          console.log('Consult updated: ', messages[0]);
          // if (messages[1] !== $cookies.get('vertx-web.session') && ctrl.patient.openConsult._id === messages[0]) {
          if (messages[1] !== $window.sessionStorage.getItem('sprtabid') && ctrl.patient.openConsult._id === messages[0]) {
            ctrl.patient.openConsult.$refresh().$asPromise().then(function () {
              ctrl.patient.openConsult.examinations.$refresh();
            });
            $log.debug(' (cbd) refreshed openconsult for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
          else {
            $log.debug(' (cbd) ignored openconsult change for patient: ', ctrl.patient.firstname + ' ' + ctrl.patient.lastname);
          }
        });
      }
    };
  }
}());
