/**
 * Created by lari on 07/01/2017.
 */
(function () {
  'use strict';
  
  /**
   * @ngdoc component
   * @name consult.component:consultList
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="consult">
   <file name="index.html">
   <consult-list></consult-list>
   </file>
   </example>
   *
   */
  angular
    .module('consult')
    .component('consultList', {
      bindings: {
        patient: '<'
      },
      templateUrl: 'consult/consult-list-directive.tpl.html',
      controllerAs: 'consCtrl',
      controller: ['Consult', 'OpenConsults', 'Patient', 'Service', '$stateParams', '$state', '$log', 'Config',
        '$scope', '$window', 'hotkeys', '$uibModal', 'Document', 'File', 'ConsultModalsHelper', '$timeout', 'Wlcommons', 'Waitinglist',
        function (Consult, OpenConsults, Patient, Service, $stateParams, $state, $log, Config,
                  $scope, $window, hotkeys, $uibModal, Document, File, ConsultModalsHelper, $timeout, Wlcommons, Waitinglist) {
          var vm = this;
          vm.startConsult = startConsult;
          vm.switchToOpenExam = switchToOpenExam;
          vm.switchToExit = switchToExit;
          vm.getDocuments = getDocuments;
          vm.getExams = getExams;

          vm.$onChanges = function (changesObj) {
            // $log.debug('Consult List component $onChanges called: ', changesObj.patient.currentValue);
            if (vm.patient) {
              if (angular.isObject(vm.patient) && angular.isArray(vm.patient.consults)) {
                // $log.debug('patconsult refresh called');
                vm.patient.consults.$refresh();
              }
              else {
                // $log.debug('patconsult refresh NOT called: ', angular.isObject(vm.patient));
              }
            }
          };
          
          function startConsult() {
            vm.patient.consults.$create().$asPromise().then(function (oc) {
              vm.patient.consultstate = 'consult';
              vm.patient.inconsult = true;
              vm.patient.incheckout = false;
              calculateActivedrugs(); // recalculate drug counter (might not be set on older patientrecords)
              vm.patient.prescriptiondate = moment().locale('de-at').format('ll'); // set formatted prescription dat for prescription, referrals and aides
              oc.examinations.$fetch();
              oc.open = true;
              oc.fdate = moment().locale('de-at').format('ll');
              vm.patient.openConsult = oc;
              vm.patient.openConsult.$save();
              $log.debug('openConsult: ', vm.patient.openConsult);
              
              vm.patient.$save().$asPromise().then(function () {
                $log.debug('consult started, should trigger update');
                OpenConsults.triggerUpdate();
                $log.debug('oc started, patient saved');
              });
              // TODO: nasty snow flake bug - figure out why above code won't work for rudi an needs below snowflake patch
              $timeout(OpenConsults.triggerUpdate(), 5000);
              $state.go('patientrecord.current.consult');
            });
          }
          
          function switchToOpenExam() {
            vm.swtespin = true;
            vm.patient.openExam.$refresh().$asPromise().then(function (e) {
              vm.swtespin = false;
              switch (vm.patient.consultstate) {
                case 'exam':
                  $state.go('patientrecord.current.exam');
                  break;
                case 'examfiles':
                  $state.go('patientrecord.current.examfiles');
                  break;
                default:
                  vm.swtespin = true;
                  vm.swteerror = true;
                  vm.patient.$refresh().$asPromise().then(function () {
                    vm.patient.openExam.$refresh().$asPromise().then(function () {
                      vm.patient.openConsult.$refresh().$asPromise().then(function () {
                        vm.patientid.openConsult.examinations.$refresh();
                        vm.swtespin = false;
                        vm.swteerror = false;
                      });
                    });
                  });
                  
                  break;
              }
            }); // switch
          }
          
          function calculateActivedrugs() {
            var i;
            vm.patient.activedrugs = 0;
            if (vm.patient.drugs.length && vm.patient.drugs.length > 0) {
              for (i = 0; i < vm.patient.drugs.length; i++) {
                if (vm.patient.drugs[i].active === true && vm.patient.drugs[i].foreign === false && vm.patient.drugs[i].print === true) {
                  vm.patient.activedrugs += 1;
                }
              }
            }
          }
          
          function switchToExit() {
            $state.go('patientrecord.checkout.' + vm.patient.checkoutstate);
          }
  
          function getDocuments(cons) {
            cons.docs = Document.$search({consultid: cons._id});
          }
  
          function getExams(cons) {
            cons.examinations.$fetch();
            cons.exams = 1;
          }
        }
      ]
    });
  
  // consultList.$inject = [];
  /*  consultList.$inject = ['Consult', 'OpenConsults', 'Patient', 'Service', '$stateParams', '$state', '$log', 'Config',
   '$scope', '$window', 'hotkeys', '$uibModal', 'Document', 'File', 'ConsultModalsHelper', '$timeout', 'Wlcommons', 'Waitinglist'];*/


// Klammerfriedhof
}());
