(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name unicatalog.directive:arrayEditor
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="unicatalog">
       <file name="index.html">
        <array-editor></array-editor>
       </file>
     </example>
   *
   */
  angular
    .module('unicatalog')
    .directive('arrayEditor', arrayEditor);

  function arrayEditor() {
    return {
      restrict: 'EA',
      scope: {
        array: '='
      },
      //templateUrl: 'unicatalog/array-editor-directive.tpl.html',
      template: '<input type="text" class="form-control" ng-model="aeCtrl.array" ng-blur="aeCtrl.deserialize(aeCtrl.array)">',
      replace: false,
      controllerAs: 'aeCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'arrayEditor';

        vm.serial = vm.serialize(vm.array);
        vm.serialize = serialize;
        vm.deserialize = deserialize;


        function serialize(input) {
          var output = '';
          if (angular.isArray(input)) {
            output = input.join(', ');
          }
          return output;
        }

        function deserialize(input) {
          var output = [];
          if (angular.isString(input)) {
            input.text().replace(/ /g, '');
            output = input.split(',');
          }
          return output;
        }
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
