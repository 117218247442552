(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name unicatalog.factory:Unicatalog
   *
   * @description
   *
   */
  angular
    .module('unicatalog')
    .factory('Unicatalog', Unicatalog)
    .factory('Unicatalogtree', Unicatalogtree);

  Unicatalog.$inject = ['restmod'];

  function Unicatalog(restmod) {
    return restmod.model('/routes/unicatalog').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      updated: {
        init: function () {
          var updates = [];
          updates.push(new Date().toISOString());
          return updates;
        }
      },
      active: {init: true},
      displayname: {init: ''},
      cname: {init: ''},
      description: {init: ''},
      ingeneralcat: {init: false},
      inusercat: {init: false},
      inoperationcat: {init: false},
      customerdata: {
        init: function () {
          return {tax: 0, price: 0, shortcut: ''};
        }
      },
      svdata: {
        init: function () {
          var struct = {
            refundable: true,
            codes1: [],
            codes2: [],
            primarycode: '',
            points: 0,
            pointcat: '',
            price: 0,
            conflictssv: [],
            conflictsuni: [],
            conditions: [],
            repeatable: false,
            repeatlimit: 0
          };
          var svstruct = {
            wggk: struct,
            noegkk: struct,
            bgkk: struct,
            ooegkk: struct,
            sgkk: struct,
            stgkk: struct,
            kgkk: struct,
            tgkk: struct,
            bva: struct,
            vaeb: struct,
            sva: struct,
            wkfa: struct,
            skfa: struct
          };
          return svstruct;
        }
      }
    });
  }

  function Unicatalogtree(restmod) {
    return restmod.model('/routes/unicatalog').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      }
    });
  }

}());
