(function () {
  'use strict';
  /**
   * @ngdoc directive
   * @name patient.directive:patientVert
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-vert cols="4" buttons="true"></patient-vert>
   </file>
   </example>
   *
   */
  angular
    .module('patient')
    .directive('patientVert', patientVert);

  function patientVert() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'patient/patient-vert-directive.tpl.html',
      replace: false,
      controllerAs: 'patCtrl',
      bindToController: true,
      controller: 'PatientCtrl',
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        /* eslint "no-unused-vars": [2, {"vars": "none"}] */
        var col = 12;
        var buttons = true;
        col = attrs.col;
        buttons = attrs.buttons;
      }
    };
  }
}());
