/**
 * Created by lari on 17/04/2017.
 */
(function () {
  'use strict';
  angular.module('finance.rksv')
    .component('deplist', {
      restrict: 'E',
      templateUrl: 'finance/rksv/deplist-component.tpl.html',
      controllerAs: 'vm',
      controller: ['$log', 'Deplist',
        function ($log, Deplist) {
          var vm = this;
          vm.currentPage = 1;
          vm.pageChanged = pageChanged;
  
  
          this.$onInit = function () {
            var search = {};
  
            search.page = vm.currentpage;
            search.query = {};
  
            search.options = {};
            search.options.fields = {
              _id: 1,
              created: 1,
              receiptnr: 1,
              type: 1,
              signatureok: 1,
              pdfid: 1,
              storno: 1
            };
  
            vm.dep = Deplist.$search(search).$then(function (dep) {
              vm.totalItems = dep.$pageCount;
            });
          };
          
          function pageChanged() {
            vm.dep.$refresh({page: vm.currentPage}).$then(function (dep) {
              vm.totalItems = dep.$pageCount;
            });
          }
          
        }] // controller
    }); // component

// klammerfriedhof
}());