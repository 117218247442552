(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name config.factory:Config
   *
   * @description
   *
   */
  angular
    .module('config')
    .factory('Config', Config);


  Config.$inject = ['restmod', '$log'];

  function Config(restmod) {
    return restmod.model('/routes/appconfig');
  }
}());
