(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name drug.directive:prescription
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="drug">
   <file name="index.html">
   <prescription patient=""></prescription>
   </file>
   </example>
   *
   */
  angular
    .module('drug')
    .directive('prescription', prescription);
  
  
  prescription.$inject = ['$log', '$http', 'Config', '$window'];
  
  
  function prescription($log, $http, Config, $window) {
    return {
      restrict: 'E',
      scope: {
        patient: '=',
        dialog: '=?'
      },
      templateUrl: 'drug/prescription-directive.tpl.html',
      replace: false,
      controllerAs: 'dpCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        
        vm.conf = Config.$find('configuration');
        
        vm.drugedittpl = 'drug/drugPopoverTemplate.html';
        
        vm.calculateActivedrugs = calculateActivedrugs;
        vm.printPrescription = printPrescription;
        vm.previewPrescription = previewPrescription;
        vm.checkSignatureUpdate = checkSignatureUpdate;
        
        function calculateActivedrugs() {
          var i;
          $log.debug('calcualte active drugs called');
          vm.patient.activedrugs = 0;
          for (i = 0; i < vm.patient.drugs.length; i++) {
            // retire once-off drugs if enddate has been reached
            if (vm.patient.drugs[i].active === true && vm.patient.drugs[i].endDate < moment().toISOString()) {
              vm.patient.drugs[i].active = false;
              vm.patient.drugs[i].print = false;
            }
            // count active, non-foreign drugs that should be printed
            if (vm.patient.drugs[i].active === true && vm.patient.drugs[i].foreign === false && vm.patient.drugs[i].print === true) {
              vm.patient.activedrugs += 1;
            }
          }
          vm.patient.$save();
        }
        
        function printPrescription() {
          if (vm.pdate === true) {
            vm.patient.prescriptiondate = moment().locale('de-at').format('ll');
          }
          else {
            vm.patient.prescriptiondate = '';
          }
          // make sure the prescription date is saved to backend before printing
          vm.patient.$then(function () {
            $log.debug('prescription date saved successfully');
            $http({
              url: '/routes/pdfprinter/patientprint',
              method: 'GET',
              params: {
                patientid: vm.patient._id,
                doctype: 'prescription'
              }
            });
          });
        }
        
        function previewPrescription() {
          var newtab = $window.open('', '_blank');
          if (vm.pdate === true) {
            vm.patient.prescriptiondate = moment().locale('de-at').format('ll');
          }
          else {
            vm.patient.prescriptiondate = '';
          }
          // make sure the prescription date is saved to backend before printing
          vm.patient.$save().$then(function () {
            var link = '/routes/pdfprinter/patientpreview?patientid=' + vm.patient._id + '&doctype=prescription';
            $log.debug('prescription date saved successfully: ', link);
            newtab.location = link;
          });
        }
        
        function checkSignatureUpdate(drug) {
          var tmpdrug = {};
          var item;
          $log.debug('**************** checkSignatureUpdate called for: ', drug);
          if (angular.isString(drug.newamount) && drug.newamount.length > 0) {
            if (drug.newamount !== drug.amount) {
              // copy drug
              for (item in drug) {
                if (drug.hasOwnProperty(item)) {
                  tmpdrug[item] = drug[item];
                }
              }
              // deactive old drug
              drug.active = false;
              drug.endDate = moment().toISOString();
              // create new drug
              tmpdrug.startDate = moment().toISOString();
              tmpdrug.amount = tmpdrug.newamount;
              delete tmpdrug.newamount;
              $log.debug('drug: ', drug);
              $log.debug('tmpdrug: ', tmpdrug);
              $log.debug('patient.drugs before: ', vm.patient.drugs);
              vm.patient.drugs.push(tmpdrug);
              $log.debug('patient.drugs after: ', vm.patient.drugs);
              calculateActivedrugs();
              // no need to save patient, calculateA... already does that!
            }
            else {
              drug.newamount = '';
            }
          }
        }
        
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
