(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name utils.fileuploader.directive:fileModel
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="utils.fileuploader">
       <file name="index.html">
        <file-model></file-model>
       </file>
     </example>
   *
   */
  angular
    .module('utils.fileuploader')
    .directive('fileModel', fileModel);

  fileModel.$inject = ['$parse'];

  function fileModel($parse) {
    return {
      restrict: 'A',

      link: function (scope, element, attrs) {
        var model = $parse(attrs.fileModel);
        var modelSetter = model.assign;

        element.bind('change', function () {
          scope.$apply(function () {
            modelSetter(scope, element[0].files[0]);
            scope.$parent.upload();
          });
        });
      }
    };
  }

// klammer friedhof
}());


