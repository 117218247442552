
  <form name="patdata">
    <div class="panel panel-default">
      <div class="panel-heading" style="padding-top: 1ex">
        <h3 class="panel-title">
          <span ng-show="patCtrl.patient.firstname">{{patCtrl.patient.firstname}} {{patCtrl.patient.lastname}} </span>
          <span ng-hide="patCtrl.patient.firstname">Neuer Patient</span>, <span style="color: #999999">Patientennummer: {{patCtrl.patient._id}}</span>
        </h3>
      </div>
      <div class="panel-body">
        <div class="row">
          <!-- Geschlecht -->
          <div class="form-group col-md-4">
            <label for="gender1">Geschlecht</label>
            <div class="radio">
              <label for="gender1" class="radio-inline">
                <input type="radio" value="weiblich" tabindex="1"
                       ng-model="patCtrl.patient.gender"
                       id="gender1">weiblich</label>
              <label for="gender2" class="radio-inline">
                <input type="radio" value="männlich" tabindex="2"
                       ng-model="patCtrl.patient.gender"
                       id="gender2">männlich</label>
              <label for="gender3" class="radio-inline">
                <input type="radio" value="unbekannt" tabindex="3"
                       ng-model="patCtrl.patient.gender"
                       id="gender3">unbekannt</label>
            </div>
          </div>
          <!-- Testpatient -->
          <div class="form-group col-md-2">
            <label for="testpat">Status</label>
            <div class="form-group">
              <label for="testpat" class="checkbox-inline">
                <input type="checkbox" id="testpat" ng-blur="patCtrl.patient.$save()" tabindex="4"
                       ng-model="patCtrl.patient.test"/> Testpatient</label>
            </div>
          </div>
          <!-- Größe -->
          <div class="form-group col-md-2">
            <div class="form-group">
              <label for="height">Größe (cm)</label>
              <input type="number" id="height" ng-model="patCtrl.patient.height"
                     ng-blur="patCtrl.patient.$save()" tabindex="5"
                     class="form-control" ng-change="patCtrl.calcBMI()"/>
            </div>
          </div>
          <!-- Gewicht -->
          <div class="form-group col-md-2">
            <div class="form-group">
              <label for="weight">Gewicht (kg)</label>
              <input type="number" id="weight" ng-model="patCtrl.patient.weight"
                     ng-blur="patCtrl.patient.$save()" tabindex="6"
                     class="form-control" ng-change="patCtrl.calcBMI()"/>
            </div>
          </div>
          <!-- BMI -->
          <div class="form-group col-md-2">
            <div class="form-group">
              <label for="bmi">BMI</label>
              <input type="number" id="bmi" ng-model="patCtrl.patient.bmi" step="0.1" class="form-control"/>
            </div>
          </div>
        </div>

        <!-- Titel, Name -->
        <div class="row">
          <div class="form-group col-md-2">
            <label for="titlefront">Titel</label>
            <input type="text" ng-model="patCtrl.patient.titlefront" tabindex="7"
                   ng-blur="patCtrl.patient.$save()"
                   class="form-control" id="titlefront"
                   placeholder="Titel">
          </div>
          <div class="form-group col-md-4">
            <label for="firstname">Vorname</label>
            <input type="text" ng-model="patCtrl.patient.firstname" tabindex="8"
                   ng-blur="patCtrl.patient.$save()"
                   class="form-control" id="firstname"
                   placeholder="Vorname">
          </div>
          <div class="form-group col-md-4">
            <label for="lastname">Nachname</label>
            <input type="text" ng-model="patCtrl.patient.lastname" tabindex="9"
                   ng-blur="patCtrl.patient.$save()"
                   class="form-control" id="lastname"
                   placeholder="Nachname">
          </div>
          <div class="form-group col-md-2">
            <label for="titleback">Titel</label>
            <input type="text" ng-model="patCtrl.patient.titleback" tabindex="10"
                   ng-blur="patCtrl.patient.$save()"
                   class="form-control" id="titleback"
                   placeholder="MSc">
          </div>
        </div>


        <div class="row">
          <!-- Geburtsdatum -->
          <div class="form-group col-md-2">
            <label for="birthdate">Geburtsdatum</label>
            <input type="text" ng-model="patCtrl.patient.birthdate" tabindex="11"
                   ng-blur="patCtrl.patient.$save()"
                   class="form-control" id="birthdate"
                   placeholder="Geburtsdatum">
          </div>
          <!-- SVNr -->
          <div class="form-group col-md-4">
            <label for="svnr">SVNr <span ng-show='!patdata.svnr.$valid && patdata.svnr.$dirty' style="color: #cc3333"> ungültig!</span> </label>
            <input type="text" ng-model="patCtrl.patient.svnr" tabindex="12"
                   ng-blur="patCtrl.patient.$save()"
                   validate-s-v-n-r
                   class="form-control" id="svnr" placeholder="nnnnddmmjj">
          </div>
          <!-- Sozialversicherung -->
          <div class="col-md-2">
            <div ng-hide="patCtrl.patient.sv">
              <label for="sv">Sozialversicherung</label>
              <sv-search
                  svres="patCtrl.patient.sv"
                  svresult="patCtrl.patient.svref"
                  ti="13" id="sv"></sv-search>
            </div>
            <div class="form-group" ng-show="patCtrl.patient.sv">
              <label for="svs">Sozialversicherung</label>

              <div class="input-group">
                <input type="text" class="form-control"
                       id="svs"
                       readonly
                       ng-model="patCtrl.patient.sv">
                <span class="input-group-btn">
                    <button class="btn btn-danger"
                            ng-click="patCtrl.clearSv()"><i class="fa fa-remove"></i>
                    </button>
                </span>
              </div>
            </div>
          </div>
          <!-- Versichertengruppe -->
          <div class="form-group col-md-4">
            <label for="svstatus">Versichertengruppe</label>
            <select ng-model="patCtrl.patient.svstatus" tabindex="14"
                    ng-blur="patCtrl.patient.$save()"
                    class="form-control" id="svstatus">
              <option value="01">01 - Erwerbstätig, Arbeitslos, Selbstversichert</option>
              <option value="02">02 - Pensionist(in)</option>
              <option value="03">03 - Kriegshinterbliebene(r)</option>
              <option value="05">05 - Angehörige(r) von 01</option>
              <option value="06">06 - Angehörige(r) von 02</option>
              <option value="08">08 - Anspruch KOVG, OFG, HVG D u. DH</option>
              <option value="10">10 - VAEB (A/B), BVA</option>
              <option value="11">11 - Angehörige(r) von 10</option>
              <option value="12">12 - Sozialversicherungsabkommen</option>
            </select>
          </div>
        </div>

        <div class="row">
          <!-- Telefon -->
          <div class="form-group col-md-6">
            <label for="phone">Telefon</label>
            <input type="text" ng-model="patCtrl.patient.phone" tabindex="15"
                   uib-tooltip="Mehrfacheinträge möglich"
                   ng-blur="patCtrl.patient.$save()"
                   class="form-control" id="phone" placeholder="Telefon">
          </div>
          <!-- E-Mail -->
          <div class="form-group col-md-6">
            <label for="email">E-Mail</label>
            <input type="email" ng-model="patCtrl.patient.email" tabindex="16"
                   ng-blur="patCtrl.patient.$save()"
                   class="form-control" id="email" placeholder="E-Mail">
          </div>
        </div>

        <div class=" row">
          <!-- Adresse -->
          <div class="form-group col-md-6">
            <label for="address">Adresse</label>
            <input type="text" ng-model="patCtrl.patient.address" tabindex="17"
                   ng-blur="patCtrl.patient.$save()"
                   class="form-control" id="address"
                   placeholder="Adresse">
          </div>
          <!-- PLZ & Ort -->
          <div class="form-group col-md-6">
            <zipcode zip="patCtrl.patient.postcode" city="patCtrl.patient.city" ti="18" ti2="19"></zipcode>
          </div>
        </div>

        <!-- Land -->
        <div class=" row">
          <div class="form-group col-md-12" ng-init="patCtrl.patient.country='Österreich'">
            <label for="country">Land</label>
            <input type="text" ng-model="patCtrl.patient.country" tabindex="20"
                   ng-blur="patCtrl.patient.$save()"
                   class="form-control" id="country" value="Österreich">
          </div>
        </div>


        <!-- Arbeitgeber -->
        <div class=" row">
          <div class="form-group col-md-12">
            <label for="employer">Arbeitgeber</label>
            <input type="text" ng-model="patCtrl.patient.employer" tabindex="21"
                   ng-blur="patCtrl.patient.$save()"
                   class="form-control" id="employer"
                   placeholder="Arbeitgeber">
          </div>
        </div>


        <div class=" row">
          <!-- Hausarzt- -->
          <div class="form-group  col-md-6" ng-hide="patCtrl.patient.practdoctor._id">
            <label for="doctor">Hausarzt</label>
            <doctor-search doctorresult="patCtrl.patient.practdoctor" id="doctor" ti="22"></doctor-search>
          </div>
          <div class="form-group col-md-6" ng-show="patCtrl.patient.practdoctor._id">
            <label for="pdoctorname">Hausarzt</label>

            <div class="input-group">
              <input type="text" class="form-control"
                     id="pdoctorname"
                     readonly
                     ng-model="patCtrl.patient.practdoctor.fname">
                <span class="input-group-btn">
                    <button class="btn btn-danger"
                            ng-click="patCtrl.clearPractDoctor()"><i class="fa fa-remove"></i>
                    </button>
                </span>
            </div>
          </div>
          <!-- Überweisender Arzt -->
          <div class="form-group  col-md-6" ng-hide="patCtrl.patient.refdoctor._id">
            <label for="refdoctor">Überweisender Arzt</label>
            <doctor-search doctorresult="patCtrl.patient.refdoctor" id="refdoctor" ti="23"></doctor-search>
          </div>
          <div class="form-group col-md-6" ng-show="patCtrl.patient.refdoctor._id">
            <label for="rdoctorname">Überweisender Arzt</label>

            <div class="input-group">
              <input type="text" class="form-control"
                     id="rdoctorname"
                     readonly
                     ng-model="patCtrl.patient.refdoctor.fname">
                <span class="input-group-btn">
                    <button class="btn btn-danger"
                            ng-click="patCtrl.clearRefDoctor()"><i class="fa fa-remove"></i>
                    </button>
                </span>
            </div>
          </div>
        </div>

        <!-- Mitversicherung -->
        <div class=" row">
          <div class="form-group col-md-6">
            <label for="insuredname">Mitversichert bei (Name)</label>
            <input type="text" ng-model="patCtrl.patient.insuredwith.name" tabindex="24"
                   ng-blur="patCtrl.patient.$save()"
                   class="form-control" id="insuredname"
                   placeholder="Name">
          </div>
          <div class="form-group col-md-6">
            <label for="insurednr">Mitversichert bei (SVNr)</label>
            <input type="text" ng-model="patCtrl.patient.insuredwith.svnr" tabindex="25"
                   ng-blur="patCtrl.patient.$save()"
                   validate-s-v-n-r
                   class="form-control" id="insurednr"
                   placeholder="SVnr">
          </div>
        </div>

        <!-- Risikofaktoren -->
        <!-- Nikotin -->
        <div class="row">
          <div class="col-md-6">
            <label for="nicr">Nikotin</label><br>
            <label class="radio-inline"><input type="radio" name="nicr" ng-value="true" tabindex="26"
                                               ng-change="patCtrl.patient.$save()"
                                               ng-model="patCtrl.patient.risk.nicotin">ja</label>
            <label class="radio-inline"><input type="radio" name="nicr" ng-value="false" tabindex="27"
                                               ng-change="patCtrl.patient.$save()"
                                               ng-model="patCtrl.patient.risk.nicotin">nein</label>
          </div>
          <div class="col-md-6">
            <label for="nicrd">Nikotin Details</label>
            <input type="text" ng-model="patCtrl.patient.risk.nicotindetails" tabindex="28"
                   ng-blur="patCtrl.patient.$save()"
                   class="form-control" id="nicrd"
                   placeholder="Details zum Nikotinkonsum">
          </div>
        </div>

        <!-- Alkohol -->
        <div class="row">
          <div class="col-md-6">
            <label for="alcr">Alkohol</label><br>
            <label class="radio-inline"><input type="radio" name="alcr" ng-value="true" tabindex="29"
                                               ng-change="patCtrl.patient.$save()"
                                               ng-model="patCtrl.patient.risk.alcohol">ja</label>
            <label class="radio-inline"><input type="radio" name="alcr" ng-value="false" tabindex="30"
                                               ng-change="patCtrl.patient.$save()"
                                               ng-model="patCtrl.patient.risk.alcohol">nein</label>
          </div>
          <div class="col-md-6">
            <label for="alcrd">Alkohol Details</label>
            <input type="text" ng-model="patCtrl.patient.risk.alcoholdetails" tabindex="31"
                   ng-blur="patCtrl.patient.$save()"
                   class="form-control" id="alcrd"
                   placeholder="Details zum Alkoholkonsum">
          </div>
        </div>


        <!-- Andere Risiken -->
        <div class="row">
          <div class="col-md-6">
            <label for="or">Andere Risiken</label><br>
            <label class="radio-inline"><input type="radio" name="or" ng-value="true" tabindex="32"
                                               ng-change="patCtrl.patient.$save()"
                                               ng-model="patCtrl.patient.risk.otherb">ja</label>
            <label class="radio-inline"><input type="radio" name="or" ng-value="false" tabindex="33"
                                               ng-change="patCtrl.patient.$save()"
                                               ng-model="patCtrl.patient.risk.otherb">nein</label>
          </div>
          <div class="col-md-6">
            <label for="riskother">Andere Risiken Details</label>
            <textarea ng-model="patCtrl.patient.risk.other" tabindex="34"
                      ng-blur="patCtrl.patient.$save()"
                      placeholder="Andere Risikofaktoren ..." class="form-control" id="riskother"></textarea>
          </div>
        </div>


        <!-- Rezeptgebührenbefreiung -->
        <div class="row">
          <div class="form-group col-md-12">
            <label for="feefree">Rezeptgebührenbefreiung</label>
            <div class="checkbox">
              <label>
                <input type="checkbox" ng-model="patCtrl.patient.feefree" tabindex="35"
                       ng-blur="patCtrl.patient.$save()"
                       id="feefree"
                >&nbsp;befreit
              </label>
            </div>
          </div>
        </div>
      </div>

      <!--
      <div class=" row">
        <div class="form-group col-md-6">
          <label for="iban">Bankverbindung (IBAN)</label>
          <input type="text" ng-model="patCtrl.patient.bank.iban" tabindex="30"
                 ng-blur="patCtrl.patient.$save()"
                 class="form-control" id="iban" placeholder="IBAN">
        </div>
        <div class="form-group col-md-6">
          <label for="swift">Bankverbindung (SWIFT)</label>
          <input type="text" ng-model="patCtrl.patient.bank.swift" tabindex="31"
                 ng-blur="patCtrl.patient.$save()"
                 class="form-control" id="swift"
                 placeholder="SWIFT">
        </div>
      </div>
-->
      <!-- <div class=" row">

           <div class="form-group col-md-12">
               <label>Stichworte zum Patienten</label>
               <div keywords-widget keywords="patCtrl.patient.keywords"></div>
           </div>
       </div>-->
      <!--
              <div class="input-group">
              <span class="input-group-btn">
                <span class="btn btn-default" type="button" ng-click="patCtrl.patient.$save();">Daten speichern</span>
                <span ng-show="patCtrl.conf.devmode" class="btn btn-danger" type="button"
                      ng-click="patCtrl.clearOpenConsult(patient)">Clear openConsult</span>
              </span>
              </div>
      -->
    </div><!-- panelbody -->

    </div><!-- panel -->
  </form>
