<h3>Konfiguration</h3>

<form class="form-vertical">
  <div class="form-group col-md-12">
    <label>Patienten-Memo anstatt der Patienten-Heute Ansicht
      <div class="radio">
        <label for="memo1" class="radio-inline">
          <input type="radio" ng-value="true"
                 ng-model="confCtrl.conf.patientmemo"
                 id="memo1">ja</label>
        <label for="memo2" class="radio-inline">
          <input type="radio" ng-value="false"
                 ng-model="confCtrl.conf.patientmemo"
                 id="memo2">nein</label>
      </div>
    </label>
  </div>
<!--  <div class="form-group col-md-12">
    <label>Kalender
      <div class="radio">
        <label for="cal1" class="radio-inline">
          <input type="radio" ng-value="true"
                 ng-model="confCtrl.conf.calendar"
                 id="cal1">ja</label>
        <label for="cal2" class="radio-inline">
          <input type="radio" ng-value="false"
                 ng-model="confCtrl.conf.calendar"
                 id="cal2">nein</label>
      </div>
    </label>
  </div>-->
<!--  <div class="form-group col-md-12">
    <label>Benutzeraccounts aktiv
      <div class="radio">
        <label for="user1" class="radio-inline">
          <input type="radio" ng-value="true"
                 ng-model="confCtrl.conf.useraccounts"
                 id="user1">ja</label>
        <label for="user2" class="radio-inline">
          <input type="radio" ng-value="false"
                 ng-model="confCtrl.conf.useraccounts" id="user2">nein</label>
      </div>
    </label>
  </div>-->
<!--  <div class="form-group col-md-12">
    <label>Freiform Rechnung
      <div class="radio">
        <label for="bill1" class="radio-inline">
          <input type="radio" ng-value="true"
                 ng-model="confCtrl.conf.freeformbill" id="bill1">ja</label>
        <label for="bill2" class="radio-inline">
          <input type="radio" ng-value="false"
                 ng-model="confCtrl.conf.freeformbill" id="bill2">nein</label>
      </div>
    </label>
  </div>-->

  <div class="form-group col-md-12">
    <label>Developer mode
      <div class="radio">
        <label for="devm1" class="radio-inline">
          <input type="radio" ng-value="true"
                 ng-model="confCtrl.conf.devmode" id="devm1">ja</label>
        <label for="devm2" class="radio-inline">
          <input type="radio" ng-value="false"
                 ng-model="confCtrl.conf.devmode" id="devm2">nein</label>
      </div>
    </label>
  </div>
<!--

  <div class="form-group">
    <label for="maincolor">Hauptfarbe <span style="background-color:{{confCtrl.conf.printconf.maincolor}}">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
    <input type="text" ng-model="confCtrl.conf.printconf.maincolor" class="form-control" id="maincolor">
  </div>

  <div class="form-group">
    <label for="highlightcolor">Highlight-Farbe <span
      style="background-color:{{confCtrl.conf.printconf.highlightcolor}}">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
    <input type="text" ng-model="confCtrl.conf.printconf.highlightcolor" class="form-control" id="highlightcolor">
  </div>

  <div class="form-group">
    <label for="shadowcolor">Highlight-Farbe <span style="background-color:{{confCtrl.conf.printconf.shadowcolor}}">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></label>
    <input type="text" ng-model="confCtrl.conf.printconf.shadowcolor" class="form-control" id="shadowcolor">
  </div>

-->

  <h3>DMS</h3>
  <div class="form-group">
    <label>Kategorien für patientenbezogene Dokumente</label>
    <list-editor ar="confCtrl.conf.dmspatientcat"></list-editor>
  </div>

  <div class="form-group">
    <label>Kategorien für generische Dokumente</label>
    <list-editor ar="confCtrl.conf.dmsgencat"></list-editor>
  </div>
  

  
  <h3>Medikamenten-Signaturen</h3>
  <div class="form-group">
    <label>Häufig gebrauchte Medikamentensignaturen</label>
    <list-editor ar="confCtrl.conf.drugsignature"></list-editor>
  </div>
  
  
  <h3>Drucker</h3>

  <!-- <div class="form-group">
    <label for="refprinter">Drucker für Verordnungen und Überweisungen</label>
    <span ng-show="confCtrl.conf.printers.refprinter">Derzeit gewählt: {{confCtrl.conf.printers.refprinter}} </span>
    <select ng-model="confCtrl.conf.printers.refprinter" class="form-control" id="refprinter">
      <option value="{{p.printername}}" ng-repeat="p in confCtrl.allprinters">{{p.description}}</option>
    </select>
  </div>
  <div class="form-group">
    <label for="prescriptionprinter">Drucker für Rezepte</label>
    <span ng-show="confCtrl.conf.printers.refprinter">Derzeit gewählt: {{confCtrl.conf.printers.refprinter}} </span>
    <select ng-model="confCtrl.conf.printers.prescriptionprinter" class="form-control" id="prescriptionprinter">
      <option value="{{p.printername}}" ng-repeat="p in confCtrl.allprinters">{{p.description}}</option>
    </select>
  </div>
  <div class="form-group">
    <label for="findingsprinter">Drucker für Befunde</label>
    <span ng-show="confCtrl.conf.printers.findingsprinter">Derzeit gewählt: {{confCtrl.conf.printers.findingsprinter}} </span>
    <select ng-model="confCtrl.conf.printers.findingsprinter" class="form-control" id="findingsprinter">
      <option value="{{p.printername}}" ng-repeat="p in confCtrl.allprinters">{{p.description}}</option>
    </select>
  </div>
  <div class="form-group">
    <label for="labelprinter">Drucker für Biopsie-Labels</label>
    <span ng-show="confCtrl.conf.printers.labelprinter">Derzeit gewählt: {{confCtrl.conf.printers.labelprinter}} </span>
    <select ng-model="confCtrl.conf.printers.labelprinter" class="form-control" id="labelprinter">
      <option value="{{p.printername}}" ng-repeat="p in confCtrl.allprinters">{{p.description}}</option>
    </select>
  </div>-->
  <div class="form-group">
    <label for="invoiceprinter">Drucker für Rechnungen</label>
    <span ng-show="confCtrl.conf.printers.invoiceprinter">Derzeit gewählt: {{confCtrl.conf.printers.invoiceprinter}} </span>
    <select ng-model="confCtrl.conf.printers.invoiceprinter" class="form-control" id="invoiceprinter">
      <option value="{{p.printername}}" ng-repeat="p in confCtrl.allprinters">{{p.description}}</option>
    </select>
  </div>
  <div class="form-group">
    <label for="receiptprinter">Drucker für Belege</label>
    <span ng-show="confCtrl.conf.printers.receiptprinter">Derzeit gewählt: {{confCtrl.conf.printers.receiptprinter}} </span>
    <select ng-model="confCtrl.conf.printers.receiptprinter" class="form-control" id="receiptprinter">
      <option value="{{p.printername}}" ng-repeat="p in confCtrl.allprinters">{{p.description}}</option>
    </select>
  </div>

<!--  <h3>Firma</h3>

  <div class="form-group col-md-12">
    <label for="compname">Firma</label>
    <input type="text" ng-model="confCtrl.conf.company.name" placeholder="Firmenname bzw. Arztname" id="compname"  class="form-control" >
  </div>
  <div class="form-group col-md-12">
    <label for="comptax">Steuernummer</label>
    <input type="text" ng-model="confCtrl.conf.company.taxnr" placeholder="Steuernummer" id="comptax" class="form-control" >
  </div>
  <div class="form-group col-md-12">
    <label for="compaddress">Adresse</label>
    <input type="text" ng-model="confCtrl.conf.company.address" placeholder="Straße Hausnummer" id="compaddress" class="form-control" >
  </div>
  <div class="form-group col-md-12">
    <label for="compcity">Ort</label>
    <input type="text" ng-model="confCtrl.conf.company.city" placeholder="PLZ Ort" id="compcity" class="form-control" >
  </div>-->



  <div class="form-group col-md-12">
    <button type="submit" class="btn btn-success"
            ng-click="confCtrl.conf.$save()">Speichern
    </button>
  </div>
</form>



