(function () {
  'use strict';

  angular
    .module('examtemplate')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('examtemplate', {
        url: '/examtemplate',
        views: {
          main: {template: '<templategenerator></templategenerator>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<h3>I\'m a chat window</h3>'}
        }
      }
    );
  }


}());
