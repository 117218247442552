(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name labvalue.factory:Labvalue
   *
   * @description
   *
   */
  angular
    .module('labvalue')
    .factory('Labvalue', Labvalue);

  Labvalue.$inject = ['restmod', '$log'];

  function Labvalue(restmod, $log) {
    return restmod.model('/routes/appconfig');
  }



  /*
  function Labvalue(restmod, $log) {
    // var model = restmod.model('/routes/labconfig');
    var model = restmod.model('/routes/appconfig');
    var values = [
      'Blutbild u. Diff',
      'BSG', 'CRP', 'GOT', 'GPT', 'GGT', 'AP', 'Bili', 'Cholinesterase', 'Lipase', 'Amylase', 'Aldolase', 'CK', 'LDH', 'BUN', 'CREA',
      'Natrium', 'Kalium', 'Chlorid', 'Calcium', 'Magnesium', 'Harns\u00E4ure',
      'Elektrophorese', 'Gesamteiweiss', 'Albumin', 'Cholesterin gesamt', 'HDL', 'LDL', 'HDL/LDL',
      'Triglyceride', 'Glukose', 'Hba1c', 'TSH', 'Vit. B12', 'Fols\u00E4ure', 'PTZ', 'Lues', 'EBV', 'CMV',
      'Toxoplasmose', 'Borreliose', 'Brucella', 'ANA', 'DNA', 'ASMA', 'APCA', 'AMA',
      'LKM', 'Fe', 'Transferrin', 'Ferritin', 'sTFR', 'Ferritinindex', 'Transferrins\u00E4ttigung', 'Retikulozyten',
      'Hepatitisserologie (A, B, C, E)',
      'ASAT', 'ALAT', 'Anti-TSH-Rezeptor', 'Anti-TG-Antik\u00F6rper', 'Anti-TPO-Antik\u00F6rper',
      'Blutsenkung', 'Serumelektrophorese', 'ASAT', 'ALAT', 'Gamma-GT', 'AP', 'Bilirubin', 'CRP', 'Calcium',
      'Phosphat', 'Kreatinin', 'TSH', 'Parathormon', 'ALSO', 'RF', 'Fibrinogen', 'Ammoniak', 'T3', 'T4', 'fT3', 'fT4',
      'gammaGT', 'Phosphat', 'Harnstoff-N (BUN)', 'Chlamydien', 'Borrela burgdorferi', 'Mycoplasma pneumoniae',
      'Salmonella', 'Yersinia', 'Brucella', 'HEP B/C', 'HIV', 'Parvovirus B19 (Ringelr\u00F6teln)', 'ACE-Spiegel', 'ANA',
      'Totalprotein', 'Albumin', '25-Hydroxy-Vitamin D', 'Alpha-Feto-Protein', 'CDT', 'Cortisol', 'Renin/Aldosteron Ratio',
      'Trop T hs', 'D-Dimer', 'Rest-N'];

    var defaultvalues = uniq(values);


    var defaultgroups =
      [
        {
          name: 'Gesamt',
          values: uniq(['Blutbild u. Diff', 'BSG', 'CRP', 'GOT', 'GPT', 'GGT', 'AP', 'Bili', 'Cholinesterase', 'Lipase',
            'Amylase', 'CK', 'LDH', 'BUN', 'CREA',
            'Natrium', 'Kalium', 'Chlorid', 'Calcium', 'Magnesium', 'Harns\u00E4ure',
            'Elektrophorese', 'Gesamteiweiss', 'Albumin', 'Cholesterin gesamt', 'HDL', 'LDL',
            'Triglyceride', 'Glucose', 'Hba1c', 'TSH', 'Vit. B12', 'Fols\u00E4ure', 'PTZ', 'Fe', 'Ferritin', 'Transferrins\u00E4ttigung'])
        },
        {name: 'ANA + Subsets (bei pos. ANA)', values: uniq(['ANA', 'DNA', 'ASMA', 'APCA', 'AMA', 'LKM'])},
        {
          name: 'Infektionen',
          values: uniq(['Hepatitisserologie (A, B, C, E)', 'Lues', 'EBV', 'CMV', 'Toxoplasmose', 'Borreliose', 'Brucella'])
        },
        {name: 'Eisenstatus', values: uniq(['Fe', 'Ferritin', 'Transferrins\u00E4ttigung'])},
        {
          name: 'Eisenstoffwechsel',
          values: uniq(['Fe', 'Transferrin', 'Ferritin', 'sTFR', 'Ferritinindex', 'CRP', 'Retikulozyten'])
        },
        {name: 'Herz', values: ['LDH', 'CK', 'Trop T hs']},
        {name: 'Schilddr\u00FCse', values: uniq(['ASAT', 'ALAT', 'Calcium', 'TSH'])},
        {
          name: 'Schilddr\u00FCsen-Antik\u00F6rper',
          values: uniq(['Anti-TSH-Rezeptor', 'Anti-TG-Antik\u00F6rper', 'Anti-TPO-Antik\u00F6rper'])
        },
        {
          name: 'Osteoporose',
          values: uniq(['Blutsenkung', 'Serumelektrophorese', 'ASAT', 'ALAT', 'gammaGT', 'AP', 'Bilirubin', 'CRP', 'Calcium',
            'Phosphat', 'Kreatinin', 'TSH', 'Parathormon'])
        },
        {name: 'Rheuma', values: uniq(['ALSO', 'RF', 'CRP'])},
        {
          name: 'Niere',
          values: uniq(['BUN', 'Natrium', 'Kalium', 'Chlorid', 'Phosphat', 'Kreatinin', 'Calcium', 'Rest-N', 'Harns\u00E4ure'])
        },
        {
          name: 'Leber', values: uniq(['PTZ', 'D-Dimer', 'Fibrinogen', 'Ammoniak', 'CRP', 'Natrium', 'Kalium', 'Chlorid',
          'Phosphat', 'Kreatinin', 'Magnesium', 'LDH', 'BUN',
          'ASAT', 'ALAT', 'Gamma-GT', 'AP', 'Bilirubin', 'Lipase', 'Amylase', 'TSH', 'HDL/LDL', 'Hba1c', 'Harns\u00E4ure', 'Glukose', 'Triglyceride',
          'Totalprotein', 'Albumin', '25-Hydroxy-Vitamin D', 'Alpha-Feto-Protein', 'CDT', 'Serumelektrophorese', 'Vit. B12', 'Fols\u00E4ure'])
        }

      ];

    var newconf = {};
    var labconfig;
    var labhandle = {};
    var initialised = false;

    function uniq(a) {
      return a.sort().filter(function (item, pos, ary) {
        return !pos || item !== ary[pos - 1];
      });
    }

    function init() {
      if (!initialised) {
        initialised = true;

        model.$find('labconfiguration').$then(function (labconfig) {
          if (labconfig === {}) {
            newconf = arraysToTagsArray(defaultvalues, defaultgroups);

            //labconfig = labconfigs.$create(newconf).$reveal();
            labconfig.values = newconf.values;
            labconfig.groups = newconf.groups;
            labconfig.$save();

            $log.debug('new labconfig created: ', labconfig);
          }
          else {

            $log.debug('labconfig read from server: ', labconfig);
          }
        });
      }
    }


    function replaceConfig(changedconfig) {
      var i;
      var changedgroups = [];
      if (angular.isUndefined(changedconfig)) {
        changedconfig = {};
        changedconfig.values = [];
        changedconfig.groups = [{name: '', values: []}];
      }
      labconfig.values = uniq(changedconfig.values);
      for (i = 0; i < changedconfig.groups.length; i++){
        changedgroups.push({
          name: changedconfig.groups[i].name,
          values: uniq(changedconfig.groups[i].values)
        });
      }
      labconfig.groups = changedgroups;
      labconfig.$save();
    }

    function arraysToTagsArray(values, groups) {
      var config = {};
      var i;
      var j;
      var grouptagsarray = [];
      config.groups = [];
      config.values = [];
      for (i = 0; i < values.length; i++) {
        config.values.push({name: values[i]});
      }
      for (i = 0; i < groups.length; i++) {
        grouptagsarray = [];
        for (j = 0; j < groups[i].values.length; j++) {
          grouptagsarray.push({name: groups[i].values[j]});
        }
        config.groups.push({name: groups[i].name, values: grouptagsarray});
      }
      return config;
    }

    labhandle.getlabconfig = function () {
      return labconfig;
    };

    labhandle.init = init;
    labhandle.labconfig = labconfig;
    labhandle.replaceconfig = replaceConfig;

    return labhandle;
  }
*/

// klammerfriedhof
}());


/*
 CED-Block:
 Blutbild komplett (incl. Diff), PTZ, aPTT, Fibrinogen, Natrium, Kalium, Chlorid, ASAT, ALAT, Gamma-GT, AP,
 Cholinesterase, Lipase, Amylase, LDH, BUN, Kreatinin, Harnsäure, Totalprotein, Albumin, Bilirubin, Glukose,
 Triglyceride, Cholesterin (HDL, LDL), Cholesterin/HDL Ratio, Transferrin, Eisen, Ferritin, CRP, TSH, Serumelektrophorese

 Diabetesblock:
 Natrium, Kalium, Chlorid, ASAT, ALAT, Gamma-GT, AP, Cholinesterase, Lipase, Amylase, LDH, BUN, Kreatinin,
 Harnsäure, Glukose, Cholesterin (HDL, LDL), Triglyceride, CRP, Hba1c

 Onko-Aufnahme:
 Blutbild komplett (incl. Diff), Retikulozyten, PTZ, aPTT, Fibrinogen, Natrium, Kalium, Chlorid, Calcium, ASAT, ALAT,
 gamma-GT, AP, LDH, Cholinesterase, Harnstoff (BUN), Kreatinin, Harnsäure, CK, Totalprotein, Albumin, Bilirubin,
 Glukose, Triglyceride, Cholesterin, Transferrin, Eisen, Ferritin, CRP, TSH, Serumelektrophorese, Blutsenkung

 Infektiöser Arthritisblock:
 ASLO, CRP, RF, Chlamydien, Borrela burgdorferi, Mycoplasma pneumoniae, Salmonella, Yersinia, Brucella,
 hepb/c, hiv, parvovirus b19 (Ringelröteln), ACE-spiegel, ANA

 Hypertonieblock:
 Blutbild + Diff, bsg, crp, got, gpt, ggt, ap, bili, cholinesterase,
 lipase, amylase, ck, ldh, bun, crea, natrium, kalium, chlorid, calcium, magnesium, harnsäure,
 elektrophorese, gesamteiweiss, albumin, cholesterin (chol-gesamt,hdl, ldl), triglyceride,
 glucose, hba1c, tsh, Eisenstatus (fe, transferrinsättigung, Ferritin),
 vit. B12, Folsäure, PTZ,
 Cortisol, Renin/Aldosteronratio
 */
