(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name tracker.directive:editTracker
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="tracker">
   <file name="index.html">
   <edit-tracker></edit-tracker>
   </file>
   </example>
   *
   */
  angular
    .module('tracker')
    .directive('editTracker', editTracker);
  
  
  editTracker.$inject = ['$log'];
  
  function editTracker($log) {
    return {
      restrict: 'EA',
      scope: {
        tracker: '=',
        patient: '='
      },
      templateUrl: 'tracker/edit-tracker-directive.tpl.html',
      replace: false,
      controllerAs: 'etCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'editTracker';
        
        vm.recalcRef = recalcRef;
        vm.addDatapoint = addDatapoint;
        function addDatapoint() {
          var tmp = {};
          var max = {};
          var min = {};
          tmp.x = vm.newdp.x;
          tmp.y = vm.newdp.y;
          tmp.group = 0;
          $log.debug('new datapoint: ', tmp);
          // check for data array
          if (!angular.isArray(vm.patient.trackers[vm.tracker].items)) {
            vm.patient.trackers[vm.tracker].items = [];
          }
          
          // push data
          vm.patient.trackers[vm.tracker].items.push(tmp);
          
          // push reference data
          if (angular.isNumber(vm.tracker.normmin) && angular.isNumber(vm.tracker.normmax)) {
            max.x = vm.newdp.x;
            max.y = vm.tracker.normmax;
            max.group = 1;
            min.x = vm.newdp.x;
            min.y = vm.tracker.normmin;
            min.group = 2;
            vm.patient.trackers[vm.tracker].items.push(max);
            vm.patient.trackers[vm.tracker].items.push(min);
          }
          
          
          vm.patient.$save();
          vm.newpd = {};
        }
        
        function recalcRef() {
          var i;
          for (i = 0; i < vm.patient.trackers[vm.tracker].items.length; i++) {
            if (vm.patient.trackers[vm.tracker].items[i].group === 1) {
              vm.patient.trackers[vm.tracker].items[i].y = vm.patient.trackers[vm.tracker].normmax;
            }
            if (vm.patient.trackers[vm.tracker].items[i].group === 2) {
              vm.patient.trackers[vm.tracker].items[i].y = vm.patient.trackers[vm.tracker].normmin;
            }
          }
          vm.patient.$save();
        }
        
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
