(function () {
  'use strict';

  /* @ngdoc object
   * @name drug
   * @description
   *
   */
  angular
    .module('drug', [
      'ui.router',
      'ui.bootstrap'
    ]);
}());
