(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name document.directive:documentList
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="document">
   <file name="index.html">
   <document-list patient=""></document-list>
   </file>
   </example>
   *
   */
  angular
    .module('document')
    .directive('documentList', documentList);

  documentList.$inject = ['Document'];

  function documentList(Document) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'document/document-list-directive.tpl.html',
      replace: false,
      controllerAs: 'docCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.ctrlName = 'DocumentCtrl';
        vm.patDocuments = Document.$search({patientid: vm.patient._id});
        // this necessery for filter/sort table directive!
        vm.pdocs = vm.patDocuments;
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */

      }
    };
  }
}());
