(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name unicatalog.factory:Unicatalogtree
   *
   * @description
   *
   */
  angular
    .module('unicatalog')
    .factory('Unicatalogtree', Unicatalogtree);

  Unicatalogtree.$inject = ['restmod'];

  function Unicatalogtree(restmod) {
    return restmod.model('/routes/unicatalogtree').mix({
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      updated: {
        init: function () {
          var updates = [];
          updates.push(new Date().toISOString());
          return updates;
        }
      },
      active: {init: true}
    });
  }

}());
