(function () {
  'use strict';

  angular
    .module('unicatalog')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('unicatalog', {
        url: '/unicatalog',
        views: {
          main: {template: '<catalog-editor></catalog-editor>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });




  }
}());
