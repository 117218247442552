(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name login.directive:login
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="login">
       <file name="index.html">
        <login></login>
       </file>
     </example>
   *
   */
  angular
    .module('login')
    .directive('login', login);

  login.$inject = ['$http', '$stateParams', '$state', '$location', '$log', '$cookies', '$window'];

  function login($http, $stateParams, $state, $location, $log, $cookies, $window) {
    return {
      restrict: 'EA',
      scope: {
        url: '=',
        dialog: '='
      },
      templateUrl: 'login/login-directive.tpl.html',
      replace: false,
      controllerAs: 'liCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'login';
        vm.error = false;
        vm.sendcreds = sendcreds;

        function sendcreds() {
          $http.post('/routes/login',
            {username: vm.username, password: vm.password})
            .then(function () {
              $cookies.put('username', vm.username);
              $log.debug('redirect url: ', vm.url);
              $log.debug('dialog: ', vm.dialog);
              vm.dialog.close();
              if (vm.url) {
                $log.debug('$window: ', $window.location);
                $window.location.href = '#' + vm.url;
                // $location.url(vm.url);
                $window.location.reload();
                //
                $log.debug('after login redirect active');
                // $state.go($state.$current, null, { reload: true });
              }
              else {
                $state.go('home', null, {reload: true});

              }
            },
              function () {
                $log.debug('login error');
                vm.username = '';
                vm.password = '';
                vm.error = true;
              });
        }


      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
