(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name file.directive:fileDemo
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="file">
   <file name="index.html">
   <file-demo></file-demo>
   </file>
   </example>
   *
   */
  angular
    .module('file')
    .directive('fileDemo', fileDemo);

  function fileDemo() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'file/file-demo-directive.tpl.html',
      replace: false,
      controller: 'FileCtrl',
      controllerAs: 'fileCtrl',
      bindToController: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
