(function () {
  'use strict';
  
  /**
   * @ngdoc service
   * @name examination.factory:ExamHelper
   *
   * @description
   *
   */
  angular
    .module('examination')
    .factory('ExamHelper', ExamHelper);
  
  ExamHelper.$inject = ['$log', '$http', '$uibModal'];
  
  function ExamHelper($log, $http, $uibModal) {
    var ExamHelperBase = {};
    
    ExamHelperBase.editExam = editExam;
    ExamHelperBase.deleteExam = deleteExam;
// yep, this is weird, but editIamge is needed both inside the Helper and inside the modal
    ExamHelperBase.editImage = editImage;
    
    function deleteExam(exam, patient) {
      exam.active = false;
      exam.$save().$asPromise().then(function () {
        patient.openConsult.$refresh();
      });
      
    }
    
    function editExam(exam, patient) {
      $log.debug('editExam in ExamHelper');
      $log.debug('exam: ', exam);
      $log.debug('patient: ', patient);
      // vmo.exam = exam;
      // vmo.patient = patient;
      // vmo.modalinstance = $uibModal.open({
      var modalinstance = $uibModal.open({
        // templateUrl: 'tepledittpl',
        templateUrl: 'examination/exam-helper.tpl.html',
        size: 'lg',
        backdrop: 'static',
        controllerAs: 'eeCtrl',
        controller: ['$uibModalInstance', 'Examination', '$window', '$rootScope', 'Rlog',
          function EditExamCtrl($uibModalInstance, Examination, $window, $rootScope, Rlog) {
            var vm = this;
            
            vm.exam = exam;
            // this doesn't work -> circular structure thanks to restmod
            // vm.exambackup = JSON.parse(JSON.stringify(exam));
            
            vm.patient = patient;
            
            vm.Examination = Examination;
            
            vm.addBiopsyField = addBiopsyField;
            vm.printBiopsyLabels = printBiopsyLabels;
            vm.saveAndQuit = saveAndQuit;
            vm.cancel = cancel;
            vm.editImage = editImage;
            
            function addBiopsyField() {
              $log.debug('addBioLab exam: ', vm.exam);
              var biopsy = {
                label: '',
                avalues: [],
                values: []
              };
              vm.exam.template.biopsy.biopsies.push(biopsy);
              vm.exam.$save();
            }
            
            function printBiopsyLabels() {
              $http({
                url: '/routes/pdfprinter/examprint',
                method: 'GET',
                params: {
                  patientid: vm.patient._id,
                  examid: vm.exam._id,
                  doctype: 'labels'
                }
              }).success(function (p) {
                vm.labelsprinted = true;
              });
            }
            
            
            function saveAndQuit() {
              var i = 0;
              
              // build diagnosis string from parts for quick reference
              if (angular.isObject(vm.exam.template.diagnosis)) {
                vm.exam.diagnosis = '';
                for (i = 0; i < vm.exam.template.diagnosis.avalues.length; i++) {
                  vm.exam.diagnosis += vm.exam.template.diagnosis.avalues[i].name;
                  if (i !== vm.exam.template.diagnosis.avalues.length - 1) {
                    vm.exam.diagnosis += ', ';
                  }
                }
              }
              
              // catch exameditclosed in calling directive to handle mandatory pdf generation!
              /*              vm.exam.$save().$asPromise().then(function reallyClose() {
               $uibModalInstance.close();
               $rootScope.$broadcast('exameditclosed');
               });*/
              
              // this is the final word in save finishing!!!!!
              if (vm.patient.openExam.$hasPendingActions()) {
                $log.debug('exam has pending actions!');
                Rlog.debug('examination', 'exam-helper-factory', 'openExam has pending actions!',
                  {openExamId: vm.patient.openExam._id, openExamType: vm.patient.openExam.template.type, patientid: vm.patient._id});
                vm.exam.$cancel();
                quit();
              }
              else {
                $log.debug('finished instantly');
                quit();
              }
            }
            
            // save, quit, emit save signal after save is confirmed!
            function quit() {
              vm.exam.$save().$then(function () {
                $rootScope.$broadcast('exameditclosed');
                Rlog.debug('examination', 'exam-helper-factory', 'exam-n-quit called for: ' + exam._id, {exam: exam._id});
                $uibModalInstance.close();
              });
              
            }
            
            
            // quit without saving
            function cancel() {
              $uibModalInstance.close();
            }
            
            function editImage(img) {
              $uibModal.open({
                template: '<imglabeler dialog="miCtrl.dialog" img="miCtrl.img"/>',
                size: 'lg',
                controller: ['$uibModalInstance', 'image', function ($uibModalInstance, image) {
                  var vmi = this;
                  vmi.dialog = $uibModalInstance;
                  vmi.img = image;
                  $log.debug('editImg modal controller function');
                }],
                controllerAs: 'miCtrl',
                resolve: {
                  image: function () {
                    $log.debug('editImg resolve function');
                    return img;
                  }
                }
              });
            } // editImage
            
            
          }], // editExam modal controller
        resolve: {
          exam: function () {
            return exam;
          },
          patient: function () {
            return patient;
          }
        }
      }); // editExam modal open
    } // editExam
    
    
    // yep, this is weird, but editIamge is needed both inside the Helper and inside the modal
    // TODO: refactor in a meaningful way
    function editImage(img) {
      $uibModal.open({
        template: '<imglabeler dialog="miCtrl.dialog" img="miCtrl.img"/>',
        size: 'lg',
        controller: ['$uibModalInstance', 'image', function ($uibModalInstance, image) {
          var vmi = this;
          vmi.dialog = $uibModalInstance;
          vmi.img = image;
          $log.debug('editImg modal controller function');
        }],
        controllerAs: 'miCtrl',
        resolve: {
          image: function () {
            $log.debug('editImg resolve function');
            return img;
          }
        }
      });
    } // editImage
    
    return ExamHelperBase;
  }
  
  // klammerfriedhof
}());
