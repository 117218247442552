(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name share.directive:shareEditor
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="share">
   <file name="index.html">
   <share-editor></share-editor>
   </file>
   </example>
   *
   */
  angular
    .module('share')
    .directive('shareEditor', shareEditor);

  function shareEditor() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'share/share-editor-directive.tpl.html',
      replace: false,
      controllerAs: 'shareCtrl',
      controller: 'ShareCtrl',
      bindToController: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
