(function () {
    'use strict';

    angular
      .module('docsaver')
      .config(config);

    config.$inject = ['$urlRouterProvider'];

    // when using states you only need the default route
    function config($urlRouterProvider) {
      $urlRouterProvider.when('/', '');
      $urlRouterProvider.otherwise('/home');
    }
  }()
);
