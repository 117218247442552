(function () {
  'use strict';

  angular
    .module('labvalue')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('labvalue', {
        url: '/labvalue',
        views: {
          main: {template: '<labvalue-editor></labvalue-editor>'},
          // leftbar: {template: '<patient-today></patient-today>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<h3>I\'m a chat window</h3>'}
        }
      });
  }



}());
