/**
 * Created by lari on 12/01/2017.
 */

(function () {
  'use strict';
  /**
   * @ngdoc directive
   * @name todo.directive:todowidget
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="todo">
   <file name="index.html">
   <todowidget></todowidget>
   </file>
   </example>
   *
   */
  
  // var todowidget;
  
  angular
    .module('todo')
    .component('todowidget', {
      restrict: 'E',
      templateUrl: 'todo/todowidget-directive.tpl.html',
      controllerAs: 'vm',
      controller: ['Todo', '$log', '$cookies', '$window', 'vertxEventBusService', '$interval', '$timeout',
        function (Todo, $log, $cookies, $window, vertxEventBusService, $interval, $timeout) {
          var vm = this;
          
          vm.todos = {};
  
          vm.save = save;
          vm.add = add;
          vm.calcdue = calcdue;

          // initalise data bindings
          vm.todos.upcoming = upcoming();
          vm.todos.overdue = dueandoverdue();
          vm.todos.lastcompleted = lastcompleted();
          
          // after a minute start repeating refreshs every 10 minutes
          $timeout(function () {
            $interval(function () {
              refreshAll();
            }, 600000); }, 60000);
          
          function add() {
            vm.newitem.done = false;
            vm.newitem.user = $cookies.get('username');
            if (!vm.newitem.due) {
              vm.newitem.due = moment().toISOString();
              vm.newitem.duestr = 'Sofort';
            }
            Todo.$create(vm.newitem).$then(function () {
              vm.todos.upcoming.$refresh();
              vm.todos.overdue.$refresh();
              vm.todos.lastcompleted.$refresh();
            });
            vm.newitem = '';
          }
          
          function save(item) {
            if (item.done === true) {
              item.completed = moment().toISOString();
            }
            else {
              delete item.completed;
            }
            item.$save().$then(function () {
              refreshAll();
            });
          }
          
          function refreshAll() {
            vm.todos.upcoming.$refresh();
            vm.todos.overdue.$refresh();
            vm.todos.lastcompleted.$refresh();
          }
          
          function calcdue(days, str) {
            vm.newitem.due = moment().add(days, 'days').toISOString();
            vm.newitem.duestr = str;
          }
          
          function lastcompleted() {
            return Todo.$search({
              completed: {$gte: moment().subtract(3, 'days').toISOString()},
              done: true
            });
          }
          
          function upcoming() {
            return Todo.$search({
              due: {$gte: moment().add('days', 1).startOf('day').toISOString()},
              done: false
            });
          }
          
          function dueandoverdue() {
            return Todo.$search({
              due: {$lt: moment().add('days', 1).startOf('day').toISOString()},
              done: false
            });
          }
          
          // listen for todos updates from other tabs
          vertxEventBusService.on('todos.update', function (message) {
            var messages = message.split(':');
            $log.debug('Todos updated: ', message);
            if (messages[1] !== $window.sessionStorage.getItem('sprtabid')) {
              refreshAll();
              $log.debug('(todo) refreshed todos');
            }
            else {
              $log.debug('(todo) ignored todo refresh');
            }
          });
        }]
    });
  
  // klammerfriedhof
}());
