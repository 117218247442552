

<div class="btn btn-success" ng-click="svCtrl.addSv()">Neue Sozialversicherung anlegen</div>

<h3>Liste</h3>
<table class="table table-striped">
  <thead>
  <tr>
    <th>SV Kürzel</th>
    <th>Vollername</th>
    <th>Adresse</th>
    <th>PLZ</th>
    <th>Ort</th>
    <th>Ort</th>
  </tr>
  </thead>
  <tr ng-repeat="sv in svCtrl.svlist">
    <td>{{sv.short}}</td>
    <td>{{sv.name}}</td>
    <td>{{sv.address}}</td>
    <td>{{sv.zipcode}}</td>
    <td>{{sv.city}}</td>
    <td>
      <button class="btn btn-primary" ng-click="svCtrl.editSv(sv)">Editeren</button>
      <button class="btn btn-danger" ng-click="svCtrl.removeSv(sv)">Löschen</button>
    </td>
  </tr>
</table>
