(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name patient.directive:patientDrugView
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-drug-view drugs="patient.drugs"></patient-drug-view>
   </file>
   </example>
   *
   */
  angular
    .module('drug')
    .directive('patientDrugView', patientDrugView);
  
  // patientDrugView.$inject = ['$scope', 'Pdfprinter', '$state', 'Drug', 'Drugsearch', 'Printer', '$log', 'Config'];
  patientDrugView.$inject = ['Drugsearch', 'Drug', '$log', '$sce', 'Config'];
  
  // TODO figure out if switichuing this control to  bindToController Syntax is possible
  function patientDrugView(Drugsearch, Drug, $log, $sce, Config) {
    return {
      restrict: 'E',
      scope: {
        patient: '='
      },
      templateUrl: 'drug/patient-drug-view-directive.tpl.html',
      replace: false,
      controllerAs: 'pdvCtrl',
      bindToController: true,
      // controller: 'PatientDrugsCtrl'
      controller: function PatientDrugViewCtrl($uibModal) {
        var vm = this;
        vm.conf = Config.$find('configuration');
        vm.data = null;
        vm.getDrugs = getDrugs;
        vm.showdruginfo = showdruginfo;
        vm.showfdruginfo = showfdruginfo;
        vm.addDrug = addDrug;
        vm.addForeignDrug = addForeignDrug;
        vm.replaceDrug = replaceDrug;
        vm.showHistory = showHistory;
        vm.hideHistory = hideHistory;
  
        // vm.clearDrug = clearDrug;
        vm.drugalternativestpl = 'drug/drugalternativestpl.html';
  
        // popover templates
        vm.repopovertpl = 'drug/repopovertpl.html';
        vm.indpopovertpl = 'drug/indpopovertpl.html';
        vm.hintpopovertpl = 'drug/hintpopovertpl.html';
        vm.kzpopover = 'drug/kzpopover.html';
        vm.atcalternativestpl = 'drug/atcalternativestpl.html';
        
        
        // calculate activedrugs helper variable
        function calculateActivedrugs() {
          var i;
          vm.patient.activedrugs = 0;
          for (i = 0; i < vm.patient.drugs.length; i++) {
            if (vm.patient.drugs[i].active === true && vm.patient.drugs[i].foreign === false && vm.patient.drugs[i].print === true) {
              vm.patient.activedrugs += 1;
            }
          }
        }
  
  
        // drugahead drug name completion
        function getDrugs(val) {
          console.log('loading drug names');
          vm.newdrug = {};
          return Drugsearch.$search({query: val}).$asPromise().then(function (response) {
            // console.log('drug search response: ', response);
            return response.map(function (drug) {
              if (angular.isString(drug.amount) && angular.isString(drug.amounttype)) {
                drug.text = drug.name + ' (' + drug.amount + drug.amounttype + ')';
              }
              else {
                drug.text = drug.ZBEZ + ' (' + drug.ZLNUMM + ')';
              }
              return drug;
            });
          });
        }
  
        // typeahead drug search information
        function showdruginfo($item) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          var i = 0;
          var atcquery;
          var atcar = [];
    
          vm.newdrug = {};
    
          $log.debug('showdruginfo called: ', $item);
          vm.selectedDrug = $item;
    
          if (angular.isString(vm.selectedDrug.name)) {
            vm.newdrug.name = vm.selectedDrug.name;
          }
          else {
            vm.newdrug.name = vm.selectedDrug.ZBEZ;
          }
          vm.newdrug.op = 'OP I';
          vm.newdrug.permanent = true;
    
    
          // Suchtgiftvignette
          if (vm.selectedDrug.ZSUCHT === 'SG') {
            vm.newdrug.vignette = true;
            vm.newdrug.comment = 'Suchtgiftvignette!';
          }
    
          // SV Box system
          if (angular.isString(vm.selectedDrug.box)) {
            vm.newdrug.box = vm.selectedDrug.box;
          }
          else {
      
            if (vm.selectedDrug.atcarray.length === 1) {
              atcquery = {atcarray: vm.selectedDrug.atcarray[0]};
            }
            else {
              vm.selectedDrug.atcarray.forEach(function (a) {
                atcar.push({atcarray: a});
              });
              atcquery = {$or: atcar};
              // var foo = {$or: [{columnA: 3}, {columnB: 'string'}]};
            }
      
            vm.newdrug.atcalternatives = Drug.$search(atcquery);
          }
    
    
          // Pharmanr
          if (angular.isString(vm.selectedDrug.pharmanr)) {
            vm.newdrug.pharmanr = vm.selectedDrug.pharmanr;
          }
    
          // Zulassungsnummer
          if (angular.isString(vm.selectedDrug.ZLNUMM)) {
            vm.newdrug.ZLNUMM = vm.selectedDrug.ZLNUMM;
          }
    
          // Array aller ATC codes
          if (angular.isArray(vm.selectedDrug.atcarray)) {
            vm.newdrug.atcarray = vm.selectedDrug.atcarray;
          }
    
          // Fachinformationen
          if (angular.isString(vm.selectedDrug.fi)) {
            // "fi" : "/fi_pdf/1-21520.pdf"
            // "fi" : "/eu_pdf/1-21520.pdf"
            vm.newdrug.fi = vm.selectedDrug.fi.replace(/_/g, '');
            vm.newdrug.fi = vm.newdrug.fi.replace(/\.pdf/g, '');
          }
    
          // Postion im SV ekotool preivergleich
          if (angular.isString(vm.selectedDrug.position)) {
            vm.newdrug.svpos = parseInt(vm.selectedDrug.position, 10);
          }
    
          // packungsgröße/art
          if (angular.isString(vm.selectedDrug.amount) && angular.isString(vm.selectedDrug.amounttype)) {
            vm.newdrug.pkgsize = vm.selectedDrug.amount + ' ' + vm.selectedDrug.amounttype;
          }
    
          if (angular.isString(vm.selectedDrug.amount) && !angular.isString(vm.selectedDrug.amounttype)) {
            vm.newdrug.pkgsize = vm.selectedDrug.amount;
          }
    
          // if (angular.isString()) {}
    
          // Hinweis zum Medikament
          if (vm.selectedDrug.hint) {
            vm.newdrug.hint = $sce.trustAsHtml('<div>' + vm.selectedDrug.hint + '</div>');  // overlay html must be contained in single tag
          }
    
          // kassenzeichen
          // KZ RE1 RE2
          if (vm.selectedDrug.kz === 'RE1' || vm.selectedDrug.kz == 'RE2') {
            vm.newdrug.re = vm.selectedDrug.kz;
          }
          if (vm.selectedDrug.recriteria) {
            $log.debug('recriteria: ', vm.selectedDrug.recriteria);
            vm.newdrug.recriteria = $sce.trustAsHtml('<div>' + vm.selectedDrug.recriteria + '</div>');  // overlay html must be contained in single tag
          }
    
          // KZ IND
          if (vm.selectedDrug.kz && vm.selectedDrug.kz === 'IND') {
            vm.newdrug.ind = true;
          }
    
          if (vm.selectedDrug.indcriteria) {
            vm.newdrug.indcriteria = $sce.trustAsHtml('<div>' + vm.selectedDrug.indcriteria + '</div>');
          }
    
    
          // All other KZ entries, use popover lookup table
          if (vm.selectedDrug.kz !== '' && vm.selectedDrug.kz !== 'RE1' &&
            vm.selectedDrug.kz !== 'RE2' && vm.selectedDrug.kz !== 'IND') {
            vm.newdrug.kz = vm.selectedDrug.kz;
          }
    
    
          // alternatives
          vm.newdrug.alternatives = [];
          if (angular.isArray(vm.selectedDrug.alternatives)) {
            vm.newdrug.alternatives = [];
            // reorder by price/posnr
            // sv data format is crap - alternatives are not sorted by proce/posnr
            // more than one drug can occupy the same posnr
            // at least they had the decency to leave space for ex-aequo drugs
            // if more than 3 products tie, I simply don't care, third place gets overridden
            for (i = 0; i < vm.selectedDrug.alternatives.length; i++) {
              if (vm.selectedDrug.alternatives[i].posnr < vm.newdrug.svpos) {
                switch (vm.selectedDrug.alternatives[i].comparetype) {
                  case '1':
                    vm.selectedDrug.alternatives[i].color = 'success';
                    vm.selectedDrug.alternatives[i].tooltip = 'Alternative mit gleichem Wirkstoff und gleicher Stärke';
                    break;
                  case '2':
                    vm.selectedDrug.alternatives[i].color = 'warning';
                    vm.selectedDrug.alternatives[i].tooltip = 'Biosimilar, Alternative mit gleichem Wirkstoff und ähnlicher Stärke';
                    break;
                  case '3':
                    vm.selectedDrug.alternatives[i].color = 'error';
                    vm.selectedDrug.alternatives[i].tooltip = 'Alternative mit anderem Wirkstoff';
                    break;
                  default:
                    break;
                }
              }
              else {
                vm.selectedDrug.alternatives[i].color = 'default';
                vm.selectedDrug.alternatives[i].tooltip = 'Alternative ist nicht günstiger als das gewählte Produkt';
              }
              if (!vm.newdrug.alternatives[vm.selectedDrug.alternatives[i].posnr - 1]) {
                vm.newdrug.alternatives[vm.selectedDrug.alternatives[i].posnr - 1] = vm.selectedDrug.alternatives[i];
              }
              else {
                if (!vm.newdrug.alternatives[vm.selectedDrug.alternatives[i].posnr]) {
                  vm.newdrug.alternatives[vm.selectedDrug.alternatives[i].posnr] = vm.selectedDrug.alternatives[i];
                }
                else {
                  if (!vm.newdrug.alternatives[vm.selectedDrug.alternatives[i].posnr + 1]) {
                    vm.newdrug.alternatives[vm.selectedDrug.alternatives[i].posnr + 1] = vm.selectedDrug.alternatives[i];
                  }
                  else {
                    vm.newdrug.alternatives[vm.selectedDrug.alternatives[i].posnr + 2] = vm.selectedDrug.alternatives[i];
                  }
                }
              }
            }
      
            // type 3: Anderer Wirkstoff
            for (i = 0; i < vm.newdrug.svpos; i++) {
              if (vm.newdrug.alternatives[i].comparetype === '3') {
                vm.newdrug.alttype = 'danger';
                vm.newdrug.alttypetext = 'Alternativen mit anderen Wirkstoffen sind günstiger';
              }
            }
      
            // type 2: Gleicher Wirkstoff, andere Dosis, Biosimilars
            for (i = 0; i < vm.newdrug.svpos; i++) {
              if (vm.newdrug.alternatives[i].comparetype === '2') {
                vm.newdrug.alttype = 'warning';
                vm.newdrug.alttypetext = 'Alternativen mit Biosimilars (gleicher Wirkstoff, ähnliche Stärke) sind günstiger';
              }
            }
      
            // type 1: Gleicher Wirkstoff, gleiche Stärke
            for (i = 0; i < vm.newdrug.svpos; i++) {
              if (vm.newdrug.alternatives[i].comparetype === '1') {
                vm.newdrug.alttype = 'success';
                vm.newdrug.alttypetext = 'Alternativen mit gleichem Wirkstoff und gleicher Stärke sind günstiger';
              }
            }
      
            // if med was already in first position, greyout alternatives option
            if (vm.newdrug.svpos === 1) {
              vm.newdrug.alttype = 'default';
              vm.newdrug.alttypetext = 'Dieses Medikament ist das günstigste mit diesem Wirkstoff und dieser Stärke';
            }
      
            $log.debug('selected drug: ', vm.selectedDrug);
            $log.debug('new drug: ', vm.newdrug);
          } // rebuild alternatives
        }
        
        // show foreign drug info
        function showfdruginfo($item) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          vm.newfdrug = {};
    
          $log.debug('showfdruginfo called: ', $item);
          vm.selectedfDrug = $item;
    
          if (angular.isString(vm.selectedfDrug.name)) {
            vm.newfdrug.name = vm.selectedfDrug.name;
          }
          else {
            vm.newfdrug.name = vm.selectedfDrug.ZBEZ;
          }

          vm.newfdrug.permanent = true;
          
    
          // Pharmanr
          if (angular.isString(vm.selectedfDrug.pharmanr)) {
            vm.newfdrug.pharmanr = vm.selectedfDrug.pharmanr;
          }
    
          // Zulassungsnummer
          if (angular.isString(vm.selectedfDrug.ZLNUMM)) {
            vm.newfdrug.ZLNUMM = vm.selectedfDrug.ZLNUMM;
          }
    
          // Array aller ATC codes
          if (angular.isArray(vm.selectedfDrug.atcarray)) {
            vm.newfdrug.atcarray = vm.selectedfDrug.atcarray;
          }
    
          // Fachinformationen
          if (angular.isString(vm.selectedfDrug.fi)) {
            // "fi" : "/fi_pdf/1-21520.pdf"
            // "fi" : "/eu_pdf/1-21520.pdf"
            vm.newfdrug.fi = vm.selectedfDrug.fi.replace(/_/g, '');
            vm.newfdrug.fi = vm.newfdrug.fi.replace(/\.pdf/g, '');
          }
    
         
          if (angular.isString(vm.selectedfDrug.amount) && !angular.isString(vm.selectedfDrug.amounttype)) {
            vm.newfdrug.pkgsize = vm.selectedfDrug.amount;
          }
    
          // if (angular.isString()) {}
    
          // Hinweis zum Medikament
          if (vm.selectedfDrug.hint) {
            vm.newfdrug.hint = $sce.trustAsHtml('<div>' + vm.selectedfDrug.hint + '</div>');  // overlay html must be contained in single tag
          }
    
          // kassenzeichen
          // KZ RE1 RE2
          if (vm.selectedfDrug.kz === 'RE1' || vm.selectedfDrug.kz === 'RE2') {
            vm.newfdrug.re = vm.selectedfDrug.kz;
          }
          if (vm.selectedfDrug.recriteria) {
            $log.debug('recriteria: ', vm.selectedfDrug.recriteria);
            vm.newfdrug.recriteria = $sce.trustAsHtml('<div>' + vm.selectedfDrug.recriteria + '</div>');  // overlay html must be contained in single tag
          }
    
          // KZ IND
          if (vm.selectedfDrug.kz && vm.selectedfDrug.kz === 'IND') {
            vm.newfdrug.ind = true;
          }
    
          if (vm.selectedfDrug.indcriteria) {
            vm.newfdrug.indcriteria = $sce.trustAsHtml('<div>' + vm.selectedfDrug.indcriteria + '</div>');
          }
    
    
          // All other KZ entries, use popover lookup table
          if (vm.selectedfDrug.kz !== '' && vm.selectedfDrug.kz !== 'RE1' &&
            vm.selectedfDrug.kz !== 'RE2' && vm.selectedfDrug.kz !== 'IND') {
            vm.newfdrug.kz = vm.selectedfDrug.kz;
          }
        }
        
  
        function replaceDrug(replacement) {
//          $log.debug('replacement: ', replacement.pharmanr);
          Drugsearch.$search({query: replacement.pharmanr}).$asPromise().then(function (rdrug) {
            vm.newdrug = {};
            vm.showdruginfo(rdrug[0]);
            if (rdrug.length > 1) {
              $log.debug('Pharmanr search returned more than one result');
            }
          });
        }
  
        function addDrug() {
          delete vm.noResults;
          vm.newdrug.color = 'primary';
          vm.newdrug.foreign = false;
          vm.newdrug.active = true;
          vm.newdrug.startDate = moment().toISOString();
          vm.newdrug.print = true;
          if (!vm.newdrug.permanent) {
            vm.newdrug.endDate = moment().add(14, 'days').toISOString();
          }
          vm.patient.drugs.push(vm.newdrug);
          calculateActivedrugs();
          vm.patient.$save();
          vm.selectedDrug = {};
          vm.newdrug = {};
          vm.newdrug.op = 'OP I';
          vm.asyncSelected = '';
        }
  
        function addForeignDrug() {
          delete vm.noFDResults;
          vm.newfdrug.color = 'info';
          vm.newfdrug.foreign = true;
          vm.newfdrug.permanent = true;
          vm.newfdrug.active = true;
          vm.newfdrug.startDate = moment().toISOString();
          vm.newfdrug.print = false;
          vm.patient.drugs.push(vm.newfdrug);
          calculateActivedrugs();
          vm.newfdrug = null;
          vm.patient.$save();
        }
  
  
        function showHistory() {
          var i = 0;
  
          // vis-timeline expects a data object containing items[]
          vm.data = {};
          vm.data.items = [];
          for (i = 0; i < vm.patient.drugs.length; i++) {
                       
            vm.data.items[i] = {};
            $log.debug('vm.patient.drugs[i].name: ', vm.patient.drugs[i].name, vm.patient.drugs[i]);
            if (angular.isString(vm.patient.drugs[i].name)) {
              vm.data.items[i].content = vm.patient.drugs[i].name.split(' ')[0] + ', ' + vm.patient.drugs[i].amount;
            }
            else {
              vm.data.items[i].content = vm.patient.drugs[i].amount;
            }
            vm.data.items[i].title = vm.patient.drugs[i].name + ', ' + vm.patient.drugs[i].amount;
            if (angular.isString(vm.patient.drugs[i].comment)) {
              $log.debug('Komment: ', vm.patient.drugs[i].comment, vm.patient.drugs[i].name);
              vm.data.items[i].title += ', ';
              vm.data.items[i].title += vm.patient.drugs[i].comment;
              $log.debug('Komment: ', vm.data.items[i].title);
            }
            if (angular.isString(vm.patient.drugs[i].privcomment)) {
              $log.debug('Komment: ', vm.patient.drugs[i].privcomment, vm.patient.drugs[i].name);
              vm.data.items[i].title += ', ';
              vm.data.items[i].title += vm.patient.drugs[i].privcomment;
              $log.debug('PrivKomment: ', vm.data.items[i].title);
            }
            vm.data.items[i].start = vm.patient.drugs[i].startDate;
            if (angular.isString(vm.patient.drugs[i].endDate)) {
              vm.data.items[i].end = vm.patient.drugs[i].endDate;
            }
            else {
              vm.data.items[i].end = new Date();
            }
            vm.data.items[i].className = vm.patient.drugs[i].color;
  
          }
          $log.debug('vm.data: ', vm.data);

          vm.options = {};
          vm.groups = {};
          // vm.events = {};
          vm.events = ['onload'];
          
          var modalinstance = $uibModal.open({
            // templateUrl: 'tepledittpl',
            template: '<div class="modalspacer"><sprtimeline data="shCtrl.data" options="shCtrl.options" events="shCtrl.events"></sprtimeline></div>',
            size: 'lg',
            backdrop: true,
            controllerAs: 'shCtrl',
            controller: ['data', 'groups', 'options', 'events', '$uibModalInstance', function ShowHistoryCtrl(data, groups, options, events, $uibModalInstance) {
                var vm = this;
                vm.quit = quit;
              
                vm.data = data;
                vm.options = options;
                vm.groups = groups;
                vm.events = events;
              
                $log.debug('shCtrl.data: ', vm.data);
              
                function quit() {
                  $uibModalInstance.close();
                }
                
              }], // showHistory modal controller
            resolve: {
              data: function () {
                return vm.data;
              },
              groups: function () {
                return vm.groups;
              },
              options: function () {
                return vm.options;
              },
              events: function () {
                return vm.events;
              }
            }
          }); // showHistory modal open
        }
        
        function hideHistory() {
          vm.showhist = false;
        }
  
      }
    };
  }
}());
