(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name tracker.factory:Tracker
   *
   * @description
   *
   */
  angular
    .module('tracker')
    .factory('Tracker', Tracker);

  function Tracker() {
    var TrackerBase = {};
    TrackerBase.someValue = 'Tracker';
    TrackerBase.someMethod = function () {
      return 'Tracker';
    };
    return TrackerBase;
  }
}());
