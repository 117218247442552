(function () {
  'use strict';

  angular
    .module('config')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('config', {
        url: '/config',
        views: {
          main: {template: '<config-editor></config-editor>'},
          // 'leftbar': {template: '<patient-today></patient-today>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });
    
    $stateProvider
      .state('version', {
        url: '/version',
        views: {
          main: {template: '<version></version>'},
          // 'leftbar': {template: '<patient-today></patient-today>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });

    $stateProvider
      .state('svs', {
        url: '/svs',
        views: {
          main: {template: '<sv></sv>'},
          // 'leftbar': {template: '<patient-today></patient-today>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });

    $stateProvider
      .state('dts', {
        url: '/dts',
        views: {
          main: {template: '<doctype-list></doctype-list>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });

  }


}());
