(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:checkoutDrugs
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <checkout-drugs></checkout-drugs>
   </file>
   </example>
   *
   */
  angular
    .module('patient')
    .directive('checkoutDrugs', checkoutDrugs);

  checkoutDrugs.$inject = ['Pdfprinter', 'Config', '$state', '$log'];

  function checkoutDrugs(Pdfprinter, Config, $state, $log) {
    return {
      restrict: 'E',
      scope: {
        patient: '='
      },
      templateUrl: 'consult/checkout-drugs-directive.tpl.html',
      replace: false,
      controllerAs: 'patdCtrl',
      bindToController: true,
      controller: function CheckoutDrugsCtrl() {
        var vm = this;
        vm.checkoutdrugs = [];
        vm.conf = Config.$find('configuration');
        vm.drugcheckout = drugcheckout;
        vm.switchToDocuments = switchToDocuments;
        vm.drugedittpl = 'drug/drugPopoverTemplate.html';
      

        function drugcheckout(patient, consultid) {
          var newprinter;
          var printobj = {};
          var printdocument = {};
          var drugprint = [];
          if (vm.pdate) {
            moment.locale('de-at');
            patient.prescriptiondate = moment().format('ll');
          }
          else {
            patient.prescriptiondate = '';
          }
          printobj.foptemplate = 'prescription.xslt';
          printobj.rootelement = 'patient';
          printobj.patientid = patient._id;
          printobj.consultid = consultid;
          printobj.examid = '';
          printobj.type = 'Rezept';
          printobj.metadata = {};
          printobj.metadata.filename = 'rezept.pdf';
          printobj.patient = clean(patient);
          patient.drugs.forEach(function (drug) {
            if (drug.print && drug.active) {
              drugprint.push(drug);
            }
          });
          // print if there actually is something to print
          if (drugprint.length > 0) {
            printobj.patient.drugs = drugprint;
            newprinter = Pdfprinter.$build(printobj);
            newprinter.$save().$asPromise().then(function (doc) {
              printdocument.docid = doc.docid;
              printdocument.type = 'Rezept';
              printdocument.filetype = 'pdf';
              printdocument.mimetype = 'application/pdf';
              printdocument.filename = 'rezept.pdf';
              printdocument.printername = vm.conf.printers.prescriptionprinter;
              printdocument.time = moment().toISOString();
              if (!patient.openConsult.hasOwnProperty('checkoutdocs')) {
                patient.openConsult.checkoutdocs = [];
              }
              patient.openConsult.checkoutdocs.push(printdocument);
              switchToDocuments(patient);
            });
          }
          else {
            // abort prescription printing when no drugs were selected
            switchToDocuments(patient);
          }
        }

        function clean(dirty) {
          var cleaned = {};
          var i;
          var reg = /^\$/;

          if (angular.isObject(dirty)) {
            for (i in dirty) {
              if (dirty.hasOwnProperty(i)) {
                $log.debug('dirty i: ', i, dirty[i]);
                switch (i) {
                  case 'consults': break;
                  case 'consult': break;
                  case 'patient': break;
                  case 'examinations': break;
                  case 'openExam': break;
                  case 'openConsult': break;
                  default:
                    if (!reg.test(i)) {
                      cleaned[i] = dirty[i];
                    }
                }
              }
            }
          }
          return cleaned;
        }

        function switchToDocuments(patient) {
          patient.consultstate = 'checkout.documents';
          patient.$save();
          $state.go('patientrecord.ccdocs');
        }

        // TODO figure out how to use ui.popover with controllerAs syntax
        /* $scope.dynamicPopover = {};
        $scope.dynamicPopover.templateUrl = 'myPopoverTemplate.html';
        $scope.dynamicPopover.title = 'Detailinformation'; */

      }
    };
  }
}());
