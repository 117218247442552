(function () {
  'use strict';

  angular
    .module('waitinglist')
    .config(config);
  
  config.$inject = ['$stateProvider'];
  
  function config($stateProvider) {
    $stateProvider
      .state('waitinglists', {
        url: '/waitinglists',
        views: {
          main: {template: '<configurewl></configurewl>'},
          // 'leftbar': {template: '<patient-today></patient-today>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });
  }
}());
