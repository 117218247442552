(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name helpers.sv.directive:svSearch
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="helpers.sv">
   <file name="index.html">
   <sv-search svres="" svresult="" ti="?"></sv-search>
   </file>
   </example>
   *
   */
  angular
    .module('helpers.sv')
    .directive('svSearch', svSearch);


  svSearch.$inject = ['Svahead', 'Sv', '$uibModal', '$log', 'SvHelper'];

  function svSearch(Svsearch, Sv, $uibModal, $log, SvHelper) {
    return {
      restrict: 'EA',
      scope: {
        svresult: '=',
        svres: '=',
        ti: '@?'
      },
      templateUrl: 'helpers/sv/sv-search-directive.tpl.html',
      replace: false,
      controllerAs: 'svsCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;

        vm.showsv = showsv;
        vm.getSvs = getSvs;
        vm.addSv = addSv;
        vm.editSv = SvHelper.editSv;

        $log.debug('ti: ', vm.ti);

        if (angular.isString(vm.ti)) {
          vm.tabi = parseInt(vm.ti, 10);
          $log.debug('tabi: ', vm.tabi);
        }
        else {
          vm.tabi = 0;
        }

        vm.newsv = {};
        vm.newsv.foo = 'quux';

        // typeahead doctor search backend query
        function getSvs(val) {
          return Svsearch.$search({query: val}).$asPromise().then(function (response) {
            return response.map(function (sv) {
              sv.text = sv.short;
              return sv;
            });
          });
        }

        // typeahead doctor search action
        function showsv($item, $model, $label) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          // vm.doctorresult = Doctor.$find($model._id);
          Sv.$find($model._id).$asPromise().then(function (sv) {
            $log.debug('sv item: ', $item);
            vm.svresult._id = sv._id;
            vm.svresult.name = sv.name;
            vm.svresult.short = sv.short;
            vm.svres = sv.short;
            delete vm.asyncSelected;
          });
        }

        function addSv() {
          var newsv = Sv.$create();
          vm.dialog = vm.editSv(newsv);
          $log.debug('dialog: ', vm.dialog);
          vm.dialog.result.finally(function () {
            $log.debug('new sv added: ', newsv);
            $log.debug('modal closed finally: ', newsv);
            $log.debug('vm.svresult before: ', vm.svresult);
            if (!angular.isObject(vm.svresult)) {
              vm.svresult = {};
            }
            vm.svresult._id = newsv._id;
            vm.svresult.name = newsv.name;
            vm.svresult.short = newsv.short;
            vm.svres = newsv.short;
            vm.asyncSelected = newsv.short;
            $log.debug('vm.svresult after: ', vm.svresult);
            $log.debug('newly added: ', vm.svres, vm.svresult);
          });
        }


        /* function addSv() {
          var dialog = $uibModal.open({
            template: '<div class="modalspacer"><sv-edit returnsv="miCtrl.returnsv" dialog="miCtrl.$uibModalInstance"></sv-edit></div>',
            controllerAs: 'miCtrl',
            controller: ['returnsv', '$uibModalInstance', function (returnsv, $uibModalInstance) {
              var vmi = this;
              vmi.returnsv = returnsv;
              vmi.$uibModalInstance = $uibModalInstance;
              $log.debug('vmi: ', returnsv);
            }],
            resolve: {
              returnsv: function () {
                $log.debug('newsv init: ', vm.newsv);
                return vm.newsv;
              },
              dialog: function () {
                $log.debug('dialog init: ', dialog);
                return dialog;
              }
            },
            size: 'lg'
          });

          dialog.result.finally(function () {
            $log.debug('new doc added: ', vm.newsv);
            $log.debug('modal closed finally: ', vm.newsv);
            vm.svresult = {};
            vm.svresult._id = vm.newsv._id;
            vm.svresult.fname = vm.newsv.short;
            vm.asyncSelected = vm.newsv.short;
          });
        }*/


      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
