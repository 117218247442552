<span class="btn btn-info btn-sm"
      ng-show="etCtrl.consult.examinations.length"
      popover-trigger="outsideClick"
      uib-popover-template="etCtrl.popover"
      popover-title="Therapie-Einträge der Untersuchungen"
      popover-placement="auto top">Therapie-Emfehlungen der Untersuchungen</span>


<script type="text/ng-template" id="examtherapies_popver.tpl.html">
  <div>
    <div ng-repeat="exam in etCtrl.consult.examinations  | filter: {active: true}">
      <h4 ng-bind="exam.type"></h4>
      <span ng-repeat="val in exam.template.recommendation.avalues">{{val.name}}{{$last ? '' : ', '}}</span>
    </div>

<p class="alert alert-info" style="margin-top: 10pt;">
  Die Therapie-Empfehlungen der einzelnen Untersuchungen werden automatisch in den Gesamtbefund/Arztbrief übernommen.</p>

  </div>
</script>