<patient-current-drugs patient="pdvCtrl.patient"></patient-current-drugs>
<div >
  <h4>Medikamenten-Historie</h4>
  <button type="button" ng-click="pdvCtrl.showHistory()" class="btn btn-info">Medikamentehistorie anzeigen</button>
</div>

<h4>Neue Verordnung</h4>
<form class="form-vertical" name="newdrugform" id="newdrugform">
  <div class="row">
    <div class="col-xs-12 col-md-6">

      <div class="input-group col-xs-12">
        <label for="newdrugname">Medikament *

          <!-- Button SV Box -->
          <span class="btn btn-success btn-sm" ng-show="pdvCtrl.newdrug.box === 'G'"
                uib-tooltip="SV Green Box">G</span>
          <span class="btn btn-warning btn-sm" ng-show="pdvCtrl.newdrug.box === 'Y'"
                uib-tooltip="SV Yellow Box">Y</span>
          <span class="btn btn-danger btn-sm" ng-hide="pdvCtrl.newdrug.box" uib-tooltip="SV No Box">NB</span>

          <!-- Fachinformationen -->
          <a class="btn btn-info btn-sm"
             href="/routes{{pdvCtrl.newdrug.fi}}" target="_blank"
             ng-show="pdvCtrl.newdrug.fi"
             uib-tooltip="Fachinformationen">FI PDF</a>

          <!-- Button Restriktionen, Ind -->
          <span class="btn btn-danger btn-sm" ng-show="pdvCtrl.newdrug.re"
                uib-tooltip="Restriktionen" ng-bind="pdvCtrl.newdrug.re"
                uib-popover-template="pdvCtrl.repopovertpl" popover-title="Restriktionen"
                popover-placement="auto bottom" popover-trigger="outsideClick"></span>
          <span class="btn btn-primary btn-sm" ng-show="pdvCtrl.newdrug.ind"
                uib-tooltip="Indikationen" popover-trigger="outsideClick"
                uib-popover-template="pdvCtrl.indpopovertpl" popover-title="Indikationen"
                popover-placement="auto bottom">IND</span>
          <span class="btn btn-info btn-sm" ng-show="pdvCtrl.newdrug.hint"
                uib-tooltip="Hinweis" popover-trigger="outsideClick"
                uib-popover-template="pdvCtrl.hintpopovertpl" popover-title="Hinweis" popover-placement="auto bottom"><i
              class="fa fa-info-circle"></i></span>
          <span class="btn btn-warning btn-sm" ng-show="pdvCtrl.newdrug.kz"
                uib-tooltip="Kassenzeichen"
                uib-popover-template="pdvCtrl.kzpopover" popover-title="Hinweis" popover-placement="auto bottom"
                ng-bind="pdvCtrl.newdrug.kz"></span>

          <!-- Alternativen -->
          <span class="btn btn-{{pdvCtrl.newdrug.alttype}} btn-sm" ng-show="pdvCtrl.newdrug.alternatives.length > 0"
                uib-popover-template="pdvCtrl.drugalternativestpl" popover-title="Alternativen"
                popover-placement="auto bottom" popover-trigger="outsideClick"
                uib-tooltip="{{pdvCtrl.newdrug.alttypetext}}">Alternativen</span>

          <!-- SV ATC Alternativen -->
          <span class="btn btn-warning btn-sm" ng-show="pdvCtrl.newdrug.atcalternatives.length > 0"
                uib-popover-template="pdvCtrl.atcalternativestpl" popover-title="SV erstattete Alternativen laut ATC Code"
                popover-placement="auto bottom" popover-trigger="outsideClick"
                uib-tooltip="SV erstattete Alternativen laut ATC Code">SV Alternativen lt. ATC Code</span>


        </label>

        <!-- searchbox -->
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Medikament oder PharmaNr."
                 ng-model="pdvCtrl.newdrug.name"
                 ng-required="!pdvCtrl.newdrug.name"
                 autocomplete="off"
                 ng-class="{ 'has-error': newdrugform.$invalid }"
                 typeahead-on-select="pdvCtrl.showdruginfo($item)"
                 typeahead-min-length="3"
                 uib-typeahead="drug as drug.text for drug in pdvCtrl.getDrugs($viewValue)"
                 typeahead-loading="loadingNames"
                 id="newdrugname"
                 select-first-match="false"
                 typeahead-no-results="pdvCtrl.noResults">
          <span class="input-group-btn">
            <button class="btn btn-info" type="button"><i class="fa fa-search"></i></button>
          </span>
        </div>
      </div>

      <i ng-show="loadingNames" class="glyphicon glyphicon-refresh"></i>

      <div ng-show="pdvCtrl.noResults" style="color: #cc3333; font-weight: bold">
        <i class="fa fa-warning"></i> Keine Medikamente gefunden!
      </div>


      <!-- Einnahmeanweisung -->
      <div class="form-group">
        <label for="newdrugamount">Einnahme *</label>
        <div class="input-group">
          <input class="form-control" ng-model="pdvCtrl.newdrug.amount" id="newdrugamount"
                 autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                 placeholder="1-0-1-0" ng-required="!pdvCtrl.newdrug.amount"
                 ng-class="{ 'has-error': newdrugform.$invalid }">
          <div class="input-group-btn" uib-dropdown>
            <button type="button" class="btn btn-default" uib-dropdown-toggle><span class="caret"></span></button>
            <ul id="amountmenu" class="dropdown-menu dropdown-menu-right" role="menu" uib-dropdown-menu ng-hide="pdvCtrl.conf.drugsignature.length">
              <li ng-click="pdvCtrl.newdrug.amount='1-0-0'">1-0-0</li>
              <li ng-click="pdvCtrl.newdrug.amount='1-0-1'">1-0-1</li>
              <li ng-click="pdvCtrl.newdrug.amount='0-1-0'">0-1-0</li>
              <li ng-click="pdvCtrl.newdrug.amount='0-0-1'">0-0-1</li>
              <li ng-click="pdvCtrl.newdrug.amount='1-1-1'">1-1-1</li>
              <li ng-click="pdvCtrl.newdrug.amount='0-0-0-1'">0-0-0-1</li>
            </ul>
            <ul id="amountmenu2" class="dropdown-menu dropdown-menu-right" role="menu" uib-dropdown-menu ng-show="pdvCtrl.conf.drugsignature.length">
              <li ng-repeat="sig in pdvCtrl.conf.drugsignature" ng-click="pdvCtrl.newdrug.amount=sig">{{sig}}</li>
            </ul>
          </div>
        </div>
      </div>

      <!--Dauermedikation-->
      <div class="input-group">
        <div class="checkbox">
          <label for="newdrugperm" class="checkbox-inline">
            <input type="checkbox"
                   ng-model="pdvCtrl.newdrug.permanent"
                   id="newdrugperm">Dauermedikamentation
            <span class="fa fa-info-circle" style="color: #3333ff"
                  uib-tooltip="Ist das Medikament nicht zur dauerhaften Einanhme/Anwendung vorgesehen, wird als Ende-Datum das heutige Datum +14 Tage eingesetzt"></span>
          </label><br>
        </div>
      </div>
    </div>
    <!-- Spezifika, IND, OP, RE -->
    <div class="col-xs-12 col-md-6">
      <div class="input-group">
        <strong>Spezifika</strong>

        <!--IND-->
        <div class="checkbox">
          <label for="newdrugind" class="checkbox-inline">
            <input type="checkbox"
                   ng-model="pdvCtrl.newdrug.ind"
                   id="newdrugind">IND (Indikation)
            <!-- <span ng-show="pdvCtrl.newdrug.indcriteria" uib-tooltip="Indikationen"
                   uib-popover-template="pdvCtrl.indpopovertpl"
                   popover-title="Indikationen" popover-placement="auto bottom"><i class="fa fa-info-circle btn btn-primary"></i></span> -->
          </label><br>
        </div>

        <!--OP-->
        <div class="radio" ng-init="pdvCtrl.newdrug.op='OP I'">
          <label for="newdrugop1" class="radio-inline">
            <input type="radio" value="OP I" required
                   ng-model="pdvCtrl.newdrug.op"
                   id="newdrugop1">OP I</label>
          <label for="newdrugop2" class="radio-inline">
            <input type="radio" value="OP II"
                   ng-model="pdvCtrl.newdrug.op"
                   id="newdrugop2">OP II</label>
          <label for="newdrugop3" class="radio-inline">
            <input type="radio" value="OP III"
                   ng-model="pdvCtrl.newdrug.op"
                   id="newdrugop3">OP III</label>
        </div>
        <!--Packungsgroesse-->
        <label for="newdrugpkgsize">Packungsgröße</label>
        <input class="form-control" ng-model="pdvCtrl.newdrug.pkgsize" id="newdrugpkgsize"
               placeholder="30 Stk">
      </div>

    </div>
  </div>
  <!--Rezeptvermerk-->
  <div class="row">
    <div class="col-xs-12">
      <div class="form-group">
        <label for="newdrugcomment">Rezeptvermerk</label>
                    <textarea class="form-control" ng-model="pdvCtrl.newdrug.comment" id="newdrugcomment"
                              placeholder=""></textarea>
      </div>
    </div>
  </div>

  <!--Verschreiben-->
  <div class="row">
    <div class="col-xs-12">
      <button class="btn btn-success spacer-top" type="button"
              ng-disabled="!newdrugform.$valid"
              ng-click="pdvCtrl.addDrug()">
        Medikament verordnen
      </button>
    </div>
  </div>
</form>

<!--Fremdverordnung-->
<h4>Fremdverordnung vermerken</h4>
<form class="form-vertical" name="newfdrugform">
  <div class="row">
    <div class="col-xs-12 col-md-6">
      <label for="newfdrugname">Medikament *</label>
      <input class="form-control" ng-model="pdvCtrl.newfdrug.name"
             type="text" class="form-control" placeholder="Medikament oder PharmaNr."
             autocomplete="off"
             typeahead-on-select="pdvCtrl.showfdruginfo($item)"
             typeahead-min-length="3"
             uib-typeahead="drug as drug.text for drug in pdvCtrl.getDrugs($viewValue)"
             typeahead-loading="loadingNames"
             id="newfdrugname"
             select-first-match="false"
             typeahead-no-results="pdvCtrl.noFDResults"
             required
      />
      <div ng-show="pdvCtrl.noFDResults" style="color: #cc3333; font-weight: bold">
        <i class="fa fa-warning"></i> Keine Medikamente gefunden!
      </div>
    </div>
    <div class="cpol-xs-12 col-md-6">
      <div class="form-group">
        <label for="fdrugamount">Einnahme *</label>
        <div class="input-group">
          <input class="form-control" ng-model="pdvCtrl.newfdrug.amount" id="fdrugamount"
                 autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                 placeholder="1-0-1-0" required ng-class="{ 'has-error': newfdrugform.$invalid }">
          <div class="input-group-btn" uib-dropdown>
            <button type="button" class="btn btn-default" uib-dropdown-toggle><span class="caret"></span></button>
            <ul id="famountmenu" class="dropdown-menu dropdown-menu-right" role="menu" uib-dropdown-menu>
              <li ng-click="pdvCtrl.newfdrug.amount='1-0-0'">1-0-0</li>
              <li ng-click="pdvCtrl.newfdrug.amount='1-0-1'">1-0-1</li>
              <li ng-click="pdvCtrl.newfdrug.amount='0-1-0'">0-1-0</li>
              <li ng-click="pdvCtrl.newfdrug.amount='0-0-1'">0-0-1</li>
              <li ng-click="pdvCtrl.newfdrug.amount='1-1-1'">1-1-1</li>
              <li ng-click="pdvCtrl.newfdrug.amount='0-0-0-1'">0-0-0-1</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <button class="btn btn-success" type="submit" ng-disabled="!newfdrugform.$valid"
              ng-click="pdvCtrl.addForeignDrug()">Fremdverordnung speichern
      </button>
    </div>
  </div>

</form>





<h4>Rezept austellen</h4>
<prescription patient="pdvCtrl.patient"></prescription>

