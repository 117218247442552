(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name dms.signoff.directive:signCounter
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="dms.signoff">
       <file name="index.html">
        <sign-counter></sign-counter>
       </file>
     </example>
   *
   */
  angular
    .module('dms.signoff')
    .directive('signCounter', signCounter);


  signCounter.$inject = ['vertxEventBusService', '$log', 'File'];
  function signCounter(vertxEventBusService, $log, File) {
    return {
      restrict: 'EA',
      scope: {},
      // templateUrl: 'dms/signoff/sign-counter-directive.tpl.html',
      template: '<span class="label label-danger">{{scCtrl.unsignedScans.length}}</span>',
      replace: false,
      controllerAs: 'scCtrl',
      controller: function () {
        var vm = this;
        vm.name = 'signCounter';
        vm.unsignedScans = File.$search({signedoff: false});


        vertxEventBusService.on('signoff.update', function () {
          vm.unsignedScans.$refresh();
          $log.debug('signoff.update caught: ', vm.unsignedScans);
        });

      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
