<!-- new consult button -->
<!-- show only if there is no open consult for the patientid -->
<!-- <form ng-show="openConsult === 'undefined' || openConsult == null"> -->
<form ng-hide="consCtrl.patient.inconsult" id="startconsult" name="startconsult">
  <button class="btn btn-success spacer-bottom" type="button" ng-click="consCtrl.startConsult();">
    Konsultation starten
  </button>
</form>

<!--<form ng-hide="consCtrl.patient.inconsult || consCtrl.patient.waitinglist.length > 0" id="putonwl" name="putonwl">
  <button ng-repeat="wl in consCtrl.waitinglists"
          ng-click="consCtrl.wlAddPatient(wl, consCtrl.patient)"
          class="btn btn-info">
    <i class="fa fa-arrow-right"></i> WL {{wl.name}}
  </button>
</form>

<form ng-show="consCtrl.patient.waitinglist.length > 0" id="removefromwl" name="removefromwl">
  <button class="btn btn-warning spacer-bottom" type="button"
          ng-click="consCtrl.wlRemovePatient(consCtrl.patient.waitinglist, consCtrl.patient);">
    Patient von Warteliste {{consCtrl.patient.waitinglist}} streichen
  </button>
</form>-->


<!--
  &nbsp;
      Auf Warteliste setzen:&nbsp;<select
        ng-model="patient.waitinglist"
        ng-change="consCtrl.changedWl()"
        ng-options="wl as wl.name for wl in consCtrl.waitinglists track by w._id">
      <option value="unset">Von Warteliste entfernen</option>
    </select>
 -->

<form ng-show="consCtrl.patient.inconsult">
  <button class="btn btn-success spacer-bottom" type="button" ui-sref="patientrecord.current">
    Zur aktuellen Konsultation wechseln
  </button>
</form>

<!--
<h4>Diagnosen</h4>
<keywords-widget keywords="consCtrl.patient.diagnoses" text="Neue Diagnose für Patienten"
                 orderbycolor="true"></keywords-widget>
-->

<!-- Patientenotizen -->
<div class="form-group">
  <label for="consultnotes">
    <h4 uib-tooltip="Diese Patientennotizen werden nicht in Befunde oder andere Dokumente übernommen.">Permanente
      Notizen zum Patienten (privat)</h4></label>
  <textarea class="form-control" ng-model="consCtrl.patient.notes"
            ng-change="consCtrl.patient.$save()"
            ng-model-options="{ updateOn: 'default blur', debounce: { default: 20000, blur: 0 } }"
            id="consultnotes"></textarea>
</div>


<!-- Historie -->
<h3>Historie</h3>

<div class="alert alert-info" ng-show="consCtrl.patConsults.length === 0">
  <h4><i class="fa fa-info-circle"></i>&nbsp;Es wurden noch keine Konsultationen abgeschlossen</h4>
</div>

<uib-accordion close-others="oneAtATime">
  <uib-accordion-group heading="{{group.title}}"
                       ng-repeat="cons in consCtrl.patient.consults  | filter: {open: false } | orderBy: '-created'">
    <uib-accordion-heading>
      <!-- Stern/starred -->
      <div class="row" style="padding:3px">
        <i ng-show="cons.stared" class="fa fa-star fa-2x" style="color: #dddd33"></i><i ng-hide="cons.stared"
                                                                                        class="fa fa-star-o fa-2x"></i>
        {{cons.created | date}}
        <!-- Consult Keywords -->
        <!--<span ng-repeat="kw in cons.flatkeywords track by $index | orderBy: 'nr'"><span class="label label-{{kw.col}}">{{kw.key}}</span>&ensp; </span>-->
        <span ng-repeat="kw in cons.keywords track by $index"><span class="label label-{{kw.col || primary}}">{{kw.key}}</span>&ensp; </span>
      </div>
      <div class="row" style="padding:3px">
        <!-- Exam Labels -->
        <!-- old -->
        <span ng-repeat="label in cons.examlabels track by $index"
              ng-hide="cons.examlabelsPrimary || cons.examlabelsSecondary"><span
            class="label label-success">{{label}}</span>&ensp; </span>
        <!-- new primary labels -->
        <span ng-repeat="plabel in cons.examlabelsPrimary track by $index">
        <!-- PDF vorhanden -->
        <a href="/routes/pdfreader/{{plabel.docid}}" uib-tooltip="Befund PDF Ansicht" ng-show="plabel.docid"
           target="_blank" style="text-decoration: none">
          <span class="label label-primary">
            {{plabel.type}}
          </span>&ensp;
        </a>
          <!-- PDF nicht vorhanden -->
        <span class="label label-primary" ng-hide="plabel.docid">
            {{plabel.type}}
          </span>&ensp;
        </span>
        <!-- new secondary labels -> Gesamtbefund -->
        <span ng-repeat="slabel in cons.examlabelsSecondary track by $index">
           <!-- Nice, try, but din't work out - no way to access gesamtbefund withour complicated query -->
          <!-- <a href="/routes/pdfreader/{{cons.docid}}" uib-tooltip="Gesamtbefund PDF" target="_blank"
              style="text-decoration: none">-->
            <span class="label label-info">{{slabel.type}}</span>&ensp;
          <!--</a>-->
        </span>
      </div>
    </uib-accordion-heading>


    <h4 ng-show="cons.symptoms">Symptome</h4>
    <p ng-show="cons.symptoms">{{cons.symptoms}}</p>
    <h4 ng-show="cons.therapy">Therapie</h4>
    <p ng-show="cons.therapy">{{cons.therapy}}</p>
    <h4 ng-show="cons.diagnoses">Diagnosen</h4>
    <p ng-show="cons.diagnoses">{{cons.diagnoses}}</p>
    <h4>Anmerkungen</h4>
    {{cons.comments}}
    <div>
      <!-- Dokumente einblenden -->
      <button class="btn btn-primary" ng-hide="cons.docs" ng-click="consCtrl.getDocuments(cons)">Dokumente einblenden
      </button>
      <button class="btn btn-primary" ng-show="cons.docs" ng-click="cons.docs = null">Dokumente ausblenden</button>
      <!-- Untersuchungen einblenden -->
      <span>
        <button class="btn btn-primary" ng-hide="cons.exams" ng-click="consCtrl.getExams(cons)">
          Untersuchungen einblenden
        </button>
        <button class="btn btn-primary" ng-show="cons.exams" ng-click="cons.exams = null">
          Untersuchungen ausblenden
        </button>
      </span>
      <!-- Bilder einblenden -->
      <span ng-show="cons.images.length > 0 ">
        <button class="btn btn-primary" ng-hide="cons.imgs" ng-click="cons.imgs = 1">
            Bilder der Konsultation einblenden
        </button>
        <button class="btn btn-primary" ng-show="cons.imgs" ng-click="cons.imgs = null">Bilder ausblenden</button>
      </span>

      <!-- Dokumente Block -->
      <div ng-show="cons.docs">
        <h4>Dokumente (Detail- &amp; Gesamtbefund, Überweisungen, ...)</h4>
        <div class="row" ng-repeat="doc in cons.docs">
          <div class="col-md-10">{{doc.type}}</div>
          <div class="col-md-2" style="text-align: right">
            <a href="/routes/pdfreader/{{doc._id}}" target="_blank" class="btn btn-primary" ng-show="doc._id"><i
                class="fa fa-eye"></i></a>
            <a href="/routes/pdfdownload/{{doc._id}}" class="btn btn-primary" ng-show="doc._id"><i
                class="fa fa-download"></i></a>
          </div>
        </div>
      </div>

      <!-- Exam Block  -->
      <div ng-show="cons.exams">
        <h4>Untersuchungen</h4>
        <div class="alert alert-info" ng-show="cons.examinations.length === 0">Keine Untersuchungen gefunden</div>
        <div class="row examrow" ng-repeat="exam in cons.examinations">
          <div class="col-md-9"><strong>{{exam.type}}</strong><br>{{exam.diagnosis}}</div>
          <div class="col-md-3" style="text-align: right">
            <a ng-show="exam.files.length > 0 && !eximg" ng-click="eximg = 1" class="btn btn-primary"
               uib-tooltip="Bilder anzeigen">
              <i class="fa fa-image"></i>
            </a>
            <a ng-show="exam.files.length > 0 && eximg" ng-click="eximg = null" class="btn btn-primary"
               uib-tooltip="Bilder ausblenden">
              <i class="fa fa-image"></i>
            </a>
            <a ng-show="!exdata" ng-click="exdata = 1" class="btn btn-primary" uib-tooltip="Daten anzeigen">
              <i class="fa fa-table"></i>
            </a>
            <a ng-show="exdata" ng-click="exdata = null" class="btn btn-primary" uib-tooltip="Daten ausblenden">
              <i class="fa fa-table"></i>
            </a>
            <!-- Untersuchungs Befund PDF für includefindings false untersuchungen -->
            <a href="/routes/pdfreader/{{exam.docid}}" target="_blank" ng-hide="exam.template.includefindings === true"
               uib-tooltip="Befund PDF anzeigen" class="btn btn-primary"><i class="fa fa-eye"></i></a>
            <a href="/routes/pdfdownload/{{exam.docid}}" ng-hide="exam.template.includefindings === true"
               uib-tooltip="Befund PDF speichern" class="btn btn-primary"><i class="fa fa-download"></i></a>
            <!--            &lt;!&ndash; Gesamtbefund für includefindings untersuchungen &ndash;&gt;
                        <a href="/routes/pdfreader/{{cons.docid}}" target="_blank" ng-show="exam.template.includefindings === true"
                           uib-tooltip="Befund PDF anzeigen" class="btn btn-primary"><i class="fa fa-eye"></i></a>
                        <a href="/routes/pdfdownload/{{cons.docid}}" ng-show="exam.template.includefindings === true"
                           uib-tooltip="Befund PDF speichern" class="btn btn-primary"><i class="fa fa-download"></i></a>-->
          </div>
          <!-- Exam Bilder -->
          <div ng-show="eximg" style="padding:20px">

            <!--<div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"-->
            <div class="col-xs-12"
                 ng-click="consCtrl.showImage(img)"
                 ng-repeat="img in exam.files | filter: {mimetype: 'image/jpeg'} |  orderBy: '_id'">

              <img class="img-responsive" src="/routes/files/filereader/{{img.fileid}}"
                   uib-tooltip="{{img.label}}">
            </div>

          </div>
          <!-- Exam Daten -->
          <div ng-show="exdata" style="padding:20px">

            <div class="row">
              <div class="col-xs-12">
                <!-- fieldgroups -->
                <div ng-repeat="grp in exam.template.fieldgroups">
                  <h5 class="dl-horizontal" ng-hide="grp.label == ''" ng-model="grp.label"
                      style="border-bottom: 1px solid black; border-top: 1px solid black;  padding-top: 5px; padding-bottom: 5px">
                    {{grp.label}}
                  </h5>
                  <h5 class="dl-horizontal" ng-show="grp.label == ''" ng-model="grp.label"
                      style="border-bottom: 1px solid black; padding-top: 5px; padding-bottom: 5px">
                  </h5>
                  <!-- fields -->
                  <dl class="dl-horizontal">
                    <span class="dl-horizontal" ng-repeat="field in grp.fields">
                      <dt>{{field.label}}</dt>
                      <dd><span ng-repeat="val in field.avalues">{{val.name}}{{$last ? '' : ', '}}</span></dd>
                    </span>
                  </dl>
                </div>

                <!-- exam images -->
                <div class="row spacer-top" ng-show="exam.files">
                  <!--<div class="col-xs-12 imgcontainer"-->
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 imgcontainer"
                       ng-repeat="img in exam.files | filter: {mimetype: 'image/jpeg'} |  orderBy: '_id'">

                    <img class="img-responsive" src="/routes/files/filereader/{{img.fileid}}"
                         uib-tooltip="{{img.label}}">
                    <!--<img class="img-responsive" src="/routes/files/filereader/{{img.fileid}}"
                         uib-tooltip="{{img.label}}"
                         ng-click="celCtrl.editImage(img)">-->
                    <!--<div class="imgcheckbox"><input type="checkbox" ng-model="img.include" ng-click="exam.$save()"></div>-->
                  </div>
                </div>

                <!-- Befundblock  -->
                <h5 class="dl-horizontal"
                    style="border-bottom: 1px solid black; border-top: 1px solid black; padding-top: 5px; padding-bottom: 5px">
                  Befund</h5>
                <dl class="dl-horizontal">
                  <dt>Diagnose</dt>
                  <dd><span
                      ng-repeat="diagnosis in exam.template.diagnosis.avalues">{{diagnosis.name}}{{$last ? '' : ', '}}</span>
                  </dd>
                  <dt>Empfehlung</dt>
                  <dd><span
                      ng-repeat="diagnosis in exam.template.recommendation.avalues">{{diagnosis.name}}{{$last ? '' : ', '}}</span>
                  </dd>
                  <dt>Anmerkung</dt>
                  <dd><span
                      ng-repeat="diagnosis in exam.template.comment.avalues">{{diagnosis.name}}{{$last ? '' : ', '}}</span>
                  </dd>
                </dl>

              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- Bilder block -->
      <div ng-show="cons.imgs">
        <h4>Bilder aus der Konsultation</h4>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
             ng-repeat="img in cons.images | filter: {mimetype: 'image/jpeg'} |  orderBy: '_id'">

          <img class="img-responsive" src="/routes/files/filereader/{{img.fileid}}"
               ng-click="consCtrl.showImage(img)"
               uib-tooltip="{{img.label}}">
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
             ng-repeat="video in cons.images | filter: {mimetype: 'video/quicktime'} |  orderBy: '_id'">
          <video controls class="img-responsive" ng-src="{{video.fileid | trustUrl}}"></video>
        </div>
      </div>
    </div>
  </uib-accordion-group>
</uib-accordion>
