(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:patientSearch
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <patient-search></patient-search>
   </file>
   </example>
   *
   */
  angular
    .module('patient')
    .directive('patientSearch', patientSearch);

  patientSearch.$inject = ['Patientsearch', '$state', '$log'];

  function patientSearch(Patientsearch, $state, $log) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'patient/patient-search-directive.tpl.html',
      replace: false,
      controllerAs: 'psCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.showpatient = showpatient;
        vm.getNames = getNames;

        // typeahead patient search backend query
        function getNames(val) {
          console.log('loading names');

          return Patientsearch.$search({query: val}).$asPromise().then(function (response) {
            console.log('response: ', response);
            return response.map(function (patient) {
              patient.text = patient.firstname + ' ' + patient.lastname + ', ' + patient.svnr;
              return patient;
            });
          });
        }

        // typeahead patient search action
        function showpatient($item, $model, $label) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          var patid = $model._id;
          var state;
          $log.debug('$model: ', $model);
          $log.debug('consultstate bool: ', $model.inconsult);
          $log.debug('checkoutstate bool: ', $model.incheckout);
          $log.debug('consultstate: ', state);
          $state.go('patientrecord.current', {patientid: patid});
          /* if ($model.inconsult === true && $model.incheckout === false) {
            state = 'patientrecord.current.' + $model.consultstate;
            $log.debug('consultstate: ', state);
            $state.go('patientrecord.current.' + $model.consultstate, {patientid: patid});
          }
          else if ($model.inconsult === true && $model.incheckout === true) {
            $state.go('patientrecord.checkout.' + $model.checkoutstate, {patientid: patid});
          }
          else {
            $state.go('patientrecord.history', {patientid: patid});
          }*/
          vm.asyncSelected = null;
        }
      }
    };
  }
}());
