(function () {
  'use strict';
  
  /**
   * @ngdoc service
   * @name waitinglist.factory:Wlcommons
   *
   * @description
   *
   */
  angular
    .module('waitinglist')
    .factory('Wlcommons', Wlcommons);
  
  Wlcommons.$inject = ['$log'];
  
  function Wlcommons($log) {
    var WlcommonsBase = {};
    
    
    WlcommonsBase.addPatient = function (list, patient) {
      var i;
/*      var pat = {};
      pat._id = patient._id;
      pat.firstname = patient.firstname;
      pat.lastname = patient.lastname;
      pat.titlefront = patient.titlefront;
      pat.titleback = patient.titleback;
      pat.svnr = patient.svnr;
      pat.birthdate = patient.birthdate;*/
      
      list.patients.$fetch().$asPromise().then(function () {
        // don't add patient to list if patient is alreaddy on list
        for (i = 0; i < list.length; i++) {
          if (list.patients[i]._id === patient._id) {
            list.splice(i, 1);
          }
        }
        list.patients.push(patient);
        patient.waitinglistid = list._id;
        patient.waitinglistname = list.name;
        list.$save();
        patient.$save();
      });
    };
    
    WlcommonsBase.removePatient = function (list, patient) {
      var i;
      
      $log.debug('wl.removePatient called: ', list, patient);
      
      list.patients.$fetch().$asPromise().then(function () {
        for (i = 0; i < list.length; i++) {
          if (list[i].patient._id === patient._id) {
            list.splice(i, 1);
          }
        }
        list.$save();
        delete patient.waitinglistid;
        delete patient.waitinglistname;
        $log.debug('list, patient after remove: ', list, patient);
        patient.$save();
      });
    };
    
    return WlcommonsBase;
  }
}());
