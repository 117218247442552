(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name heartbeat.directive:heartbeat
   * @restrict A
   * @element
   *
   * @description detects backend outages and warns user
   *
   *
   * @example
   <html name="index.html">
   <body heartbeat></body>
   </html>
   *
   */
  angular
    .module('heartbeat')
    .directive('heartbeat', heartbeat);
  
  
  heartbeat.$inject = ['$log', 'vertxEventBusService', '$state', '$rootScope', '$interval', '$window', '$timeout', '$uibModal'];
  
  function heartbeat($log, vertxEventBusService, $state, $rootScope, $interval, $window, $timeout, $uibModal) {
    return {
      restrict: 'A',
      scope: {},
      replace: false,
      controllerAs: 'heartbeat',
      controller: function () {
        var vm = this;
        vm.name = 'heartbeat';
        
        $interval(function () {
          // $log.debug('timout called');
          if (angular.isUndefined($rootScope.connfailure)) {
            $rootScope.connfailure = 0;
          }
          
          // $log.debug('heartbeat : ', $rootScope.heartbeat.isBetween(moment().subtract(5, 'second'), moment()));
          
          if (angular.isDefined($rootScope.heartbeat)) {
            if ($rootScope.heartbeat.isBetween(moment().subtract(5, 'second'), moment())) {
              // $log.debug('connection exists');
              $rootScope.connfailure = 0;
            }
            else {
              // $log.debug('connection failed');
              $rootScope.connfailure++;
            }
            
            if ($rootScope.connfailure >= 3) {
              // $window.alert('Verbindung futsch!');
              if (angular.isUndefined($rootScope.connalert)) {
                $rootScope.connalert = $uibModal.open({
                  template: '<div class="modalspacer">' +
                  '<div class="alert alert-danger" ng-hide="connfailure === 0">' +
                  '<h1 style="text-align: center"><i class="fa fa-warning"></i> Die Verbindung zum Server wurde unterbrochen!</h1>' +
                  '<h3 style="text-align: center">Dieses Hinweis verschwindet, sobald der Fehler behoben ist.</h3>' +
                  '<div class="spacer-top"><h3>Fehlerbehebung</h3>' +
                  '<ul><li>Für alle: 2 Minuten warten, Verbindungsfehler beheben sich oft von alleine.</li>' +
                  '<li>Im WLAN: WLAN am Laptop o. iPad abdrehen und wieder neu verbinden. 60 Sekunden warten.</li>' +
                  '<li>Über VPN: VPN abdrehen und wieder neu verbinden. 60 Sekunden warten.</li>' +
                  '<li>Für alle: Sprechstunde/Browser schliessen und wieder öffnen. 60 Sekunden warten.</li>' +
                  '<li>Wenn das alles nichts geholfen hat: anrufen! 0664 845 96 90</li><!-- TODO - Support Hotline Nummer! -->' +
                  '</ul></div></div>' +
                  '<div class="alert alert-success" ng-show="connfailure === 0">' +
                  '<h1 style="text-align: center"><i class="fa fa-check"></i> Die Verbindung zum Server wurde wieder hergestellt!</h1>' +
                  '<h3>Je nach dem wo Sie gerade gearbeitet haben, kann es zu Datenverlust der letzten 20 Sekunden gekommen sein.' +
                  'Bitte überprüfen und ggf. nochmal eintragen.</h3>' +
                  '<button class="btn btn-success" ng-click="connalert.close()">Weiterabeiten</button>' +
                  '</div></div>',
                  size: 'lg',
                  backdrop: 'static',
                  controllerAs: 'miCtrl',
                  bindToController: true,
                  controller: function () {
                    $log.debug('modal $scope: ', $rootScope);
                    // this an empty controller - weird
                    var vmi = this;
                    vmi.$rootScope = $rootScope;
                  },
      /*            controller: ['$rootScope', function ($rootScope) {
                    var vmi = this;
                    vmi.$rootScope = $rootScope;
                  }],*/
                  resolve: {}
                });
              }
            }
          }
          
        }, 5000);
        
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        
        vertxEventBusService.on('heartbeat', function () {
          
          $rootScope.heartbeat = moment();
          // $log.debug('heartbeat caught: ', $rootScope.heartbeat);
        });
        
        
      }
    };
  }
}());
