(function () {
  'use strict';

  /* @ngdoc object
   * @name invoice
   * @description
   *
   */
  angular
    .module('finance.invoice', [
      'ui.router',
      'smart-table',
      'utils.filereader',
      'ui.bootstrap'
    ])
    .filter('bezahlt', function () {
      return function (input) {
        return input ? 'bezahlt' : 'offen';
      };
    })
    .filter('gemahnt', function () {
      return function (input) {
        return input ? 'gemahnt' : 'nicht gemahnt';
      };
    });

  // klammerfriedhof
}());
