<aside ui-view="leftbar" class="hidden-sm hidden-xs hidden-md col-lg-2 col-xl-3">
  <patient-memo patient="patrCtrl.patient" ng-show="patrCtrl.conf.patientmemo == true"></patient-memo>
  <!-- <patient-today ng-hide="patrCtrl.conf.patientmemo == true"></patient-today>-->
</aside>
<div class="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-9">
  <!-- patient header large screens md, lg, xl -->
  <div class="hidden-xs hidden-sm">
    <!-- Photo, Name, Risiken, Diagnosen -->
    <div class="row">
      <!-- Photo -->
      <div class="col-sm-2 portraitcontainer">
        <img ng-src="{{patrCtrl.patient.image}}" class="img-responsive"
             alt="{{patrCtrl.patient.firstname}} {{patrCtrl.patient.lastname}}">
        <imagecapture filehandle="patrCtrl.newphoto" class="imgcapture"></imagecapture>
      </div>
      <!-- Name, Risiken, Diagnosen -->
      <div class="col-sm-10">

        <h3><span ng-show="patrCtrl.patient.titlefront">{{patrCtrl.patient.titlefront}} </span>{{patrCtrl.patient.firstname}}
          {{patrCtrl.patient.lastname}}<span
              ng-show="patrCtrl.patient.titleback">, {{patrCtrl.patient.titleback}} </span>,
          * {{patrCtrl.patient.birthdate}}

          <!-- Risikofaktoren -->
          <i class="fa fa-glass text-danger" uib-tooltip="Alkohol: {{patrCtrl.patient.risk.alcoholdetails}}"
             ng-show="patrCtrl.patient.risk.alcohol === true"></i>
          <i class="fa fa-glass text-muted" uib-tooltip="Alkohol: {{patrCtrl.patient.risk.alcoholdetails}}"
             ng-show="patrCtrl.patient.risk.alcohol === false"></i>
          <i class="fa fa-magic text-danger" uib-tooltip="Nikotin: {{patrCtrl.patient.risk.nicotindetails}}"
             ng-show="patrCtrl.patient.risk.nicotin === true"></i>
          <i class="fa fa-magic text-muted" uib-tooltip="Nikotin: {{patrCtrl.patient.risk.nicotindetails}}"
             ng-show="patrCtrl.patient.risk.nicotin === false"></i>
          <i class="fa fa-warning text-danger" uib-tooltip="{{patrCtrl.patient.risk.other}}"
             ng-show="patrCtrl.patient.risk.otherb === true"></i>
          <i class="fa fa-warning text-muted" uib-tooltip="{{patrCtrl.patient.risk.other}}"
             ng-show="patrCtrl.patient.risk.otherb === false"></i>
        </h3>


        <!-- Diagnosen-->
        <!--      <keywords-widget keywords="patrCtrl.patient.diagnoses" text="Neue Diagnose für Patienten"
                               orderbycolor="true"></keywords-widget>-->
        <sortable-keywords keywords="patrCtrl.patient.diagnoses" text="Neue Diagnose für Patienten"
                           orderbycolor="true"></sortable-keywords>




      </div>
    </div>
    <!-- Nav tabs -->
    <ul class="nav nav-tabs hidden-xs hidden-sm" role="tablist" style="padding-top: 1ex;">
      <!-- Aktuell -->
      <li role="presentation" ui-sref="patientrecord.current" ui-sref-active="active"
          ng-show="patrCtrl.patient.inconsult === true && !patrCtrl.patient.incheckout === true" class="curtab">
        <a aria-controls="aktuell"><u>A</u>ktuell</a>
      </li>
      <!-- Rechnungspositionen -->
      <li role="presentation" ui-sref="patientrecord.billing" ui-sref-active="active"
          ng-show="patrCtrl.patient.inconsult === true && !patrCtrl.patient.incheckout === true" class="curtab">
        <a aria-controls="Rechnugspositionen" uib-tooltip="Rechnungspositionen"><u>R</u>echnung</a>
      </li>
      <!-- Abschluss -->
      <li role="presentation" ui-sref="patientrecord.checkout" ui-sref-active="active"
          ng-show="patrCtrl.patient.inconsult === true" class="curtab" uib-tooltip="Aktuelle Dokumente und Abschluß">
        <a aria-controls="aktuell"><u>S</u>chluss</a>
      </li>
      <!-- Historie -->
      <li role="presentation" ui-sref="patientrecord.history" ui-sref-active="active">
        <a aria-controls="ordination"><u>H</u>istorie</a>
      </li>
      <!-- Medikamente -->
      <li role="presentation" ui-sref="patientrecord.drugs" ui-sref-active="active">
        <a aria-controls="Medikamente"><u>M</u>edikamente</a>
      </li>
      <!-- Untersuchungen -->
      <li role="presentation" ui-sref="patientrecord.examinations" ui-sref-active="active">
        <a aria-controls="Untersuchungen"><u>U</u>ntersuchungen</a>
      </li>
      <!-- Dokumente -->
      <li role="presentation" ui-sref="patientrecord.documents" ui-sref-active="active">
        <a aria-controls="Dokumente"><u>D</u>okumente</a>
      </li>
      <!-- TRacker -->
      <li role="presentation" ui-sref="patientrecord.trackers" ui-sref-active="active">
        <a aria-controls="TRacker"><u>T</u>racker</a>
      </li>
      <!-- Stammdaten -->
      <li role="presentation" ui-sref="patientrecord.basicdata" ui-sref-active="active">
        <a aria-controls="Stammdaten">Stammdaten</a>
      </li>
    </ul>
  </div> <!-- end of large screen patient header -->


  <!-- patient header small screens xs, sm -->
  <div class="hidden-md hidden-lg hidden-xl">
    <div class="row">
      <!-- Photo -->
      <div class="col-xs-3 col-sm-3 portraitcontainer">
        <img ng-src="{{patrCtrl.patient.image}}" class="img-responsive"
             alt="{{patrCtrl.patient.firstname}} {{patrCtrl.patient.lastname}}">
        <imagecapture filehandle="patrCtrl.newphoto" captureid="patientphoto" class="imgcapture"></imagecapture>
      </div>
      <!-- Name, Risiken -->
      <div class="col-xs-9 col-sm-9">
        <h3><span ng-show="patient.titlefront">{{patrCtrl.patient.titlefront}} </span>{{patrCtrl.patient.firstname}}
          {{patrCtrl.patient.lastname}}<span
              ng-show="patrCtrl.patient.titleback">, {{patrCtrl.patient.titleback}} </span>,
          *&nbsp;{{patrCtrl.patient.birthdate}}&nbsp;<i class="fa fa-warning text-danger" uib-tooltip="Testpatient"
                                                        ng-show="patrCtrl.patient.test"></i>
          <i class="fa fa-glass text-danger" uib-tooltip="Alkohol: {{patrCtrl.patient.risk.alcoholdetails}}"
             ng-show="patrCtrl.patient.risk.alcohol === true"></i>
          <i class="fa fa-glass text-muted" uib-tooltip="Alkohol: {{patrCtrl.patient.risk.alcoholdetails}}"
             ng-show="patrCtrl.patient.risk.alcohol === false"></i>
          <i class="fa fa-magic text-danger" uib-tooltip="Nikotin: {{patrCtrl.patient.risk.nicotindetails}}"
             ng-show="patrCtrl.patient.risk.nicotin === true"></i>
          <i class="fa fa-magic text-muted" uib-tooltip="Nikotin: {{patrCtrl.patient.risk.nicotindetails}}"
             ng-show="patrCtrl.patient.risk.nicotin === false"></i>
          <i class="fa fa-warning text-danger" uib-tooltip="{{patrCtrl.patient.risk.other}}"
             ng-show="patrCtrl.patient.risk.otherb === true"></i>
          <i class="fa fa-warning text-muted" uib-tooltip="{{patrCtrl.patient.risk.other}}"
             ng-show="patrCtrl.patient.risk.otherb === false"></i></h3>
      </div>
    </div>
    <!-- patient diagnoses xs -->
    <div class="row spacer-top">
      <sortable-keywords keywords="patrCtrl.patient.diagnoses"
                         text="Neue Diagnose für Patienten"
                         orderbycolor="true">
      </sortable-keywords>
    </div>
    <!-- dropdown nav small screens -->
    <div class="spacer-bottom row" uib-dropdown style="padding-top: 1ex;">
      <span uib-dropdown-toggle class="btn btn-info spacer-bottom" style="width: 100%">Navigation</span>
      <ul uib-dropdown-menu="">
        <!-- Aktuell -->
        <li role="presentation" ui-sref="patientrecord.current" ui-sref-active="active"
            ng-show="patrCtrl.patient.inconsult === true && !patrCtrl.patient.incheckout === true">
          <a aria-controls="aktuell">Aktuell</a>
        </li>
        <!-- Rechnungspositionen -->
        <li role="presentation" ui-sref="patientrecord.billing" ui-sref-active="active"
            ng-show="patrCtrl.patient.inconsult === true && !patrCtrl.patient.incheckout === true">
          <a aria-controls="Rechnugspositionen">Rechnung</a>
        </li>
        <!-- Abschluss -->
        <li role="presentation" ui-sref="patientrecord.checkout" ui-sref-active="active"
            ng-show="patrCtrl.patient.inconsult === true"><a aria-controls="aktuell">Schluss</a>
        </li>
        <li role="presentation" ui-sref-active="active"><a aria-controls="ordination"
                                                           ui-sref="patientrecord.history">Historie</a>
        </li>
        <li role="presentation" ui-sref-active="active"><a aria-controls="Medikamente"
                                                           ui-sref="patientrecord.drugs">Medikamente</a>
        </li>
        <li role="presentation" ui-sref-active="active"><a ui-sref="patientrecord.examinations"
                                                           aria-controls="Untersuchungen">Untersuchungen</a></li>
        <li role="presentation" ui-sref-active="active"><a aria-controls="Dokumente" ui-sref="patientrecord.documents">Dokumente</a>
        </li>
        <li role="presentation" ui-sref-active="active"><a aria-controls="Dokumente" ui-sref="patientrecord.trackers">Tracker</a>
        </li>
        <li role="presentation" ui-sref-active="active"><a ui-sref="patientrecord.basicdata" aria-controls="Stammdaten">Stammdaten</a>
        </li>
      </ul>
    </div>
  </div> <!-- end of small screen header -->

  <div class="row">
    <!-- Unsignierte Dokumente -->
    <div class="alert alert-danger spacer-top" ng-show="patrCtrl.unsignedScans.length > 0">
      <h4>Unsignierte Dokumente für den Patienten gefunden.
        <a class="btn btn-primary" ui-sref="dms.patsignoff({patientid: patrCtrl.patient._id})">
          Dokumente ansehen
        </a>
      </h4>
    </div>


  <!-- Offene Rechnungen -->
  <div class="alert alert-info spacer-top" ng-show="patrCtrl.openInvoices.length > 0">
    <h4>Offene Rechnungen des Patienten gefunden.</h4>
    <table class="table table-striped">
      <tr>
        <th>Datum</th>
        <th>Betrag</th>
        <th>Fällig</th>
        <th>Datei</th>
        <th>Aktion</th>
      </tr>
      <tr ng-repeat="inv in patrCtrl.openInvoices">
        <td ng-bind="inv.date"></td>
        <td>{{inv.total | currency: '': 2}}&nbsp;€</td>
        <td>
          <span ng-show="moment(inv.duedate).isBefore(moment())" style="color: #AA3333">{{inv.fduedate}}</span>
          <span ng-hide="moment(inv.duedate).isBefore(moment())" style="color: #33AA33">{{inv.fduedate}}</span>
        </td>
        <td>
          <a href="/routes/pdfreader/{{inv.patientinvoiceid}}" uib-tooltip="Patientenrechnung" target="_blank"><i
              class="fa fa-file-pdf-o"></i></a>
        </td>
        <td><span class="btn btn-primary" ng-hide="inv.paid"
                  ng-click="patrCtrl.enterPaidDate(inv)">Zahlung registrieren</span></td>
      </tr>
    </table>
  </div>

  <!-- View für alle Teile der Patienteakte -->
  <div ui-view="pr-details" style="padding-top: 1ex;"></div>
  </div>
</div>
