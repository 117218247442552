<h3>Lokal erstellte Dokumente</h3>

<div class="alert alert-info" ng-show="docCtrl.patDocuments.length === 0">Keine Dokumente gefunden</div>
<table st-table="docCtrl.pdocs" st-safe-src="docCtrl.patDocuments" class="table table-striped">
  <thead>
  <tr>
    <th st-sort="created" st-sort-default="reverse">Datum</th>
    <th st-sort="type">Typ</th>
    <th st-sort="keywords">Stichworte</th>
    <th>Datei</th>
  </tr>
  <tr>
    <th><input st-search="created" placeholder="Suche nach Datum" class="input-sm form-control" type="search"/></th>
    <th><input st-search="type" placeholder="Suche nach Typ" class="input-sm form-control" type="search"/></th>
    <th><input st-search="keywords" placeholder="Suche nach Stichwort" class="input-sm form-control" type="search"/>
    </th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr ng-repeat="doc in docCtrl.pdocs">
    <!--<td>{{doc.created | javadate: 'll'}}</td>-->
    <td>{{doc.created | moment: 'll'}}</td>
    <td>{{doc.type}}</td>
    <td>{{doc.keywords}}</td>
    <td>
      <a href="/routes/pdfreader/{{doc._id}}" target="_blank" class="btn btn-primary"><i class="fa fa-eye"></i></a>
      <a href="/routes/pdfdownload/{{doc._id}}" class="btn btn-primary"><i class="fa fa-download"></i></a>
    </td>
    <td></td>
  </tr>
  </tbody>
</table>


