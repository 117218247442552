(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name reminder.directive:patientReminder
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="reminder">
   <file name="index.html">
   <patient-reminder></patient-reminder>
   </file>
   </example>
   *
   */
  angular
    .module('reminder')
    .directive('patientReminder', patientReminder);
  
  patientReminder.$inject = ['Reminder', 'Patient', '$log'];
  
  function patientReminder(Reminder, Patient, $log) {
    return {
      restrict: 'EA',
      scope: {
        patient: '='
      },
      templateUrl: 'reminder/patient-reminder-directive.tpl.html',
      replace: false,
      controllerAs: 'premCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'patientReminder';
        vm.addTodoEntry = addTodoEntry;
        vm.calcdue = calcdue;
        vm.save = save;
        vm.newreminder = {};
        // vm.todos = [];
  
        // vm.todos = vm.patient.reminders.$refresh();
  
  
        /* vm.patient.reminders.$refresh().$then(function (reminders) {
          vm.todos = reminders;
          $log.debug('******************** patient todosCtr -  reminders loaded:', reminders);
        }, function ( ) {
          $log.debug('******************** patient todosCtr -  loading reminders failed:', reminders);
        });*/
        
/*        vm.patient.reminders.$refresh().$asPromise().then(function (reminders) {
          vm.todos = reminders;
        });*/
        
        /*        vm.patient.reminders.$refresh().$then(function (reminders) {
         vm.todos = reminders;
         });*/
        
        function addTodoEntry() {
          vm.newreminder.done = false;
          vm.newreminder.pfname = vm.patient.firstname;
          vm.newreminder.plname = vm.patient.lastname;
          vm.newreminder.psvnr = vm.patient.svnr;
          vm.newreminder.patientid = vm.patient._id;
          if (!vm.newreminder.due) {
            vm.newreminder.due = moment().toISOString();
            vm.newreminder.duestr = 'Sofort';
          }
          
          // doesn't work, needs new backend route
          // vm.todos.$create(vm.newreminder);
          
          Reminder.$create(vm.newreminder).$then(function () {
            vm.patient.reminders.$refresh();
            // vm.todos.$refresh();
          });
          vm.newreminder = {};
        }
        
        function calcdue(days, str) {
          vm.newreminder.due = moment().add(days, 'days').toISOString();
          vm.newreminder.duestr = str;
        }
        
        function save(item) {
          var logentry = {};
          logentry.created = moment().toISOString();
          if (item.done === true) {
            item.completed = moment().toISOString();
            logentry.entry = 'Todo abgeschlossen: ' + item.label;
          }
          if (item.done === false) {
            delete item.completed;
            logentry.entry = 'Todo wieder geöffnet: ' + item.label;
          }
          item.$save().$asPromise().then(function () {
            Patient.$find(item.patientid).$asPromise().then(function (pat) {
              if (!angular.isArray(pat.log)) {
                pat.log = [];
              }
              pat.log.push(logentry);
              pat.$save();
            });
          });
        }
        
        
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
