<h3>Neue Privat-Dienstleistung</h3>

<form class="form-vertical">
  <div class="row">
    <div class="form-group col-md-9">
      <label for="servname">Name</label>
      <input type="text" ng-model="servCtrl.newservice.name" class="form-control" id="servname">
    </div>
    <div class="form-group col-md-3">
      <label for="servhint" uib-tooltip="Wird nicht auf der Rechnung ausgegeben">Hinweis</label>
      <input type="text" ng-model="servCtrl.newservice.hint" class="form-control" id="servhint">
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-12">
      <label for="servdetails" uib-tooltip="Detaillierte Beschreibung der Leistung, wird auf Rechhnung ausgegeben">Details</label>
      <textarea ng-model="servCtrl.newservice.details" class="form-control" id="servdetails" placeholder="Detaillierte Beschreibung der Leistung, wird auf Rechhnung ausgegeben"></textarea>
    </div>
  </div>

  <!--<div class="row">
    <div class="form-group col-md-12">
      <label for="servcomment">Zusatz/Kommentar</label>
      <textarea class="form-control" ng-model="servCtrl.newservice.comment" rows="5" id="servcomment"></textarea>
    </div>
  </div>-->
  <div class="row">
    <div class="form-group col-md-6">
      <label for="servprice">Preis</label>
      <input type="number" min="0" max="5000" step="0.01" ng-model="servCtrl.newservice.price" class="form-control" style="text-align: right;" id="servprice">
    </div>
    <div class="form-group col-md-6" ng-init="servCtrl.newservice.tax = 0">
      <label for="servtax">Steuersatz</label>
      <input type="number" min=0 max=100 ng-model="servCtrl.newservice.tax" class="form-control" style="text-align: right;" id="servtax">
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-12">
      <button class="btn btn-default" type="button" ng-click="servCtrl.addAnother();">
        Speichern
      </button>
    </div>
  </div>
</form>

<h3>Dienstleistungskatalog</h3>


<table>
  <tr>
    <th>Kat</th>
    <th>Num</th>
    <th width="50%">Bezeichnung</th>
    <th align="right" colspan="2">NöAEK Emf.</th>
    <th></th>
    <th>Preis (EUR)</th>
    <th>USt %</th>
    <th></th>
    <th></th>
  </tr>
  <tr ng-repeat="serv in servCtrl.services | filter: { active: true }" style="vertical-align: top">
    <td ng-bind="serv.cat"></td>
    <td ng-bind="serv.num"></td>
    <td><span ng-bind="serv.name" ng-hide="serv.cat === 'Priv'"></span>
      <input type="text" ng-model="serv.name" ng-show="serv.cat === 'Priv'"
             ng-change="serv.$save()"
             ng-model-options="{ updateOn: 'default blur', debounce: { default: 5, blur: 0 } }"
             class="form-control">
      <input type="text" ng-model="serv.hint" ng-show="serv.cat === 'Priv'"
             ng-change="serv.$save()"
             ng-model-options="{ updateOn: 'default blur', debounce: { default: 5, blur: 0 } }"
             class="form-control">
      <textarea ng-model="serv.details" ng-show="serv.cat === 'Priv'" class="form-control"
             ng-change="serv.$save()" ng-model-options="{ updateOn: 'default blur', debounce: { default: 5, blur: 0 } }"
             placeholder="Details zur Leistung (werden auf der Rechnung angezeigt)"></textarea>
    </td>
    </td>
    <td align="right"><span ng-hide="serv.cat === 'Priv'">{{serv.points | aek | currency:"":2 }}</span></td>
    <td><span ng-hide="serv.cat === 'Priv'">&nbsp;EUR</span></td>
    <td align="center">
      <button class="btn btn-primary" type="button" uib-tooltip="Empfehlung übernehmen"
              ng-click="serv.price = serv.points  * 0.15; serv.$save();"
              ng-hide="serv.cat === 'Priv'">
        <i class="fa fa-copy"></i>
      </button>
    </td>
    <td><input type="number" ng-model="serv.price"
               ng-change="serv.$save()"
               ng-model-options="{ updateOn: 'default blur', debounce: { default: 5, blur: 0 } }"
               class="form-control" size="5" style="text-align: right;"></td>

    <td><input type="number" ng-model="serv.tax"
               ng-change="serv.$save()"
               ng-model-options="{ updateOn: 'default blur', debounce: { default: 5, blur: 0 } }"
               class="form-control" size="5" style="text-align: right;"></td>
    <td align="center">
     <!-- <button class="btn btn-success" type="button" uib-tooltip="Speichern" ng-click="serv.$save()">
        <i class="fa fa-check"></i>
      </button>-->
    </td>
    <td align="center">
      <button class="btn btn-danger" type="button" uib-tooltip="Löschen" ng-click="serv.active=false; serv.$save()">
        <i class="fa fa-remove"></i>
      </button>
    </td>
  </tr>
</table>
</div>
