<h3>Leistung editieren</h3>

<form class="form-vertical">
    <div class="row">
        <div class="col-md-12">
            <label>Displayname</label>
            <input class="form-control" type="text" ng-model="ieCtrl.item.displayname">
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <label>Canonical Name</label>
            <input class="form-control" type="text" ng-model="ieCtrl.item.cname">
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <label>Beschreibung</label>
            <input class="form-control" type="text" ng-model="ieCtrl.item.description">
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <label>AEK Nummer</label>
            <input class="form-control" type="text" ng-model="ieCtrl.item.aeknum">
        </div>
        <div class="col-md-4">
            <label>AEK Punkte</label>
            <input class="form-control" type="text" ng-model="ieCtrl.item.aekpoints">
        </div>
        <div class="col-md-4">
            <label>AEK Preisempfehlung</label>
            <input class="form-control" type="text" ng-model="ieCtrl.item.aekprice">
        </div>
    </div>

    <uib-accordion close-others="true">
        <uib-accordion-group heading="{{sv}}" ng-repeat="sv in Object.keys(svdata)">
            <div class="form-group">
                <div class="row">
                    <div class="col-md-6">
                        <label>Codes 1</label>
                        <array-editor array="ieCtrl.item.svdata[sv].codes1"></array-editor>
                    </div>
                    <div class="col-md-6">
                        <label>Codes 2</label>
                        <array-editor array="ieCtrl.item.svdata[sv].codes2"></array-editor>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label>Primärcode</label>
                        <input class="form-control" type="text" ng-model="ieCtrl.item.svdata[sv].primarycode">
                    </div>
                    <div class="col-md-3">
                        <label>Punkte</label>
                        <input class="form-control" type="text" ng-model="ieCtrl.item.svdata[sv].points">
                    </div>
                    <div class="col-md-3">
                        <label>Pkt-Kat</label>
                        <input class="form-control" type="text" ng-model="ieCtrl.item.svdata[sv].pointcat">
                    </div>
                    <div class="col-md-3">
                        <label>Preis</label>
                        <input class="form-control" type="text" ng-model="ieCtrl.item.svdata[sv].price">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label>Konflikte (intern)</label>
                        <array-editor array="ieCtrl.item.svdata[sv].conflictssv"></array-editor>
                    </div>
                    <div class="col-md-6">
                        <label>Konflikte (uni)</label>
                        <array-editor array="ieCtrl.item.svdata[sv].conflictsuni"></array-editor>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label>Konditionen</label>
                        <textarea class="form-control" ng-model="ieCtrl.item.svdata[sv].conditions"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label>Konflikte (intern)</label>
                        <array-editor array="ieCtrl.item.svdata[sv].conflictssv"></array-editor>
                    </div>
                    <div class="col-md-6">
                        <label>Konflikte (uni)</label>
                        <array-editor array="ieCtrl.item.svdata[sv].conflictsuni"></array-editor>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label>Wiederholung</label>
                        <input type="checkbox" ng-model="ieCtrl.item.svdata[sv].repeatable"> Wiederholbar
                    </div>
                    <div class="col-md-6">
                        <label>Limit</label>
                        <input class="form-control" type="text" ng-model="ieCtrl.item.svdata[sv].price">
                    </div>
                </div>
            </div>
        </uib-accordion-group>






</form>



<!--


svdata:
wggk: {
              codes1: [],
              codes2: [],
              primarycode: '',
              points: 0,
              pointcat: '',
              price: 0,
              conflictssv: [],
              conflictsuni: [],
              conditions: [],
              repeatable: false,
              repeatlimit: 0
            },
            -->