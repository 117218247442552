(function () {
  'use strict';

  /* @ngdoc object
   * @name consult
   * @description
   *
   */
  angular
    .module('consult', [
      'ui.router',
      'ui.bootstrap',
      'patient',
      'drug',
      'document',
      'examination',
      'examtemplate',
      'knalli.angular-vertxbus',
      'cfp.hotkeys',
      'helpers.doctor',
      'rlog'
    ]);

}());
