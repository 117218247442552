(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name utils.keywords.directive:sortableKeywords
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="utils.keywords">
       <file name="index.html">
        <sortable-keywords></sortable-keywords>
       </file>
     </example>
   *
   */
  angular
    .module('utils.keywords')
    .directive('sortableKeywords', sortableKeywords);
  
  
  sortableKeywords.$inject = ['$log'];
  
  function sortableKeywords($log) {
    return {
      restrict: 'EA',
      scope: {
        keywords: '=',
        text: '@',
        orderbycolor: '='
      },
      templateUrl: 'utils/keywords/sortable-keywords-directive.tpl.html',
      replace: false,
      controllerAs: 'vmKw',
      bindToController: true,
      controller: function () {
        var vm = this;
         
        var nrResolver = {
          primary: 1,
          info: 2,
          danger: 3,
          warning: 4,
          success: 5
        };
        
        var altTextResolver = {
          primary: '(neu)',
          info: '',
          danger: '',
          warning: '',
          success: '(St.p.)'
        };
        
        vm.orderkey = 'nr';
        vm.updateColor = updateColor;
        vm.addKeyword = addKeyword;
        vm.removeKeyword = removeKeyword;
  
        function updateColor(value, color) {
          var index = vm.keywords.map(function (el) {
            return el.key;
          }).indexOf(value);
          console.log('value: ', value, ', index: ', index);
          vm.keywords[index].col = color;
          vm.keywords[index].nr = nrResolver[color];
          vm.keywords[index].alttext = altTextResolver[color];
          vm.keywords[index].updated = moment().locale('de-at').format('l');
        }
  
        function removeKeyword(value) {
          var index = vm.keywords.map(function (el) {
            return el.key;
          }).indexOf(value);
          vm.keywords.splice(index, 1);
        }
  
        function addKeyword() {
          var kw = {};
          kw.col = 'primary';
          kw.key = vm.newkeyword;
          kw.nr = nrResolver[kw.col];
          kw.alttext = altTextResolver[kw.col];
          kw.timestamp = moment().toISOString();
          kw.created = moment().locale('de-at').format('l');
          kw.updated = moment().locale('de-at').format('l');
    
          if (vm.keywords.hasOwnProperty('length') === false) {
            vm.keywords = [];
          }
          $log.debug('Keyword: ', kw.key);
          $log.debug('Keyword length: ', kw.key.length);
          if (kw.key.length > 0) {
            vm.keywords.push(kw);
          }
          vm.newkeyword = '';
        }
  
  
  
  
  
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
  
        console.log('Attr: ', attrs);
        if (!attrs.orderbycolor) {
          attrs.orderbycolor = false;
          ctrl.orderkey = '$index';
        }
        else {
          ctrl.orderkey = 'nr';
        }
        if (!attrs.text) {
          attrs.text = 'Neues Stichwort eingeben <Eingabataste>';
        }
        
      }
    };
  }
}());
