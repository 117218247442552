(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name drug.factory:Drug
   *
   * @description
   *
   */
  angular
    .module('drug')
    .factory('Drug', Drug)
    .factory('Drugsearch', Drugsearch);

  Drug.$inject = ['restmod'];

  function Drug(restmod) {
    return restmod.model('/routes/meds');
  }

  Drugsearch.$inject = ['restmod'];

  function Drugsearch(restmod) {
    return restmod.model('/routes/meds/medahead');
  }


}());
