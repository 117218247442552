<h3>Überweisungen</h3>

<form class="form-inline">
  <h4>Überweisungsart</h4>
  <div class="form-group">
    <div class="radio" ng-init="refCtrl.newreferral.reftype = 'std'" >
      <label for="stdref" class="radio-inline"><input type="radio" ng-value="std" tabindex="1"
                                                      ng-model="refCtrl.newreferral.reftype"
                                                      id="stdref"> Standard</label>
      <label for="labref" class="radio-inline"><input type="radio" value="lab" tabindex="2"
                                                      ng-model="refCtrl.newreferral.reftype"
                                                      ng-click="refCtrl.setlab()"
                                                      id="labref"> Labor</label>
      <!--      <label for="imgref" class="radio-inline"><input type="radio" value="img"
                                                           ng-model="refCtrl.newreferral.reftype"
                                                           ng-click="refCtrl.setimg()"
                                                           id="imgref"> Bildgebende Diagnostik</label>-->

    </div>
  </div>
</form>

<!-- Überweisung an: -->
<form class="form-vertical" id="refform" name="refform">
  <div class="form-group">
    <label for="refto">
      <span uib-tooltip="Es muß entweder ein Arzt aus der Liste ausgewählt werden oder das Feld Freitext ausgefüllt werden">
        An/Zu: Arztauswahl ODER freie Texteingabe
      </span>
      <span uib-tooltip="Pflichtfeld">*</span>
    </label>
    <!-- Arztsuche -->
    <!--<doctor-search doctorresult="refCtrl.newreferral.doctor" ti="3"></doctor-search>-->
  
  
    <div class="form-group" ng-hide="refCtrl.newreferral.doctor._id">
      <doctor-search doctorresult="refCtrl.newreferral.doctor" ti="3"></doctor-search>
    </div>
    <div class="form-group" ng-show="refCtrl.newreferral.doctor._id">
      <div class="input-group">
        <input type="text" class="form-control"
               id="refdoctorname"
               readonly
               ng-model="refCtrl.newreferral.doctor.fname">
        <span class="input-group-btn">
                    <button class="btn btn-danger"
                            ng-click="refCtrl.clearRefDoctor()"><i class="fa fa-remove"></i>
                    </button>
        </span>
      </div>
    </div>
    
    <!-- Freitext -->
    <input type="text" ng-model="refCtrl.newreferral.to" tabindex="4"
           ng-required="!(refCtrl.newreferral.to || refCtrl.newreferral.doctor)"
           ng-hide="refCtrl.newreferral.doctor"
           placeholder="Freitext für Überweisungsziel" class="form-control" id="refto">
  </div>
  <!-- diagnose/Begründung -->
  <div class="form-group">
    <label for="refdiag">Diagnose/Begründung<span uib-tooltip="Pflichtfeld">*</span></label>
    <input required type="text" ng-model="refCtrl.newreferral.diag"
           class="form-control" id="refdiag" tabindex="5">
  </div>

  <!-- Selected Labvalue Output -->
  <div ng-show="refCtrl.newreferral.reftype == 'lab'">
    <labreferral allvalues="refCtrl.newreferral.labvalues"
                 additionalvalues="refCtrl.newreferral.additionalvalues"
                 ginclude="refCtrl.newreferral.ginclude"
                 ti="6">
    </labreferral>
  </div>
  
  <!-- Erbeten -->
  <div class="form-group" ng-hide="refCtrl.newreferral.reftype == 'lab'">
    <label for="refref">Erbeten<span uib-tooltip="Pflichtfeld">*</span></label>
    <textarea class="form-control" ng-model="refCtrl.newreferral.ref" rows="5"
              ng-required="!(refCtrl.newreferral.ref || refCtrl.newreferral.labvalues)"
              id="refref" tabindex="4"></textarea>
  </div>

  <!-- gültig -->
  <div class="form-group">
    <label>Gültig </label>
    <div class="radio">
      <label class="radio-inline">
        <input type="radio" ng-value="true" ng-model="refCtrl.newreferral.pdate"
               ng-click="refCtrl.newreferral.refdate = moment().locale('de-at').format('ll')">
        1 Monat</label>
      <label class="radio-inline">
        <input type="radio" ng-value="false" ng-model="refCtrl.newreferral.pdate"
               ng-click="refCtrl.newreferral.refdate = ''">
        Kein Datum</label>
    </div>
  </div>

<!-- Buttons -->
  <button class="btn btn-success" ng-disabled="refform.$invalid" type="button" ng-click="refCtrl.addAnotherReferral();" tabindex="50">
    Speichern
  </button>

  <button class="btn btn-danger" type="button" ng-click="refCtrl.dialog.close()" ng-show="refCtrl.dialog" tabindex="51">
    Schließen
  </button>
</form>

<!-- Bestehende Überweisungen -->
<div ng-repeat="ref in refCtrl.patient.openConsult.referrals">
  <div class="row">
    <div class="col-sm-11"><h4>Überweisung</h4></div>
    <div class="col-sm-1">
      <button class="btn btn-danger" ng-click="refCtrl.removeRef($index)" uib-tooltip="Überweisung löschen">
        <i class="fa fa-remove"></i>
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <dl class="dl-horizontal">
        <dt>An/zu</dt>
        <dd>{{ref.to}}{{ref.doctor.fname}}</dd>
        <dt>Diagnose</dt>
        <dd ng-bind="ref.diag"></dd>
        <dt ng-hide="ref.labvalues.length > 0">Erbeten</dt>
        <dd ng-hide="ref.labvalues.length > 0" ng-model="ref.ref">{{ref.ref}}</dd>
        <dt ng-show="ref.labvalues.length > 0">Laborwerte</dt>
        <dd ng-show="ref.labvalues.length > 0"><span
            ng-repeat="val in ref.labvalues">{{val}}{{$last ? '' : ', '}}</span></dd>
        <dt ng-show="ref.biolabels.length > 0">Biopsien</dt>
        <dd ng-show="ref.biolabels.length > 0">
          <div ng-repeat="label in ref.biolabels">{{label}}</div>
          </dd>
        <dt>Datum</dt>
        <dd ng-bind="ref.refdate"></dd>
      </dl>
    </div>
  </div>
</div>

<!-- PDF Vorschau -->
<a class="btn btn-info" target="_blank" ng-show="refCtrl.patient.openConsult.referrals.length"
   href="/routes/pdfprinter/consultpreview?patientid={{refCtrl.patient._id}}&consultid={{refCtrl.patient.openConsult._id}}&doctype=referral">
  <i class="fa fa-eye"></i> PDF Vorschau
</a>
<div style="min-height:3cm">&nbsp;</div>