/**
 * Created by lari on 31/03/2017.
 */


(function () {
  'use strict';
  /**
   * @ngdoc directive
   * @name finance.rksv.component:depexport
   * @restrict E
   * @element
   *
   * @description
   *
   * @example
   <example module="finance.rksv">
   <file name="index.html">
   <depexport></depexport>
   </file>
   </example>
   *
   */
  
  angular
    .module('finance.rksv')
    .component('depexport', {
      restrict: 'E',
      templateUrl: 'finance/rksv/depexport-component.tpl.html',
      controllerAs: 'vm',
      controller: ['$log', '$http',
        function ($log, $http) {
          var vm = this;
          vm.status = 'init';
          vm.exportDEP = exportDEP;
          vm.thisyear = thisyear;
          vm.allyears = allyears;
          vm.startdate = new Date(moment().year(), 0, 1).toISOString();
          $log.debug('Startdate: ', vm.startdate);
          // vm.enddate = new Date().toISOString();
          vm.enddate = moment().endOf('day').toISOString();
          vm.dateOptions = {
            formatYear: 'yyyy',
            startingDay: 1
          };
  
          vm.altInputFormats = ['yyyy-MM-dd', 'dd.MM.yyyy'];
  
  
          function thisyear() {
            vm.startdate = moment().startOf('year').toISOString();
            vm.enddate = moment().endOf('year').toISOString();
            exportDEP();
          }
          
          function allyears() {
            vm.startdate = moment('2017-01-01 00:00:00').toISOString();
            vm.enddate = moment().toISOString();
            exportDEP();
          }
          
          
          function exportDEP() {

            var timerange = {
              $gte: vm.startdate,
              $lt: vm.enddate
            };
            
            var query = {};
            query.created = timerange;
            
            $http.post('/routes/depexport', query).then(function (res) {
              var anchor = angular.element('<a/>');
              $log.debug('res data: ', res);
              $log.debug('data string: ', res.data.toString());
              anchor.attr({
                href: 'data:attachment/json;charset=utf-8,' + encodeURI(angular.toJson(res.data, true)),
                target: '_blank',
                download: 'depexport.json'
              })[0].click();
              vm.status = 'success';
              vm.message = 'DEP Export war erfolgreich';
              
              $log.debug('RKSV zero record sent successfully');
            }, function () {
              $log.error('DEP Export failed');
              vm.status = 'danger';
              vm.message = 'DEP Export ist fehlgeschlagen';
            });
            
          }
          
        }]
    });
  
  // klammerfriedhof
}());
