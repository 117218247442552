(function () {
  'use strict';

  angular
    .module('forms')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('forms', {
        url: '/forms',
        views: {
          main: {template: '<aside ui-view="leftbar" class="hidden-sm hidden-xs hidden-md col-lg-2 col-xl-3"></aside><div ui-view="formsmain" class="col-sm-12 col-md-12 col-lg-10 col-xl-9"></div>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });
  
    $stateProvider
      .state('forms.referral', {
        url: '/referral',
        views: {
          formsmain: {template: '<referral></referral>'}
        }
      });
  
    $stateProvider
      .state('forms.aide', {
        url: '/aide',
        views: {
          leftbar: {template: ''},
          formsmain: {template: '<aide></aide>'}
        }
      });
    
    $stateProvider
      .state('forms.time', {
        url: '/time',
        views: {
          formsmain: {template: '<timeevidence></timeevidence>'}
        }
      });
  
    $stateProvider
      .state('forms.transport', {
        url: '/transport',
        views: {
          leftbar: {template: ''},
          formsmain: {template: '<transport></transport>'}
        }
      });
   
    $stateProvider
      .state('forms.textprocessing', {
        url: '/text',
        views: {
          leftbar: {template: ''},
          formsmain: {template: '<textprocessing></textprocessing>'}
        }
      });
    
  }
}());
