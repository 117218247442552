(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name forms.directive:timeevidence
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="forms">
       <file name="index.html">
        <timeevidence></timeevidence>
       </file>
     </example>
   *
   */
  angular
    .module('forms')
    .directive('timeevidence', timeevidence);
  
  timeevidence.$inject = ['$log', '$http', '$timeout', '$state', 'Patientsearch'];
  
  function timeevidence($log, $http, $timeout, $state, Patientsearch) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'forms/timeevidence-directive.tpl.html',
      replace: false,
      controllerAs: 'teCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'timeevidence';
    
        vm.print = print;
        vm.clear = clear;
        vm.getNames = getNames;
        vm.showpatient = showpatient;
        vm.timeevidence = {};
        vm.timeevidence.fdate = moment().locale('de-at').format('ll');
        vm.timeevidence.starttime = moment().subtract(58, 'minutes').format('hh:mm');
        vm.timeevidence.endtime = moment().add(5, 'minutes').format('hh:mm');
  
        // typeahead patient search backend query
        function getNames(val) {
          console.log('loading names');
    
          return Patientsearch.$search({query: val}).$asPromise().then(function (response) {
            console.log('response: ', response);
            return response.map(function (patient) {
              patient.text = patient.firstname + ' ' + patient.lastname + ', ' + patient.svnr;
              return patient;
            });
          });
        }
  
        // typeahead patient search action
        function showpatient($item, $model, $label) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          vm.patientid = $model._id;
        }
  
  
        function print() {
          var request;
          var payload = {};
          payload.timeevidence = vm.timeevidence;
    
          request = $http({
            url: '/routes/pdfprinter/genpatientprint',
            method: 'POST',
            data: {
              patientid: vm.patientid,
              doctype: 'time',
              payload: payload
            }
          });
    
          request.success(function () {
            vm.printed = true;
            $timeout($state.go('home'), 5000);
          });
          request.error(function () {
            vm.printed = false;
          });
    
        }
  
        function clear() {
          vm.patient = null;
          vm.timevidence = {};
          vm.timeevidence.fdate = moment().locale('de-at').format('ll');
          vm.timeevidence.starttime = moment().subtract(58, 'minutes').format('hh:mm');
          vm.timeevidence.endtime = moment().add(5, 'minutes').format('hh:mm');
        }
  
  
  
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
