(function () {
  'use strict';

  angular
    .module('finance')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider
      .state('finance', {
        url: '/finance',
        views: {
          main: {template: '<aside ui-view="leftbar" class="hidden-sm hidden-xs hidden-md col-lg-2 col-xl-3"></aside><div ui-view="financemain" class="col-sm-12 col-md-12 col-lg-10 col-xl-9"></div>'},
          rightupper: {template: '<todowidget></todowidget>'},
          rightlower: {template: '<chat></chat>'}
        }
      });

    $stateProvider
      .state('finance.invoices', {
        url: '/invoices',
        views: {
          financemain: {template: '<invoicebrowser></invoicebrowser>'}
        }
      });
    
    $stateProvider
      .state('finance.invoices.cashinv', {
        url: '/cashinv',
        views: {
          invoicedetails: {template: '<cashinvoice mode=""></cashinvoice>'}
        },
        onEnter: function () {console.log('finance.invoices.cashinv entered');}
      });
    
    $stateProvider
      .state('finance.invoices.checkinv', {
        url: '/checkinv',
        views: {
          invoicedetails: {template: '<checkinvoice mode=""></checkinvoice>'}
        }
      });
    
    $stateProvider
      .state('finance.invoices.checkpaid', {
        url: '/checkpaid',
        views: {
          invoicedetails: {template: '<checkinvoice mode="paid"></checkinvoice>'}
        }
      });
    
    $stateProvider
      .state('finance.invoices.checkopen', {
        url: '/checkopen',
        views: {
          invoicedetails: {template: '<checkinvoice mode="open"></checkinvoice>'}
        }
      });
    
    $stateProvider
      .state('finance.invoices.checkoverdue', {
        url: '/checkoverdue',
        views: {
          invoicedetails: {template: '<checkinvoice mode="overdue"></checkinvoice>'}
        }
      });
    
    $stateProvider
      .state('finance.cashbook', {
        url: '/cashbook',
        views: {
          leftbar: {template: ''},
          financemain: {template: '<cashbook></cashbook>'}
        }
      });

    $stateProvider
      .state('finance.closing', {
        url: '/closing',
        views: {
          leftbar: {template: ''},
          financemain: {template: '<closing></closing>'}
        }
      });

    $stateProvider
      .state('finance.bmfexport', {
        url: '/bmfexport',
        views: {
          leftbar: {template: ''},
          financemain: {template: '<bmfexport></bmfexport>'}
        }
      });

    $stateProvider
      .state('finance.turnover', {
        url: '/turnover',
        views: {
          leftbar: {template: ''},
          financemain: {template: '<turnover></turnover>'}
        }
      });
  
    $stateProvider
      .state('finance.rksv', {
        url: '/rksv',
        views: {
          leftbar: {template: ''},
          financemain: {template: '<rksv-init></rksv-init><rksv-zero></rksv-zero><depexport></depexport><deplist></deplist>'}
        },
        onEnter: function () {
          console.log('finance.rksv state entered');
        }
      });
    
  } // config
  // klammerfriedhof
}());
