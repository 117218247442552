<h3>Rezepte austellen</h3>

<!-- Medikamente buttons -->
<form class="form-horizontal">
  <div class="form-group" ng-repeat="drug in patdCtrl.patient.drugs">
    <label ng-show="drug.active && !(drug.foreign)">
      <input type="checkbox"
             ng-model="drug.print"/>
      {{drug.name}} {{drug.amount}}, {{drug.op}}
      <button uib-popover-template="patdCtrl.drugedittpl" type="button" class="btn btn-primary btn-sm">Details</button>
    </label>
  </div>

  <!-- Gültigkeitsdatum -->
  <div class="form-group">
    <label>Gültig </label>
    <div ng-init="patdCtrl.pdate=true">
      <input type="radio" ng-value="true" ng-model="patdCtrl.pdate"><label class="radio-inline">1&nbsp;Monat</label>
      <input type="radio" value="false" ng-model="patdCtrl.pdate"><label class="radio-inline">Kein Datum</label>
    </div>
  </div>

  <!-- Rezept austellen -->
  <div class="form-group">
    <button class="btn btn-success"
            ng-click="patdCtrl.drugcheckout(patdCtrl.patient, patdCtrl.patient.openConsult._id);">Rezept(e) drucken
      &amp; Weiter
    </button>
    <button class="btn btn-danger" ng-click="patdCtrl.switchToDocuments(patdCtrl.patient)">Weiter ohne Rezept(e)
    </button>
  </div>
</form>

<!-- drug info in popup -->
<!--
<script type="text/ng-template" id="drugPopoverTemplate.html">
  <div>
    <div class="input-group">
      <label for="drugamount">Einnahme</label>
      <input class="form-control" ng-model="drug.amount" id="drugamount" placeholder="1-0-1-0">
    </div>
    <div class="input-group">
      <strong>Spezifika</strong>

      <div class="checkbox">
        <label for="drugind" class="checkbox-inline"><input type="checkbox" ng-model="drug.ind" id="drugind">IND
          (Indikation)</label><br>
      </div>
      <div class="radio">
        <label for="drugop1" class="radio-inline"><input type="radio" value="OP I" ng-model="drug.op" id="drugop1">OP I</label>
        <label for="drugop2" class="radio-inline"><input type="radio" value="OP II" ng-model="drug.op" id="drugop2">OP
          II</label>
        <label for="drugop3" class="radio-inline"><input type="radio" value="OP III" ng-model="drug.op" id="drugop3">OP
          III</label>
      </div>
      <label for="drugpkgsize">Packungsgröße</label>
      <input class="form-control" ng-model="drug.pkgsize" id="drugpkgsize" placeholder="30 Stk">
    </div>
    <div class="input-group">
      <div class="checkbox">
        <label for="drugperm" class="checkbox-inline"><input type="checkbox" ng-model="drug.permanent" id="drugperm">Dauermedikamentation</label><br>
      </div>
    </div>
    <div class="form-group">
      <label for="drugcomment">Rezeptvermerk</label>
      <textarea class="form-control" ng-model="drug.comment" id="drugcomment" placeholder=""></textarea>
    </div>
  </div>
</script>

-->
