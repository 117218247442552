<h4>Logbuch</h4>
<div ng-show="plogCtrl.patient.log == null">Keine Logeinträge</div>
<form class="form-vertical">
  <div class="input-group">
    <input type="text" ng-model="plogCtrl.patient.newlog" class="form-control" id="newlog"
           placeholder="Neuer Logeintrag">
    <span class="input-group-btn">
      <button class="btn btn-success" type="submit" ng-click="plogCtrl.addLogEntry()">
        <i class="fa fa-plus"></i>
      </button>
    </span>
  </div>
</form>
<div class="myscrollbox">
<dl class="dl-vertical" ng-repeat="le in plogCtrl.patient.log | orderBy: '-created' ">
  <dt ng-bind="le.created | moment: 'll' "></dt>
  <dd ng-bind="le.entry"></dd>
</dl>
</div>