<div class="panel panel-default">
  <div class="panel-heading">Chat</div>
  <div class="panel-body">
    <div scroll-glue class="chatlog">
      <div ng-repeat="msg in chatCtrl.chatlog track by $index">
        <div ng-bind="msg"></div>
      </div>
    </div>

    <form>
      <div class="input-group">
        <input class="form-control"
               ng-model="chatCtrl.newmessage" id="newgroup" placeholder="Nachricht eingeben" />
        <span class="input-group-btn">
          <button class="btn btn-success"
                  type="submit"
                  ng-click="chatCtrl.sendMsg()"><i class="fa fa-send"></i></button>
        </span>
      </div>
    </form>
  </div>

</div>




