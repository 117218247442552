<!--<div class="btn btn-success" ng-click="dtlCtrl.addDoctype()">Neuen Dokumenttyp anlegen</div>-->

<h3>Liste</h3>
<table class="table table-striped">
  <thead>
  <tr>
    <th>ID</th>
    <th>Name</th>
    <th>XSLT</th>
    <th>Drucker</th>
  </tr>
  </thead>
  <tr ng-repeat="dt in dtlCtrl.doctypelist">
    <td>{{dt._id}}</td>
    <td>{{dt.name}}</td>
    <td>{{dt.stylesheet}}</td>
    <td>{{dt.printer}}</td>

    <td>
      <button class="btn btn-primary" ng-click="dtlCtrl.editDoctype(dt)">Editeren</button>
      <button class="btn btn-danger" ng-click="dtlCtrl.removeDoctype(dt)">Löschen</button>
    </td>
  </tr>
</table>
