<div class="panel panel-default">
  <div class="panel-heading">
    <h3>Login</h3>
  </div>
  <div class="panel-body">
    <div class="alert alert-danger" ng-show="liCtrl.error">Benutzername und/oder Passwort falsch!</div>
    <form class="form-vertical">
      <div class="form-group">
        <label for="username">Benutzername</label>
        <input type="text" class="form-control" name="username" id="username"
               autocapitalize="off" autocorrect="off"  spellcheck="false"
               ng-model="liCtrl.username">
      </div>
      <div class="form-group">
        <label for="password">Passwort</label>
        <input type="password" class="form-control" name="password" id="password"
               autocapitalize="off" autocorrect="off" spellcheck="false"
               ng-model="liCtrl.password">
      </div>
      <div class="form-group">
        <input type="submit" class="btn btn-success" ng-click="liCtrl.sendcreds()" value="Einloggen">
      </div>
    </form>
  </div>
</div>
