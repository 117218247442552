(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name utils.filereader.controller:FilereaderCtrl
   *
   * @description
   *
   */
  angular
    .module('utils.filereader')
    .controller('FilereaderCtrl', FilereaderCtrl);

  FilereaderCtrl.$inject = ['Filereader', '$scope', '$http', '$log'];

  function FilereaderCtrl(Filereader, $scope, $http, $log) {
    var vm = this;
    vm.ctrlName = 'FilereaderCtrl';

    console.log(Filereader);

    vm.getFile = function () {
      vm.file.progress = 0;
      vm.blob = vm.file;
      switch (vm.file.type) {
        case 'image/gif':
          vm.file.img = true;
          break;
        case 'image/jpg':
          vm.file.img = true;
          break;
        case 'image/jpeg':
          vm.file.img = true;
          break;
        case 'image/png':
          vm.file.img = true;
          break;
        case 'text/plain':
          vm.file.text = true;
          break;
        case 'text/csv':
          vm.file.text = true;
          break;
        default:
          vm.file.nopreview = true;
      }

      if (vm.file.img) {
        Filereader.readAsDataUrl(vm.file, $scope)
          .then(function (result) {
            vm.file.fileSrc = result;
          });
        Filereader.readAsBinaryString(vm.file, $scope)
          .then(function (result) {
            vm.file.binString = result;
          });
      }

      if (vm.file.text) {
        Filereader.readAsText(vm.file, $scope)
          .then(function (result) {
            vm.file.fileSrc = result;
            vm.file.textpreview = fileHead();
          });
      }

      if (vm.file.nopreview) {
        Filereader.readAsDataUrl(vm.file, $scope)
          .then(function (result) {
            vm.file.fileSrc = result;
          });
        Filereader.readAsBinaryString(vm.file, $scope)
          .then(function (result) {
            vm.file.binString = result;
          });
      }

    };


    function fileHead() {
      // var head;
      var lines = [];
      console.log('head: ', vm.file.fileSrc);

      // head = vm.file.fileSrc.split(0, 500);
      // lines = vm.file.fileSrc.split('/\r?\n/');
      //lines = vm.file.fileSrc.split('\n');
      lines = vm.file.fileSrc.split('\r\n|[\n\v\f\r\x85\u2028\u2029]');
      console.log('lines: ', lines);
      return lines.slice(0, 4);
    }

    $scope.$on('fileProgress', function (e, progress) {
      vm.file.progress = progress.loaded / progress.total;
    });

  }

}());
