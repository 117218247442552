(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name unicatalog.directive:itemEditor
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="unicatalog">
   <file name="index.html">
   <item-editor></item-editor>
   </file>
   </example>
   *
   */
  angular
    .module('unicatalog')
    .directive('itemEditor', itemEditor);

  itemEditor.$inject = ['$log'];

  function itemEditor($log) {
    return {
      restrict: 'EA',
      scope: {
        item: '='
      },
      templateUrl: 'unicatalog/item-editor-directive.tpl.html',
      replace: false,
      controllerAs: 'ieCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'itemEditor';


      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
