(function () {
  'use strict';

  /* @ngdoc object
   * @name finance
   * @description
   *
   */
  angular
    .module('finance', [
      'ui.router'
    ]);
  
  angular.module('finance')
    .filter('datetime', function () {
      return function (dateString) {
        return moment(dateString).locale('de-at').format('lll');
      };
    });
  
}());
