<h3>Verordnungen von Hilfsmitteln</h3>




<form class="form-vertical" id="aideform" name="aideform">
<div class="form-group">
  <label for="patient">Patient</label>
  <div class="input-group">
    <input type="text" class="form-control" id="patient"
           autofocus required
           placeholder="Nachname, SVNr, Stichwort"
           ng-model="aidCtrl.patient"
           typeahead-on-select="aidCtrl.showpatient($item, $model, $label)"
           uib-typeahead="pat as pat.text for pat in aidCtrl.getNames($viewValue)"
           typeahead-loading="loadingNames"
           typeahead-no-results="noResults">
    <span class="input-group-btn">
      <button type="submit" class="btn btn-success"><i class="fa fa-search"></i></button>
    </span>
  </div>
</div>



  <div class="form-group">
    <label for="aideto">An/Zu<span uib-tooltip="Pflichtfeld">*</span></label>
    <input type="text" required ng-model="aidCtrl.newaide.to" class="form-control" id="aideto">
  </div>
  <div class="form-group">
    <label for="aidediag">Diagnose/Begründung<span uib-tooltip="Pflichtfeld">*</span></label>
    <input type="text" required ng-model="aidCtrl.newaide.diag" class="form-control" id="aidediag">
  </div>
  <div class="form-group">
    <label for="aideaide">Verordnung<span uib-tooltip="Pflichtfeld">*</span></label>
    <textarea class="form-control" required ng-model="aidCtrl.newaide.aide" rows="5"
              id="aideaide"></textarea>
  </div>
  
  <!-- gültig -->
  <div class="form-group">
    <label>Gültig </label>
    <div class="radio">
      <label class="radio-inline">
        <input type="radio" ng-value="true" ng-model="aidCtrl.newaide.pdate"
               ng-click="aidCtrl.newaide.aidedate = moment().locale('de-at').format('ll')">
        1 Monat</label>
      <label class="radio-inline">
        <input type="radio" ng-value="false" ng-model="aidCtrl.newaide.pdate"
               ng-click="aidCtrl.newaide.aidedate = ''">
        Kein Datum</label>
    </div>
  </div>
  
  <!-- Buttons -->
  <button class="btn btn-success" type="button" ng-click="aidCtrl.print()"
          ng-disabled="aideform.$invalid">
    Drucken
  </button>
  <button class="btn btn-danger" type="button" ng-click="aidCtrl.clear()">
    Abbrechen
  </button>
</form>
<div class="alert alert-success" ng-show="aidCtrl.printed === true">
  <i class="fa fa-check"></i> Erfolgreich gedruckt. Weiter zur <a ui-sref="home">Übersicht</a>.
</div>
<div class="alert alert-error" ng-show="aidCtrl.printed === false">
  <i class="fa fa-warning"></i> Druck ist fehlgeschlagen. Weiter zur <a ui-sref="home">Übersicht</a>.
</div>