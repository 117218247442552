(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name cashbook.factory:Cashbook
   *
   * @description
   *
   */
  angular
    .module('finance.cashbook')
    .factory('Cashbook', Cashbook)
    .factory('Fiscaljournal', Fiscaljournal)
  ;

  Cashbook.$inject = ['restmod', '$log'];
  Fiscaljournal.$inject = ['restmod', '$log'];
  
  // gets the current cash-amount
  function Cashbook(restmod) {
    return restmod.model('/routes/cashbook');
  }
  
  // gets cashbook history
  function Fiscaljournal(restmod) {
    return restmod.model('/routes/cashbookhistory', 'PagedModel');
  }
  

}());
