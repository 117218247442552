(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name bmfexport.directive:bmfexport
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="bmfexport">
       <file name="index.html">
        <bmfexport></bmfexport>
       </file>
     </example>
   *
   */
  angular
    .module('finance.bmfexport')
    .directive('bmfexport', bmfexport);

  bmfexport.$inject = ['Config', '$log', '$http'];

  function bmfexport(Config, $log, $http) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'finance/bmfexport/bmfexport-directive.tpl.html',
      replace: false,
      controllerAs: 'beCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        var thisyear;
        vm.name = 'bmfexport';
        vm.rangequery = rangequery;
        vm.conf = Config.$find('configuration');
        // vm.startdate = moment(moment().year() + '-01-01', 'YYYY-MM-DD').toDate();
        vm.startdate = new Date(moment().year(), 0, 1).toISOString();
        $log.debug('Startdate: ', vm.startdate);
        vm.enddate = new Date().toISOString();
        vm.dateOptions = {
          formatYear: 'yyyy',
          startingDay: 1
        };

        vm.altInputFormats = ['yyyy-MM-dd', 'dd.MM.yyyy'];


        thisyear = {
          $gte: moment().startOf('year').toISOString(),
          $lt: moment().endOf('year').toISOString()
        };

        vm.thisyearquery = '/routes/bmfexport' + '?created=' + encodeURIComponent(JSON.stringify(thisyear));


        function rangequery (start, end) {
          var qq = {
            $gte: start,
            $lt: end
          };
// '/routes/bmfexport' + '?created=' + encodeURIComponent(JSON.stringify({}));
          var tmp = '/routes/bmfexport' + '?created=' + encodeURIComponent(JSON.stringify(qq));
          $log.debug('tmp: ', tmp);
          // $log.debug('query: ', JSON.stringify(query));
          $http.get(tmp, {})
            .success(function (data, status, headers, config) {
              var anchor = angular.element('<a/>');
              anchor.attr({
                href: 'data:application/octet-stream;base64,' + encodeURI(data),
                target: '_blank',
                download: 'bmfexport.zip'
              })[0].click();
            })
            .error(function (data, status, headers, config) {
              $log.error('bmfexport filegeneration and export failed');
              $log.error(status);
              $log.error(headers);
              $log.error(data);
            });
        }


      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
