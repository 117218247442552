(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name doctype.directive:doctypeList
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="doctype">
       <file name="index.html">
        <doctype-list></doctype-list>
       </file>
     </example>
   *
   */
  angular
    .module('doctype')
    .directive('doctypeList', doctypeList);


  doctypeList.$inject = ['Doctype', '$uibModal', '$log', 'DoctypeHelper'];

  function doctypeList(Doctype, $uibModal, $log, DoctypeHelper) {
    return {
      restrict: 'E',
      scope: {},
      templateUrl: 'doctype/doctype-list-directive.tpl.html',
      replace: false,
      controllerAs: 'dtlCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'doctypeList';
        
        vm.editDoctype = DoctypeHelper.editDoctype;
        vm.addDoctype = addDoctype;
        vm.removeDoctype = removeDoctype;
  
        vm.doctypelist = Doctype.$search();
        
        function addDoctype() {
          var newdoctype = vm.doctypelist.$create();
          vm.editDoctype(newdoctype);
        }
        
        
        function removeDoctype(dt) {
          dt.active = false;
          dt.$save();
        }
        
        
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
