(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name file.factory:File
   *
   * @description
   *
   */
  angular
    .module('file')
    .factory('File', File);
   
  File.$inject = ['restmod'];

  function File(restmod) {
    return restmod.model('/routes/files');
  }

// klammerfriedhof
}());
