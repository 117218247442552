<span class="btn btn-info btn-sm"
      ng-show="esCtrl.consult.examinations.length"
      popover-trigger="outsideClick"
      uib-popover-template="esCtrl.popover"
      popover-title="Zusammenfassungen der U."
      popover-placement="auto top">Zusammenfassung d. U.</span>


<script type="text/ng-template" id="examsummaries_popver.tpl.html">
  <div>
    <div ng-repeat="exam in esCtrl.consult.examinations | filter: {active: true}">
      <h4 ng-bind="exam.type"></h4>
      <span ng-bind="exam.diagnosis"></span>
      <!--<span ng-repeat="val in exam.template.diagnosis.avalues">{{val.name}}{{$last ? '' : ', '}}</span>-->
    </div>

    <p class="alert alert-info" style="margin-top: 10pt;">
      Die Befundzusammenfassungen der einzelne Untersuchungen werden automatisch in den
      Gesamtbefund/Arztbrief übernommen.
    </p>

  </div>
</script>