(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name utils.keywords.directive:keywordsWidget
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="utils.keywords">
   <file name="index.html">
   <keywords-widget keywords="patient.keywords"></keywords-widget>
   <div keywords-widget keywords="patient.keywords"></div>
   </file>
   </example>
   *
   */
  angular
    .module('utils.keywords')
    .directive('keywordsWidget', keywordsWidget);

  keywordsWidget.$inject = [];

  function keywordsWidget() {
    return {
      restrict: 'EA',
      scope: {
        keywords: '=',
        text: '@',
        orderbycolor: '='
      },
      templateUrl: 'utils/keywords/keywords-widget-directive.tpl.html',
      replace: false,
      controllerAs: 'vmKw',
      bindToController: true,
      controller: 'KeywordsCtrl',
      link: function (scope, el, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */

        console.log('Attr: ', attrs);
        if (!attrs.orderbycolor) {
          attrs.orderbycolor = false;
          ctrl.orderkey = '$index';
        }
        else {
          ctrl.orderkey = 'nr';
        }
        if (!attrs.text) {
          attrs.text = 'Neues Stichwort eingeben <Eingabataste>';
        }
      }
    };
  }
}());
