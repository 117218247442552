(function () {
  'use strict';
  
  /**
   * @ngdoc service
   * @name waitinglist.factory:Waitinglist
   *
   * @description
   *
   */
  angular
    .module('waitinglist')
    .factory('Waitinglist', Waitinglist)
    .config(config);
  
  config.$inject = ['restmodProvider'];
  
  function config(restmodProvider) {
    restmodProvider.rebase({
      $config: {
        primaryKey: '_id'
      }
    });
  }
  
  Waitinglist.$inject = ['restmod'];
  
  function Waitinglist(restmod) {
    return restmod.model('/routes/waitinglists').mix({
      patients: {hasMany: 'Patient', inverseOf: 'waitinglistid', path: 'patients'},
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true}
    });
  }
  
  
  //      patients: {hasMany: 'Patient', inverseOf: 'waitinglist', path: 'patients'},
  /*  function Waitinglist(restmod) {
   return restmod.model('/routes/waitinglists');
   }*/
  
  
}());
