(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name printer.factory:Printer
   *
   * @description
   *
   */
  angular
    .module('printer')
    .factory('Printer', Printer)
    .factory('Printerlist', Printerlist);

  Printer.$inject = ['restmod'];

  function Printer(restmod) {
    return restmod.model('/routes/printer');
  }


  Printerlist.$inject = ['restmod'];

  function Printerlist(restmod) {
    var model = restmod.model('/routes/printer');
    var printers = {};
    function getPrinters() {
      return model.$search();
    }

    printers.getPrinters = getPrinters;
    printers.model = model;

    return printers;
  }

}());
