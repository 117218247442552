<h3>Netzwerkshares</h3>

<form class="form-vertical">
  <div class="form-group">
    <label for="sharename">Name</label>
    <input type="text" ng-model="shareCtrl.newshare.name" class="form-control" id="sharename">
  </div>
  <div class="form-group">
    <label for="sharepath">Pfad</label>
    <input type="text" ng-model="shareCtrl.newshare.path" class="form-control" id="sharepath">
  </div>
  <div class="form-group">
    <label for="sharedesc">Beschreibung</label>
    <input type="text" ng-model="shareCtrl.newshare.desc" class="form-control" id="sharedesc">
  </div>
  <div class="form-group">
    <label for="sharefiletype">Filetyp</label>
    <select class="form-control" id="sharefiletype" ng-model="shareCtrl.newshare.filetype">
      <option>image/jpeg</option>
      <option>image/gif</option>
      <option>image/tiff</option>
      <option>application/pdf</option>
      <option>video/mpeg</option>
      <option>audio/mpeg3</option>
    </select>
  </div>
  <div class="form-group">
    <div class="btn btn-success" ng-click="shareCtrl.addShare()">Speichern</div>
    <div class="btn btn-danger" ng-click="shareCtrl.newshare=''">Abbrechen</div>
  </div>
</form>


<table>
  <tr>
    <th width="20%">Name</th>
    <th width="40%">Beschreibung</th>
    <th width="30%">Pfad</th>
    <th width="20%">Filetype</th>
    <th width="5%"></th>
    <th width="5%"></th>
  </tr>
  <tr ng-repeat="share in shareCtrl.allshares">
    <td ng-bind="share.name"></td>
    <td ng-bind="share.desc"></td>
    <td ng-bind="share.path"></td>
    <td ng-bind="share.filetype"></td>
    <td>
      <div class="btn btn-primary" ng-click="shareCtrl.editShare(share)" uib-tooltip="Editieren"><i class="fa fa-edit"></i>
      </div>
    </td>
    <td>
      <div class="btn btn-danger" ng-click="share.active=false; share.$save();" uib-tooltip="Löschen"><i
        class="fa fa-remove"></i></div>
    </td>
  </tr>
</table>


<script type="text/ng-template" id="shareedittempl">
  <div>
    <div class="modal-header">
      <h3 class="modal-title">Netzwerk-Share editieren</h3>
    </div>
    <div class="modal-body">
      <form class="form-vertical">
        <div class="form-group">
          <label for="shareename">Name</label>
          <input type="text" ng-model="sheCtrl.share.name" class="form-control" id="shareename">
        </div>
        <div class="form-group">
          <label for="shareepath">Pfad</label>
          <input type="text" ng-model="sheCtrl.share.path" class="form-control" id="shareepath">
        </div>
        <div class="form-group">
          <label for="shareedesc">Beschreibung</label>
          <input type="text" ng-model="sheCtrl.share.desc" class="form-control" id="shareedesc">
        </div>
        <div class="form-group">
          <label for="shareefiletype">Filetyp</label>
          <select class="form-control" id="shareefiletype" ng-model="sheCtrl.share.filetype">
            <option>image/jpeg</option>
            <option>image/gif</option>
            <option>image/tiff</option>
            <option>application/pdf</option>
            <option>video/mpeg</option>
            <option>audio/mpeg3</option>
          </select>
        </div>
        <div class="form-group">
          <div class="btn btn-success" ng-click="sheCtrl.share.$save()">Speichern</div>
          <div class="btn btn-danger" ng-click="sheCtrl.$uibModalInstance.dismiss('cancel')">Abbrechen</div>
        </div>
      </form>
    </div>
  </div>
</script>
