
<!-- labelled groups -->
<div class="panel panel-default" ng-hide="efgCtrl.efieldgroup.label == ''" tabindex="-1">
  <div class="panel-heading"  tabindex="-1"
       ng-model="efgCtrl.efieldgroup.label">{{efgCtrl.efieldgroup.label}}</div>
  <div class="panel-body"  tabindex="-1">
    <div ng-style="{'margin-left': efgCtrl.efieldgroup.marginleft}">
      <div ng-repeat="field in efgCtrl.efieldgroup.fields">
        <field efield="field" ti="{{$index}}" gi="{{efgCtrl.gi}}"></field>
      </div>
    </div>
  </div>
</div>

<!-- unlabelled groups -->
<div ng-show="efgCtrl.efieldgroup.label == ''"  tabindex="-1">
  <div ng-repeat="field in efgCtrl.efieldgroup.fields">
    <field efield="field" ti="{{$index}}" gi="{{efgCtrl.gi}}"></field>
  </div>
</div>


