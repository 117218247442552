<div class="modal-body"
     ng-controller="FileCtrl as fC"
     ng-swipe-left="Lightbox.nextImage()"
     ng-swipe-right="Lightbox.prevImage()">
  <!-- navigation -->
  <div class="lightbox-nav">
    <!-- close button -->
    <button class="close" aria-hidden="true" ng-click="$dismiss()">×</button>

    <div>
      <a class="btn btn-xs btn-default" ng-click="fC.previous()" style="display: inline">
        ‹ Zur&uuml;ck
      </a>

      <form class="form-horizontal" style="display: inline">
        <span class="input-group" style="width: 70%">
        <span class="input-group-btn">
          <button class="btn btn-danger" ng-click="fC.fileDelete(Lightbox.image)" uib-tooltip="Löschen">
            <i class="fa fa-remove"></i>
          </button>
        </span>
          <input type="text" class="form-control" ng-model="Lightbox.image.label" id="deposit"
                 placeholder="Bezeichnung">
          <span class="input-group-btn">
            <button class="btn btn-success" type="submit" ng-click="fC.fileLabel(Lightbox.image)"
                    uib-tooltip="In Befund übernehmen">
              <i class="fa fa-plus"></i>
            </button>
          </span>
        </span>
      </form>
      <a class="btn btn-xs btn-default" ng-click="fC.next()" style="display: inline">
        Weiter ›
      </a>
    </div>
    <!--
    <div class="btn-group-md">
      <a class="btn btn-xs btn-default" ng-click="Lightbox.prevImage()">
        ‹ Zurück
      </a>

      <a ng-click="fC.fileInclude(Lightbox.image)"
         class="btn btn-xs btn-default" title="-&gt; Befund">
        <i class="fa fa-text-o"></i> in Befund
      </a>
      <a ng-click="fC.fileKeep(Lightbox.image)"
         class="btn btn-xs btn-default" title="Speichern">
        <i class="fa fa-save"></i> Speichern
      </a>
      <a ng-click="fC.fileDelete(Lightbox.image)"
         class="btn btn-xs btn-default" title="Löschen">
        <i class="fa fa-remove text-danger"></i> Löschen
      </a>
      <a ng-href="{{Lightbox.imageUrl}}" target="_blank"
         class="btn btn-xs btn-default" title="Open in new tab">
        Bild in neuem Tab öffnen
      </a>

      <form>
      <div class="input-group" style="width: 30%">
        <input type="text" class="form-control" ng-model="Lightbox.image.label" id="deposit" placeholder="Bezeichnung">
    <span class="input-group-btn">
    <button class="btn btn-success" type="submit" ng-click="fC.fileLabel(Lightbox.image)" uib-tooltip="Bezeichnung">
      <i class="fa fa-plus"></i>
    </button>
      </span>
      </div>
      </form>
      <a class="btn btn-xs btn-default" ng-click="Lightbox.nextImage()">
        Weiter ›
      </a>
    </div>
    -->
  </div>

  <div class="lightbox-image-container">
    <!-- caption -->
    <div class="lightbox-image-caption">
      <span>{{Lightbox.imageCaption}}</span>
      <span>{{Lightbox.image.label}}</span>
      <span ng-show="Lightbox.image.processed == true"><i class="fa fa-check text-success"></i> Bearbeitet </span>
      <span ng-show="Lightbox.image.keep == true"><i class="fa fa-check text-success"></i> Behalten </span>
      <span ng-show="Lightbox.image.keep == false"><i class="fa fa-remove text-danger"></i> Löschen </span>
      <span ng-show="Lightbox.image.include == true"><i class="fa fa-check text-success"></i> in Befund </span>
    </div>

    <!-- image -->
    <img ng-if="!Lightbox.isVideo(Lightbox.image)"
         lightbox-src="{{Lightbox.imageUrl}}">

    <!-- video -->
    <div ng-if="Lightbox.isVideo(Lightbox.image)"
         class="embed-responsive embed-responsive-16by9">
      <!-- video file embedded directly -->
      <video ng-if="!Lightbox.isSharedVideo(Lightbox.image)"
             lightbox-src="{{Lightbox.imageUrl}}"
             controls
             autoplay="true">
      </video>

      <!-- video embedded with an external service using
           `ng-videosharing-embed` -->
      <embed-video ng-if="Lightbox.isSharedVideo(Lightbox.image)"
                   lightbox-src="{{Lightbox.imageUrl}}"
                   ng-href="{{Lightbox.imageUrl}}"
                   iframe-id="lightbox-video"
                   class="embed-responsive-item">
        <a ng-href="{{Lightbox.imageUrl}}">Video ansehen</a>
      </embed-video>
    </div>
  </div>
</div>
