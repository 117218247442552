(function () {
  'use strict';
  
  /**
   * @ngdoc directive
   * @name config.directive:version
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="config">
   <file name="index.html">
   <version></version>
   </file>
   </example>
   *
   */
  angular
    .module('config')
    .directive('version', version);
  
  version.$inject = ['$http', '$log']
  
  function version($http, $log) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'config/version-directive.tpl.html',
      replace: false,
      controllerAs: 'verCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'version';
        
        $http.get('/routes/version')
          .then(function success(v) {
              vm.version = v;
            },
            function failure(e) {
              vm.error = e.statusText + ' (Error: ' + e.status + ')';
            });
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
