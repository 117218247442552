(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name service.directive:serviceeditor
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="service">
   <file name="index.html">
   <serviceeditor></serviceeditor>
   </file>
   </example>
   *
   */
  angular
    .module('service')
    .directive('serviceeditor', serviceeditor);

  serviceeditor.$inject = ['Service'];
  
  function serviceeditor(Service) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'service/serviceeditor-directive.tpl.html',
      replace: false,
      controllerAs: 'servCtrl',
      controller: function () {
        var vm = this;
        vm.ctrlName = 'ServiceCtrl';
        vm.Service = Service;
        vm.services = vm.Service.$search();

        vm.addAnother = addAnother;

        function addAnother() {
          vm.services.$create(vm.newservice);
          vm.newservice = {};
          return vm.services;
        }
      },
      bindToController: true
    };
  }
}());
