(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name tracker.directive:2dgraph
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="tracker">
       <file name="index.html">
        <twodgraph></twodgraph>
       </file>
     </example>
   *
   */
  angular
    .module('tracker')
    .directive('twodgraph', twodgraph);
  
  twodgraph.$inject = ['$log'];
  
  function twodgraph($log) {
    return {
      restrict: 'E',
      scope: {
        data: '=',
        graphid: '=',
        events: '='
      },
      templateUrl: 'tracker/2dgraph-directive.tpl.html',
      replace: false,
      controllerAs: 'tdCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = '2dgraph';
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        
        // Create the chart
        var graph = null;
    
        scope.$watch('tdCtrl.data', function (data) {
          // $log.debug('graph data: ', data);
          // Sanity check
          if (angular.isObject(data) && angular.isArray(data.items) && data.items.length > 0) {
            // $log.debug('graph data found');
            // If we've actually changed the data set, then recreate the graph
            // We can always update the data by adding more data to the existing data set
            if (graph !== null) {
              graph.destroy();
            }
        
            /* var items = new vis.DataSet(scope.data.items);
             var groups = new vis.DataSet(scope.data.groups);*/
        
            // Create the graph2d object
            graph = new vis.Graph2d(element[0], data.items, data.groups, data.graphoptions);
            // $log.debug('graph: ', graph);
          }
          else {
            // $log.debug('no graph data found');
          }
        }, true);
    
      }
      /* link: function (scope, element, attrs) {
        /!* jshint unused:false *!/
        /!* eslint "no-unused-vars": [2, {"args": "none"}] *!/
 

        // Create the chart
        var graph = null;
  
        scope.$watch('data', function () {
          // Sanity check
          if (scope.data === null) {
            return;
          }
    
          // If we've actually changed the data set, then recreate the graph
          // We can always update the data by adding more data to the existing data set
          if (graph !== null) {
            graph.destroy();
          }
    
          // Create the graph2d object
          graph = new vis.Graph2d(element[0], scope.data.items, scope.data.groups, scope.data.graphoptions);
          // graph = new vis.Graph2d(, scope.data.items, scope.data.groups, scope.data.graphoptions);
    
          // Attach an event handler if defined
          angular.forEach(scope.events, function (callback, event) {
            if (graphEvents.indexOf(String(event)) >= 0) {
              graph.on(event, callback);
            }
          });
    
          // onLoad callback
          if (scope.events !== null && scope.events.onload != null &&
            angular.isFunction(scope.events.onload)) {
            scope.events.onload(graph);
          }
        });
  
        scope.$watchCollection('options', function (options) {
          if (graph === null) {
            return;
          }
          graph.setOptions(options);
        });
        
      }*/
    };
  }
}());
