<h4>Patienten-Todos</h4>
<div class="alert alert-info" ng-hide="premCtrl.patient.reminders.length > 0">Keine Todos</div>
<form class="form-vertical">
    <div class="input-group">
        <input type="text" ng-model="premCtrl.newreminder.label" class="form-control" id="newtodo" placeholder="Neues Todo">
        <div uib-dropdown class="input-group-btn" ng-class="gtClass">
            <button uib-dropdown-toggle type="button" class="btn btn-secondary">
                 <span ng-show="premCtrl.newreminder.duestr">{{premCtrl.newreminder.duestr}}</span>
                <span ng-hide="premCtrl.newreminder.duestr">Fällig  </span><span class="caret"></span>
            </button>
            <!-- override bootstrap .dropdown-menu { min-width: 160px } -->
            <ul class="dropdown-menu" style="min-width: 0;">
                <li><a ng-click="premCtrl.calcdue(0, 'Sofort')">Sofort</a></li>
                <li><a ng-click="premCtrl.calcdue(14, '2 Wochen')">2 Wochen</a></li>
                <li><a ng-click="premCtrl.calcdue(30, '1 Monat')">1 Monat</a></li>
                <li><a ng-click="premCtrl.calcdue(90, '3 Monate')">3 Monate</a></li>
                <li><a ng-click="premCtrl.calcdue(180, '6 Monate')">6 Monate</a></li>
                <li><a ng-click="premCtrl.calcdue(365, '1 Jahr')">1 Jahr</a></li>
                <li><a ng-click="premCtrl.calcdue(730, '2 Jahre')">2 Jahre</a></li>
                <li><a ng-click="premCtrl.calcdue(1100, '3 Jahre')">3 Jahre</a></li>
            </ul>
            <button class="btn btn-success" type="submit" ng-click="premCtrl.addTodoEntry()">
            <i class="fa fa-plus"></i>
            </button>
        </div>
    </span>
    </div>
</form>
<div class="myscrollbox">
<form>
    <div class="form-group spacer-top" ng-repeat="item in premCtrl.patient.reminders | orderBy: 'due' | filter: {done: false}">
        <label>
            <input type="checkbox"
                   ng-model="item.done"
                   ng-change="premCtrl.save(item)"/>&nbsp;{{item.label}}, {{item.due | moment: 'l' }}</label>
    </div>
</form>
</div>