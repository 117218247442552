(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name patient.directive:checkoutReferral
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="patient">
   <file name="index.html">
   <checkout-referrals></checkout-referrals>
   </file>
   </example>
   *
   */
  angular
    .module('consult')
    .directive('checkoutReferrals', checkoutReferrals);

  checkoutReferrals.$inject = ['$log'];

  function checkoutReferrals($log) {
    return {
      restrict: 'EA',
      scope: {
        patient: '=',
        dialog: '=?'
      },
      templateUrl: 'consult/checkout-referrals-directive.tpl.html',
      replace: false,
      controllerAs: 'refCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        
        vm.removeRef = removeRef;
        vm.clearRefDoctor = clearRefDoctor;
        vm.addAnotherReferral = addAnotherReferral;
        vm.setlab = setlab;

        initNewRef();
        
        function initNewRef() {
          vm.newreferral = {};
          vm.newreferral.refdate = '';
          vm.newreferral.pdate = false;
          vm.newreferral.ginclude = [];
          vm.newreferral.additionalvalues = [];
        }
        
        function setlab() {
          vm.newreferral.to = 'Labor';
        }

        function removeRef(i) {
          vm.patient.openConsult.referrals.splice(i, 1);
          vm.patient.openConsult.$save();
        }
  
        function clearRefDoctor() {
          delete vm.newreferral.doctor;
        }
        
        
        function addAnotherReferral() {
          if (vm.newreferral.pdate === true) {
            vm.newreferral.refdate = moment().locale('de-at').format('ll');
          }
          else {
            vm.newreferral.refdate = '';
          }
          
          // create referrals array if not present
          if (!angular.isArray(vm.patient.openConsult.referrals)) {
            vm.patient.openConsult.referrals = [];
          }
          
          vm.patient.openConsult.referrals.push(vm.newreferral);
          vm.patient.openConsult.$save();

          initNewRef();
        }


      }
    };
  }
}());
