<div class="modalspacer">
  <form class="form-vertical">
    <div class="row">
      <div class="col-xs-12">
        <img class="img-responsive" ng-src="/routes/files/filereader/{{ilCtrl.img.fileid}}">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-9">
        <label for="imglabel">Bildbeschreibung</label>
        <input id="imglabel" type="text" ng-model="ilCtrl.img.label" class="form-control"
               ng-model-options="{ debounce: 2000 }">
      </div>
      <div class="col-xs-12 col-sm-3">
        <label for="imginc">In Befund</label>
        <input id="imginc" type="checkbox" ng-model="ilCtrl.img.include" class="form-control">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <span class="btn btn-success" ng-click="ilCtrl.save()">Speichern</span>
        <span class="btn btn-danger" ng-click="ilCtrl.cancel()">Abbrechen</span>
      </div>
    </div>
  </form>
</div>