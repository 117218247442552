<!-- This is a very important hack: DO NOT REMOVE this totally pointless invisible bindings -->
<!-- Removal of these bindings will cause exam and consult close detection to fail -->
<span style="visibility: hidden">Consult Status patient: <span ng-bind='payCtrl.patient.inconsult'></span> </span>
<span style="visibility: hidden">Consult Status openConsult: <span
    ng-bind='payCtrl.patient.openConsult.open'></span> </span>
<span style="visibility: hidden">Exam Status patient: <span ng-bind='payCtrl.patient.inexam'></span> </span>
<span style="visibility: hidden">Exam Status openExam: <span ng-bind='payCtrl.patient.openExam.open'></span> </span>
<span style="visibility: hidden">openExam type: <span
    ng-bind='payCtrl.patient.openExam.template.displayname'></span> </span>
<span style="visibility: hidden">Consultstate: <span ng-bind='payCtrl.patient.consultstate'></span> </span>
<span style="visibility: hidden">Checkoutstate: <span ng-bind='payCtrl.patient.checkoutstate'></span> </span>


<!-- not in consult -->
<div ng-hide="payCtrl.patient.inconsult === true && payCtrl.patient.openConsult.open === true">
  <div class="alert alert-info">
    <h4>Die Konsultation wurde von anderer Stelle beendet</h4>
    <span class="btn btn-primary"
          ng-click="payCtrl.$state.go('patientrecord.history')">Zur Patienten-Historie wechseln</span>
  </div>
</div>


<!-- In consult, not already in checkout -->
<div ng-show="payCtrl.patient.inconsult === true && payCtrl.patient.openConsult.open === true">
  
  <!--
  // TODO: replace with more intelligent version using patient todos
  <div ng-show="payCtrl.patient.openConsult.nextappointment != null">
    <h3>Termin vereinbaren</h3>
  
    ab {{payCtrl.patient.openConsult.nextappointment | addweeks: 'll' }} (in
    {{payCtrl.patient.openConsult.nextappointment}} Wochen)
  </div>-->
  
  <h3>Bezahlen</h3>
  <!-- if no serive items are found -->
  <!--<div ng-hide="payCtrl.patient.openConsult.service">-->
  <div ng-hide="payCtrl.nonzeroitems() > 0">
    <div class="alert alert-info">
      <h3>Keine Rechnungspositionen verfügbar</h3>
    </div>
    <div class="btn btn-success" ng-click="payCtrl.backToBilling()">Zurück zur Rechnungslegung</div>
    <div class="btn btn-danger" ng-click="payCtrl.reallyFinish()">Konsultation ohne Rechnung abschliessen</div>
  </div>
  
  
  <!-- Rechnungsvorschau service items -->
  <!--<div ng-show="payCtrl.patient.openConsult.service">-->
  <div ng-show="payCtrl.nonzeroitems() > 0">
    <h3>Rechnungsvorschau</h3>
    <table width="100%">
      <tr>
        <th>Pos.</th>
        <th>Bezeichnung</th>
        <th align="right">Preis</th>
        <th></th>
        <th align="right">St.Satz</th>
      </tr>
      <!-- items -->
      <tr ng-repeat="item in payCtrl.patient.openConsult.service.items | filter: {selected: true}">
        <td>{{$index + 1}}</td>
        <td ng-bind="item.name"></td>
        <td ng-bind="item.price | currency: '': 2" align="right"></td>
        <td>&nbsp;€</td>
        <td ng-bind="(item.tax | currency: '' : 0) + '%'" align="right"></td>
      </tr>
      <!-- Rabatt, discount -->
      <tr style="border-top: 1pt solid black">
        <td></td>
        <td>Rabatt</td>
        <td align="right" ng-show="payCtrl.patient.openConsult.service.discount"> -&nbsp;{{payCtrl.patient.openConsult.service.discount
          | currency: '': 2}}
        </td>
        <td align="right" ng-hide="payCtrl.patient.openConsult.service.discount"> - 0,00</td>
        <td>&nbsp;€</td>
        <td align="right"> 0%</td>
      </tr>
      <!-- Steuer, tax -->
      <tr>
        <td></td>
        <td>Ust. 20% gesamt</td>
        <td ng-bind="payCtrl.patient.openConsult.service.tax | currency: '': 2" align="right"></td>
        <td>&nbsp;€</td>
        <td></td>
      </tr>
      <!-- Summe, total -->
      <tr style="border-top: 1pt solid black; border-bottom: 1pt double black;">
        <td></td>
        <td>Gesamt</td>
        <td ng-bind="payCtrl.patient.openConsult.service.total | currency: '': 2" align="right"></td>
        <td>&nbsp;€</td>
        <td></td>
      </tr>
    </table>
    
    
    <!-- invoice has already been created -->
    <div ng-show="payCtrl.patient.openConsult.invoicecreated === true && payCtrl.invoicecreated !== true"
         class="alert alert-info spacer-top" role="alert">
      <h3>Es wurde bereits eine Rechnung erstellt!</h3>
      <button class="btn btn-primary" ng-click="payCtrl.retrieveInvoice()">PDFs anzeigen</button>
      &nbsp;
      <button class="btn btn-primary" ng-click="payCtrl.retrieveAndFinish()">Konsultation abschließen</button>
    </div>
    
    
    <!-- Zahlungsmittel auswahl -->
    <!-- only show if no invoice has been generated yet -->
    <div ng-hide="payCtrl.patient.openConsult.invoicecreated === true" class="spacer-top">
      <!-- service items total alertbox -->
      <div class="alert alert-info spacer-top" role="alert">
        <h3>Gesamt (Brutto): {{payCtrl.patient.openConsult.service.total | currency : '€ ' : 2}} </h3>
      </div>
      <!-- Choose payment typecash, card or invoice alertbox -->
      <form class="form-vertical spacer-top" name="paymenttype" id="paymenttype">
        <div class="form-group col-md-12 spacer-top alert"
             ng-class="{ 'alert-danger': paymenttype.$invalid, 'alert-success': paymenttype.$valid }">
          <div class="radio">
            <label for="pay1" class="radio-inline"><input type="radio" value="cash"
                                                          ng-required="!payCtrl.patient.openConsult.service.paytype"
                                                          ng-model="payCtrl.patient.openConsult.service.paytype"
                                                          id="pay1">Bar</label>
            <label for="pay2" class="radio-inline"><input type="radio" value="card"
                                                          ng-required="!payCtrl.patient.openConsult.service.paytype"
                                                          ng-model="payCtrl.patient.openConsult.service.paytype"
                                                          id="pay2">Bankomat o. Kreditkarte</label>
            <label for="pay3" class="radio-inline"><input type="radio" value="bill"
                                                          ng-model="payCtrl.patient.openConsult.service.paytype"
                                                          ng-required="!payCtrl.patient.openConsult.service.paytype"
                                                          id="pay3">Rechnung mit Zahlschein</label>
          </div>
        </div>
        
        <!-- Wechselgeld -->
        <div class="row spacer-top">
          <div class="form-group col-md-6">
            <label for="paycash">Bar erhalten</label>
            <input type="number" step="0.1" ng-model="payCtrl.paycash"
                   ng-change="payCtrl.payreturn = payCtrl.paycash - payCtrl.patient.openConsult.service.total"
                   class="form-control" id="paycash">
          </div>
          <div class="form-group  col-md-6">
            <label for="payreturn">Wechselgeld</label>
            <input type="number" step="0.1" ng-model="payCtrl.payreturn"
                   ng-value="" class="form-control" id="payreturn">
          </div>
        </div>
        
        <!-- Create invoices and receipt (where necessery) -->
        <div class="form-group col-md-12 spacer-top">
          <button type="submit"
                  data-ng-disabled="paymenttype.$invalid"
                  ng-click="payCtrl.saveInvoice()"
                  class="btn btn-success">Rechnung &amp; Beleg ausstellen
          </button>
        </div>
      </form>
    </div>
    
    
    <!-- user check for correct invoice generation -->
    <div ng-show="payCtrl.patient.openConsult.invoicecreated === true && payCtrl.invoicecreated === true">
      <form class="form-vertical">
        
        <!-- questions depending on invoice type -->
        <h3 ng-show="payCtrl.patient.openConsult.service.paytype === 'bill'">
          Rechnung erfolgreich ausgedruckt?
        </h3>
        <h3 ng-show="payCtrl.patient.openConsult.service.paytype === 'cash' ||
                   payCtrl.patient.openConsult.service.paytype === 'card'">
          Rechnung und Beleg inhaltlich richtig und erfolgreich ausgedruckt?
        </h3>
        
        <!-- buttons -->
        <div class="spacer-top">
          <div class="form-group col-md-4">
            <div class="btn btn-success" ng-click="payCtrl.finishConsult()">
              Ja
            </div>
          </div>
          <div class="form-group col-md-4">
            <div class="btn btn-info" ng-click="payCtrl.retryPrinting()" uib-tooltip="PDFs werden angezeigt">
              PDF Dateien anzeigen
            </div>
          </div>
          <!-- ask before canceling invoice on grounds of error -->
          <div class="form-group col-md-4">
            <div class="btn btn-danger"
                 ng-really-message="Rechnung stornieren und neue Rechnung ausstellen?"
                 ng-really-click="payCtrl.redoBilling()">
              Nein, inhaltlich falsch
            </div>
          </div>
        </div>
      </form>
    </div>
    
    <!-- invoice documents, show on user command, e.g. when print failed -->
    <!--<div ng-show="payCtrl.showpdfs === true || payCtrl.patient.openConsult.invoicecreated === true">-->
    <div ng-show="payCtrl.showpdfs === true">
      <h3>Rechnungen und Belege</h3>
      <!-- Patientenrechnung -->
      <div class="row">
        <div class="col-md-1">
          <i class="fa fa-file-pdf-o" uib-tooltip="PDF"></i>
        </div>
        <div class="col-md-9">
          <h4>Patientenrechnung</h4>
        </div>
        <div class="col-md-1">
          <a href="/routes/pdfreader/{{payCtrl.createdinvoice.patientinvoiceid}}" target="_blank"
             class="btn btn-primary"
             role="button">
            <i class="fa fa-eye" uib-tooltip="Anzeigen"></i>
          </a>
        </div>
        <div class="col-md-1">
          <a href="/routes/pdfdownload/{{payCtrl.createdinvoice.patientinvoiceid}}" class="btn btn-primary"
             role="button">
            <i class="fa fa-download" uib-tooltip="Download"></i>
          </a>
        </div>
      </div>
      <!-- Artzrechnung -->
      <div class="row">
        <div class="col-md-1">
          <i class="fa fa-file-pdf-o" uib-tooltip="PDF"></i>
        </div>
        <div class="col-md-9">
          <h4>Arztrechnung</h4>
        </div>
        <div class="col-md-1">
          <a href="/routes/pdfreader/{{payCtrl.createdinvoice.doctorinvoiceid}}" target="_blank" class="btn btn-primary"
             role="button">
            <i class="fa fa-eye" uib-tooltip="Anzeigen"></i>
          </a>
        </div>
        <div class="col-md-1">
          <a href="/routes/pdfdownload/{{payCtrl.createdinvoice.doctorinvoiceid}}" class="btn btn-primary"
             role="button">
            <i class="fa fa-download" uib-tooltip="Download"></i>
          </a>
        </div>
      </div>
      <!-- Beleg -->
      <div class="row">
        <div class="col-md-1">
          <i class="fa fa-file-pdf-o" uib-tooltip="PDF"></i>
        </div>
        <div class="col-md-9">
          <h4>Beleg</h4>
        </div>
        <div class="col-md-1">
          <a href="/routes/pdfreader/{{payCtrl.createdinvoice.receiptid}}" target="_blank" class="btn btn-primary"
             role="button">
            <i class="fa fa-eye" uib-tooltip="Anzeigen"></i>
          </a>
        </div>
        <div class="col-md-1">
          <a href="/routes/pdfdownload/{{payCtrl.createdinvoice.receiptid}}" class="btn btn-primary" role="button">
            <i class="fa fa-download" uib-tooltip="Download"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
  
  <div style="min-height:3cm">&nbsp;</div>

</div>
