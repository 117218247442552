<h3>Kassabuch</h3>

<div class="alert alert-info"><h3>Aktueller Bargeldbestand: {{vm.cashbook[0].cashvalue | currency: '€ ' : 2}}</h3></div>

<div class="row">
  <div class="col-xs-12 col-md-6">
    <form class="form-vertical">
      <div class="form-group">
        <label for="deposit">Bareinlage</label>
        
        <div class="input-group">
          <input type="number" class="form-control" ng-model="vm.depositvalue" id="deposit" placeholder="Bareinlage">
          <span class="input-group-btn">
          <button class="btn btn-success" type="submit" ng-click="vm.deposit()" uib-tooltip="Bareinlage">
            <i class="fa fa-plus"></i>
          </button>
            </span>
        </div>
      </div>
    </form>
  </div>
  <div class="col-xs-12 col-md-6">
    <form class="form-vertical">
      <div class="form-group">
        <label for="withdrawal">Barentnahme</label>
        
        <div class="input-group">
          <input type="number" class="form-control" id="withdrawal" ng-model="vm.withdrawalvalue"
                 placeholder="Barentnahme">
          <span class="input-group-btn">
            <button class="btn btn-danger" type="submit" ng-click="vm.withdrawal()" uib-tooltip="Barentnahme">
              <i class="fa fa-minus"></i>
            </button>
          </span>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="row" ng-hide="vm.cashlog.length === 0">
  <div class="col-xs-12">
    <table class="table table-striped">
      <tr>
        <th>Datum</th>
        <th align="center">Bareinlage</th>
        <th align="center">Barentnahme</th>
        <th align="center">Bareinnahme</th>
        <th align="center">Bareinnahmestorno</th>
      </tr>
      
      <tr ng-repeat="entry in vm.cashlog">
        <td ng-bind="entry.created | datetime"></td>
        <td ng-bind="entry.bareinlagebetrag | currency" align="right"></td>
        <td ng-bind="entry.barentnahmebetrag | currency" align="right"></td>
        <td ng-bind="entry.bareinnahmebetrag | currency" align="right"></td>
        <td ng-bind="entry.bareinnahmestornobetrag | currency" align="right"></td>
      </tr>
    </table>
    
    
    <uib-pagination total-items="vm.totalItems"
                    items-per-page="20"
                    max-size="10"
                    first-text="Erste"
                    last-text="Letzte"
                    next-text="N&auml;chste"
                    previous-text="Vorige"
                    boundary-links="true"
                    rotate="false"
                    ng-model="vm.currentPage"
                    ng-change="vm.pageChanged()"></uib-pagination>
  </div>
</div>

