<form class="form-vertical">
  <div class="form-group">
    <label for="nlname">Nachname</label>
    <input type="text" class="form-control"
           autofocus autocomplete="off" autocorrect="off" autocapitalize="on" spellcheck="false"
           placeholder="Nachname"
           ng-model="npCtrl.asyncSelected" id="nlname"
           typeahead-on-select="npCtrl.setLastname($model)"
           uib-typeahead="pat as pat.text for pat in npCtrl.getNewNames($viewValue)"
           typeahead-no-results="noResults">
  </div>
  <div class="form-group">
    <label for="nfname">Vorname</label>
    <input type="text" ng-model="npCtrl.newpatient.firstname" class="form-control"
           autocomplete="off" autocorrect="off" autocapitalize="on" spellcheck="false"
           id="nfname" placeholder="Vorname">
  </div>
</form>

<!--<i ng-show="loadingNames" class="glyphicon glyphicon-refresh"></i>-->
<div ng-repeat="pat in npCtrl.newnames">
  <span ng-click="npCtrl.showRecord(pat._id)" class="btn btn-info">Akte</span>
  {{pat.lastname | uppercase }} {{pat.firstname}}, * {{pat.birthdate}}
</div>

<div ng-show="noResults" class="form-group">
  <i class="fa fa-remove"></i> Keine Patienten gefunden
</div>

<div class="form-group">
  <div class="btn btn-success btn-spacer-top" ng-click="npCtrl.createPatient(); $parent.$dismiss();">Neuen Patienten anlegen</div>
  <div class="btn btn-danger btn-spacer-top" ng-click="npCtrl.cancel();">Abbrechen</div>
</div>

