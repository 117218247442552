<h3>Untersuchungen</h3>

<div class="alert alert-info" ng-show="exlCtrl.patExams.length == 0">Keine Untersuchungen gefunden</div>
<table st-table="exlCtrl.pexs" st-safe-src="exlCtrl.patExams" class="table table-striped">
    <thead>
    <tr>
        <th st-sort="created" st-sort-default="reverse">Datum</th>
        <th st-sort="type">Typ</th>
        <th st-sort="diagnosis">Befund</th>
        <th>Datei</th>
    </tr>
    <tr>
        <th><input st-search="created" placeholder="Suche nach Datum" class="input-sm form-control" type="search"/></th>
        <th><input st-search="type" placeholder="Suche nach Typ" class="input-sm form-control" type="search"/></th>
        <th><input st-search="diagnosis" placeholder="Suche nach Diagnose" class="input-sm form-control" type="search"/>
        </th>
        <th></th>
    </tr>
    </thead>
    <tbody>
    <tr ng-repeat="exam in exlCtrl.pexs">
        <td>{{exam.created | moment: 'll'}}</td>
        <td><a href="/routes/pdfreader/{{exam.docid}}" target="_blank">{{exam.type}}</a></td>
        <td><a href="/routes/pdfreader/{{exam.docid}}" target="_blank">{{exam.diagnosis}}</a></td>
        <td align="right">
            <!-- Biopsie eintragen -->
            <a ng-click="exlCtrl.editExam(exam, $index)" class="btn btn-success"
               ng-show="exam.template.biopsy"
               uib-tooltip="Biopsiebericht eintragen"><i class="fa fa-flask"></i></a>
            <!-- Existierendes PDF anzeigen -->
            <a href="/routes/pdfreader/{{exam.docid}}" target="_blank" class="btn btn-primary" uib-tooltip="Anzeigen">
                <i ng-show="!exlCtrl.spinners[$index]" class="fa fa-eye"></i>
                <i ng-show="exlCtrl.spinners[$index]" class="fa fa-spinner fa-spin"></i>
            </a>
            <!-- exisierendes PDF laden -->
            <a href="/routes/pdfdownload/{{exam.docid}}" class="btn btn-primary"  uib-tooltip="Herunterladen"><i class="fa fa-download"></i></a>
            <!-- Untersuchung nachträglich editieren, mit Rückfrage -->
            <a ng-really-click="exlCtrl.editExam(exam, $index)"
               ng-really-message="Wollen Sie wirklich diese Untersuchung nachträglich editieren und einen neuen PDF Befund erstellen?"
               class="btn btn-warning"
               uib-tooltip="Editieren &amp; neues PDF erzeugen"><i class="fa fa-edit"></i></a>
        </td>
    </tr>
    </tbody>
</table>




