(function () {
  'use strict';
  
  /**
   * @ngdoc object
   * @name examination.controller:ExaminationCtrl
   *
   * @description
   *
   */
  angular
    .module('examination')
    .controller('ExaminationCtrl', ExaminationCtrl);
  
  ExaminationCtrl.$inject = ['Examination', 'hotkeys', '$scope', '$http', '$state', '$log', '$timeout', '$interval', 'Rlog'];
  
  function ExaminationCtrl(Examination, hotkeys, $scope, $http, $state, $log, $timeout, $interval, Rlog) {
    var vm = this;
    vm.ctrlName = 'ExaminationCtrl';
    vm.Examination = Examination;
    vm.$state = $state;

    vm.addBiopsyField = addBiopsyField;
    vm.printBiopsyLabels = printBiopsyLabels;
    vm.finishExam = finishExam;
    vm.backToConsult = backToConsult;
    vm.backToRecord = backToRecord;


    function backToConsult() {
      $state.go('patientrecord.current.consult');
    }
    function backToRecord() {
      $state.go('patientrecord.history');
    }

    function printBiopsyLabels() {
      // cancel all pending $saves, $save again, on return send label print request
      if (vm.patient.openExam.$hasPendingActions()) {
        $log.debug('openExam has pending actions!');
        Rlog.debug('examination', 'examination-controller', 'openExam has pending actions!',
          {openExamId: vm.patient.openExam._id, openExamType: vm.patient.openExam.template.type, patientid: vm.patient._id});
        vm.patient.openExam.$cancel();
        vm.patient.openExam.$save().$then(function () {
          reallyPrint();
        });
      }
      else {
        reallyPrint();
      }
    }
    
    function reallyPrint() {
      $http({
        url: '/routes/pdfprinter/examprint',
        method: 'GET',
        params: {
          patientid: vm.patient._id,
          examid: vm.patient.openExam._id,
          doctype: 'labels'
        }
      }).success(function () {
        vm.labelsprinted = true;
      }).error(function () {
        vm.labelsprinted = false;
      });
    }
    

    function addBiopsyField() {
      var biopsy = {
        label: '',
        avalues: [],
        values: []
      };
      vm.patient.openExam.template.biopsy.biopsies.push(biopsy);
    }

    function finishExam() {
      var i;
      var btest = false;
      var biopsies = [];
      $log.debug('examination-controller.finishExam entered');


      // build diagnosis string from parts for quick reference
      if (angular.isObject(vm.patient.openExam.template.diagnosis)) {
        vm.patient.openExam.diagnosis = '';
        for (i = 0; i < vm.patient.openExam.template.diagnosis.avalues.length; i++) {
          vm.patient.openExam.diagnosis += vm.patient.openExam.template.diagnosis.avalues[i].name;
          if (i !== vm.patient.openExam.template.diagnosis.avalues.length - 1) {
            vm.patient.openExam.diagnosis += ', ';
          }
        }
      }

      // add top-level type entry for sorting in table view
      vm.patient.openExam.type = vm.patient.openExam.template.displayname;

      // add formated date vor easy identification and display ordering
      vm.patient.openExam.time = moment().format('HH:mm');

      // create biopsy referral for exams with biopsy field

      // test if biopsie entries where actually created
      if (angular.isObject(vm.patient.openExam.template.biopsy) && vm.patient.openExam.template.biopsy.biopsies.length > 0) {
        biopsies = vm.patient.openExam.template.biopsy.biopsies;
        btest = false;
        for (i = 0; i < biopsies.length; i++) {
          if (biopsies[i].avalues.length > 0) {
            btest = true;
          }
        }
        if (btest) {
          addBiopsyReferral();
        }
      }


      // get images (where applicable) or directly generate PDF
      // exam stays open!
      if (vm.patient.openExam.template.loadfiles === true && vm.patient.openExam.template.shareid !== '') {
        vm.patient.consultstate = 'examfiles';
        vm.patient.$save();
        $state.go('patientrecord.current.examfiles');
      }
      else {
        // rapid double saving can cause lock on $save() operation, check exam backlog first
        safeFinish();
      }
    } // finishExam
    
    
    function safeFinish() {
      if (vm.patient.openExam.$hasPendingActions()) {
        $log.debug('openExam has pending actions!');
        Rlog.debug('examination', 'examination-controller', 'openExam has pending actions!',
          {openExamId: vm.patient.openExam._id, openExamType: vm.patient.openExam.template.type, patientid: vm.patient._id});
        vm.patient.openExam.$cancel();
        reallyFinish();
      }
      else {
        $log.debug('finished instantly');
        reallyFinish();
      }
    }
    
    function reallyFinish() {
      vm.patient.openExam.open = false;
      vm.patient.openExam.$save();
      vm.patient.inexam = false;
      vm.patient.consultstate = 'consult';
      vm.patient.$save();
      $state.go('patientrecord.current.consult');
    }
    
    
    // add patho referral for biopsies
    function addBiopsyReferral() {
      var biolabels = [];
      var label;
      var i;
      if (!vm.patient.openConsult.hasOwnProperty('referrals') || !(vm.patient.openConsult.referrals instanceof Array)) {
        vm.patient.openConsult.referrals = [];
      }
      
      if (!vm.patient.openConsult.hasOwnProperty('newreferral')) {
        vm.patient.openConsult.newreferral = {};
      }
      
      if (angular.isObject(vm.pathology)) {
        vm.patient.openConsult.newreferral.doctor = vm.pathology;
      }
      else {
        vm.patient.openConsult.newreferral.to = 'Pathologie';
      }
      
      if (angular.isString(vm.pathdiag)) {
        vm.patient.openConsult.newreferral.diag = vm.pathdiag;
      }
      else {
        vm.patient.openConsult.newreferral.diag = 'Auffälligkeiten';
      }
      
      if (angular.isString(vm.pathcomment)) {
        vm.patient.openConsult.newreferral.ref = vm.pathcomment;
      }
      else {
        vm.patient.openConsult.newreferral.comment = 'Biopsien';
      }
      
      
      // vm.patient.openConsult.newreferral.biolabels = vm.patient.openExam.template.biopsy.biopsies.join(', ');
      for (i = 1; i <= vm.patient.openExam.template.biopsy.biopsies.length; i++) {
        label = vm.patient.openExam.template.type + ' PE ' + i + ': ';
        vm.patient.openExam.template.biopsy.biopsies[i - 1].avalues.forEach(function (v) {
          $log.debug('v: ', v);
          label += v.name;
          label += ', ';
        });
        biolabels.push(label.substring(0, label.length - 2));
      }
      
      vm.patient.openConsult.newreferral.biolabels = biolabels;
      
      $log.debug('BioReferral: ', vm.patient.openConsult.newreferral);
      if (Object.keys(vm.patient.openConsult.newreferral).length > 0) {
        vm.patient.openConsult.referrals.push(vm.patient.openConsult.newreferral);
        vm.patient.openConsult.newreferral = {};
      }
      vm.patient.openConsult.$save();
    }

    // hotkey grab backspace, which causes uncontrolled 'back' commands in chrom browser
    hotkeys.bindTo($scope)
      .add({
        combo: 'backspace',
        description: 'Unterdrückt: Verhindert Rückwärtsspringen im Browser',
        callback: function (event, hotkey) {
          // do exactly nothing :-)
          // $window.alert('backspace pressed');
          event.preventDefault();
        }
      });


  } // ExaminationCtrl
// klammerfriedhof
}());
