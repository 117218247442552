(function () {
  'use strict';

  /* @ngdoc object
   * @name waitinglist
   * @description
   *
   */
  angular
    .module('waitinglist', [
      'ui.router',
      'utils.listEditor',
      'restmod',
      'dndLists',
      'as.sortable'
    ]);
}());
