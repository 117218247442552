(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name examination.factory:Examination
   *
   * @description
   *
   */
  angular
    .module('examtemplate')
    .factory('Examination', Examination)
    .config(config);

  config.$inject = ['restmodProvider'];

  function config(restmodProvider) {
    restmodProvider.rebase({
      $config: {
        primaryKey: '_id'
      }
    });
  }

  Examination.$inject = ['restmod'];

  function Examination(restmod) {
    return restmod.model('/routes/examinations').mix({
      patient: {belongsTo: 'Patient', key: 'patientid'},
      consult: {belongsTo: 'Consult', key: 'consultid'},
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true},
      open: {init: false}
    });
  }
}());
