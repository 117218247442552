(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name file.controller:FileCtrl
   *
   * @description
   *
   */
  angular
    .module('file')
    .controller('FileCtrl', FileCtrl);


  FileCtrl.$inject = ['File', 'Share', 'FileGrabberStart', 'FileGrabberStop', 'Lightbox', 'hotkeys', '$scope', '$log'];


  function FileCtrl(File, Share, FileGrabberStart, FileGrabberStop, Lightbox, hotkeys, $scope, $log) {
    var vm = this;
    vm.ctrlName = 'FileCtrl';
    vm.File = File;
    vm.allshares = Share.$search();

    vm.fileGrabberStart = FileGrabberStart;
    vm.fileGrabberStop = FileGrabberStop;
    vm.getFiles = getFiles;
    vm.showFiles = showFiles;

    vm.fileKeep = fileKeep;
    vm.fileInclude = fileInclude;
    vm.fileDelete = fileDelete;
    vm.fileLabel = fileLabel;
    vm.next = next;
    vm.previous = previous;


    vm.demoStop = demoStop;
    vm.demoStart = demoStart;


    function getFiles(jobid) {
      File.$search({jobid: jobid.jobid}).$asPromise().then(function (files) {
        vm.files = files;
        console.log('vm.files: ', vm.files);
        vm.showFiles();
      });
    }

    function previous(image) {
      if (!image.processed) {
        image.keep = true;
        image.processed = true;
      }
      image.$save();
      Lightbox.prevImage();
    }

    function next(image) {
      if (!image.processed) {
        image.keep = true;
        image.processed = true;
      }
      image.$save();
      Lightbox.nextImage();
    }

    function showFiles() {
      Lightbox.openModal(vm.files, 0);
    }

    function fileKeep(image) {
      console.log('File keep: ', image);
      image.keep = true;
      image.processed = true;
      image.$save();
    }

    function fileInclude(image) {
      console.log('File Include ', image);
      image.keep = true;
      image.include = true;
      image.processed = true;
      image.$save();
    }

    function fileDelete(image) {
      console.log('File delete ', image);
      image.keep = false;
      image.processed = true;
      image.include = false;
      image.$save();
      Lightbox.nextImage();
    }

    function fileUnDelete(image) {
      console.log('File delete ', image);
      image.keep = true;
      image.processed = true;
      image.include = false;
      image.$save();
    }

    function fileLabel(image) {
      console.log('File label: ', image);
      image.keep = true;
      image.include = true;
      image.processed = true;
      image.$save();
      Lightbox.nextImage();
    }


    // hotkeys for image picker
    hotkeys.bindTo($scope)
      .add({
        combo: 'b',
        description: 'in Befund',
        callback: function () {
          $log.debug('Lightbox.image: ', Lightbox.image);
          fileInclude(Lightbox.image);
        }
      })
      .add({
        combo: 's',
        description: 'Speichern',
        callback: function () {
          fileKeep(Lightbox.image);
        }
      })
      .add({
        combo: 'x',
        description: 'Löschen',
        callback: function () {
          fileDelete(Lightbox.image);
        }
      })
      .add({
        combo: 'u',
        description: 'Un-Löschen',
        callback: function () {
          fileUnDelete(Lightbox.image);
        }
      })
      .add({
        combo: 't',
        description: 'Text',
        callback: function () {
          fileLabel(Lightbox.image);
        }
      })
      .add({
        combo: 't',
        description: 'Text',
        callback: function () {
          fileLabel(Lightbox.image);
        }
      })
      .add({
        combo: 'left',
        description: 'Vorheriges Bild',
        callback: function () {
          next(Lightbox.image);
        }
      })
      .add({
        combo: 'right',
        description: 'N&auml;chste Bild',
        callback: function () {
          previous(Lightbox.image);
        }
      });


    // for demonstration purposes only
    function demoStart(share) {
      var jobid = vm.fileGrabberStart(share._id);
      if (typeof vm.openGrabbers === 'undefined') {
        vm.openGrabbers = [];
      }
      vm.openGrabbers.push(share);
      return jobid;
    }

    // for demonstration purposes only
    function demoStop(share, metadata, $index) {
      var stopobj = {};
      stopobj.shareid = share._id;
      stopobj.metadata = metadata;
      vm.fileGrabberStop(stopobj).$asPromise().then(function (jobid) {
        vm.openGrabbers.splice($index);
        vm.getFiles(jobid);
      });
    }

// klammerfriedhof
  }
}());
