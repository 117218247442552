(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name turnover.factory:Turnover
   *
   * @description
   *
   */
  angular
    .module('finance.turnover')
    .factory('Turnover', Turnover);

  Turnover.$inject = ['restmod'];

  function Turnover(restmod) {
    return restmod.model('/routes/sales');
  }

}());
