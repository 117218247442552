(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name patient.factory:PatientService
   *
   * @description
   *
   */
  angular
    .module('patient')
    .factory('Patient', Patient)
    .factory('TestPatient', TestPatient)
    .factory('Patientsearch', Patientsearch)
    .factory('Patientcount', Patientcount)
    .factory('Patientpaged', Patientpaged)
    .config(config);


  config.$inject = ['restmodProvider'];

  function config(restmodProvider) {
    restmodProvider.rebase({
      $config: {
        primaryKey: '_id'
      }
    });
  }


  Patient.$inject = ['restmod'];
  
  
  function Patient(restmod) {
    return restmod.model('/routes/patients').mix({
      consults: {hasMany: 'Consult'},
      reminders: {hasMany: 'Reminder'},
      openConsult: {hasOne: 'Consult'},
      openExam: {hasOne: 'Examination'},
      waitinglist: {belongsTo: 'Waitinglist', inverseOf: 'patients', map: 'patwl'},
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true},
      test: {init: false},
      drugs: {init: []},
      todos: {init: []},
      log: {init: []},
      keywords: {init: []},
      svref: {init: {}},
      diagnoses: {init: {}},
      image: {init: '/img/head_male.png'},
      inconsult: {inconsult: false},
      inexam: {inexam: false},
      incheckout: {incheckout: false},
      consultstate: {consultstate: ''},
      checkoutstate: {checkoutstate: ''},
      today: {init: false}
    });
  }

  Patientsearch.$inject = ['restmod'];

  function Patientsearch(restmod) {
    return restmod.model('/routes/patients/patahead');
  }


  Patientcount.$inject = ['restmod'];
  function Patientcount(restmod) {
    return restmod.model('/routes/patients/count');
  }


  Patientpaged.$inject = ['restmod'];
  function Patientpaged(restmod) {
    return restmod.model('/routes/patients/pagedlist');
  }


  TestPatient.$inject = ['restmod'];

  function TestPatient(restmod) {
    return restmod.model('/routes/patients').mix({
      consults: {hasMany: 'Consult'},
      created: {
        init: function () {
          return moment().toISOString();
        }
      },
      active: {init: true},
      test: {init: true},
      firstname: {init: 'Maximilian'},
      lastname: {init: 'Mustermann'},
      birthdate: {init: '01.01.2010'},
      svnr: {init: '1234010110'},
      address: {init: 'Mustergasse 24'},
      postcode: {init: '1010'},
      city: {init: 'Wien'},
      insuredwith: {init: {name: 'Maria Mustermann', svnr: '432101070'}},
      drugs: {init: []},
      todos: {init: []},
      log: {init: []},
      keywords: {init: [{key: 'Erstbesuch', col: 'info'}]},
      diagnoses: {init: {}},
      image: {init: '/img/head_male.png'},
      inconsult: {inconsult: false},
      today: {init: false}
    });
  }


}());
