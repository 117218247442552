<h3>Tracker</h3>

<div ng-hide="tlCtrl.patient.trackers.length > 0" class="alert alert-info">
  Noch keine Tracker angelegt
</div>

<!-- Neuer Tracker-->
<h4>Neuen Tracker anlegen</h4>
<form id="newtr" name="newtr">
  <div class="row">
    <div class="col-xs-12 col-sm-6">
      <label for="trname">Name</label>
      <input type="text" class="form-control" ng-model="tlCtrl.newtr.name" required placeholder="Name des Trackers"
             id="trname">
    </div>
    <div class="col-xs-12 col-sm-2">
      <label for="trunit">Einheit</label>
      <input type="text" class="form-control" ng-model="tlCtrl.newtr.unit" required placeholder="z.B. mmHg ..."
             id="trunit">
    </div>
    <div class="col-xs-12 col-sm-2">
      <label for="truplim" uib-tooltip="Untere Grenze Normbereich">Norm-Min</label>
      <input type="number" class="form-control" ng-model="tlCtrl.newtr.min" placeholder="" id="truplim">
    </div>
    <div class="col-xs-12 col-sm-2">
      <label for="trdownlim" uib-tooltip="Obere Grenze Normbereich">Norm-Max</label>
      <input type="number" class="form-control" ng-model="tlCtrl.newtr.max" placeholder="" id="trdownlim">
    </div>
  </div>
  <div class="row spacer-top">
    <div class="col-xs-12">
      <button type="button" class="btn btn-success" ng-click="tlCtrl.addTracker()"><i class="fa fa-plus"></i> Tracker
        hinzufügen
      </button>
    </div>
  </div>
</form>

<!-- Dateneingabe -->
<form name="dpentry" id="dpentry" ng-show="tlCtrl.patient.trackers.length > 0">
  <h4>Dateneingabe für bestehende Tracker</h4>
  <div ng-repeat="tr in tlCtrl.patient.trackers | filter: {active: true}" class="row" ng-init="tlCtrl.newdp[$index].date = tlCtrl.today();">
    <div class="col-xs-12 col-sm-4">
      <label for="dpname">Name</label>
      <input type="text" disabled class="form-control" ng-model="tr.name" id="dpname">
    </div>
    <div class="col-xs-12 col-sm-4">
      <label for="trname">Datum</label>
      <input type="text" class="form-control" ng-model="tlCtrl.newdp[$index].date"
      
             required placeholder="JJJJ-MM-DD" id="tpdate">
    </div>
    <div class="col-xs-12 col-sm-4">
      <label for="dpval">Wert</label>
      <input type="number" class="form-control" ng-model="tlCtrl.newdp[$index].data" required placeholder="" id="dpval">
    </div>
  
  </div>
  <div class="row spacer-top">
    <div class="col-xs-12">
      <button type="submit" ng-click="tlCtrl.addDatapoints()" class="btn btn-success">
        <span ng-hide="tlCtrl.dpsaved">Daten speichern</span>
        <span ng-show="tlCtrl.dpsaved"><i class="fa fa-check"></i> Daten gespeichert</span>
      </button>
    </div>
  
  </div>
</form>

<!-- Graphiken -->
<div ng-show="tlCtrl.patient.trackers.length > 0" class="row spacer-top spacer-bottom">
  <div ng-repeat="tr in tlCtrl.patient.trackers | filter: {active: true}" class="col-xs-12 col-sm-6">
    <h4>
      <span>{{tr.name}} ({{tr.unit}})</span>
      <span class="pull-right">
        <button class="btn btn-primary btn-xs" type="button" uib-tooltip="Daten editieren" ng-click="tlCtrl.editTracker($index)"> <i class="fa fa-edit"></i></button>
        <button class="btn btn-danger btn-xs" type="button" uib-tooltip="Tracker löschen" ng-click="tr.active = false; tlCtrl.patient.$save();"> <i class="fa fa-remove"></i></button>
      </span>
    </h4>
    <twodgraph data="tr" graphid="$index" events=""></twodgraph>
  </div>
</div>

<!-- Comfort whitespace -->
<div class="spacer-top spacer-bottom" style="min-height: 25px">&nbsp;</div>
