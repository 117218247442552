(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name helpers.zipcode.directive:zipcode
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="helpers.zipcode">
   <file name="index.html">
   <zipcode></zipcode>
   </file>
   </example>
   *
   */
  angular
    .module('helpers.zipcode')
    .directive('zipcode', zipcode);

  zipcode.$inject = ['Zipcodesearch', '$log'];

  function zipcode(Zipcodesearch, $log) {
    return {
      restrict: 'E',
      scope: {
        zip: '=',
        city: '=',
        ti: '@?',
        ti2: '@?'
      },
      templateUrl: 'helpers/zipcode/zipcode-directive.tpl.html',
      replace: false,
      controllerAs: 'zcCtrl',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.name = 'zipcode';

        // default optional ti (tabindex) to 1000 & 1001
        if (!angular.isObject(vm.ti)) {
          vm.ti = 1000;
        }

        if (!angular.isObject(vm.ti2)) {
          vm.ti2 = 1001;
        }

        vm.getzipcity = getzipcity;
        vm.foreignzip = foreignzip;
        vm.getNames = getNames;
        // vm.ngModelOptionsSelected = ngModelOptionsSelected;


        // typeahead patient search backend query
        function getNames(val) {
          $log.debug('loading city names');

          return Zipcodesearch.$search({zip: val}).$asPromise().then(function (response) {
            console.log('response: ', response);
            return response.map(function (zip) {
              zip.text = zip.zip + ' ' + zip.city;
              zip.label = zip.zip + ' ' + zip.city;
              $log.debug('response zip: ', zip);
              return zip;
            });
          });
        }

        // typeahead patient search action
        function getzipcity($item, $model, $label) {
          /* jshint unused:false */
          /* eslint "no-unused-vars": [2, {"args": "none"}] */
          //  $log.debug('orig zip: ', vm.zip);
          //  $log.debug('$model zip: ', $model.zip);
          //  $log.debug('orig city: ', vm.city);
          vm.zip = $model.zip;
          vm.city = $model.city;
        }

        function foreignzip() {
          $log.debug('foreignzip vm.zip:', vm.zip);
          $log.debug('foreignzip asyncSelected:', vm.asyncSelected);
          if (!vm.zip) {
            if (angular.isString(vm.asyncSelected)) {
              vm.zip = vm.asyncSelected;
              $log.debug('foreignzip string vm.zip:', vm.zip);
            }
            if (angular.isObject(vm.asyncSelected) && vm.asyncSelected.hasOwnProperty(zip)) {
              vm.zip = vm.asyncSelected.zip;
              $log.debug('foreignzip object vm.zip:', vm.zip);
            }
          }
        }


      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
