(function () {
  'use strict';

  /* @ngdoc object
   * @name utils
   * @description
   *
   */
  angular
    .module('utils', [
      'ui.router',
      'utils.keywords',
      'utils.fileuploader',
      'utils.listEditor',
      'utils.stacktrace'
    ]);
}());
