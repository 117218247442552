<div class="panel panel-default">
  <div class="panel-heading">
    <h3 class="panel-title">Neuer Anwender</h3>
  </div>
  <div class="panel-body">
    <form>
      <div>
        <input type="checkbox" ng-model="user.newuser.active"> Account aktiv
      </div>
      <div class="form-group">
        <label for="username">Username</label>
        <input type="text" ng-model="uCtrl.newuser.username" class="form-control" id="username" placeholder="Username">
      </div>
      <!--<div class="form-group">
        <label for="formattedname">Vollständiger (formatierter) Name</label>
        <input type="text" ng-model="uCtrl.newuser.formattedname" class="form-control" id="formattedname"
               placeholder="Voller Name">
      </div>-->
      <div class="form-group">
        <label for="password">Passwort</label>
        <input type="password" ng-model="uCtrl.newuser.password" class="form-control" id="password"
               placeholder="Voller Name">
      </div>
      <div class="input-group">
        <span class="input-group-btn">
	        <button class="btn btn-default" type="button" ng-click="uCtrl.add()">Neuen Anwender anlegen</button>
        </span>
      </div>
    </form>
  </div>
  <!-- panelbody -->
</div><!-- panel -->


<div class="panel panel-default">
  <div class="panel-heading">
    <h3 class="panel-title">Alle Anwender</h3>
  </div>
  <div class="panel-body">
    <table class="table table-striped">
      <thead>
      <tr>
        <th>ID</th>
        <!--<th>Aktiv</th>-->
        <th>Benutzername</th>
        <!--<th>Vollständiger Name</th>-->
        <!--<th>Aktion</th>-->
      </tr>
      </thead>
      <tbody>
      <tr ng-repeat="u in uCtrl.users">
        <td>
          uid: {{u._id}}
        </td>
        <!--<td>
          <input type="checkbox" ng-model="u.active">
        </td>-->
        <td>
          <input type="text" ng-model="u.username">
        </td>
        <!--<td>
          <input type="text" ng-model="u.formattedname">
        </td>-->
        <td>
          <!--<button class="btn btn-default" type="button" ng-click="u.$save()">Ändern</button>-->
          <!--<button class="btn btn-default" type="button" ng-click="u.active=false;u.$save()">Löschen</button>-->
        </td>
      </tr>
  </div>
  <!-- panelbody -->
</div><!-- panel -->
