(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name examination.directive:field
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="examination">
       <file name="index.html">
        <field></field>
       </file>
     </example>
   *
   */
  angular
    .module('examination')
    .directive('field', field);

  field.$inject = ['$log'];

  function field($log) {
    return {
      restrict: 'E',
      scope: {
        efield: '=',
        ti: '@?',
        gi: '@?'
      },
      templateUrl: 'examination/exameditor-field.tpl.html',
      replace: false,
      controllerAs: 'efCtrl',
      bindToController: true,
      controller: function ($scope) {
        var vm = this;
        vm.name = 'efCtrl';
        vm.loadValues = loadValues;
        vm.save = save;

        vm.groupi = parseInt(vm.gi, 10);
        vm.tabi = parseInt(vm.ti, 10);
        vm.fti = 100*vm.groupi + vm.tabi;
        
        function save() {
          $log.debug('field save called');
          $scope.$emit('tagadded', '');
        }

        function loadValues($query) {
          var promise = new Promise(function (resolve, reject) {
            // TODO this is a rather nasty piece of code to generate a promise that is needed in the next step
            if (true) {
              // console.log('efield values', vm.efield.values);
              resolve(vm.efield.values);
            }
            else {
              reject([]);
            }
          });
          return promise.then(function (values) {
            var filteredValues = values.filter(function (value) {
              return value.name.toLowerCase().indexOf($query.toLowerCase()) !== -1;
            });
            // console.log('filtered values: ', filteredValues);
            return filteredValues;
          });
        }
      }
    };
  }
}());
