<div>{{waitinglists.name}}</div>

<div class="panel-group">
<div ng-repeat="list in wlCtrl.waitinglists" class="panel panel-default">
  <div class="panel-heading"><h4>{{list.name}}</h4></div>
  <div class="panel-body" as-sortable="wlCtrl.sortableOptions" ng-model="list.patients">
    <div class="alert alert-info" ng-repeat="pat in list.patients" as-sortable-item style="font-size: larger">
      <span as-sortable-item-handle>{{pat.lastname}} {{pat.firstname}}</span>
      <span class="pull-right">
        <button type="button" class="btn btn-success" uib-tooltip="Patient aufrufen" ng-click="">
          <i class="fa fa-bullhorn"></i>
        </button>
        <button type="button" class="btn btn-primary" uib-tooltip="Patientenakte" ng-click="">
          <i class="fa fa-folder"></i>
        </button>
        <button type="button" class="btn btn-danger" uib-tooltip="von Warteliste löschen" ng-click="">
          <i class="fa fa-remove"></i>
        </button>
      </span>
    </div>
    <div style="min-height: 40px">
      &nbsp;
      <!-- Comfort whitespace -->
    </div>
  </div>
</div>
</div>

<!--
<ul dnd-list="list.people"
    dnd-allowed-types="list.allowedTypes"
    dnd-disable-if="list.people.length >= list.max">
  
  <li ng-repeat="person in list.people"
      dnd-draggable="person"
      dnd-type="person.type"
      dnd-disable-if="person.type == 'unknown'"
      dnd-moved="list.people.splice($index, 1)"
      dnd-effect-allowed="move"
      class="background-{{person.type}}"
  >
    <dnd-nodrag>
      <div dnd-handle class="handle">:::</div>
      <div class="name">
        <input type="text" ng-model="person.name" class="background-{{person.type}} form-control input-sm">
      </div>
    </dnd-nodrag>
  </li>
  
  <li class="dndPlaceholder">
    Drop any <strong>{{list.allowedTypes.join(' or ')}}</strong> here
  </li>

</ul>
-->
