<!-- <label for="newkeyword">{{vmKw.text}}</label> -->
<span class="input-group" style="display: inline" as-sortable="vmKw.sortableOptions" ng-model="vmKw.keywords" id="sortable-container">
    <span ng-repeat="kw in vmKw.keywords" as-sortable-item style="display: inline;">
      <span uib-dropdown>
        <button type="button"  as-sortable-item-handle
                class="btn btn-{{kw.col}}" uib-dropdown-toggle
                data-toggle="uib-dropdown"
                aria-haspopup="true" aria-expanded="false">
          {{kw.key}} <span class="caret"></span>
        </button>
        <ul uib-dropdown-menu>
          <li>
            <a ng-click="vmKw.updateColor(kw.key,'success')">
              <span class="label label-success">Grün (st.p.)</span>
            </a>
          </li>
          <li>
            <a ng-click="vmKw.updateColor(kw.key,'warning')">
              <span class="label label-warning">Gelb (Warnung)</span>
            </a>
          </li>
          <li>
            <a ng-click="vmKw.updateColor(kw.key,'danger')">
              <span class="label label-danger">Rot (Gefahr)</span>
            </a>
          </li>
          <li>
            <a ng-click="vmKw.updateColor(kw.key,'info')">
              <span class="label label-info">Türkis (Aktuell)</span>
            </a>
          </li>
          <li>
            <a ng-click="vmKw.updateColor(kw.key,'primary')">
              <span class="label label-primary">Blau (Neu)</span>
            </a>
          </li>
          <li role="separator" class="divider" uib-dropdown></li>
          <li uib-dropdown ng-show="kw.created"><a><span class="label label-default">Erstellt: {{kw.created}}</span></a></li>
          <li uib-dropdown ng-show="kw.updated"><a><span class="label label-default">Geändert: {{kw.updated}}</span></a></li>
          <li ng-hide="kw.col === 'danger'" role="separator" class="divider"  uib-dropdown></li>
          <li ng-hide="kw.col === 'danger'">
            <a ng-really-message="Stichwort wirklich löschen?"
               ng-really-click="vmKw.removeKeyword(kw.key)">
              <span class="label label-danger">X</span> Löschen
            </a>
        </ul>
      </span>
    </span><!-- ng-repeat -->
  </span>

<form class="spacer-top">
  <div class="input-group spacer-top">
    <input class="form-control" ng-model="vmKw.newkeyword" id="newkeyword"
           ng-blur="vmKw.addKeyword()"
           placeholder="{{vmKw.text}}"/>
    <span class="input-group-btn">
          <button class="btn btn-success" type="submit" ng-click="vmKw.addKeyword()">+</button>
        </span>
  </div>
  </div>
</form>
